export const DEFAULT_SAAS_ICON = 'SaaS';

export const DEFAULT_PUBLIC_CLOUD_ICON = 'Public Cloud';

export const SESSION_VIEW_OPTIONS: SessionViewOption[] = [
  {
    label: 'allow',
    value: 'ALLOW',
  },
  {
    label: 'force',
    value: 'FORCE',
  },
  {
    label: 'hide',
    value: 'HIDE',
  },
];

export const DEFAULT_MAX_AMOUNT_PER_TOPOLOGY = 1;

export const DEFAULT_SESSION_VIEW = 'ALLOW';

export const DESCRIPTION_FIELD_NUM_ROWS = 3;
export const DETAILS_FIELD_NUM_ROWS = 10;
export const STATUS_MESSAGE_FIELD_NUM_ROWS = 1;
