import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetInventorySaasTemplatesQuery,
  useGetSaasIntegrationsQuery,
} from '../../../../../redux/configuration/saas/api';
import { addConfiguredQuantityToSaasTemplatesAndFilterByType } from './utils';
import { AddSaasIntegrations } from '.';

type ConnectedAddSaasIntegrationsProps = {
  integrationType: IntegrationType;
};

export function ConnectedAddSaasIntegrations({
  integrationType,
}: ConnectedAddSaasIntegrationsProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    data: userSaasIntegrations,
    isLoading: isLoadingUserSaasIntegrations,
    isFetching: isFetchingUserSaasIntegrations,
    isError: isErrorUserSaasIntegrations,
  } = useGetSaasIntegrationsQuery(topologyUid);

  const {
    data: inventorySaasTemplates,
    isLoading: isLoadingInventory,
    isFetching: isFetchingInventory,
    isError: isErrorInventory,
  } = useGetInventorySaasTemplatesQuery(topologyUid, {
    selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
      data: addConfiguredQuantityToSaasTemplatesAndFilterByType(
        data || [],
        userSaasIntegrations?.saasIntegrations || [],
        integrationType,
      ),
      isError,
      isFetching,
      isLoading,
    }),
  });

  return (
    <AddSaasIntegrations
      isLoading={
        isLoadingInventory ||
        isFetchingInventory ||
        isErrorInventory ||
        isLoadingUserSaasIntegrations ||
        isFetchingUserSaasIntegrations ||
        isErrorUserSaasIntegrations
      }
      inventorySaasTemplates={inventorySaasTemplates}
      integrationType={integrationType}
    />
  );
}

export type AddSaasIntegrationsProps = {
  isLoading: boolean;
  inventorySaasTemplates?: SaasTemplateWithConfiguredQuantity[];
  integrationType: IntegrationType;
};
