import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../constants/form';

export function buildIPAddressOptionsNicTarget(
  selectedVm?: string,
  vmNetworkInterfaces?: VmNicTarget[],
): SelectOption<string>[] {
  return vmNetworkInterfaces
    ? vmNetworkInterfaces?.reduce(
        (options: SelectOption<string>[], internalTarget) => {
          internalTarget.ipAddress &&
            internalTarget.available &&
            internalTarget.vm.name === selectedVm &&
            options.push({
              name: internalTarget.ipAddress,
              value: internalTarget.vm.networkInterface.uid as string,
            });
          return options;
        },
        [PLEASE_SELECT_OPTION],
      )
    : [PLEASE_SELECT_OPTION];
}
