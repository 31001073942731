import React, { ReactElement } from 'react';
import { SelectOption } from 'dcloud-shared-ui';
import { ExternalDnsAddFormWithAssetsContainer } from '../ExternalDnsAddform/withDnsAssetsContainer';
import { isUserPermitted } from '../../../../../../auth/components/UserRoleGuard/utils';
import { ExternalDnsAddFormContainer } from '../ExternalDnsAddform/container';

export type ExternalDnsAddFormContainersHelperProps = {
  isLoading: boolean;
  publicNatRuleOptions: SelectOption<string>[];
  userRoles: UserRole[] | undefined;
};

export const ExternalDnsAddFormContainersHelper = ({
  isLoading,
  publicNatRuleOptions,
  userRoles,
}: ExternalDnsAddFormContainersHelperProps): ReactElement => {
  if (!isLoading && publicNatRuleOptions.length > 1) {
    if (isUserPermitted(userRoles || [], ['ROLE_ADMIN', 'ROLE_DEVELOPER'])) {
      return (
        <ExternalDnsAddFormWithAssetsContainer
          natTargets={publicNatRuleOptions}
        />
      );
    } else {
      return <ExternalDnsAddFormContainer natTargets={publicNatRuleOptions} />;
    }
  } else {
    return <></>;
  }
};
