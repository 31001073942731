import { ICellRendererParams } from 'ag-grid-community';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';
import { sessionApi } from '../../../../../../../redux/views/session-view/session/api/api';
import { sessionInventorySelectors } from '../../../../../../../redux/views/session-view/session-inventory/slice';
import { GuestOsCellRenderer } from '.';

type GuestOsCellRendererContainerOwnProps = Pick<ICellRendererParams, 'data'>;

const mapStateToProps = (
  state: RootState,
  ownProps: GuestOsCellRendererContainerOwnProps,
) => {
  const {
    data: { sessionUid, versionUid, mor },
  }: { data: SessionInventoryVirtualMachineData } = ownProps;

  const vmStatusResult =
    sessionApi.endpoints.getSessionVmStatusForSessionInventory.select({
      mor,
      sessionUid,
      versionUid,
    })(state);

  const { data, status } = vmStatusResult;

  return {
    guestOsValue: data?.vmwareState.guestState,
    isLoadingAllStatuses:
      sessionInventorySelectors.getIsCallingVmStatusesAPICalls(state),
    loadingStatus: status as string,
  };
};

export const GuestOsCellRendererContainer =
  connect(mapStateToProps)(GuestOsCellRenderer);

export type GuestOsCellRendererProps = ReturnType<typeof mapStateToProps>;
