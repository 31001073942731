import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from './VirtualMachinesTableActions.module.css';

export interface VirtualMachinesTableActionsProps {
  onDeleteClick: VoidFunction;
  virtualMachine: VirtualMachine;
}

export function VirtualMachinesTableActions({
  onDeleteClick,
  virtualMachine,
}: VirtualMachinesTableActionsProps): ReactElement {
  const { url } = useRouteMatch();

  return (
    <ul aria-label="Card actions" className={styles.root}>
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={onDeleteClick}
          testId="vms-table-actions-delete"
        >
          <Icon name="delete" />
        </Button>
      </li>
      <li className={styles.action}>
        <Link
          to={`${url}/${virtualMachine.uid}`}
          className={styles.linkAction}
          data-testid="vms-table-actions-edit"
        >
          <Icon name="edit" />
        </Link>
      </li>
    </ul>
  );
}
