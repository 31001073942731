import React, { ReactElement, HTMLAttributes } from 'react';
import { SIZES, WEIGHTS, HEADING_LEVELS } from '../../constants/font';

type TextSizes = typeof SIZES[number];
type TextWeights = typeof WEIGHTS[number];
type HeadingLevels = typeof HEADING_LEVELS[number];

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level: HeadingLevels;
  headingText: string;
  size?: TextSizes;
  weight?: TextWeights;
  align?: 'left' | 'center' | 'right';
}

export const DEFAULT_PROPS: Partial<HeadingProps> = {
  align: 'center',
  size: '64',
  weight: '300',
};

export function Heading({
  level: HeadingLevel,
  headingText,
  size = DEFAULT_PROPS.size,
  weight = DEFAULT_PROPS.weight,
  align = DEFAULT_PROPS.align,
}: HeadingProps): ReactElement {
  return (
    <HeadingLevel
      className={`text-size-${size} text-weight-${weight} text-${align}`}
    >
      {headingText}
    </HeadingLevel>
  );
}
