import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  reset,
  networksSelectors,
  setNetworkToDelete,
  setShowAddModal,
  setNetworkToEdit,
} from '../../../../redux/configuration/networks/slice';
import { RootState } from '../../../../redux/store';
import {
  useGetInventoryNetworksQuery,
  useGetNetworksQuery,
} from '../../../../redux/configuration/networks/api';
import {
  getAvailableInventoryNetworksByType,
  getMaxNumNetworksReached,
  getNetworkInventoryIdsByType,
} from './utils';
import { Networks } from '.';

function NetworksContainer({
  networkToEdit,
  networkToDelete,
  showAddModal,
  reset,
  setNetworkToDelete,
  setNetworkToEdit,
  setShowAddModal,
}: NetworksContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    data: inventoryNetworks = [],
    isFetching: isFetchingInventoryNetworks,
    isLoading: isLoadingInventoryNetworks,
  } = useGetInventoryNetworksQuery(topologyUid);

  const {
    data: networks = [],
    isLoading: isLoadingNetworks,
    isFetching: isFetchingNetworks,
    maxNumNetworksReached,
  } = useGetNetworksQuery(topologyUid, {
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      data,
      isFetching,
      isLoading,
      maxNumNetworksReached: getMaxNumNetworksReached(
        getAvailableInventoryNetworksByType(
          getNetworkInventoryIdsByType(data || [], 'ROUTED'),
          'ROUTED',
          inventoryNetworks || [],
        ),
        getAvailableInventoryNetworksByType(
          getNetworkInventoryIdsByType(data || [], 'UNROUTED'),
          'UNROUTED',
          inventoryNetworks || [],
        ),
      ),
    }),
  });

  return (
    <Networks
      loading={
        isLoadingNetworks ||
        isLoadingInventoryNetworks ||
        isFetchingNetworks ||
        isFetchingInventoryNetworks
      }
      maxNumNetworksReached={maxNumNetworksReached}
      networkToEdit={networkToEdit}
      networkToDelete={networkToDelete}
      networks={networks}
      reset={reset}
      setNetworkToDelete={setNetworkToDelete}
      setNetworkToEdit={setNetworkToEdit}
      setShowAddModal={setShowAddModal}
      showAddModal={showAddModal}
    />
  );
}

export type NetworksContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type NetworksRTKProps = {
  loading: boolean;
  networks: Network[];
  maxNumNetworksReached: boolean;
};

export type NetworksProps = NetworksContainerProps & NetworksRTKProps;

const mapStateToProps = (state: RootState) => ({
  networkToDelete: networksSelectors.getNetworkToDelete(state),
  networkToEdit: networksSelectors.getNetworkToEdit(state),
  showAddModal: networksSelectors.getShowAddModal(state),
});

const mapDispatchToProps = {
  reset,
  setNetworkToDelete,
  setNetworkToEdit,
  setShowAddModal,
};

export const ConnectedNetworks = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NetworksContainer);
