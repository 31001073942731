import React, { ChangeEvent, ReactElement } from 'react';
import { Checkbox } from 'dcloud-shared-ui';
import classNames from 'classnames';
import { Dropdown } from '../../../../../components/Dropdown';
import { ResetPowerDropdownItemContainer } from '../SessionInventoryHardware/components/ResetPowerDropdownItem/container';
import { MultiSelectDropDownVmContainer } from '../MultiSelectDropDownVm/container';
import { SelectAllCellRendererProps } from './container';
import styles from './SellectAllCellRenderer.module.css';

export const SelectAllCellRenderer = ({
  setIsSelectAllHwChecked,
  selectedItemsToAction,
  setIsSelectAllVmChecked,
  isDisabled,
  isSelectAllHwChecked,
  isSelectAllVmChecked,
  page,
}: SelectAllCellRendererProps): ReactElement => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? page === 'hardware'
        ? setIsSelectAllHwChecked(true)
        : setIsSelectAllVmChecked(true)
      : page === 'hardware'
      ? setIsSelectAllHwChecked(false)
      : setIsSelectAllVmChecked(false);
  };

  return (
    <div className={classNames('base-padding', styles.root)}>
      <div className={styles.checkbox}>
        <Checkbox
          data-testid="session-inventory-select-all-checkbox"
          onChange={handleOnChange}
          checked={
            page === 'hardware' ? isSelectAllHwChecked : isSelectAllVmChecked
          }
          disabled={isDisabled}
        />
      </div>
      <div>
        <>
          {selectedItemsToAction.length > 0 && page === 'hardware' && (
            <Dropdown
              testId="multi-select-dropdown-button"
              isDisabled={isDisabled}
            >
              <ResetPowerDropdownItemContainer />
            </Dropdown>
          )}

          {selectedItemsToAction.length > 0 && page === 'vm' && (
            <MultiSelectDropDownVmContainer />
          )}
        </>
      </div>
    </div>
  );
};
