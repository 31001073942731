import { Alert, SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Control, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../components';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormWrapper } from '../../../../../../../components/FormWrapper';
import { SrvTable } from './SrvTable';
import styles from './SrvAddForm.module.css';
interface SrvFormProps {
  srvRecords: SrvRecord[];
  control: Control<SrvRecord>;
  errors: UseFormMethods['errors'];
  handleSubmit: UseFormMethods['handleSubmit'];
  formState: UseFormMethods['formState'];
  handleCreate: SubmitHandler<SrvRecord>;
  protocolOptions: SelectOption<string>[];
  onDeleteSrv: (srvRecord: SrvRecord) => void;
}

export function SrvForm({
  srvRecords,
  errors,
  handleCreate,
  formState,
  handleSubmit,
  onDeleteSrv,
  protocolOptions,
  control,
}: SrvFormProps): ReactElement {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <FormWrapper
            aria-label="Add SRV form"
            className={styles.root}
            onSubmit={handleSubmit(handleCreate)}
          >
            <ControlledTextfield
              control={control}
              label={t('traffic.externalDns.edit.srv.table.headings.service')}
              name="service"
              required={true}
              error={errors?.service?.message}
              className={styles.service}
            />
            <ControlledSelect
              control={control}
              label={t('traffic.externalDns.edit.srv.table.headings.protocol')}
              name="protocol"
              required={true}
              error={errors?.protocol?.message}
              options={protocolOptions}
              className={styles.protocol}
            />
            <ControlledTextfield
              control={control}
              label={t('traffic.externalDns.edit.srv.table.headings.port')}
              name="port"
              required={true}
              error={errors?.port?.message}
              className={styles.port}
              isNumber={true}
            />
            <PrimaryButton disabled={!isDirty || !isValid} type="submit">
              {t('traffic.externalDns.edit.srv.addButton')}
            </PrimaryButton>
          </FormWrapper>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {srvRecords.length ? (
            <SrvTable
              srvRecords={srvRecords || []}
              isError={false}
              isLoading={false}
              onDeleteClick={onDeleteSrv}
            />
          ) : (
            <Alert colour="info">
              {t('traffic.externalDns.edit.srv.empty')}
            </Alert>
          )}
        </div>
      </div>
    </>
  );
}
