import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const GENERAL_HELP_URL =
  'https://dcloud-docs.cisco.com/c/r/dcloud-docs/sites/en_us/dcloud-help.html';

export function AdditionalResources(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <p className="base-margin-bottom text-weight-700">
        {t('sessions.panels.documentation.additionalResources.generalHelp')}
      </p>
      <a href={GENERAL_HELP_URL} target="_blank" rel="noreferrer noopener">
        {GENERAL_HELP_URL}
      </a>
    </>
  );
}
