import React, { ReactElement, useEffect } from 'react';
import { NavigationTabs } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import i18n from '../../../../i18n';
import { MainRoutes } from '../../../../constants/routes';
import { useScrollToTop } from '../../../../hooks/use-scroll-to-top';
import styles from './manageTemplates.module.css';
import { routes, TemplateManagerRouter } from './TemplateManagerRouter';

export function TemplateManager(): ReactElement {
  const { t } = useTranslation();
  const classes = classnames('text-center', styles.header);

  useScrollToTop();

  useEffect(() => {
    document.title = t('siteTitle');
  }, [t]);

  return (
    <>
      <section className={classes}>
        <h1 className="display-1">{i18n.t('templateManager.title')}</h1>
      </section>
      <div className={styles.root}>
        <div className="dbl-padding-bottom half-padding">
          <NavigationTabs
            tabs={routes.map((tab) => ({
              ...tab,
              pathname: `${MainRoutes.Templates}${tab.pathname}`,
            }))}
          />
        </div>
        <TemplateManagerRouter />
      </div>
    </>
  );
}
