import React, { ReactElement } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { TemplatesTableInfoModalContainer } from '../../../../components/VirtualMachineEntities/Add/components/TemplateTabContent/components/TemplatesTableInfoModal/container';
import { TemplatesTableContainer } from '../../../../components/VirtualMachineEntities/Add/components/TemplateTabContent/components/TemplatesTable/container';

interface TemplatesTabContentProps {
  templateVms: InventoryVirtualMachine[];
  isFetching: boolean;
  setShowMoreInfoForVmTemplate: ActionCreatorWithPayload<
    InventoryVirtualMachine | undefined,
    string
  >;
  topologyRemoteAccessCount: number;
  setShowRemoteAccessCountExceededModal: ActionCreatorWithPayload<
    boolean,
    string
  >;
  isDiagramView?: boolean;
}

export function TemplatesTabContent({
  templateVms,
  setShowMoreInfoForVmTemplate,
  topologyRemoteAccessCount,
  setShowRemoteAccessCountExceededModal,
  isDiagramView = false,
}: TemplatesTabContentProps): ReactElement {
  return (
    <>
      <TemplatesTableContainer
        templateVms={templateVms}
        loading={false}
        setShowMoreInfoForVmTemplate={setShowMoreInfoForVmTemplate}
        topologyRemoteAccessCount={topologyRemoteAccessCount}
        setShowRemoteAccessCountExceededModal={
          setShowRemoteAccessCountExceededModal
        }
        isDiagramView={isDiagramView}
      />
      <TemplatesTableInfoModalContainer />
    </>
  );
}
