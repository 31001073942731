import { ICellRendererParams } from 'ag-grid-community';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';
import { sessionApi } from '../../../../../../../redux/views/session-view/session/api/api';
import { sessionInventorySelectors } from '../../../../../../../redux/views/session-view/session-inventory/slice';
import { VmWareToolsCellRenderer } from '.';

type VmWareToolCellRendererContainerOwnProps = Pick<
  ICellRendererParams,
  'data'
>;

const mapStateToProps = (
  state: RootState,
  ownProps: VmWareToolCellRendererContainerOwnProps,
) => {
  const {
    data: { sessionUid, versionUid, mor },
  }: { data: SessionInventoryVirtualMachineData } = ownProps;

  const vmStatusResult =
    sessionApi.endpoints.getSessionVmStatusForSessionInventory.select({
      mor,
      sessionUid,
      versionUid,
    })(state);

  const { data, status } = vmStatusResult;

  return {
    isLoadingAllStatuses:
      sessionInventorySelectors.getIsCallingVmStatusesAPICalls(state),
    loadingStatus: status as string,
    vmWareToolsValue: data?.vmwareState.guestToolsState,
  };
};

export const VmWareToolCellRendererContainer = connect(mapStateToProps)(
  VmWareToolsCellRenderer,
);

export type VmWareToolsCellRendererProps = ReturnType<typeof mapStateToProps>;
