import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetNetworksQuery } from '../../../../../../redux/configuration/networks/api';
import {
  virtualMachinesSelectors,
  setDemoVirtualMachineToConfigure,
} from '../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../redux/store';
import {
  topologyLimitsSelectors,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
} from '../../../../../../redux/configuration/topologyLimits/slice';
import { handleAddVmFromDemosTabFromDiagram } from '../../../../../../redux/diagram/slice';
import { topologyLimitsApi } from '../../../../../../redux/configuration/topologyLimits/api';
import { useGetVmsQuery } from '../../../../../../redux/configuration/virtual-machine-entities/api';
import { TableLoader } from '../../../../../../components/Table/TableLoader';
import { DemoVirtualMachinesConfigure } from '.';

function DemoVirtualMachinesConfigureContainer({
  vm,
  setDemoVirtualMachineToConfigure,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
  topologyLimitsWarningAccepted,
  isDiagramView,
  backButtonOverride,
  handleAddVmFromDemosTabFromDiagram,
}: DemoVirtualMachinesConfigureContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data: networks = [], isLoading: isLoadingNetworks } =
    useGetNetworksQuery(topologyUid);

  const { topologyLimits } =
    topologyLimitsApi.endpoints.getTopologyLimits.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        topologyLimits: data,
      }),
    });

  const { data: vms = [], isLoading: isLoadingVms } =
    useGetVmsQuery(topologyUid);

  if (isLoadingVms || isLoadingNetworks || !vm) {
    return <TableLoader />;
  }

  return (
    <DemoVirtualMachinesConfigure
      handleAddVmFromDemosTabFromDiagram={handleAddVmFromDemosTabFromDiagram}
      backButtonOverride={backButtonOverride}
      isDiagramView={isDiagramView}
      setDemoVirtualMachineToConfigure={setDemoVirtualMachineToConfigure}
      vm={vm}
      networks={networks}
      setShowTopologyLimitWarningForEntity={
        setShowTopologyLimitWarningForEntity
      }
      resetTopologyLimits={resetTopologyLimits}
      topologyLimitsWarningAccepted={topologyLimitsWarningAccepted}
      topologyLimits={topologyLimits}
      vms={vms}
    />
  );
}

type ConnectedDemoConfigureOwnProps = {
  isDiagramView: boolean;
  backButtonOverride?: VoidFunction;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedDemoConfigureOwnProps,
) => ({
  backButtonOverride: ownProps.backButtonOverride,
  isDiagramView: ownProps.isDiagramView,
  topologyLimitsWarningAccepted:
    topologyLimitsSelectors.getTopologyLimitsWarningAccepted(state),
  vm: virtualMachinesSelectors.getDemoVirtualMachineToConfigure(state),
});

const mapDispatchToProps = {
  handleAddVmFromDemosTabFromDiagram,
  resetTopologyLimits,
  setDemoVirtualMachineToConfigure,
  setShowTopologyLimitWarningForEntity,
};

type DemoVirtualMachinesConfigureRTKProps = {
  networks: Network[];
  topologyLimits?: TopologyLimits;
  vms?: VirtualMachine[];
};

type DemoVirtualMachinesConfigureContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export type DemoVirtualMachinesConfigureProps =
  DemoVirtualMachinesConfigureContainerProps &
    DemoVirtualMachinesConfigureRTKProps;

export const ConnectedDemoConfigure = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DemoVirtualMachinesConfigureContainer);
