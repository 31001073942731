import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../redux/views/session-view/session/api/api';
import { AnyConnect } from '.';

export function ConnectedDCloudAnyConnect({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const { anyConnectNode, isFetching, isLoading, isError } =
    sessionApi.endpoints.getSession.useQueryState(
      {
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          anyConnectNode: data?.sessionDetails.anyConnect,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <AnyConnect
      anyConnectNode={anyConnectNode}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
