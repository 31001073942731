import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import {
  setSelectedIconToEdit,
  setSelectedIcon,
} from '../../../../../../redux/diagram/slice';
import { NodeIconEditButton } from '.';

const mapDispatchToProps = {
  setSelectedIcon,
  setSelectedIconToEdit,
};

export type NodeIconEditButtonContainerOwnProps = {
  iconToEdit: DiagramIconToEditActionPayload;
};

const mapStateToProps = (
  state: RootState,
  { iconToEdit }: NodeIconEditButtonContainerOwnProps,
) => ({
  iconToEdit,
});

export type NodeIconEditButtonProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const NodeIconEditButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NodeIconEditButton);
