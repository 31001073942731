import {
  EXTERNAL_DNS,
  SRV_PROTOCOLS,
  TOPOLOGIES_PATH,
} from '../../../../api/paths';
import i18n from '../../../../i18n';
import { dcloudApi } from '../../../api';
import { TAGS } from '../../../api/constants';

export const externalDnsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    addExternalDnsRecord: build.mutation<
      ExternalDnsRecord,
      ExternalDnsPostPayload
    >({
      invalidatesTags: [TAGS.EXTERNAL_DNS],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('traffic.externalDns.add.error'),
        method: 'post',
        successMessage: i18n.t('traffic.externalDns.add.success'),
        url: `${EXTERNAL_DNS}`,
      }),
    }),
    deleteExternalDnsRecord: build.mutation<undefined, string>({
      invalidatesTags: [TAGS.EXTERNAL_DNS],
      query: (uid) => ({
        errorMessage: i18n.t('traffic.externalDns.delete.error'),
        method: 'DELETE',
        successMessage: i18n.t('traffic.externalDns.delete.success'),
        url: `${EXTERNAL_DNS}/${uid}`,
      }),
    }),
    getExternalDnsItem: build.query<ExternalDnsRecord, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.EXTERNAL_DNS],
      query: (uid) => ({
        errorMessage: i18n.t('traffic.externalDns.fetch.error'),
        method: 'get',
        url: `${EXTERNAL_DNS}/${uid}`,
      }),
    }),
    getExternalDnsRecords: build.query<FetchExternalDnsResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.EXTERNAL_DNS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('traffic.externalDns.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${EXTERNAL_DNS}`,
      }),
    }),
    getSrvProtocol: build.query<FetchSrvProtocolsResponse, void>({
      providesTags: [TAGS.SRV_PROTOCOLS],
      query: () => ({
        errorMessage: i18n.t('traffic.externalDns.srv.fetch.error'),
        method: 'GET',
        url: `${SRV_PROTOCOLS}`,
      }),
    }),
    updateExternalDnsRecord: build.mutation<
      ExternalDnsRecord,
      UpdateExternalDnsPayload
    >({
      invalidatesTags: [TAGS.EXTERNAL_DNS, TAGS.VIRTUAL_MACHINE_TARGETS],
      query: (externalDnsRecord) => ({
        data: externalDnsRecord,
        errorMessage: i18n.t('traffic.externalDns.update.error'),
        method: 'PUT',
        successMessage: i18n.t('traffic.externalDns.update.success'),
        url: `${EXTERNAL_DNS}/${externalDnsRecord.uid}`,
      }),
    }),
  }),
});

export const {
  useAddExternalDnsRecordMutation,
  useGetExternalDnsRecordsQuery,
  useGetSrvProtocolQuery,
  useDeleteExternalDnsRecordMutation,
  useUpdateExternalDnsRecordMutation,
  useGetExternalDnsItemQuery,
} = externalDnsApi;
