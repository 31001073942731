import React, { ReactElement, useRef } from 'react';
import ContentLoader from 'react-content-loader';
import * as d3 from 'd3';
import { Node } from '../../../Node';
import { useSkeletonColours } from '../../../../../../hooks/use-skeleton-colours';
import styles from './DiagramLineSkeletonLoader.module.css';
import { usePosition } from './hooks/use-position';

export function DiagramLineSkeletonLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  const nodeRef = useRef<HTMLDivElement>(null);
  usePosition({
    nodeRef,
    uid: 'SKELETON_LOADER',
  });
  const transform = d3.zoomTransform(
    d3.select<HTMLDivElement, unknown>('#canvas').node()!,
  );
  return (
    <Node
      ref={nodeRef}
      uid="loader"
      isReadOnly={true}
      className={styles.skeletonLoader}
    >
      <ContentLoader
        className="half-margin-top"
        speed={0.7}
        width={220 / transform.k}
        height={15}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="-5" width="2200" height="15" />
      </ContentLoader>
    </Node>
  );
}
