import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../constants';
import { searchAndFilterIntegrations } from './utils';

interface IntegrationsFiltersState {
  filteredIntegrations: Integration[];
  showFilters: IntegrationType[];
  integrations: Integration[];
  searchValue: string;
}

export const initialState: IntegrationsFiltersState = {
  filteredIntegrations: [],
  integrations: [],
  searchValue: '',
  showFilters: [PUBLIC_CLOUD_INTEGRATION_TYPE, SAAS_INTEGRATION_TYPE],
};

export const integrationsFiltersSlice = createSlice({
  initialState,
  name: 'integrationsFilters',
  reducers: {
    reset: () => initialState,
    setIntegrations: (state, action: PayloadAction<Integration[]>) => {
      state.integrations = action.payload;
      state.filteredIntegrations = action.payload;
    },
    setPublicCloudFilter: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        if (!state.showFilters.includes(PUBLIC_CLOUD_INTEGRATION_TYPE)) {
          state.showFilters.push(PUBLIC_CLOUD_INTEGRATION_TYPE);
        }
      } else {
        state.showFilters = state.showFilters.filter(
          (showFilter) => showFilter !== PUBLIC_CLOUD_INTEGRATION_TYPE,
        );
      }
      state.filteredIntegrations = searchAndFilterIntegrations(
        state.integrations,
        state.searchValue,
        state.showFilters,
      );
    },
    setSaasFilter: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        if (!state.showFilters.includes(SAAS_INTEGRATION_TYPE)) {
          state.showFilters.push(SAAS_INTEGRATION_TYPE);
        }
      } else {
        state.showFilters = state.showFilters.filter(
          (showFilter) => showFilter !== SAAS_INTEGRATION_TYPE,
        );
      }
      state.filteredIntegrations = searchAndFilterIntegrations(
        state.integrations,
        state.searchValue,
        state.showFilters,
      );
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      const newIntegrations = searchAndFilterIntegrations(
        state.integrations,
        state.searchValue,
        state.showFilters,
      );
      state.filteredIntegrations = newIntegrations;
    },
  },
});

export const {
  reducer: integrationsFiltersReducer,
  actions: {
    reset,
    setSearchValue,
    setIntegrations,
    setSaasFilter,
    setPublicCloudFilter,
  },
} = integrationsFiltersSlice;

const getIntegrationsFilters = (state: RootState) => state.integrationsFilters;

export const integrationsFiltersSelectors = {
  getFilteredIntegrations: createSelector(
    getIntegrationsFilters,
    ({ filteredIntegrations }) => filteredIntegrations,
  ),
  getIntegrations: createSelector(
    getIntegrationsFilters,
    ({ integrations }) => integrations,
  ),
  getSearchValue: createSelector(
    getIntegrationsFilters,
    ({ searchValue }) => searchValue,
  ),
};
