import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setLeftPanelType,
  setSharedWithUsers,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { LeftPanel } from '.';

const mapDispatchToProps = {
  setLeftPanelType,
  setSharedWithUsers,
};

const mapStateToProps = (state: RootState) => ({
  leftPanelType: sessionViewSelectors.getLeftPanelType(state),
});

export type LeftPanelProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const LeftPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeftPanel);
