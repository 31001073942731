import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { AuthStatusValues } from '../..';
import { FullScreenSpinner } from '../../../components/Loaders/FullScreenSpinner';
import { AuthenticatedGuardProps } from './container';

export const AuthenticatedGuard = ({
  authStatus,
  startAuth,
  children,
}: PropsWithChildren<AuthenticatedGuardProps>): ReactElement => {
  useEffect(() => {
    if (authStatus === AuthStatusValues.NotAuthenticated) {
      startAuth();
    }
  }, [startAuth, authStatus]);

  return (
    <>
      {authStatus === AuthStatusValues.NotAuthenticated ? (
        <FullScreenSpinner />
      ) : (
        children
      )}
    </>
  );
};
