import React, { ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  ColDef,
  EditableCallbackParams,
  GridApi,
  ICellRendererParams,
  RowEditingStoppedEvent,
  RowEvent,
} from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { GridEditor } from '../../../../../../../components/GridEditor';
import { ConfirmModal, Modal } from '../../../../../../../components';
import {
  MaxLengths,
  SCENARIO_INTERNAL_NAME_REGEX,
} from '../../../../../../../validation';
import { mergeGridData } from '../../../../../../../components/GridEditor/utils/util';
import styles from './ScenariosTable.module.css';

type ScenariosTableProps = {
  scenariosToSave: Scenarios;
  setScenariosToSave: (scenariosToSave: Scenarios) => void;
  setIsEditingScenarioOption: (isEditingScenarioOption: boolean) => void;
};

export function ScenariosTable({
  scenariosToSave,
  setScenariosToSave,
  setIsEditingScenarioOption,
}: ScenariosTableProps): ReactElement {
  const [editingRowIndex, setEditingRowIndex] = useState<
    number | undefined | null
  >(undefined);
  const { t } = useTranslation();
  const [deletingRowIndex, setDeletingRowIndex] = useState<number | undefined>(
    undefined,
  );
  const [internalName, setInternalName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [gridApi, setGridApi] = useState<GridApi>();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  useEffect(() => {
    if (editingRowIndex !== undefined && editingRowIndex !== null) {
      gridApi?.redrawRows();
      setTimeout(() => {
        gridApi?.startEditingCell({
          colKey: 'internalName',
          rowIndex: editingRowIndex,
        });
      }, 1);
    }
  }, [editingRowIndex, gridApi]);
  const updateScenarioOptions = (
    internalName: string,
    displayName: string,
    rowIndex: number | undefined | null,
  ) => {
    if (rowIndex !== undefined && rowIndex !== null) {
      setScenariosToSave({
        ...scenariosToSave,
        scenarioOptions: mergeGridData(
          scenariosToSave.scenarioOptions!,
          {
            displayName,
            internalName,
          },
          rowIndex,
        ) as ScenarioOption[],
      });
    }
  };
  const onDisplayNameKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      gridApi?.stopEditing(false);
    }
  };
  const editable = (cell: EditableCallbackParams) =>
    cell.node.rowIndex === editingRowIndex && !!cell.colDef.field;
  const columns: ColDef[] = [
    {
      cellClassRules: {
        'cell-fail': (params: { value: string }) => !params.value,
      },
      cellEditorParams: {
        autoFocus: true,
        maxLength: MaxLengths.ScenarioOption,
        onChange: setInternalName,
        regex: SCENARIO_INTERNAL_NAME_REGEX,
      },
      editable,
      field: 'internalName',
      headerName: t('scenarios.scenarioName'),
      valueSetter: () => true,
      width: 400,
    },
    {
      cellClassRules: {
        'cell-fail': (params: { value: string }) => !params.value,
      },
      cellEditorParams: {
        autoFocus: false,
        maxLength: MaxLengths.ScenarioOption,
        onChange: setDisplayName,
        onKeyUp: onDisplayNameKeyUp,
      },
      editable,
      field: 'displayName',
      valueSetter: () => true,
      width: 400,
    },
  ];
  const onRowSaveClick = (row: RowEditingStoppedEvent) => {
    if (editingRowIndex === row.rowIndex) {
      updateScenarioOptions(internalName, displayName, editingRowIndex);
      setInternalName('');
      setDisplayName('');
      setEditingRowIndex(undefined);
      setIsEditingScenarioOption(false);
    }
  };
  const onEditClick = (row: RowEvent) => {
    if (row.rowIndex !== undefined && row.rowIndex !== null) {
      setEditingRowIndex(row.rowIndex);
      setInternalName(row.data.internalName);
      setDisplayName(row.data.displayName);
    }
    setIsEditingScenarioOption(true);
  };
  const onDeleteClick = (cell: ICellRendererParams) => {
    if (
      !scenariosToSave.scenarioOptions ||
      scenariosToSave.scenarioOptions.length < 2
    ) {
      setShowInfoModal(true);
    } else {
      setDeletingRowIndex(cell.rowIndex);
      setShowConfirmModal(true);
    }
  };
  const onGridReady = (gridApi: GridApi) => {
    setGridApi(gridApi);
  };
  const onInfoModalClose = () => {
    setShowInfoModal(false);
  };
  const onConfirmModalCancel = () => {
    setDeletingRowIndex(undefined);
    setShowConfirmModal(false);
  };
  const onDeleteConfirm = () => {
    let n = 0;
    const updatedScenarios = {
      ...scenariosToSave,
      scenarioOptions: scenariosToSave.scenarioOptions?.filter(
        () => n++ !== deletingRowIndex,
      ),
    };
    setScenariosToSave(updatedScenarios);
    setEditingRowIndex(undefined);
    setInternalName('');
    setDisplayName('');
    setIsEditingScenarioOption(false);
    setDeletingRowIndex(undefined);
    setShowConfirmModal(false);
  };
  return (
    <>
      <GridEditor
        columns={columns}
        data={scenariosToSave.scenarioOptions!}
        onGridReady={onGridReady}
        className={classnames('base-padding-left', styles.scenarioOptionsTable)}
        editingRowIndex={editingRowIndex}
        onEditClick={scenariosToSave.enabled ? onEditClick : undefined}
        onDeleteClick={scenariosToSave.enabled ? onDeleteClick : undefined}
        onRowSaveClick={onRowSaveClick}
        deleteAriaLabel={t('scenarios.delete.ariaLabel')}
        editAriaLabel={t('scenarios.edit.ariaLabel')}
        updateAriaLabel={t('scenarios.update.ariaLabel')}
      />
      <Modal
        onClose={onInfoModalClose}
        show={showInfoModal}
        title={t('scenarios.formErrors.minLimit.heading')}
        size="default"
        clickScreenToClose={true}
      >
        <div className="base-padding-top">
          {t('scenarios.formErrors.minLimit.subHeading')}
        </div>
      </Modal>
      <ConfirmModal
        heading={t('scenarios.delete.confirm.heading')}
        subHeading={t('scenarios.delete.confirm.subHeading')}
        size={'small'}
        onClose={onConfirmModalCancel}
        show={showConfirmModal}
        loading={false}
        onCancel={onConfirmModalCancel}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}
