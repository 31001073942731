import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedExpoInfo } from './expoContainer';
import { ConnectedDCloudInfo } from './dCloudContainer';

export function Info({ nodeUid }: InfoContainerProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoInfo
        engagementUid={engagementUid}
        nodeUid={nodeUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudInfo
      nodeUid={nodeUid}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  nodeUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type InfoContainerProps = ReturnType<typeof mapStateToProps>;

type InfoRTKProps = {
  staticVmInfo: VmPanelStaticInfo | undefined;
  dynamicVmInfo: VmPanelDynamicInfo | undefined;
  isLoadingDynamicVmInfo: boolean;
};

export type InfoProps = InfoRTKProps;

export const InfoContainer = connect(mapStateToProps)(Info);
