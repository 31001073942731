import { connect } from 'react-redux';
import { setShowExtendSessionModal } from '../../../../../../../../redux/views/session-view/session/slice';
import { ExtendSessionForm } from '.';

const mapDispatchToProps = {
  setShowExtendSessionModal,
};

export type ExtendSessionFormProps = typeof mapDispatchToProps;

export const ExtendSessionFormContainer = connect(
  null,
  mapDispatchToProps,
)(ExtendSessionForm);
