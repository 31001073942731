import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { setIsSnapToGridEnabled } from '../../../../redux/diagram/slice';
import { RootState } from '../../../../redux/store';
import UserPreferencesModal from './UserPreferencesModal';

const UserPreferencesModalContainer = ({
  onClose,
  setIsSnapToGridEnabled,
}: DiagramContainerProps): ReactElement => {
  return (
    <UserPreferencesModal
      setIsSnapToGridEnabled={setIsSnapToGridEnabled}
      onClose={onClose}
    />
  );
};

const mapDispatchToProps = {
  setIsSnapToGridEnabled,
};

export type DiagramContainerProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export type UserPreferencesModalProps = DiagramContainerProps;

type UserPreferencesModalContainerOwnProps = {
  onClose: () => void;
};

const mapStateToProps = (
  _: RootState,
  ownProps: UserPreferencesModalContainerOwnProps,
) => ({
  onClose: ownProps.onClose,
});

const ConnectedUserPreferencesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPreferencesModalContainer);

export default ConnectedUserPreferencesModal;
