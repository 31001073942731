import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef } from 'ag-grid-community';
import { Alert } from 'dcloud-shared-ui';
import { GridEditor } from '../../../../../components/GridEditor';

type IntegrationsInUseInfoModalTableProps = {
  topologiesUsingIntegration?: Topology[];
};

export const IntegrationsInUseInfoModalTable = ({
  topologiesUsingIntegration,
}: IntegrationsInUseInfoModalTableProps): ReactElement => {
  const { t } = useTranslation();
  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'uid',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.uid'),
      minWidth: 250,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'name',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.name'),
      minWidth: 220,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'datacenter',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.datacenter'),
      minWidth: 120,
    },
    {
      autoHeight: true,
      cellStyle: { 'overflow-wrap': 'anywhere', 'white-space': 'normal' },
      field: 'owner',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.owner'),
      minWidth: 210,
    },
    {
      field: 'usedInCurrentVersion',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.inCurrentVersion'),
      minWidth: 150,
    },
    {
      field: 'usedInPreviousVersions',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.inPreviousVersions'),
      minWidth: 150,
    },
    {
      field: 'usedInDemo',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.inDemo'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'demoId',
      flex: 1,
      headerName: t('integrations.info.uses.table.headings.demoId'),
      minWidth: 120,
      valueGetter: (p) =>
        p.data.demoId ?? t('integrations.info.uses.table.values.notApplicable'),
    },
  ];
  return topologiesUsingIntegration && topologiesUsingIntegration.length > 0 ? (
    <GridEditor
      columns={columns}
      data={topologiesUsingIntegration!}
      customProps={{
        suppressNoRowsOverlay: true,
      }}
    />
  ) : (
    <Alert>{t('integrations.info.uses.noTopologies')}</Alert>
  );
};
