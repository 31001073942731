import React, { ReactElement } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { useGetNatRulesQuery } from '../../../../../redux/configuration/traffic/nat-rules/api';
import { RootState } from '../../../../../redux/store';
import {
  getNatRules,
  setNatRuleToDelete,
} from '../../../../../redux/configuration/traffic/nat-rules/slice';
import { NatRules } from '.';

export function NatRulesContainer({
  natRuleToDelete,
  setNatRuleToDelete,
}: NatRulesContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isError, isLoading } = useGetNatRulesQuery(topologyUid);

  return (
    <NatRules
      setNatRuleToDelete={setNatRuleToDelete}
      natRuleToDelete={natRuleToDelete}
      natRules={data?.natRules}
      isError={isError}
      isLoading={isLoading}
    />
  );
}

export type NatRulesContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export interface NatRulesRTKProps {
  natRules?: NatRule[];
  isError: boolean;
  isLoading: boolean;
}

export type NatRulesProps = NatRulesContainerProps & NatRulesRTKProps;

const mapStateToProps = (state: RootState) => ({
  natRuleToDelete: getNatRules(state).natRuleToDelete,
});

const mapDispatchToProps = {
  setNatRuleToDelete,
};

export const ConnectedNatRules = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NatRulesContainer);
