import { useCallback, useState, useEffect } from 'react';

export interface UseSelectedItem {
  selectedItem: string;
  toggleSelectedItem: (uid: string) => void;
}

export function useSelectedItem(): UseSelectedItem {
  const [selectedItem, setSelectedItem] = useState('');

  const toggleSelectedItem = useCallback((uid) => {
    setSelectedItem((prevItem) => (prevItem === uid ? '' : uid));
  }, []);

  const clickoutsideElement = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const element = document.querySelector(
        `[data-component="${selectedItem!}"]`,
      );

      if (
        element &&
        !element.contains(target) &&
        (event.target as HTMLElement).dataset.clickablearea === 'true'
      ) {
        setSelectedItem('');
      }
    },
    [selectedItem],
  );

  useEffect(() => {
    if (selectedItem) {
      document.addEventListener('click', clickoutsideElement);
    }

    return () => {
      document.removeEventListener('click', clickoutsideElement);
    };
  }, [clickoutsideElement, selectedItem]);

  return {
    selectedItem,
    toggleSelectedItem,
  };
}
