import { dcloudApi } from '../../api';
import {
  DATACENTERS_PATH,
  INVENTORY_TEMPLATE_TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { TAGS } from '../../api/constants';

export const templatesApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getTemplatesByDC: build.query<
      { inventoryTopologies: InventoryTopology[] },
      DataCenter
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DEMOS_BY_DC],
      query: (dcUid) => ({
        errorMessage: i18n.t('demos.fetch.errors.many'),
        method: 'get',
        url: `${DATACENTERS_PATH}/${dcUid.toLowerCase()}${INVENTORY_TEMPLATE_TOPOLOGIES_PATH}`,
      }),
    }),
  }),
});

export const { useGetTemplatesByDCQuery } = templatesApi;
