import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledTextfield,
} from '../../../../../../../../components';
import { parseChangeEventToNumber, parseNumberToString } from '../../utils';
import formStyles from '../../../../../EditFormStyles.module.css';
import { FORM_SECTION_CLASSES } from '../../../constants';
import styles from './GuestAutomationForm.module.css';

export function GuestAutomationForm(): ReactElement {
  const { control, errors, watch, setValue, trigger } = useFormContext();
  const { t } = useTranslation();
  const formSectionClasses = classnames(
    FORM_SECTION_CLASSES,
    styles.formSection,
  );
  const watchIsStartUpAutomationEnabled = watch('isGuestAutomationEnabled');
  const watchIsShutdownAutomationEnabled = watch('isShutdownAutomationEnabled');

  return (
    <>
      <div>
        <p className={formStyles.titlePadding}>
          {t('virtualMachines.edit.sessionXmlMessage')}
        </p>
      </div>
      <div className={classnames(formStyles.sectionPadding, styles.root)}>
        <div className="row col-12">
          <div className={formSectionClasses}>
            <div className="base-margin-bottom">
              <ControlledCheckbox
                control={control}
                id="isGuestAutomationEnabled"
                label={t('virtualMachines.edit.enableGuestAutomation')}
                customOnChange={(event) => {
                  setValue('isGuestAutomationEnabled', event.target.checked);
                  setValue('guestAutomation.delaySecs', 0);
                  trigger('guestAutomation.delaySecs');
                }}
                testId="edit-vm-enable-guest-automation"
              />
            </div>

            <div
              data-testid="startupInputs"
              className={watchIsStartUpAutomationEnabled ? '' : styles.hide}
            >
              <ControlledTextfield
                control={control}
                error={errors?.guestAutomation?.command?.message}
                label={t('virtualMachines.edit.command')}
                name="guestAutomation.command"
                testId="edit-vm-command"
              />
              <ControlledTextfield
                transform={{
                  input: parseNumberToString,
                  output: parseChangeEventToNumber,
                }}
                control={control}
                error={errors?.guestAutomation?.delaySecs?.message}
                label={t('virtualMachines.edit.delaySecs')}
                name="guestAutomation.delaySecs"
                required={false}
                testId="edit-vm-delaySecs"
                isNumber={true}
              />
            </div>
          </div>
          <div className={formSectionClasses}>
            <div className="base-margin-bottom">
              <ControlledCheckbox
                control={control}
                id="isShutdownAutomationEnabled"
                customOnChange={(event) => {
                  setValue('isShutdownAutomationEnabled', event.target.checked);
                  setValue('shutdownAutomation.delaySecs', 0);
                  trigger('shutdownAutomation.delaySecs');
                }}
                label={t('virtualMachines.edit.enableShutdownAutomation')}
                testId="edit-vm-enable-shutdown-automation"
              />
            </div>

            <div
              data-testid="shutdownInputs"
              className={watchIsShutdownAutomationEnabled ? '' : styles.hide}
            >
              <ControlledTextfield
                control={control}
                error={errors?.shutdownAutomation?.command?.message}
                label={t('virtualMachines.edit.command')}
                name="shutdownAutomation.command"
                testId="edit-vm-shutdown-command"
              />
              <ControlledTextfield
                transform={{
                  input: parseNumberToString,
                  output: parseChangeEventToNumber,
                }}
                control={control}
                error={errors?.shutdownAutomation?.delaySecs?.message}
                label={t('virtualMachines.edit.delayShutdownSecs')}
                name="shutdownAutomation.delaySecs"
                required={true}
                testId="edit-vm-shutdown-delaySecs"
                isNumber={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
