import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledSwitch } from '../../../../../../components/Form/ControlledSwitch';
import { ControlledTextfield } from '../../../../../../components';
import { FormUnsavedChangesPrompt } from '../../../../../../components/FormUnsavedChangesPrompt';
import { useLeaveFormPrompt } from '../../../../hooks/useLeaveFormPrompt';
import { scenarioFieldsSchema } from '../../../../../../validation';
import { PageHeadingWithCTA } from '../../../../components/PageHeadingWithCTA/PageHeadingWithCTA';
import { useErrorToast } from '../../../../../../hooks/toasts/use-error-toast';
import { hasDuplicateScenarioOptions } from '../../utils/scenariosUtils';
import { HelpButton } from '../../../../../../components/HelpButton';
import { SCENARIOS_HELP_LINKS } from '../../../../../../constants/user-guide';
import { ScenariosTable } from './ScenariosTable/ScenariosTable';
import styles from './ScenariosForm.module.css';
import { ScenarioOptionsForm } from './ScenarioOptionsForm';

interface ScenariosFormProps {
  originalScenarios?: Scenarios;
  scenariosToSave: Scenarios;
  setScenariosToSave: (scenarios?: Scenarios) => void;
  updateScenarios: (scenarios: ScenariosPayload) => void;
}

export function ScenariosForm({
  originalScenarios,
  scenariosToSave,
  setScenariosToSave,
  updateScenarios,
}: ScenariosFormProps): ReactElement {
  useEffect(() => {
    return () => {
      setScenariosToSave(undefined);
    };
  }, [setScenariosToSave]);
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<TopologyUid>();
  const [isEditingScenarioOption, setIsEditingScenarioOption] =
    useState<boolean>(false);
  const { showErrorToast } = useErrorToast();
  const formData = useForm<ScenarioFields>({
    defaultValues: {
      displayName: '',
      enabled: originalScenarios?.enabled || false,
      internalName: '',
      question: originalScenarios?.question || '',
    },
    mode: 'all',
    resolver: yupResolver(scenarioFieldsSchema),
  });
  const {
    control,
    errors,
    watch,
    formState: { dirtyFields, isValid },
  } = formData;
  const onSaveClick = () => {
    if (hasDuplicateScenarioOptions(scenariosToSave.scenarioOptions)) {
      showErrorToast(t('scenarios.formErrors.duplicate'));
    } else {
      const payload = {
        scenariosToSave: {
          enabled: scenariosToSave.enabled,
          question: control.getValues().question,
          scenarioOptions: scenariosToSave.scenarioOptions,
        },
        topologyUid,
      };
      updateScenarios(payload);
    }
  };
  const haveScenarioOptionChanged = !isEqual(
    originalScenarios?.scenarioOptions,
    scenariosToSave.scenarioOptions,
  );
  const hasFormChanged =
    haveScenarioOptionChanged ||
    dirtyFields.question === true ||
    dirtyFields.enabled === true;
  const scenarioOptionsExist =
    scenariosToSave.scenarioOptions &&
    scenariosToSave.scenarioOptions.length > 0;
  const isSaveDisabled =
    !isValid ||
    !hasFormChanged ||
    !scenarioOptionsExist ||
    !!scenariosToSave.scenarioOptions?.find(
      (scenario) => !scenario.displayName || !scenario.internalName,
    ) ||
    isEditingScenarioOption;
  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(hasFormChanged);
  const isFormVisible =
    scenariosToSave.enabled ||
    scenarioOptionsExist ||
    watch('question') ||
    watch('internalName') ||
    watch('displayName');
  const onSwitchToggle = (enabled: boolean) => {
    setScenariosToSave({ ...scenariosToSave, enabled });
  };
  const isFormDisabled = !scenariosToSave.enabled || isEditingScenarioOption;
  return (
    <>
      <div className="half-padding-left">
        <PageHeadingWithCTA
          buttonLabel={t('scenarios.save.buttonText')}
          onButtonClick={onSaveClick}
          disableButton={isSaveDisabled}
          pageTitle={t('scenarios.name')}
          buttonColor="success"
        />
      </div>
      <div className="base-padding">
        <ControlledSwitch
          id="enabled"
          testId="scenario-enabled-switch"
          label={t('scenarios.enabled')}
          control={control}
          labelPosition="right"
          onChange={onSwitchToggle}
          disabled={isEditingScenarioOption}
        />
      </div>
      <div className={!scenariosToSave.enabled ? styles.disabled : ''}>
        <div className={isFormVisible ? '' : styles.hidden}>
          <div className="base-padding">
            <ControlledTextfield
              disabled={isFormDisabled}
              control={control}
              label={t('scenarios.question')}
              className={styles.questionField}
              name="question"
              required={true}
              testId="scenarios-question-input"
              error={errors.question?.message}
            />
          </div>
          {scenariosToSave.enabled && (
            <ScenarioOptionsForm
              scenariosToSave={scenariosToSave}
              setScenariosToSave={setScenariosToSave}
              isEditingScenarioOption={isEditingScenarioOption}
              formData={formData}
            />
          )}
          {scenarioOptionsExist && (
            <ScenariosTable
              scenariosToSave={scenariosToSave}
              setScenariosToSave={setScenariosToSave}
              setIsEditingScenarioOption={setIsEditingScenarioOption}
            />
          )}
        </div>
      </div>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <HelpButton links={SCENARIOS_HELP_LINKS} />
    </>
  );
}
