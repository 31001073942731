import React, { ReactElement } from 'react';
import { Heading } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';

export type InternalIpHeaderProps = {
  isLoading: boolean;
  isError: boolean;
  sessionInternalAddresses?: SessionInternalAddress[];
};

export const InternalIpHeader = ({
  isLoading,
  isError,
  sessionInternalAddresses,
}: InternalIpHeaderProps): ReactElement => {
  const { t } = useTranslation();
  if (isLoading || isError || !sessionInternalAddresses?.length) return <></>;

  return (
    <Heading level="h2" align="left" size="18">
      {t('sessions.panels.info.publicIpProxy.internalIP')}
    </Heading>
  );
};
