import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './InboundProxyRulesTableActions.module.css';

export interface InboundProxyRulesTableActionsProps {
  onDeleteClick: VoidFunction;
}

export function InboundProxyRulesTableActions({
  onDeleteClick,
}: InboundProxyRulesTableActionsProps): ReactElement {
  return (
    <ul className={styles.root}>
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={onDeleteClick}
          testId="inbound-proxy-rules-table-actions-delete"
        >
          <Icon name="delete" />
        </Button>
      </li>
    </ul>
  );
}
