import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const IpAddressesTableNoDataAlert = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Alert>
      {t(
        'sessionsInventory.virtualMachines.ipAddressesTable.noDataToDisplayAlertText',
      )}
    </Alert>
  );
};
