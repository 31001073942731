import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetExternalDnsRecordsQuery } from '../../../../../redux/configuration/traffic/external-dns/api';
import {
  getExternalDns,
  setExternalDnsToDelete,
} from '../../../../../redux/configuration/traffic/external-dns/slice';
import { useGetNatRulesQuery } from '../../../../../redux/configuration/traffic/nat-rules/api';
import { RootState } from '../../../../../redux/store';
import { getCurrentUserState } from '../../../../../redux/configuration/currentUser/slice';
import { ExternalDnsLoader } from './ExternalDnsLoader';
import { ExternalDNS } from '.';

export function ExternalDnsContainer({
  externalDnsRecordToDelete,
  setExternalDnsToDelete,
  userRoles,
}: ExternalDnsContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isError, isLoading } =
    useGetExternalDnsRecordsQuery(topologyUid);
  const {
    data: natRules,
    isError: isNatError,
    isLoading: isLoadingNat,
  } = useGetNatRulesQuery(topologyUid);

  if (isLoading || isLoadingNat) {
    return <ExternalDnsLoader />;
  }

  return (
    <ExternalDNS
      externalDNSRecord={data?.externalDnsRecords}
      isError={isError || isNatError}
      isLoading={isLoading}
      natRules={natRules?.natRules}
      externalDnsRecordToDelete={externalDnsRecordToDelete}
      setExternalDnsToDelete={setExternalDnsToDelete}
      userRoles={userRoles}
    />
  );
}
export type ExternalDnsContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export interface ExternalDnsRTKProps {
  isError: boolean;
  isLoading: boolean;
  externalDNSRecord?: ExternalDnsRecord[];
  natRules?: NatRule[];
}

export type ExternalDnsProps = ExternalDnsContainerProps & ExternalDnsRTKProps;

const mapStateToProps = (state: RootState) => ({
  externalDnsRecordToDelete: getExternalDns(state).externalDnsRecordToDelete,
  userRoles: getCurrentUserState(state).userRoles,
});

const mapDispatchToProps = {
  setExternalDnsToDelete,
};

export const ConnectExternalDns = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalDnsContainer);
