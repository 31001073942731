import { useEffect } from 'react';
import * as d3 from 'd3';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { x, y } from '../../../d3';
import { HEADER_OFFSET_VAR } from '../../../constants';
import { calculateNewCoordinate } from '../../../utils';

interface UseResize {
  isReadOnly: boolean;
  display: DiagramLineDisplay;
  uid: string;
  nodeRef: React.RefObject<HTMLDivElement>;
  isSnapToGridEnabled?: boolean;
}

export function useResize({
  display,
  isReadOnly,
  nodeRef,
  uid,
  isSnapToGridEnabled = true,
}: UseResize): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly) return;

    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLSpanElement, DiagramDisplay>()
      .on('drag', function (event) {
        if (!currentRef) return;
        const [pointerX, pointerY] = d3.pointer(event, currentRef);
        const transform = d3.zoomTransform(this);
        const leftHandle = currentRef.childNodes[1]
          .childNodes[0] as HTMLDivElement;
        const rightHandle = currentRef.childNodes[1]
          .childNodes[1] as HTMLDivElement;
        const leftNodeBounds = leftHandle.getBoundingClientRect();
        const rightNodeBounds = rightHandle.getBoundingClientRect();
        const [headerHeightStr] = getComputedStyle(document.body)
          .getPropertyValue(HEADER_OFFSET_VAR)
          .split('px');
        const headerHeight = Number(headerHeightStr);
        const xMidLeftNode = leftNodeBounds.x + leftNodeBounds.width / 2;
        const yMidLeftNode =
          leftNodeBounds.y + leftNodeBounds.height / 2 - headerHeight;
        const xMidRightNode = rightNodeBounds.x + rightNodeBounds.width / 2;
        const yMidRightNode =
          rightNodeBounds.y + rightNodeBounds.height / 2 - headerHeight;
        const line = `[data-line-component-uid="${uid}"]`;
        switch (this.dataset.handle) {
          case 'left':
            d3.select<HTMLDivElement, DiagramDisplay>(line)
              .attr('x1', xMidLeftNode)
              .attr('y1', yMidLeftNode)
              .attr('x2', xMidRightNode)
              .attr('y2', yMidRightNode);
            d3.select(leftHandle).attr(
              'style',
              `transform: translate(${pointerX}px, ${pointerY}px) scale(${transform.k}`,
            );
            break;
          case 'right':
            d3.select<HTMLDivElement, DiagramDisplay>(line)
              .attr('x1', xMidLeftNode)
              .attr('y1', yMidLeftNode)
              .attr('x2', xMidRightNode)
              .attr('y2', yMidRightNode);
            d3.select(rightHandle).attr(
              'style',
              `transform: translate(${pointerX}px, ${pointerY}px) scale(${transform.k}`,
            );
            break;
        }
      })
      .on('end', function (event) {
        if (!currentRef) return;
        const transform = d3.zoomTransform(this);
        const leftHandleRectangle = (
          currentRef.childNodes[1].childNodes[0] as Element
        ).getBoundingClientRect();
        const rightHandleRectangle = (
          currentRef.childNodes[1].childNodes[1] as Element
        ).getBoundingClientRect();

        const isUserOverridingSnapToGrid = event.sourceEvent.altKey;

        switch (this.dataset.handle) {
          case 'left':
            const x1 = x.invert(transform.invertX(leftHandleRectangle.left));
            const y1 = y.invert(transform.invertY(leftHandleRectangle.top));

            updateNode({
              display: {
                ...(display || {}),
                x1: calculateNewCoordinate({
                  coordinate: x1,
                  isSnapToGridEnabled,
                  isUserOverridingSnapToGrid,
                }),
                y1: calculateNewCoordinate({
                  coordinate: y1,
                  isSnapToGridEnabled,
                  isUserOverridingSnapToGrid,
                }),
              },
              nodeUid: uid,
            });
            break;
          case 'right':
            const x2 = x.invert(transform.invertX(rightHandleRectangle.left));
            const y2 = y.invert(transform.invertY(rightHandleRectangle.top));

            updateNode({
              display: {
                ...(display || {}),
                x2: calculateNewCoordinate({
                  coordinate: x2,
                  isSnapToGridEnabled,
                  isUserOverridingSnapToGrid,
                }),
                y2: calculateNewCoordinate({
                  coordinate: y2,
                  isSnapToGridEnabled,
                  isUserOverridingSnapToGrid,
                }),
              },
              nodeUid: uid,
            });
            break;
        }
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .selectAll<HTMLSpanElement, DiagramDisplay>(`[data-handle]`)
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
          .selectAll<HTMLSpanElement, DiagramDisplay>(`[data-handle]`)
          .on('mousedown.drag', null);
      }
    };
  }, [display, isReadOnly, nodeRef, updateNode, uid, isSnapToGridEnabled]);
}
