import React, { ReactElement } from 'react';
import styles from './InfoModalColumn.module.css';

export interface InfoModalColumnProps {
  data: InfoModalDataItem[];
}

export function InfoModalColumn({ data }: InfoModalColumnProps): ReactElement {
  return (
    <div className={styles.root}>
      <ul className={styles.ul}>
        {data.map((item, index) => (
          <li key={index} className={styles.li}>
            {item.label && <span className={styles.label}>{item.label}</span>}
            <span className={styles.value}>{item.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
