import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import styles from './index.module.css';
import { NodeIconEditButtonProps } from './container';

export const NodeIconEditButton = ({
  setSelectedIcon,
  setSelectedIconToEdit,
  iconToEdit,
}: NodeIconEditButtonProps): ReactElement => {
  const handleOnClick = () => {
    setSelectedIcon(iconToEdit.display.icon);
    setSelectedIconToEdit(iconToEdit);
  };

  return (
    <div
      className={styles.root}
      onClick={handleOnClick}
      aria-label={`Edit ${iconToEdit.display.name} Icon`}
      role="button"
    >
      <Icon name="pencil" className={styles.icon} />
    </div>
  );
};
