import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { PageHeadingWithCTA } from '../../../../components/PageHeadingWithCTA/PageHeadingWithCTA';
import { useSkeletonColours } from '../../../../../../hooks/use-skeleton-colours';
import { TableLoader } from '../../../../../../components/Table/TableLoader';

export function ScenariosLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeadingWithCTA pageTitle={t('scenarios.name')} />
      <ContentLoader
        className="qtr-margin-top"
        speed={1.5}
        width="100%"
        height={150}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" width="30" height="24" />
        <rect x="50" y="0" width="100" height="24" />

        <rect x="0" y="60" width="540" height="30" />
      </ContentLoader>
      <TableLoader />
    </section>
  );
}
