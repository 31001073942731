import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import UserRoleGuardContainer from '../../../../auth/components/UserRoleGuard/container';
import { isUserPermitted } from '../../../../auth/components/UserRoleGuard/utils';
import { Tile } from './components/Tile';
import styles from './Tiles.module.css';
import { InactiveTile } from './components/InactiveTile';
import { ExternalLinkTile } from './components/ExternalLinkTile';
import { TILES } from './constants';
import { determineXlClassForTiles } from './utils';

type TilesProps = {
  userRoles: UserRole[];
};

export function Tiles({ userRoles }: TilesProps): ReactElement {
  const { t } = useTranslation();
  const renderTiles = () =>
    TILES.map((tile) => {
      const tileClassName = determineXlClassForTiles(TILES, userRoles);
      return tile.disabled ||
        !isUserPermitted(userRoles, tile.disabledWithoutRoles || []) ? (
        <InactiveTile
          className={tileClassName}
          title={t(tile.title)}
          description={t(tile.description)}
          link={tile.link}
          key={tile.title}
          Image={tile.Image}
        />
      ) : tile.externalLink ? (
        <ExternalLinkTile
          className={tileClassName}
          title={t(tile.title)}
          description={t(tile.description)}
          link={tile.link}
          key={tile.title}
          Image={tile.Image}
        />
      ) : tile.requiredRoles ? (
        <UserRoleGuardContainer
          key={tile.title}
          requiredRoles={tile.requiredRoles}
          redirectToMainPageIfNotPermitted={true}
        >
          <Tile
            className={tileClassName}
            title={t(tile.title)}
            description={t(tile.description)}
            link={tile.link}
            key={tile.title}
            Image={tile.Image}
          />
        </UserRoleGuardContainer>
      ) : (
        <Tile
          className={tileClassName}
          title={t(tile.title)}
          description={t(tile.description)}
          link={tile.link}
          key={tile.title}
          Image={tile.Image}
        />
      );
    });
  return (
    <div className={classnames('row container dbl-padding-top', styles.root)}>
      {renderTiles()}
    </div>
  );
}
