import React, { ReactElement } from 'react';
import { LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { MainRoutes } from '../../../../constants/routes';
import { CreateTopologyRoutes } from '../../constants';
import { ImportTemplatesTable } from './components/TemplatesTable';
import { InventoryTemplatesTableInfoModalContainer } from './components/InventoryTemplatesInfoModal/container';

export interface DemoImportProps {
  transformedTemplates: InventoryTopology[];
  isLoading: boolean;
  setTemplateToImport: (demo: InventoryTopology) => void;
  setShowMoreInfoForInventoryTemplate: (
    inventoryTemplate: InventoryTopology | undefined,
  ) => void;
}

export function DemoTemplate({
  transformedTemplates,
  isLoading,
  setTemplateToImport,
  setShowMoreInfoForInventoryTemplate,
}: DemoImportProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <div className="row flex-center">
        <ImportTemplatesTable
          setTemplateToImport={setTemplateToImport}
          setShowMoreInfoForInventoryTemplate={
            setShowMoreInfoForInventoryTemplate
          }
          demos={transformedTemplates}
          isLoading={isLoading}
        />
        <InventoryTemplatesTableInfoModalContainer />
      </div>
      <div className="text-center dbl-margin-top">
        <LinkButton to={MainRoutes.Topologies} colour="ghost" size="large">
          {t('buttons.cancel')}
        </LinkButton>
        <LinkButton
          to={`${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoType}`}
          colour="ghost"
          size="large"
          testId="create-topology-demo-type-back-button"
        >
          {t('buttons.back')}
        </LinkButton>
      </div>
    </>
  );
}
