import React, { ReactElement } from 'react';
import { VmLeftPanelHeaderButtonsContainer } from '../../../PanelHeaderButtons/VMPanel/container';
import { PanelHeaderButtonsContainer } from '../../../PanelHeaderButtons/container';
import { InfoLeftPanelHeaderButtonsContainer } from '../../../PanelHeaderButtons/infoContainer';
import { IntegrationLeftPanelHeaderButtonsContainer } from '../../../PanelHeaderButtons/IntegrationPanel/container';
import { LeftPanelHeaderProps } from './container';

export function LeftPanelHeader({
  title,
  leftPanelType,
}: LeftPanelHeaderProps): ReactElement {
  const renderHeader = () => {
    switch (leftPanelType) {
      case 'vm':
        return <VmLeftPanelHeaderButtonsContainer />;
      case 'info':
        return <InfoLeftPanelHeaderButtonsContainer />;
      case 'saas':
        return <IntegrationLeftPanelHeaderButtonsContainer />;
      case 'publicCloud':
        return <IntegrationLeftPanelHeaderButtonsContainer />;
      default:
        return <PanelHeaderButtonsContainer />;
    }
  };
  return (
    <div className={'flex flex-between'}>
      <div className="text-size-28">{title}</div>
      <div>{renderHeader()}</div>
    </div>
  );
}
