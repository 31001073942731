import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  networksSelectors,
  setNetworkToDelete,
} from '../../../../redux/configuration/networks/slice';
import { useDeleteNetworkMutation } from '../../../../redux/configuration/networks/api';
import { DeleteNetworkModal } from '.';

function DeleteNetworkModalContainer({
  network,
  setNetworkToDelete,
}: DeleteNetworkModalContainerProps): ReactElement {
  const [deleteNetwork, { isLoading: isDeleting }] = useDeleteNetworkMutation();
  return (
    <DeleteNetworkModal
      network={network}
      deleteNetwork={deleteNetwork}
      isDeleting={isDeleting}
      setNetworkToDelete={setNetworkToDelete}
    />
  );
}

export type DeleteNetworkModalProps = DeleteNetworkModalRTKProps &
  DeleteNetworkModalContainerProps;

type DeleteNetworkModalRTKProps = {
  deleteNetwork: RTKMutation<Network, undefined>;
  isDeleting: boolean;
};

export type DeleteNetworkModalContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  network: networksSelectors.getNetworkToDelete(state),
});

const mapDispatchToProps = {
  setNetworkToDelete,
};

export const ConnectedDeleteNetworkModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteNetworkModalContainer);
