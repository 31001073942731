import { connect } from 'react-redux';
import { sessionViewSelectors } from '../../../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../../../redux/store';
import { LeftPanelHeader } from '.';

export type LeftPanelHeaderProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState, ownProps: { title: string }) => ({
  leftPanelType: sessionViewSelectors.getLeftPanelType(state),
  title: ownProps.title || '',
});

export const LeftPanelHeaderContainer =
  connect(mapStateToProps)(LeftPanelHeader);
