import React, { ReactElement } from 'react';
import { ExternalDnsDeleteModal } from '../ExternalDnsDeleteModal';

export type ExternalDnsDeleteModalHelperProps = {
  setExternalDnsToDelete: (externalDns?: ExternalDnsRecord | undefined) => void;
  externalDnsRecordToDelete?: ExternalDnsRecord;
};

export const ExternalDnsDeleteModalHelper = ({
  setExternalDnsToDelete,
  externalDnsRecordToDelete,
}: ExternalDnsDeleteModalHelperProps): ReactElement => {
  if (!externalDnsRecordToDelete) return <></>;

  return (
    <ExternalDnsDeleteModal
      setExternalDnsToDelete={setExternalDnsToDelete}
      externalDns={externalDnsRecordToDelete}
    />
  );
};
