import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../../../../constants';
import i18n from '../../../../../i18n';
import { WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME } from '../../../../components/DefaultToParentField/DefaultToParentFieldConstants';

export type InputsFromForm = { [key: string]: string | boolean };

type BuildInputsPayload = {
  inputsFromForm: InputsFromForm;
  fetchedInputs?: SaasTemplateInput[];
};

export const buildInputsPayload = ({
  inputsFromForm,
  fetchedInputs,
}: BuildInputsPayload): PostSaasIntegrationPayloadInput[] =>
  (fetchedInputs || []).map((fetchedInput) => {
    const { mappingName } = fetchedInput;
    const defaultToTemplate = inputsFromForm[
      `${WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME}-${mappingName}`
    ] as boolean;
    const value = inputsFromForm[mappingName] as string;
    const defaultToTemplateProperty = !!fetchedInput.defaultValue
      ? {
          defaultToTemplate,
        }
      : {};
    const valueProperty =
      !defaultToTemplate && fetchedInput.userConfigurable
        ? {
            value,
          }
        : {};

    return {
      mappingName: fetchedInput.mappingName,
      ...valueProperty,
      ...defaultToTemplateProperty,
    };
  });

export const buildDefaultValues = (
  inventorySaasTemplateToConfigure: SaasTemplate | undefined,
): { [key: string]: unknown } => ({
  TBV3_userSaasIntegrationIconDefaultValue:
    inventorySaasTemplateToConfigure?.icon,
  TBV3_userSaasIntegrationNameDefaultValue: '',
});

type HandleOnSubmit = {
  fields: AddSaasIntegrationFormFields;
  createSaasIntegration: RTKMutation<
    PostSaasIntegrationPayload,
    SaasIntegration
  >;
  inventorySaasTemplateToConfigure: SaasTemplate;
  topologyUid: string;
};

export const handleOnSubmit = ({
  fields,
  createSaasIntegration,
  inventorySaasTemplateToConfigure,
  topologyUid,
}: HandleOnSubmit): void => {
  const {
    TBV3_userSaasIntegrationIconDefaultValue,
    TBV3_userSaasIntegrationNameDefaultValue,
    hiddenFromSessionView,
    ...rest
  } = fields;
  createSaasIntegration({
    hiddenFromSessionView,
    icon: TBV3_userSaasIntegrationIconDefaultValue,
    inputs: buildInputsPayload({
      fetchedInputs: inventorySaasTemplateToConfigure.inputs,
      inputsFromForm: { ...rest },
    }),
    name: TBV3_userSaasIntegrationNameDefaultValue,
    saasTemplate: {
      uid: inventorySaasTemplateToConfigure!.uid,
    },
    topology: {
      uid: topologyUid,
    },
  });
};

export const determineConfigurePageTitle = (
  integrationType: IntegrationType,
): string => {
  switch (integrationType) {
    case SAAS_INTEGRATION_TYPE:
      return i18n.t('saas.configure.headings.saas');
    case PUBLIC_CLOUD_INTEGRATION_TYPE:
      return i18n.t('saas.configure.headings.publicCloud');
    default:
      return i18n.t('saas.configure.headings.saas');
  }
};
