import React, { ReactElement } from 'react';
import { PageHeading } from '../../../../../components/PageHeading';
import { InventorySaasTemplatesTable } from './components/InventorySaasTemplatesTable';
import { AddSaasIntegrationsProps } from './container';
import { InventorySaasTemplatesAlert } from './components/InventorySaasTemplatesAlert';
import { InventorySaasTemplatesTableLoader } from './components/InventorySaasTemplatesTableLoader';
import { determineAddPageTitle } from './utils';

export function AddSaasIntegrations({
  isLoading,
  inventorySaasTemplates,
  integrationType,
}: AddSaasIntegrationsProps): ReactElement {
  return (
    <section className="half-padding-left">
      <PageHeading
        useCustomStyling={true}
        withBackBtn={true}
        pageTitle={determineAddPageTitle(integrationType)}
      />
      <InventorySaasTemplatesTableLoader isLoading={isLoading} />
      <InventorySaasTemplatesAlert
        isLoading={isLoading}
        inventorySaasTemplates={inventorySaasTemplates}
        integrationType={integrationType}
      />
      <InventorySaasTemplatesTable
        isLoading={isLoading}
        inventorySaasTemplates={inventorySaasTemplates}
      />
    </section>
  );
}
