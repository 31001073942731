import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { saasIntegrationFieldsSchema } from '../../../../../validation';
import { useLeaveFormPrompt } from '../../../hooks/useLeaveFormPrompt';
import { FormUnsavedChangesPrompt } from '../../../../../components/FormUnsavedChangesPrompt';
import { buildInputsPayload, InputsFromForm } from '../Configure/utils';
import { SaasConfigureFormInputs } from '../Configure/components/ConfigureFormInputs';
import { buildTakenNamesForSaasIntegrationForm } from '../utils/utils';
import { SaasEditProps } from './container';
import { EditSaaSIntegrationHeader } from './components/EditSaaSIntegrationHeader';
import { buildDefaultValuesForEdit } from './utils';

export function SaasEdit({
  saasIntegrationToEdit,
  updateSaasIntegration,
  isUpdating,
  userSaasIntegrations,
  integrationType,
}: SaasEditProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const formMethods = useForm<AddSaasIntegrationFormFields>({
    defaultValues: buildDefaultValuesForEdit(saasIntegrationToEdit),
    mode: 'all',
    resolver: yupResolver(
      saasIntegrationFieldsSchema(
        saasIntegrationToEdit?.saasTemplate.inputs || [],
        buildTakenNamesForSaasIntegrationForm(
          userSaasIntegrations,
          saasIntegrationToEdit.uid,
        ),
      ),
    ),
  });
  const {
    reset,
    formState: { dirtyFields },
  } = formMethods;
  const isDirty = !!Object.keys(dirtyFields).length;
  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);
  const onSubmit = (fields: AddSaasIntegrationFormFields) => {
    const {
      TBV3_userSaasIntegrationIconDefaultValue,
      TBV3_userSaasIntegrationNameDefaultValue,
      hiddenFromSessionView,
      ...inputsFromForm
    } = fields;
    updateSaasIntegration({
      hiddenFromSessionView,
      icon: TBV3_userSaasIntegrationIconDefaultValue,
      inputs: buildInputsPayload({
        fetchedInputs: saasIntegrationToEdit?.saasTemplate.inputs,
        inputsFromForm: inputsFromForm as InputsFromForm,
      }),
      name: TBV3_userSaasIntegrationNameDefaultValue,
      saasTemplate: {
        uid: saasIntegrationToEdit.saasTemplate.uid,
      },
      topology: {
        uid: topologyUid,
      },
      uid: saasIntegrationToEdit.uid,
    })
      .unwrap()
      .then(() => reset(fields));
  };
  return (
    <section className="half-padding-left">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <EditSaaSIntegrationHeader
            isLoading={isUpdating}
            integrationType={integrationType}
          />
          <SaasConfigureFormInputs
            integrationType={integrationType}
            inventorySaasTemplateToConfigure={
              saasIntegrationToEdit.saasTemplate
            }
            saasIntegrationToEdit={saasIntegrationToEdit}
            isEditing={true}
          />
        </form>
      </FormProvider>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </section>
  );
}
