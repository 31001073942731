import React, { ReactElement } from 'react';
import { Button, Icon } from 'dcloud-shared-ui';
import { buildRefreshButtonLabels } from '../../utils';
import styles from './GetVmStatusButton.module.css';
import { GetVmStatusButtonProps } from './container';

export const GetVmStatusButton = ({
  isCallingVmStatusAPICalls,
  isShowingMultiActionProgressBar,
  startGetAllVmStatuses,
  hasCalledVmStatusApiCalls,
  virtualMachines,
}: GetVmStatusButtonProps): ReactElement => {
  return (
    <Button
      type="button"
      colour="primary"
      disabled={
        isCallingVmStatusAPICalls ||
        isShowingMultiActionProgressBar ||
        virtualMachines.length === 0
      }
      className=" base-margin-right base-margin-bottom"
      onClick={() => {
        startGetAllVmStatuses(virtualMachines);
      }}
      testId="session-inventory-vms-refresh-btn"
      aria-label="Refresh Session Inventory VMs Table"
    >
      <Icon name="refresh" className={styles.icon} />{' '}
      {buildRefreshButtonLabels({
        hasCalledVmStatusApiCalls,
        isCallingVmStatusAPICalls,
      })}
    </Button>
  );
};
