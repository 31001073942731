import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getLicences,
  setLicenceToEdit,
} from '../../../../redux/configuration/licences/slice';
import { RootState } from '../../../../redux/store';
import {
  useGetInventoryLicencesQuery,
  useGetLicenceQuery,
  useUpdateLicenceMutation,
} from '../../../../redux/configuration/licences/api';
import { EditLicenceModal } from '.';

export function EditLicenceModalContainer({
  licenceUid,
  setLicenceToEdit,
}: EditLicenceModalContainerReduxProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const [updateLicence, { isLoading: isUpdating }] = useUpdateLicenceMutation();
  const { data: licence } = useGetLicenceQuery(licenceUid!);

  const { isLoading: isLoadingAvailable, data: availableLicences } =
    useGetInventoryLicencesQuery(topologyUid);

  if (!availableLicences) {
    return <></>;
  }
  return (
    <EditLicenceModal
      availableLicenses={availableLicences.inventoryLicenses}
      updateLicence={updateLicence}
      isUpdating={isUpdating || isLoadingAvailable}
      setLicenceToEdit={setLicenceToEdit}
      licence={licence}
    />
  );
}

export type EditLicenceModalProps = Omit<
  EditLicenceModalContainerReduxProps,
  'licenceUid'
> &
  EditLicenceModalRTKProps;

export type EditLicenceModalRTKProps = {
  availableLicenses: InventoryLicence[];
  updateLicence: RTKMutation<Licence, Omit<Licence, '_links'>>;
  isUpdating: boolean;
  licence?: Licence;
};

export type EditLicenceModalContainerReduxProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  licenceUid: getLicences(state).licenceToEdit,
});

const mapDispatchToProps = {
  setLicenceToEdit,
};

export const ConnectedEditLicenceModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditLicenceModalContainer);
