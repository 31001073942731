import { Alert, SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement, useState } from 'react';
import { Control, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledSelect,
} from '../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../../../../../components/FormUnsavedChangesPrompt';
import { PLEASE_SELECT_OPTION } from '../../../../../../../../constants/form';
import { useLeaveFormPrompt } from '../../../../../../hooks/useLeaveFormPrompt';
import { buildNatIpOptions } from '../../../utils';
import { NatRulesAddFormProps } from '../../NatRulesAddForm';
import styles from '../NatRulesVmInfo.module.css';

type NatRulesAddFormFieldsForTypeProps = {
  control: Control<NatRuleFormData>;
  errors: DeepMap<NatRuleFormData, FieldError>;
  isCreating: boolean;
  formState: UseFormMethods['formState'];
  setVmIpAddressOptions: NatRulesAddFormProps['setVmIpAddressOptions'];
  vmIpAddressOptions: NatRulesAddFormProps['vmIpAddressOptions'];
  vmTargets: NatRulesAddFormProps['vmTargets'];
  vmTargetOptions: SelectOption<string>[];
};

export function NatRulesAddFormFieldsForType({
  control,
  errors,
  vmTargets,
  isCreating,
  formState,
  setVmIpAddressOptions,
  vmIpAddressOptions,
  vmTargetOptions,
}: NatRulesAddFormFieldsForTypeProps): ReactElement {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;
  const [selectedVm, setSelectedVm] = useState<string>(
    PLEASE_SELECT_OPTION.value,
  );
  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  if (vmTargetOptions.length === 1) {
    return <></>;
  }

  return (
    <div className={styles.root} data-testid="natRulesVmInfo">
      <ControlledSelect
        className={styles.targetField}
        options={vmTargetOptions}
        control={control}
        label={t('traffic.natRules.table.headings.target')}
        name="name"
        required={true}
        error={errors?.name?.message}
        customOnChange={({ target }) => {
          control.setValue('targetItem.uid', '');
          setSelectedVm(target.value);
          setVmIpAddressOptions(
            buildNatIpOptions({
              vmName: target.value,
              vmTargets: vmTargets || [],
            }),
          );
        }}
        testId="natRulesVmTarget"
      />
      {vmIpAddressOptions.length > 1 ||
      selectedVm === PLEASE_SELECT_OPTION.value ? (
        <>
          <ControlledSelect
            className={styles.ipAddress}
            options={vmIpAddressOptions}
            control={control}
            label={t('traffic.natRules.table.headings.ipAddress')}
            name="targetItem.uid"
            required={true}
            error={errors?.targetItem?.uid?.message}
            customOnChange={({ target }) => {
              control.setValue('targetItem.uid', target.value);
            }}
            testId="natRulesVmIp"
          />
          <ControlledCheckbox
            className={styles.verticalAlign}
            control={control}
            label={t('traffic.natRules.table.headings.eastWestNat')}
            id="eastWest"
          />
          <PrimaryButton
            disabled={!isDirty || !isValid}
            loading={isCreating}
            type="submit"
            testId="natRulesAddButton"
          >
            {t('buttons.add')}
          </PrimaryButton>
        </>
      ) : (
        <Alert colour="info">{t('traffic.noAvailableNicTargets')}</Alert>
      )}
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </div>
  );
}
