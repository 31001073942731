import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { DataCenters } from '../CreateTopology/pages/DataCenters';
import { TemplatePortalProps } from './container';
import { NoDataCenterRedirectRoute } from './NoDataCenterRedirect';
import { TemplateManager } from './pages/TemplateManager';
import { TemplateManagerRoutes } from './pages/TemplateManager/TemplateManagerRouter';
import { ConnectedCreateVMTemplate } from './pages/VmTemplates/Components/AddTemplate/container';
import { ConnectedEditVMTemplateForm } from './pages/VmTemplates/Components/EditTemplate/container';

export function TemplatePortal({
  datacenters,
  selectedDataCenter,
  setSelectedDataCenter,
}: TemplatePortalProps): ReactElement {
  const { path } = useRouteMatch();
  return (
    <DefaultLayout>
      <div className="dbl-padding">
        <Switch>
          <Route
            path={path}
            render={() => (
              <div className="container">
                <DataCenters
                  setDataCenter={setSelectedDataCenter}
                  datacenters={datacenters || []}
                  resetDemoType={() => null}
                  isTemplate={true}
                />
              </div>
            )}
            exact={true}
          />
          <NoDataCenterRedirectRoute
            datacenter={selectedDataCenter}
            path={`${path}${TemplateManagerRoutes.VmTemplates}`}
            render={() => <TemplateManager />}
            exact={true}
          />
          <NoDataCenterRedirectRoute
            datacenter={selectedDataCenter}
            path={`${path}${TemplateManagerRoutes.Demos}`}
            render={() => <TemplateManager />}
            exact={true}
          />
          <NoDataCenterRedirectRoute
            datacenter={selectedDataCenter}
            path={`${path}${TemplateManagerRoutes.VmTemplates}${TemplateManagerRoutes.CreateVmTemplate}`}
            exact
            component={ConnectedCreateVMTemplate}
          />
          <NoDataCenterRedirectRoute
            datacenter={selectedDataCenter}
            path={`${path}${TemplateManagerRoutes.VmTemplates}${TemplateManagerRoutes.EditVmTemplate}`}
            exact
            component={ConnectedEditVMTemplateForm}
          />
        </Switch>
      </div>
    </DefaultLayout>
  );
}
