import React, { ReactElement } from 'react';
import { ColDef } from 'ag-grid-community';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { GridEditor } from '../../../../../../../components/GridEditor';
import { IpAddressesCellRenderer } from '../IpAddressCellRenderer';
import { GuestOsCellRendererContainer } from '../GuestOsCellRenderer/container';
import { VmWareToolCellRendererContainer } from '../VmWareToolsCellRenderer/container';
import { SelectCellRendererContainer } from '../../../SelectCellRenderer/container';
import { VirtualMachinesTableActionsCellRendererWrapper } from '../VirtualMachinesTableActionsCellRendererWrapper';
import { SelectAllCellRendererContainer } from '../../../SelectAllCellRenderer/container';

type SessionInventoryVirtualMachinesTableProps = {
  virtualMachines?: SessionInventoryVirtualMachineData[];
};

export const SessionInventoryVirtualMachinesTable = ({
  virtualMachines,
}: SessionInventoryVirtualMachinesTableProps): ReactElement => {
  const { t } = useTranslation();

  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: 'select',
      cellRendererParams: { page: 'vm' },
      cellStyle: { 'white-space': 'normal' },
      field: '',
      flex: 1,
      headerComponent: 'selectAll',
      headerComponentParams: { page: 'vm' },
      maxWidth: 80,
      minWidth: 80,
    },
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'name',
      flex: 1,
      headerName: t('sessionsInventory.virtualMachines.table.headings.name'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'description',
      flex: 1,
      headerName: t(
        'sessionsInventory.virtualMachines.table.headings.description',
      ),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'memory',
      flex: 1,
      headerName: t(
        'sessionsInventory.virtualMachines.table.headings.memoryGb',
      ),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'cpuQty',
      flex: 1,
      headerName: t('sessionsInventory.virtualMachines.table.headings.cpus'),
      minWidth: 100,
    },
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'nics',
      flex: 1,
      headerName: t('sessionsInventory.virtualMachines.table.headings.nics'),
      minWidth: 100,
    },
    {
      autoHeight: true,
      cellRenderer: 'ipAddress',
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'ipAddress',
      flex: 1,
      headerName: t(
        'sessionsInventory.virtualMachines.table.headings.ipAddress',
      ),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellRenderer: 'guestOs',
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'guestOS',
      flex: 1,
      headerName: t('sessionsInventory.virtualMachines.table.headings.guestOS'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellRenderer: 'vmWareTools',
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'vmwareTools',
      flex: 1,
      headerName: t(
        'sessionsInventory.virtualMachines.table.headings.vmwareTools',
      ),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellRenderer: 'actions',
      cellStyle: { 'white-space': 'normal' },
      field: 'actions',
      flex: 1,
      headerName: t('sessionsInventory.virtualMachines.table.headings.actions'),
      minWidth: 100,
    },
  ];

  if (virtualMachines) {
    return (
      <>
        {virtualMachines.length > 0 ? (
          <GridEditor
            columns={columns}
            data={virtualMachines}
            customComponents={{
              actions: VirtualMachinesTableActionsCellRendererWrapper,
              guestOs: GuestOsCellRendererContainer,
              ipAddress: IpAddressesCellRenderer,
              select: SelectCellRendererContainer,
              selectAll: SelectAllCellRendererContainer,
              vmWareTools: VmWareToolCellRendererContainer,
            }}
          />
        ) : (
          <Alert>
            {t('sessionsInventory.virtualMachines.noItemsToDisplay')}
          </Alert>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
