import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { useAddNetworkForm } from '../../hooks/use-add-network-form';
import {
  NetworkDescriptionInput,
  NetworkNameInput,
  NetworkSubnetSelect,
  NetworkRoutedCheckbox,
} from '../NetworkForm';
import styles from './AddNetworkForm.module.css';
import { AddNetworkFormProps } from '.';

export function AddNetworkForm({
  addNetwork,
  adding,
  availableRouted,
  availableUnrouted,
  networkNames,
}: AddNetworkFormProps): ReactElement {
  const { t } = useTranslation();
  const {
    alertMessage,
    control,
    errors,
    handleSubmit,
    isDirty,
    isNetworkCheckboxDisabled,
    isRouted,
    isValid,
    onRoutedChange,
    onSubmit,
    subnetOptions,
  } = useAddNetworkForm({
    addNetwork,
    availableRouted,
    availableUnrouted,
    networkNames,
  });

  return (
    <form className={styles.root}>
      <NetworkNameInput control={control} errorMesage={errors?.name?.message} />
      <NetworkDescriptionInput
        control={control}
        errorMesage={errors?.description?.message}
      />
      <NetworkRoutedCheckbox
        isChecked={isRouted}
        isDisabled={isNetworkCheckboxDisabled}
        onChange={onRoutedChange}
        data-testid="network-form-routed-checkbox"
      />
      {alertMessage && <Alert colour="info">{alertMessage}</Alert>}
      <NetworkSubnetSelect
        control={control}
        errorMesage={errors?.subnet?.message}
        options={subnetOptions}
      />
      <div className={styles.submit}>
        <PrimaryButton
          colour="success"
          disabled={!isDirty || !isValid}
          className="dbl-margin-top"
          onClick={handleSubmit(onSubmit)}
          loading={adding}
          testId="network-form-submit-btn"
        >
          {t('buttons.add')}
        </PrimaryButton>
      </div>
    </form>
  );
}
