import React, { ReactElement, isValidElement, cloneElement } from 'react';

export interface TabPanelsProps extends React.HTMLAttributes<HTMLDivElement> {
  items: React.ReactNode;
  active: number;
  id: string;
}

export function TabPanels({ items, active, id }: TabPanelsProps): ReactElement {
  return (
    <div className="tab-content">
      {React.Children.map(items, (Child, index) =>
        isValidElement(Child)
          ? cloneElement(Child as ReactElement, {
              active: index === active,
              children: Child.props.children,
              id,
              role: 'tabpanel',
              title: Child.props.title,
            })
          : null,
      )}
    </div>
  );
}
