import { useCallback, useEffect } from 'react';
import * as d3 from 'd3';
import { x, y, zoom } from '../../../../../d3';
import { useWindowResize } from '../../../../../../../hooks/use-window-resize';
import { DefaultTextNodeDisplay } from '../../../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddText/constants';

interface UsePosition {
  display: DiagramTextDisplay;
  nodeRef: React.RefObject<HTMLDivElement>;
  uid: string;
}

export function usePosition({ display, nodeRef, uid }: UsePosition): void {
  const position = useCallback(() => {
    d3.select<HTMLDivElement, DiagramNode>(nodeRef.current!)
      .datum(display)
      .attr('style', function () {
        const transform = d3.zoomTransform(this);
        const zx = transform.rescaleX(x);
        const zy = transform.rescaleY(y);
        const left = zx(x.invert(transform.invertX(DefaultTextNodeDisplay.x)));
        const top = zy(y.invert(transform.invertY(DefaultTextNodeDisplay.y)));
        return `transform: translate(${
          left - 5
        }px, ${top - 10}px) scale(${transform.k})`;
      });
  }, [display, nodeRef]);

  useWindowResize(() => {
    position();
  });

  useEffect(() => {
    position();

    zoom.on(`zoom.${uid}`, function () {
      position();
    });

    return () => {
      zoom.on(`zoom.${uid}`, null);
    };
  }, [display, position, uid]);
}
