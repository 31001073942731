import * as d3 from 'd3';
import { diagramApi } from '../../../../../../redux/diagram/api';
import { updateConnectionsDisplayReducer } from '../../../../../../redux/diagram/utils';
import { logicalConnectionLineDefaults } from '../../DiagramSideMenu/components/DiagramSideMenuButtonAddLogicalConnection/constants';
type UpdateRtkQueryCacheWithNewConnection = {
  dispatch: (value: unknown) => void;
  topologyUid: string;
  selectedNode: DiagramNodeTypes;
  newDisplay: DiagramDisplay;
};

export const updateRtkQueryCacheWithNewConnection = ({
  dispatch,
  topologyUid,
  selectedNode,
  newDisplay,
}: UpdateRtkQueryCacheWithNewConnection): void => {
  dispatch(
    diagramApi.util.updateQueryData(
      'getDiagram',
      topologyUid,
      (response: DiagramResponse) =>
        updateConnectionsDisplayReducer({
          connectionUid: selectedNode.uid,
          diagramResponse: response,
          display: newDisplay,
        }),
    ),
  );
};

type UpdateRtkQueryCacheRemoveConnection = {
  dispatch: (value: unknown) => void;
  topologyUid: string;
  selectedNode: DiagramNodeTypes;
};

export const updateRtkQueryCacheRemoveConnection = ({
  dispatch,
  topologyUid,
  selectedNode,
}: UpdateRtkQueryCacheRemoveConnection): void => {
  dispatch(
    diagramApi.util.updateQueryData(
      'getDiagram',
      topologyUid,
      (response: DiagramResponse) =>
        updateRtkCacheToRemoveConnection({
          diagramResponse: response,
          selectedNode: selectedNode as DiagramConnection,
        }),
    ),
  );
};

type UpdateRtkCacheToRemoveConnection = {
  diagramResponse: DiagramResponse;
  selectedNode: DiagramConnection;
};

export function updateRtkCacheToRemoveConnection({
  selectedNode,
  diagramResponse,
}: UpdateRtkCacheToRemoveConnection): DiagramResponse {
  const connectionsWithItemRemoved = diagramResponse.connections.filter(
    (connection) => {
      if (connection.uid !== selectedNode.uid) return connection;
    },
  );

  const connectionsUpdated = connectionsWithItemRemoved.map((connection) => {
    if (
      connection.nodes.from.uid === selectedNode.nodes.from.uid &&
      connection.nodes.to.uid === selectedNode.nodes.to.uid &&
      connection.derived?.order &&
      selectedNode.derived?.order
    ) {
      return {
        ...connection,
        derived: {
          ...connection.derived,
          order:
            connection.derived?.order > selectedNode.derived?.order
              ? connection.derived.order - 1
              : connection.derived.order,
          total: connection.derived.total ? connection.derived.total - 1 : 1,
        },
      };
    } else {
      return connection;
    }
  });

  return { ...diagramResponse, connections: connectionsUpdated };
}

type UpdateLineColorAttribute = {
  selection: d3.Selection<SVGLineElement, unknown, HTMLElement, unknown>;
  value?: string;
};

export const updateLineColorAttribute = ({
  selection,
  value,
}: UpdateLineColorAttribute): void => {
  value && selection.attr('stroke', `var(--cui-${value})`);
};

type UpdateNodeStrokeAttributes = {
  selection: d3.Selection<SVGLineElement, unknown, HTMLElement, unknown>;
  scaleFactor: number;
  selectedStrokeType: DiagramDrawingStrokeType;
};

export const updateNodeStrokeAttributes = ({
  selectedStrokeType,
  selection,
  scaleFactor,
}: UpdateNodeStrokeAttributes): void => {
  if (selectedStrokeType === 'dashed') {
    selection.attr(
      'stroke-dasharray',
      (logicalConnectionLineDefaults.dashSize * scaleFactor).toString(),
    );
    selection.attr('data-connection-stroke-type', 'dashed');
  } else {
    selection.attr('stroke-dasharray', '0');
    selection.attr('data-connection-stroke-type', 'solid');
  }
};

export const calculateAllConnectionsTotalValue = (
  connection: DiagramConnection,
): number => {
  const selection = d3
    .selectAll<SVGLineElement, unknown>(
      `[data-connection-from-to="${connection.nodes.from.uid}-${connection.nodes.to.uid}"]`,
    )
    .nodes();

  return selection.length / 2;
};
