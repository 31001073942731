import classnames from 'classnames';
import { Search as SharedSearch } from 'dcloud-shared-ui';
import React, { ChangeEventHandler, ReactElement } from 'react';
import styles from './Search.module.css';

export interface SearchProps {
  className?: string;
  id: string;
  label?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value: string;
}

export function Search({ className, ...props }: SearchProps): ReactElement {
  return (
    <div className={classnames(styles.root, className)}>
      <SharedSearch {...props} />
    </div>
  );
}
