import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { buildProtocolOptions } from '../utils';
import { SrvForm } from './SrvForm';

interface SrvAddFormContainerProps {
  srvRecords: SrvRecord[];
  addSrvRecord: (srvRecord: SrvRecord) => void;
  protocols: SrvProtocol[];
  onDeleteSrv: (srvRecord: SrvRecord) => void;
  formMethods: UseFormMethods<SrvRecord>;
}

export function SrvAddFormContainer({
  addSrvRecord,
  protocols,
  srvRecords,
  onDeleteSrv,
  formMethods,
}: SrvAddFormContainerProps): ReactElement {
  const { control, errors, formState, handleSubmit, reset } = formMethods;
  const handleCreate = (record: SrvRecord) => {
    addSrvRecord(record);
    reset();
  };

  return (
    <SrvForm
      control={control}
      errors={errors}
      protocolOptions={buildProtocolOptions(protocols)}
      formState={formState}
      handleCreate={handleCreate}
      handleSubmit={handleSubmit}
      onDeleteSrv={onDeleteSrv}
      srvRecords={srvRecords}
    />
  );
}
