export const getLicenceIds = (licences: Licence[]): string[] =>
  licences.map((licence) => licence.inventoryLicense.id);

export const filterAvailableLicences = (
  inventoryLicences: InventoryLicence[],
  selectedLicenses: Licence[],
): InventoryLicence[] =>
  inventoryLicences.filter(
    (inventoryLicence) =>
      inventoryLicence.quantity > 0 &&
      selectedLicenses?.find(
        (license) => license.inventoryLicense.id === inventoryLicence.id,
      ) === undefined,
  );

export const getMaxAvailable = (
  selectedLicense: Licence,
  availableLicenses: InventoryLicence[],
): number | undefined =>
  availableLicenses.find(
    (invLicense) => invLicense.id === selectedLicense.inventoryLicense.id,
  )?.quantity;
