import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { linkToNewTab } from '../../../../../../../../utils/helpers';
import { ActionsButtonGroupProps } from '../../../../ActionsButtonGroup';
import { HWConsole } from '../HWConsole';
import { HWPowerControl } from '../HWPowerControl';

export interface HwControlsProps {
  consoleHref?: string;
  postHwAction: (action: SessionHwActions) => void;
  pduControl?: boolean;
}

export function HWControls({
  consoleHref,
  postHwAction,
  pduControl,
}: HwControlsProps): ReactElement {
  const { t } = useTranslation();

  const powerButtonActions: ActionsButtonGroupProps['actions'] = [
    {
      content: t('sessions.panels.hardware.controls.reset'),
      onClick: pduControl ? () => postHwAction('RESET') : undefined,
    },
  ];

  const hardwareConsoleButtonActions: ActionsButtonGroupProps['actions'] = [
    {
      content: t('sessions.panels.hardware.controls.open'),
      onClick: consoleHref ? () => linkToNewTab(consoleHref) : undefined,
    },
  ];

  return (
    <div className="base-padding">
      <HWPowerControl
        pduControl={pduControl}
        powerButtonActions={powerButtonActions}
      />
      <HWConsole
        consoleHref={consoleHref}
        hardwareConsoleButtonActions={hardwareConsoleButtonActions}
      />
    </div>
  );
}
