import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import history from '../../../../../redux/history';
import { saasIntegrationFieldsSchema } from '../../../../../validation';
import { useLeaveFormPrompt } from '../../../hooks/useLeaveFormPrompt';
import { FormUnsavedChangesPrompt } from '../../../../../components/FormUnsavedChangesPrompt';
import { buildTakenNamesForSaasIntegrationForm } from '../utils/utils';
import { SaasConfigureProps } from './container';
import { SaasConfigureFormInputs } from './components/ConfigureFormInputs';
import { useResetInventorySaasTemplateToConfigure } from './hooks/useResetInventorySaasTemplateToConfigure';
import {
  buildDefaultValues,
  determineConfigurePageTitle,
  handleOnSubmit,
} from './utils';

export function SaasConfigure({
  inventorySaasTemplateToConfigure,
  createSaasIntegration,
  isCreating,
  isCreatingSuccess,
  setInventorySaasTemplateToConfigure,
  userSaasIntegrations,
  integrationType,
}: SaasConfigureProps): ReactElement {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const formMethods = useForm<AddSaasIntegrationFormFields>({
    defaultValues: buildDefaultValues(
      inventorySaasTemplateToConfigure,
    ) as AddSaasIntegrationFormFieldsInputs,
    mode: 'all',
    resolver: yupResolver(
      saasIntegrationFieldsSchema(
        inventorySaasTemplateToConfigure?.inputs || [],
        buildTakenNamesForSaasIntegrationForm(userSaasIntegrations),
      ),
    ),
  });

  const {
    reset,
    formState: { isDirty, isValid },
  } = formMethods;

  useEffect(() => {
    if (!inventorySaasTemplateToConfigure) {
      history.push(url.substring(0, url.lastIndexOf('/configure')));
    }
  }, [inventorySaasTemplateToConfigure, url]);

  useResetInventorySaasTemplateToConfigure({
    setInventorySaasTemplateToConfigure,
  });

  useEffect(() => {
    if (isCreatingSuccess) {
      reset();
      history.push(url.substring(0, url.lastIndexOf('/add')));
    }
  }, [isCreatingSuccess, isDirty, isValid, reset, url]);

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  const onSubmit = (fields: AddSaasIntegrationFormFields) => {
    handleOnSubmit({
      createSaasIntegration,
      fields,
      inventorySaasTemplateToConfigure: inventorySaasTemplateToConfigure!,
      topologyUid,
    });
  };

  return (
    <section className="half-padding-left">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageHeading
            useCustomStyling={true}
            withBackBtn={true}
            ctaBtn={
              <PrimaryButton
                disabled={!isValid || !isDirty}
                colour="success"
                type="submit"
                loading={isCreating}
                testId="saas-configure-save-button"
              >
                {t('buttons.save')}
              </PrimaryButton>
            }
            pageTitle={determineConfigurePageTitle(integrationType)}
          />
          <SaasConfigureFormInputs
            integrationType={integrationType}
            inventorySaasTemplateToConfigure={inventorySaasTemplateToConfigure!}
          />
        </form>
      </FormProvider>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </section>
  );
}
