import React, { ReactElement } from 'react';
import { InfoModal } from '../../../../../../../../../../components/Modals/InfoModal';
import { buildInfoColsData, buildInfoModalFooter } from '../../utils';
import { TemplatesTableInfoModalProps } from './container';

export function TemplatesTableInfoModal({
  vmTemplate,
  setShowMoreInfoForVmTemplate,
}: TemplatesTableInfoModalProps): ReactElement {
  return (
    <>
      {vmTemplate ? (
        <InfoModal
          colsContent={buildInfoColsData(vmTemplate)}
          title={vmTemplate.name || ''}
          show={true}
          footerContent={buildInfoModalFooter(vmTemplate)}
          onClose={() => setShowMoreInfoForVmTemplate(undefined)}
        />
      ) : null}
    </>
  );
}
