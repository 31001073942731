import React, { ReactElement } from 'react';
import { LoaderDots } from 'dcloud-shared-ui';
import { NO_TEXT } from '../../../../constants';
import { GuestOsCellRendererProps } from './container';

export const GuestOsCellRenderer = ({
  loadingStatus,
  guestOsValue,
  isLoadingAllStatuses,
}: GuestOsCellRendererProps): ReactElement => {
  const renderStatus = () => {
    switch (loadingStatus) {
      case 'fulfilled':
        return guestOsValue ? guestOsValue : NO_TEXT;
      case 'pending':
        return <LoaderDots />;
      default:
        return isLoadingAllStatuses ? <LoaderDots /> : NO_TEXT;
    }
  };
  return <span aria-label="Guest OS">{renderStatus()}</span>;
};
