import React, { ReactElement, PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './Container.module.css';

type ContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function Container({
  children,
  className,
}: ContainerProps): ReactElement {
  const classes = classnames(
    styles.root,
    className,
    'base-padding-left',
    'base-padding-right',
  );

  return <div className={classes}>{children}</div>;
}
