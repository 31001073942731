import React, { ReactElement, useMemo } from 'react';
import classnames from 'classnames';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../../../components/Accordion';
import { getSelectedIconForIntegrationType } from '../../../../../../../EditTopology/configuration/SaasIntegrations/Configure/components/ConfigureFormIconInput/utils';
import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../../../../../../../constants';
import { IntegrationPanelSections } from './components/IntegrationPanelSections';
import { SaasPanelNoDataAlert } from './components/IntegrationPanelNoDataAlert';
import styles from './IntegrationPanelInfo.module.css';

type IntegrationPanelInfoProps = {
  sessionIntegrationItems?: SessionIntegrationItem[];
  integrationType: IntegrationType;
};

export function IntegrationPanelInfo({
  sessionIntegrationItems,
  integrationType,
}: IntegrationPanelInfoProps): ReactElement {
  const accordionRows = useMemo<AccordionProps['rows']>(
    () =>
      (sessionIntegrationItems || []).map((sessionIntegrationItem) => ({
        children: (
          <IntegrationPanelSections
            integrationSessionItem={sessionIntegrationItem}
          />
        ),
        className: styles.integrationAccordion,
        imageComponent: (
          <div className={classnames('align-self-start', styles.svgContainer)}>
            {getSelectedIconForIntegrationType(
              sessionIntegrationItem.icon,
              integrationType === 'SAAS'
                ? SAAS_INTEGRATION_TYPE
                : PUBLIC_CLOUD_INTEGRATION_TYPE,
            )}
          </div>
        ),
        title: sessionIntegrationItem.name,
      })),
    [sessionIntegrationItems, integrationType],
  );
  const noData = (sessionIntegrationItems || []).length < 1;
  return (
    <div aria-label="Integration panel">
      {noData ? (
        <SaasPanelNoDataAlert integrationType={integrationType} />
      ) : (
        <Accordion rows={accordionRows} size="small" />
      )}
    </div>
  );
}
