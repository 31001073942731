import classnames from 'classnames';
import React, { PropsWithChildren, ReactElement, HTMLProps } from 'react';
import styles from './FormWrapper.module.css';

export type FormWrapperProps = PropsWithChildren<HTMLProps<HTMLFormElement>>;

export function FormWrapper({
  children,
  className,
  ...props
}: FormWrapperProps): ReactElement {
  return (
    <form className={classnames(className, styles.root)} {...props}>
      {children}
    </form>
  );
}
