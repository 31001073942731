import React, { ReactElement } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../components/PageHeading';
import { useDeleteVmTemplateMutation } from '../../../../redux/templateManager/VmTemplates/api';
import { VmTemplateTable } from './Table';

interface VmTemplateProps {
  vmTemplates: VmTemplate[];
  setSelectedInventoryItem: ActionCreatorWithOptionalPayload<
    VmTemplate | undefined,
    string
  >;
  selectedInventoryItem?: VmTemplate;
  isLoading: boolean;
}

export function VmTemplates({
  vmTemplates,
  setSelectedInventoryItem,
  isLoading,
}: VmTemplateProps): ReactElement {
  const { t } = useTranslation();

  const [deleteTemplate, { isLoading: isDeleting }] =
    useDeleteVmTemplateMutation();

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('templateManager.vmTemplates.tabTitle')} />
      <div className="row flex-right">
        <VmTemplateTable
          setSelectedInventoryItem={setSelectedInventoryItem}
          vmTemplates={vmTemplates}
          loading={isDeleting || isLoading}
          deleteVmTemplate={deleteTemplate}
        />
      </div>
    </section>
  );
}
