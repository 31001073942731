export const tagComparator = (valueA: Tag[], valueB: Tag[]): 0 | 1 | -1 => {
  const tagsA = valueA ? valueA.map((tag: Tag) => tag.name).join('') : '';
  const tagsB = valueB ? valueB.map((tag: Tag) => tag.name).join('') : '';
  if (tagsA === tagsB) {
    return 0;
  }
  return tagsA > tagsB ? 1 : -1;
};

export const booleanComparator = (
  valueA: boolean,
  valueB: boolean,
): 0 | 1 | -1 => {
  if (valueA === valueB) {
    return 0;
  }
  return !valueA && valueB ? 1 : -1;
};
