import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';

export type ExternalDnsNoRecordsAlertProps = {
  externalDNSRecord?: ExternalDnsRecord[];
  isLoading: boolean;
};

export const ExternalDnsNoRecordsAlert = ({
  externalDNSRecord,
  isLoading,
}: ExternalDnsNoRecordsAlertProps): ReactElement => {
  const { t } = useTranslation();

  if (!externalDNSRecord?.length && !isLoading)
    return <Alert colour="info">{t('traffic.externalDns.empty')}</Alert>;

  return <></>;
};
