import React, { ReactElement, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { Heading } from '../../Heading';
import styles from './Modal.module.css';
import { MODAL_OVERLAY_ID } from './constants';
import { handleMouseDown } from './utils/utils';

type size = 'small' | 'large' | 'default' | 'fluid';

export interface ModalProps {
  className?: string;
  clickScreenToClose?: boolean;
  children: ReactNode;
  testId?: string;
  title?: string;
  size?: size;
  show?: boolean;
  headerAlignment?: 'left' | 'center' | 'right';
  onClose: () => void;
}

export function ModalBody({
  className,
  clickScreenToClose = false,
  children,
  testId,
  title = '',
  show = false,
  size = 'default',
  headerAlignment,
  onClose,
}: ModalProps): ReactElement {
  const wrapperClasses = classnames(
    className,
    `modal modal--${size}`,
    { hide: !show },
    styles.root,
  );
  const closeButtonClasses = classnames('modal__close', [styles.closeButton]);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose]);

  return (
    <div
      className={wrapperClasses}
      data-testid={testId}
      id={`modal-${size}`}
      onMouseDown={(event) =>
        handleMouseDown({
          clickScreenToClose,
          onClose,
          target: event.target as HTMLElement,
        })
      }
    >
      <div className="modal__dialog" onClick={(e) => e.stopPropagation()}>
        <div className="modal__content">
          <div className={styles.contentWrapper}>
            <button
              className={closeButtonClasses}
              onClick={onClose}
              aria-label="Close modal"
            >
              <span className="icon-close"></span>
            </button>
            <div className="modal__header">
              <div className="modal__title">
                <Heading
                  level="h2"
                  headingText={title}
                  size="24"
                  align={headerAlignment || 'left'}
                />
              </div>
            </div>
            <div className="modal__body">{show ? children : null}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Modal(props: ModalProps): ReactElement {
  return createPortal(
    ModalBody(props),
    document.getElementById(MODAL_OVERLAY_ID) as HTMLElement,
  );
}

export default Modal;
