import React, { ReactElement } from 'react';

export type DocumentationUrlProps = {
  href?: string;
};

export const DEMO_DOCUMENT_TITLE = 'Demo Documentation';

export function DocumentationUrl({
  href,
}: DocumentationUrlProps): ReactElement {
  if (!href || !href.length) {
    return <></>;
  }

  return (
    <li>
      <a href={href} target="_blank" rel="noreferrer noopener">
        {DEMO_DOCUMENT_TITLE}
      </a>
    </li>
  );
}
