import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Alert } from 'dcloud-shared-ui';
import { format } from 'date-fns';
import {
  ControlledSelect,
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../../../components';
import { ArchitectureLabelGroup } from '../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { ArchitectureLabelPicker } from '../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelPicker';
import { ControlledSwitch } from '../../../../../../components/Form/ControlledSwitch';
import { removeSelectedArchitectures } from '../../../utils';
import styles from '../VmTemplates.module.css';
import { TOPOLOGIES_LAST_UPDATED_FORMAT } from '../../../../../../entities/topologies/constants';

export interface VmTemplateFormProps {
  allArchitectures: Architecture[];
  removeArchitecture: ActionCreatorWithPayload<string, string>;
  addSelectedArchitecture: ActionCreatorWithPayload<Architecture, string>;
  selectedArchitectures?: Architecture[];
  hasMissingArchitecture: boolean;
  lastUpdated?: Date;
  isEdit: boolean;
}

export function VmTemplateForm({
  addSelectedArchitecture,
  allArchitectures,
  hasMissingArchitecture,
  selectedArchitectures,
  removeArchitecture,
  lastUpdated,
  isEdit,
}: VmTemplateFormProps): ReactElement {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  return (
    <section className="half-padding-left">
      <div className="row">
        <div className="col-4">
          <ControlledTextfield<EditVmTemplateFormData>
            control={control}
            error={errors?.templateName?.message}
            label={t('templateManager.vmTemplates.form.labels.name')}
            labelClass="col-2"
            name="templateName"
            required={true}
          />
          <ControlledTextfield<EditVmTemplateFormData>
            control={control}
            error={errors?.contact?.message}
            label={t('templateManager.vmTemplates.form.labels.contact')}
            labelClass="col-3"
            name="contact"
          />
        </div>
        <div className="col-4">
          <ControlledSelect<EditVmTemplateFormData>
            control={control}
            error={errors?.licensed?.message}
            label={t('templateManager.vmTemplates.form.labels.licensed')}
            labelClass="col-2"
            name="licensed"
            options={[
              {
                name: t('common.yes'),
                value: 'yes',
              },
              {
                name: t('common.no'),
                value: 'no',
              },
            ]}
          />
          <ControlledSwitch<EditVmTemplateFormData>
            control={control}
            label={t('templateManager.vmTemplates.form.labels.userEnabled')}
            className={styles.switch}
            id="userEnabled"
            labelPosition="right"
          />
        </div>

        <div className="col-4">
          <ControlledTextfield<EditVmTemplateFormData>
            control={control}
            error={errors?.templateVersion?.message}
            label={t('templateManager.vmTemplates.form.labels.version')}
            labelClass="col-6"
            name="templateVersion"
          />
          {isEdit && (
            <ControlledTextfield<EditVmTemplateFormData>
              className={styles.lastUpdatedField}
              control={control}
              label={t('templateManager.vmTemplates.form.labels.lastUpdated')}
              labelClass="col-6"
              name="lastUpdated"
              disabled={true}
              defaultValue={
                lastUpdated
                  ? format(
                      new Date(lastUpdated as unknown as string),
                      TOPOLOGIES_LAST_UPDATED_FORMAT,
                    )
                  : ''
              }
            />
          )}
          <div className={styles.archContainer}>
            <label className={styles.archLabel}>
              {t('templateManager.vmTemplates.form.labels.available')}
            </label>
            <ArchitectureLabelPicker
              architectures={removeSelectedArchitectures(
                allArchitectures,
                selectedArchitectures || [],
              )}
              onAdd={addSelectedArchitecture}
            />
          </div>
          <label className={styles.archLabel}>
            {t('templateManager.vmTemplates.form.labels.selectedArchitectures')}
          </label>
          <div className={styles.archContainer}>
            {selectedArchitectures && (
              <ArchitectureLabelGroup
                architectures={selectedArchitectures}
                onDismiss={(id: string) => removeArchitecture(id)}
                hideMissing={false}
              />
            )}
            {hasMissingArchitecture && (
              <Alert colour="warning">
                {t(
                  'templateManager.vmTemplates.form.validations.missingArchitecture',
                )}
              </Alert>
            )}
          </div>
        </div>
      </div>
      <div className={styles.description}>
        <ControlledTextarea<EditVmTemplateFormData>
          control={control}
          error={errors?.templateDescription?.message}
          required
          label={t('templateManager.vmTemplates.form.labels.description')}
          labelClass="col-12"
          rows={5}
          name="templateDescription"
        />
      </div>
    </section>
  );
}
