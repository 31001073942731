import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../../constants';
import {
  DEFAULT_PUBLIC_CLOUD_ICON,
  DEFAULT_SAAS_ICON,
} from '../../CreateIntegration/constants';
import { determineInputFieldValue } from '../../utils/workFlowParameterMappingUtil';

export const getIconOnIntegrationTypeChange = (
  selectedIntegrationType: string,
  existingIntegration: Integration,
): string => {
  if (selectedIntegrationType === SAAS_INTEGRATION_TYPE) {
    if (existingIntegration.type === SAAS_INTEGRATION_TYPE) {
      return existingIntegration.icon;
    } else {
      return DEFAULT_SAAS_ICON;
    }
  } else {
    if (existingIntegration.type === PUBLIC_CLOUD_INTEGRATION_TYPE) {
      return existingIntegration.icon;
    } else {
      return DEFAULT_PUBLIC_CLOUD_ICON;
    }
  }
};

export const prepareWorkflowParamsForComparison = (
  workflowParams?: SaasTemplateInput[],
): SaasTemplateInput[] =>
  workflowParams?.map((input) => ({
    ...input,
    defaultToWorkflowEngine: !!input.defaultToWorkflowEngine,
    value: determineInputFieldValue(
      input.value || '',
      input.defaultValue || '',
      !!input.defaultToWorkflowEngine,
    ),
  })) || [];
