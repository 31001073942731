export const getPhoneNumbersFromSession = (
  data: Session | undefined,
): PhoneNumberItem[] | undefined => {
  if (!data) return undefined;

  const {
    sessionDetails: { sessionDids },
  } = data;

  return sessionDids.map((sessionDid) => {
    return {
      description: sessionDid.description,
      did: sessionDid.did,
      dn: sessionDid.dn,
    };
  });
};
