import React, { ReactElement, useEffect, useState } from 'react';
import {
  useRouteMatch,
  useParams,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageHeading } from '../../../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import {
  useCloneVmMutation,
  useCreateVmMutation,
} from '../../../../../../../redux/configuration/virtual-machine-entities/api';
import { virtualMachinesSelectors } from '../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { getNetworkOptions } from '../Utils';
import { prepMappingsForClone } from '../../../../VirtualMachines/Configure/Demos';
import { useClearSelectedOnUnmount } from '../hooks/use-clear-selected-on-unmount';
import { topologyLimitsApi } from '../../../../../../../redux/configuration/topologyLimits/api';
import { addVmFieldArraySchema } from '../../../../../../../validation';
import { useShowTopologyLimitsWarningAddVm } from './hooks/use-show-topology-limits-warning-add-vm';
import { ConfigureFormFields } from './ConfigureFormFields';
import {
  prepInterfacesForTemplatesConfigureScreen,
  prepMappingsForCreate,
} from './utils';
import { VirtualMachinesConfigureProps } from '.';

export function VirtualMachinesConfigure({
  interfaces,
  networks,
  topologyLimitsWarningAccepted,
  setShowTopologyLimitWarningForEntity,
  resetTopologyLimits,
  isDiagramView = false,
  backButtonOverride,
  handleAddVmFromTemplatesTabFromDiagramWorker,
  vms,
}: VirtualMachinesConfigureProps): ReactElement {
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();

  const { topologyLimits } =
    topologyLimitsApi.endpoints.getTopologyLimits.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        topologyLimits: data,
      }),
    });

  const [cloneVm, { isLoading, isSuccess }] = useCloneVmMutation();
  const [createVm, { isLoading: isCreating, isSuccess: isCreatingSuccess }] =
    useCreateVmMutation();

  const inventoryVm = useSelector(
    virtualMachinesSelectors.getVirtualMachineToConfigure,
  );

  const interfacesForForm = prepInterfacesForTemplatesConfigureScreen(
    interfaces || [],
    networks,
  );

  const formMethods = useForm<AddVmConfigureFormValues>({
    defaultValues: {
      interfaces: [...interfacesForForm],
    },
    mode: 'all',
    resolver: yupResolver(addVmFieldArraySchema(networks, vms)),
  });
  const {
    getValues,
    formState: { errors },
  } = formMethods;

  const onSubmit = () => {
    const data: AddVmConfigureFormValues = getValues();
    if (inventoryVm!.vm) {
      cloneVm({
        payload: {
          topology: { uid: topologyUid },
          vmNetworkInterfaces: prepMappingsForClone(data.interfaces),
          vmUid: inventoryVm!.vm.uid,
        },
      });
    } else {
      createVm({
        payload: {
          inventoryVmId: inventoryVm!.id,
          topology: { uid: topologyUid },
          vmNetworkInterfaces: prepMappingsForCreate(data.interfaces),
        },
      });
    }
  };

  const { onAddClicked } = useShowTopologyLimitsWarningAddVm({
    addVm: onSubmit,
    resetTopologyLimits,
    setShowTopologyLimitWarningForEntity,
    topologyLimits,
    topologyLimitsWarningAccepted,
    vm: inventoryVm,
  });

  useClearSelectedOnUnmount();

  useEffect(() => {
    const determineIfAddButtonIsDisabled = (): void => {
      if (!errors.interfaces) {
        setIsAddDisabled(false);
        return;
      }

      const numberOfDuplicateIpTestErrors = errors.interfaces?.filter(
        (interfaceItem) => interfaceItem?.ipAddress?.type === 'duplicateIpTest',
      );

      const numberOfDuplicateMacTestErrors = errors.interfaces?.filter(
        (interfaceItem) =>
          interfaceItem?.macAddress?.type === 'duplicateMacAddressTest',
      );

      const totalNumErrors = errors.interfaces.reduce(function (result, error) {
        if (error) {
          result += Object.keys(error)?.length;
        }
        return result;
      }, 0);

      setIsAddDisabled(
        totalNumErrors >
          numberOfDuplicateIpTestErrors.length +
            numberOfDuplicateMacTestErrors.length,
      );
    };

    determineIfAddButtonIsDisabled();
  });

  useEffect(() => {
    if ((isSuccess || isCreatingSuccess) && !isDiagramView) {
      history.push(url.substring(0, url.lastIndexOf('/add')));
    }
  }, [history, isCreatingSuccess, isDiagramView, isSuccess, url]);

  if (!interfaces && !isDiagramView) {
    return <Redirect to={url.substring(0, url.lastIndexOf('/'))} />;
  }

  return (
    <FormProvider {...formMethods}>
      <PageHeading
        withBackBtn={true}
        backButtonOverride={backButtonOverride}
        ctaBtn={
          <PrimaryButton
            testId="add-vm"
            aria-label="Add Button"
            disabled={isAddDisabled}
            loading={isLoading || isCreating}
            onClick={
              isDiagramView
                ? () =>
                    handleAddVmFromTemplatesTabFromDiagramWorker({
                      inventoryVm,
                      topologyLimits,
                      topologyUid: { uid: topologyUid },
                      vmNetworkInterfaces: getValues().interfaces,
                    })
                : onAddClicked
            }
          >
            {t('buttons.add')}
          </PrimaryButton>
        }
        pageTitle={t('virtualMachines.configure.title')}
      />
      <form>
        <ConfigureFormFields
          networks={networks}
          options={getNetworkOptions(networks || [])}
          interfaces={interfacesForForm}
        />
      </form>
    </FormProvider>
  );
}
