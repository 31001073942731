import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type UseHeroCarouselReturn = {
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
};
type UseHeroCarouselArgs = {
  numOfItems: number;
  transitionTime: number;
  resetTransitionTime: number;
};

export const useHeroCarousel = ({
  numOfItems,
  transitionTime,
  resetTransitionTime,
}: UseHeroCarouselArgs): UseHeroCarouselReturn => {
  const FAKE_FIRST_SLIDE_INDEX = 0;
  const FIRST_SLIDE_INDEX = 1;
  const FAKE_LAST_SLIDE_INDEX = numOfItems - 1;

  const [activeIndex, setActiveIndex] = useState(FAKE_FIRST_SLIDE_INDEX);

  useEffect(() => {
    let transitionTimeout: NodeJS.Timeout;
    let lastFakeSlideToFirstFakeSlideTransition: NodeJS.Timeout;

    if (activeIndex === FAKE_FIRST_SLIDE_INDEX) {
      setActiveIndex(FIRST_SLIDE_INDEX);
    } else if (activeIndex === FAKE_LAST_SLIDE_INDEX) {
      lastFakeSlideToFirstFakeSlideTransition = setTimeout(() => {
        setActiveIndex(FAKE_FIRST_SLIDE_INDEX);
      }, resetTransitionTime);
    } else {
      transitionTimeout = setTimeout(() => {
        if (activeIndex < FAKE_LAST_SLIDE_INDEX) {
          setActiveIndex(activeIndex + 1);
        }
      }, transitionTime);
    }

    return () => {
      transitionTimeout && clearTimeout(transitionTimeout);
      lastFakeSlideToFirstFakeSlideTransition &&
        clearTimeout(lastFakeSlideToFirstFakeSlideTransition);
    };
  }, [FAKE_LAST_SLIDE_INDEX, activeIndex, transitionTime, resetTransitionTime]);

  return {
    activeIndex,
    setActiveIndex,
  };
};
