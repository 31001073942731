import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { willCpuLimitBeExceeded, willMemoryLimitBeExceeded } from '../utils';

type UseShowTopologyLimitsWarningEditVmReturn = {
  onAddClicked: (formData: VmEditFormData) => void;
};

export type UseShowTopologyLimitsWarningEditVmProps = {
  topologyLimitsWarningAccepted?: boolean;
  resetTopologyLimits: VoidFunction;
  updateVm: SubmitHandler<VmEditFormData>;
  vm?: VirtualMachine;
  topologyLimits?: TopologyLimits;
  setShowTopologyLimitWarningForEntity: ActionCreatorWithOptionalPayload<
    | TopologyLimitsDisplayLabels
    | Array<TopologyLimitsDisplayLabels>
    | undefined,
    string
  >;
};

export function useShowTopologyLimitsWarningEditVm({
  topologyLimitsWarningAccepted,
  resetTopologyLimits,
  updateVm,
  vm,
  topologyLimits,
  setShowTopologyLimitWarningForEntity,
}: UseShowTopologyLimitsWarningEditVmProps): UseShowTopologyLimitsWarningEditVmReturn {
  const [formData, setFormdata] = useState<VmEditFormData>();

  useEffect(() => {
    if (topologyLimitsWarningAccepted) {
      resetTopologyLimits();
      updateVm(formData);
    }
  }, [updateVm, resetTopologyLimits, topologyLimitsWarningAccepted, formData]);

  const onAddClicked = (formData: VmEditFormData) => {
    resetTopologyLimits();
    setFormdata(formData);
    if (!vm || !topologyLimits) {
      updateVm(formData);
      return;
    }
    const memoryLimitExceeded = willMemoryLimitBeExceeded(
      vm.memoryMb,
      formData.memoryMb,
      topologyLimits.memoryMb,
    );
    const cpuLimitExceeded = willCpuLimitBeExceeded(
      vm.cpuQty,
      formData.cpuQty,
      topologyLimits.cpuCores,
    );

    switch (memoryLimitExceeded) {
      case true:
        cpuLimitExceeded
          ? setShowTopologyLimitWarningForEntity(['Memory (GB)', 'vCPUs'])
          : setShowTopologyLimitWarningForEntity('Memory (GB)');
        break;
      case false:
        cpuLimitExceeded
          ? setShowTopologyLimitWarningForEntity('vCPUs')
          : updateVm(formData);
        break;
      default:
        updateVm(formData);
        break;
    }
  };

  return { onAddClicked };
}
