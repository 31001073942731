import { useState } from 'react';

type HardwareItemDefaultProps = Pick<InventoryHardwareItemToAdd, 'topology'>;

export interface UseSelectInventoryItem {
  topologyUid: string;
}

export interface UseSelectHardwareItemsProps {
  handleOnSelect: (
    isSelected: boolean,
    hardwareItem: InventoryHardwareItem,
  ) => void;
  selectedHardwareItems: InventoryHardwareItemToAdd[];
}

export function useSelectHardwareInventoryItems({
  topologyUid,
}: UseSelectInventoryItem): UseSelectHardwareItemsProps {
  const [selected, setSelected] = useState<InventoryHardwareItemToAdd[]>([]);

  const defaultHardwareItemProps: HardwareItemDefaultProps = {
    topology: { uid: topologyUid },
  };

  const handleOnSelect: UseSelectHardwareItemsProps['handleOnSelect'] = (
    isSelected,
    hardwareItem,
  ) => {
    setSelected((prev) =>
      isSelected
        ? [
            ...prev,
            {
              inventoryHardwareItem: { id: hardwareItem.id },
              ...defaultHardwareItemProps,
            },
          ]
        : prev.filter(
            ({ inventoryHardwareItem: { id } }) => id !== hardwareItem.id,
          ),
    );
  };

  return { handleOnSelect, selectedHardwareItems: selected };
}
