import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { setShowRenameSessionModal } from '../../../../../../../../redux/views/session-view/session/slice';
import { useUpdateSessionMutation } from '../../../../../../../../redux/views/session-view/session/api/api';
import { RenameSessionForm } from '.';

function RenameSessionContainer({
  setShowRenameSessionModal,
}: RenameSessionFormProps): ReactElement {
  const [renameSession, { isLoading }] = useUpdateSessionMutation();

  return (
    <RenameSessionForm
      setShowRenameSessionModal={setShowRenameSessionModal}
      isLoading={isLoading}
      renameSession={renameSession}
    />
  );
}

const mapDispatchToProps = {
  setShowRenameSessionModal,
};
export type RenameSessionFormProps = typeof mapDispatchToProps;

export const ConnectedRenameSession = connect(
  null,
  mapDispatchToProps,
)(RenameSessionContainer);
