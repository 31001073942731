import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HelpButton } from '../../../../../components/HelpButton';
import { PageHeading } from '../../../../../components/PageHeading';
import { NAT_RULES_HELP_LINKS } from '../../../../../constants/user-guide';
import { useGetVmNicTargetsQuery } from '../../../../../redux/configuration/traffic/api';
import { NatRuleDeleteModal } from './components/NatRuleDeleteModal';
import { ConnectedNatRulesAddForm } from './components/NatRulesAddForm/container';
import { NatRulesTable } from './components/NatRulesTable';
import { NatRulesContainerProps, NatRulesRTKProps } from './container';

export type NatRulesProps = NatRulesContainerProps & NatRulesRTKProps;

export function NatRules({
  isError,
  isLoading,
  natRuleToDelete,
  natRules,
  setNatRuleToDelete,
}: NatRulesProps): ReactElement | null {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<TopologyUid>();
  const { data: vmTargetsData } = useGetVmNicTargetsQuery(topologyUid);

  const onDeleteClick = useCallback(
    (natRule: NatRule) => {
      setNatRuleToDelete(natRule);
    },
    [setNatRuleToDelete],
  );

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('traffic.natRules.name')} />
      <ConnectedNatRulesAddForm internalIps={vmTargetsData?.internalIps} />
      {!natRules?.length && !isLoading ? (
        <Alert colour="info">{t('traffic.natRules.empty')}</Alert>
      ) : (
        <NatRulesTable
          onDeleteClick={onDeleteClick}
          natRules={natRules || []}
          isError={isError}
          isLoading={isLoading}
        />
      )}
      {natRuleToDelete && (
        <NatRuleDeleteModal
          setNatRuleToDelete={setNatRuleToDelete}
          natRule={natRuleToDelete}
        />
      )}
      <HelpButton links={NAT_RULES_HELP_LINKS} />
    </section>
  );
}
