import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import { addErrorToast } from '../../../toast/slice';
import {
  resetTopologyLimits,
  topologyLimitsSelectors,
} from '../../../configuration/topologyLimits/slice';
import i18n from '../../../../i18n';
import { diagramApi } from '../../api';
import {
  setShowAddHardwareSavingModal,
  setShowAddVmSavingModal,
} from '../../slice';
import { addVirtualMachineFromDemosTabHelper } from '../../sagas/addVm/fromDemosTab/addVmFromDemosTabSagas';
import { addVirtualMachineFromTemplatesTabHelper } from '../../sagas/addVm/fromTemplatesTab/addVmFromTemplatesTabSagas';
import { addHardwareItemsHelper } from '../../sagas';

export function* refetchDiagramDataHelper(topologyUid: string): SagaIterator {
  let putResult;
  try {
    const callResult = yield call(
      diagramApi.endpoints.getDiagram.initiate,
      topologyUid,
    );
    putResult = yield put(callResult);
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    if (putResult) {
      yield call(putResult.unsubscribe);
    }
  }
}

export function* handleTopologyLimitsWarningAcceptedInDiagramWorker({
  payload,
}: PayloadAction<boolean | undefined>): SagaIterator {
  try {
    const isAccepted = payload;
    if (isAccepted) {
      const type: string = yield select(
        topologyLimitsSelectors.getShowTopologyLimitWarningForEntity,
      );

      if (type === 'Devices') {
        const itemsToAdd: Omit<
          InventoryHardwareItemsToAddFromDiagram,
          'topologyLimit'
        > = yield select(topologyLimitsSelectors.getItemsToAdd);

        yield call(addHardwareItemsHelper, itemsToAdd.items);
        yield call(refetchDiagramDataHelper, itemsToAdd.topologyUid);
      }

      if (
        type === 'Memory (GB)' ||
        type === 'vCPUs' ||
        (Array.isArray(type) &&
          (type.includes('Memory (GB)') || type.includes('vCPUs')))
      ) {
        const vmToAddFromTemplatesTab: Omit<
          VirtualMachineToAddFromTemplatesTabFromDiagram,
          'topologyLimits'
        > = yield select(topologyLimitsSelectors.getVmToAddFromTemplateTab);

        if (vmToAddFromTemplatesTab) {
          const { inventoryVm, topologyUid, vmNetworkInterfaces } =
            vmToAddFromTemplatesTab;
          yield call(
            addVirtualMachineFromTemplatesTabHelper,
            topologyUid,
            vmNetworkInterfaces,
            inventoryVm,
          );
        } else {
          const vmToAddFromDemosTab: Omit<
            VirtualMachineToAddFromDemosTabFromDiagram,
            'topologyLimits'
          > = yield select(topologyLimitsSelectors.getVmToAddFromDemoTab);

          if (vmToAddFromDemosTab) {
            const { vm, topologyUid, vmNetworkInterfaces } =
              vmToAddFromDemosTab;

            if (!vm) {
              yield put(
                addErrorToast({ message: i18n.t('common.errorMessage') }),
              );
              return;
            }

            yield call(
              addVirtualMachineFromDemosTabHelper,
              topologyUid,
              vmNetworkInterfaces,
              vm.uid,
            );
          }
        }
      }
    }
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    yield put(setShowAddHardwareSavingModal(false));
    yield put(setShowAddVmSavingModal(false));
    yield put(resetTopologyLimits());
  }
}
