/* eslint @typescript-eslint/no-explicit-any: 0 */
import { Textfield } from 'dcloud-shared-ui';
import React, { ChangeEventHandler, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { ControlledFieldProps } from '../types';
import { ControlledTextFieldSkeleton } from './ControlledTextFieldSkeleton';

export function ControlledTextfield<T extends Record<string, any>>({
  control,
  transform,
  isNumber,
  defaultValue = '',
  error,
  horizontal = false,
  label,
  labelClass,
  loading = false,
  name,
  placeholder,
  required = false,
  readonly = false,
  testId,
  id,
  customOnChange,
  ...inputProps
}: ControlledFieldProps<T> & {
  customOnChange?: ChangeEventHandler<HTMLInputElement>;
}): ReactElement {
  if (loading) {
    return <ControlledTextFieldSkeleton />;
  }

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      render={({ name: fieldName, onChange, onBlur, value }) => (
        <Textfield
          error={error}
          horizontal={horizontal}
          label={label}
          labelClass={labelClass}
          name={fieldName}
          onChange={
            isNumber && transform
              ? (e) => {
                  onChange(transform.output(e));
                  customOnChange && customOnChange(e);
                }
              : (e) => {
                  onChange(e);
                  customOnChange && customOnChange(e);
                }
          }
          onBlur={onBlur}
          value={isNumber && transform ? transform.input(value) : value}
          required={required}
          readOnly={readonly}
          data-testid={testId}
          id={id}
          placeholder={placeholder}
          {...inputProps}
        />
      )}
    />
  );
}
