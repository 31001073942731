import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useGetVmQuery } from '../../../../../../redux/configuration/virtual-machine-entities/api';
import { useGetOperatingSystemsQuery } from '../../../../../../redux/configuration/operating-systems/api';
import {
  setNetworkingToEdit,
  setVirtualMachineToEdit,
} from '../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../redux/store';
import { getOperatingSystems } from '../../../../../../redux/entities/operating-systems/slice';
import { customEtagSelectors } from '../../../../../../redux/configuration/etagCollection/slice';
import {
  determineRDPEnabledNIC,
  determineSSHEnabledNIC,
} from '../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/utils';
import { EditVmTemplateFormLoader } from '../FormLoader';
import { ConnectedInventoryTemplateVmEdit } from './Form/container';

export function TemplateVmEditContainer({
  setNetworkingToEdit: onSetNetworkingToEdit,
  setVirtualMachineToEdit: onSetVirtualMachineToEdit,
  inventoryVmUid,
  operatingSystems,
  customEtags,
}: TemplateVmEditContainer): ReactElement {
  const {
    data: inventoryVmToEdit,
    isFetching: isFetchingVM,
    rdpEnabledNic,
    sshEnabledNic,
  } = useGetVmQuery(inventoryVmUid, {
    selectFromResult: ({ data, isFetching, isError }) => ({
      data,
      isError,
      isFetching,
      rdpEnabledNic: determineRDPEnabledNIC(data?.vmNetworkInterfaces || []),
      sshEnabledNic: determineSSHEnabledNIC(data?.vmNetworkInterfaces || []),
    }),
    skip: !inventoryVmUid,
  });

  useEffect(() => {
    if (inventoryVmToEdit) {
      onSetNetworkingToEdit(inventoryVmToEdit.vmNetworkInterfaces);
    }

    return () => {
      onSetNetworkingToEdit(undefined);
    };
  }, [onSetNetworkingToEdit, inventoryVmToEdit]);

  useEffect(() => {
    if (inventoryVmToEdit) {
      onSetVirtualMachineToEdit(inventoryVmToEdit);
    }

    return () => {
      onSetVirtualMachineToEdit(undefined);
    };
  }, [onSetVirtualMachineToEdit, inventoryVmToEdit]);

  const { isError: isErrorLoadingOperatingSystems } =
    useGetOperatingSystemsQuery(undefined, {
      skip: operatingSystems !== undefined,
    });

  if (!operatingSystems || isFetchingVM || isErrorLoadingOperatingSystems) {
    return <EditVmTemplateFormLoader />;
  }

  return (
    <ConnectedInventoryTemplateVmEdit
      customEtags={customEtags}
      rdpEnabledNic={rdpEnabledNic}
      setNetworkingToEdit={onSetNetworkingToEdit}
      sshEnabledNic={sshEnabledNic}
      vmToEdit={inventoryVmToEdit}
    />
  );
}

type TemplateVmEditContainer = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ConnectedVirtualMachinesEditProps;

type ConnectedVirtualMachinesEditOwnProps = {
  inventoryVmUid: string;
};

type ConnectedVirtualMachinesEditProps = {
  inventoryVmId?: string;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedVirtualMachinesEditOwnProps,
) => ({
  customEtags: customEtagSelectors.getAllCustomEtags(state),
  inventoryVmUid: ownProps.inventoryVmUid,
  operatingSystems: getOperatingSystems(state).operatingSystems,
});

const mapDispatchToProps = {
  setNetworkingToEdit,
  setVirtualMachineToEdit,
};

export const ConnectedTemplateVMEdit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateVmEditContainer);
