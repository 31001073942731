import React, { ReactElement } from 'react';
import { useDeleteNodeMutation } from '../../../../redux/diagram/api';
import { FourHandleNode } from './index';

export type ConnectedFourHandleNodeProps = {
  display: DiagramBoxDisplay;
  isReadOnly: boolean;
  uid: string;
  className?: string;
  isSnapToGridEnabled?: boolean;
};

export type FourHandleNodeProps = ConnectedFourHandleNodeProps & {
  deleteNode: RTKMutation<string, undefined>;
};

export function ConnectedFourHandleNode({
  className,
  display,
  isReadOnly,
  uid,
  isSnapToGridEnabled,
}: ConnectedFourHandleNodeProps): ReactElement {
  const [deleteNode] = useDeleteNodeMutation();
  return (
    <FourHandleNode
      className={className}
      display={display}
      isReadOnly={isReadOnly}
      uid={uid}
      deleteNode={deleteNode}
      isSnapToGridEnabled={isSnapToGridEnabled}
    ></FourHandleNode>
  );
}
