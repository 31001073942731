import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useDeleteSaasIntegrationMutation } from '../../../../../../redux/configuration/saas/api';
import {
  setInventorySaasTemplateToDelete,
  saasSelectors,
} from '../../../../../../redux/configuration/saas/slice';
import { RootState } from '../../../../../../redux/store';
import { SaasIntegrationDeleteModal } from '.';

export type DeleteSaasIntegrationModalContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

function DeleteSaasIntegrationModalContainer({
  saasIntegrationtoDelete,
  setInventorySaasTemplateToDelete,
}: DeleteSaasIntegrationModalContainerProps): ReactElement {
  const [deleteSaasIntegration, { isLoading: isDeleting, isSuccess, isError }] =
    useDeleteSaasIntegrationMutation();

  return (
    <SaasIntegrationDeleteModal
      setInventorySaasTemplateToDelete={setInventorySaasTemplateToDelete}
      saasIntegrationtoDelete={saasIntegrationtoDelete}
      deleteSaasIntegration={deleteSaasIntegration}
      isSuccess={isSuccess}
      isError={isError}
      isDeleting={isDeleting}
    />
  );
}

type SaasIntegrationDeleteModalRTKProps = {
  deleteSaasIntegration: RTKMutation<string, undefined>;
  isDeleting: boolean;
  isSuccess: boolean;
  isError: boolean;
};

export type SaasIntegrationDeleteModalProps =
  DeleteSaasIntegrationModalContainerProps & SaasIntegrationDeleteModalRTKProps;

const mapStateToProps = (state: RootState) => ({
  saasIntegrationtoDelete:
    saasSelectors.getInventorySaasTemplateToDelete(state),
});

const mapDispatchToProps = {
  setInventorySaasTemplateToDelete,
};

export const ConnectedDeleteSaasIntegrationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteSaasIntegrationModalContainer);
