import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useGetAllVmTemplatesQuery } from '../../../../redux/templateManager/VmTemplates/api';
import { RootState } from '../../../../redux/store';
import {
  setSelectedInventoryItem,
  templateManagerSelectors,
} from '../../../../redux/templateManager/slice';
import { TableLoader } from '../../../../components/Table/TableLoader';
import { prepTemplatedVmsForTable } from './utils';
import { VmTemplates } from '.';

function ManageVmTemplatesContainer({
  selectedDatacenter,
  setSelectedInventoryItem,
  selectedInventoryItem,
}: ManageVmTemplatesContainerProps): ReactElement {
  const { isFetching, isLoading, isError, transformedTemplateVms } =
    useGetAllVmTemplatesQuery(selectedDatacenter!, {
      selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
        data,
        isError,
        isFetching,
        isLoading,
        transformedTemplateVms: data
          ? prepTemplatedVmsForTable(data)
          : undefined,
      }),
    });

  if (!transformedTemplateVms || isLoading || isFetching || isError) {
    return <TableLoader />;
  }

  return (
    <VmTemplates
      vmTemplates={transformedTemplateVms || []}
      selectedInventoryItem={selectedInventoryItem}
      setSelectedInventoryItem={setSelectedInventoryItem}
      isLoading={isLoading || isFetching || isError}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  selectedDatacenter: templateManagerSelectors.getSelectedDataCenter(state),
  selectedInventoryItem:
    templateManagerSelectors.getSelectedInventoryItem(state),
});

const mapDispatchToProps = {
  setSelectedInventoryItem,
};

export type ManageVmTemplatesContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const ConnectedVmTemplates = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageVmTemplatesContainer);
