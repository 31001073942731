import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { dcloudApi } from '../../api';
import {
  INVENTORY_DEMO_VMS_PATH,
  INVENTORY_TEMPLATE_TOPOLOGIES_PATH,
  INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH,
  INVENTORY_VIRTUAL_MACHINES_TEMPLATES_PATH,
  TOPOLOGIES_PATH,
  VM_CLONE_PATH,
  VM_NETWORK_INTERFACE_TYPE,
  VMS_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { setNicTypes } from '../../entities/nic-types/slice';
import { clearGivenEtag } from '../etagCollection/slice';
import { setVmIdToConfigureInDiagram } from '../../diagram/slice';
import { diagramApi } from '../../diagram/api';
import { TAGS } from '../../api/constants';

type GetDemoVmsProps = {
  demoId: string;
  topologyUid: string;
};

export type PostVmProps = {
  payload: VmToCreate;
};

type CloneVmPayload = {
  payload: VmToClone;
};

export const virtualMachineEntitiesApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    cloneVm: build.mutation<VirtualMachine, CloneVmPayload>({
      invalidatesTags: [
        TAGS.VIRTUAL_MACHINES,
        TAGS.VIRTUAL_MACHINES_ALL,
        TAGS.TOPOLOGY_LIMITS,
      ],
      query: ({ payload }) => ({
        data: payload,
        errorMessage: i18n.t('virtualMachines.add.error'),
        method: 'POST',
        successMessage: i18n.t('virtualMachines.add.success'),
        url: `${VM_CLONE_PATH}`,
      }),
    }),
    createVm: build.mutation<VirtualMachine, PostVmProps>({
      invalidatesTags: [TAGS.TOPOLOGY_LIMITS],
      query: ({
        payload: { vmNetworkInterfaces, inventoryVmId, topology },
      }) => ({
        data: { inventoryVmId, topology, vmNetworkInterfaces },
        errorMessage: i18n.t('virtualMachines.add.error'),
        method: 'POST',
        successMessage: i18n.t('virtualMachines.add.success'),
        url: `${VMS_PATH}`,
      }),
    }),
    deleteVM: build.mutation<VirtualMachine, VmToDeletePayload>({
      invalidatesTags: [
        TAGS.VIRTUAL_MACHINES,
        TAGS.VIRTUAL_MACHINES_ALL,
        TAGS.TOPOLOGY_LIMITS,
      ],
      query: ({ id }) => ({
        errorMessage: i18n.t('virtualMachines.delete.error'),
        method: 'delete',
        successMessage: i18n.t('virtualMachines.delete.success'),
        url: `${VMS_PATH}/${id}`,
      }),
    }),
    getAll: build.query<VirtualMachine[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINES_ALL],
      query: (topologyUid) => ({
        errorMessage: i18n.t('virtualMachines.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}/${VMS_PATH}`,
      }),
      transformResponse: ({
        vms,
      }: BaseQueryApi & FetchVirtualMachinesAxiosResponse) => vms,
    }),
    getDemoVms: build.query<InventoryVirtualMachine[], GetDemoVmsProps>({
      providesTags: [TAGS.VIRTUAL_MACHINES_TEMPLATES_INVENTORY],
      query: ({ demoId, topologyUid }) => ({
        errorMessage: i18n.t('virtualMachines.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH}/${demoId}${INVENTORY_DEMO_VMS_PATH}`,
      }),
      transformResponse: (response: FetchInventoryDemoVmsResponse) =>
        response.inventoryVms,
    }),
    getInventoryTemplateTopologies: build.query<InventoryTopologies, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INVENTORY_TEMPLATED_TOPOLOGIES],
      query: (topologyUid) => ({
        errorMessage: i18n.t('inventoryTopologies.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_TEMPLATE_TOPOLOGIES_PATH}`,
      }),
      transformResponse: (response: FetchInventoryTopologiesResponse) =>
        response.inventoryTopologies,
    }),
    getVMDemosInventory: build.query<InventoryDemo[], string>({
      providesTags: [TAGS.VIRTUAL_MACHINES_TEMPLATES_INVENTORY],
      query: (topologyUid) => ({
        errorMessage: i18n.t('demos.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH}`,
      }),
      transformResponse: (response: FetchInventoryDemosResponse) =>
        response.inventoryDemos,
    }),
    getVMTemplatesInventory: build.query<InventoryVirtualMachine[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINES_TEMPLATES_INVENTORY],
      query: (topologyUid) => ({
        errorMessage: i18n.t('inventoryVirtualMachines.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_VIRTUAL_MACHINES_TEMPLATES_PATH}`,
      }),
      transformResponse: (
        response: FetchInventoryTemplateVirtualMachineResponse,
      ) => response.inventoryVms,
    }),
    getVm: build.query<VirtualMachine, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINE],
      query: (vmUid) => ({
        errorMessage: i18n.t('virtualMachines.fetch.errors.one'),
        method: 'get',
        url: `${VMS_PATH}/${vmUid}`,
      }),
    }),
    getVmNetworkInterfaceTypes: build.query<
      VmNetworkInterfaceType[],
      undefined
    >({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setNicTypes(data));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.VIRTUAL_MACHINES_NETWORK_INTERFACE_TYPES],
      query: () => ({
        errorMessage: i18n.t(
          'virtualMachines.edit.networkInterfaceTypes.error',
        ),
        method: 'get',
        url: VM_NETWORK_INTERFACE_TYPE,
      }),
      transformResponse: (response: FetchVmNetworkInterfaceTypesResponse) =>
        response.vmNetworkInterfaceTypes,
    }),
    getVms: build.query<VirtualMachine[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINES],
      query: (topologyUid) => ({
        errorMessage: i18n.t('virtualMachines.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}/${VMS_PATH}`,
      }),
      transformResponse: ({
        vms,
      }: BaseQueryApi & FetchVirtualMachinesAxiosResponse) => vms,
    }),
    updateVm: build.mutation<VirtualMachine, VmToUpdatePayload>({
      invalidatesTags: [
        TAGS.VIRTUAL_MACHINE,
        TAGS.VIRTUAL_MACHINES_ALL,
        TAGS.TOPOLOGY_LIMITS,
      ],
      async onQueryStarted(
        { isInvalidateDiagramTagNeeded },
        { dispatch, queryFulfilled },
      ) {
        try {
          dispatch(clearGivenEtag('/vms/'));
          await queryFulfilled;
          dispatch(setVmIdToConfigureInDiagram(undefined));
          if (isInvalidateDiagramTagNeeded) {
            dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
          }
        } catch (err) {
          dispatch(setVmIdToConfigureInDiagram(undefined));
          throw err;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ etag, isInvalidateDiagramTagNeeded: _, ...vm }) => ({
        data: vm,
        errorMessage: i18n.t('virtualMachines.edit.error'),
        method: 'put',
        overrideEtag: etag,
        successMessage: i18n.t('virtualMachines.edit.success'),
        url: `${VMS_PATH}/${vm.uid}`,
      }),
    }),
  }),
});

export const {
  useGetAllQuery,
  useGetInventoryTemplateTopologiesQuery,
  useGetVmNetworkInterfaceTypesQuery,
  useGetVmQuery,
  useGetVmsQuery,
  useGetVMTemplatesInventoryQuery,
  useGetDemoVmsQuery,
  useDeleteVMMutation,
  useUpdateVmMutation,
  useCreateVmMutation,
  useCloneVmMutation,
} = virtualMachineEntitiesApi;
