import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DiagramProvider } from '../components';
import { ActionsBar } from './components/TopActions/ActionsBar';
import { SessionDiagramContainer } from './components/SessionDiagram/container';
import { TimerExpiredModalContainer } from './components/Modals/TimerExpiredModal/container';
import { ConnectedEndSession } from './components/Modals/EndSession/container';
import { SaveAndEndSessionModalContainer } from './components/Modals/SaveAndEndModal/container';
import { ConnectedResetSessionModal } from './components/Modals/ResetSession/container';
import { RenameSessionModalContainer } from './components/Modals/RenameSession/container';
import { ExtendSessionModalContainer } from './components/Modals/ExtendSession/container';
import { LeftPanelContainer } from './components/Panels/LeftPanel/container';
import { SessionViewProps } from './container';

export function SessionView({ leftPanelType }: SessionViewProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('sessions.siteTitle');
  }, [t]);

  return (
    <DiagramProvider>
      <ActionsBar />
      <SessionDiagramContainer />
      <TimerExpiredModalContainer />
      <ConnectedEndSession />
      <SaveAndEndSessionModalContainer />
      <ConnectedResetSessionModal />
      <RenameSessionModalContainer />
      <ExtendSessionModalContainer />
      {!!leftPanelType && <LeftPanelContainer />}
    </DiagramProvider>
  );
}
