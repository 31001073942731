import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from './TableActions.module.css';

interface TableActionsProps {
  hardware: Hardware;
  onDeleteClick: (hardware: Hardware) => void;
}

export function TableActions({
  hardware,
  onDeleteClick,
}: TableActionsProps): ReactElement {
  const { url } = useRouteMatch();

  return (
    <ul aria-label="Card actions" className={styles.root}>
      <li className={styles.action}>
        <Link
          to={`${url}/${hardware.uid}`}
          className={styles.linkAction}
          data-test="hardware-table-actions-edit"
        >
          <Icon name="edit" />
        </Link>
      </li>
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={() => onDeleteClick(hardware)}
          data-test="hardware-table-actions-delete"
        >
          <Icon name="delete" />
        </Button>
      </li>
    </ul>
  );
}
