import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  addTagToIntegrationToUpdate,
  integrationSelectors,
  updateTags,
} from '../../../../redux/integrations/slice';
import { RootState } from '../../../../redux/store';
import { useGetIntegrationTagsQuery } from '../../../../redux/integrations/api';
import { TagsFormSkeleton } from '../../../../components/Tags/TagsFormSkeleton';
import TagsForm from '.';

const TagsFormContainer = ({
  addTagToIntegrationToUpdate,
  integrationToUpdate,
  updateTags,
  tagError,
  setTagError,
  selectedTags,
}: CreateIntegrationContainerProps): ReactElement => {
  const {
    data: fetchedIntegrationTags,
    isLoading,
    isError,
  } = useGetIntegrationTagsQuery(undefined);
  return !fetchedIntegrationTags || isLoading || isError ? (
    <TagsFormSkeleton />
  ) : (
    <TagsForm
      addTag={addTagToIntegrationToUpdate}
      integrationToUpdate={integrationToUpdate}
      setTags={updateTags}
      allTags={fetchedIntegrationTags.tags}
      setTagError={setTagError}
      tagError={tagError}
      selectedTags={selectedTags}
    />
  );
};

type OwnProps = {
  tagError: boolean;
  setTagError: (value: boolean) => void;
  selectedTags: Tag[];
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  integrationToUpdate: integrationSelectors.getIntegrationToUpdate(state),
  selectedTags: ownProps.selectedTags,
  setTagError: ownProps.setTagError,
  tagError: ownProps.tagError,
});

const mapDispatchToProps = {
  addTagToIntegrationToUpdate,
  updateTags,
};

type CreateIntegrationContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedTagForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TagsFormContainer);
