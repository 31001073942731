import { NavigationTab } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ConnectedDemosTabContent } from './components/DemosTabContent/container';
import { ConnectedVMTemplatesTabContent } from './components/TemplateTabContent/container';

export enum AddVirtualMachinesRoutes {
  Demos = '/demos',
  Templates = '/templates',
}

export const routes: NavigationTab[] = [
  {
    component: ConnectedVMTemplatesTabContent,
    label: 'VM Templates',
    pathname: AddVirtualMachinesRoutes.Templates,
  },
  {
    component: ConnectedDemosTabContent,
    label: 'Demo Templates',
    pathname: AddVirtualMachinesRoutes.Demos,
  },
];

export function VirtualMachinesAddRouter(): ReactElement {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      {routes.map(({ component, label, pathname }) => (
        <Route path={`${path}${pathname}`} component={component} key={label} />
      ))}
      <Redirect to={`${url.substring(0, url.lastIndexOf('/'))}`} />
    </Switch>
  );
}
