import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction } from '@reduxjs/toolkit';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import {
  useFetchAllHardwareQuery,
  useGetHardwareItemQuery,
  useUpdateHardwareItemMutation,
} from '../../../../../redux/configuration/hardware/api';
import {
  getHardware,
  setNetworkInterfaceToDelete,
} from '../../../../../redux/configuration/hardware/slice';
import { RootState } from '../../../../../redux/store';
import {
  diagramApi,
  useUpdateNodeMutation,
} from '../../../../../redux/diagram/api';
import {
  diagramSelectors,
  setDeviceIdToConfigureInDiagram,
} from '../../../../../redux/diagram/slice';
import { ConfigureDeviceFromDiagramLoader } from '../../../../Views/Diagram/components/ConfigureDeviceFromDiagram/ConfigureDeviceFromDiagramLoader';
import { Modal } from '../../../../../components';
import { TAGS } from '../../../../../redux/api/constants';
import styles from './Edit.module.css';
import { FormContainer } from './Form/container';

function EditHardwareContainer({
  deviceIdToConfigureInDiagram,
  isModal,
  networkInterfaceToDelete,
  setDeviceIdToConfigureInDiagram,
  setNetworkInterfaceToDelete,
}: HardwareContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data: allHardware, isLoading } =
    useFetchAllHardwareQuery(topologyUid);
  const hardwareUidFromParams = useParams<{ hardwareUid: string }>()
    .hardwareUid;
  const hardwareUid =
    isModal && deviceIdToConfigureInDiagram
      ? deviceIdToConfigureInDiagram
      : hardwareUidFromParams;
  const { data: hardwareItem, isFetching } =
    useGetHardwareItemQuery(hardwareUid);

  const [updateHardwareItem, { isLoading: isUpdating }] =
    useUpdateHardwareItemMutation();

  const [updateNode, { isLoading: isNodeUpdating }] = useUpdateNodeMutation();

  if (!allHardware || !hardwareItem || isLoading) {
    return isModal ? (
      <Modal
        show={true}
        onClose={() => setDeviceIdToConfigureInDiagram(undefined)}
        size="small"
        clickScreenToClose={true}
        className={styles.diagramDeviceEditModal}
      >
        <ConfigureDeviceFromDiagramLoader />
      </Modal>
    ) : (
      <TableLoader />
    );
  }

  return (
    <section>
      <FormContainer
        allHardware={allHardware}
        updateHardwareItem={updateHardwareItem}
        hardwareItem={hardwareItem}
        isUpdating={isUpdating || isFetching || isNodeUpdating}
        setNetworkInterfaceToDelete={setNetworkInterfaceToDelete}
        networkInterfaceToDelete={networkInterfaceToDelete}
        updateNode={updateNode}
        isModal={isModal}
        setDeviceIdToConfigureInDiagram={setDeviceIdToConfigureInDiagram}
      />
    </section>
  );
}

type HardwareContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export interface HardwareRTKProps {
  allHardware: Hardware[];
  hardwareItem: Hardware;
  isUpdating: boolean;
  nodeData?: DiagramNode;
  updateHardwareItem: RTKMutation<UpdateHardwarePayload, Hardware>;
  updateNode: RTKMutation<UpdateDiagramNodeProps, DiagramNode>;
}

export type HardwareProps = HardwareContainerProps & HardwareRTKProps;

type OwnProps = {
  isModal?: boolean;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  deviceIdToConfigureInDiagram:
    diagramSelectors.getDeviceIdToConfigureInDiagram(state),
  isModal: ownProps.isModal,
  networkInterfaceToDelete: getHardware(state).networkInterfaceToDelete,
});

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => ({
  onModalClose: () => dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM])),
  setDeviceIdToConfigureInDiagram: (deviceId: string | undefined) =>
    dispatch(setDeviceIdToConfigureInDiagram(deviceId)),
  setNetworkInterfaceToDelete: (nic: string) =>
    dispatch(setNetworkInterfaceToDelete(nic)),
});

export const ConnectedEditHardware = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditHardwareContainer);
