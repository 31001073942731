import React, { ReactElement } from 'react';
import { Heading } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import {
  ActionsButtonGroup,
  ActionsButtonGroupProps,
} from '../../../../ActionsButtonGroup';

interface ControlProps {
  postVmAction: (action: SessionVmActions) => void;
}

export function Control({ postVmAction }: ControlProps): ReactElement {
  const { t } = useTranslation();

  const powerButtonActions: ActionsButtonGroupProps['actions'] = [
    {
      content: t('sessions.panels.vm.control.on'),
      onClick: () => postVmAction('VM_POWER_ON'),
    },
    {
      content: t('sessions.panels.vm.control.off'),
      onClick: () => postVmAction('VM_POWER_OFF'),
    },
    {
      content: t('sessions.panels.vm.control.reset'),
      onClick: () => postVmAction('VM_RESET'),
    },
  ];

  const guestOSButtonActions: ActionsButtonGroupProps['actions'] = [
    {
      content: t('sessions.panels.vm.control.reboot'),
      onClick: () => postVmAction('GUEST_OS_REBOOT'),
    },
    {
      content: t('sessions.panels.vm.control.shutdown'),
      onClick: () => postVmAction('GUEST_OS_SHUTDOWN'),
    },
  ];

  return (
    <>
      <Heading level="h2" size="18" align="left">
        {t('sessions.panels.vm.control.power')}
        <div className="base-padding">
          <ActionsButtonGroup actions={powerButtonActions} />
        </div>
      </Heading>
      <Heading level="h2" size="18" align="left">
        {t('sessions.panels.vm.control.guestOS')}
        <div className="base-padding">
          <ActionsButtonGroup actions={guestOSButtonActions} />
        </div>
      </Heading>
    </>
  );
}
