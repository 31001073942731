import React, { ReactElement } from 'react';
import { FormGroup, Switch } from 'dcloud-shared-ui';
import { Control, Controller } from 'react-hook-form';
import { ControlledFieldProps } from '../types';

export interface ControlledSwitchProps<T extends Record<string, unknown>>
  extends Pick<ControlledFieldProps<T>, 'className' | 'control' | 'label'> {
  defaultValue?: boolean;
  disabled?: boolean;
  id: keyof T;
  inline?: boolean;
  testId?: string;
  label: string;
  labelPosition?: 'left' | 'right';
  onChange?: (value: boolean) => void;
}

export function ControlledSwitch<T extends Record<string, unknown>>({
  className,
  control,
  defaultValue = false,
  disabled = false,
  label,
  labelPosition = 'left',
  id,
  inline = false,
  onChange,
  testId,
}: ControlledSwitchProps<T>): ReactElement {
  const typedId = id as string;

  return (
    <Controller
      name={typedId}
      control={control as Control}
      defaultValue={defaultValue}
      render={(props) => (
        <FormGroup inline={inline} className={className}>
          <Switch
            labelLeft={labelPosition === 'left' ? label : ''}
            labelRight={labelPosition === 'right' ? label : ''}
            disabled={disabled}
            id={typedId}
            onChange={(event) => {
              if (onChange) {
                onChange(event.target.checked);
              }
              return props.onChange(event.target.checked);
            }}
            checked={props.value}
            data-testid={testId}
          />
        </FormGroup>
      )}
    />
  );
}
