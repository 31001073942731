import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { orderBy } from 'lodash';
import { Accordion, AccordionProps } from '../../../../components/Accordion';
import styles from '../../Integrations.module.css';
import { ConnectedIntegrationInput } from '../../../CreateIntegration/components/IntegrationInput/container';
import { ConnectedIntegrationInputToEdit } from '../../../EditIntegration/components/IntegrationInput/container';

type IntegrationInputListProps = {
  inputsToCreate?: IntegrationInput[];
  inputsToEdit?: SaasTemplateInput[];
  isEditing?: boolean;
  inputStatuses?: { [key: string]: boolean };
};

export function IntegrationInputAccordions({
  inputsToCreate,
  inputsToEdit,
  inputStatuses,
  isEditing,
}: IntegrationInputListProps): ReactElement {
  const { t } = useTranslation();
  const accordionRows: AccordionProps['rows'] = isEditing
    ? orderBy(inputsToEdit || [], ['mappingName']).map((input) => ({
        children: <ConnectedIntegrationInputToEdit input={input} />,
        iconClassName:
          inputStatuses && inputStatuses[input.mappingName]
            ? styles.successIcon
            : styles.errorIcon,
        iconName:
          inputStatuses && inputStatuses[input.mappingName]
            ? 'check-outline'
            : 'exit-outline',
        title: `${input.mappingName}`,
      }))
    : orderBy(inputsToCreate || [], ['mappingName']).map((input) => ({
        children: <ConnectedIntegrationInput input={input} />,
        iconClassName:
          inputStatuses && inputStatuses[input.name]
            ? styles.successIcon
            : styles.errorIcon,
        iconName:
          inputStatuses && inputStatuses[input.name]
            ? 'check-outline'
            : 'exit-outline',
        title: `${input.name}`,
      }));
  return (
    <>
      <div className="half-padding">
        {t('integrations.forms.common.workflowParameterMappings')}
      </div>
      <Accordion rows={accordionRows} />
      {!accordionRows.length && (
        <Alert colour="info">
          {t('integrations.forms.common.inputs.errors.noDataFound')}
        </Alert>
      )}
    </>
  );
}
