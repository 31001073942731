import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { DEFAULT_CHECKED_SHOW_FILTERS } from '../../../../redux/topologiesFilters/constants';
import CheckboxWrapper from '../../../../components/CheckboxWrapper';
import { FilterColumn } from './FilterColumn';
import { FilterItems } from './FilterItems';
import { AccordionButton } from './AccordionButton';
import { FiltersProps } from './container';
import { DATA_CENTER_FILTERS, SHOW_FILTERS } from './constants';
import styles from './Filters.module.css';

export const Filters = ({
  dataCentersFilters,
  showFilters,
  addFilter,
  deleteFilter,
  addDataCenterFilter,
  deleteDataCenterFilter,
  isOpen,
  setIsOpen,
  isApplyingFilters,
}: FiltersProps): ReactElement => {
  const { t } = useTranslation();

  const clearAll = (event: { preventDefault: VoidFunction }): void => {
    SHOW_FILTERS.forEach((filter) => deleteFilter(filter.filterKey));
    DATA_CENTER_FILTERS.forEach((filter) =>
      deleteDataCenterFilter(filter.filterKey),
    );
    event.preventDefault();
  };

  const selectAll = (event: { preventDefault: VoidFunction }): void => {
    SHOW_FILTERS.forEach((filter) => {
      if (!showFilters.includes(filter.filterKey)) {
        addFilter(filter.filterKey);
      }
    });
    DATA_CENTER_FILTERS.forEach((filter) => {
      if (!dataCentersFilters.includes(filter.filterKey)) {
        addDataCenterFilter(filter.filterKey);
      }
    });
    event.preventDefault();
  };

  const resetAll = (event: { preventDefault: VoidFunction }): void => {
    SHOW_FILTERS.forEach((filter) => {
      if (DEFAULT_CHECKED_SHOW_FILTERS.includes(filter.filterKey)) {
        if (!showFilters.includes(filter.filterKey)) {
          addFilter(filter.filterKey);
        }
      } else {
        deleteFilter(filter.filterKey);
      }
    });
    DATA_CENTER_FILTERS.forEach((filter) => {
      if (!dataCentersFilters.includes(filter.filterKey)) {
        addDataCenterFilter(filter.filterKey);
      }
    });
    event.preventDefault();
  };

  return (
    <section
      className={classNames('base-margin-left base-margin-right', styles.root)}
    >
      <div className="col-12">
        <ul className="accordion">
          <li className={classNames({ active: isOpen })}>
            <AccordionButton
              isOpen={isOpen}
              isApplyingFilters={isApplyingFilters}
              setIsOpen={setIsOpen}
              filtersCount={
                uniq(showFilters).length + uniq(dataCentersFilters).length
              }
            />
            <div
              className={classNames('accordion__content half-padding-top', {
                [styles.accordionContentOpen]: isOpen,
              })}
            >
              <CheckboxWrapper
                className={styles.buttonLinks}
                clearAll={clearAll}
                resetAll={resetAll}
                selectAll={selectAll}
              >
                <div className={styles.filterColumns}>
                  <FilterColumn title={t('topologies.filters.show.title')}>
                    <FilterItems
                      availableFilters={SHOW_FILTERS}
                      selectedFilters={showFilters}
                      addFilter={addFilter}
                      deleteFilter={deleteFilter}
                    />
                  </FilterColumn>
                  <FilterColumn
                    title={t('topologies.filters.dataCenters.title')}
                  >
                    <FilterItems
                      availableFilters={DATA_CENTER_FILTERS}
                      selectedFilters={dataCentersFilters}
                      addFilter={addDataCenterFilter}
                      deleteFilter={deleteDataCenterFilter}
                    />
                  </FilterColumn>
                </div>
              </CheckboxWrapper>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
