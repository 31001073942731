import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit';
import { getVirtualMachines } from '../../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../../../redux/store';
import { getOperatingSystems } from '../../../../../../../../redux/entities/operating-systems/slice';
import { useUpdateVmMutation } from '../../../../../../../../redux/configuration/virtual-machine-entities/api';
import {
  useGetNodeQuery,
  useUpdateNodeMutation,
} from '../../../../../../../../redux/diagram/api';
import { EditLoader } from '../EditLoader';
import { extractIdFromUrl } from '../../../../../../../../utils/helpers';
import {
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
  topologyLimitsSelectors,
} from '../../../../../../../../redux/configuration/topologyLimits/slice';
import { topologyLimitsApi } from '../../../../../../../../redux/configuration/topologyLimits/api';
import { Form } from '.';

interface FormContainerProps {
  networkingToEdit?: VmNetworkingToEdit;
  operatingSystems?: OperatingSystem[];
  rdpEnabledNic?: VMNetworkInterface;
  setNetworkingToEdit: (networkingToEdit: VmNetworkInterfacesToEdit) => void;
  sshEnabledNic?: VMNetworkInterface;
  vmNames: TakenVmNames;
  vmToEdit: VirtualMachine;
  resetTopologyLimits: ActionCreatorWithoutPayload<string>;
  setShowTopologyLimitWarningForEntity: ActionCreatorWithOptionalPayload<
    TopologyLimitsDisplayLabels | TopologyLimitsDisplayLabels[] | undefined,
    string
  >;
  topologyLimitsWarningAccepted?: boolean;
  isModal?: boolean;
}

export function FormContainer({
  networkingToEdit,
  operatingSystems = [],
  rdpEnabledNic,
  setNetworkingToEdit,
  sshEnabledNic,
  vmNames,
  vmToEdit,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
  topologyLimitsWarningAccepted,
  isModal,
}: FormContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const [updateVM, { isLoading: isUpdating }] = useUpdateVmMutation();
  const [updateNode, { isLoading: isNodeUpdating }] = useUpdateNodeMutation();
  const { data: nodeData, isLoading: isNodeLoading } = useGetNodeQuery(
    extractIdFromUrl(vmToEdit._links.node.href),
  );
  const { topologyLimits } =
    topologyLimitsApi.endpoints.getTopologyLimits.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        topologyLimits: data,
      }),
    });

  return isNodeLoading || !nodeData ? (
    <EditLoader />
  ) : (
    <Form
      isUpdating={isUpdating}
      vmToEdit={vmToEdit}
      updateNode={updateNode}
      updateVM={updateVM}
      isNodeUpdating={isNodeUpdating}
      setNetworkingToEdit={setNetworkingToEdit}
      nodeData={nodeData!}
      vmNetworkingToEdit={networkingToEdit}
      operatingSystems={operatingSystems}
      rdpEnabledNic={rdpEnabledNic}
      sshEnabledNic={sshEnabledNic}
      vmNames={vmNames}
      resetTopologyLimits={resetTopologyLimits}
      setShowTopologyLimitWarningForEntity={
        setShowTopologyLimitWarningForEntity
      }
      topologyLimitsWarningAccepted={topologyLimitsWarningAccepted}
      topologyLimits={topologyLimits}
      isModal={isModal}
    />
  );
}

type OwnProps = {
  isModal?: boolean;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isModal: ownProps.isModal,
  networkingToEdit: getVirtualMachines(state).networkingToEdit,
  operatingSystems: getOperatingSystems(state).operatingSystems,
  topologyLimitsWarningAccepted:
    topologyLimitsSelectors.getTopologyLimitsWarningAccepted(state),
});

const mapDispatchToProps = {
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
};

export const ConnectedVirtualMachinesEditForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormContainer);
