import React, { ReactElement } from 'react';
import { PSTNServicesForm } from '../PSTNServicesForm';

interface TelephonyDeleteProps {
  onDelete: () => void;
}

export function TelephonyDelete({
  onDelete,
}: TelephonyDeleteProps): ReactElement {
  return (
    <section className="half-padding-left">
      <PSTNServicesForm defaultValue={true} onSubmit={onDelete} />
    </section>
  );
}
