import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetDocumentationQuery,
  useUpdateDocumentationMutation,
} from '../../../../redux/configuration/documentation/api';
import { Documentation } from '.';

export function DocumentationContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    data: documentation,
    isLoading,
    isFetching,
  } = useGetDocumentationQuery(topologyUid);
  const [updateDocumentation, { isLoading: isUpdating }] =
    useUpdateDocumentationMutation();

  return (
    <Documentation
      isLoading={isLoading || isFetching || isUpdating}
      updateDocumentation={updateDocumentation}
      documentation={documentation}
    />
  );
}

export interface DocumentationProps {
  isLoading: boolean;
  updateDocumentation: RTKMutation<DocumentationRequestData, Documentation>;
  documentation?: Documentation;
}
