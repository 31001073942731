import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

export type HandleOnHWNodeClick = {
  expired: boolean;
  setLeftPanelType: ActionCreatorWithOptionalPayload<SessionPanel | undefined>;
  setLeftPanelDeviceUid: ActionCreatorWithOptionalPayload<string | undefined>;
  isHardwareNodeInDiagramView?: string | false;
  nodeUid: string;
  assetUid?: string;
  setDeviceIdToConfigureInDiagram: ActionCreatorWithOptionalPayload<
    string | undefined
  >;
};

export const handleOnHWNodeClick = ({
  expired,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  isHardwareNodeInDiagramView,
  nodeUid,
  assetUid,
  setDeviceIdToConfigureInDiagram,
}: HandleOnHWNodeClick): void => {
  if (!expired) {
    setLeftPanelType('hardware');
    setLeftPanelDeviceUid(nodeUid);
  }

  if (isHardwareNodeInDiagramView && assetUid) {
    setDeviceIdToConfigureInDiagram(assetUid);
  }
};

type DetermineShouldRenderHwNodeActionButtons = {
  isHardwareNodeInDiagramView: string | false | undefined;
  isAddingLogicalConnection: boolean;
};

export const determineShouldRenderHwNodeActionButtons = ({
  isHardwareNodeInDiagramView,
  isAddingLogicalConnection,
}: DetermineShouldRenderHwNodeActionButtons): boolean => {
  if (
    isHardwareNodeInDiagramView !== false &&
    isHardwareNodeInDiagramView !== undefined
  ) {
    return !isAddingLogicalConnection;
  }
  return false;
};
