import { isAfter } from 'date-fns';
import { Icon } from 'dcloud-shared-ui';
import React, { ReactElement, useEffect, useState } from 'react';
import { INTERVAL_END, EXPIRED_TIMER_VALUE } from '../../../constants';
import {
  calculateInterval,
  formatIntervalWithDays,
  getNumberOfDaysUntilDate,
} from '../../../utils';
import styles from './ActionsBarTimer.module.css';
import { ActionsBarTimerProps } from './container';

export function ActionsBarTimer({
  expired,
  stopDateString,
  setExpired,
  setShowExpiredModal,
}: ActionsBarTimerProps): ReactElement {
  const [timerValue, setTimerValue] = useState('');
  const [interval, setInterval] = useState<Duration | undefined>({});
  const [numOfDays, setNumOfDays] = useState<number>(0);
  const [expiredLocal, setExpiredLocal] = useState(false);

  const handleTimerExpired = () => {
    if (!expiredLocal) {
      setExpired(true);
      setShowExpiredModal(true);
      setExpiredLocal(true);
    }
  };

  useEffect(() => {
    if (isAfter(new Date(), new Date(stopDateString))) {
      setExpired(true);
      setShowExpiredModal(true);
      setTimerValue(EXPIRED_TIMER_VALUE);
    } else {
      setInterval(calculateInterval(new Date(), new Date(stopDateString)));
      setNumOfDays(
        getNumberOfDaysUntilDate(new Date(), new Date(stopDateString)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopDateString]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (!isAfter(new Date(), new Date(stopDateString))) {
      timer = setTimeout(() => {
        if (interval?.seconds === 0) {
          setInterval(calculateInterval(new Date(), new Date(stopDateString)));
          setNumOfDays(
            getNumberOfDaysUntilDate(new Date(), new Date(stopDateString)),
          );
          setTimerValue(formatIntervalWithDays(interval, numOfDays));
        } else {
          setInterval({
            ...interval,
            seconds: interval?.seconds ? interval.seconds - 1 : 0,
          });
          setTimerValue(
            formatIntervalWithDays(interval || INTERVAL_END, numOfDays),
          );
        }
      }, 1000);
    } else {
      if (!expiredLocal) {
        handleTimerExpired();
      }
    }
    return () => clearTimeout(timer);
  });

  return (
    <div className={styles.root}>
      <Icon name="clock" className={styles.icon} />
      {expired ? EXPIRED_TIMER_VALUE : timerValue}
    </div>
  );
}
