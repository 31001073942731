import * as d3 from 'd3';
import { calculateAllConnectionsTotalValue } from '../../pages/Views/Diagram/components/DiagramTopMenu/utils/utilsHelpers';

interface updateNodeDisplayReducerProps {
  diagramResponse: DiagramResponse;
  display: DiagramDisplay;
  nodeUid: DiagramNode['uid'];
}

interface addNodeToNodeDisplayReducerProps {
  node: DiagramNode;
  diagramResponse: DiagramResponse;
}

export function updateNodeDisplayReducer({
  diagramResponse,
  display,
  nodeUid,
}: updateNodeDisplayReducerProps): DiagramResponse {
  const newNodes = diagramResponse.nodes.map((node) => {
    if (node.uid !== nodeUid) return node;

    return { ...node, display };
  });
  return { ...diagramResponse, nodes: newNodes };
}

type UpdateNodeDisplayReducer = {
  diagramResponse: DiagramResponse;
  display: DiagramDisplay;
  connectionUid: DiagramConnection['uid'];
};

export function updateConnectionsDisplayReducer({
  connectionUid,
  diagramResponse,
  display,
}: UpdateNodeDisplayReducer): DiagramResponse {
  const newConnections = diagramResponse.connections.map((connection) => {
    if (connection.uid !== connectionUid) return connection;

    return { ...connection, display };
  });
  return { ...diagramResponse, connections: newConnections };
}

export function addNodeToNodeDisplayReducer({
  diagramResponse,
  node,
}: addNodeToNodeDisplayReducerProps): DiagramResponse {
  return {
    ...diagramResponse,
    nodes: [...diagramResponse.nodes, { ...node }],
  };
}

type AddDerivedPropertiesToConnections = {
  connection: DiagramConnection;
  diagramResponse: DiagramResponse;
};

export function addDerivedPropertiesToConnections({
  diagramResponse,
  connection,
}: AddDerivedPropertiesToConnections): DiagramResponse {
  let newConnection = { ...connection };

  if (connection.type === 'LOGICAL') {
    newConnection = {
      ...connection,
      derived: {
        fromTo: `${connection.nodes.from.uid}-${connection.nodes.to.uid}`,
        order: calculateAllConnectionsTotalValue(connection) + 1,
        total: calculateAllConnectionsTotalValue(connection) + 1,
      },
    };
  }

  const updatedConnections = diagramResponse.connections.map((connection) => {
    if (
      connection.type === 'LOGICAL' &&
      connection.nodes.from.uid === newConnection.nodes.from.uid &&
      connection.nodes.to.uid === newConnection.nodes.to.uid
    ) {
      return {
        ...connection,
        derived: {
          ...connection.derived,
          total: connection.derived?.total ? connection.derived?.total + 1 : 1,
        },
      };
    } else {
      return connection;
    }
  });

  return {
    ...diagramResponse,
    connections: [...updatedConnections, { ...newConnection }],
  };
}

type RemoveConnectionToNodeDisplayReducer = {
  connectionUid: DiagramConnection['uid'];
  diagramResponse: DiagramResponse;
};

export function removeConnectionToNodeDisplayReducer({
  diagramResponse,
  connectionUid,
}: RemoveConnectionToNodeDisplayReducer): DiagramResponse {
  return {
    ...diagramResponse,
    connections: diagramResponse.connections.filter(
      (connection) => connection.uid !== connectionUid,
    ),
  };
}

export function decorateConnections(
  diagramResponse: DiagramQueryResponse,
): DiagramResponse {
  const decoratedConnections = (
    diagramResponse as unknown as DiagramResponse
  ).connections.map((connection) => {
    if (connection.type !== 'LOGICAL') {
      return {
        ...connection,
        display: {
          ...connection.display,
          drawingType: 'LINE_PHYSICAL_CONNECTION' as DiagramDrawingType,
        },
      };
    }

    return {
      ...connection,
      derived: {
        fromTo: `${connection.nodes.from.uid}-${connection.nodes.to.uid}`,
        order: determineDerivedOrderOnFirstRenderForConnection({
          connection,
          connections: (diagramResponse as unknown as DiagramResponse)
            .connections,
        }),
        total: sumMultipleConnectionsBetweenNodes(
          (diagramResponse as unknown as DiagramResponse).connections,
          connection,
        ),
      },
    };
  });

  return {
    ...(diagramResponse as unknown as DiagramResponse),
    connections: decoratedConnections,
  };
}

type DetermineDerivedOrderOnFirstRenderForConnection = {
  connection: DiagramConnection;
  connections: DiagramConnection[];
};

export const determineDerivedOrderOnFirstRenderForConnection = ({
  connection,
  connections,
}: DetermineDerivedOrderOnFirstRenderForConnection): number => {
  const relatedConnections = connections.filter(
    (item) =>
      item.nodes.from.uid === connection.nodes.from.uid &&
      item.nodes.to.uid === connection.nodes.to.uid,
  );

  const sortedRelatedConnections = relatedConnections.sort(
    (a, b) => a.display!.connection!.order! - b.display!.connection!.order!,
  );

  return (
    sortedRelatedConnections.findIndex((item) => item.uid === connection.uid) +
    1
  );
};

type UpdateDerivedOrderForConnectionsAfterDelete = {
  fromToConnectionsToUpdate: string;
  deletedConnectionDerivedOrderValue: string;
};

export function updateDerivedOrderForConnectionsAfterDelete({
  fromToConnectionsToUpdate,
  deletedConnectionDerivedOrderValue,
}: UpdateDerivedOrderForConnectionsAfterDelete): void {
  d3.selectAll<SVGLineElement, unknown>(
    `line[data-connection-from-to="${fromToConnectionsToUpdate}"]`,
  ).each(function () {
    const connectionCurrentDerivedOrderValue = this.getAttribute(
      'data-connection-derived-order',
    );

    if (
      determineIfDerivedOrderValueNeedsUpdating({
        connectionCurrentDerivedOrderValue,
        deletedConnectionDerivedOrderValue,
      })
    ) {
      this.setAttribute(
        'data-connection-derived-order',
        (Number(connectionCurrentDerivedOrderValue) - 1).toString(),
      );
    }
  });
}

type DetermineIfDerivedOrderValueNeedsUpdating = {
  connectionCurrentDerivedOrderValue: string | null;
  deletedConnectionDerivedOrderValue: string;
};

export const determineIfDerivedOrderValueNeedsUpdating = ({
  connectionCurrentDerivedOrderValue,
  deletedConnectionDerivedOrderValue,
}: DetermineIfDerivedOrderValueNeedsUpdating): boolean => {
  if (connectionCurrentDerivedOrderValue === null) return false;

  return (
    Number(connectionCurrentDerivedOrderValue) >
    Number(deletedConnectionDerivedOrderValue)
  );
};

export const sumMultipleConnectionsBetweenNodes = (
  diagramResponseConnections: DiagramConnection[],
  connection: DiagramConnection,
): number => {
  const currentConnectionNodesConnected = [
    connection.nodes.from.uid,
    connection.nodes.to.uid,
  ];

  const result = diagramResponseConnections.filter((connection) => {
    const nodesConnected = [connection.nodes.from.uid, connection.nodes.to.uid];

    return (
      currentConnectionNodesConnected.filter((uid) =>
        nodesConnected.includes(uid),
      ).length === 2
    );
  });

  return result.length;
};

export const updateAllConnectionsTotalValue = (
  connection: DiagramConnection,
): void => {
  const newTotal = calculateAllConnectionsTotalValue(connection);
  d3.selectAll<HTMLDivElement, unknown>(
    `[data-connection-from-to="${connection.nodes.from.uid}-${connection.nodes.to.uid}"]`,
  ).attr('data-connection-total', newTotal);

  d3.selectAll<HTMLDivElement, unknown>(
    `[data-connection-from-to="${connection.nodes.to.uid}-${connection.nodes.from.uid}"]`,
  ).attr('data-connection-total', newTotal);
};
