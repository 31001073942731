import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudControl } from './dCloudContainer';
import { ConnectedExpoControl } from './expoContainer';

export function ControlContainer({
  nodeUid,
}: ControlContainerProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();
  if (engagementUid) {
    return (
      <ConnectedExpoControl
        engagementUid={engagementUid}
        nodeUid={nodeUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudControl
      nodeUid={nodeUid}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  nodeUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type ControlContainerProps = ReturnType<typeof mapStateToProps>;

export const ConnectedHwControl = connect(mapStateToProps)(ControlContainer);
