import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  useGetScenariosQuery,
  useUpdateScenariosMutation,
} from '../../../../redux/scenarios/api';
import { RootState } from '../../../../redux/store';
import {
  scenariosSelectors,
  setScenariosToSave,
} from '../../../../redux/scenarios/slice';
import { ScenariosLoader } from './components/ScenariosLoader';
import { ScenariosForm } from './components/ScenariosForm/ScenariosForm';

function ScenariosContainer({
  scenariosToSave,
  setScenariosToSave,
}: ScenariosContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const {
    data: originalScenarios,
    isLoading,
    isFetching,
  } = useGetScenariosQuery(topologyUid);
  const [
    updateScenarios,
    { isLoading: isScenariosUpdateLoading, isError: isScenariosUpdateError },
  ] = useUpdateScenariosMutation();
  return isLoading ||
    isFetching ||
    !scenariosToSave ||
    !originalScenarios ||
    isScenariosUpdateLoading ||
    isScenariosUpdateError ? (
    <ScenariosLoader />
  ) : (
    <>
      <ScenariosForm
        scenariosToSave={scenariosToSave}
        originalScenarios={originalScenarios}
        setScenariosToSave={setScenariosToSave}
        updateScenarios={updateScenarios}
      />
    </>
  );
}

const mapDispatchToProps = {
  setScenariosToSave,
};

const mapStateToProps = (state: RootState) => ({
  scenariosToSave: scenariosSelectors.getScenariosToSave(state),
});

export type ScenariosContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedScenarios = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScenariosContainer);
