import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './IntegrationPanelActionButton.module.css';

type IntegrationPanelActionButtonProps = {
  action: SessionIntegrationAction;
};

export function IntegrationPanelActionButton({
  action,
}: IntegrationPanelActionButtonProps): ReactElement {
  return (
    <a
      className={classNames(styles.actionButton, 'btn btn--ghost')}
      key={action.title}
      href={action.target}
      rel="noopener noreferrer"
      target="_blank"
      data-testid="integration-panel-action-button"
    >
      {action.title}
    </a>
  );
}
