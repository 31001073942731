import React, { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { saveAndEndSchema } from '../../../../../../../../validation';
import {
  ControlledTextarea,
  ControlledTextfield,
  ControlledCheckbox,
} from '../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import styles from '../../../Modals.module.css';

export interface SaveAndEndFormProps extends FetchSessionArgs {
  description: string;
  isLoading: boolean;
  name: string;
  retainDocuments?: boolean;
  saveAndEndSession: RTKMutation<SaveAndEndSessionArgs, Session>;
  setExpired: ActionCreatorWithPayload<boolean, string>;
  setShowSaveAndEndSessionModal: ActionCreatorWithPayload<boolean, string>;
}

export function SaveAndEndForm({
  description,
  isLoading,
  name,
  saveAndEndSession,
  setExpired,
  setShowSaveAndEndSessionModal,
  sessionUid,
  versionUid,
}: SaveAndEndFormProps): ReactElement {
  const { t } = useTranslation();
  const { errors, control, formState, handleSubmit } =
    useForm<SessionSaveAndEndFields>({
      defaultValues: {
        description: description,
        name: name,
        retainDocumentation: false,
      },
      mode: 'all',
      resolver: yupResolver(saveAndEndSchema),
    });

  const { isDirty, isValid } = formState;

  const handleSuccess = () => {
    setShowSaveAndEndSessionModal(false);
    setExpired(true);
  };

  const handleSave: SubmitHandler<SessionSaveAndEndFields> = async (
    formData,
  ) => {
    const { error } = await saveAndEndSession({
      description: formData.description,
      name: formData.name,
      saveDocuments: formData.retainDocumentation,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });

    !error && handleSuccess();
  };

  return (
    <form>
      <ControlledTextfield<SessionSaveAndEndFields>
        control={control}
        error={errors?.name?.message}
        label={t('sessions.forms.saveAndEnd.name.label')}
        labelClass="col-3"
        name="name"
        required={true}
      />
      <ControlledTextarea<SessionSaveAndEndFields>
        control={control}
        error={errors?.description?.message}
        label={t('sessions.forms.saveAndEnd.description.label')}
        labelClass="col-3"
        name="description"
        rows={4}
        required={true}
      />
      <ControlledCheckbox
        control={control}
        id="retainDocumentation"
        label={t('sessions.forms.saveAndEnd.retainDocumentation.label')}
      />

      <div className={classNames(styles.buttonGroup, 'dbl-margin-top')}>
        <Button
          size="large"
          colour="ghost"
          type="button"
          onClick={() => setShowSaveAndEndSessionModal(false)}
        >
          {t('buttons.cancel')}
        </Button>
        <PrimaryButton
          disabled={!isDirty || !isValid}
          testId="general-form-submit"
          loading={isLoading}
          onClick={handleSubmit(handleSave)}
          type="submit"
          colour="success"
        >
          {t('buttons.confirm')}
        </PrimaryButton>
      </div>
    </form>
  );
}
