import React, { ReactElement } from 'react';
import {
  sessionApi,
  useGetSessionVmStatusQuery,
} from '../../../../../../../../redux/views/session-view/session/api/api';
import { getMorIdFromSessionDetails } from '../../../../../utils';
import { getInfoFromNodeDetails } from './utils';
import { InfoContainerProps } from './container';
import { Info } from '.';

type ConnectedDCloudInfoProps = InfoContainerProps & FetchSessionArgs;

export function ConnectedDCloudInfo({
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedDCloudInfoProps): ReactElement {
  const { staticVmInfo, mor } = sessionApi.endpoints.getSession.useQueryState(
    { sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        mor: getMorIdFromSessionDetails(
          (
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionVmNode
          ).sessionDetails,
        ),
        staticVmInfo: getInfoFromNodeDetails(
          data?.diagram.nodes.filter(
            (node) => node.uid === nodeUid,
          )[0] as SessionVmNode,
        ),
      }),
    },
  );

  const { dynamicVmInfo, isLoadingDynamicVmInfo, isFetchingDynamicVmInfo } =
    useGetSessionVmStatusQuery(
      { mor, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching }) => ({
          dynamicVmInfo: {
            guestOs: data?.vmwareState.guestState,
            vmWareTools: data?.vmwareState.guestToolsState,
          },
          isFetchingDynamicVmInfo: isFetching,
          isLoadingDynamicVmInfo: isLoading,
        }),
      },
    );

  return (
    <Info
      staticVmInfo={staticVmInfo}
      dynamicVmInfo={dynamicVmInfo}
      isLoadingDynamicVmInfo={isLoadingDynamicVmInfo || isFetchingDynamicVmInfo}
    />
  );
}
