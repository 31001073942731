import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  addLabelToIntegrationToUpdate,
  integrationSelectors,
  removeLabelFromIntegrationToUpdate,
  setFetchedLabelsToEdit,
  updateLabels,
} from '../../../../redux/integrations/slice';
import { useGetIntegrationLicensesQuery } from '../../../../redux/integrations/api';
import { LicenseFormSkeleton } from '../../../CreateIntegration/components/LicenseForm/LicenseFormSkeleton';
import LicenseForm from './';

const LicenseFormContainer = ({
  integrationToUpdate,
  addLabel,
  removeLabel,
  selectedLicenses,
  setFetchedLabelsToEdit,
  updateLabels,
}: CreateIntegrationContainerProps): ReactElement => {
  const {
    data: fetchedIntegrationLicenses,
    isLoading,
    isError,
  } = useGetIntegrationLicensesQuery(undefined);
  return !fetchedIntegrationLicenses || isLoading || isError ? (
    <LicenseFormSkeleton />
  ) : (
    <LicenseForm
      integrationToUpdate={integrationToUpdate}
      addLabel={addLabel}
      removeLabel={removeLabel}
      labels={fetchedIntegrationLicenses.integrationLicenses}
      selectedLabels={selectedLicenses}
      setFetchedLabelsToEdit={setFetchedLabelsToEdit}
      updateLabels={updateLabels}
    />
  );
};

type OwnProps = {
  selectedLicenses: CustomLabel[];
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  integrationToUpdate: integrationSelectors.getIntegrationToUpdate(state),
  selectedLicenses: ownProps.selectedLicenses,
});

const mapDispatchToProps = {
  addLabel: addLabelToIntegrationToUpdate,
  removeLabel: removeLabelFromIntegrationToUpdate,
  setFetchedLabelsToEdit,
  updateLabels,
};

type CreateIntegrationContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedLicenseForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LicenseFormContainer);
