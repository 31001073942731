import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetInventoryScriptsQuery } from '../../../../../../redux/configuration/hardware/api';
import { FormScripts } from '.';

export function FormScriptsContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data: scripts, isLoading } = useGetInventoryScriptsQuery(topologyUid);

  return <FormScripts isLoading={isLoading} scripts={scripts} />;
}
