import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ColDef, ICellRendererParams, RowEvent } from 'ag-grid-community';
import { Alert } from 'dcloud-shared-ui';
import { useRouteMatch } from 'react-router-dom';
import { GridEditor } from '../../../../../../components/GridEditor';
import { tagComparator } from '../../../../../Integrations/utils/utils';
import { TagsCellRenderer } from '../TagsCellRenderer';
import { SAAS_INTEGRATION_TYPE } from '../../../../../../constants';
import history from '../../../../../../redux/history';
import { NameWithIconCellRenderer } from '../NameWithIconCellRenderer';
import { Description } from './components/Description/Description';
import { IntegrationsTableProps } from './container';

export const IntegrationsTable = ({
  integrations,
  setInventorySaasTemplateToDelete,
  integrationType,
}: IntegrationsTableProps): ReactElement => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const handleOnDeleteClick = (cell: ICellRendererParams) => {
    setInventorySaasTemplateToDelete(cell.data as SaasTemplate);
  };

  const handleOnEditClick = (row: RowEvent) => {
    history.push(`${url}/${row.data.uid}`);
  };

  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: 'nameWithIcon',
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'name',
      flex: 1,
      headerName: t('integrations.table.integration'),
      minWidth: 130,
    },
    {
      autoHeight: true,
      cellRenderer: 'description',
      cellStyle: { 'white-space': 'normal' },
      field: 'saasTemplate.description',
      flex: 3,
      headerName: t('integrations.table.description'),
      minWidth: 500,
    },
    {
      autoHeight: true,
      cellRenderer: 'tags',
      cellStyle: { 'white-space': 'normal' },
      comparator: tagComparator,
      field: 'saasTemplate.tags',
      flex: 1,
      headerName: t('integrations.table.tags'),
      minWidth: 250,
    },
  ];
  return integrations.length > 0 ? (
    <GridEditor
      columns={columns}
      data={integrations}
      className={classnames('base-padding-left')}
      onGridReady={() => undefined}
      customComponents={{
        description: Description,
        nameWithIcon: NameWithIconCellRenderer,
        tags: TagsCellRenderer,
      }}
      onDeleteClick={handleOnDeleteClick}
      onEditClick={handleOnEditClick}
      deleteAriaLabel="Delete integration"
      editAriaLabel="Edit integration"
    />
  ) : (
    <Alert>
      {integrationType === SAAS_INTEGRATION_TYPE
        ? t('saas.search.noItemsFound.saas')
        : t('saas.search.noItemsFound.cloud')}
    </Alert>
  );
};
