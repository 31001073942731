import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../../constants/form';

export function buildProtocolOptions(
  protocols: SrvProtocol[],
): SelectOption<string>[] {
  const options = protocols.map((current) => ({
    name: current.protocol,
    value: current.id,
  }));
  return [PLEASE_SELECT_OPTION, ...options];
}
