export const getInfoFromNodeDetails = (
  data: SessionVmNode,
): VmPanelStaticInfo | undefined => {
  if (!data) return undefined;

  return {
    description: data.asset.description,
    memory: data.asset.memoryMb,
    name: data.asset.name,
    osFamily: data.asset.osFamily,
    vCPUs: data.asset.cpuQty,
  };
};
