import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'dcloud-shared-ui';
import { TemplateManagerRoutes } from '../../TemplateManager/TemplateManagerRouter';
import styles from './TableActions.module.css';

interface TableActionsProps {
  vmTemplate: VmTemplate;
  onDeleteClick: (templateId: string) => void;
  onEditClick: (vmTemplate: VmTemplate) => void;
  canBeDeleted: boolean;
}

export function VmTemplateTableActions({
  onDeleteClick,
  onEditClick,
  vmTemplate,
  canBeDeleted,
}: TableActionsProps): ReactElement {
  return (
    <ul aria-label="Card actions" className={styles.root}>
      {canBeDeleted && (
        <li className={styles.action}>
          <Button
            colour="link"
            style={{ color: 'var(--cui-theme-danger' }}
            onClick={() => onDeleteClick(vmTemplate.uid)}
            data-test="vm-template-table-actions-delete"
          >
            <Icon name="delete" />
          </Button>
        </li>
      )}
      {vmTemplate.uid && (
        <li className={styles.action}>
          <Link
            to={`/templates${TemplateManagerRoutes.VmTemplates}${TemplateManagerRoutes.EditVmTemplate}`}
            className={styles.linkAction}
            onClick={() => onEditClick(vmTemplate)}
            data-testid="vms-table-actions-edit"
          >
            <Icon name="edit" />
          </Link>
        </li>
      )}
      {!vmTemplate.uid && (
        <li className={styles.action}>
          <Link
            to={`/templates${TemplateManagerRoutes.VmTemplates}${TemplateManagerRoutes.CreateVmTemplate}`}
            className={styles.linkAction}
            onClick={() => onEditClick(vmTemplate)}
            data-testid="vms-table-actions-create"
          >
            <Icon name="add" />
          </Link>
        </li>
      )}
    </ul>
  );
}
