import { connect } from 'react-redux';
import { getAuthStatus, startAuth } from '../../../redux/auth/slice';
import { RootState } from '../../../redux/store';
import { AuthenticatedGuard } from './index';

const mapDispatchToProps = {
  startAuth,
};

const mapStateToProps = (state: RootState) => ({
  authStatus: getAuthStatus(state),
});

export type AuthenticatedGuardProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedGuard);
