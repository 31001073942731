import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useDeleteNodeMutation } from '../../../../redux/diagram/api';
import {
  diagramSelectors,
  setTextNodeToConfigureInDiagram,
} from '../../../../redux/diagram/slice';
import { RootState } from '../../../../redux/store';
import { DiagramTextNode } from './index';

type ConnectedDiagramTextNodeProps = {
  display: DiagramTextDisplay;
  isReadOnly: boolean;
  uid: string;
};

function DiagramTextNodeContainer({
  display,
  isReadOnly,
  uid,
  setTextNodeToConfigureInDiagram,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
}: DiagramTextNodeContainerProps): ReactElement {
  const [deleteNode] = useDeleteNodeMutation();
  return (
    <DiagramTextNode
      display={display}
      isReadOnly={isReadOnly}
      uid={uid}
      deleteNode={deleteNode}
      setTextNodeToConfigureInDiagram={setTextNodeToConfigureInDiagram}
      isSnapToGridEnabled={isSnapToGridEnabled}
      isAddingLogicalConnection={isAddingLogicalConnection}
    />
  );
}

type DiagramTextNodeRTKProps = {
  deleteNode: RTKMutation<string, undefined>;
};

type DiagramTextNodeContainerProps = ConnectedDiagramTextNodeProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export type DiagramTextNodeProps = DiagramTextNodeContainerProps &
  DiagramTextNodeRTKProps;

const mapDispatchToProps = {
  setTextNodeToConfigureInDiagram,
};

const mapStateToProps = (state: RootState) => ({
  isAddingLogicalConnection:
    diagramSelectors.getIsAddingLogicalConnection(state),
  isSnapToGridEnabled: diagramSelectors.getIsSnapToGridEnabled(state),
});

export const ConnectedDiagramTextNode = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiagramTextNodeContainer);
