import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from '../../../../../../../../components/ExternalLink';
import { REQUEST_VM_URL } from '../../../../../constants';
import styles from './RequestVmButton.module.css';

export const RequestVmButton = (): ReactElement => {
  const { t } = useTranslation();

  const RequestVmBtnClasses = classNames(
    'btn btn--ghost btn--wide btn--large',
    styles.requestVmButton,
    styles.a,
  );

  return (
    <ExternalLink
      href={REQUEST_VM_URL}
      linkText={t('virtualMachines.requestVm')}
      className={RequestVmBtnClasses}
      target="_blank"
      aria-label="request virtual machine"
    />
  );
};
