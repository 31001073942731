import React, { ReactElement, useEffect } from 'react';
import { LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { MainRoutes } from '../../../../constants/routes';
import UserRoleGuardContainer from '../../../../auth/components/UserRoleGuard/container';
import { DemoTypeItem } from './constants';
import { DemoTypeTile } from './components/DemoTypeTile';

export interface DemoTypeProps {
  setDemoType: (demoType: string) => void;
  resetDemoToImport: VoidFunction;
  demoTypes: DemoTypeItem[];
}

export function DemoType({
  setDemoType,
  resetDemoToImport,
  demoTypes,
}: DemoTypeProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    resetDemoToImport();
  }, [resetDemoToImport]);

  return (
    <>
      <div className="row flex-center">
        {demoTypes.map((demoType) =>
          demoType.requiredRoles ? (
            <UserRoleGuardContainer
              key={demoType.title}
              requiredRoles={demoType.requiredRoles}
            >
              <DemoTypeTile demoType={demoType} setDemoType={setDemoType} />
            </UserRoleGuardContainer>
          ) : (
            <DemoTypeTile
              key={demoType.title}
              demoType={demoType}
              setDemoType={setDemoType}
            />
          ),
        )}
      </div>
      <div className="flex flex-center dbl-margin-top">
        <LinkButton to={MainRoutes.Topologies} colour="ghost" size="large">
          {t('buttons.cancel')}
        </LinkButton>
        <LinkButton
          to={MainRoutes.CreateTopology}
          colour="ghost"
          size="large"
          testId="create-topology-demo-type-back-button"
        >
          {t('buttons.back')}
        </LinkButton>
      </div>
    </>
  );
}
