import React, { ReactElement } from 'react';
import { Tooltip } from '../../../../../components/Tooltip';
import { PushActionUpdateButton } from '../PushActionUpdateButton';

export interface DisabledPushActionUpdateButtonProps {
  tooltipMessage: string;
  tooltipId: string;
}

export function DisabledPushActionUpdateButton({
  tooltipMessage,
  tooltipId,
}: DisabledPushActionUpdateButtonProps): ReactElement {
  return (
    <Tooltip id={tooltipId} text={tooltipMessage}>
      <PushActionUpdateButton onClick={() => null} isDisabled={true} />
    </Tooltip>
  );
}
