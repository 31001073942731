import * as d3 from 'd3';
import { useEffect } from 'react';
import { useDiagramContext } from '../../DiagramProvider';
import {
  addEventListenerToConnectionLine,
  removeEventListenerToConnectionLine,
} from '../utils';

export function useClickConnection(): void {
  const { toggleSelectedItem } = useDiagramContext();

  const selector = 'line[data-connection-selector]';

  useEffect(() => {
    function handleEvent(event: MouseEvent) {
      const uid = (event.currentTarget as SVGLineElement).getAttribute(
        'data-uid',
      );
      const subStringToRemove = '-selector';
      const uidTransformed =
        uid && uid.substring(0, uid.length - subStringToRemove.length);

      uidTransformed !== null && toggleSelectedItem(uidTransformed);
    }

    d3.selectAll<SVGLineElement, unknown>(selector).each(function () {
      addEventListenerToConnectionLine({ element: this, handleEvent });
    });

    return () => {
      d3.selectAll<SVGLineElement, unknown>(selector).each(function () {
        removeEventListenerToConnectionLine({ element: this, handleEvent });
      });
    };
  });
}
