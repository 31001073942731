export enum NodeStrokeTypes {
  solid = 'solid',
  dashed = 'dashed',
}

export const DRAWING_STROKE_TYPES = [
  NodeStrokeTypes.solid,
  NodeStrokeTypes.dashed,
];

export const DIAGRAM_DRAWING_COLORS = [
  'color-gray-200',
  'color-gray-400',
  'color-gray-600',
  'color-gray-800',
  'color-black',
  'color-sky',
  'color-link',
  'color-ocean',
  'color-warning-alt',
  'color-warning',
  'color-danger',
  'color-success',
] as const;
