import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PanelTableLoader } from '../../../../PanelTableLoader';
import { PanelGrid } from '../../../PanelGrid';
import { PhoneNumbersProps } from './container';

export function PhoneNumbers({
  phoneNumbers,
  isLoading,
  isError,
}: PhoneNumbersProps): ReactElement {
  const { t } = useTranslation();

  const buildGridData = ({
    dn,
    did,
    description,
  }: PhoneNumberItem): PanelGridDataItem[] => {
    return [
      {
        key: `External${did}`,
        name: t('sessions.panels.info.phoneNumbers.externalDid'),
        value: did,
      },
      {
        key: `Internal${dn}`,
        name: t('sessions.panels.info.phoneNumbers.internalDid'),
        value: dn,
      },
      {
        key: `Description${description}`,
        name: t('sessions.panels.info.phoneNumbers.description'),
        value: description,
      },
    ];
  };

  if (isLoading || isError) {
    return <PanelTableLoader />;
  }

  if (!phoneNumbers || !phoneNumbers.length) {
    return (
      <Alert>
        {t('sessions.panels.info.phoneNumbers.noPhoneNumbersConfigured')}
      </Alert>
    );
  }

  return (
    <>
      {phoneNumbers.map(function (phoneNumber) {
        return (
          <PanelGrid
            key={`${phoneNumber.description}${phoneNumber.did}-panel`}
            data={buildGridData(phoneNumber)}
            wideCol="value"
            nameBold={true}
            className="base-padding-bottom"
          />
        );
      })}
    </>
  );
}
