import classnames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { Modal } from '../Modals';
import styles from './InventoryModal.module.css';

export interface InventoryModalProps {
  children: ReactNode;
  className?: string;
  onClose: VoidFunction;
  show: boolean;
  title: string;
}

export function InventoryModal({
  children,
  className,
  onClose,
  show,
  title,
}: InventoryModalProps): ReactElement {
  return (
    <Modal
      className={classnames(styles.root, className)}
      clickScreenToClose={true}
      show={show}
      onClose={onClose}
      title={title}
      testId={`inventory-modal-${title}`}
    >
      <div className={styles.container}>{children}</div>
    </Modal>
  );
}
