import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';

export type IconPanelPropertiesEmptyAlertProps = {
  properties: DiagramAddIconFormFieldsDatum[];
};

export function IconPanelPropertiesEmptyAlert({
  properties,
}: IconPanelPropertiesEmptyAlertProps): ReactElement {
  const { t } = useTranslation();

  if (properties.length > 0) return <></>;

  return (
    <Alert>
      {t('sessions.panels.icon.sections.properties.messages.empty')}
    </Alert>
  );
}
