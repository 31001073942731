import { SelectOption } from 'dcloud-shared-ui';
import i18n from '../i18n';

export const PLEASE_SELECT_OPTION: SelectOption<string> = {
  name: i18n.t('common.pleaseSelect'),
  value: '',
};

export const NONE_OPTION: SelectOption<string> = {
  name: i18n.t('common.none'),
  value: '',
};
