import React, { PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';
import styles from './Actions.module.css';

type ActionsProps = PropsWithChildren<unknown>;

export function Actions({ children }: ActionsProps): ReactElement {
  return (
    <div className={classnames('flex flex-middle', styles.root)}>
      {children}
    </div>
  );
}
