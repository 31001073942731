import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import classNames from 'classnames';
import styles from './HelpButtonLink.module.css';

export interface HelpButtonLinkProps {
  href: string;
  ariaLabel: string;
  label: string;
  target?: string;
  show: boolean;
}

export function HelpButtonLink({
  href,
  ariaLabel,
  label,
  target = '_blank',
  show,
}: HelpButtonLinkProps): ReactElement {
  const wrapperClasses = classNames(
    show ? styles['wrapperShow'] : styles['wrapper'],
  );

  return (
    <div className={wrapperClasses}>
      <span className={styles.label}>{label}</span>
      <a
        aria-label={ariaLabel}
        href={href}
        className="btn btn--circle btn--small btn-primary"
        rel="noopener noreferrer"
        target={target}
      >
        <Icon name="info-outline" className={styles.icon} />
      </a>
    </div>
  );
}
