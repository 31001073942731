import React, { ChangeEvent, ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { integrationInputParameterFieldsSchema } from '../../../../validation';
import {
  ControlledCheckbox,
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../components';
import { DefaultToParentField } from '../../../components/DefaultToParentField/DefaultToParentField';
import { WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME } from '../../../components/DefaultToParentField/DefaultToParentFieldConstants';
import { determineInputFieldValue } from '../../../utils/workFlowParameterMappingUtil';
import styles from '../../../components/DefaultToParentField/DefaultToParentField.module.css';
import { useTriggerWorkflowParamValueField } from '../../../hooks/use-trigger-workflow-param-value-field/use-trigger-workflow-param-value-field';

type IntegrationInputProps = {
  input: SaasTemplateInput;
  updateInput: (payload: UpdateInputPayload) => void;
  updateInputStatus: (payload: UpdateInputStatusPayload) => void;
};

export default function IntegrationInput({
  input,
  updateInput,
  updateInputStatus,
}: IntegrationInputProps): ReactElement {
  const defaultValue = input.defaultToWorkflowEngine
    ? input.defaultValue
    : input.value;
  const formMethods = useForm<IntegrationInputParameterFields>({
    defaultValues: {
      default: defaultValue || '',
      defaultToWorkflowEngine: input.defaultToWorkflowEngine,
      description: input.description || '',
      name: input.fieldName || '',
      userConfigurable: input.userConfigurable,
    },
    mode: 'all',
    resolver: yupResolver(integrationInputParameterFieldsSchema()),
  });
  const { t } = useTranslation();
  const {
    errors,
    control,
    formState: { isValid, isDirty },
    watch,
    setValue,
    trigger,
  } = formMethods;
  const watchDefaultToWorkflowEngine: boolean = watch(
    `${WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME}-${input.mappingName}`,
    input.defaultToWorkflowEngine,
  );
  const watchUserConfigurable = watch('userConfigurable');

  useEffect(() => {
    updateInputStatus({ isValid, mappingName: input.mappingName });
  }, [isValid, updateInputStatus, input.mappingName]);

  useTriggerWorkflowParamValueField({
    defaultToParent: watchDefaultToWorkflowEngine,
    isDirty,
    trigger,
    userConfigurable: watchUserConfigurable,
  });

  const onDefaultToWorkflowValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const defaultToWorkflowEngine = event.target.checked;
    const value = determineInputFieldValue(
      input.value || '',
      input.defaultValue || '',
      defaultToWorkflowEngine,
    );
    setValue('default', value, {
      shouldDirty: true,
    });
    updateInput({
      defaultToWorkflowEngine,
      mappingName: input.mappingName,
      value,
    });
  };

  const onUserConfigurableValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    updateInput({
      mappingName: input.mappingName,
      userConfigurable: event.target.checked,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <div className="dbl-margin-left dbl-margin-right">
        <div className="row">
          <div className="col-4">
            <ControlledTextfield<IntegrationInputParameterFields>
              control={control}
              error={errors?.name?.message}
              label={t('integrations.forms.common.inputs.name')}
              name="name"
              required={true}
              customOnChange={(event) =>
                updateInput({
                  fieldName: event.target.value,
                  mappingName: input.mappingName,
                })
              }
            />
          </div>
          <div className="col-4">
            <ControlledTextfield<IntegrationInputParameterFields>
              control={control}
              error={errors?.default?.message}
              label={t('integrations.forms.common.inputs.default')}
              name="default"
              disabled={watchDefaultToWorkflowEngine}
              required={!watchUserConfigurable}
              customOnChange={(event) =>
                updateInput({
                  mappingName: input.mappingName,
                  value: event.target.value,
                })
              }
            />
          </div>
          <div
            className={classnames('col-4', styles.defaultToParentFieldWrapper)}
          >
            <DefaultToParentField
              mappingName={input.mappingName}
              onChange={onDefaultToWorkflowValueChange}
              defaultValue={input.defaultToWorkflowEngine || false}
              userConfigurable={watchUserConfigurable}
              templateDefaultValue={input.defaultValue}
              isAdmin={true}
            />
          </div>
        </div>
        <div className="half-margin-top">
          <ControlledTextarea<IntegrationInputParameterFields>
            control={control}
            error={errors?.description?.message}
            label={t('integrations.forms.common.inputs.description')}
            name="description"
            required={true}
            customOnChange={(event) =>
              updateInput({
                description: event.target.value,
                mappingName: input.mappingName,
              })
            }
          />
        </div>
        <div className="half-margin-top">
          <ControlledCheckbox<IntegrationInputParameterFields>
            id="userConfigurable"
            customId={`userConfigurable-${input.mappingName}`}
            defaultValue={input.userConfigurable}
            control={control}
            label={t('integrations.forms.common.inputs.userConfigurable')}
            customOnChange={onUserConfigurableValueChange}
          />
        </div>
      </div>
    </FormProvider>
  );
}
