import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetRemoteAccessQuery,
  useUpdateRemoteAccessMutation,
} from '../../../../redux/configuration/remote-access/api';
import { RemoteAccessLoader } from './RemoteAccessLoader';
import { RemoteAccess } from '.';

export function RemoteAccessContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { data, isFetching, isError } = useGetRemoteAccessQuery(topologyUid);

  const [updateRemoteAccess, { isLoading: isUpdating }] =
    useUpdateRemoteAccessMutation();

  if (!data) {
    return <RemoteAccessLoader />;
  }

  return (
    <RemoteAccess
      data={data}
      isError={isError}
      isFetching={isFetching}
      isUpdating={isUpdating}
      updateRemoteAccess={updateRemoteAccess}
    />
  );
}

interface RemoteAccessRTKProps {
  data?: RemoteAccess;
  isFetching: boolean;
  isError: boolean;
  isUpdating: boolean;
  updateRemoteAccess: RTKMutation<RemoteAccessPayload, RemoteAccess>;
}

export type RemoteAccessProps = RemoteAccessRTKProps;
