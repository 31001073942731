import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  useGetIntegrationsQuery,
  useGetSaasTemplateAvailabilityValuesQuery,
} from '../../redux/integrations/api';
import { FullScreenSpinner } from '../../components/Loaders/FullScreenSpinner';
import {
  setIntegrationToDelete,
  setIntegrationToDisplayUid,
  integrationSelectors,
} from '../../redux/integrations/slice';
import {
  reset,
  integrationsFiltersSelectors,
  setSearchValue,
} from '../../redux/integrationsFilter/slice';
import { RootState } from '../../redux/store';
import { Integrations } from '.';

export function IntegrationsContainer({
  reset,
  setIntegrationToDelete,
  filteredIntegrations,
  integrations,
  searchValue,
  setSearchValue,
  setIntegrationToDisplayUid,
  integrationToDisplayUid,
}: IntegrationsContainerProps): ReactElement {
  const {
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
    isError: isIntegrationsError,
  } = useGetIntegrationsQuery(undefined);

  const {
    isLoading: isAvailabilityValuesLoading,
    isFetching: isAvailabilityValuesFetching,
    isError: isAvailabilityValuesError,
  } = useGetSaasTemplateAvailabilityValuesQuery(undefined);

  return isIntegrationsLoading ||
    isIntegrationsFetching ||
    isIntegrationsError ||
    isAvailabilityValuesLoading ||
    isAvailabilityValuesFetching ||
    isAvailabilityValuesError ? (
    <FullScreenSpinner />
  ) : (
    <Integrations
      integrationToDisplayUid={integrationToDisplayUid}
      reset={reset}
      filteredIntegrations={filteredIntegrations}
      setIntegrationToDelete={setIntegrationToDelete}
      setIntegrationToDisplayUid={setIntegrationToDisplayUid}
      integrations={integrations}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
    />
  );
}

const mapDispatchToProps = {
  reset,
  setIntegrationToDelete,
  setIntegrationToDisplayUid,
  setSearchValue,
};

const mapStateToProps = (state: RootState) => ({
  filteredIntegrations:
    integrationsFiltersSelectors.getFilteredIntegrations(state),
  integrationToDisplayUid:
    integrationSelectors.getIntegrationToDisplayUid(state),
  integrations: integrationsFiltersSelectors.getFilteredIntegrations(state),
  searchValue: integrationsFiltersSelectors.getSearchValue(state),
});

type IntegrationsContainerProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export type IntegrationsProps = IntegrationsContainerProps;

export const ConnectedIntegrations = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationsContainer);
