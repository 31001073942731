import { dcloudApi } from '../../../api';
import { TOPOLOGIES_PATH, VM_START_ORDER } from '../../../../api/paths';
import { setOriginal, setStartStopOrder } from '../slice';
import i18n from '../../../../i18n';
import { TAGS } from '../../../api/constants';

export const vmStartOrderApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getVmStartOrder: build.query<StartStopOrder, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setStartStopOrder(data));
          dispatch(setOriginal(data));
        } catch (err) {
          throw err;
        }
      },
      providesTags: [TAGS.VM_START_ORDER],
      query: (topologyUid) => ({
        errorMessage: i18n.t('startStopOrder.vmStart.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${VM_START_ORDER}`,
      }),
    }),
    updateVmStartOrder: build.mutation<VirtualMachine, StartStopOrderPayload>({
      invalidatesTags: [TAGS.VM_START_ORDER],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('startStopOrder.vmStart.update.error'),
        method: 'PUT',
        successMessage: i18n.t('startStopOrder.vmStart.update.success'),
        url: `${VM_START_ORDER}/${payload.uid}`,
      }),
    }),
  }),
});

export const { useGetVmStartOrderQuery, useUpdateVmStartOrderMutation } =
  vmStartOrderApi;
