import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { CopyButton } from '../../../../../../components/CopyButton';
import styles from './PanelGrid.module.css';

export interface PanelGridProps {
  data: PanelGridDataItem[];
  wideCol?: PanelGridWideCol;
  wideColRatio?: 1 | 2 | 3 | 4 | 5 | 6;
  nameBold?: boolean;
  nameHeader?: string;
  valueHeader?: string;
  className?: string;
  wrapData?: boolean;
  enableCopy?: boolean;
}

export function PanelGrid({
  data,
  wideCol = 'name',
  nameBold,
  nameHeader,
  valueHeader,
  className,
  wideColRatio = 2,
  wrapData = false,
  enableCopy = false,
}: PanelGridProps): ReactElement {
  const nameColClasses = classNames(styles.cell, {
    [styles.wideCell]: wideCol === 'name',
    [styles.bold]: nameBold,
    [styles[`wideCell${wideColRatio}`]]: wideColRatio && wideCol === 'name',
    ['wrap']: wrapData === true,
  });

  const valueColClasses = classNames(styles.cell, {
    [styles.wideCell]: wideCol === 'value',
    [styles[`wideCell${wideColRatio}`]]: wideColRatio && wideCol === 'value',
    ['wrap']: wrapData === true,
  });

  const nameHeaderClasses = classNames(styles.cell, {
    [styles.wideCell]: wideCol === 'name',
    [styles.bold]: nameBold,
    [styles[`wideCell${wideColRatio}`]]: wideColRatio && wideCol === 'name',
  });

  const valueHeaderClasses = classNames(styles.cell, {
    [styles.wideCell]: wideCol === 'value',
    [styles.bold]: nameBold,
    [styles[`wideCell${wideColRatio}`]]: wideColRatio && wideCol === 'value',
  });

  const renderHeader = () => {
    if (nameHeader || valueHeader) {
      return (
        <div className={styles.row}>
          <div
            className={nameHeaderClasses}
            data-testid="panel-grid-header-name-cell"
          >
            {nameHeader}
          </div>
          <div
            className={valueHeaderClasses}
            data-testid="panel-grid-header-value-cell"
          >
            {valueHeader}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      data-testid="panel-grid-root"
      className={classNames('base-padding-bottom', styles.root, className)}
    >
      {renderHeader()}
      {data.map(function ({ name, value, key }, index) {
        if (!value) return null;
        return (
          <div key={key} className={styles.row}>
            <div
              data-testid={`panel-grid-name-cell-row-${index}`}
              className={nameColClasses}
            >
              {name}
            </div>
            <div
              data-testid={`panel-grid-value-cell-row-${index}`}
              className={valueColClasses}
            >
              {enableCopy ? (
                <div className={styles.valueRow}>
                  <div className={styles.valueCol1}>{value}</div>
                  <div className={styles.valueCol2}>
                    <CopyButton value={value} />
                  </div>
                </div>
              ) : (
                value
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
