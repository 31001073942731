import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { setVideoToPlay } from '../../redux/video/slice';
import { useGetVideosQuery } from '../../redux/configuration/videos/api';
import { VideoLoader } from './components/VideoLoader';
import VideosView from './';

const VideoContainer = ({ setVideoToPlay }: Props): ReactElement => {
  const { isLoading, isError, data } = useGetVideosQuery(undefined);
  return isLoading || isError || !data ? (
    <VideoLoader />
  ) : (
    <VideosView
      setVideoToPlay={setVideoToPlay}
      videoCategories={data.videoShowcases}
    />
  );
};

const mapDispatchToProps = {
  setVideoToPlay: setVideoToPlay,
};

export type Props = typeof mapDispatchToProps;

export const ConnectedVideosView = connect(
  null,
  mapDispatchToProps,
)(VideoContainer);
