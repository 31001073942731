import { connect } from 'react-redux';
import { setShowConfirmForActionForHw } from '../../../../../../../redux/views/session-view/session-inventory/slice';
import { RootState } from '../../../../../../../redux/store';
import { HardwareTableActionsCellRenderer } from '.';

const mapDispatchToProps = {
  setShowConfirmForActionForHw,
};

type OwnProps = {
  consoleHref?: string;
  deviceId?: number;
  isPowerControlEnabled: boolean;
  label: string;
  name: string;
  nodeType: SessionInventoryConfirmActionPayloadNodeType;
  nodeUid: string;
  warning?: string;
};

const mapStateToProps = (_: RootState, ownProps: OwnProps) => ({
  consoleHref: ownProps.consoleHref,
  deviceId: ownProps.deviceId,
  isPowerControlEnabled: ownProps.isPowerControlEnabled,
  label: ownProps.label,
  name: ownProps.name,
  nodeType: ownProps.nodeType,
  nodeUid: ownProps.nodeUid,
  warning: ownProps.warning,
});

export type HardwareTableActionsCellRendererProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const HardwareTableActionsCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HardwareTableActionsCellRenderer);
