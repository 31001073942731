import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { Button } from 'dcloud-shared-ui';
import { Table } from '../../../../../../../../../components';
import { convertMbToGb } from '../../../../../../components/VirtualMachineEntities/Edit/utils';
import { MAX_TOPOLOGY_REMOTE_ACCESS_COUNT } from '../../../../../../components/VirtualMachineEntities/constants';
import { determineWillMaxRemoteAccessCountBeExceededByVm } from './utils';
import styles from './VmsTable.module.css';

export interface VmsTableProps {
  isLoading: boolean;
  virtualMachines: VirtualMachine[];
  selectedTopologyUid: string;
  setDemoVirtualMachineToConfigure: ActionCreatorWithOptionalPayload<
    VirtualMachine | undefined,
    string
  >;
  topologyRemoteAccessCount: number;
  setShowRemoteAccessCountExceededModal: ActionCreatorWithPayload<
    boolean,
    string
  >;
  isDiagramView: boolean;
}

export function VmsTable({
  isLoading,
  virtualMachines,
  selectedTopologyUid,
  setDemoVirtualMachineToConfigure,
  topologyRemoteAccessCount,
  setShowRemoteAccessCountExceededModal,
  isDiagramView,
}: VmsTableProps): ReactElement {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const i18nPrefix = 'inventoryTopologies.vms.table.headings.';
  const VMS_PAGE_SIZE_LIMIT = 15;

  const willMaxRemoteAccessCountBeExceededByVm = useCallback(
    (currentTopologyRemoteAccessCount: number, vm: VirtualMachine): boolean =>
      determineWillMaxRemoteAccessCountBeExceededByVm(
        currentTopologyRemoteAccessCount,
        vm,
        MAX_TOPOLOGY_REMOTE_ACCESS_COUNT,
      ),
    [],
  );

  const columns = useMemo<Column<VirtualMachine>[]>(
    () => [
      {
        Cell: ({ row, value }: CellProps<VirtualMachine>) =>
          willMaxRemoteAccessCountBeExceededByVm(
            topologyRemoteAccessCount,
            row.original,
          ) ? (
            <Button
              colour="link"
              onClick={() => setShowRemoteAccessCountExceededModal(true)}
              className={styles.nameButton}
            >
              {value}
            </Button>
          ) : isDiagramView ? (
            <Button
              colour="link"
              onClick={() => setDemoVirtualMachineToConfigure(row.original)}
              className={styles.nameButton}
            >
              {value}
            </Button>
          ) : (
            <Link
              to={`${url.replace(':uid', selectedTopologyUid)}/democonfigure`}
              onClick={() => setDemoVirtualMachineToConfigure(row.original)}
            >
              {value}
            </Link>
          ),
        Header: () => t(`${i18nPrefix}name`),
        accessor: 'name',
      },
      {
        Header: () => t(`${i18nPrefix}description`),
        accessor: 'description',
        maxWidth: 25,
      },
      {
        Header: () => t(`${i18nPrefix}nics`),
        accessor: 'nicQty',
        maxWidth: 25,
      },
      {
        Header: () => t(`${i18nPrefix}cpus`),
        accessor: 'cpuQty',
        maxWidth: 25,
      },
      {
        Cell: ({ value }) =>
          value ? convertMbToGb(value, { roundCeil: false }) : null,
        Header: () => t(`${i18nPrefix}memoryGb`),
        accessor: 'memoryMb',
        maxWidth: 50,
      },
    ],
    [
      isDiagramView,
      selectedTopologyUid,
      setDemoVirtualMachineToConfigure,
      setShowRemoteAccessCountExceededModal,
      t,
      topologyRemoteAccessCount,
      url,
      willMaxRemoteAccessCountBeExceededByVm,
    ],
  );

  return (
    <Table<VirtualMachine>
      ariaLabel="VMs Table"
      columns={columns}
      loading={isLoading}
      data={virtualMachines}
      initialState={{
        pageSize: VMS_PAGE_SIZE_LIMIT,
        sortBy: [{ desc: false, id: 'name' }],
      }}
    />
  );
}
