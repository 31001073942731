import React, { ReactElement } from 'react';
import { ConnectedUserIntegrations } from '../container';
import { PUBLIC_CLOUD_INTEGRATION_TYPE } from '../../../../../constants';

export const PublicCloud = (): ReactElement => {
  return (
    <ConnectedUserIntegrations
      integrationType={PUBLIC_CLOUD_INTEGRATION_TYPE}
    />
  );
};
