import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../redux/views/session-view/session/api/expoApi';
import { getIconDataFromNodeDetails } from './utils/utils';
import { IconInfoContainerProps } from './container';
import { IconInfo } from '.';

type ConnectedExpoIconInfoProps = IconInfoContainerProps & FetchExpoSessionArgs;

export function ConnectedExpoIconInfo({
  engagementUid,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedExpoIconInfoProps): ReactElement {
  const data = expoSessionApi.endpoints.getExpoSession.useQueryState(
    { engagementUid, sessionUid, versionUid },
    {
      selectFromResult: ({ data }) =>
        getIconDataFromNodeDetails(
          data?.diagram.nodes.filter(
            (node) => node.uid === nodeUid,
          )[0] as SessionIconNode,
        ),
    },
  );

  return <IconInfo data={data.keyValuePairs} title={data.title} />;
}
