import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface LicencesState {
  licenceToEdit?: string;
  licenceToDelete?: Licence;
  showInventoryModal: boolean;
}

export const initialState: LicencesState = {
  licenceToDelete: undefined,
  licenceToEdit: undefined,
  showInventoryModal: false,
};

const slice = createSlice({
  initialState,
  name: 'licences',
  reducers: {
    reset: () => initialState,
    setLicenceToDelete: (state, action: PayloadAction<Licence | undefined>) => {
      state.licenceToDelete = action.payload;
    },
    setLicenceToEdit: (state, action: PayloadAction<string | undefined>) => {
      state.licenceToEdit = action.payload;
    },
    setShowInventoryModal: (state, action: PayloadAction<boolean>) => {
      state.showInventoryModal = action.payload;
    },
  },
});

export const getLicences = (state: RootState): typeof initialState =>
  state.configuration[slice.name];

export const { reducer: licencesReducer } = slice;
export const {
  reset,
  setLicenceToDelete,
  setLicenceToEdit,
  setShowInventoryModal,
} = slice.actions;
