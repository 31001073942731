import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import { useSelectedItem, UseSelectedItem } from './hooks/useSelectedItem';
import { useToggleGrid, UseToggleGrid } from './hooks/useToggleGrid';

interface DiagramContextReturn {
  selectedItem: UseSelectedItem['selectedItem'];
  showGrid: UseToggleGrid['showGrid'];
  toggleSelectedItem: UseSelectedItem['toggleSelectedItem'];
  toggleGrid: UseToggleGrid['toggleGrid'];
}

export const DiagramContext = createContext<DiagramContextReturn>(null!);

export function DiagramProvider({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  const { selectedItem, toggleSelectedItem } = useSelectedItem();
  const { showGrid, toggleGrid } = useToggleGrid();

  return (
    <DiagramContext.Provider
      value={{ selectedItem, showGrid, toggleGrid, toggleSelectedItem }}
    >
      {children}
    </DiagramContext.Provider>
  );
}

export function useDiagramContext(): DiagramContextReturn {
  const context = useContext(DiagramContext);

  if (!context) {
    throw new Error('Please use within the "DiagramProvider"');
  }

  return context;
}
