import React, { HTMLAttributes, isValidElement, ReactElement } from 'react';
import classnames from 'classnames';
import { AlignTypes } from '../Tabs';
import { TabsItem } from '../TabsItem';

export interface TabsItemsProps extends HTMLAttributes<HTMLUListElement> {
  activeTab: number;
  bordered: boolean;
  align?: AlignTypes;
  id: string;
  items: React.ReactNode;
  setSelected(index: number, title: string): void;
}

export function TabsItems({
  activeTab,
  align,
  bordered,
  id,
  items,
  setSelected,
}: TabsItemsProps): ReactElement {
  return (
    <ul
      role="tablist"
      className={classnames('tabs', `tabs--${align}`, {
        [`tabs--bordered`]: bordered,
      })}
    >
      {React.Children.map(items, (Child, index) =>
        isValidElement(Child) ? (
          <TabsItem
            active={index === activeTab}
            bordered={bordered}
            id={id}
            index={index}
            setSelected={setSelected}
            title={Child.props.title}
            icon={Child.props.icon}
          />
        ) : null,
      )}
    </ul>
  );
}
