import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';

interface LicenceDeleteModalProps {
  licence: Licence;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  isDeleting: boolean;
}

export function LicenceDeleteModal({
  licence,
  onClose,
  onConfirm,
  isDeleting,
}: LicenceDeleteModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      heading={t('licences.delete.heading').replace(
        ':licence',
        licence.inventoryLicense.name,
      )}
      subHeading={t('licences.delete.subHeading')}
      loading={isDeleting}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="licence-delete-modal"
    />
  );
}
