import { connect } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../redux/views/session-view/session/slice';
import { SessionInventoryContent } from '.';

const mapStateToProps = (state: RootState) => ({
  activeTab: sessionViewSelectors.getSessionInventoryActiveTab(state),
});

export type SessionInventoryContentProps = ReturnType<typeof mapStateToProps>;

export const SessionInventoryContentContainer = connect(mapStateToProps)(
  SessionInventoryContent,
);
