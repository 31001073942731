import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../redux/views/session-view/session/api/expoApi';
import { DemoHeaderTitle } from '.';

export type ConnectedExpoDemoHeaderTitleProps = {
  engagementUid: string;
  sessionUid: string;
  versionUid: string;
};

export function ConnectedExpoDemoHeaderTitle({
  engagementUid,
  sessionUid,
  versionUid,
}: ConnectedExpoDemoHeaderTitleProps): ReactElement {
  const { isError, isFetching, isLoading, demoTitle } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isFetching, isLoading, isError }) => ({
          demoTitle: data?.sessionDetails.name,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <DemoHeaderTitle
      demoTitle={demoTitle}
      isError={isError}
      isLoading={isLoading || isFetching}
    />
  );
}
