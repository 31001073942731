import { SelectOption } from 'dcloud-shared-ui';
import { v4 } from 'uuid';
import { TEMP_UID_KEY } from '../constants';

export function createTemporaryNetwork(
  fields: AddNetworkFields,
): HardwareNetworkInterface {
  return {
    network: {
      _links: {},
      name: '',
      uid: fields.network,
    },
    networkInterface: {
      id: fields.interface,
    },
    uid: `${TEMP_UID_KEY}${v4()}`,
  };
}

export const convertNetworksToOptions = (
  networks: Network[],
): SelectOption<string>[] =>
  networks.map((item) => ({ name: item.name, value: item.uid }));

export const convertInterfacesToOptions = (
  interfaces: NetworkInterface[],
  selectedInterfaces: HardwareNetworkInterface[],
): SelectOption<string>[] => {
  return interfaces
    .filter((item) => {
      const selectedIds = selectedInterfaces.map(
        (item) => item.networkInterface.id,
      );

      return !selectedIds.includes(item.id);
    })
    .map((item) => ({ name: item.id, value: item.id }));
};

export const returnFieldOrUndefined = (
  field: UidOnlyField,
): UidOnlyField | undefined => {
  return field.uid ? field : undefined;
};
