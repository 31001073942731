import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { ExpoIntegrationPanelContainer } from './expoContainer';
import { DCloudIntegrationPanelContainer } from './dCloudContainer';

export type IntegrationPanelContainerProps = {
  integrationType: IntegrationType;
};

export function IntegrationPanelContainer({
  integrationType,
}: IntegrationPanelContainerProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ExpoIntegrationPanelContainer
        engagementUid={engagementUid}
        integrationType={integrationType}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }
  return (
    <DCloudIntegrationPanelContainer
      integrationType={integrationType}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}
