import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadonlyField } from '../../../../../../../components';

interface ExternalDnsGeneralProps {
  externalDnsRecord: ExternalDnsRecord;
}

export function ExternalDnsGeneral({
  externalDnsRecord,
}: ExternalDnsGeneralProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-6">
        <ReadonlyField
          label={t('traffic.externalDns.edit.general.aRecord')}
          value={externalDnsRecord.aRecord}
        />
      </div>
    </div>
  );
}
