import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from 'dcloud-shared-ui/dist/components/Select';
import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAddMailServerMutation } from '../../../../../../../redux/configuration/traffic/mail-server/api';
import { mailServerSchema } from '../../../../../../../validation';
import { buildVmNameOptions } from '../../../NatRules/utils';
import { buildAssetOptions, buildMailServerPayload } from '../../utils';
import { PLEASE_SELECT_OPTION } from '../../../../../../../constants/form';
import { MailServerAddForm } from '.';

interface MailServerAddFormContainerProps {
  vmTargets: VmNicTarget[];
  dnsAssets?: InventoryDnsAsset[];
}

export function MailServerAddFormContainer({
  vmTargets,
  dnsAssets,
}: MailServerAddFormContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
  } = useForm<MailServerFormData>({
    defaultValues: {
      asset: '',
      ipAddress: '',
      virtualMachine: '',
    },
    mode: 'all',
    resolver: yupResolver(mailServerSchema),
  });
  const [vmIpAddressOptions, setVmIpAddressOptions] = useState<
    SelectOption<string>[]
  >([PLEASE_SELECT_OPTION]);

  const [createMailServer, { isError, isLoading }] = useAddMailServerMutation();
  const handleCreate: SubmitHandler<MailServerFormData> = async (formData) => {
    const payload = buildMailServerPayload({ formData, topologyUid });
    await createMailServer(payload);

    !isError && resetForm();
  };

  return (
    <MailServerAddForm
      control={control}
      errors={errors}
      formState={formState}
      handleCreate={handleCreate}
      handleSubmit={handleSubmit}
      vmOptions={buildVmNameOptions(vmTargets)}
      assetOptions={buildAssetOptions(dnsAssets)}
      isCreating={isLoading}
      setVmIpAddressOptions={setVmIpAddressOptions}
      ipAddressOptions={vmIpAddressOptions}
      vmTargets={vmTargets}
    />
  );
}
