/* eslint-disable sort-keys */
import { IconFormKeyValuePairTypes } from '../../../Diagram/components/DiagramModals/IconModals/components/IconForm/constants';

export const ENDPOINT_KITS_FIXTURE = {
  A: false,
  B: true,
  C: true,
  D: true,
  E: false,
  F: false,
};

export const HARDWARE_PANEL_INFO_SECTION = {
  description:
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
  deviceType: 'Adaptive Security Applicances',
  hwConsole: 'True',
  name: 'AAA 555',
  powerControl: 'True',
  sessionDevice: 'Switch 1',
};

export const INFO_PANEL_ANYCONNECT_SECTION_FIXTURE = {
  vpnUserIds: 'user1',
  vpnPassword: '12345',
  vpnServer: 'https://dcloud2-local.cisco.com',
};

export const INFO_PANEL_DNS_SECTION_FIXTURE = [
  {
    name: 'vcse.somnewhere-007.dc-01',
    type: 'A',
  },
  {
    name: 'vcse.somnewhere-007.dc-02',
    type: 'SRV',
  },
  {
    name: 'vcse.somnewhere-008.dc-03',
    type: 'SRV',
  },
  {
    name: 'vcse.somnewhere-008.dc-04',
    type: 'A',
  },
];

export const INFO_PANEL_ENDPOINT_KITS_SECTION = [
  {
    uid: 1,
    kitId: 1,
    name: 'Router A WiFi Credentials',
    status: 'UNKNOWN',
    deviceType: '89x',
    statusDate: '',
    dataSSID: 'cisco-somewhere-data-kit-1',
    voiceSSID: 'cisco-somewhere-voice-kit-1',
    passphrase: '12345',
  },
  {
    uid: 2,
    kitId: 2,
    name: 'Router B WiFi Credentials',
    status: 'ACTIVE',
    deviceType: '89x',
    statusDate: '',
    dataSSID: 'cisco-somewhere-data-kit-2',
    voiceSSID: 'cisco-somewhere-voice-kit-2',
    passphrase: '12345',
  },
  {
    uid: 3,
    kitId: 3,
    name: 'Router C WiFi Credentials',
    status: 'DISCONNECTED',
    deviceType: '89x',
    statusDate: '',
    dataSSID: 'cisco-somewhere-data-kit-3',
    voiceSSID: 'cisco-somewhere-voice-kit-3',
    passphrase: '12345',
  },
];

export const INFO_PANEL_PHONE_NUMBERS_SECTION = [
  {
    description: 'Name One',
    did: '11-2222222',
    dn: 1111,
  },
  {
    description: 'Name Two',
    did: '22-2222222',
    dn: 2222,
  },
  {
    description: 'Name Three',
    did: '33-3333333',
    dn: 3333,
  },
];

export const INFO_PANEL_PUBLIC_IP_PROXY_FIXTURE = {
  proxy: [
    {
      targetVm: 'Collab DB',
      linkName: 'Click me...',
      href: 'https://dcloud2-local.cisco.com',
    },
  ],
  publicIp: [
    {
      publicAddress: '173.38.218.129',
      privateAddress: '198.18.131.100',
      description: 'Some Device',
    },
    {
      publicAddress: '173.38.218.130',
      privateAddress: '198.18.131.201',
      description: 'Mail Server 1',
    },
  ],
};

export const INFO_PANEL_SESSION_INFORMATION_FIXTURE: SessionInformation = {
  endTime: '23-Sep-2021 14:26',
  lastModified: '29-Sep-2021 09:18',
  owner: 'someone@cisco.com',
  parentDemo: 'Test Demo 1',
  sessionId: '123456',
  virtualCenter: 3,
  vpnAvailable: true,
  licenses: 'There are no session Licenses configured in this demo.',
};

export const SHARE_PANEL_GRID_FIXTURE = [
  {
    fullName: 'person1@cisco.com',
    userId: '1',
  },
  {
    fullName: 'person2@cisco.com',
    userId: '2',
  },
  {
    fullName: 'person3@cisco.com',
    userId: '3',
  },
  {
    fullName: 'person4@cisco.com',
    userId: '4',
  },
];

export const VM_PANEL_INFO_SECTION = {
  description:
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
  guestOs: 'notRunning',
  memory: '6',
  name: 'somewhere-333.dc-01.com',
  osFamily: 'Windows',
  vCPUs: '18',
  vmWareTools: 'guestToolsNotRunning',
};

export const VM_PANEL_STATIC_INFO: VmPanelStaticInfo = {
  description:
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
  memory: 6,
  name: 'somewhere-333.dc-01.com',
  osFamily: 'Windows',
  vCPUs: 18,
};

export const VM_PANEL_DYNAMIC_INFO: VmPanelDynamicInfo = {
  guestOs: 'notRunning',
  vmWareTools: 'guestToolsNotRunning',
};

export const VM_PANEL_REMOTE_ACCESS_SECTION: VmPanelRemoteAccess = {
  console: { href: 'https://dcloud2-local.cisco.com' },
  internalUrl1: 'http://dcloud2-local.cisco.com',
  internalUrl1Description: 'Description URL1',
  internalUrl2: 'http://dcloud2-local.cisco.com',
  internalUrl2Description: 'Description URL2',
  password: 'Password',
  rdp: {
    href: 'https://dcloud2-local.cisco.com',
    ipAddress: '1.1.1.1',
    networkName: 'RDP Network Name',
  },
  ssh: {
    href: 'https://dcloud2-local.cisco.com',
    ipAddress: '2.2.2.2',
    networkName: 'SSH Network Name',
  },
  user: 'Administrator',
};

export const VM_NODE_SESSION_DETAILS_REMOTE_ACCESS_FIXTURE: SessionVmNodeSessionDetailsRemoteAccess =
  {
    console: { href: 'https://dcloud2-local.cisco.com' },
    password: 'Password',
    rdp: {
      href: 'https://dcloud2-local.cisco.com',
      ipAddress: '1.1.1.1',
      networkName: 'RDP Network Name',
    },
    ssh: {
      href: 'https://dcloud2-local.cisco.com',
      ipAddress: '2.2.2.2',
      networkName: 'SSH Network Name',
    },
    username: 'Username',
    name: 'Name',
  };

export const VM_NODE_SESSION_DETAILS_FIXTURE: SessionVmNodeSessionDetails = {
  mor: 'vm-19260',
  remoteAccess: VM_NODE_SESSION_DETAILS_REMOTE_ACCESS_FIXTURE,
  serverId: '123456',
};

export const VM_NODE_FIXTURE: SessionVmNode = {
  assetType: 'VM',
  asset: {
    _links: {},
    advancedSettings: {
      allDisksNonPersistent: false,
      biosUuid: '61 62 63 64 65 66 67 68-69 6a 6b 6c 6d 6e 6f 70',
      hiddenFromSession: false,
      nameInHypervisor: 'Win_10-VM',
      notStarted: false,
    },
    cpuQty: 8,
    description: 'Collab DB',
    inventoryVmId: 'templatevm1',
    memoryMb: 3,
    name: 'Collab DB',
    nestedHypervisor: false,
    osFamily: 'WINDOWS',
    remoteAccess: VM_NODE_SESSION_DETAILS_REMOTE_ACCESS_FIXTURE,
    topology: { uid: 'lontopology' },
    uid: 'lonnoassetnode',
    vmNetworkInterfaces: [],
  },
  display: {},
  sessionDetails: VM_NODE_SESSION_DETAILS_FIXTURE,
  topology: { uid: 'lontopology' },
  topologyVersion: { uid: 'lontopologyv1' },
  uid: 'uid',
  _links: {},
};

export const SESSION_HW_NODE_FIXTURE: SessionHwNode = {
  assetType: 'HARDWARE_ITEM',
  uid: 'uid',
  topology: {
    uid: 'lontopology',
  },
  topologyVersion: {
    uid: 'lontopologyv1',
  },
  asset: {
    uid: 'lonhardwareitem2',
    name: 'ISA3000-FTD',
    powerControlEnabled: true,
    hardwareConsoleEnabled: true,
    inventoryStartupScript: {
      uid: 'script1',
      name: 'EN-3850-switch-start.xml',
    },
    inventoryShutdownScript: {
      uid: 'script2',
      name: 'EN-3850-switch-stop.xml',
    },
    inventoryCustomScript: {
      uid: 'script3',
      name: 'custom.xml',
    },
    inventoryTemplateConfigScript: {
      uid: 'template1',
      name: 'en-sw-3850-16-v1.txt',
    },
    hardwareNetworkInterfaces: [
      {
        uid: 'uid',
        networkInterface: {
          id: 'GigabitEthernet1/1',
        },
        network: {
          uid: 'lontopologyv1nwk',
          name: 'Default Network',
          _links: {
            self: {
              href: 'http://localhost/networks/lontopologyv1nwk',
            },
          },
        },
      },
    ],
    inventoryHardwareItem: {
      id: '86',
      name: 'ISA3000-FTD',
      description: 'ISA3000-FTD',
      powerControlAvailable: true,
      hardwareConsoleAvailable: true,
      networkInterfaces: [
        {
          id: 'GigabitEthernet1/1',
        },
        {
          id: 'GigabitEthernet1/2',
        },
      ],
    },
    topology: {
      uid: 'lontopology',
    },
    _links: {
      self: {
        href: 'http://localhost/hardware-items/lonhardwareitem2',
      },
      delete: {
        href: 'http://localhost/hardware-items/lonhardwareitem2',
      },
      topology: {
        href: 'http://localhost/topologies/lontopology',
      },
    },
  },
  sessionDetails: {
    deviceId: 2898,
    name: 'ISA3000-1',
    deviceType: 'ISA3000-FTD',
    status: 'INUSE',
    pduControl: true,
    consoleUrl: 'https://dcloud2-local.cisco.com',
    updated: '2022-03-17T13:04:58Z',
  },
  display: {
    x1: 0,
    y1: 30,
  },
  _links: {
    self: {
      href: 'http://localhost/nodes/lonhwitem2node',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
    asset: {
      href: 'http://localhost/hardware-items/lonhardwareitem2',
    },
  },
};

export const SESSION_ICON_NODE_FIXTURE: SessionIconNode = {
  uid: 'lonstaticiconnode',
  display: {
    x1: -31,
    y1: 26,
    drawingType: 'ICON',
    icon: 'Firewall',
    name: 'Firewall Icon',
    data: [
      {
        key: 'Key1',
        value:
          'This is a value for the Firewall for the first key within the key value pairs',
      },
      {
        key: 'This is Key2',
        value:
          'This is a value for the Firewall for the second key within the key value pairs',
      },
      {
        key: 'This is the third key for the key value pairs for the Firewall',
        value: 'Third value',
      },
      {
        key: 'Click here',
        type: IconFormKeyValuePairTypes.link,
        value: 'http://about:blank',
      },
    ],
  },
  topology: {
    uid: 'lontopology',
  },
  topologyVersion: {
    uid: 'lontopologyv1',
  },
  _links: {
    self: {
      href: 'http://localhost/nodes/lonstaticiconnode',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
  },
};

export const SESSION_ICON_NODE_EMPTY_DATA_FIXTURE: SessionIconNode = {
  uid: 'lonstaticiconnode',
  display: {
    x1: -31,
    y1: 26,
    drawingType: 'ICON',
    icon: 'Firewall',
    name: 'Firewall Icon',
    data: [],
  },
  topology: {
    uid: 'lontopology',
  },
  topologyVersion: {
    uid: 'lontopologyv1',
  },
  _links: {
    self: {
      href: 'http://localhost/nodes/lonstaticiconnode',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
  },
};

export const SESSION_DNS_FIXTURE = [
  {
    type: 'CNAME',
    name: 'Win_10',
  },
  {
    type: 'A',
    name: 'mail1',
  },
  {
    type: 'A',
    name: 'lonhost',
  },
  {
    type: 'SRV',
    name: '_sip._',
  },
];

export const SESSION_DNS_ASSETS_FIXTURE = [
  {
    name: 'px.dc-03.com',
  },
  {
    name: 'cb576.dc-03.com',
  },
];

export const SESSION_PHONE_NUMBERS = [
  {
    description: '',
    did: '+44 118 916 2009',
    dn: 6019,
  },
  {
    description: '',
    did: '+44 118 916 2007',
    dn: 6017,
  },
];

export const SESSION_PROXY_FIXTURE = [
  {
    targetVm: 'Collab DB',
    linkName: 'Click me...',
    href: 'https://dcloud2-local.cisco.com',
  },
];

export const SESSION_PUBLIC_ADDRESSES_FIXTURE = [
  {
    publicAddress: '173.38.218.129',
    privateAddress: '198.18.131.100',
    description: 'Some Device',
  },
  {
    publicAddress: '173.38.218.130',
    privateAddress: '198.18.131.201',
    description: 'Mail Server 1',
  },
];

export const SESSION_INFORMATION_FIXURE: SessionInformation = {
  endTime: '2022-01-28T17:00:00Z',
  lastModified: '2022-03-17T13:04:58Z',
  owner: 'someone@cisco.com',
  parentDemo: '502378',
  sessionId: '111111',
  virtualCenter: 3,
  vpnAvailable: false,
  licenses: 'There are no session Licenses configured in this demo.',
};

export const SESSION_INFO_PANEL_ENDPOINTS_FIXTURE = [
  {
    uid: 3,
    kitId: 3,
    name: 'Leonid Router L4 UAT',
    status: 'UNKNOWN',
    deviceType: '89x',
    statusDate: '',
    voiceSSID: 'cisco-dcloud-voice-kit-3',
    dataSSID: 'cisco-dcloud-data-kit-3',
    passphrase: 'adgjmptw',
  },
  {
    uid: 4,
    kitId: 4,
    name: 'James Router L4 UAT',
    status: 'ACTIVE',
    deviceType: '1921',
    statusDate: '2022-03-01T15:14:13Z',
    voiceSSID: 'cisco-dcloud-voice-kit-4',
    dataSSID: 'cisco-dcloud-data-kit-4',
    passphrase: 'abcdefgh',
  },
  {
    uid: 5,
    kitId: 5,
    name: 'Rory Router L4 UAT',
    status: 'DISCONNECTED',
    deviceType: '891FW',
    statusDate: '2022-02-28T03:02:01Z',
    voiceSSID: 'cisco-dcloud-voice-kit-5',
    dataSSID: 'cisco-dcloud-data-kit-5',
    passphrase: 'asdfghjk',
  },
  {
    uid: 6,
    kitId: 6,
    name: 'Router C WiFi Credentials - without optional data',
    status: 'DISCONNECTED',
    deviceType: '89x',
    statusDate: '',
  },
];

export const DOCUMENTATION_PANEL_FIXTURE = [
  {
    uid: 28826,
    mimeType: 'text/html',
    documentLink: 'http://www.google.co.uk',
    name: 'External document (a link to Google)',
    type: 'Web Link',
    internal: false,
    contentId: 3,
    contentTypeId: 1,
  },
  {
    uid: 28827,
    mimeType: 'application/pdf',
    documentLink: 'example-link',
    name: 'Cisco Smart Net Total Care',
    type: 'Attachment',
    internal: true,
    contentId: 3,
    contentTypeId: 1,
  },
];
