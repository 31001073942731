import { SagaIterator } from 'redux-saga';
import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { startAuthWatcher } from './auth/sagas';
import { handleAddHardwareFromDiagramWorker } from './diagram/sagas';
import { handleAddVmFromDemosTabFromDiagramWorker } from './diagram/sagas/addVm/fromDemosTab/addVmFromDemosTabSagas';
import { handleAddVmFromTemplatesTabFromDiagramWorker } from './diagram/sagas/addVm/fromTemplatesTab/addVmFromTemplatesTabSagas';
import { handleTopologyLimitsWarningAcceptedInDiagramWorker } from './diagram/sagas/shared/sharedSagas';
import { topologiesFiltersWatchers } from './topologiesFilters/sagas';
import {
  getExpoInternalDocumentWorker,
  getInternalDocumentWorker,
} from './views/session-view/session/sagas';
import { sessionInventoryWatchers } from './views/session-view/session-inventory/sagas';
import { updateNodeInDiagramWorker } from './diagram/sagas/updateDrawingTypeNode/Text/updateFontSizeSaga';

export function* rootSaga(): SagaIterator {
  yield all([
    yield fork(startAuthWatcher),
    yield fork(topologiesFiltersWatchers),
    yield fork(sessionInventoryWatchers),
    yield takeEvery('views/getInternalDocument', getInternalDocumentWorker),
    yield takeEvery(
      'views/getExpoInternalDocument',
      getExpoInternalDocumentWorker,
    ),
    yield takeEvery(
      'diagram/handleAddHardwareFromDiagram',
      handleAddHardwareFromDiagramWorker,
    ),
    yield takeEvery(
      'diagram/handleAddVmFromTemplatesTabFromDiagramWorker',
      handleAddVmFromTemplatesTabFromDiagramWorker,
    ),
    yield takeEvery(
      'diagram/handleAddVmFromDemosTabFromDiagram',
      handleAddVmFromDemosTabFromDiagramWorker,
    ),
    yield takeLatest(
      'topologyLimits/setTopologyLimitsWarningAcceptedInDiagram',
      handleTopologyLimitsWarningAcceptedInDiagramWorker,
    ),
    yield takeLatest('diagram/updateNode', updateNodeInDiagramWorker),
  ]);
}
