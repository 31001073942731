import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './FormReadOnlyInput.module.css';
type FormReadOnlyInputProps = {
  label: string;
  value?: string;
};

export function ConfigureFormReadOnlyInput({
  label,
  value,
}: FormReadOnlyInputProps): ReactElement {
  const classes = classNames(styles.root, 'col-md-12 col-lg-9');
  return (
    <div className={classes}>
      <p>{label}</p>
      <p className="base-padding">{value}</p>
    </div>
  );
}
