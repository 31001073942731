import React, { ChangeEvent, ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { integrationInputParameterFieldsSchema } from '../../../../validation';
import {
  ControlledCheckbox,
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../components';
import { DefaultToParentField } from '../../../components/DefaultToParentField/DefaultToParentField';
import styles from '../../../components/DefaultToParentField/DefaultToParentField.module.css';
import { determineInputFieldValue } from '../../../utils/workFlowParameterMappingUtil';
import { WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME } from '../../../components/DefaultToParentField/DefaultToParentFieldConstants';
import { useTriggerWorkflowParamValueField } from '../../../hooks/use-trigger-workflow-param-value-field/use-trigger-workflow-param-value-field';

type IntegrationInputProps = {
  input: IntegrationInput;
  setInput: (payload: SetInputPayload) => void;
  setInputStatus: (payload: SetInputStatusPayload) => void;
};

export default function IntegrationInput({
  input,
  setInput,
  setInputStatus,
}: IntegrationInputProps): ReactElement {
  const defaultToWorkflowEngine = !!input.default;
  const formMethods = useForm<IntegrationInputParameterFields>({
    defaultValues: {
      default: input.default || '',
      defaultToWorkflowEngine,
      description: input.description || '',
      name: '',
      userConfigurable: false,
    },
    mode: 'all',
    resolver: yupResolver(integrationInputParameterFieldsSchema()),
  });
  const {
    errors,
    control,
    formState: { isValid, isDirty },
    watch,
    setValue,
    trigger,
  } = formMethods;
  const { t } = useTranslation();
  const watchDefaultToWorkflowEngine: boolean | undefined = watch(
    `${WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME}-${input.name}`,
    !!input.default,
  );
  const watchUserConfigurable = watch('userConfigurable');

  useEffect(() => {
    setInput({
      defaultToWorkflowEngine,
      name: input.name,
    });
  }, [defaultToWorkflowEngine, input.name, setInput]);

  useEffect(() => {
    setInputStatus({ isValid, name: input.name });
  }, [
    isValid,
    setInputStatus,
    input.name,
    input.default,
    input.userConfigurable,
  ]);

  useTriggerWorkflowParamValueField({
    defaultToParent: watchDefaultToWorkflowEngine,
    isDirty,
    trigger,
    userConfigurable: watchUserConfigurable,
  });

  const onDefaultToWorkflowValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const value = determineInputFieldValue(
      '',
      input.default || '',
      event.target.checked,
    );
    setValue('default', value, {
      shouldDirty: true,
    });
    setInput({
      default: value,
      defaultToWorkflowEngine: event.target.checked,
      name: input.name,
    });
  };

  const onUserConfigurableValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setInput({
      name: input.name,
      userConfigurable: event.target.checked,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <div className="dbl-margin-left dbl-margin-right">
        <div className="row">
          <div className="col-4">
            <ControlledTextfield<IntegrationInputParameterFields>
              control={control}
              error={errors?.name?.message}
              label={t('integrations.forms.common.inputs.name')}
              name="name"
              required={true}
              customOnChange={(event) =>
                setInput({ fieldName: event.target.value, name: input.name })
              }
            />
          </div>
          <div className="col-4">
            <ControlledTextfield<IntegrationInputParameterFields>
              control={control}
              error={errors?.default?.message}
              label={t('integrations.forms.common.inputs.default')}
              name="default"
              disabled={watchDefaultToWorkflowEngine}
              required={!watchUserConfigurable}
              customOnChange={(event) =>
                setInput({
                  default: event.target.value,
                  name: input.name,
                })
              }
            />
          </div>
          <div
            className={classNames('col-4', styles.defaultToParentFieldWrapper)}
          >
            <DefaultToParentField
              mappingName={input.name}
              onChange={onDefaultToWorkflowValueChange}
              defaultValue={!!input.default}
              userConfigurable={watchUserConfigurable}
              templateDefaultValue={input.default}
              isAdmin={true}
            />
          </div>
        </div>
        <div className="half-margin-top">
          <ControlledTextarea<IntegrationInputParameterFields>
            control={control}
            error={errors?.description?.message}
            label={t('integrations.forms.common.inputs.description')}
            name="description"
            required={true}
            customOnChange={(event) =>
              setInput({
                description: event.target.value,
                name: input.name,
              })
            }
          />
        </div>
        <div className="half-margin-top">
          <ControlledCheckbox<IntegrationInputParameterFields>
            id="userConfigurable"
            customId={`userConfigurable-${input.name}`}
            defaultValue={false}
            control={control}
            label={t('integrations.forms.common.inputs.userConfigurable')}
            customOnChange={onUserConfigurableValueChange}
          />
        </div>
      </div>
    </FormProvider>
  );
}
