import React, { ReactElement, useState } from 'react';
import { IconButton } from 'dcloud-shared-ui';

type IconButtonProps = {
  value: string;
};

export function CopyButton({ value }: IconButtonProps): ReactElement {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipBoard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return isCopied ? (
    <IconButton size="small" icon="check" colour="link" />
  ) : (
    <IconButton
      icon="clone"
      colour="link"
      size="small"
      onClick={handleCopyToClipBoard}
      testId="copy-button"
    />
  );
}
