import React, { ReactElement } from 'react';
import { useTheme } from 'dcloud-shared-ui';
import { determineColorForConnection } from '../../utils';
import { physicalConnectionLineDefaults } from '../../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLogicalConnection/constants';

export type PhysicalConnectionProps = {
  connection: DiagramConnection;
  opacity: string;
  isHiddenFromSessionView?: boolean;
};

export const PhysicalConnection = ({
  connection,
  opacity,
  isHiddenFromSessionView,
}: PhysicalConnectionProps): ReactElement => {
  const [theme] = useTheme();
  const connectionType = 'PHYSICAL';

  return (
    <line
      stroke={determineColorForConnection(
        connection.display?.connection?.color,
        theme,
        connection.uid,
        true,
      )}
      strokeWidth={physicalConnectionLineDefaults.strokeWidth}
      opacity={opacity}
      data-uid={connection.uid}
      data-component={connection.uid}
      data-connection-from={connection.nodes.from.uid}
      data-connection-to={connection.nodes.to.uid}
      data-connection-type={connectionType}
      data-connection-stroke-type={
        connection.display?.connection?.strokeType ||
        physicalConnectionLineDefaults.strokeType
      }
      className={isHiddenFromSessionView ? 'cursorNotAllowed' : ''}
    ></line>
  );
};
