import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components';
import { getMaxAvailable } from '../../../../pages/EditTopology/configuration/Licences/utils';
import { EditLicenceForm } from '../EditLicenceForm';
import { EditLicenceModalProps } from './container';
import styles from './EditLicenceModal.module.css';

export function EditLicenceModal({
  availableLicenses,
  updateLicence,
  setLicenceToEdit,
  isUpdating,
  licence,
}: EditLicenceModalProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Modal
      className={styles.root}
      testId="edit-licence-modal"
      title={t('licences.edit.title')}
      show={!!licence}
      size="default"
      onClose={() => setLicenceToEdit(undefined)}
      clickScreenToClose={true}
    >
      {licence && (
        <EditLicenceForm
          licence={licence}
          loading={isUpdating}
          onSubmit={updateLicence}
          maxAvailable={getMaxAvailable(licence, availableLicenses) || 0}
        />
      )}
    </Modal>
  );
}
