import React, { ReactElement } from 'react';
import { InternalIpProps } from './container';
import { InternalIpPanelTableLoader } from './components/InternalIpPanelTableLoader';
import { InternalIpNoDataAlert } from './components/InternalIpNoDataAlert';
import { InternalIpGrid } from './components/InternalIpGrid';
import { InternalIpHeader } from './components/InternalIpHeader';

export function InternalIP({
  sessionInternalAddresses,
  isLoading,
  isError,
}: InternalIpProps): ReactElement {
  return (
    <>
      <InternalIpHeader
        isLoading={isLoading}
        isError={isError}
        sessionInternalAddresses={sessionInternalAddresses}
      />
      <InternalIpPanelTableLoader isLoading={isLoading} isError={isError} />
      <InternalIpNoDataAlert
        isLoading={isLoading}
        isError={isError}
        sessionInternalAddresses={sessionInternalAddresses}
      />
      <InternalIpGrid
        isLoading={isLoading}
        isError={isError}
        sessionInternalAddresses={sessionInternalAddresses}
      />
    </>
  );
}
