/* eslint @typescript-eslint/no-explicit-any: 0 */
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { ControlledFieldProps } from '../types';
import { ControlledTextFieldSkeleton } from '../ControlledTextfield/ControlledTextFieldSkeleton';
import { TextfieldWithDescription } from './components/TextFieldWithDescription';

export function ControlledTextfieldWithDescription<
  T extends Record<string, any>,
>({
  control,
  defaultValue = '',
  error,
  horizontal = false,
  label,
  labelClass,
  loading = false,
  name,
  required = false,
  readonly = false,
  testId,
  id,
  description,
  descriptionClass,
  ...inputProps
}: ControlledFieldProps<T> & {
  description: string;
  descriptionClass?: string;
}): ReactElement {
  if (loading) {
    return <ControlledTextFieldSkeleton />;
  }

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      render={({ name: fieldName, onBlur, onChange, value }) => (
        <TextfieldWithDescription
          error={error}
          horizontal={horizontal}
          label={label}
          labelClass={labelClass}
          description={description}
          descriptionClass={descriptionClass}
          name={fieldName}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          required={required}
          readOnly={readonly}
          data-testid={testId}
          id={id}
          {...inputProps}
        />
      )}
    />
  );
}
