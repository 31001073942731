import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isUserPermitted } from '../../auth/components/UserRoleGuard/utils';
import { HelpButton } from '../../components/HelpButton';
import { HOME_HELP_LINKS } from '../../constants/user-guide';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Tiles } from './components/Tiles';
import { HomeProps } from './container';
import { HomePageCarousel } from './components/HomePageCarousel';
import { Hero } from './components/Hero';
import { HeroWebex } from './components/HeroWebex';

export function Home({ userRoles }: HomeProps): ReactElement {
  const { t } = useTranslation();
  useScrollToTop();

  useEffect(() => {
    document.title = t('siteTitle');
  }, [t]);

  const userHasAccess = isUserPermitted(userRoles || [], ['ROLE_USER']);

  return (
    <DefaultLayout>
      <HomePageCarousel
        slides={[
          <Hero key={0} userHasAccess={userHasAccess} />,
          <HeroWebex key={1} />,
        ]}
      />
      <Tiles userRoles={userRoles || []} />
      <HelpButton links={HOME_HELP_LINKS} />
    </DefaultLayout>
  );
}
