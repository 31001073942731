import React, { ReactElement } from 'react';
import {
  expoSessionApi,
  usePostExpoSessionVmActionMutation,
} from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { getMorIdFromSessionDetails } from '../../../../../utils';
import { ControlProps } from './container';
import { Control } from '.';

type ConnectedExpoControlProps = ControlProps & FetchExpoSessionArgs;

export function ConnectedExpoControl({
  engagementUid,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedExpoControlProps): ReactElement {
  const { mor } = expoSessionApi.endpoints.getExpoSession.useQueryState(
    { engagementUid, sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        mor: getMorIdFromSessionDetails(
          (
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionVmNode
          ).sessionDetails,
        ),
      }),
    },
  );
  const [postSessionVmAction] = usePostExpoSessionVmActionMutation();

  const postVmAction = (action: SessionVmActions) => {
    postSessionVmAction({
      action,
      engagementUid,
      mor,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });
  };

  return <Control postVmAction={postVmAction} />;
}
