import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {
  sessionInventorySelectors,
  setShowConfirmForActionForHw,
  setShowConfirmForActionForVm,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import {
  usePostSessionHwActionMutation,
  usePostSessionVmActionMutation,
} from '../../../../../redux/views/session-view/session/api/api';
import { buildLabel } from '../SessionInventoryActionsConfirmModal/utils';
import { addErrorToast } from '../../../../../redux/toast/slice';
import { useSessionUserContext } from '../../../../../hooks/use-session-user-context';
import { postAction } from './utils';
import { SessionInventoryActionConfirmModal } from '.';

export const SessionInventoryActionConfirmModalContainer = ({
  setShowConfirmForActionForHw,
  setShowConfirmForActionForVm,
  showConfirmForActionForHw,
  showConfirmForActionForVm,
  addErrorToast,
}: SessionInventoryActionConfirmModalContainerProps): ReactElement => {
  const { sessionUid, versionUid } = useSessionUserContext();

  const [postSessionHwAction] = usePostSessionHwActionMutation();
  const [postSessionVmAction] = usePostSessionVmActionMutation();

  return (
    <SessionInventoryActionConfirmModal
      handleOnConfirm={() =>
        postAction({
          addErrorToast,
          postSessionHwAction,
          postSessionVmAction,
          sessionUid,
          setShowConfirmForActionForHw,
          setShowConfirmForActionForVm,
          showConfirmForActionForHw,
          showConfirmForActionForVm,
          versionUid,
        })
      }
      setShowConfirmForAction={
        showConfirmForActionForHw
          ? setShowConfirmForActionForHw
          : setShowConfirmForActionForVm
      }
      showConfirmForAction={
        showConfirmForActionForHw
          ? showConfirmForActionForHw
          : showConfirmForActionForVm
      }
      actionLabel={buildLabel(
        showConfirmForActionForVm?.actionType ||
          showConfirmForActionForHw?.actionType,
      )}
    />
  );
};

const mapDispatchToProps = {
  addErrorToast,
  setShowConfirmForActionForHw,
  setShowConfirmForActionForVm,
};

const mapStateToProps = (state: RootState) => ({
  showConfirmForActionForHw:
    sessionInventorySelectors.getShowConfirmForActionForHw(state),
  showConfirmForActionForVm:
    sessionInventorySelectors.getShowConfirmForActionForVm(state),
});

type SessionInventoryActionConfirmModalContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const ConnectedSessionInventoryActionConfirmModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionInventoryActionConfirmModalContainer);
