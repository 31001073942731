import { connect } from 'react-redux';
import { PropsWithChildren, ReactElement } from 'react';
import { getCurrentUserState } from '../../../redux/configuration/currentUser/slice';
import { RootState } from '../../../redux/store';
import { UserRoleGuard } from '.';

type OwnProps = {
  requiredRoles: UserRoles[] | undefined;
  redirectToMainPageIfNotPermitted?: boolean;
  loadingComponent?: ReactElement;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  loadingComponent: ownProps.loadingComponent,
  redirectToMainPageIfNotPermitted: ownProps.redirectToMainPageIfNotPermitted,
  requiredRoles: ownProps.requiredRoles,
  userRoles: getCurrentUserState(state).userRoles,
});

export type UserRoleGuardProps = PartialBy<
  PropsWithChildren<ReturnType<typeof mapStateToProps>>,
  'redirectToMainPageIfNotPermitted' | 'loadingComponent'
>;

export default connect(mapStateToProps)(UserRoleGuard);
