import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import styles from './videos.module.css';
import VideoTile from './components/VideoTile';
import FeaturedVideoTile from './components/FeaturedVideoTile';
import { ConnectedVideoModal } from './components/VideoModal/container';
import { FEATURED_VIDEO_ID } from './constants';
import { isRecent } from './utils/util';

interface Props {
  setVideoToPlay: (video: VideoToPlay) => void;
  videoCategories: VideoCategory[];
}

const VideosView = ({
  setVideoToPlay,
  videoCategories,
}: Props): ReactElement => {
  useScrollToTop();
  const { t } = useTranslation();
  return (
    <DefaultLayout>
      <div className={classnames('base-margin-bottom', styles.heroWrapper)}>
        <FeaturedVideoTile
          title={t('videos.header.title')}
          id={FEATURED_VIDEO_ID}
          setVideoToPlay={setVideoToPlay}
        />
      </div>
      <div className={styles.body}>
        {videoCategories.map((category) => (
          <div key={category.id}>
            <h2>{category.title}</h2>
            <div className={styles.videos}>
              {category.videos.map((video) => (
                <VideoTile
                  key={video.id}
                  id={video.id}
                  isRecent={isRecent(new Date(video.lastUpdated))}
                  image={video.image}
                  title={video.title}
                  description={video.description}
                  setVideoToPlay={setVideoToPlay}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <ConnectedVideoModal />
    </DefaultLayout>
  );
};

export default VideosView;
