import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../redux/views/session-view/session/api/api';
import { getPhoneNumbersFromSession } from './utils';
import { PhoneNumbers } from '.';

export function ConnectedDCloudPhoneNumbers({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const { isError, isFetching, isLoading, phoneNumbersData } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isFetching, isLoading, isError }) => ({
          isError,
          isFetching,
          isLoading,
          phoneNumbersData: getPhoneNumbersFromSession(data),
        }),
      },
    );

  return (
    <PhoneNumbers
      isLoading={isFetching || isLoading}
      isError={isError}
      phoneNumbers={phoneNumbersData}
    />
  );
}
