import { useCallback, useEffect } from 'react';
import * as d3 from 'd3';
import {
  calculateDefaultPositionOnGraphBasedOnType,
  calculateLineAngleByEndpoints,
  calculateLineLengthByEndpoints,
  setXY,
  x,
  y,
  zoom,
} from '../../../d3';
import { useWindowResize } from '../../../../../hooks/use-window-resize';
import { HEADER_OFFSET_VAR } from '../../../constants';
import { DefaultLineNodeDisplay } from '../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLine/constants';
import { NodeStrokeTypes } from '../../../../../components/DrawingPalettes/constants';

interface UsePosition {
  display: DiagramLineDisplay;
  nodeRef: React.RefObject<HTMLDivElement>;
  uid: string;
}

export function usePosition({ display, nodeRef, uid }: UsePosition): void {
  const position = useCallback(
    (zoomValue?: number) => {
      if (zoomValue && zoomValue !== 1) {
        d3.selectAll<HTMLDivElement, DiagramNode>(
          `line[data-line-component-stroke-type="${NodeStrokeTypes.dashed}"]`,
        ).attr('stroke-dasharray', DefaultLineNodeDisplay.dashSize * zoomValue);
      }
      const leftHandle = nodeRef.current!.childNodes[1]
        .childNodes[0] as HTMLDivElement;
      let leftHandleX: number;
      let leftHandleY: number;
      let rightHandleX: number;
      let rightHandleY: number;
      d3.select<HTMLDivElement, DiagramNode>(leftHandle)
        .datum(display)
        .attr('style', function (d) {
          const transform = d3.zoomTransform(this);
          const zx = transform.rescaleX(x);
          const zy = transform.rescaleY(y);
          const displayX = d.x1;
          const displayY = d.y1;
          leftHandleX = zx(displayX);
          leftHandleY = zy(displayY);
          return `transform-origin: left top; transform: translate(${leftHandleX}px, ${leftHandleY}px) scale(${transform.k});`;
        });

      const rightHandle = nodeRef.current!.childNodes[1]
        .childNodes[1] as HTMLDivElement;
      d3.select<HTMLDivElement, DiagramNode>(rightHandle)
        .datum(display)
        .attr('style', function (d) {
          const transform = d3.zoomTransform(this);
          const zx = transform.rescaleX(x);
          const zy = transform.rescaleY(y);
          const defaultXY = calculateDefaultPositionOnGraphBasedOnType(this);
          const displayX = d?.x2 || defaultXY.x2;
          const displayY = d?.y2 || defaultXY.y2;
          rightHandleX = zx(displayX);
          rightHandleY = zy(displayY);
          return `transform-origin: left top; transform: translate(${rightHandleX}px, ${rightHandleY}px) scale(${transform.k});`;
        });

      const line = `[data-line-component-uid="${uid}"]`;
      const [headerHeightStr] = getComputedStyle(document.body)
        .getPropertyValue(HEADER_OFFSET_VAR)
        .split('px');
      const leftHandleNodeBounds = leftHandle.getBoundingClientRect();
      const xMidLeftHandle =
        leftHandleNodeBounds.x + leftHandleNodeBounds.width / 2;
      const yMidLeftHandle =
        leftHandleNodeBounds.y +
        leftHandleNodeBounds.height / 2 -
        Number(headerHeightStr);
      const rightHandleNodeBounds = rightHandle.getBoundingClientRect();
      const xMidRightHandle =
        rightHandleNodeBounds.x + rightHandleNodeBounds.width / 2;
      const yMidRightHandle =
        rightHandleNodeBounds.y +
        rightHandleNodeBounds.height / 2 -
        Number(headerHeightStr);
      d3.select<HTMLDivElement, DiagramNode>(line)
        .attr('x1', xMidLeftHandle)
        .attr('y1', yMidLeftHandle)
        .attr('x2', xMidRightHandle)
        .attr('y2', yMidRightHandle);

      const lineSelector = nodeRef.current!.childNodes[0] as HTMLDivElement;
      d3.select<HTMLDivElement, DiagramNode>(lineSelector)
        .datum(display)
        .attr('style', function () {
          const transform = d3.zoomTransform(this);
          const nodeBoundsLeftHandle = leftHandle.getBoundingClientRect();
          const nodeBoundsRightHandle = rightHandle.getBoundingClientRect();
          const xLeftHandleMid =
            nodeBoundsLeftHandle.x + nodeBoundsLeftHandle.width / 2;
          const yLeftHandleMid =
            nodeBoundsLeftHandle.y + nodeBoundsLeftHandle.height / 2;
          const xRightHandleMid =
            nodeBoundsRightHandle.x + nodeBoundsRightHandle.width / 2;
          const yRightHandleMid =
            nodeBoundsRightHandle.y + nodeBoundsRightHandle.height / 2;
          const lineSelectorHeight = nodeBoundsLeftHandle.height;
          const lineSelectorLeftMid = lineSelectorHeight / 2;
          return `transform-origin: ${lineSelectorLeftMid}px ${lineSelectorLeftMid}px; transform: translate(${leftHandleX}px, ${leftHandleY}px) rotate(${calculateLineAngleByEndpoints(xLeftHandleMid, yLeftHandleMid, xRightHandleMid, yRightHandleMid)}deg) scale(${transform.k}); width: ${lineSelectorHeight + calculateLineLengthByEndpoints(xLeftHandleMid, yLeftHandleMid, xRightHandleMid, yRightHandleMid) / transform.k}px; height: ${lineSelectorHeight}px`;
        });
    },
    [display, nodeRef, uid],
  );

  useWindowResize(() => {
    setXY();
    position();
  });

  useEffect(() => {
    setXY();
    const initialTransform = d3.zoomTransform(
      d3.select<HTMLDivElement, unknown>('#canvas').node()!,
    );
    position(initialTransform.k);
    zoom.on(`zoom.${uid}`, function (value) {
      position(value.transform.k);
    });

    return () => {
      zoom.on(`zoom.${uid}`, null);
    };
  }, [display, position, uid]);
}
