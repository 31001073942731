import React, { ReactElement } from 'react';
import { Control, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledTextfield,
} from '../../../../../../../components';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../../../../components/FormUnsavedChangesPrompt';
import { useLeaveFormPrompt } from '../../../../../hooks/useLeaveFormPrompt';
import styles from './NatRulesIpInfo.module.css';

interface NatRulesIpInfoProps {
  control: Control<NatRuleFormData>;
  errors: DeepMap<NatRuleFormData, FieldError>;
  formState: UseFormMethods['formState'];
  isCreating: boolean;
}

export function NatRulesIpInfo({
  control,
  errors,
  isCreating,
  formState,
}: NatRulesIpInfoProps): ReactElement {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <div className={styles.root}>
      <ControlledTextfield
        className={styles.targetField}
        control={control}
        label={t('traffic.natRules.table.headings.target')}
        name="name"
        required={true}
        error={errors?.name?.message}
      />
      <ControlledTextfield
        control={control}
        label={t('traffic.natRules.table.headings.ipAddress')}
        name="ipAddress"
        required={true}
        error={errors?.ipAddress?.message}
      />
      <ControlledCheckbox
        className={styles.verticalAlign}
        control={control}
        label={t('traffic.natRules.table.headings.eastWestNat')}
        id="eastWest"
      />
      <PrimaryButton
        disabled={!isDirty || !isValid}
        loading={isCreating}
        type="submit"
        testId="natRulesAddButton"
      >
        {t('buttons.add')}
      </PrimaryButton>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </div>
  );
}
