import { Header, NavLinks, ThemeSwitch } from 'dcloud-shared-ui';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainRoutes } from '../../../constants/routes';
import UserPreferencesLink from '../components/UserPreferences/UserPreferencesLink';
import ConnectedUserPreferencesModal from '../components/UserPreferences/container';
import { NavigationButtons } from './components/NavigationButtons';

export function EditHeader(): ReactElement {
  const { t } = useTranslation();
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  const openSettingsModal = (event: { preventDefault: VoidFunction }) => {
    event.preventDefault();
    setSettingsOpen(true);
  };
  return (
    <Header
      logoLinkTo={MainRoutes.Main}
      title={t('siteTitle')}
      center={<NavigationButtons />}
      right={
        <>
          <NavLinks
            links={[
              {
                label: t('configuration.header.links.home'),
                to: MainRoutes.Main,
              },
              {
                label: t('configuration.header.links.topologies'),
                to: MainRoutes.Topologies,
              },
            ]}
          />
          <UserPreferencesLink onClick={openSettingsModal} />
          <ThemeSwitch />
          {isSettingsOpen && (
            <ConnectedUserPreferencesModal
              onClose={() => setSettingsOpen(false)}
            />
          )}
        </>
      }
    />
  );
}
