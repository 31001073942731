import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox } from '../../../../../../../components';
import styles from './ConfigureHideOnSessionViewCheckbox.module.css';

export type ConfigureHideOnSessionViewCheckboxProps = {
  sessionView: SessionView;
  saasIntegrationToEdit?: SaasIntegration;
};

export function ConfigureHideOnSessionViewCheckbox({
  sessionView,
  saasIntegrationToEdit,
}: ConfigureHideOnSessionViewCheckboxProps): ReactElement {
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <div
      className={classNames(
        styles.root,
        'col-12 half-padding-top base-padding-bottom',
      )}
    >
      <ControlledCheckbox
        control={control}
        defaultValue={
          sessionView === 'HIDE' ||
          (saasIntegrationToEdit?.hiddenFromSessionView === true &&
            sessionView === 'ALLOW')
        }
        disabled={sessionView !== 'ALLOW'}
        id="hiddenFromSessionView"
        label={t('saas.configure.formLabels.hideOnSessionView')}
        testId="hidden-from-session-view-checkbox"
      />
    </div>
  );
}
