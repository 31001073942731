import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import styles from '../../SiteHeader/SiteHeader.module.css';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const UserPreferencesLink = ({ onClick }: Props): ReactElement => {
  return (
    <a
      href=""
      className={styles.settingsLink}
      onClick={onClick}
      data-testid="user-preferences-link"
    >
      <Icon name="admin" className={styles.settingsIcon} />
    </a>
  );
};

export default UserPreferencesLink;
