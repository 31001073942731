import React, { ChangeEvent, KeyboardEvent, ReactElement } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './TagsInput.module.css';
import Tag from './Tag';

type TagsInputProps = {
  enteredText: string;
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  setText: (event: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
};

export const TagsInput = ({
  enteredText,
  tags,
  setTags,
  setText,
  error,
}: TagsInputProps): ReactElement => {
  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const text = (event.target as HTMLInputElement).value;
    if (!text && tags.length && event.key === 'Backspace') {
      (event.target as HTMLInputElement).value = '';
      setTags([...tags.slice(0, -1)]);
    }
    if (
      text.length > 2 &&
      !error &&
      event.key === 'Enter' &&
      !tags.find((tag) => tag.name === text)
    ) {
      event.preventDefault();
      setTags([...tags, { name: text }]);
    }
  };

  const onTagRemove = (text: string) => {
    setTags(tags.filter((tag) => tag.name !== text));
  };
  const { t } = useTranslation();
  return (
    <>
      <div
        className={classnames(
          styles.tagContainer,
          error && styles.errorTextfield,
        )}
      >
        {tags.map((tag) => (
          <Tag key={tag.name} text={tag.name} remove={onTagRemove} />
        ))}
        <input
          className={classnames(
            styles.tagInput,
            !tags.length && styles.tagFullWidth,
          )}
          type="text"
          onKeyDown={handleOnKeyDown}
          onChange={setText}
          value={enteredText}
          data-testid="tagInput"
          placeholder={t('tags.startTyping')}
        />
      </div>
    </>
  );
};
