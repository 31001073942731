import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { setLeftPanelType } from '../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudInfoLeftPanelHeaderButtons } from './dCloudContainer';
import { ConnectedExpoInfoLeftPanelHeaderButtons } from './expoContainer';

export function InfoLeftPanelHeaderButtons({
  setLeftPanelType,
}: InfoLeftPanelProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoInfoLeftPanelHeaderButtons
        setLeftPanelType={setLeftPanelType}
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudInfoLeftPanelHeaderButtons
      setLeftPanelType={setLeftPanelType}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapDispatchToProps = {
  setLeftPanelType,
};

export type InfoLeftPanelProps = typeof mapDispatchToProps;

export const InfoLeftPanelHeaderButtonsContainer = connect(
  null,
  mapDispatchToProps,
)(InfoLeftPanelHeaderButtons);
