import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';

export type ConfigurationEditTopologyWarningProps = {
  warning: TopologyWarning;
};

export const ConfigurationEditTopologyWarning = ({
  warning: { message, detail },
}: ConfigurationEditTopologyWarningProps): ReactElement => (
  <Alert showIcon colour="warning">
    {`${message} (${detail})`}
  </Alert>
);
