import React, { ReactElement } from 'react';
import { PanelGrid } from '../../../../PanelGrid';
import { buildSessionIntegrationParamsData } from './utils';

type IntegrationPanelParametersProps = {
  parameters: SessionIntegrationParameter[];
};

export function IntegrationPanelParameters({
  parameters,
}: IntegrationPanelParametersProps): ReactElement {
  return (
    <PanelGrid
      data={buildSessionIntegrationParamsData(parameters)}
      wideCol="value"
      wideColRatio={2}
      wrapData={true}
      enableCopy={true}
    />
  );
}
