import React, { ChangeEvent, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox } from '../../../components';
import { determineIfDefaultToParentFieldIsVisible } from '../../utils/workFlowParameterMappingUtil';
import { WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME } from './DefaultToParentFieldConstants';
import styles from './DefaultToParentField.module.css';

export type DefaultToParentFieldProps = {
  mappingName: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultValue: boolean;
  userConfigurable: boolean;
  templateDefaultValue?: string;
  isAdmin: boolean;
};

export const DefaultToParentField = ({
  mappingName,
  onChange,
  defaultValue,
  userConfigurable,
  templateDefaultValue,
  isAdmin,
}: DefaultToParentFieldProps): ReactElement => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  if (
    !determineIfDefaultToParentFieldIsVisible(
      userConfigurable,
      templateDefaultValue,
      isAdmin,
    )
  ) {
    return <></>;
  }

  const checkboxName = `${WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME}-${mappingName}`;

  return (
    <>
      <div>
        <ControlledCheckbox
          id={checkboxName}
          defaultValue={defaultValue}
          control={control}
          label={t('saas.common.fields.defaultToWorkflowEngine.title')}
          customOnChange={onChange}
        />
      </div>
      {!isAdmin && (
        <div className={styles.defaultToParentFieldText}>
          {t('saas.common.fields.defaultToWorkflowEngine.subTitle')}
        </div>
      )}
    </>
  );
};
