import React, { ReactElement } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox } from '../../../../../components';
import { RemoteAccessFormLoader } from '../RemoteAccessFormLoader';
import styles from './RemoteAccessForm.module.css';

export interface RemoteAccessFormProps {
  control: Control<RemoteAccessFormData>;
  isFetching: boolean;
}

enum LabelKeys {
  AnyconnectEnabled = 'anyconnectEnabled',
  EndpointKitEnabled = 'endpointKitEnabled',
}

const translationKey = (key: LabelKeys): string =>
  `remoteAccess.formLabels.${key}`;

export function RemoteAccessForm({
  control,
  isFetching,
}: RemoteAccessFormProps): ReactElement {
  const { t } = useTranslation();

  if (isFetching) {
    return <RemoteAccessFormLoader />;
  }

  return (
    <form className={styles.root}>
      <ControlledCheckbox
        control={control}
        id={LabelKeys.AnyconnectEnabled as string}
        label={t(translationKey(LabelKeys.AnyconnectEnabled))}
      />
      <ControlledCheckbox
        control={control}
        id={LabelKeys.EndpointKitEnabled as string}
        label={t(translationKey(LabelKeys.EndpointKitEnabled))}
      />
    </form>
  );
}
