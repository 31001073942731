import { DOCUMENTATION_PATH, EXPO_ENGAGEMENTS } from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { TAGS } from '../../api/constants';

type ExpoDocumentationArgs = {
  topologyUid: string;
  engagementUid: string;
};

export const expoDocumentationApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getExpoDocumentation: build.query<Documentation, ExpoDocumentationArgs>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DOCUMENTATION],
      query: ({ topologyUid, engagementUid }) => ({
        errorMessage: i18n.t('documentation.fetch.error'),
        method: 'get',
        url: `${EXPO_ENGAGEMENTS}${DOCUMENTATION_PATH}/${topologyUid}?engagementId=${engagementUid}`,
      }),
    }),
  }),
});

export const { useGetExpoDocumentationQuery } = expoDocumentationApi;
