import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { Table } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { format } from 'date-fns';
import { TwoLineTruncatedCell } from '../../../../../entities/topologies/components/TopologiesTable/TwoLineTruncatedCell';
import { ArchitectureLabelGroup } from '../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { SEARCH_MIN_CHARS } from '../../../../EditTopology/configuration/constants';
import { filterItems } from '../../../../EditTopology/configuration/searchUtils';
import { Search } from '../../../../../components/Search';
import { TOPOLOGIES_LAST_UPDATED_FORMAT } from '../../../../../entities/topologies/constants';
import { DemoTemplateTableActions } from './TableActions';

export interface VmTemplatesTableProps {
  loading: boolean;
  demoTemplates: DemoTemplate[];
  setSelectedDemoTemplate: ActionCreatorWithOptionalPayload<
    DemoTemplate | undefined,
    string
  >;
}

export function DemoTemplateTable({
  loading,
  demoTemplates,
  setSelectedDemoTemplate,
}: VmTemplatesTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<DemoTemplate>[]>(
    () => [
      {
        Header: () => t('templateManager.demoTemplates.table.headings.name'),
        accessor: 'name',
        minWidth: 150,
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () =>
          t('templateManager.demoTemplates.table.headings.description'),
        accessor: 'description',
        minWidth: 100,
      },
      {
        Header: () => t('templateManager.demoTemplates.table.headings.demoId'),
        accessor: 'demoId',
        maxWidth: 70,
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () => t('templateManager.demoTemplates.table.headings.contact'),
        accessor: 'contact',
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () => t('templateManager.vmTemplates.table.headings.active'),
        accessor: (row) => (row.userEnabled ? 'Enabled' : 'Disabled'),
        id: 'userEnabled',
      },
      {
        Cell: ({ row }: CellProps<VmTemplate>) =>
          row.original.architectureNames ? (
            <ArchitectureLabelGroup
              architectures={row.original.architectureNames}
            />
          ) : null,
        Header: () =>
          t('templateManager.vmTemplates.table.headings.architecture'),
        accessor: 'architectures',
        minWidth: 200,
      },
      {
        Cell: ({ row }: CellProps<VmTemplate>) =>
          row.original.lastUpdated
            ? format(
                new Date(row.original.lastUpdated),
                TOPOLOGIES_LAST_UPDATED_FORMAT,
              )
            : null,
        Header: () =>
          t('templateManager.vmTemplates.table.headings.lastUpdated'),
        accessor: 'lastUpdated',
        minWidth: 130,
      },
    ],
    [t],
  );

  const [filteredItems, setFilteredItems] =
    useState<DemoTemplate[]>(demoTemplates);
  const [searchValue, setSearchValue] = useState<string>('');
  const filterKeys = [
    'name',
    'description',
    'architectureNames',
    'demoId',
    'description',
    'contact',
    'originalDescription',
    'originalName',
    'lastUpdated',
  ];

  function handleSearchChange(newSearchValue: string): void {
    setSearchValue(newSearchValue);
    if (newSearchValue.length === 0) {
      setFilteredItems(demoTemplates);
    } else if (newSearchValue.length >= SEARCH_MIN_CHARS) {
      const searchText = newSearchValue.toLowerCase();
      const result = filterItems({
        filterKeys,
        list: demoTemplates,
        searchText,
      });
      setFilteredItems(result);
    }
  }

  const renderActions = (demoTemplate: DemoTemplate): ReactNode => (
    <DemoTemplateTableActions
      demoTemplate={demoTemplate}
      onEditClick={setSelectedDemoTemplate}
    />
  );

  return (
    <>
      <div className="row flex-right">
        <Search
          className="base-padding"
          id="demo-template-search"
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder={t('common.search')}
          value={searchValue}
        />
      </div>
      <Table<DemoTemplate>
        actions={renderActions}
        columns={columns}
        loading={loading}
        data={filteredItems}
        initialState={{ pageSize: 15, sortBy: [{ desc: false, id: 'name' }] }}
      />
    </>
  );
}
