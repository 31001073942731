import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { CustomLabel } from '../CustomLabel';
import styles from './CustomLabelGroup.module.css';

export type CustomLabelGroupProps = {
  className?: string;
  labels: CustomLabel[];
  onDismiss: (label: CustomLabel) => void;
};

export const CustomLabelGroup = ({
  className,
  labels,
  onDismiss,
}: CustomLabelGroupProps): ReactElement => {
  return (
    <div
      className={classnames(styles.container, className)}
      data-testid="custom-label-container"
    >
      {labels.map((label) => (
        <CustomLabel
          key={label.uid}
          label={label}
          onDismiss={() => onDismiss(label)}
        />
      ))}
    </div>
  );
};
