import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { InventoryModal } from '../../../../components/InventoryModal';
import { Search } from '../../../../components/Search';
import { useFilter } from '../../../../hooks/use-filter';
import { useSelectLicences } from '../../hooks/use-select-licences';
import { InventoryLicencesTable } from '../InventoryLicencesTable';
import styles from './InventoryLicencesModal.module.css';
import { InventoryLicencesModalProps } from './container';

export function InventoryLicencesModal({
  inventoryLicences = [],
  loading,
  isCreating,
  createLicences,
  onClose,
}: InventoryLicencesModalProps): ReactElement {
  const { uid } = useParams<EditTopologyParams>();
  const { t } = useTranslation();
  const { handleOnSelect, handleQuantityChange, selectedLicences } =
    useSelectLicences(uid);
  const { filteredItems, handleSearchChange, searchValue } = useFilter({
    filterKeys: ['name'],
    items: inventoryLicences,
  });

  return (
    <InventoryModal
      className={styles.root}
      onClose={onClose}
      show={true}
      title={t('licences.add.title')}
    >
      <div className={classnames('container', styles.container)}>
        <div className="row flex-between">
          <PrimaryButton
            className={styles.addBtn}
            loading={isCreating}
            disabled={!selectedLicences.length}
            onClick={() => createLicences(selectedLicences)}
          >
            {t('buttons.add')}
          </PrimaryButton>
          <Search
            className="base-padding"
            id="add-licence-search"
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder={t('common.search')}
            value={searchValue}
          />
        </div>
        <InventoryLicencesTable
          handleOnSelect={handleOnSelect}
          handleQuantityChange={handleQuantityChange}
          inventoryLicences={filteredItems}
          loading={loading}
          selectedLicences={selectedLicences}
        />
      </div>
    </InventoryModal>
  );
}
