import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './TagsInput.module.css';

interface TagProps {
  text: string;
  remove: (tag: string) => void;
  disabled?: boolean;
  className?: string;
}

export default function Tag({
  text,
  remove,
  disabled,
  className,
}: TagProps): ReactElement {
  const handleOnRemove = (event: { stopPropagation: VoidFunction }) => {
    event.stopPropagation();
    remove(text);
  };
  return (
    <span className={classnames(styles.tag, className)}>
      <span data-testid="customTagLabel">{text}</span>
      {!disabled && (
        <button
          type="button"
          onClick={handleOnRemove}
          aria-label={`remove ${text}`}
        >
          <span className="icon-close"></span>
        </button>
      )}
    </span>
  );
}
