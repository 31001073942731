import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridColumn, AgGridReact, AgGridReactProps } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import {
  GridApi,
  ICellRendererParams,
  RowEditingStoppedEvent,
  RowEvent,
  RowNode,
} from 'ag-grid-community';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import classnames from 'classnames';
import styles from './GridEditor.module.css';
import GridActions from './GridActions';
import CellEditor from './CellEditor';

type FrameworkComponents = {
  [key: string]: (props: RowNode) => ReactElement | null;
};

interface GridEditorProps {
  columns: ColDef[];
  data: GridEditorRecord[];
  onGridReady?: (gridApi: GridApi) => void;
  className?: string;
  editingRowIndex?: number | null;
  onRowSaveClick?: (row: RowEditingStoppedEvent) => void;
  onEditClick?: (row: RowEvent) => void;
  onDeleteClick?: (cell: ICellRendererParams) => void;
  onInfoClick?: (cell: ICellRendererParams) => void;
  editAriaLabel?: string;
  deleteAriaLabel?: string;
  updateAriaLabel?: string;
  customComponents?: FrameworkComponents;
  customProps?: AgGridReactProps;
}

export function GridEditor({
  className,
  columns,
  data,
  editingRowIndex,
  onRowSaveClick,
  onEditClick,
  onDeleteClick,
  onInfoClick,
  onGridReady: onGridReadyProp,
  editAriaLabel,
  deleteAriaLabel,
  updateAriaLabel,
  customComponents,
  customProps,
}: GridEditorProps): ReactElement {
  const [gridApi, setGridApi] = useState<GridApi>();
  const gridRef = useRef(null);
  const defaultColDef = useMemo(() => {
    return {
      editable: !!onEditClick,
      resizable: true,
      sortable: true,
    };
  }, [onEditClick]);
  const onGridReady = useCallback(
    (gridData) => {
      gridData.api.setDomLayout('autoHeight');
      setGridApi(gridData.api);
      if (onGridReadyProp) {
        onGridReadyProp(gridData.api);
      }
    },
    [onGridReadyProp],
  );

  const frameworkComponents = {
    cellEditor: CellEditor,
    gridActions: GridActions,
    ...customComponents,
  };
  return (
    <div className={classnames(styles.grid, className)}>
      <AgGridReact
        defaultColDef={defaultColDef}
        editType="fullRow"
        frameworkComponents={frameworkComponents}
        headerHeight={45}
        rowHeight={45}
        icons={{
          sortAscending: '<span class="icon-dropdown"></span>',
          sortDescending:
            '<span class="icon-dropdown icon-dropdown-reverse"></span>',
        }}
        suppressCellSelection={true}
        suppressClickEdit={true}
        suppressRowClickSelection={true}
        onGridReady={onGridReady}
        onRowEditingStopped={onRowSaveClick}
        ref={gridRef}
        rowData={data}
        {...customProps}
      >
        {columns.map((column) => (
          <AgGridColumn
            key={column.field}
            cellEditor={column.cellEditor || 'cellEditor'}
            suppressKeyboardEvent={() => true}
            {...column}
          />
        ))}
        {(!!onEditClick || !!onDeleteClick || !!onInfoClick) && (
          <AgGridColumn
            headerName=""
            field=""
            cellRenderer="gridActions"
            cellRendererParams={{
              deleteAriaLabel,
              editAriaLabel,
              editingRowIndex,
              gridApi,
              onDeleteClick,
              onEditClick,
              onInfoClick,
              updateAriaLabel,
            }}
            editable={false}
            sortable={false}
            suppressNavigable={true}
            width={100}
          />
        )}
      </AgGridReact>
    </div>
  );
}
