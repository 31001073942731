import classNames from 'classnames';
import { Icon, IconNames } from 'dcloud-shared-ui';
import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import styles from './ActionsBarButton.module.css';

export interface ActionBarButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  iconName: IconNames;
  isDisabled?: boolean;
}

export function ActionBarButton({
  title,
  iconName,
  onClick,
  isDisabled = false,
}: ActionBarButtonProps): ReactElement {
  return (
    <div>
      <button
        disabled={isDisabled}
        className={classNames(styles.actionsButton)}
        onClick={onClick}
        aria-label={`${title} button`}
      >
        <Icon name={iconName} className={styles.icon} />
        {title}
      </button>
    </div>
  );
}
