import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../components/Form/ControlledIconPickerButton/ControlledIconPicker.module.css';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { ConfirmModal } from '../../../components';

export function SelectIntegrationIcon(): ReactElement {
  const { t } = useTranslation();
  const [showIconPickerModal, setShowIconPickerModal] =
    useState<boolean>(false);
  return (
    <>
      <div className="dbl-padding-top">
        <div className="half-padding-bottom">
          {t('integrations.forms.common.icon.label')} *
        </div>
        <PrimaryButton
          className={styles.changeIconButton}
          onClick={() => setShowIconPickerModal(true)}
          colour="primary"
        >
          {t(
            'integrations.forms.common.icon.errors.integrationTypeNotSelected.buttonLabel',
          )}
        </PrimaryButton>
      </div>
      <ConfirmModal
        heading={t(
          'integrations.forms.common.icon.errors.integrationTypeNotSelected.modalTitle',
        )}
        clickScreenToClose={true}
        show={showIconPickerModal}
        onCancel={() => setShowIconPickerModal(false)}
        onClose={() => setShowIconPickerModal(false)}
        onConfirm={() => setShowIconPickerModal(false)}
        loading={false}
        subHeading={t(
          'integrations.forms.common.icon.errors.integrationTypeNotSelected.modalMessage',
        )}
        confirmButtonColour="primary"
        confirmButtonLabel={t('buttons.close')}
        showCancel={false}
      />
    </>
  );
}
