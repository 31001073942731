export const DefaultTextNodeDisplay = {
  colorPropertyName: 'textColor',
  defaultFontSizeOption: 3,
  delayInFontSizeUpdates: 800,
  drawingType: 'TEXT' as DiagramDrawingType,
  fontSizeUnit: 'pt',
  fontSizes: [
    8, 9, 10, 10.5, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44, 48,
  ],
  x: 145,
  y: 125,
};
