import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../../redux/store';
import {
  saasSelectors,
  setInventorySaasTemplateToConfigure,
} from '../../../../../redux/configuration/saas/slice';
import {
  useCreateSaasIntegrationMutation,
  useGetSaasIntegrationsQuery,
} from '../../../../../redux/configuration/saas/api';
import { ConfigureIntegrationLoader } from './components/ConfigureIntegrationLoader';
import { SaasConfigure } from '.';

const SaasConfigureContainer = ({
  inventorySaasTemplateToConfigure,
  setInventorySaasTemplateToConfigure,
  integrationType,
}: SaasConfigureContainerProps) => {
  const [
    createSaasIntegration,
    { isSuccess: isCreatingSuccess, isLoading: isCreating },
  ] = useCreateSaasIntegrationMutation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    data: userSaasIntegrations,
    isLoading: isLoadingUserSaasIntegrations,
    isError: isErrorUserSaasIntegrations,
  } = useGetSaasIntegrationsQuery(topologyUid);

  return isLoadingUserSaasIntegrations || isErrorUserSaasIntegrations ? (
    <ConfigureIntegrationLoader integrationType={integrationType} />
  ) : (
    <SaasConfigure
      isCreating={isCreating}
      isCreatingSuccess={isCreatingSuccess}
      setInventorySaasTemplateToConfigure={setInventorySaasTemplateToConfigure}
      createSaasIntegration={createSaasIntegration}
      inventorySaasTemplateToConfigure={inventorySaasTemplateToConfigure}
      userSaasIntegrations={userSaasIntegrations?.saasIntegrations || []}
      integrationType={integrationType}
    />
  );
};

type ConectedSaasConfigureOwnProps = {
  integrationType: IntegrationType;
};
const mapStateToProps = (
  state: RootState,
  ownProps: ConectedSaasConfigureOwnProps,
) => ({
  integrationType: ownProps.integrationType,
  inventorySaasTemplateToConfigure:
    saasSelectors.getInventorySaasTemplateToConfigure(state),
});

const mapDispatchToProps = {
  setInventorySaasTemplateToConfigure,
};

export const ConectedSaasConfigure = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaasConfigureContainer);

type SaasConfigureContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
type SaasConfigureRTKProps = {
  isCreating: boolean;
  isCreatingSuccess: boolean;
  createSaasIntegration: RTKMutation<
    PostSaasIntegrationPayload,
    SaasIntegration
  >;
  userSaasIntegrations: SaasIntegration[];
};

export type SaasConfigureProps = SaasConfigureContainerProps &
  SaasConfigureRTKProps;
