import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../redux/views/session-view/session/api/api';
import { PanelHeaderButtons } from '../index';
import { IntegrationLeftPanelHeaderButtonsProps } from './container';

type ConnectedDCloudIntegrationLeftPanelHeaderButtonsProps =
  IntegrationLeftPanelHeaderButtonsProps & FetchSessionArgs;

export function ConnectedDCloudIntegrationLeftPanelHeaderButtons({
  sessionUid,
  setLeftPanelType,
  versionUid,
}: ConnectedDCloudIntegrationLeftPanelHeaderButtonsProps): ReactElement {
  const { refetch } = sessionApi.endpoints.getSession.useQuerySubscription({
    sessionUid,
    versionUid,
  });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
