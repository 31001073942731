import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getVirtualMachines,
  setShowMoreInfoForVmTemplate,
  setShowRemoteAccessCountExceededModal,
} from '../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../../redux/store';
import {
  useGetAllQuery,
  useGetVMTemplatesInventoryQuery,
} from '../../../../../../../redux/configuration/virtual-machine-entities/api';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { prepTemplateVmsForTable } from '../../../../components/VirtualMachineEntities/Add/components/TemplateTabContent/utils';
import { calculateTopologyRemoteAccessCount } from '../../../../components/VirtualMachineEntities/utils';
import { TemplatesTabContent } from '.';

function TemplatesTabContentContainer({
  setShowMoreInfoForVmTemplate,
  setShowRemoteAccessCountExceededModal,
  isDiagramView,
}: VMTemplatesTabContentContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { isFetching, isLoading, transformedTemplateVms } =
    useGetVMTemplatesInventoryQuery(topologyUid, {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        data,
        isFetching,
        isLoading,
        transformedTemplateVms: data
          ? prepTemplateVmsForTable(data)
          : undefined,
      }),
    });

  const {
    topologyRemoteAccessCount,
    isError: isErrorAllVms,
    isLoading: isLoadingAllVms,
    isFetching: isFetchingAllVms,
  } = useGetAllQuery(topologyUid, {
    selectFromResult: ({ data, isFetching, isLoading, isError }) => ({
      isError,
      isFetching,
      isLoading,
      topologyRemoteAccessCount: calculateTopologyRemoteAccessCount(data),
    }),
  });

  if (
    isFetching ||
    isLoading ||
    !transformedTemplateVms ||
    isLoadingAllVms ||
    isFetchingAllVms ||
    isErrorAllVms
  ) {
    return <TableLoader />;
  }

  return (
    <TemplatesTabContent
      isDiagramView={isDiagramView}
      templateVms={transformedTemplateVms || []}
      isFetching={isFetching || isLoading}
      setShowMoreInfoForVmTemplate={setShowMoreInfoForVmTemplate}
      topologyRemoteAccessCount={topologyRemoteAccessCount}
      setShowRemoteAccessCountExceededModal={
        setShowRemoteAccessCountExceededModal
      }
    />
  );
}

type ConnectedVMTemplatesTabContentOwnProps = {
  isDiagramView?: boolean;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedVMTemplatesTabContentOwnProps,
) => ({
  isDiagramView: ownProps.isDiagramView,
  showMoreInfoForVmTemplate:
    getVirtualMachines(state).showMoreInfoForVmTemplate,
});

const mapDispatchToProps = {
  setShowMoreInfoForVmTemplate,
  setShowRemoteAccessCountExceededModal,
};

type VMTemplatesTabContentContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedVMTemplatesTabContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplatesTabContentContainer);
