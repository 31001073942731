export const determineWillMaxRemoteAccessCountBeExceededByIvm = (
  currentTopologyRemoteAccessCount: number,
  vm: InventoryVirtualMachine,
  max: number,
): boolean =>
  currentTopologyRemoteAccessCount + getInventoryVmRemoteAccessCount(vm) > max;

export const getInventoryVmRemoteAccessCount = (
  inventoryVm: InventoryVirtualMachine,
): number => {
  let count = 0;
  inventoryVm.remoteAccess?.sshEnabled ? count++ : null;
  inventoryVm.remoteAccess?.rdpEnabled ? count++ : null;
  return count;
};
