import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import styles from '../../Integrations/Integrations.module.css';
import { PageHeading } from '../../../components/PageHeading';
import { DefaultLayout } from '../../../layouts/DefaultLayout';
import { useSkeletonColours } from '../../../hooks/use-skeleton-colours';

export default function EditIntegrationSkeleton(): ReactElement {
  const { t } = useTranslation();
  const { backgroundColor, foregroundColor } = useSkeletonColours();
  return (
    <DefaultLayout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <PageHeading
              pageTitle={t('integrations.forms.edit.title')}
              withBackBtn={true}
            />
            <section className="dbl-padding">
              <ContentLoader
                className="margin-top"
                speed={1.5}
                width="100%"
                height={450}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
              >
                <rect x="0" y="0" rx="3" ry="3" width="490" height="50" />
                <rect x="510" y="0" rx="3" ry="3" width="490" height="50" />
                <rect x="0" y="100" rx="3" ry="3" width="1000" height="80" />
                <rect x="0" y="200" rx="3" ry="3" width="1000" height="150" />
              </ContentLoader>
            </section>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
