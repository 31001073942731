import React, { ReactElement } from 'react';
import { Button } from 'dcloud-shared-ui';
import { useHistory } from 'react-router-dom';
import { NotifyPanel } from '../../../components';

export function PageNotFound(): ReactElement {
  const history = useHistory();

  return (
    <NotifyPanel
      callToAction={
        <Button
          className="base-margin-top base-margin-bottom"
          colour="success"
          onClick={() => history.push('/')}
          size="large"
          testId="page-not-found-cta"
        >
          Return to Topology Builder
        </Button>
      }
      heading="Topology Builder"
      subHeading="Sorry, page not found"
    />
  );
}
