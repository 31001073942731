import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedExpoControl } from './expoContainer';
import { ConnectedDCloudControl } from './dCloudContainer';

export function Control({ nodeUid }: ControlProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoControl
        engagementUid={engagementUid}
        nodeUid={nodeUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudControl
      nodeUid={nodeUid}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  nodeUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type ControlProps = ReturnType<typeof mapStateToProps>;

export const ControlContainer = connect(mapStateToProps)(Control);
