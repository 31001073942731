import React, { ReactElement, PropsWithChildren } from 'react';
import { Footer } from 'dcloud-shared-ui';
import { SiteHeader } from '../../components/Headers/SiteHeader';
import { FOOTER_LINKS } from '../../constants';
import styles from './DefaultLayout.module.css';

type DefaultLayoutProps = PropsWithChildren<unknown>;

export function DefaultLayout({ children }: DefaultLayoutProps): ReactElement {
  return (
    <div className={styles.root}>
      <SiteHeader />
      <div className={styles.content}>{children}</div>
      <Footer links={FOOTER_LINKS} />
    </div>
  );
}
