import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useParams, useRouteMatch } from 'react-router-dom';
import { SideTabs } from 'dcloud-shared-ui';
import { routes, ConfigurationRouter } from './ConfigurationRouter';
import { ConfigurationProps } from './container';
import { buildLinks } from './utils';
import styles from './Configuration.module.css';
import { ConfigurationEditWarning } from './components/ConfigurationEditWarning';
import { ConfigurationEditTopologyWarnings } from './components/ConfigurationEditTopologyWarnings';

export function Configuration({
  userRoles,
  topologyToEdit,
}: ConfigurationProps): ReactElement {
  const { uid } = useParams<{ uid: string }>();
  const match = useRouteMatch();

  return (
    <div className={styles.root}>
      <aside className={classnames(styles.sidebar)}>
        <SideTabs tabs={buildLinks(routes, userRoles || [], match, uid)} />
      </aside>
      <main className={styles.content}>
        <ConfigurationEditWarning
          isActiveTemplate={topologyToEdit.activeTemplate || false}
          isDemoPublished={topologyToEdit.demoPublished || false}
        />
        <ConfigurationEditTopologyWarnings warnings={topologyToEdit.warnings} />
        <ConfigurationRouter />
      </main>
    </div>
  );
}
