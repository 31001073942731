import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { RemoteAccessFormLoader } from '../RemoteAccessFormLoader';

export function RemoteAccessLoader(): ReactElement {
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeading
        ctaBtn={
          <PrimaryButton
            testId="remote-access-form-submit-btn"
            type="submit"
            colour="success"
            disabled={true}
          >
            {t('buttons.update')}
          </PrimaryButton>
        }
        pageTitle={t('remoteAccess.name')}
      />
      <RemoteAccessFormLoader />
    </section>
  );
}
