import i18n from '../../../../../i18n';
import { ACTION_LABEL_MAPPINGS } from '../../constants';

export const buildSubHeading = (
  selectedAction?: SessionVmActions | 'RESET',
): string => {
  if (!selectedAction) {
    return i18n.t(
      'sessionsInventory.multiSelectConfirmModal.subHeadingWithoutAction',
    );
  } else {
    const res = `${i18n.t(
      'sessionsInventory.multiSelectConfirmModal.subHeadingWithAction',
    )}${ACTION_LABEL_MAPPINGS[selectedAction]}`;

    return res.replace(/_/g, ' ');
  }
};

export const buildLabel = (actionType?: string): string => {
  switch (actionType) {
    case 'VM_RESET':
      return 'sessionsInventory.virtualMachines.table.actions.reset';
    case 'VM_POWER_OFF':
      return 'sessionsInventory.virtualMachines.table.actions.powerOff';
    case 'VM_POWER_ON':
      return 'sessionsInventory.virtualMachines.table.actions.powerOn';
    case 'GUEST_OS_SHUTDOWN':
      return 'sessionsInventory.virtualMachines.table.actions.guestOsShutdown';
    case 'GUEST_OS_REBOOT':
      return 'sessionsInventory.virtualMachines.table.actions.guestOsReboot';
    case 'RESET':
      return 'sessionsInventory.hardware.table.actions.resetPower';
    default:
      return 'common.somethingWentWrong';
  }
};
