import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { Table as SharedTable } from '../../../../../../components';
import { TableLoader } from '../../../../../../components/Table/TableLoader';
import { TableActions } from './TableActions';

export interface TableProps {
  loading: boolean;
  hardware: Hardware[];
  isError: boolean;
  isLoading: boolean;
  onDeleteClick: (hardware: Hardware) => void;
}

export function Table({
  loading,
  hardware,
  isError,
  isLoading,
  onDeleteClick,
}: TableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<Hardware>[]>(
    () => [
      {
        Header: () => t('hardware.table.headings.name'),
        accessor: 'name',
        minWidth: 150,
      },
      {
        Cell: ({ value }: CellProps<Hardware>) => value.length,
        Header: () => t('hardware.table.headings.interfaces'),
        accessor: 'hardwareNetworkInterfaces',
        width: 25,
      },
      {
        Cell: ({ value }: CellProps<Hardware>) =>
          value ? t('common.yes') : t('common.no'),
        Header: () => t('hardware.table.headings.powerControlEnabled'),
        accessor: 'powerControlEnabled',
        width: 40,
      },
      {
        Cell: ({ value }: CellProps<Hardware>) =>
          value ? t('common.yes') : t('common.no'),
        Header: () => t('hardware.table.headings.hardwareConsoleEnabled'),
        accessor: 'hardwareConsoleEnabled',
        width: 40,
      },
    ],
    [t],
  );

  const renderActions = (hardware: Hardware): ReactNode => (
    <TableActions hardware={hardware} onDeleteClick={onDeleteClick} />
  );

  if (isError || isLoading || !hardware) {
    return <TableLoader />;
  }

  return (
    <SharedTable<Hardware>
      columns={columns}
      loading={loading}
      data={hardware}
      actions={renderActions}
    />
  );
}
