import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import {
  setTopologyToClone,
  setTopologyToDelete,
  setTopologyToSave,
  setTopologyToUpdate,
} from '../../../../redux/topologies/slice';
import { topologiesApi } from '../../../../redux/topologies/api';
import { RootState } from '../../../../redux/store';
import {
  topologiesFiltersSelectors,
  setSearchValue,
  setTopologiesViewType,
  setFilteredToplogies,
  setTopologies,
} from './../../../../redux/topologiesFilters/slice';
import { TopologiesView } from './';

function TopologiesViewContainer({
  onClone,
  onDelete,
  onPush,
  onUpdate,
  filteredTopologies,
  showFilters,
  dataCentersFilters,
  searchValue,
  setSearchValue,
  topologiesViewType,
  setTopologiesViewType,
  isApplyingFilters,
  setFilteredToplogies,
}: TopologiesViewProps): ReactElement {
  topologiesApi.endpoints.getTopologies.useQueryState(undefined);

  return (
    <TopologiesView
      onClone={onClone}
      onDelete={onDelete}
      onPush={onPush}
      onUpdate={onUpdate}
      topologies={filteredTopologies || []}
      filteredTopologies={filteredTopologies}
      showFilters={showFilters}
      dataCentersFilters={dataCentersFilters}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      topologiesViewType={topologiesViewType}
      setTopologiesViewType={setTopologiesViewType}
      isApplyingFilters={isApplyingFilters}
      setFilteredToplogies={setFilteredToplogies}
      setTopologies={setTopologies}
    />
  );
}
export type TopologiesViewProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    topologies?: Topology[];
  };

const mapDispatchToProps = {
  onClone: setTopologyToClone,
  onDelete: setTopologyToDelete,
  onPush: setTopologyToSave,
  onUpdate: setTopologyToUpdate,
  setFilteredToplogies: setFilteredToplogies,
  setSearchValue: setSearchValue,
  setTopologies: setTopologies,
  setTopologiesViewType: setTopologiesViewType,
};

const mapStateToProps = (state: RootState) => ({
  dataCentersFilters: topologiesFiltersSelectors.getDataCentersFilters(state),
  filteredTopologies: topologiesFiltersSelectors.getFilteredTopologies(state),
  isApplyingFilters: topologiesFiltersSelectors.getIsApplyingFilters(state),
  searchValue: topologiesFiltersSelectors.getSearchValue(state),
  showFilters: topologiesFiltersSelectors.getShowFilters(state),
  topologiesViewType: topologiesFiltersSelectors.getTopologiesViewType(state),
});

export const ConnectedTopologiesView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopologiesViewContainer);
