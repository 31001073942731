import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

interface ExternalDnsState {
  externalDnsRecordToDelete?: ExternalDnsRecord;
}

export const initialState: ExternalDnsState = {
  externalDnsRecordToDelete: undefined,
};

const slice = createSlice({
  initialState,
  name: 'externalDns',
  reducers: {
    setExternalDnsToDelete: (
      state,
      action: PayloadAction<ExternalDnsRecord | undefined>,
    ) => {
      state.externalDnsRecordToDelete = action.payload;
    },
  },
});

export const getExternalDns = (state: RootState): ExternalDnsState =>
  state.configuration[slice.name];

export const { reducer: externalDnsReducer } = slice;
export const { setExternalDnsToDelete } = slice.actions;
