import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAccessRoleAssignments,
  setAccessRoleAssignmentToDelete,
} from '../../../../redux/configuration/sharing/slice';
import { RootState } from '../../../../redux/store';
import { useGetAccessRoleAssignmentsQuery } from '../../../../redux/configuration/sharing/api';
import { AccessRoleAssignmentLoader } from './components/AccessRoleAssignmentLoader';
import { AccessRoleAssignments } from '.';

function AccessRoleAssignmentContainer({
  accessRoleAssignmentToDelete,
  setAccessRoleAssignmentToDelete,
}: AccessRoleAssignmentContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isError, isLoading } =
    useGetAccessRoleAssignmentsQuery(topologyUid);

  if (isLoading) {
    return <AccessRoleAssignmentLoader />;
  }

  return (
    <AccessRoleAssignments
      accessRoleAssignments={data?.accessRoleAssignments}
      isError={isError}
      isLoading={isLoading}
      accessRoleAssignmentToDelete={accessRoleAssignmentToDelete}
      setAccessRoleAssignmentToDelete={setAccessRoleAssignmentToDelete}
    />
  );
}

export type AccessRoleAssignmentContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

interface AccessRoleAssignmentRTKProps {
  accessRoleAssignments?: AccessRoleAssignment[];
  isError: boolean;
  isLoading: boolean;
}

export type AccessRoleAssignmentProps = AccessRoleAssignmentContainerProps &
  AccessRoleAssignmentRTKProps;

const mapStateToProps = (state: RootState) => ({
  accessRoleAssignmentToDelete:
    getAccessRoleAssignments(state).accessRoleAssignmentToDelete,
});

const mapDispatchToProps = {
  setAccessRoleAssignmentToDelete,
};

export const ConnectedAccessRoleAssignment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccessRoleAssignmentContainer);
