import { AnyAction } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';
import {
  sessionInventorySelectors,
  startGetAllVmStatuses,
} from '../../../../../../../redux/views/session-view/session-inventory/slice';
import { GetVmStatusButton } from '.';

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => ({
  startGetAllVmStatuses: (vms: SessionInventoryVirtualMachineData[]) =>
    dispatch(startGetAllVmStatuses(vms)),
});

type GetVmStatusButtonContainerOwnProps = {
  virtualMachines: SessionInventoryVirtualMachineData[];
};

const mapStateToProps = (
  state: RootState,
  ownProps: GetVmStatusButtonContainerOwnProps,
) => ({
  hasCalledVmStatusApiCalls:
    sessionInventorySelectors.getHasCalledVmStatusesAPICalls(state),
  isCallingVmStatusAPICalls:
    sessionInventorySelectors.getIsCallingVmStatusesAPICalls(state),
  isShowingMultiActionProgressBar:
    sessionInventorySelectors.getShowMultiActionProgressBar(state),
  virtualMachines: ownProps.virtualMachines,
});

export type GetVmStatusButtonProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const GetVmStatusButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GetVmStatusButton);
