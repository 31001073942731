export const NIC_FIXTURE = {
  ipAddress: '198.18.133.111',
  macAddress: '00:50:56:00:00:03',
  name: 'Network adapter 1',
  type: 'VIRTUAL_E1000',
  uid: 'lonvhw1nic1',
};

/* eslint-disable sort-keys */
export const NETWORKS_RESPONSE_29LIMIT_28NICS = {
  _embedded: {
    networks: [
      {
        uid: 'lontopologyv1nwk',
        name: 'Default Network',
        description: 'Default Network description',
        inventoryNetwork: {
          id: 'VLAN-PRIMARY',
          type: 'ROUTED',
          subnet: '198.18.128.0 /18',
        },
        vmConnectionInfo: {
          maxConnections: 29,
          currentConnections: Array(28).fill(NIC_FIXTURE),
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
        },
      },
      {
        uid: 'routednetwork',
        name: 'Routed Network',
        description: 'Routed Network description',
        inventoryNetwork: {
          id: 'L3-VLAN-1',
          type: 'ROUTED',
          subnet: '198.18.1.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 29,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/routednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/routednetwork',
          },
        },
      },
      {
        uid: 'unroutednetwork',
        name: 'Unrouted Network',
        description: 'Unrouted Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/unroutednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/unroutednetwork',
          },
        },
      },
      {
        uid: 'notfound',
        name: 'Not found Network',
        description: 'Not found Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/notfound',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/notfound',
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/networks',
    },
  },
};

export const NETWORKS_RESPONSE_30LIMIT_29NICS = {
  _embedded: {
    networks: [
      {
        uid: 'lontopologyv1nwk',
        name: 'Default Network',
        description: 'Default Network description',
        inventoryNetwork: {
          id: 'VLAN-PRIMARY',
          type: 'ROUTED',
          subnet: '198.18.128.0 /18',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: Array(29).fill(NIC_FIXTURE),
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
        },
      },
      {
        uid: 'routednetwork',
        name: 'Routed Network',
        description: 'Routed Network description',
        inventoryNetwork: {
          id: 'L3-VLAN-1',
          type: 'ROUTED',
          subnet: '198.18.1.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 29,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/routednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/routednetwork',
          },
        },
      },
      {
        uid: 'unroutednetwork',
        name: 'Unrouted Network',
        description: 'Unrouted Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/unroutednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/unroutednetwork',
          },
        },
      },
      {
        uid: 'notfound',
        name: 'Not found Network',
        description: 'Not found Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/notfound',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/notfound',
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/networks',
    },
  },
};

export const NETWORKS_RESPONSE_30LIMIT_30NICS = {
  _embedded: {
    networks: [
      {
        uid: 'lontopologyv1nwk',
        name: 'Default Network',
        description: 'Default Network description',
        inventoryNetwork: {
          id: 'VLAN-PRIMARY',
          type: 'ROUTED',
          subnet: '198.18.128.0 /18',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: Array(30).fill(NIC_FIXTURE),
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
        },
      },
      {
        uid: 'routednetwork',
        name: 'Routed Network',
        description: 'Routed Network description',
        inventoryNetwork: {
          id: 'L3-VLAN-1',
          type: 'ROUTED',
          subnet: '198.18.1.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 29,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/routednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/routednetwork',
          },
        },
      },
      {
        uid: 'unroutednetwork',
        name: 'Unrouted Network',
        description: 'Unrouted Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/unroutednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/unroutednetwork',
          },
        },
      },
      {
        uid: 'notfound',
        name: 'Not found Network',
        description: 'Not found Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/notfound',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/notfound',
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/networks',
    },
  },
};

export const NETWORKS_RESPONSE_30LIMIT_30NICS_2NETWORKS = {
  _embedded: {
    networks: [
      {
        uid: 'lontopologyv1nwk',
        name: 'Default Network',
        description: 'Default Network description',
        inventoryNetwork: {
          id: 'VLAN-PRIMARY',
          type: 'ROUTED',
          subnet: '198.18.128.0 /18',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: Array(30).fill(NIC_FIXTURE),
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/lontopologyv1nwk',
          },
        },
      },
      {
        uid: 'routednetwork',
        name: 'Routed Network',
        description: 'Routed Network description',
        inventoryNetwork: {
          id: 'L3-VLAN-1',
          type: 'ROUTED',
          subnet: '198.18.1.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: Array(30).fill(NIC_FIXTURE),
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/routednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/routednetwork',
          },
        },
      },
      {
        uid: 'unroutednetwork',
        name: 'Unrouted Network',
        description: 'Unrouted Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/unroutednetwork',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/unroutednetwork',
          },
        },
      },
      {
        uid: 'notfound',
        name: 'Not found Network',
        description: 'Not found Network description',
        inventoryNetwork: {
          id: 'L2-VLAN-1',
          type: 'UNROUTED',
          subnet: '198.18.5.0 /24',
        },
        vmConnectionInfo: {
          maxConnections: 30,
          currentConnections: [],
        },
        topology: {
          uid: 'lontopology',
        },
        _links: {
          self: {
            href: 'http://localhost/networks/notfound',
          },
          topology: {
            href: 'http://localhost/topologies/lontopology',
          },
          delete: {
            href: 'http://localhost/networks/notfound',
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/networks',
    },
  },
};
