import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from './TableActions.module.css';

interface ExternalDnsTableActionsProps {
  externalDnsRecord: ExternalDnsRecord;
  onDeleteClick: (externalDnsRecord: ExternalDnsRecord) => void;
}

export function ExternalDnsTableActions({
  externalDnsRecord,
  onDeleteClick,
}: ExternalDnsTableActionsProps): ReactElement {
  const { url } = useRouteMatch();

  return (
    <ul aria-label="Card actions" className={styles.root}>
      <li className={styles.action}>
        <Link
          to={`${url}/${externalDnsRecord.uid}`}
          className={styles.linkAction}
          data-test="external-dns-table-actions-edit"
        >
          <Icon name="edit" />
        </Link>
      </li>
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={() => onDeleteClick(externalDnsRecord)}
          data-test="external-dns-table-actions-delete"
        >
          <Icon name="delete" />
        </Button>
      </li>
    </ul>
  );
}
