import classNames from 'classnames';
import { IconNames } from 'dcloud-shared-ui';
import styles from './TopologyLimitsToolBarItem.module.css';

export const determineTopologyLimitsToolBarItemLabel = (
  { current, limit }: TopologyLimit,
  label: TopologyLimitsDisplayLabels,
): string => {
  if (label === 'Memory (GB)')
    return `${label}: ${Math.ceil(current / 1024)}/${Math.ceil(limit / 1024)}`;
  return `${label}: ${current}/${limit}`;
};

export const determineTopologyLimitsToolBarItemIcon = (
  approvedLimitExceeded: boolean,
): IconNames => {
  return approvedLimitExceeded ? 'info-outline' : 'check-outline';
};

export const determineTopologyLimitsToolBarItemIconStyles = (
  approvedLimitExceeded: boolean,
): string => {
  return classNames(
    styles.icon,
    { [styles.iconWarning]: approvedLimitExceeded },
    { [styles.iconSuccess]: !approvedLimitExceeded },
  );
};

export const determineRootStyles = (approvedLimitExceeded: boolean): string => {
  return classNames(
    styles.root,
    { [styles.cursorPointer]: approvedLimitExceeded },
    { [styles.cursorDisabled]: !approvedLimitExceeded },
  );
};
