import {
  base64URLencode,
  generateRandomString,
  sha256,
} from '../utils/helpers';
import { clearUserPrefs } from '../utils/userPref';
import { MainRoutes } from '../constants/routes';
import { BASE_URL, config, OauthQueryParams } from '.';

export const replaceURLWithIntendedURL = (URL: string): void =>
  window.location.replace(URL);

export const buildSSOFullAuthURL = (
  redirectUri: string,
  codeChallenge: string,
  state: string,
): string => {
  const query = new URLSearchParams();
  query.append('client_id', config.SSO.CLIENT_ID);
  query.append('response_type', config.SSO.RESPONSE_TYPE);
  query.append('scope', config.SSO.SCOPE);
  query.append('code_challenge', codeChallenge);
  query.append('code_challenge_method', config.SSO.CODE_CHALLENGE_METHOD);
  query.append('redirect_uri', redirectUri);
  query.append('state', state);
  const params = query.toString();
  return `${config.SSO.FULL_AUTH_URL}?${params}`;
};

export const clearCodeVerifier = (): void =>
  window.localStorage.removeItem(config.SSO.CODE_VERIFIER_NAME);

export const generateCodeChallenge = async (
  verifier: string,
): Promise<string> => {
  const digest = await sha256(verifier);
  return base64URLencode(digest);
};

export const generateCodeVerifier = (): string => generateRandomString();

export const generateStateParam = (): string => generateRandomString();

export const removeToken = (tokenName: string): void =>
  window.localStorage.removeItem(tokenName);

export const removeTokens = (): void => {
  removeToken(config.SSO.ACCESS_TOKEN_NAME);
  removeToken(config.SSO.REFRESH_TOKEN_NAME);
  clearUserPrefs();
};

export const getAccessToken = (): string | null =>
  window.localStorage.getItem(config.SSO.ACCESS_TOKEN_NAME);

export const getAndRemoveSessionItem = (key: string): string | null => {
  const value = getSessionItem(key);
  removeSessionItem(key);
  return value;
};

export const getCodeVerifier = (): string =>
  window.localStorage.getItem(config.SSO.CODE_VERIFIER_NAME) ?? '';

export const getCurrentURL = (): string => window.location.toString();

export const getIdPRedirectUri = (): string => {
  const redirectUrl = new URL(window.location.origin);
  redirectUrl.pathname = `${BASE_URL}${MainRoutes.IDP_CALLBACK}`;
  return redirectUrl.toString();
};

export const getRefreshToken = (): string | null =>
  window.localStorage.getItem(config.SSO.REFRESH_TOKEN_NAME);

export const getSearchParamFromURL = (param: OauthQueryParams): string => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param) ?? '';
};

export const getSessionItem = (key: string): string | null =>
  window.sessionStorage.getItem(key);

export const redirectTo = (URL: string): void => window.location.assign(URL);

export const removeSessionItem = (key: string): void =>
  window.sessionStorage.removeItem(key);

export const storeTokens = (
  accessToken: string,
  refreshToken: string,
): void => {
  window.localStorage.setItem(config.SSO.ACCESS_TOKEN_NAME, accessToken);
  window.localStorage.setItem(config.SSO.REFRESH_TOKEN_NAME, refreshToken);
  clearUserPrefs();
};

export const storeSessionItem = (key: string, value: string): void =>
  window.sessionStorage.setItem(key, value);

export const storeCodeVerifier = (verifier: string): void =>
  window.localStorage.setItem(config.SSO.CODE_VERIFIER_NAME, verifier);
