import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../redux/views/session-view/session/api/api';
import { getIconDataFromNodeDetails } from './utils/utils';
import { IconInfoContainerProps } from './container';
import { IconInfo } from '.';

type ConnectedDCloudIconInfoProps = IconInfoContainerProps & FetchSessionArgs;

export function ConnectedDCloudIconInfo({
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedDCloudIconInfoProps): ReactElement {
  const data = sessionApi.endpoints.getSession.useQueryState(
    { sessionUid, versionUid },
    {
      selectFromResult: ({ data }) =>
        getIconDataFromNodeDetails(
          data?.diagram.nodes.filter(
            (node) => node.uid === nodeUid,
          )[0] as SessionIconNode,
        ),
    },
  );

  return <IconInfo data={data.keyValuePairs} title={data.title} />;
}
