import React, { ReactElement } from 'react';
import { IntegrationPanelActionButton } from './IntegrationPanelActionButton';

type IntegrationPanelActionButtonsProps = {
  actions: SessionIntegrationAction[];
};

export function IntegrationPanelActionButtons({
  actions,
}: IntegrationPanelActionButtonsProps): ReactElement {
  return (
    <>
      {actions.map((action) => (
        <IntegrationPanelActionButton key={action.title} action={action} />
      ))}
    </>
  );
}
