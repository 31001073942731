import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import styles from './CustomIconPicker.module.css';
import { CustomIcon } from './components/CustomIcon/CustomIcon';

export interface IconPickerProps {
  selectedIcon: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleUpdate: (selection: string) => void;
  icons: Array<ReactElement>;
}

export function CustomIconPicker({
  setShowModal,
  selectedIcon,
  handleUpdate,
  icons,
}: IconPickerProps): ReactElement {
  const { t } = useTranslation();
  const [pickerSelectedIcon, setPickerSelectedIcon] = useState<string>(
    selectedIcon || '',
  );

  return (
    <div className={styles.container}>
      <div className={styles.gallery}>
        {icons.map((icon) => (
          <CustomIcon
            key={icon.key}
            isSelected={icon.key === pickerSelectedIcon}
            title={icon.key?.toString()}
            onSelect={setPickerSelectedIcon}
          >
            {icon}
          </CustomIcon>
        ))}
      </div>
      <div className="flex flex-center dbl-margin-top">
        <Button colour="ghost" onClick={() => setShowModal(false)}>
          {t('buttons.cancel')}
        </Button>
        <PrimaryButton
          onClick={() => handleUpdate(pickerSelectedIcon)}
          disabled={selectedIcon === pickerSelectedIcon}
        >
          {t('buttons.use')}
        </PrimaryButton>
      </div>
    </div>
  );
}
