import React, { ReactElement, useRef } from 'react';
import ContentLoader from 'react-content-loader';
import { Node } from '../../../Node';
import { useSkeletonColours } from '../../../../../../hooks/use-skeleton-colours';
import styles from './DiagramBoxSkeletonLoader.module.css';
import { usePosition } from './hooks/use-position';

export function DiagramBoxSkeletonLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  const nodeRef = useRef<HTMLDivElement>(null);
  usePosition({
    nodeRef,
    uid: 'BOX_SKELETON_LOADER',
  });
  return (
    <Node
      ref={nodeRef}
      uid="BOX_SKELETON_LOADER"
      isReadOnly={true}
      className={styles.skeletonLoader}
    >
      <ContentLoader
        speed={0.7}
        width={120}
        height={120}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" width="120" height="120" />
      </ContentLoader>
    </Node>
  );
}
