import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { ControlledTextfieldWithDescription } from '../../../../../../../../../components';
import { DefaultToParentField } from '../../../../../../../../components/DefaultToParentField/DefaultToParentField';
import {
  determineUserWorkflowParameterDefaultValue,
  determineDefaultToTemplateValue,
  determineIfInputFieldIsDisabled,
  determineInputFieldValue,
} from '../../../../../../../../utils/workFlowParameterMappingUtil';
import styles from './WorkFlowParameterMapping.module.css';

export type WorkFlowParameterMappingProps = {
  input: SaasTemplateInput;
  userInputs?: SaasIntegrationInput[];
  isEditing?: boolean;
};

export const WorkFlowParameterMapping = ({
  input: {
    fieldName,
    description,
    userConfigurable,
    mappingName,
    defaultValue: templateDefaultValue = '',
  },
  userInputs,
  isEditing,
}: WorkFlowParameterMappingProps): ReactElement => {
  const { control, errors } = useFormContext();
  const userInput = userInputs?.find(
    (input) => input.mappingName === mappingName,
  );
  const userInputValue = userInput?.value || '';
  const defaultToTemplate = determineDefaultToTemplateValue(
    !!userInput?.defaultToTemplate,
    templateDefaultValue,
    !!isEditing,
  );
  const [isInputFieldDisabled, setIsInputFieldDisabled] = useState(true);
  const defaultValue = determineUserWorkflowParameterDefaultValue({
    defaultToTemplate,
    isEditing,
    templateDefaultValue,
    userConfigurable,
    userInputValue: userInput?.value,
  });
  const enableOrDisableInputField = useCallback(
    (defaultToTemplate: boolean) => {
      setIsInputFieldDisabled(
        determineIfInputFieldIsDisabled(userConfigurable, defaultToTemplate),
      );
    },
    [userConfigurable],
  );
  const onDefaultToParentFieldChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    control.setValue(
      mappingName,
      determineInputFieldValue(
        userInputValue,
        templateDefaultValue,
        event.target.checked,
      ),
    );
    enableOrDisableInputField(event.target.checked);
    control.trigger(mappingName);
  };

  useEffect(() => {
    const initialiseInputValue = () => {
      control.setValue(mappingName, defaultValue);
    };
    initialiseInputValue();
    enableOrDisableInputField(defaultToTemplate);
  }, [
    control,
    defaultToTemplate,
    defaultValue,
    enableOrDisableInputField,
    mappingName,
  ]);
  return (
    <div className="row">
      <div className="col-6">
        <ControlledTextfieldWithDescription
          testId={`param-input-${mappingName}`}
          key={mappingName}
          disabled={isInputFieldDisabled}
          control={control}
          label={fieldName}
          description={description}
          descriptionClass={styles.worklowparamDescription}
          name={mappingName}
          error={errors?.[mappingName]?.message}
          required={true}
          className="base-padding-top base-padding-bottom"
        />
      </div>
      <div className={classnames('col-4', styles.defaultToTemplateCheckbox)}>
        <DefaultToParentField
          mappingName={mappingName}
          defaultValue={defaultToTemplate}
          onChange={onDefaultToParentFieldChange}
          userConfigurable={userConfigurable}
          templateDefaultValue={templateDefaultValue}
          isAdmin={false}
        />
      </div>
    </div>
  );
};
