import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useSkeletonColours } from '../../../../../hooks/use-skeleton-colours';

export function CreateIntegrationModalSkeleton(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();
  return (
    <section className="dbl-padding">
      <ContentLoader
        className="margin-top"
        speed={1.5}
        width="100%"
        height={150}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" rx="3" ry="3" width="620" height="40" />
        <rect x="0" y="80" rx="3" ry="3" width="620" height="40" />
      </ContentLoader>
    </section>
  );
}
