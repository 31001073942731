import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetInventoryTemplateTopologiesQuery } from '../../../../../../../redux/configuration/virtual-machine-entities/api';
import {
  getVirtualMachines,
  setSelectedTopologyUid,
  setShowMoreInfoForInventoryTopology,
  virtualMachinesSelectors,
} from '../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { RootState } from '../../../../../../../redux/store';
import { prepInventoryTopologiesForTable } from './utils';
import { DemosTabContent } from '.';

export function DemosTabContentContainer({
  setSelectedTopologyUid,
  setShowMoreInfoForInventoryTopology,
  isDiagramView = false,
  selectedTopologyUid,
}: DemosTabContentContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { transformedInventoryTopologies, isLoading, isFetching, isError } =
    useGetInventoryTemplateTopologiesQuery(topologyUid, {
      selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
        data,
        isError,
        isFetching,
        isLoading,
        transformedInventoryTopologies: data
          ? prepInventoryTopologiesForTable(data)
          : undefined,
      }),
    });

  if (!transformedInventoryTopologies || isLoading || isFetching || isError) {
    return <TableLoader />;
  }

  return (
    <DemosTabContent
      selectedTopologyUid={selectedTopologyUid}
      isDiagramView={isDiagramView}
      inventoryTopologies={transformedInventoryTopologies || []}
      isFetching={isFetching || isLoading || isError}
      setSelectedTopologyUid={setSelectedTopologyUid}
      setShowMoreInfoForInventoryTopology={setShowMoreInfoForInventoryTopology}
    />
  );
}

type ConnectedDemosTabContentOwnProps = {
  isDiagramView?: boolean;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedDemosTabContentOwnProps,
) => ({
  isDiagramView: ownProps.isDiagramView,
  selectedTopologyUid: virtualMachinesSelectors.getSelectedTopologyUid(state),
  showMoreInfoForInventoryTopology:
    getVirtualMachines(state).showMoreInfoForInventoryTopology,
});

const mapDispatchToProps = {
  setSelectedTopologyUid,
  setShowMoreInfoForInventoryTopology,
};

type DemosTabContentContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedDemosTabContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DemosTabContentContainer);
