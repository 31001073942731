export const getNetworkInventoryIdsByType = (
  networks: Network[],
  type: 'UNROUTED' | 'ROUTED',
): string[] =>
  networks
    .filter((network) => network.inventoryNetwork.type === type)
    .map((network) => network.inventoryNetwork.id);

export const getNetworkNames = (networks: Network[]): string[] =>
  networks.map((network) => network.name);

export const getIsLastRouted = (networks: Network[]): boolean =>
  networks.filter((network) => network.inventoryNetwork.type === 'ROUTED')
    .length === 1;

export const getAvailableInventoryNetworksByType = (
  usedInventoryNetworkIds: string[],
  type: 'UNROUTED' | 'ROUTED',
  allInventoryNetworks: InventoryNetwork[],
): InventoryNetwork[] =>
  allInventoryNetworks.filter(
    (inventoryNetwork) =>
      inventoryNetwork.type === type &&
      !usedInventoryNetworkIds.includes(inventoryNetwork.id),
  );

export const getMaxNumNetworksReached = (
  availableRouted: InventoryNetwork[],
  availableUnrouted: InventoryNetwork[],
): boolean => availableUnrouted.length + availableRouted.length === 0;
