import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../redux/views/session-view/session/api/api';
import { buildIpAddressesForSessionInventoryVmData } from '../../../../../../SessionInventory/components/SessionInventoryVirtualMachines/utils';
import { IpAddressTableProps } from './container';
import { IpAddressesTable } from '.';

type ConnectedDCloudIpAddressTableProps = IpAddressTableProps &
  FetchSessionArgs;

export function ConnectedDCloudIpAddressTable({
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedDCloudIpAddressTableProps): ReactElement {
  const { data: ipAddresses } = sessionApi.endpoints.getSession.useQueryState(
    { sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        data: buildIpAddressesForSessionInventoryVmData(
          data?.diagram.nodes.filter(
            (node) => node.uid === nodeUid,
          )[0] as DiagramNodeVM,
        ),
      }),
    },
  );

  return <IpAddressesTable ipAddresses={ipAddresses} />;
}
