import { IconButton } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Actions } from '../../../../../../components/Actions';

interface NetworkTableActionsProps {
  onDeleteClick: VoidFunction;
}

export function NetworkTableActions({
  onDeleteClick,
}: NetworkTableActionsProps): ReactElement {
  return (
    <Actions>
      <IconButton
        icon="trash"
        type="button"
        colour="link"
        onClick={onDeleteClick}
      />
    </Actions>
  );
}
