import React, { ReactElement } from 'react';
import classnames from 'classnames';
import {
  Table as SharedUiTable,
  TableProps as TableComponentProps,
} from 'dcloud-shared-ui';
import { TableLoader } from './TableLoader';
import styles from './Table.module.css';

export interface TableProps<T extends Record<string, unknown>>
  extends TableComponentProps<T> {
  loading?: boolean;
}

export function Table<T extends Record<string, unknown>>({
  className,
  loading = false,
  ...props
}: TableProps<T>): ReactElement {
  if (loading) {
    return <TableLoader />;
  }

  return (
    <SharedUiTable {...props} className={classnames(styles.root, className)} />
  );
}
