import {
  NAT_RULE_TARGET_TYPES,
  NAT_RULE_TYPES,
} from '../../../../../../../../constants/nat-rules';

export const willNatRulesLimitBeExceeded = ({
  current,
  limit,
}: TopologyLimit): boolean => {
  return current + 1 > limit;
};

export const determineNatRuleTargetTypeFromNatRuleType = (
  natRuleType: NatRuleType | '' | undefined,
): NatRuleTargetType | undefined => {
  if (natRuleType === '' || natRuleType === undefined) {
    return undefined;
  } else if (
    [NAT_RULE_TYPES.internalIp, NAT_RULE_TYPES.publicIp].includes(natRuleType)
  ) {
    return NAT_RULE_TARGET_TYPES.ip;
  } else if (
    [NAT_RULE_TYPES.internalVmNic, NAT_RULE_TYPES.publicVmNic].includes(
      natRuleType,
    )
  ) {
    return NAT_RULE_TARGET_TYPES.vmNic;
  }
};
