import React, { ReactElement } from 'react';
import { Checkbox } from 'dcloud-shared-ui';

export type FilterItemProps = {
  label: string;
  isChecked: boolean;
  onChange: VoidFunction;
};
export const FilterItem = ({
  label,
  isChecked,
  onChange,
}: FilterItemProps): ReactElement => {
  return (
    <span className="half-margin-top">
      <Checkbox
        data-testid={`topologies-filter-${label}`}
        label={label}
        checked={isChecked}
        onChange={onChange}
      />
    </span>
  );
};
