import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setShowSaveAndEndSessionModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { SaveAndEndSessionModal } from '.';

const mapStateToProps = (state: RootState) => ({
  showSaveAndEndSessionModal:
    sessionViewSelectors.getShowSaveAndEndSessionModal(state),
});

const mapDispatchToProps = {
  setShowSaveAndEndSessionModal,
};

export type SaveAndEndSessionModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const SaveAndEndSessionModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveAndEndSessionModal);
