import React, { ReactElement } from 'react';
import { ConnectedActionBarTimer } from '../ActionsBarTimer/container';

export function ActionsBarRight(): ReactElement {
  return (
    <div className="header-panel header-panel--right">
      <div className="header-item">
        <ConnectedActionBarTimer />
      </div>
    </div>
  );
}
