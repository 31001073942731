import React, { ReactElement, Suspense } from 'react';
import { CenteredSpinner } from '../../../components';

const Diagram = React.lazy(() => import('./container'));

export function CodeSplittedDiagram(): ReactElement {
  return (
    <div>
      <Suspense fallback={<CenteredSpinner size="small" />}>
        <Diagram />
      </Suspense>
    </div>
  );
}
