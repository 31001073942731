import React, { ReactElement } from 'react';
import { Card, Image } from 'dcloud-shared-ui';
import { Link } from 'react-router-dom';
import styles from './LinkImageCard.module.css';

export interface LinkImageCardProps {
  imageAltText: string;
  imageSrc: string;
  onClick?: VoidFunction;
  path: string;
  subTitle: string;
  testId?: string;
  title: string;
}

export function LinkImageCard({
  imageAltText,
  imageSrc,
  onClick,
  path,
  subTitle,
  testId,
  title,
}: LinkImageCardProps): ReactElement {
  return (
    <Card className="text-center dbl-padding" testId={testId}>
      <Link to={path} className={styles.link} onClick={onClick}>
        <Image src={imageSrc} alt={imageAltText} className={styles.image} />
        <h3 className="text-weight-700 no-margin-bottom base-margin-top">
          {title}
        </h3>
        <h4 className="half-margin-top no-margin-bottom text-size-16 text-weight-200">
          {subTitle}
        </h4>
      </Link>
    </Card>
  );
}
