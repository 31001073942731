import { SelectOption } from 'dcloud-shared-ui';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import formStyles from '../../../EditFormStyles.module.css';
import { ControlledSelect } from '../../../../../../components';
import { FORM_SECTION_CLASSES } from '../../../components/VirtualMachineEntities/constants';

interface FormTemplateConfigProps {
  isLoading: boolean;
  templateOptions: SelectOption<string>[];
}

export function FormTemplateConfig({
  isLoading,
  templateOptions,
}: FormTemplateConfigProps): ReactElement {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className={classNames('row', formStyles.sectionPadding)}>
      <div className={FORM_SECTION_CLASSES}>
        <ControlledSelect
          control={control}
          label={t('hardware.edit.titles.templates')}
          horizontal={false}
          options={templateOptions}
          name="inventoryTemplateConfigScript.uid"
          loading={isLoading}
        />
      </div>
    </div>
  );
}
