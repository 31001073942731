import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import styles from '../../CarouselWrapper.module.css';

type HeroIndicatorButtonProps = {
  activeIndex: number;
  index: number;
  updateIndex: (newIndex: number) => void;
};

export const HeroIndicatorButton = ({
  activeIndex,
  index,
  updateIndex,
}: HeroIndicatorButtonProps): ReactElement => {
  return (
    <button
      className={styles.indicatorButtons}
      onClick={() => {
        updateIndex(index);
      }}
      key={index}
    >
      <Icon
        className={`${
          index === activeIndex
            ? styles.indicatorSymbolActive
            : styles.indicatorSymbol
        }`}
        name={'circle'}
        testId="indicator-icon"
      />
    </button>
  );
};
