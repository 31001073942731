import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { buildIpAddressesForSessionInventoryVmData } from '../../../../../../SessionInventory/components/SessionInventoryVirtualMachines/utils';
import { IpAddressTableProps } from './container';
import { IpAddressesTable } from '.';

type ConnectedExpoIpAddressTableProps = IpAddressTableProps &
  FetchExpoSessionArgs;

export function ConnectedExpoIpAddressTable({
  engagementUid,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedExpoIpAddressTableProps): ReactElement {
  const { data: ipAddresses } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          data: buildIpAddressesForSessionInventoryVmData(
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as DiagramNodeVM,
          ),
        }),
      },
    );

  return <IpAddressesTable ipAddresses={ipAddresses} />;
}
