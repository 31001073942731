import React, { ReactElement } from 'react';
import { DemoTypeItem } from '../constants';
import { LinkImageCard } from '../../../../../components/Cards/LinkImageCard';

export interface DemoTypeTileProps {
  demoType: DemoTypeItem;
  setDemoType: (demoType: string) => void;
}

export function DemoTypeTile({
  demoType,
  setDemoType,
}: DemoTypeTileProps): ReactElement {
  return (
    <div className="col-md-3">
      <LinkImageCard
        path={demoType.path}
        imageAltText={demoType.title}
        imageSrc={demoType.icon}
        title={demoType.title}
        subTitle={demoType.subTitle}
        testId={`demo-type-${demoType.type.toLowerCase()}`}
        onClick={() => setDemoType(demoType.type)}
      />
    </div>
  );
}
