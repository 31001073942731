import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextarea,
  ControlledTextfield,
  Modal,
} from '../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import { MaxValues } from '../../../../../../../../validation';
import { IconPicker } from '../../../../../../../../components/IconPicker';
import { createOsOptions } from '../../utils';
import { CpuInput } from '../CpuInput';
import { MemoryInput } from '../MemoryInput';
import formStyles from '../../../../../EditFormStyles.module.css';
import { FORM_SECTION_CLASSES } from '../../../constants';
import { getSelectedIcon } from './utils';
import styles from './GeneralForm.module.css';

export interface GeneralFormInterface {
  operatingSystems: OperatingSystem[];
  nodeData?: DiagramNode;
}

export function GeneralForm({
  operatingSystems,
  nodeData,
}: GeneralFormInterface): ReactElement {
  const { control, errors, watch } = useFormContext();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [largeVmWarning, setLargeVmWarning] = useState<string>('');
  const [selectedIcon, setSelectedIcon] = useState<string>(
    nodeData?.display?.icon || '',
  );

  if (control.getValues().icon !== selectedIcon) {
    setSelectedIcon(control.getValues().icon);
  }

  const handleUpdateIcon = async (newSelection: string) => {
    control.setValue('icon', newSelection, {
      shouldDirty: true,
    });
    setShowModal(false);
  };

  const watchMemoryGb = watch('memoryGb');
  const watchCpuQty = watch('cpuQty');

  const checkLargeVmValues = useCallback(() => {
    if (
      watchMemoryGb > MaxValues.VirtualMachineMemoryGbRecommended &&
      watchCpuQty > MaxValues.VirtualMachineCpuQtyRecommended
    ) {
      setLargeVmWarning(t('virtualMachines.edit.memoryAndCpu'));
    } else if (watchMemoryGb > MaxValues.VirtualMachineMemoryGbRecommended) {
      setLargeVmWarning(t('virtualMachines.edit.memoryMb'));
    } else if (watchCpuQty > MaxValues.VirtualMachineCpuQtyRecommended) {
      setLargeVmWarning(t('virtualMachines.edit.cpuQty'));
    } else {
      setLargeVmWarning('');
    }
  }, [t, watchCpuQty, watchMemoryGb]);

  useEffect(() => {
    checkLargeVmValues();
  }, [checkLargeVmValues]);

  return (
    <>
      {largeVmWarning.length > 0 && (
        <Alert data-testid="large-vm-warning" colour="warning">
          {t('virtualMachines.edit.largeVmWarningPt1')} {largeVmWarning}
          {t('virtualMachines.edit.largeVmWarningPt2')}
        </Alert>
      )}
      <div className={classnames(styles.root, formStyles.sectionPadding)}>
        <div className={FORM_SECTION_CLASSES}>
          <ControlledTextfield
            control={control}
            error={errors?.name?.message}
            label={t('virtualMachines.edit.name')}
            name="name"
            required={true}
            testId="edit-vm-name"
            id={`vm-edit-general-name-input`}
          />

          <ControlledSelect
            control={control}
            label={t('virtualMachines.edit.osIcon')}
            name="osFamily"
            options={createOsOptions(operatingSystems)}
            id={`vm-edit-general-os-input`}
          />

          <ControlledTextarea
            control={control}
            error={errors?.description?.message}
            label={t('virtualMachines.edit.description')}
            name="description"
            rows={4}
            required={true}
            testId="edit-vm-description"
            id={`vm-edit-general-description-input`}
          />
          <ControlledCheckbox
            className={styles.checkbox}
            control={control}
            id="nestedHypervisor"
            label={t('virtualMachines.edit.nestedHypervisor')}
            testId="edit-vm-nested-hypervisor"
          />
        </div>
        <div className={FORM_SECTION_CLASSES}>
          <CpuInput />
          <MemoryInput />

          {nodeData && (
            <div className={styles.iconGroup}>
              <Controller
                name="icon"
                defaultValue={selectedIcon}
                control={control}
                render={() => (
                  <div id="icon" className={styles.vmIcon}>
                    {getSelectedIcon(selectedIcon)}
                  </div>
                )}
              />
              <div className={styles.buttonWrapper}>
                <PrimaryButton
                  onClick={() => setShowModal(true)}
                  colour="primary"
                  name={t('virtualMachines.edit.changeIcon')}
                >
                  {t('virtualMachines.edit.changeIcon')}
                </PrimaryButton>
              </div>
            </div>
          )}
          <Modal
            clickScreenToClose={true}
            show={showModal}
            onClose={() => setShowModal(false)}
            title={t('virtualMachines.edit.changeIcon')}
            headerAlignment="center"
          >
            <IconPicker
              handleUpdate={handleUpdateIcon}
              selectedIcon={selectedIcon}
              setShowModal={setShowModal}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
