import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../components/Modals/ConfirmModal';
import { useDeleteExternalDnsRecordMutation } from '../../../../../../redux/configuration/traffic/external-dns/api';

interface ExternalDnsDeleteModalProps {
  setExternalDnsToDelete: (externalDns?: ExternalDnsRecord) => void;
  externalDns: ExternalDnsRecord;
}
export function ExternalDnsDeleteModal({
  setExternalDnsToDelete,
  externalDns,
}: ExternalDnsDeleteModalProps): ReactElement {
  const { t } = useTranslation();
  const [deleteExternalDns, { isLoading, isSuccess, isError }] =
    useDeleteExternalDnsRecordMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setExternalDnsToDelete(undefined);
    }
  }, [isError, isSuccess, setExternalDnsToDelete]);

  return (
    <ConfirmModal
      heading={t('traffic.externalDns.delete.heading')}
      subHeading={t('traffic.externalDns.delete.subHeading')}
      loading={isLoading}
      onCancel={() => setExternalDnsToDelete(undefined)}
      onClose={() => setExternalDnsToDelete(undefined)}
      onConfirm={() => deleteExternalDns(externalDns.uid)}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="external-dns-delete-modal"
    />
  );
}
