export const DEFAULT_DELAY = 300;
export const ONE_DAY = 60 * 60 * 24;

export const IP_ADDRESS_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;
export const URL_PATH_REGEX =
  /^((\/){1}([a-zA-Z0-9@:%_+~#=])+)*(\?([\d\w%]+=[\d\w%]+(&[\d\w%]+=[\d\w%]+)*)+)?[/]?$/;
export const HOSTNAME_REGEX = /^(?!-)[a-zA-Z0-9-]*$/;
export const SRV_SERVICE_REGEX = /^_[a-z0-9](\/?[a-z0-9-])*\/?$/;
export const CISCO_DOMAIN_REGEX =
  /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(cisco)\.com$/;
export const API_ERROR_CODES = {
  NOT_AUTHORIZED: 401,
};
export const DEFAULT_NETWORK_SUBNET = '198.18.128.0 /18';
export const REMOTE_SHUTDOWN_DEFAULT_DELAY = 0;
export const LINKS_REGEX = /^(https?:\/\/)(.*)/;
export const TRAILING_OR_LEADING_SPACE = /^\s|\s$/;
