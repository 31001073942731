import React, { ReactElement, useEffect } from 'react';
import { FullScreenSpinner } from '../../components/Loaders/FullScreenSpinner';
import { AuthenticateProps } from './container';

export function Authenticate({
  swapAccessCode,
}: AuthenticateProps): ReactElement {
  useEffect(() => {
    swapAccessCode();
  }, [swapAccessCode]);

  return <FullScreenSpinner />;
}
