import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { useTheme } from 'dcloud-shared-ui';
import { Heading } from '..';
import { ReactComponent as TopologyManSvg } from '../../assets/images/home/topologyMan.svg';
import './NotifyPanel.css';

export interface NotifyPanelProps {
  className?: string;
  callToAction: ReactNode;
  heading: string;
  subHeading: string;
}

export function NotifyPanel({
  className,
  callToAction,
  heading,
  subHeading,
}: NotifyPanelProps): ReactElement {
  const [theme] = useTheme();
  const rootClasses = classnames('text-center', className, {
    'theme--light': theme === 'light',
  });

  return (
    <section className={rootClasses} data-testid="cta-section">
      <TopologyManSvg className="dbl-margin-bottom dbl-margin-top" />
      <Heading headingText={heading} level="h3" size="36" />
      <Heading headingText={subHeading} level="h4" size="20" />
      {callToAction}
    </section>
  );
}
