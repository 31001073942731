import React, { ReactElement } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { VirtualMachinesTableActionsCellRendererContainer } from '../VirtualMachinesTableActionsCellRenderer/container';

export type VirtualMachinesTableActionsCellRendererWrapperProps = Pick<
  ICellRendererParams,
  'data'
>;

export const VirtualMachinesTableActionsCellRendererWrapper = (
  props: VirtualMachinesTableActionsCellRendererWrapperProps,
): ReactElement => {
  const {
    data: { mor, name },
  }: { data: SessionInventoryVirtualMachineData } = props;

  return (
    <VirtualMachinesTableActionsCellRendererContainer
      mor={mor}
      name={name}
      nodeType="VIRTUAL_MACHINE"
    />
  );
};
