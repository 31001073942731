import React, { ReactElement } from 'react';
import {
  ControlledTextareaSkeleton,
  ControlledTextFieldSkeleton,
} from '../../../../../../components';

export function GeneralFormSkeleton(): ReactElement {
  return (
    <>
      <ControlledTextFieldSkeleton />
      <ControlledTextareaSkeleton />
      <ControlledTextareaSkeleton />
    </>
  );
}
