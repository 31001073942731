import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../components';
import { ConnectedRenameSession } from './components/RenameSessionForm/container';
import { RenameSessionModalProps } from './container';

export function RenameSessionModal({
  setShowRenameSessionModal,
  showRenameSessionModal,
}: RenameSessionModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      show={showRenameSessionModal}
      onClose={() => setShowRenameSessionModal(false)}
      title={t('sessions.modals.renameSession.heading')}
    >
      <div>
        <ConnectedRenameSession />
      </div>
    </Modal>
  );
}
