import React, { ComponentPropsWithRef, ReactElement } from 'react';
import classnames from 'classnames';
import { FormGroup, FormGroupText } from 'dcloud-shared-ui';

export type TextfieldWithDescriptionProps = ComponentPropsWithRef<'input'> & {
  error?: string;
  horizontal?: boolean;
  label?: string;
  labelClass?: string;
  description: string;
  descriptionClass?: string;
  required?: boolean;
};

export function TextfieldWithDescription({
  className,
  error,
  horizontal = true,
  id,
  label,
  description,
  descriptionClass,
  labelClass,
  required,
  disabled,
  ...rest
}: TextfieldWithDescriptionProps): ReactElement {
  const classes = classnames({ error });
  const disabledClasses = classnames({ cursorNotAllowed: disabled });

  return (
    <FormGroup
      horizontal={horizontal}
      errors={error ? [error] : []}
      className={className}
    >
      <FormGroupText>
        <label className={labelClass} htmlFor={id}>
          {label} {required && '*'}
        </label>
        <p className={descriptionClass}>{description}</p>
        <div className={disabledClasses}>
          <input
            id={id}
            type="text"
            className={classes}
            disabled={disabled}
            {...rest}
          />
        </div>
      </FormGroupText>
    </FormGroup>
  );
}
