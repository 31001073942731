import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { Alert } from 'dcloud-shared-ui';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../../../components/Buttons/PrimaryButton';
import { editvmNetworkInterfaceFieldsSchema } from '../../../../../../../../../../validation';
import { getNetworkOptions } from '../../../../../Configure/Utils';
import {
  findNetworkName,
  getNicTypeOptions,
  hasNicLimitReached,
} from '../../utils';
import { NicLimitError } from '../NicLimitError';
import { DEFAULT_NETWORK_MAX_CONNECTIONS } from '../../../../../../../constants';
import { NetworkingFormData } from '../NetworkingForm';
import styles from '../NetworkingForm/NetworkingForm.module.css';
import { useTriggerIpValidation } from './hooks/use-trigger-ip-validation';

interface EditNetworkingFormProps {
  updateVMNetworkInterface: ActionCreatorWithPayload<
    VmNetworkInterfaceToEdit,
    string
  >;
  networks: Network[];
  nicTypes: VmNetworkInterfaceType[];
  nicToEdit: VmNetworkInterfaceToEdit;
  setNicToEdit: ActionCreatorWithOptionalPayload<
    VmNetworkInterfaceToEdit | undefined,
    string
  >;
  limits: Limits;
  vmNetworkInterfaces?: VmNetworkingToEdit;
  vmToEdit?: VirtualMachine;
}

export function EditNetworkingForm({
  networks,
  nicTypes,
  nicToEdit,
  setNicToEdit,
  limits,
  updateVMNetworkInterface,
  vmNetworkInterfaces,
  vmToEdit,
}: EditNetworkingFormProps): ReactElement {
  const { t } = useTranslation();
  const [isNwLimitReached, setIsNwLimitReached] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(0);
  const [subnet, setSubnet] = useState<string>('');
  const formSectionClasses = classnames('col-2', styles.formSection);
  const checkBoxClasses = classnames('col-1', styles.checkboxGroup);
  const buttonGroupClasses = classnames('col-4', styles.formSection);

  const { control, errors, handleSubmit, formState, setValue, trigger, watch } =
    useForm<NetworkingFormData>({
      defaultValues: {
        assignDhcp: nicToEdit?.assignDhcp || false,
        ipAddress: nicToEdit?.ipAddress || '',
        macAddress: nicToEdit?.macAddress || '',
        networkName: nicToEdit?.network?.uid || '',
        type: nicToEdit?.type || '',
      },
      mode: 'all',
      resolver: yupResolver(
        editvmNetworkInterfaceFieldsSchema(limits, subnet, networks),
      ),
    });

  const onSaveClicked: SubmitHandler<NetworkingFormData> = async (formData) => {
    const networkName = findNetworkName(networks, formData);
    if (networkName) {
      const formDataWithTempUid = {
        ...formData,
        inUse: nicToEdit?.inUse || false,
        network: {
          name: networkName,
          uid: formData.networkName,
        },
        uid: nicToEdit.uid,
      };

      updateVMNetworkInterface(formDataWithTempUid);
      setNicToEdit(undefined);
      return;
    }
  };
  const { isDirty, isValid } = formState;

  useEffect(() => {
    triggerNicLimitCheck(control.getValues().networkName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vmNetworkInterfaces?.length]);

  const triggerNicLimitCheck = (selectedNwUid: string) => {
    if (selectedNwUid) {
      const savedNics = vmToEdit?.vmNetworkInterfaces || [];
      const nicsToSave = vmNetworkInterfaces || [];
      const selectedNetwork = networks.find((nw) => nw.uid === selectedNwUid);
      const isEditing =
        !!nicToEdit && nicToEdit?.network?.uid === selectedNwUid;
      const limitReached = hasNicLimitReached(
        savedNics,
        nicsToSave,
        selectedNetwork!,
        isEditing,
      );
      setIsNwLimitReached(limitReached);
      if (limitReached) {
        setLimit(
          selectedNetwork?.vmConnectionInfo?.maxConnections ||
            DEFAULT_NETWORK_MAX_CONNECTIONS,
        );
      }

      selectedNetwork
        ? setSubnet(selectedNetwork.inventoryNetwork.subnet)
        : setSubnet('');
    }
  };

  const handleDhcpChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await setValue('assignDhcp', e.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await setValue('ipAddress', control.getValues().ipAddress, {
      shouldDirty: true,
      shouldValidate: true,
    });
    trigger('ipAddress');
  };

  const watchAssignDhcp = watch('assignDhcp');
  const watchNetworkName = watch('networkName');

  useTriggerIpValidation({ trigger, watchNetworkName });

  const showError = isNwLimitReached && !!control.getValues().networkName;
  return (
    <>
      <div aria-label="VM Edit Networking form" className={styles.root}>
        <div className={formSectionClasses}>
          <ControlledSelect
            className={showError ? styles.errorSelect : ''}
            control={control}
            label={t('virtualMachines.edit.networking.network')}
            horizontal={false}
            options={getNetworkOptions(networks)}
            name="networkName"
            loading={false}
            testId="edit-vm-networking-nic-network-select"
            defaultValue={nicToEdit?.network?.name}
            required={true}
            error={errors?.networkName?.message}
            customOnChange={(event) => {
              triggerNicLimitCheck(event.target.value);
            }}
          />
        </div>
        <div className={formSectionClasses}>
          <ControlledSelect
            control={control}
            label={t('virtualMachines.edit.networking.type')}
            horizontal={false}
            options={getNicTypeOptions(nicTypes)}
            defaultValue={nicToEdit?.type}
            name="type"
            loading={false}
            testId="edit-vm-networking-nic-type-select"
            required={true}
            error={errors?.type?.message}
          />
        </div>
        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.macAddress?.message}
            defaultValue={nicToEdit?.macAddress}
            label={t('virtualMachines.edit.networking.macAddress')}
            name="macAddress"
            required={false}
            testId="edit-vm-networking-mac-address-input"
          />
        </div>
        <div aria-label="Add NIC IP Address" className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            defaultValue={nicToEdit?.ipAddress}
            error={errors?.ipAddress?.message}
            label={t('virtualMachines.edit.networking.ipAddress')}
            name="ipAddress"
            required={watchAssignDhcp}
            testId="edit-vm-networking-ip-address-input"
          />
        </div>
        <div className={checkBoxClasses}>
          <p>{t('virtualMachines.edit.networking.dhcp.title')} </p>
          <ControlledCheckbox
            className={styles.checkbox}
            control={control}
            defaultValue={nicToEdit?.assignDhcp}
            customOnChange={async (e) => handleDhcpChange(e)}
            id="assignDhcp"
            label=""
            testId="edit-vm-networking-enable-dhcp"
          />
        </div>
        <div className={buttonGroupClasses}>
          <PrimaryButton
            className={styles.button}
            type="button"
            disabled={!isDirty || !isValid || showError}
            onClick={handleSubmit(onSaveClicked)}
            testId="edit-vm-networking-save-button"
          >
            {t('buttons.save')}
          </PrimaryButton>

          <PrimaryButton
            className={styles.button}
            type="button"
            colour="danger"
            onClick={() => setNicToEdit(undefined)}
            testId="edit-vm-networking-cancel-button"
          >
            {t('buttons.cancel')}
          </PrimaryButton>
        </div>
      </div>
      {showError && <NicLimitError limit={limit} />}

      <Alert colour="info">
        {t('virtualMachines.edit.networking.warnings.changesInfo')}
      </Alert>
    </>
  );
}
