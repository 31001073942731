import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useSkeletonColours } from '../../hooks/use-skeleton-colours';

export function TableLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  return (
    <ContentLoader
      className="dbl-margin-top"
      speed={1.5}
      width="100%"
      height={300}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0" y="0" rx="3" ry="3" width="67" height="10" />
      <rect x="25%" y="0" rx="3" ry="3" width="67" height="10" />
      <rect x="50%" y="0" rx="3" ry="3" width="67" height="10" />
      <rect x="75%" y="0" rx="3" ry="3" width="67" height="10" />
      <rect x="0" y="40" rx="3" ry="3" width="100%" height="30" />
      <rect x="0" y="80" rx="3" ry="3" width="100%" height="30" />
      <rect x="0" y="120" rx="3" ry="3" width="100%" height="30" />
      <rect x="0" y="160" rx="3" ry="3" width="100%" height="30" />
    </ContentLoader>
  );
}
