import { CURRENT_USER } from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { TAGS } from '../../api/constants';
import { setCurrentUserRoles } from './slice';

export const currentUserApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUserRoles: build.query<CurrentUserResponse, undefined>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCurrentUserRoles(data.userRoles));
        } catch (err) {
          throw err;
        }
      },
      providesTags: [TAGS.CURRENT_USER],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('currentUser.fetch.error'),
        method: 'get',
        url: `${CURRENT_USER}`,
      }),
    }),
  }),
});

export const { useGetCurrentUserRolesQuery } = currentUserApi;
