import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { willNatRulesLimitBeExceeded } from '../utils/utils';
import { NAT_RULE_TYPES } from '../../../../../../../../constants/nat-rules';

type UseShowLimitsWarningRulesReturn = {
  onAddClicked: (formData: NatRuleFormData) => void;
};

export type UseShowLimitsWarningRulesProps = {
  topologyLimitsWarningAccepted?: boolean;
  resetTopologyLimits: VoidFunction;
  add: SubmitHandler<NatRuleFormData>;
  topologyLimit?: TopologyLimit;
  setShowTopologyLimitWarningForEntity: ActionCreatorWithOptionalPayload<
    | TopologyLimitsDisplayLabels
    | Array<TopologyLimitsDisplayLabels>
    | undefined,
    string
  >;
};

export function useShowTopologyLimitsWarningRules({
  topologyLimitsWarningAccepted,
  resetTopologyLimits,
  add,
  topologyLimit,
  setShowTopologyLimitWarningForEntity,
}: UseShowLimitsWarningRulesProps): UseShowLimitsWarningRulesReturn {
  const [formData, setFormdata] = useState<VmEditFormData>();
  const internalNatRuleTypes = [
    NAT_RULE_TYPES.internalIp,
    NAT_RULE_TYPES.internalVmNic,
  ];

  useEffect(() => {
    if (topologyLimitsWarningAccepted) {
      resetTopologyLimits();
      add(formData);
    }
  }, [add, formData, resetTopologyLimits, topologyLimitsWarningAccepted]);

  const onAddClicked = (formData: NatRuleFormData) => {
    resetTopologyLimits();
    setFormdata(formData);
    if (
      !topologyLimit ||
      (formData?.type && internalNatRuleTypes.includes(formData.type))
    ) {
      add(formData);
      return;
    }

    if (willNatRulesLimitBeExceeded(topologyLimit)) {
      setShowTopologyLimitWarningForEntity('Public NAT Rules');
    } else {
      add(formData);
    }
  };

  return { onAddClicked };
}
