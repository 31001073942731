import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setLeftPanelDeviceUid,
  setLeftPanelType,
} from '../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../redux/store';
import { diagramSelectors } from '../../../../redux/diagram/slice';
import {
  logicalConnectionsSelectors,
  setIsDrawingLogicalConnectionFrom,
} from '../../../../redux/logicalConnections/slice';
import { IconNode } from '.';

const mapDispatchToProps = {
  setIsDrawingLogicalConnectionFrom,
  setLeftPanelDeviceUid,
  setLeftPanelType,
};

type IconNodeOwnProps = {
  display: DiagramIconDisplay;
  isReadOnly: boolean;
  uid: string;
};

const mapStateToProps = (state: RootState, ownProps: IconNodeOwnProps) => ({
  display: ownProps.display,
  expired: sessionViewSelectors.getExpired(state),
  isAddingLogicalConnection:
    diagramSelectors.getIsAddingLogicalConnection(state),
  isDrawingLogicalConnectionFrom:
    logicalConnectionsSelectors.getIsDrawingLogicalConnectionFrom(state),
  isReadOnly: ownProps.isReadOnly,
  isSnapToGridEnabled: diagramSelectors.getIsSnapToGridEnabled(state),
  uid: ownProps.uid,
});

export type IconNodeProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export const IconNodeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IconNode);
