import { useEffect } from 'react';
import { Control } from 'react-hook-form';

export type UseClearIntegrationStatusMessage = {
  isStatusAvailabilityMessageFieldVisible: boolean;
  availabilityStatusMessage?: string;
  setValue: Control['setValue'];
};

export function useClearIntegrationStatusMessage({
  isStatusAvailabilityMessageFieldVisible,
  availabilityStatusMessage,
  setValue,
}: UseClearIntegrationStatusMessage): void {
  useEffect(() => {
    if (!isStatusAvailabilityMessageFieldVisible && availabilityStatusMessage) {
      setValue('availabilityStatusMessage', '', {
        shouldDirty: true,
      });
    }
  }, [
    isStatusAvailabilityMessageFieldVisible,
    availabilityStatusMessage,
    setValue,
  ]);
}
