import { TOPOLOGIES_PATH, TOPOLOGY_LIMITS_PATH } from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { TAGS } from '../../api/constants';

export const topologyLimitsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getTopologyLimits: build.query<TopologyLimitsResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.TOPOLOGY_LIMITS],
      query: (topologyId) => ({
        errorMessage: i18n.t('topologies.topologyLimitsToolbar.fetch.error'),
        method: 'GET',
        url: `${TOPOLOGIES_PATH}/${topologyId}${TOPOLOGY_LIMITS_PATH}`,
      }),
    }),
  }),
});

export const { useGetTopologyLimitsQuery } = topologyLimitsApi;
