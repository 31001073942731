import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../../components/Table';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { SrvTableActions } from './SrvTableActions';

type SrvTableProps = {
  isError: boolean;
  isLoading: boolean;
  srvRecords: SrvRecord[];
  onDeleteClick: (srvRecord: SrvRecord) => void;
};

export function SrvTable({
  isError,
  isLoading,
  srvRecords,
  onDeleteClick,
}: SrvTableProps): ReactElement | null {
  const { t } = useTranslation();
  const columns = useMemo<Column<SrvRecord>[]>(
    () => [
      {
        Header: () => t('traffic.externalDns.edit.srv.table.headings.service'),
        accessor: ({ service }) => service,
        id: 'natRule',
        width: 80,
      },
      {
        Header: () => t('traffic.externalDns.edit.srv.table.headings.protocol'),
        accessor: ({ protocol }) => protocol,
        id: 'protocol',
        width: 50,
      },
      {
        Header: () => t('traffic.externalDns.edit.srv.table.headings.port'),
        accessor: ({ port }) => port,
        id: 'hostname',
        width: 50,
      },
    ],
    [t],
  );

  const renderActions = (srvRecord: SrvRecord): ReactNode => (
    <SrvTableActions srvRecord={srvRecord} onDeleteClick={onDeleteClick} />
  );

  if (isError || isLoading || !srvRecords) {
    return <TableLoader />;
  }

  return (
    <Table<SrvRecord>
      data={srvRecords}
      columns={columns}
      actions={renderActions}
    />
  );
}
