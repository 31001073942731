import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { getDnsFromSession } from './utils';
import { DNS } from '.';

export function ConnectedExpoDNS({
  engagementUid,
  sessionUid,
  versionUid,
}: FetchExpoSessionArgs): ReactElement {
  const { dns, isLoading, isFetching, isError } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          dns: getDnsFromSession(data),
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <DNS dns={dns} isLoading={isLoading || isFetching} isError={isError} />
  );
}
