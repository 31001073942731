import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { MainRoutes } from '../../../constants/routes';
import { useGetCurrentUserRolesQuery } from '../../../redux/configuration/currentUser/api';
import { UserRoleGuardProps } from './container';
import { isUserPermitted } from './utils';

export const UserRoleGuard = ({
  children,
  requiredRoles,
  userRoles,
  redirectToMainPageIfNotPermitted = false,
  loadingComponent,
}: UserRoleGuardProps): ReactElement => {
  const { data: fetchedUserRoles } = useGetCurrentUserRolesQuery(undefined, {
    skip: userRoles !== undefined,
  });

  if (!requiredRoles) {
    throw new Error('Please Provide required user Roles');
  }

  if (
    loadingComponent &&
    userRoles === undefined &&
    fetchedUserRoles === undefined
  ) {
    return loadingComponent;
  }

  if (
    !isUserPermitted(fetchedUserRoles?.userRoles || userRoles, requiredRoles)
  ) {
    return redirectToMainPageIfNotPermitted ? (
      <Redirect to={MainRoutes.Main} />
    ) : (
      <></>
    );
  }
  return <>{children}</>;
};
