import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedDemoTemplate,
  templateManagerSelectors,
} from '../../../../../../redux/templateManager/slice';
import { useCreateDemoTemplateMutation } from '../../../../../../redux/templateManager/DemoTemplates/api';
import { buildDemoTemplateColsData } from '../../utils';
import { AddDemoTemplateModal } from '.';

function AddDemoTemplateContainer({
  addSelectedArchitecture,
  allArchitectures,
  currentDc,
  setSelectedDemoTemplate,
  removeSelectedArchitecture,
  selectedArchitectures,
  selectedDemoTemplate,
}: AddVmTemplateModalRTKProps): ReactElement {
  const [createDemoTemplate, { isLoading: isCreating }] =
    useCreateDemoTemplateMutation();

  if (!selectedDemoTemplate) {
    return <></>;
  }

  return (
    <AddDemoTemplateModal
      currentDc={currentDc!}
      createDemoTemplate={createDemoTemplate}
      addSelectedArchitecture={addSelectedArchitecture}
      allArchitectures={allArchitectures}
      showAddModal={true}
      setSelectedDemoTemplate={setSelectedDemoTemplate}
      isCreating={isCreating}
      selectedArchitectures={selectedArchitectures}
      removeArchitecture={removeSelectedArchitecture}
      templateToEdit={selectedDemoTemplate}
      colsContent={buildDemoTemplateColsData(currentDc!, selectedDemoTemplate)}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  allArchitectures: templateManagerSelectors.getAllArchitectures(state),
  currentDc: templateManagerSelectors.getSelectedDataCenter(state),
  selectedArchitectures:
    templateManagerSelectors.getSelectedArchitectures(state),
  selectedDemoTemplate: templateManagerSelectors.getSelectedDemoTemplate(state),
});

const mapDispatchToProps = {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedDemoTemplate,
};

export type AddVmTemplateModalRTKProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedAddDemoTemplateModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddDemoTemplateContainer);
