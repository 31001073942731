import { isUserPermitted } from '../../../../auth/components/UserRoleGuard/utils';
import { TileProps } from './components/Tile';
import {
  TILE_CLASS_3_COLUMN_GRID_XL,
  TILE_CLASS_4_COLUMN_GRID_XL,
} from './constants';

export const determineXlClassForTiles = (
  tiles: TileProps[],
  userRoles: UserRole[],
): string => {
  let tilesCount = tiles.length;
  tiles.forEach((tile) => {
    if (tile.requiredRoles && !isUserPermitted(userRoles, tile.requiredRoles)) {
      tilesCount--;
    }
  });
  return tilesCount === 3
    ? TILE_CLASS_3_COLUMN_GRID_XL
    : TILE_CLASS_4_COLUMN_GRID_XL;
};
