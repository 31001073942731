import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { Heading } from '../Heading';
import { BackButton } from './BackButton';
import styles from './PageHeading.module.css';

export interface PageHeadingProps {
  ctaBtn?: ReactNode;
  pageTitle: string;
  withBackBtn?: boolean;
  backButtonOverride?: VoidFunction;
  useCustomStyling?: boolean;
}

export function PageHeading({
  ctaBtn,
  pageTitle,
  withBackBtn = false,
  backButtonOverride,
  useCustomStyling = false,
}: PageHeadingProps): ReactElement {
  const classes = classNames(
    'dbl-margin-bottom flex-between flex-middle',
    styles.root,
  );

  return (
    <div className={classes}>
      <div className={styles.heading}>
        {withBackBtn && <BackButton backButtonOverride={backButtonOverride} />}
        <Heading
          className="dbl-padding"
          headingText={pageTitle}
          level="h1"
          size="36"
          align="left"
          weight="200"
        />
      </div>
      {ctaBtn && useCustomStyling ? (
        <span>{ctaBtn}</span>
      ) : (
        ctaBtn && <span className={styles.button}>{ctaBtn}</span>
      )}
    </div>
  );
}
