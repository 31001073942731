import { ThemeValues } from 'dcloud-shared-ui';
import { DiagramDrawingColorName } from '../../../../typings/drawing-palettes';
import {
  logicalConnectionLineDefaults,
  physicalConnectionLineDefaults,
} from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLogicalConnection/constants';

export const determineColorForConnection = (
  selectedColor: DiagramDrawingColorName,
  selectedTheme: ThemeValues,
  uid: string,
  isPhysicalConnection?: boolean,
): HtmlColorValue => {
  if (selectedColor === 'transparent') return selectedColor;
  const lightTheme = 'light';
  const resultPrefix = 'var(--cui-';

  if (isPhysicalConnection) {
    const { strokeDarkThemeColor, strokeLightThemeColor, defaultColor } =
      physicalConnectionLineDefaults;

    if (selectedColor === strokeDarkThemeColor) {
      if (selectedTheme === lightTheme) {
        return `${resultPrefix}${strokeLightThemeColor})`;
      } else {
        return `${resultPrefix}${strokeDarkThemeColor})`;
      }
    } else if (selectedColor === undefined) {
      return `${resultPrefix}${defaultColor})`;
    }

    return `${resultPrefix}${selectedColor})`;
  }

  const { strokeDarkThemeColor, strokeLightThemeColor } =
    logicalConnectionLineDefaults;
  const tempLogicalGuideUid = 'TEMP_LOGICAL_GUIDE_UID';

  if (uid === tempLogicalGuideUid) {
    const result =
      selectedTheme === lightTheme
        ? strokeLightThemeColor
        : strokeDarkThemeColor;

    return `${resultPrefix}${result})`;
  }

  const isLightTheme = selectedTheme === lightTheme;

  const isDefaultColorSelected = selectedColor === strokeDarkThemeColor;

  const result =
    isLightTheme && isDefaultColorSelected
      ? strokeLightThemeColor
      : selectedColor || strokeDarkThemeColor;

  return `${resultPrefix}${result})`;
};

type UpdateStrokeDashArrayAttribute = {
  strokeType: string | null;
  scaleFactor: number;
  connection: d3.Selection<SVGLineElement, unknown, null, undefined>;
};

export const updateStrokeDashArrayAttribute = ({
  strokeType,
  scaleFactor,
  connection,
}: UpdateStrokeDashArrayAttribute): void => {
  const strokeDashArray = 'stroke-dasharray';
  const strokeTypeDashed = 'dashed';

  if (strokeType === strokeTypeDashed) {
    connection.attr(
      strokeDashArray,
      (logicalConnectionLineDefaults.dashSize * scaleFactor).toString(),
    );
  } else {
    connection.attr(strokeDashArray, 0);
  }
};

type AddEventListenerToConnectionLine = {
  element: SVGLineElement;
  handleEvent(event: MouseEvent): void;
};

export const addEventListenerToConnectionLine = ({
  element,
  handleEvent,
}: AddEventListenerToConnectionLine): void => {
  element.addEventListener<'click'>('click', handleEvent);
};

type RemoveEventListenerToConnectionLine = {
  element: SVGLineElement;
  handleEvent(event: MouseEvent): void;
};

export const removeEventListenerToConnectionLine = ({
  element,
  handleEvent,
}: RemoveEventListenerToConnectionLine): void => {
  element.removeEventListener<'click'>('click', handleEvent);
};
