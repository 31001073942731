import { Label } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TopologiesInSyncLabel.module.css';

export function TopologiesInSyncLabel(): ReactElement {
  const { t } = useTranslation();

  return (
    <Label className={styles.inSyncLabel} colour="tertiary" size="small">
      {t('topologies.push.inSyncWithDcloud')}
    </Label>
  );
}
