import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../../../../redux/views/session-view/session/api/expoApi';
import { Proxy } from '.';

export function ConnectedExpoProxy({
  engagementUid,
  sessionUid,
  versionUid,
}: FetchExpoSessionArgs): ReactElement {
  const { sessionProxyAddresses, isLoading, isFetching, isError } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      {
        engagementUid,
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionProxyAddresses: data?.sessionDetails.sessionProxyAddresses,
        }),
      },
    );

  return (
    <Proxy
      sessionProxyAddresses={sessionProxyAddresses}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
