import { useEffect } from 'react';

type useUnsubscribeFromGetAllVmStatusesDataArgs = {
  unsubscribeFromGetAllVmStatusesData: VoidFunction;
};

export function useUnsubscribeFromGetAllVmStatusesData({
  unsubscribeFromGetAllVmStatusesData,
}: useUnsubscribeFromGetAllVmStatusesDataArgs): void {
  useEffect(() => {
    return () => {
      unsubscribeFromGetAllVmStatusesData();
    };
  }, [unsubscribeFromGetAllVmStatusesData]);
}
