import i18n from '../../../../../i18n';

export const buildWarningAlterTextPart1 = (): string => {
  return i18n.t('topologies.configurationEditWarnings.warningStart');
};

export const buildWarningAlterTextPart2 = (
  isActiveTemplate: boolean,
  isDemoPublished: boolean,
): string => {
  if (isDemoPublished && isActiveTemplate) {
    return `${i18n.t(
      'topologies.configurationEditWarnings.published',
    )} ${i18n.t('topologies.configurationEditWarnings.and')} ${i18n.t(
      'topologies.configurationEditWarnings.anActiveTemplate',
    )}`;
  } else {
    return [
      isDemoPublished
        ? i18n.t('topologies.configurationEditWarnings.published')
        : null,
      isActiveTemplate
        ? i18n.t('topologies.configurationEditWarnings.anActiveTemplate')
        : null,
    ]
      .filter((row) => row)
      .join(' ' + i18n.t('topologies.configurationEditWarnings.and') + ' ');
  }
};

export const buildWarningAlterTextPart3 = (): string => {
  return i18n.t('topologies.configurationEditWarnings.warningEnd');
};
