import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CenteredSpinner } from '../../../../../../../components';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../../components/Accordion';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../../../../components/FormUnsavedChangesPrompt';
import { PageHeading } from '../../../../../../../components/PageHeading';
import {
  useGetSrvProtocolQuery,
  useUpdateExternalDnsRecordMutation,
} from '../../../../../../../redux/configuration/traffic/external-dns/api';
import { srvScheme } from '../../../../../../../validation';
import { useLeaveFormPrompt } from '../../../../../hooks/useLeaveFormPrompt';
import { EditExternalDnsProps } from '../container';
import { ExternalDnsGeneral } from '../General';
import { SrvAddFormContainer } from '../SRV/container';

export function EditExternalDnsForm({
  externalDnsRecord,
}: EditExternalDnsProps): ReactElement {
  const { t } = useTranslation();

  const formMethods = useForm<SrvRecord>({
    defaultValues: {
      port: undefined,
      protocol: '',
      service: '_',
    },
    mode: 'all',
    resolver: yupResolver(srvScheme),
  });

  const [srvRecords, setSrvRecords] = useState<SrvRecord[]>(
    externalDnsRecord.srvRecords || [],
  );

  const [originalSrvRecords, setOriginalSrvRecords] = useState<SrvRecord[]>(
    externalDnsRecord.srvRecords || [],
  );

  const { isDirty, isValid } = formMethods.formState;
  const isUnchanged = !isDirty || !isValid;
  const srvChanged = isEqual(srvRecords, externalDnsRecord.srvRecords);

  const addNewSrvRecord = (srvRecord: SrvRecord) => {
    setSrvRecords((prevState) => [...prevState, srvRecord]);
  };

  const deleteSrv = (srvRecord: SrvRecord) => {
    setSrvRecords((prevState) =>
      prevState.filter((item) => !isEqual(item, srvRecord)),
    );
  };

  useEffect(() => {
    if (externalDnsRecord.srvRecords !== originalSrvRecords) {
      setOriginalSrvRecords(externalDnsRecord.srvRecords || []);
      setSrvRecords(externalDnsRecord.srvRecords || []);
    }
  }, [externalDnsRecord.srvRecords, originalSrvRecords]);

  const { showModal, handleConfirm, handleCancel } = useLeaveFormPrompt(
    isDirty || !srvChanged,
  );

  const { data } = useGetSrvProtocolQuery();
  const [updateExternalDns] = useUpdateExternalDnsRecordMutation();

  const onSubmit = () => {
    const payload: UpdateExternalDnsPayload = {
      hostname: externalDnsRecord.hostname,
      inventoryDnsAsset: externalDnsRecord.inventoryDnsAsset,
      natRule: { uid: externalDnsRecord.natRule.uid },
      srvRecords: srvRecords,
      topology: externalDnsRecord.topology,
      uid: externalDnsRecord.uid,
    };
    updateExternalDns(payload);
  };

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <ExternalDnsGeneral externalDnsRecord={externalDnsRecord} />,
        title: t('traffic.externalDns.edit.general.heading'),
      },
      {
        children: (
          <>
            <SrvAddFormContainer
              formMethods={formMethods}
              addSrvRecord={addNewSrvRecord}
              srvRecords={srvRecords}
              onDeleteSrv={deleteSrv}
              protocols={data?.srvProtocols || []}
            />
            <FormUnsavedChangesPrompt
              showModal={showModal}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
            />
          </>
        ),
        title: t('traffic.externalDns.edit.srv.heading'),
      },
    ],
    [
      data?.srvProtocols,
      externalDnsRecord,
      formMethods,
      handleCancel,
      handleConfirm,
      showModal,
      srvRecords,
      t,
    ],
  );

  if (!data) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <PageHeading
        withBackBtn={true}
        pageTitle={externalDnsRecord.aRecord}
        ctaBtn={
          <PrimaryButton
            type="submit"
            colour="success"
            size="large"
            loading={false}
            disabled={!isUnchanged || srvChanged}
            onClick={() => onSubmit()}
            testId="edit-srvUpdate-update"
          >
            {t('buttons.save')}
          </PrimaryButton>
        }
      />
      <Accordion rows={accordionRows} />
    </>
  );
}
