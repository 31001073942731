import React, { ReactElement, useEffect, useState } from 'react';
import {
  useRouteMatch,
  useParams,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageHeading } from '../../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { useCloneVmMutation } from '../../../../../../redux/configuration/virtual-machine-entities/api';
import { getNetworkOptions } from '../../../components/VirtualMachineEntities/Configure/Utils';
import { VirtualMachinesRoutes } from '../../VirtualMachinesRouter';
import { ConfigureFormFields } from '../../../components/VirtualMachineEntities/Configure/Templates/ConfigureFormFields';
import { addVmFieldArraySchema } from '../../../../../../validation';
import { prepInterfacesForDemosConfigureScreen } from '../../../components/VirtualMachineEntities/Configure/Templates/utils';
import { DemoVirtualMachinesConfigureProps } from './container';
import { useShowTopologyLimitsWarningAddVmFromDemo } from './hooks/use-show-topology-limits-warning-add-vm-from-demo';

export const prepMappingsForClone = (
  mappings: NetworkToConfigure[],
): NetworkToConfigure[] => {
  const mappingsLessNulls = mappings.filter((item) => item !== null);
  return mappingsLessNulls.map((map) => ({
    assignDhcp: map.assignDhcp || false,
    ipAddress: map.ipAddress || undefined,
    macAddress: map.macAddress || undefined,
    name: map.name!,
    network: {
      uid: map.network,
    },
  }));
};

export function DemoVirtualMachinesConfigure({
  networks,
  setDemoVirtualMachineToConfigure,
  vm,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
  topologyLimitsWarningAccepted,
  topologyLimits,
  backButtonOverride,
  isDiagramView = false,
  handleAddVmFromDemosTabFromDiagram,
  vms,
}: DemoVirtualMachinesConfigureProps): ReactElement {
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const [cloneVm, { isLoading: isCloning, isSuccess: isCloningSuccess }] =
    useCloneVmMutation();

  useEffect(() => {
    return () => {
      setDemoVirtualMachineToConfigure(undefined);
    };
  }, [setDemoVirtualMachineToConfigure]);

  const interfacesForForm = prepInterfacesForDemosConfigureScreen(
    vm?.vmNetworkInterfaces || [],
    networks,
  );

  const formMethods = useForm<AddVmConfigureFormValues>({
    defaultValues: {
      interfaces: [...interfacesForForm],
    },
    mode: 'all',
    resolver: yupResolver(addVmFieldArraySchema(networks, vms || [])),
  });
  const {
    getValues,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    const determineIfAddButtonIsDisabled = (): void => {
      if (!errors.interfaces) {
        setIsAddDisabled(false);
        return;
      }

      const numberOfDuplicateIpTestErrors = errors.interfaces?.filter(
        (interfaceItem) => interfaceItem?.ipAddress?.type === 'duplicateIpTest',
      );

      const numberOfDuplicateMacTestErrors = errors.interfaces?.filter(
        (interfaceItem) =>
          interfaceItem?.macAddress?.type === 'duplicateMacAddressTest',
      );

      const totalNumErrors = errors.interfaces.reduce(function (result, error) {
        if (error) {
          result += Object.keys(error)?.length;
        }
        return result;
      }, 0);

      setIsAddDisabled(
        totalNumErrors >
          numberOfDuplicateIpTestErrors.length +
            numberOfDuplicateMacTestErrors.length,
      );
    };
    determineIfAddButtonIsDisabled();
  });

  useEffect(() => {
    if ((isCloning || isCloningSuccess) && !isDiagramView) {
      history.push(
        url.substring(0, url.lastIndexOf(VirtualMachinesRoutes.Add)),
      );
    }
  }, [history, isCloning, isCloningSuccess, isDiagramView, url]);

  const onSubmit = () => {
    const data: AddVmConfigureFormValues = getValues();
    if (!vm) return;
    cloneVm({
      payload: {
        topology: { uid: topologyUid },
        vmNetworkInterfaces: prepMappingsForClone(data.interfaces),
        vmUid: vm.uid,
      },
    });
  };

  const { onAddClicked } = useShowTopologyLimitsWarningAddVmFromDemo({
    addVm: onSubmit,
    resetTopologyLimits,
    setShowTopologyLimitWarningForEntity,
    topologyLimits,
    topologyLimitsWarningAccepted,
    vm: vm,
  });

  if (!vm?.vmNetworkInterfaces && !isDiagramView) {
    return (
      <Redirect
        to={url.substring(0, url.lastIndexOf(VirtualMachinesRoutes.Add))}
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <PageHeading
        withBackBtn={true}
        backButtonOverride={backButtonOverride}
        ctaBtn={
          <PrimaryButton
            aria-label="Add Button"
            testId="add-vm"
            disabled={isAddDisabled}
            loading={isCloning}
            onClick={
              isDiagramView
                ? () =>
                    handleAddVmFromDemosTabFromDiagram({
                      topologyLimits: topologyLimits,
                      topologyUid: { uid: topologyUid },
                      vm,
                      vmNetworkInterfaces: getValues().interfaces,
                    })
                : onAddClicked
            }
          >
            {t('buttons.add')}
          </PrimaryButton>
        }
        pageTitle={t('virtualMachines.configure.title')}
      />
      <form>
        <ConfigureFormFields
          networks={networks}
          options={getNetworkOptions(networks || [])}
          interfaces={interfacesForForm}
        />
      </form>
    </FormProvider>
  );
}
