import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { sessionApi } from '../session/api/api';
import { addErrorToast, addSuccessToast } from '../../../toast/slice';
import i18n from '../../../../i18n';
import { ACTION_LABEL_MAPPINGS } from '../../../../pages/Views/SessionInventory/constants';
import {
  START_GET_ALL_VM_STATUSES_ACTION,
  STOP_GET_ALL_VM_STATUSES_ACTION,
  UNSUBSCRIBE_FROM_ALL_VM_STATUSES_DATA_ACTION,
  incrementNumOfActionsCompleted,
  sessionInventorySelectors,
  setHasCalledVmStatusesAPICalls,
  setIsCallingVmStatusesAPICalls,
  setNumberOfMultiActionItemsCompleted,
  setShowMultiActionProgressBar,
  increamentMultiSelectApiErrors,
  resetNumOfActionsCompleted,
  resetMultiSelect,
  addMultiSelectHwApiErrorItem,
  addMultiSelectVmApiErrorItem,
  clearHwMultiSelectErrorItems,
  clearVmMultiSelectErrorItems,
} from './slice';
import {
  addEffectToUnsubscribeEffectsHelper,
  buildArrayOfCallEffects,
  buildArrayOfMultiSelectHwCallEffects,
  buildArrayOfMultiSelectVmCallEffects,
  getLengthOfSessionInventoryVmStatusUnsubscribeEffects,
  resetEffectsToUnsubscribeHelper,
} from './saga-helpers';

const WAIT_IN_MILLISECONDS_BETWEEN_VM_STATUS_CALLS = 300;
const WAIT_IN_MILLISECONDS_BETWEEN_MULTI_API_CALLS = 300;
const WAIT_IN_MILLISECONDS_AFTER_ALL_MULTI_API_CALLS = 3000;

const sessionInventoryVmStatusUnsubscribeEffects: CallEffect<unknown>[] = [];

export function* getVmStatusHelper(
  fetchSessionVmStatusArgs: FetchSessionVmStatusArgs,
): SagaIterator {
  let putResult;

  try {
    putResult = yield put(
      yield call(
        sessionApi.endpoints.getSessionVmStatusForSessionInventory.initiate,
        fetchSessionVmStatusArgs,
      ),
    );
    yield putResult;
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    if (putResult) {
      yield call(
        addEffectToUnsubscribeEffectsHelper,
        call(putResult.unsubscribe),
        sessionInventoryVmStatusUnsubscribeEffects,
      );
    }
  }
}

export function* getVmsStatusesHelper(
  virtualMachines: SessionInventoryVirtualMachineData[],
): SagaIterator {
  try {
    yield put(setIsCallingVmStatusesAPICalls(true));

    const effects = yield call(buildArrayOfCallEffects, virtualMachines);

    for (const effect of effects) {
      yield delay(WAIT_IN_MILLISECONDS_BETWEEN_VM_STATUS_CALLS);
      yield effect;
    }

    yield put(setIsCallingVmStatusesAPICalls(false));
    yield put(setHasCalledVmStatusesAPICalls(true));
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  }
}

export function* startGetAllVmStatusesForSessionInventoryWorker({
  payload,
}: PayloadAction<StartGetAllVmStatusesForSessionInventoryPayload>): SagaIterator {
  try {
    const length = yield call(
      getLengthOfSessionInventoryVmStatusUnsubscribeEffects,
      sessionInventoryVmStatusUnsubscribeEffects,
    );

    if (length > 0) {
      yield all(sessionInventoryVmStatusUnsubscribeEffects);
      yield call(
        resetEffectsToUnsubscribeHelper,
        sessionInventoryVmStatusUnsubscribeEffects,
      );
    }

    yield call(getVmsStatusesHelper, payload);
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  }
}

export function* unsubscribeFromGetAllVmStatusesDataWorker(): SagaIterator {
  try {
    yield all(sessionInventoryVmStatusUnsubscribeEffects);
    yield call(
      resetEffectsToUnsubscribeHelper,
      sessionInventoryVmStatusUnsubscribeEffects,
    );
    yield put(setHasCalledVmStatusesAPICalls(false));
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  }
}

export function* postHwActionHelper(
  postHwActionHelperArgs: SessionHwActionArgs & {
    name: SessionInventoryHardwareData['name'];
  },
): SagaIterator {
  let putResult;
  const { name, ...rest } = postHwActionHelperArgs;

  try {
    putResult = yield put(
      yield call(sessionApi.endpoints.postSessionHwAction.initiate, {
        ...rest,
      }),
    );
    yield putResult;
    yield putResult.unwrap();
  } catch (error) {
    yield put(increamentMultiSelectApiErrors());
    yield put(
      addMultiSelectHwApiErrorItem({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        logref: (error as any).data[0]?.logref || '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: (error as any).data[0]?.message || '',
        name,
      }),
    );
  } finally {
    if (putResult) {
      yield call(putResult.unsubscribe);
    }
  }
}

export function* postVmActionHelper(
  postVmActionHelperArgs: SessionVmActionArgs & {
    name: SessionInventoryVirtualMachineData['name'];
  },
): SagaIterator {
  let putResult;
  const { name, ...rest } = postVmActionHelperArgs;

  try {
    putResult = yield put(
      yield call(sessionApi.endpoints.postSessionVmAction.initiate, {
        ...rest,
      }),
    );
    yield putResult;
    yield putResult.unwrap();
  } catch (error) {
    yield put(increamentMultiSelectApiErrors());
    yield put(
      addMultiSelectVmApiErrorItem({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        logref: (error as any).data[0]?.logref || '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: (error as any).data[0]?.message || '',
        name,
      }),
    );
  } finally {
    if (putResult) {
      yield call(putResult.unsubscribe);
    }
  }
}

export function* hasAcceptedMultiSelectActionConfirmWorker(
  payload: PayloadAction<boolean>,
): SagaIterator {
  try {
    if (payload) {
      let effects;
      yield put(setNumberOfMultiActionItemsCompleted(0));
      yield put(resetNumOfActionsCompleted());
      yield put(setShowMultiActionProgressBar(true));

      const selectedNodeType = yield select(
        sessionInventorySelectors.getSelectedNodeType,
      );

      const selectedAction = yield select(
        sessionInventorySelectors.getSelectedAction,
      );

      if (!selectedNodeType || !selectedAction) {
        yield put(resetMultiSelect());
        yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
        return;
      } else {
        if (selectedNodeType === 'HW') {
          yield put(clearHwMultiSelectErrorItems());
          const selectedHwItemsToAction = yield select(
            sessionInventorySelectors.getSelectedHwItemsToAction,
          );

          effects = yield call(
            buildArrayOfMultiSelectHwCallEffects,
            selectedHwItemsToAction,
            selectedAction,
          );
        } else {
          yield put(clearVmMultiSelectErrorItems());
          const selectedVmItemsToAction = yield select(
            sessionInventorySelectors.getSelectedVmItemsToAction,
          );

          effects = yield call(
            buildArrayOfMultiSelectVmCallEffects,
            selectedVmItemsToAction,
            selectedAction,
          );
        }
      }

      for (const effect of effects) {
        yield delay(WAIT_IN_MILLISECONDS_BETWEEN_MULTI_API_CALLS);
        yield effect;
        yield put(incrementNumOfActionsCompleted());
      }

      yield delay(WAIT_IN_MILLISECONDS_AFTER_ALL_MULTI_API_CALLS);
    }
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    yield call(cleanUp);
  }
}

export function* cleanUp(): SagaIterator {
  yield call(createToasts);
  yield put(setShowMultiActionProgressBar(false));
}

export function* createToasts(): SagaIterator {
  const selectedAction: SessionVmActions | SessionHwActionReset = yield select(
    sessionInventorySelectors.getSelectedAction,
  );

  const numOfErrors = yield select(
    sessionInventorySelectors.getNumOfMultiSelectApiErrors,
  );

  if (numOfErrors === 0) {
    yield put(
      addSuccessToast({
        message: `${ACTION_LABEL_MAPPINGS[selectedAction]}${i18n.t(
          'sessionsInventory.toasts.sentSuccessfully',
        )}`,
      }),
    );
  }
}

export function* sessionInventoryWatchers(): SagaIterator {
  yield all([
    yield takeLatest(
      START_GET_ALL_VM_STATUSES_ACTION,
      function* (
        payload: PayloadAction<StartGetAllVmStatusesForSessionInventoryPayload>,
      ) {
        yield race({
          cancel: take(STOP_GET_ALL_VM_STATUSES_ACTION),
          task: call(startGetAllVmStatusesForSessionInventoryWorker, payload),
        });
      },
    ),
    yield takeLatest(
      UNSUBSCRIBE_FROM_ALL_VM_STATUSES_DATA_ACTION,
      unsubscribeFromGetAllVmStatusesDataWorker,
    ),
    yield takeLatest(
      'sessionInventory/setHasAcceptedMultiSelectActionConfirm',
      hasAcceptedMultiSelectActionConfirmWorker,
    ),
  ]);
}
