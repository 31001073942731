import { SelectOption } from 'dcloud-shared-ui';
import classNames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledSelect } from '../../../../../../components';
import formStyles from '../../../EditFormStyles.module.css';
import { FORM_SECTION_CLASSES } from '../../../components/VirtualMachineEntities/constants';
import { convertScriptsToOptions } from './utils';

interface FormScriptsProps {
  isLoading: boolean;
  scripts?: HardwareScript[];
}

export function FormScripts({
  isLoading,
  scripts = [],
}: FormScriptsProps): ReactElement {
  const { control } = useFormContext();

  const scriptOptions = useMemo<SelectOption<string>[]>(
    () => [{ name: 'None', value: '' }, ...convertScriptsToOptions(scripts)],
    [scripts],
  );

  return (
    <div className={classNames('row', formStyles.sectionPadding)}>
      <div className={FORM_SECTION_CLASSES}>
        <ControlledSelect
          control={control}
          label="Startup"
          horizontal={false}
          options={scriptOptions}
          name="inventoryStartupScript.uid"
          loading={isLoading}
        />
        <ControlledSelect
          control={control}
          label="Custom"
          horizontal={false}
          options={scriptOptions}
          name="inventoryCustomScript.uid"
          loading={isLoading}
        />
        <ControlledSelect
          control={control}
          label="Shutdown"
          horizontal={false}
          options={scriptOptions}
          name="inventoryShutdownScript.uid"
          loading={isLoading}
        />
      </div>
    </div>
  );
}
