export const isUserPermitted = (
  currentUserRoles: UserRole[],
  requiredRoles: UserRoles[],
): boolean => {
  if (requiredRoles.length === 0) {
    return true;
  }

  if (currentUserRoles === undefined || currentUserRoles.length === 0) {
    return false;
  }

  return currentUserRoles.some((definedRoles) =>
    requiredRoles.includes(definedRoles.role),
  );
};
