import React, { ReactElement } from 'react';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../components/Modals';
import { DemoTemplateForm } from '../DemoTemplateForm';
import styles from '../DemoTemplates.module.css';
import { prepDemoTemplateForEdit } from '../../utils';

interface EditDemoTemplateModalProps {
  addSelectedArchitecture: ActionCreatorWithPayload<Architecture, string>;
  allArchitectures: Architecture[];
  colsContent: InfoPanelData[];
  showEditModal: boolean;
  selectedArchitectures?: Architecture[];
  removeArchitecture: ActionCreatorWithPayload<string, string>;
  updateDemoTemplate: RTKMutation<DemoTemplateUpdatePayload, DemoTemplate>;
  setSelectedDemoTemplate: ActionCreatorWithOptionalPayload<
    DemoTemplate | undefined,
    string
  >;
  templateToEdit: DemoTemplate;
  isUpdating: boolean;
}

export function EditDemoTemplateModal({
  addSelectedArchitecture,
  allArchitectures,
  setSelectedDemoTemplate,
  showEditModal,
  selectedArchitectures,
  removeArchitecture,
  colsContent,
  updateDemoTemplate,
  isUpdating,
  templateToEdit,
}: EditDemoTemplateModalProps): ReactElement {
  const { t } = useTranslation();

  const handleSubmit = (demoTemplate: DemoTemplate) => {
    updateDemoTemplate({
      demoTemplate: demoTemplate,
      templateId: demoTemplate.uid,
    });
  };

  return (
    <Modal
      className={styles.root}
      clickScreenToClose={true}
      show={showEditModal}
      onClose={() => setSelectedDemoTemplate(undefined)}
      title={t('templateManager.demoTemplates.form.titles.edit')}
      headerAlignment="center"
    >
      <div className={styles.container}>
        <DemoTemplateForm
          isEdit={true}
          addSelectedArchitecture={addSelectedArchitecture}
          currentDc={templateToEdit.datacenter!}
          removeArchitecture={removeArchitecture}
          selectedArchitectures={selectedArchitectures}
          allArchitectures={allArchitectures}
          colsContent={colsContent}
          isUpdating={isUpdating}
          onClose={() => setSelectedDemoTemplate(undefined)}
          templateToEdit={prepDemoTemplateForEdit(templateToEdit)}
          submitAction={handleSubmit}
        />
      </div>
    </Modal>
  );
}
