import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { getHwInfoFromNodeDetails } from './utils';
import { HwInfoContainerProps } from './container';
import { HWInfo } from '.';

type ConnectedExpoHWInfoProps = HwInfoContainerProps & FetchExpoSessionArgs;

export function ConnectedExpoHWInfo({
  engagementUid,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedExpoHWInfoProps): ReactElement {
  const { hWInfoData } = expoSessionApi.endpoints.getExpoSession.useQueryState(
    { engagementUid, sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        hWInfoData: getHwInfoFromNodeDetails(
          data?.diagram.nodes.filter(
            (node) => node.uid === nodeUid,
          )[0] as SessionHwNode,
        ),
      }),
    },
  );

  return <HWInfo data={hWInfoData || {}} />;
}
