import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLoader } from '../../../../../../../../components/Table/TableLoader';
import formStyles from '../../../../../EditFormStyles.module.css';
import { NetworkingConfirmDeleteModal } from './components/NetworkingConfirmDeleteModal';
import { NetworkingForm } from './components/NetworkingForm';
import { NetworkingTable } from './components/NetworkingTable';
import { NetworkingProps } from './container';
import { DhcpConfig } from './components/DhcpConfig';
import { EditNetworkingForm } from './components/EditNicForm';

export function Networking({
  addErrorToast,
  isErrorLoadingNicTypes,
  isErrorLoadingNetworks,
  networkingToEdit,
  nicToEdit,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  networks,
  nicTypes,
  isLoadingLimits,
  isLoadingNicTypes,
  isLoadingNetworks,
  limits,
  setNicToEdit,
  interfaceToConfirmDelete,
  setInterfaceToConfirmDelete,
  updateVMNetworkInterface,
  vmToEdit,
}: NetworkingProps): ReactElement | null {
  const { t } = useTranslation();
  const [hasAssignDhcp, setHasAssignDhcp] = useState(false);

  const onDeleteClick = useCallback(
    (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => {
      return networkingToEdit && networkingToEdit?.length <= 1
        ? addErrorToast({
            message: t('virtualMachines.edit.networking.minLimit'),
          })
        : vmNetworkInterfaceToEdit.inUse
        ? setInterfaceToConfirmDelete(vmNetworkInterfaceToEdit)
        : deleteVMNetworkInterface(vmNetworkInterfaceToEdit);
    },
    [
      addErrorToast,
      deleteVMNetworkInterface,
      networkingToEdit,
      setInterfaceToConfirmDelete,
      t,
    ],
  );

  const onConfirmDeleteClick = useCallback(
    (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => {
      deleteVMNetworkInterface(vmNetworkInterfaceToEdit);
    },
    [deleteVMNetworkInterface],
  );

  const onEditClick = useCallback(
    (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => {
      setNicToEdit(vmNetworkInterfaceToEdit);
    },
    [setNicToEdit],
  );

  useEffect(() => {
    const interfacesWithAssignDhcp = networkingToEdit?.some(
      (nic) => nic.assignDhcp,
    );
    setHasAssignDhcp(interfacesWithAssignDhcp || false);
  }, [networkingToEdit, vmToEdit]);

  if (
    isLoadingLimits ||
    isLoadingNicTypes ||
    isLoadingNetworks ||
    isErrorLoadingNetworks ||
    isErrorLoadingNicTypes
  ) {
    return <TableLoader />;
  }

  return (
    <section className={formStyles.sectionPadding}>
      <>
        {!nicToEdit && limits && (
          <NetworkingForm
            addVMNetworkInterface={addVMNetworkInterface}
            networks={networks || []}
            nicTypes={nicTypes || []}
            addErrorToast={addErrorToast}
            totalNics={networkingToEdit?.length || 0}
            vmNetworkInterfaces={networkingToEdit}
            vmToEdit={vmToEdit}
            limits={limits}
          />
        )}
        {networkingToEdit && networkingToEdit.length === 0 && (
          <Alert colour="info">
            {t('virtualMachines.edit.networking.empty.alertText')}
          </Alert>
        )}
        {nicToEdit && limits && (
          <EditNetworkingForm
            limits={limits}
            networks={networks || []}
            nicTypes={nicTypes || []}
            nicToEdit={nicToEdit}
            setNicToEdit={setNicToEdit}
            updateVMNetworkInterface={updateVMNetworkInterface}
            vmNetworkInterfaces={networkingToEdit}
            vmToEdit={vmToEdit}
          />
        )}
        {hasAssignDhcp && !nicToEdit && <DhcpConfig />}
        {networkingToEdit &&
          !nicToEdit &&
          limits &&
          networkingToEdit.length > 0 && (
            <NetworkingTable
              vmNetworkInterfaces={networkingToEdit || []}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              isLoading={isLoadingNicTypes || isErrorLoadingNicTypes}
            />
          )}
        {interfaceToConfirmDelete && (
          <NetworkingConfirmDeleteModal
            interfaceToConfirmDelete={interfaceToConfirmDelete}
            onConfirm={onConfirmDeleteClick}
            setInterfaceToConfirmDelete={setInterfaceToConfirmDelete}
          />
        )}
      </>
    </section>
  );
}
