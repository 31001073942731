interface BuildAccessRoleAssignmentPayloadProps {
  formData: AccessRoleAssignmentFormData;
  topologyUid: TopologyUid['uid'];
}

export const buildAccessRoleAssignmentPayload = ({
  formData,
  topologyUid,
}: BuildAccessRoleAssignmentPayloadProps): AccessRoleAssignmentPostPayload => {
  const { email } = formData;
  return {
    accessRole: 'EDITOR',
    email: email,
    topology: { uid: topologyUid },
  };
};
