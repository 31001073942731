import { useCallback, useState } from 'react';

export interface UseToggleGrid {
  showGrid: boolean;
  toggleGrid: VoidFunction;
}

export function useToggleGrid(): UseToggleGrid {
  const [showGrid, setShowGrid] = useState(true);

  const toggleGrid = useCallback(() => {
    setShowGrid((prevShowGrid) => !prevShowGrid);
  }, []);

  return {
    showGrid,
    toggleGrid,
  };
}
