import React, { ReactElement } from 'react';
import styles from './index.module.css';
import { NodeEditButton } from './components/EditButton';
import { NodeDeleteButton } from './components/DeleteButton';

export type NodeActionButtonsProps = {
  name: string;
  onDeleteClick: VoidFunction;
  onEditClick: VoidFunction;
};

export const NodeActionButtons = ({
  name,
  onDeleteClick,
  onEditClick,
}: NodeActionButtonsProps): ReactElement => (
  <div className={styles.root}>
    <NodeEditButton name={name} onClick={onEditClick} />
    <NodeDeleteButton name={name} onClick={onDeleteClick} />
  </div>
);
