import { useEffect } from 'react';

type useStopGetAllVmStatusApiCallsOnUnmountArgs = {
  stopGetAllVmStatusesForSessionInventory: VoidFunction;
};

export function useStopGetAllVmStatusesApiCallsOnUnmount({
  stopGetAllVmStatusesForSessionInventory,
}: useStopGetAllVmStatusApiCallsOnUnmountArgs): void {
  useEffect(() => {
    return () => {
      stopGetAllVmStatusesForSessionInventory();
    };
  }, [stopGetAllVmStatusesForSessionInventory]);
}
