import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './AssetCount.module.css';

export type AssetCountProps = {
  filteredCount: number;
  totalCount: number;
};

export const AssetCount = ({
  filteredCount,
  totalCount,
}: AssetCountProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        [styles.filteredCount]: filteredCount !== totalCount,
      })}
    >
      {`${filteredCount}
      ${
        filteredCount === 1
          ? t('sessionsInventory.assetCount.totalAsset')
          : t('sessionsInventory.assetCount.totalAssets')
      }
      ${
        filteredCount !== totalCount
          ? t('sessionsInventory.assetCount.matchedTheSearchText')
          : ''
      }
      `}
    </div>
  );
};
