import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components';
import { FullScreenSpinner } from '../../../../components/Loaders/FullScreenSpinner';
import { IntegrationInUseInfoModalProps } from './container';
import { IntegrationsInUseInfoModalTable } from './components/IntegrationsInUseInfoModalTable';
import { IntegrationInUseCloseButton } from './components/IntegrationInUseCloseButton';

export function IntegrationInUseInfoModal({
  integrationToDisplayUid,
  clearIntegrationToDisplayUid,
  integrationToDisplay,
  isLoading,
}: IntegrationInUseInfoModalProps): ReactElement {
  const { t } = useTranslation();
  return integrationToDisplayUid ? (
    <Modal
      title={t('integrations.info.uses.title')}
      onClose={() => clearIntegrationToDisplayUid()}
      clickScreenToClose={true}
      show={true}
      size="fluid"
    >
      {isLoading ? (
        <FullScreenSpinner />
      ) : (
        <div>
          <IntegrationsInUseInfoModalTable
            topologiesUsingIntegration={integrationToDisplay}
          />
          <IntegrationInUseCloseButton
            onClose={() => clearIntegrationToDisplayUid()}
          />
        </div>
      )}
    </Modal>
  ) : (
    <></>
  );
}
