import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../components/Accordion';
import { AdditionalResources } from './components/AdditionalResources';
import { DocumentsContainer } from './components/Documents/container';

export function DocumentationPanel(): ReactElement {
  const { t } = useTranslation();

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <DocumentsContainer />,
        title: t('sessions.panels.documentation.documentation.sectionHeader'),
      },
      {
        children: <AdditionalResources />,
        title: t(
          'sessions.panels.documentation.additionalResources.sectionHeader',
        ),
      },
    ],
    [t],
  );

  return <Accordion rows={accordionRows} size="small" />;
}
