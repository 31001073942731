import { useLocation, useParams } from 'react-router-dom';
import React from 'react';

interface UseURLParamsProps {
  queryParams: string[];
}

interface UseURLParams {
  path: StringRecord;
  query: StringRecord;
}

function useURLParams({ queryParams }: UseURLParamsProps): UseURLParams {
  const pathParamObj = useParams<StringRecord>();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const queryParamObj: StringRecord = Object.assign(
    {},
    ...queryParams!.map((param) => ({ [param]: query.get(param) })),
  );

  return { path: pathParamObj, query: queryParamObj };
}

export function useSessionUserContext(): SessionUserContext {
  const {
    path: { sessionUid = '' },
    query: { versionUid, engagementId },
  } = useURLParams({
    queryParams: ['versionUid', 'engagementId'],
  });
  return {
    engagementUid: engagementId ?? '',
    sessionUid,
    versionUid: versionUid ?? '',
  };
}
