import React, { ReactElement } from 'react';
import { orderBy } from 'lodash';
import classnames from 'classnames';
import { CustomLabel } from '../CustomLabel';
import styles from './CustomLabelPicker.module.css';

export type CustomLabelPickerProps = {
  className?: string;
  labels: CustomLabel[];
  onAdd: (label: CustomLabel) => void;
};

export const CustomLabelPicker = ({
  className,
  labels,
  onAdd,
}: CustomLabelPickerProps): ReactElement => {
  return (
    <div
      className={classnames(styles.pickerContainer, className)}
      data-testid="custom-label-picker"
    >
      {labels &&
        orderBy(labels, ['name']).map((label) => (
          <CustomLabel
            key={label.uid}
            label={label}
            onAdd={() => onAdd(label)}
          />
        ))}
    </div>
  );
};
