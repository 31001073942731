import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import classNames from 'classnames';
import { extendSessionSchema } from '../../../../../../../../validation';
import {
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../../../../../components/Form';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import styles from '../../../Modals.module.css';
import { ExtendSessionFormProps } from './container';

export function ExtendSessionForm({
  setShowExtendSessionModal,
}: ExtendSessionFormProps): ReactElement {
  const { t } = useTranslation();
  const { errors, control, formState } = useForm<SessionExtendFields>({
    defaultValues: { newEndDate: '', newEndTime: '' },
    mode: 'all',
    resolver: yupResolver(extendSessionSchema),
  });

  const { isDirty, isValid } = formState;

  return (
    <form>
      <ControlledTextfield<SessionExtendFields>
        control={control}
        error={errors?.newEndDate?.message}
        label={t('sessions.forms.extend.newEndDate.label')}
        labelClass="col-3"
        name="newEndDate"
        required={true}
      />
      <ControlledTextarea<SessionExtendFields>
        control={control}
        error={errors?.newEndTime?.message}
        label={t('sessions.forms.extend.newEndTime.label')}
        labelClass="col-3"
        name="newEndTime"
        rows={4}
        required={true}
      />
      <div className={classNames(styles.buttonGroup, 'dbl-margin-top')}>
        <Button
          size="large"
          colour="ghost"
          type="button"
          onClick={() => setShowExtendSessionModal(false)}
        >
          {t('buttons.cancel')}
        </Button>
        <PrimaryButton
          disabled={!isDirty || !isValid}
          testId="general-form-submit"
          loading={false}
          type="submit"
          colour="success"
        >
          {t('buttons.confirm')}
        </PrimaryButton>
      </div>
    </form>
  );
}
