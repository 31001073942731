import { useEffect } from 'react';
import * as d3 from 'd3';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { HEADER_OFFSET_VAR } from '../../../constants';
import {
  calculateNewX1andY1andX2andY2,
  updateTwoHandleLineNode,
} from '../utils';
import { x, y } from '../../../d3';
interface UseDrag {
  isReadOnly: boolean;
  display: DiagramLineDisplay;
  uid: string;
  nodeRef: React.RefObject<HTMLDivElement>;
  isSnapToGridEnabled?: boolean;
}

export function useDrag({
  display,
  isReadOnly,
  nodeRef,
  uid,
  isSnapToGridEnabled,
}: UseDrag): void {
  const [updateNode] = useUpdateNodeMutation();
  useEffect(() => {
    if (isReadOnly) return;
    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLSpanElement, DiagramDisplay>()
      .on('drag', function ({ dx, dy }) {
        if (!currentRef) return;
        const transform = d3.zoomTransform(this);

        const leftHandle = currentRef.childNodes[1]
          .childNodes[0] as HTMLDivElement;

        const rightHandle = currentRef.childNodes[1]
          .childNodes[1] as HTMLDivElement;

        const leftHandleMatrix = new WebKitCSSMatrix(
          window.getComputedStyle(leftHandle).transform,
        );

        const rightHandleMatrix = new WebKitCSSMatrix(
          window.getComputedStyle(rightHandle).transform,
        );

        d3.select(leftHandle).attr(
          'style',
          `transform-origin: left top; transform: translate(${
            leftHandleMatrix.m41 + dx
          }px, ${leftHandleMatrix.m42 + dy}px) scale(${transform.k}`,
        );

        d3.select(rightHandle).attr(
          'style',
          `transform-origin: left top; transform: translate(${
            rightHandleMatrix.m41 + dx
          }px, ${rightHandleMatrix.m42 + dy}px) scale(${transform.k}`,
        );

        const line = `[data-line-component-uid="${uid}"]`;

        const [headerHeightStr] = getComputedStyle(document.body)
          .getPropertyValue(HEADER_OFFSET_VAR)
          .split('px');

        const headerHeight = Number(headerHeightStr);

        const leftNodeBounds = leftHandle.getBoundingClientRect();

        const rightNodeBounds = rightHandle.getBoundingClientRect();

        const xMidLeftNode = leftNodeBounds.x + leftNodeBounds.width / 2;

        const yMidLeftNode =
          leftNodeBounds.y + leftNodeBounds.height / 2 - headerHeight;

        const xMidRightNode = rightNodeBounds.x + rightNodeBounds.width / 2;

        const yMidRightNode =
          rightNodeBounds.y + rightNodeBounds.height / 2 - headerHeight;

        d3.select<HTMLDivElement, DiagramDisplay>(line)
          .attr('x1', xMidLeftNode)
          .attr('y1', yMidLeftNode)
          .attr('x2', xMidRightNode)
          .attr('y2', yMidRightNode);
      })

      .on('end', function (event) {
        if (!currentRef) return;

        const transform = d3.zoomTransform(this);

        const leftHandle = currentRef.childNodes[1]
          .childNodes[0] as HTMLDivElement;

        const rightHandle = currentRef.childNodes[1]
          .childNodes[1] as HTMLDivElement;

        const leftHandleMatrix = new WebKitCSSMatrix(
          window.getComputedStyle(leftHandle).transform,
        );

        const rightHandleMatrix = new WebKitCSSMatrix(
          window.getComputedStyle(rightHandle).transform,
        );

        const { x1, y1, x2, y2 } = calculateNewX1andY1andX2andY2({
          display,
          event,
          isSnapToGridEnabled,
          leftHandleMatrix,
          rightHandleMatrix,
          transform,
          uid,
          updateNode,
          x,
          y,
        });

        updateTwoHandleLineNode({
          display,
          uid,
          updateNode,
          x1,
          x2,
          y1,
          y2,
        });
      });

    if (currentRef) {
      d3.select<HTMLElement, DiagramDisplay>(
        `[data-selector-component-uid="${uid}"]`,
      ).call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLElement, DiagramDisplay>(
          `[data-selector-component-uid="${uid}"]`,
        ).on('mousedown.drag', null);
      }
    };
  }, [display, isReadOnly, nodeRef, updateNode, uid, isSnapToGridEnabled]);
}
