import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  clearIntegrationToDisplayUid,
  integrationSelectors,
} from '../../../../redux/integrations/slice';
import { useGetIntegrationTopologiesQuery } from '../../../../redux/integrations/api';
import { IntegrationInUseInfoModal } from '.';

function IntegrationInUseInfoModalContainer({
  integrationToDisplayUid,
  clearIntegrationToDisplayUid,
}: IntegrationInUseInfoModalContainerProps): ReactElement {
  const { isLoading, isFetching, isError, data } =
    useGetIntegrationTopologiesQuery({
      integrationUid: integrationToDisplayUid ? integrationToDisplayUid : '',
    });

  return (
    <IntegrationInUseInfoModal
      isLoading={isLoading || isFetching || isError}
      integrationToDisplay={data?.topologies}
      integrationToDisplayUid={integrationToDisplayUid}
      clearIntegrationToDisplayUid={clearIntegrationToDisplayUid}
    />
  );
}

type IntegrationInUseInfoModalContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  integrationToDisplayUid:
    integrationSelectors.getIntegrationToDisplayUid(state),
});

const mapDispatchToProps = {
  clearIntegrationToDisplayUid,
};

export type IntegrationInUseInfoModalProps =
  IntegrationInUseInfoModalContainerProps & {
    isLoading: boolean;
    integrationToDisplay?: Topology[];
  };

export const ConnectedIntegrationInUseInfoModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationInUseInfoModalContainer);
