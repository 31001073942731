import React, { ReactElement } from 'react';
import { useParams } from 'react-router';
import { useGetTopologyLimitsQuery } from '../../../../redux/configuration/topologyLimits/api';
import { TopologyLimitsToolBar } from '.';

export const ConnectedTopologyLimitsToolBar = (): ReactElement => {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    data,
    isLoading: isLoadingLimits,
    isFetching,
    isError,
  } = useGetTopologyLimitsQuery(topologyUid);

  return (
    <TopologyLimitsToolBar
      topologyLimits={data}
      isLoading={isLoadingLimits || isFetching || isError}
    />
  );
};

export type TopologyLimitsToolBarProps = {
  topologyLimits?: TopologyLimits;
  isLoading: boolean;
};
