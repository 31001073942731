import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PanelTableLoader } from '../../../../PanelTableLoader';
import { PanelGrid } from '../../../PanelGrid';
import { EndpointKitHeader } from './components/EndpointKitHeader';
import { EndpointKitsProps } from './container';

export function EndpointKits({
  endpoints,
  isLoading,
  isError,
}: EndpointKitsProps): ReactElement {
  const { t } = useTranslation();

  const buildGridData = ({
    dataSSID,
    name,
    passphrase,
    voiceSSID,
  }: EndpointKit): PanelGridDataItem[] => {
    return [
      {
        key: `voiceSSID${name}`,
        name: t('sessions.panels.info.endpointKits.voiceSSID'),
        value: voiceSSID,
      },
      {
        key: `dataSSID${name}`,
        name: t('sessions.panels.info.endpointKits.dataSSID'),
        value: dataSSID,
      },
      {
        key: `passphrase${name}`,
        name: t('sessions.panels.info.endpointKits.passphrase'),
        value: passphrase,
      },
    ];
  };

  if (isLoading || isError) {
    return <PanelTableLoader />;
  }

  if (!endpoints || !endpoints.length) {
    return (
      <Alert>
        {t('sessions.panels.info.endpointKits.noEndpointKitsConfigured')}
      </Alert>
    );
  }

  return (
    <>
      {endpoints.map(function (endpoint) {
        return (
          <div key={endpoint.name}>
            <EndpointKitHeader status={endpoint.status} name={endpoint.name} />
            <PanelGrid
              key={endpoint.name}
              data={buildGridData(endpoint)}
              wideCol="value"
              nameBold={true}
              nameHeader={t('sessions.panels.info.endpointKits.type')}
              valueHeader={t('sessions.panels.info.endpointKits.networkName')}
            />
          </div>
        );
      })}
    </>
  );
}
