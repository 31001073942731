import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { CellProps } from 'react-table';
import { MainRoutes } from '../../../../constants/routes';

export function LinkToTopology({
  row: { original },
  value,
}: CellProps<Topology>): ReactElement {
  return (
    <Link
      to={MainRoutes.Edit.replace(':uid', original.uid)}
      className="truncate-three-lines"
    >
      {value}
    </Link>
  );
}
