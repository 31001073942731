import React, { ReactElement } from 'react';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { GridEditor } from '../../../../../../../../components/GridEditor';
import { IpAddressesTableNoDataAlert } from '../../../../../../SessionInventory/components/SessionInventoryVirtualMachines/components/IpAddressCellRenderer/components/IpAddressesTableNoDataAlert';

type IpAddressesTableProps = {
  ipAddresses: SessionInventoryVirtualMachineDataIpAddress[];
};

export const IpAddressesTable = ({
  ipAddresses,
}: IpAddressesTableProps): ReactElement => {
  const { t } = useTranslation();

  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'name',
      flex: 1,
      headerName: t(
        'sessionsInventory.virtualMachines.ipAddressesTable.headings.adapterName',
      ),
      minWidth: 70,
    },
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'ipAddress',
      flex: 1,
      headerName: t(
        'sessionsInventory.virtualMachines.ipAddressesTable.headings.ipAddress',
      ),
      minWidth: 70,
    },
  ];

  return ipAddresses.length > 0 ? (
    <GridEditor columns={columns} data={ipAddresses} />
  ) : (
    <IpAddressesTableNoDataAlert />
  );
};
