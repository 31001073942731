import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetTelephonyItemsQuery } from '../../../../redux/configuration/telephony/api';
import { PSTNValueLoader } from './PSTNValueLoader';
import { extractPstnItemFromTelephonyItems } from './utils';
import { TelephonyDeleteContainer } from './TelephonyDelete/container';
import { InventoryTelephonyContainer } from './InventoryTelephony/container';

export function TelephonyContainer(): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const { isLoading, isFetching, isError, data } =
    useGetTelephonyItemsQuery(topologyUid);
  if (data === undefined || isLoading || isFetching || isError) {
    return <PSTNValueLoader />;
  }

  const pstnTelephonyItem = extractPstnItemFromTelephonyItems(
    data.telephonyItems,
  ) as TelephonyItem;

  if (pstnTelephonyItem) {
    return (
      <TelephonyDeleteContainer telephonyItemUid={pstnTelephonyItem.uid} />
    );
  }

  return <InventoryTelephonyContainer />;
}
