import { Checkbox, FormGroup } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface NetworkRoutedCheckboxProps {
  isChecked: boolean;
  isDisabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function NetworkRoutedCheckbox({
  isChecked,
  isDisabled,
  onChange,
}: NetworkRoutedCheckboxProps): ReactElement {
  const { t } = useTranslation();

  return (
    <FormGroup inline={true}>
      <Checkbox
        label={t('networks.form.routedByVpodGw')}
        checked={isChecked}
        onChange={onChange}
        data-testid="network-form-routed-checkbox"
        disabled={isDisabled}
      />
    </FormGroup>
  );
}
