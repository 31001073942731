import * as d3 from 'd3';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetLogicalConnections } from '../../../../../redux/logicalConnections/slice';
import { useConnection } from '../../Connections/hooks/use-connection';
import {
  handleMouseDownOnNodesCanvas,
  makeTempGuideLineFollowMouse,
  resetTempGuideLine,
} from '../utils/utils';
import { TEMP_LOGICAL_GUIDE_UID } from '../../../constants';

export interface UseLogicalConnectionGuideLineHelper
  extends Omit<BaseNodeProps, 'uid'> {
  isAddingLogicalConnection: boolean;
  isCreatingLogicalConnection: boolean;
  isDrawingLogicalConnectionFrom?: string;
  uid?: string;
  nodesCanvas: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>;
}

export function useLogicalConnectionGuideLineHelper({
  isReadOnly,
  isAddingLogicalConnection,
  isCreatingLogicalConnection,
  isDrawingLogicalConnectionFrom,
  uid,
  nodesCanvas,
}: UseLogicalConnectionGuideLineHelper): void {
  const dispatch = useDispatch();
  const { redrawNodeConnections } = useConnection();

  useEffect(() => {
    if (
      isReadOnly ||
      !isAddingLogicalConnection ||
      isCreatingLogicalConnection
    ) {
      return;
    }

    if (isDrawingLogicalConnectionFrom !== uid) return;

    nodesCanvas.on('mousedown', function (event) {
      if (event.target !== event.currentTarget) {
        return;
      }

      handleMouseDownOnNodesCanvas({
        isCreatingLogicalConnection,
        isDrawingLogicalConnectionFrom,
        redrawNodeConnections,
        resetLogicalConnections: () => dispatch(resetLogicalConnections()),
        uid,
      });
    });

    nodesCanvas.on('mousemove', function (event) {
      const [x, y] = d3.pointer(event);
      makeTempGuideLineFollowMouse(
        x,
        y,
        d3.select(`[data-uid="${TEMP_LOGICAL_GUIDE_UID}"]`),
      );
    });

    nodesCanvas.on('mouseleave', function () {
      dispatch(resetLogicalConnections());
      nodesCanvas.on('mousemove', null);
      resetTempGuideLine(d3.select(`[data-uid="${TEMP_LOGICAL_GUIDE_UID}"]`));
      redrawNodeConnections(uid!);
    });

    return () => {
      nodesCanvas.on('mouseleave', null);
      nodesCanvas.on('mousemove', null);
      nodesCanvas.on('mousedown', null);
    };
  }, [
    dispatch,
    isAddingLogicalConnection,
    isCreatingLogicalConnection,
    isDrawingLogicalConnectionFrom,
    isReadOnly,
    nodesCanvas,
    redrawNodeConnections,
    uid,
  ]);
}
