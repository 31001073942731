import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  setExpired,
  setShowSaveAndEndSessionModal,
} from '../../../../../../../../redux/views/session-view/session/slice';
import {
  sessionApi,
  useSaveAndEndSessionMutation,
} from '../../../../../../../../redux/views/session-view/session/api/api';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { SaveAndEndForm } from '.';

function SaveAndEndContainer({
  setExpired,
  setShowSaveAndEndSessionModal,
}: SaveAndEndSessionFormProps): ReactElement {
  const [saveAndEndSession, { isLoading }] = useSaveAndEndSessionMutation();
  const { sessionUid, versionUid } = useSessionUserContext();
  const { description, name } = sessionApi.endpoints.getSession.useQueryState(
    { sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        description: data?.sessionDetails.description,
        name: data?.sessionDetails.name,
      }),
    },
  );

  return (
    <SaveAndEndForm
      description={description || ''}
      isLoading={isLoading}
      name={name || ''}
      saveAndEndSession={saveAndEndSession}
      setExpired={setExpired}
      setShowSaveAndEndSessionModal={setShowSaveAndEndSessionModal}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}
const mapDispatchToProps = {
  setExpired,
  setShowSaveAndEndSessionModal,
};

export type SaveAndEndSessionFormProps = typeof mapDispatchToProps;

export const ConnectedSaveAndEndForm = connect(
  null,
  mapDispatchToProps,
)(SaveAndEndContainer);
