import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  addLabel,
  integrationSelectors,
  removeLabel,
} from '../../../../redux/integrations/slice';
import { useGetIntegrationLicensesQuery } from '../../../../redux/integrations/api';
import LicenseForm from './LicenseForm';
import { LicenseFormSkeleton } from './LicenseFormSkeleton';

const LicenseFormContainer = ({
  integrationToSave,
  addLabel,
  removeLabel,
}: CreateIntegrationContainerProps): ReactElement => {
  const {
    data: fetchedIntegrationLicenses,
    isLoading,
    isError,
  } = useGetIntegrationLicensesQuery(undefined);
  return !fetchedIntegrationLicenses || isLoading || isError ? (
    <LicenseFormSkeleton />
  ) : (
    <LicenseForm
      integrationToSave={integrationToSave}
      addLabel={addLabel}
      removeLabel={removeLabel}
      labels={fetchedIntegrationLicenses.integrationLicenses}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  integrationToSave: integrationSelectors.getIntegrationToSave(state),
});

const mapDispatchToProps = {
  addLabel,
  removeLabel,
};

type CreateIntegrationContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedLicenseForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LicenseFormContainer);
