import React, { ReactElement } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../components';

export interface EndSessionModalProps {
  endSession: VoidFunction;
  isLoading: boolean;
  isSuccess: boolean;
  setExpired: ActionCreatorWithPayload<boolean, string>;
  setShowEndSessionModal: ActionCreatorWithPayload<boolean, string>;
  showEndSessionModal: boolean;
}

export function EndSessionModal({
  endSession,
  setShowEndSessionModal,
  showEndSessionModal,
  setExpired,
  isSuccess,
  isLoading,
}: EndSessionModalProps): ReactElement {
  const { t } = useTranslation();

  const handleSuccess = () => {
    setExpired(true);
    setShowEndSessionModal(false);
  };

  isSuccess && handleSuccess();

  return (
    <ConfirmModal
      heading={t('sessions.modals.endSession.heading')}
      subHeading={t('sessions.modals.endSession.subHeading')}
      loading={isLoading}
      onCancel={() => setShowEndSessionModal(false)}
      onClose={() => setShowEndSessionModal(false)}
      onConfirm={() => endSession()}
      confirmButtonLabel={t('buttons.confirm')}
      cancelButtonLabel={t('buttons.cancel')}
      show={showEndSessionModal}
      size="small"
      testId="end-session-confirm-modal"
    />
  );
}
