import React, { ReactElement, useState } from 'react';
import { RowNode } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../components';
import styles from './DescriptionCellRenderer.module.css';

export const DescriptionCellRenderer = (props: RowNode): ReactElement => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const {
    data: { description, name, details },
  }: { data: SaasTemplate } = props;

  return (
    <>
      {description}{' '}
      <button
        className={styles.seeFullDetailsButton}
        onClick={() => setShow(true)}
      >
        {t('buttons.seeFullDetails')}
      </button>
      {show && (
        <Modal
          clickScreenToClose={true}
          show={true}
          onClose={() => setShow(false)}
          title={name}
          testId={`inventory-saas-templates-table-details-modal-${name}`}
          headerAlignment="left"
          size="large"
        >
          {details}
        </Modal>
      )}
    </>
  );
};
