import { DEFAULT_DELAY } from './constants';

export const objectEquals = <T extends ObjectRecord>(
  obj: T,
  obj2: T,
): boolean => JSON.stringify(obj) === JSON.stringify(obj2);

export const sortObjectKeys = <T extends ObjectRecord>(obj: T): T => {
  return Object.keys(obj)
    .sort()
    .reduce((result: ObjectRecord, key) => {
      result[key] = obj[key];
      return result;
    }, {}) as T;
};

export function delay(timer = DEFAULT_DELAY): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timer);
  });
}

export function deepClone<T extends ObjectRecord>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export const base64URLencode = (buffer: ArrayBuffer): string => {
  const array: number[] = Array.from(new Uint8Array(buffer));
  return btoa(String.fromCharCode.apply(null, array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

export const generateRandomString = (): string => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    '',
  );
};

export const sha256 = async (plain: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return await window.crypto.subtle.digest('SHA-256', data);
};

export const linkToNewTab = (href: string): void => {
  window.open(href, '_blank') || window.location.replace(href);
};

export const extractIdFromUrl = (href: string): string => {
  const urlData = href.split('/');
  const id = urlData[urlData.length - 1];
  return id;
};
