import { Button } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './Attachment.module.css';

interface AttachmentProps {
  sessionDocument: SessionDocument;
  getInternalDocument: () => void;
}

export function Attachment({
  sessionDocument,
  getInternalDocument,
}: AttachmentProps): ReactElement {
  return (
    <li>
      <Button
        className={styles.downloadButton}
        colour="link"
        type="button"
        onClick={getInternalDocument}
      >
        {sessionDocument.name}
      </Button>
    </li>
  );
}
