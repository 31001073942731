import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface VideoState {
  videoToPlay?: VideoToPlay;
}

export const initialState: VideoState = {
  videoToPlay: undefined,
};

export const videoSlice = createSlice({
  initialState,
  name: 'video',
  reducers: {
    setVideoToPlay: (state, action: PayloadAction<VideoToPlay | undefined>) => {
      state.videoToPlay = action.payload;
    },
  },
});

export const {
  reducer: videoReducer,
  actions: { setVideoToPlay },
} = videoSlice;

const getVideoToPlay = (state: RootState) => state.video;

export const videoSelectors = {
  getVideoToPlay: createSelector(
    getVideoToPlay,
    ({ videoToPlay }) => videoToPlay,
  ),
};
