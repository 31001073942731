import React, { ReactElement, useEffect } from 'react';
import { PanelTableLoader } from '../../PanelTableLoader';
import { IntegrationPanelInfo } from './components/IntegrationPanelInfo';

type IntegrationPanelProps = {
  sessionIntegrationItems?: SessionIntegrationItem[];
  isLoading: boolean;
  isError: boolean;
  integrationType: IntegrationType;
  onIntegrationTypeChange: () => void;
};

export function IntegrationPanel({
  isLoading,
  sessionIntegrationItems,
  isError,
  integrationType,
  onIntegrationTypeChange,
}: IntegrationPanelProps): ReactElement {
  useEffect(() => {
    onIntegrationTypeChange();
  }, [integrationType, onIntegrationTypeChange]);
  return !sessionIntegrationItems || isLoading || isError ? (
    <PanelTableLoader />
  ) : (
    <IntegrationPanelInfo
      sessionIntegrationItems={sessionIntegrationItems}
      integrationType={integrationType}
    />
  );
}
