import React, { ReactElement } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../components/FormUnsavedChangesPrompt';
import { HelpButton } from '../../../../components/HelpButton';
import { PageHeading } from '../../../../components/PageHeading';
import { REMOTE_ACCESS_HELP_LINKS } from '../../../../constants/user-guide';
import { useLeaveFormPrompt } from '../../hooks/useLeaveFormPrompt';
import { RemoteAccessProps } from './container';
import { RemoteAccessForm } from './RemoteAccessForm';

export function RemoteAccess({
  data,
  isError,
  isFetching,
  isUpdating,
  updateRemoteAccess,
}: Required<RemoteAccessProps>): ReactElement {
  const { t } = useTranslation();

  const formMethods = useForm<RemoteAccessFormData>({
    defaultValues: {
      anyconnectEnabled: data.anyconnectEnabled,
      endpointKitEnabled: data.endpointKitEnabled,
    },
    mode: 'all',
  });

  const handleUpdateClicked: SubmitHandler<RemoteAccessFormData> = async (
    formData,
  ) => {
    await updateRemoteAccess({
      topology: data.topology,
      uid: data.uid,
      ...formData,
    });
    formMethods.reset(formData);
  };

  const { dirtyFields, isDirty } = formMethods.formState;
  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <section className="half-padding-left">
      <FormProvider {...formMethods}>
        <PageHeading
          ctaBtn={
            <PrimaryButton
              onClick={formMethods.handleSubmit(handleUpdateClicked)}
              testId="remote-access-form-submit-btn"
              type="submit"
              colour="success"
              disabled={Object.keys(dirtyFields).length === 0}
              loading={isUpdating}
            >
              {t('buttons.update')}
            </PrimaryButton>
          }
          pageTitle={t('remoteAccess.name')}
        />
        {!isError && (
          <RemoteAccessForm
            control={formMethods.control}
            isFetching={isFetching}
          />
        )}
      </FormProvider>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <HelpButton links={REMOTE_ACCESS_HELP_LINKS} />
    </section>
  );
}
