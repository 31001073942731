import { Card } from 'dcloud-shared-ui';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { TILE_CLASSES } from '../constants';
import styles from './Tile.module.css';
import { TileProps } from './Tile';

export function InactiveTile({
  description,
  Image,
  title,
  className,
}: TileProps): ReactElement {
  return (
    <div className={classnames(styles.root, TILE_CLASSES, className)}>
      <Card className={classnames(styles.card, styles.inactivecard)}>
        <Image />
      </Card>
      <h3 className="dbl-margin-top base-margin-bottom">{title}</h3>
      <p
        className={classnames(
          styles.description,
          'text-weight-200 text-size-16',
        )}
      >
        {description}
      </p>
    </div>
  );
}
