import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox } from '../../../../../components';
import { FormUnsavedChangesPrompt } from '../../../../../components/FormUnsavedChangesPrompt';
import { PageHeadingWithCTA } from '../../../components/PageHeadingWithCTA/PageHeadingWithCTA';
import { useLeaveFormPrompt } from '../../../hooks/useLeaveFormPrompt';

interface PSTNServicesFormProps {
  defaultValue: boolean;
  onSubmit: () => void;
}
export function PSTNServicesForm({
  defaultValue,
  onSubmit,
}: PSTNServicesFormProps): ReactElement {
  const { t } = useTranslation();
  const { control, formState } = useForm({
    defaultValues: {
      telephonyCheckbox: defaultValue,
    },
    mode: 'all',
  });

  const { isDirty } = formState;

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <>
      <form>
        <PageHeadingWithCTA
          disableButton={Object.keys(formState.dirtyFields).length === 0}
          buttonLabel={t('buttons.save')}
          buttonAriaLabel="PSTN Save Button"
          buttonColor="success"
          onButtonClick={onSubmit}
          pageTitle={t('telephony.name')}
        />
        <ControlledCheckbox
          control={control}
          label={t('telephony.checkboxLabel')}
          id="telephonyCheckbox"
          testId="telephony-checkbox"
          defaultValue={defaultValue}
        />
      </form>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
}
