import { NavigationTabs } from 'dcloud-shared-ui';
import React, { ReactElement, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { VM_ADD_HELP_LINKS } from '../../../../../constants/user-guide';
import { HelpButton } from '../../../../../components/HelpButton';
import { ConfirmModal } from '../../../../../components';
import { RequestVmButton } from '../../components/VirtualMachineEntities/Add/components/RequestVmButton';
import { routes, VirtualMachinesAddRouter } from './VirtualMachinesAddRouter';
import { VirtualMachinesAddProps } from './container';

export function VirtualMachinesAdd({
  setShowRemoteAccessCountExceededModal,
  showRemoteAccessCountExceededModal,
}: VirtualMachinesAddProps): ReactElement {
  const { uid } = useParams<EditTopologyParams>();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  useEffect(() => {
    return () => {
      setShowRemoteAccessCountExceededModal(false);
    };
  }, [setShowRemoteAccessCountExceededModal]);

  return (
    <>
      <PageHeading
        pageTitle={t('virtualMachines.add.title')}
        withBackBtn={true}
        ctaBtn={<RequestVmButton />}
      />
      <NavigationTabs
        tabs={routes.map((tab) => ({
          ...tab,
          pathname: `${url.replace(':uid', uid)}${tab.pathname}`,
        }))}
      />
      <VirtualMachinesAddRouter />
      <HelpButton links={VM_ADD_HELP_LINKS} />
      <ConfirmModal
        onConfirm={() => setShowRemoteAccessCountExceededModal(false)}
        onCancel={() => setShowRemoteAccessCountExceededModal(false)}
        loading={false}
        onClose={() => setShowRemoteAccessCountExceededModal(false)}
        heading={t(
          'virtualMachines.remoteAccessCountWarning.adding.modal.heading',
        )}
        subHeading={t(
          'virtualMachines.remoteAccessCountWarning.adding.modal.subHeading',
        )}
        show={showRemoteAccessCountExceededModal}
        showCancel={false}
        confirmButtonLabel="Close"
      />
    </>
  );
}
