import classNames from 'classnames';
import { Button } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../Filters.module.css';

export type AccordionButtonProps = {
  isOpen: boolean;
  isApplyingFilters: boolean;
  setIsOpen: (val: boolean) => void;
  filtersCount: number;
};

export const AccordionButton = ({
  isOpen,
  isApplyingFilters,
  setIsOpen,
  filtersCount,
}: AccordionButtonProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Button
      testId="topologies-filters-btn"
      className="accordion__title btn btn--link"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span
        className={classNames(styles.heading, {
          [styles.headingOpen]: isOpen,
        })}
      >
        {t('topologies.filters.heading')}
      </span>
      <span className={styles.count}>
        {isApplyingFilters
          ? `(${t('topologies.filters.applying')})`
          : filtersCount
          ? `(${filtersCount} ${t('topologies.filters.applied')})`
          : null}
      </span>

      <span className="accordion__toggle icon-chevron-down"></span>
    </Button>
  );
};
