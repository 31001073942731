import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { getSessionInformationFromSession } from './utils';
import { SessionInformation } from '.';

export function ConnectedExpoSessionInformation({
  engagementUid,
  sessionUid,
  versionUid,
}: FetchExpoSessionArgs): ReactElement {
  const { sessionInformation, isError, isLoading, isFetching } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionInformation: getSessionInformationFromSession(data),
        }),
      },
    );

  return (
    <SessionInformation
      sessionInformation={sessionInformation}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
