import React, { ReactElement } from 'react';
import { buildVmIcons } from '../../utils/utils';
import { VmIconsFlexWrapper } from './components/FlexWrapper/VmIconsFlexWrapper';
import { OsIcon } from './components/OsIcon/OsIcon';

export type VmIconsProps = {
  asset?: VirtualMachine;
};

export function VmIcons({ asset }: VmIconsProps): ReactElement {
  return (
    <>
      <VmIconsFlexWrapper icons={buildVmIcons(asset)} />
      <OsIcon asset={asset} />
    </>
  );
}
