import React, { ReactElement } from 'react';
import { retrieveVisibleNodes } from '../../utils';
import { expoSessionApi } from '../../../../redux/views/session-view/session/api/expoApi';
import { NodesContainerProps } from './container';
import { Nodes } from '.';

type ConnectedExpoSessionNodesProps = NodesContainerProps &
  FetchExpoSessionArgs;

export function ConnectedExpoSessionNodes({
  isReadOnly,
  isAddingBox,
  isAddingLine,
  isAddingText,
  engagementUid,
  sessionUid,
  versionUid,
  isSnapToGridEnabled,
}: ConnectedExpoSessionNodesProps): ReactElement {
  const { data } = expoSessionApi.endpoints.getExpoSession.useQueryState({
    engagementUid,
    sessionUid,
    versionUid,
  });
  return (
    <Nodes
      isSnapToGridEnabled={isSnapToGridEnabled}
      nodes={retrieveVisibleNodes(data!.diagram.nodes)}
      isReadOnly={isReadOnly}
      isAddingBox={isAddingBox}
      isAddingLine={isAddingLine}
      isAddingText={isAddingText}
      isAddingLogicalConnection={false}
    />
  );
}
