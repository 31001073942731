import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SelectOption } from 'dcloud-shared-ui';
import { getFormIntegrationAvailabilityOptions } from '../../utils/integrations';
import { RootState } from '../../redux/store';
import {
  integrationSelectors,
  setFetchedInputsToEdit,
  setInputsToEdit,
} from '../../redux/integrations/slice';
import {
  useGetIntegrationQuery,
  useGetIntegrationsQuery,
  useGetSaasTemplateAvailabilityValuesQuery,
  useUpdateIntegrationMutation,
} from '../../redux/integrations/api';
import EditIntegrationSkeleton from './components/EditIntegrationSkeleton';
import { EditIntegration } from '.';

const EditIntegrationContainer = ({
  integrationToUpdate,
  setInputsToEdit,
  setFetchedInputsToEdit,
}: EditIntegrationContainerProps): ReactElement => {
  const [
    updateIntegration,
    { isLoading: isAddIntegrationLoading, isError: isAddIntegrationError },
  ] = useUpdateIntegrationMutation();

  const { integrationUid } = useParams<EditIntegrationParams>();

  const {
    data: fetchedIntegrations,
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
    isError: isIntegrationsError,
  } = useGetIntegrationsQuery(undefined);

  const {
    data: fetchedIntegration,
    isLoading: isIntegrationLoading,
    isFetching: isIntegrationFetching,
    isError: isIntegrationError,
  } = useGetIntegrationQuery({
    integrationUid,
  });

  const {
    availabilityValues,
    isAvailabilityValuesLoading,
    isAvailabilityValuesFetching,
    isAvailabilityValuesError,
  } = useGetSaasTemplateAvailabilityValuesQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
      availabilityValues: getFormIntegrationAvailabilityOptions(
        data?.saasTemplateAvailabilityValues,
      ),
      isAvailabilityValuesError: isError,
      isAvailabilityValuesFetching: isFetching,
      isAvailabilityValuesLoading: isLoading,
    }),
  });

  return isIntegrationsError ||
    isAddIntegrationError ||
    isIntegrationError ||
    isAvailabilityValuesError ||
    isAddIntegrationLoading ||
    isIntegrationsLoading ||
    isIntegrationsFetching ||
    isIntegrationLoading ||
    isIntegrationFetching ||
    isAvailabilityValuesLoading ||
    isAvailabilityValuesFetching ||
    !fetchedIntegration ||
    !fetchedIntegrations ? (
    <EditIntegrationSkeleton />
  ) : (
    <EditIntegration
      integrations={fetchedIntegrations?.saasTemplates}
      integration={fetchedIntegration}
      integrationToUpdate={integrationToUpdate}
      updateIntegration={updateIntegration}
      setInputsToEdit={setInputsToEdit}
      setFetchedInputsToEdit={setFetchedInputsToEdit}
      integrationAvailabilityOptions={availabilityValues}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  integrationToUpdate: integrationSelectors.getIntegrationToUpdate(state),
});

const mapDispatchToProps = {
  setFetchedInputsToEdit,
  setInputsToEdit,
};

type EditIntegrationContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type EditIntegrationContainerRTKProps = {
  updateIntegration: (integrationToUpdate: IntegrationToUpdate) => void;
  integration?: Integration;
  integrations?: Integration[];
  integrationAvailabilityOptions: SelectOption<string>[];
};

export type EditIntegrationProps = EditIntegrationContainerProps &
  EditIntegrationContainerRTKProps;

export const ConnectedEditIntegration = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditIntegrationContainer);
