import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PUBLIC_CLOUD_INTEGRATION_TYPE } from '../../../../../../../constants';

export type InventorySaasTemplatesAlertProps = {
  isLoading: boolean;
  inventorySaasTemplates?: SaasTemplate[];
  integrationType: IntegrationType;
};

export function InventorySaasTemplatesAlert({
  isLoading,
  inventorySaasTemplates,
  integrationType,
}: InventorySaasTemplatesAlertProps): ReactElement {
  const { t } = useTranslation();

  if (
    !isLoading &&
    (!inventorySaasTemplates || inventorySaasTemplates?.length < 1)
  ) {
    return (
      <Alert colour="info">
        {integrationType === PUBLIC_CLOUD_INTEGRATION_TYPE
          ? t('saas.add.noItemsAlertCloud')
          : t('saas.add.noItemsAlert')}
      </Alert>
    );
  } else {
    return <></>;
  }
}
