import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { ColDef } from 'ag-grid-community';
import classnames from 'classnames';
import { GridEditor } from '../../../../../../../../../../components/GridEditor';
import { hasDuplicateMacAddress, hasDuplicateIpAddress } from '../../utils';
import { CustomTickRenderer } from '../../../../../../../../../../components/GridEditor/CellTick';
import {
  ipAddressesOnSameNetwork,
  isDuplicateMacAddress,
} from '../../../../../Configure/Utils';

type NetworkingTableProps = {
  vmNetworkInterfaces: VmNetworkingToEdit;
  onDeleteClick: (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => void;
  onEditClick: (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => void;
  isLoading: boolean;
};

export function NetworkingTable({
  vmNetworkInterfaces,
  onEditClick,
  onDeleteClick,
}: NetworkingTableProps): ReactElement | null {
  const { t } = useTranslation();
  const hasDuplicateMacs = hasDuplicateMacAddress(vmNetworkInterfaces);
  const hasDuplicateIps = hasDuplicateIpAddress(vmNetworkInterfaces);

  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      editable: false,
      field: 'name',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.adapterName'),
      minWidth: 130,
      sort: 'asc',
    },
    {
      cellStyle: { 'white-space': 'normal' },
      field: 'network.name',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.network'),
      minWidth: 120,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'type',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.type'),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellClassRules: {
        'cell-warn-text': (params) => {
          return isDuplicateMacAddress(vmNetworkInterfaces, params.value);
        },
      },
      cellStyle: { 'white-space': 'normal' },
      field: 'macAddress',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.macAddress'),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellClassRules: {
        'cell-warn-text': (params) => {
          const ipAddress = params.value;
          const network = params.data.network;

          return ipAddressesOnSameNetwork(
            network.uid,
            ipAddress,
            vmNetworkInterfaces,
          );
        },
      },
      cellStyle: { 'white-space': 'normal' },
      field: 'ipAddress',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.ipAddress'),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellRenderer: 'tickBox',
      cellStyle: { 'white-space': 'normal' },
      field: 'assignDhcp',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.dhcp.title'),
      minWidth: 20,
    },
  ];

  return (
    <>
      <GridEditor
        columns={columns}
        data={vmNetworkInterfaces}
        className={classnames('base-padding-left')}
        onEditClick={(row) => onEditClick(row.data)}
        onDeleteClick={(element) => {
          onDeleteClick(element.data);
        }}
        customComponents={{
          tickBox: CustomTickRenderer,
        }}
        deleteAriaLabel="delete-nic-item"
        editAriaLabel="edit-nic-item"
        updateAriaLabel="update-nic-item"
      />
      {hasDuplicateIps && (
        <Alert colour="warning" showIcon>
          {t('virtualMachines.edit.networking.warnings.duplicateIp')}
        </Alert>
      )}
      {hasDuplicateMacs && (
        <Alert colour="warning" showIcon>
          {t('virtualMachines.edit.networking.warnings.duplicateMac')}
        </Alert>
      )}
    </>
  );
}
