import React, { ReactElement } from 'react';
import { CellProps } from 'react-table';
import { TopologySharedIcon } from '../TopologySharedIcon';
import { TopologyWarnings } from '../TopologyWarnings';

export const TopologiesListIconsCol = ({
  row: { original },
}: CellProps<Topology>): ReactElement => (
  <>
    <TopologyWarnings
      warnings={original.warnings}
      topologyUid={original.uid}
      className="half-margin-right"
    />
    {original.sharedWithCurrentUser && (
      <TopologySharedIcon id={`tooltip-topology-${original.uid}`} />
    )}
  </>
);
