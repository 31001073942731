import React, { ReactElement, ReactNode } from 'react';
import { Button } from 'dcloud-shared-ui';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from '../../';
import styles from './InfoModal.module.css';
import { InfoModalColumn } from './components/InfoModalColumn';

export interface InfoModalProps {
  className?: string;
  onClose: VoidFunction;
  show: boolean;
  title: string;
  colsContent: InfoModalData[];
  footerContent: ReactNode;
  size?: ModalProps['size'];
  closeButtonClass?: string;
}

export function InfoModal({
  className,
  onClose,
  show,
  title,
  colsContent,
  footerContent,
  size,
  closeButtonClass,
}: InfoModalProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Modal
      className={classnames(styles.root, className)}
      clickScreenToClose={true}
      show={show}
      onClose={onClose}
      title={title}
      testId={`info-modal-${title}`}
      headerAlignment="center"
      size={size}
    >
      <div className={styles.container}>
        <div className={styles.colsContainer}>
          {colsContent.map((colContent, index) => {
            return (
              <div key={index} className={styles.col}>
                <InfoModalColumn data={colContent.data} />
              </div>
            );
          })}
        </div>

        <div className="row base-margin-bottom dbl-margin-top">
          <div className="col-12">{footerContent}</div>
        </div>

        <div className="flex flex-center dbl-margin-top">
          <Button onClick={() => onClose()} className={closeButtonClass}>
            {t('buttons.close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
