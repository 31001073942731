import React, { ReactElement, ReactNode } from 'react';
import { Alert, Heading } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PanelGrid } from '../../../../../PanelGrid';
import { PanelTableLoader } from '../../../../../../PanelTableLoader';
import { ProxyProps } from './container';

export function Proxy({
  sessionProxyAddresses,
  isLoading,
  isError,
}: ProxyProps): ReactElement {
  const { t } = useTranslation();

  const buildGridData = ({
    href,
    targetVm,
    linkName,
  }: SessionProxyAddress): PanelGridDataItem[] => {
    return [
      {
        key: 'targetVm',
        name: t('sessions.panels.info.publicIpProxy.targetVm'),
        value: targetVm,
      },
      {
        key: 'href',
        name: t('sessions.panels.info.publicIpProxy.link'),
        value:
          href && linkName ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={href} target="_blank" rel="noopener">
              {linkName}
            </a>
          ) : undefined,
      },
    ];
  };

  const renderGrids = (
    sessionProxyAddresses: SessionProxyAddress[],
  ): ReactNode => {
    return sessionProxyAddresses.map(function (sessionProxyAddress, index) {
      return (
        <PanelGrid
          key={index}
          data={buildGridData(sessionProxyAddress)}
          wideCol="value"
          nameBold={true}
        />
      );
    });
  };

  if (isLoading || isError) {
    return <PanelTableLoader />;
  }

  if (!sessionProxyAddresses || !sessionProxyAddresses.length) {
    return (
      <Alert>{t('sessions.panels.info.publicIpProxy.noProxyConfigured')}</Alert>
    );
  }

  return (
    <>
      <Heading level="h2" align="left" size="18">
        {t('sessions.panels.info.publicIpProxy.proxy')}
      </Heading>

      {renderGrids(sessionProxyAddresses)}
    </>
  );
}
