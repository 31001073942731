import React, { ReactElement } from 'react';
import { NavigationTab } from 'dcloud-shared-ui';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ConnectedDemoTemplates } from '../DemoTemplates/container';
import { ConnectedVmTemplates } from '../VmTemplates/container';
import { MainRoutes } from '../../../../constants/routes';
import i18n from '../../../../i18n';

export enum TemplateManagerRoutes {
  CreateVmTemplate = '/create-vm-template',
  EditVmTemplate = '/edit-vm-template',
  VmTemplates = '/manage-vms',
  Demos = '/demos',
}

export const routes: NavigationTab[] = [
  {
    component: ConnectedVmTemplates,
    label: i18n.t('templateManager.vmTemplates.title'),
    pathname: TemplateManagerRoutes.VmTemplates,
  },
  {
    component: ConnectedDemoTemplates,
    label: i18n.t('templateManager.demoTemplates.title'),
    pathname: TemplateManagerRoutes.Demos,
  },
];

export function TemplateManagerRouter(): ReactElement {
  const { url } = useRouteMatch();
  return (
    <Switch>
      {routes.map(({ component, label, pathname }) => (
        <Route
          path={`${MainRoutes.Templates}${pathname}`}
          component={component}
          key={label}
        />
      ))}

      <Redirect to={`${url.substring(0, url.lastIndexOf('/'))}`} />
    </Switch>
  );
}
