type BuildUserWorkflowParameterDefaultValue = (args: {
  isEditing?: boolean;
  userConfigurable: boolean;
  defaultToTemplate?: boolean;
  userInputValue?: string;
  templateDefaultValue?: string;
}) => string | undefined;

export const determineUserWorkflowParameterDefaultValue: BuildUserWorkflowParameterDefaultValue =
  ({
    isEditing,
    userConfigurable,
    defaultToTemplate,
    userInputValue,
    templateDefaultValue,
  }): string | undefined => {
    let defaultValue: string | undefined;
    if (userConfigurable) {
      if (isEditing) {
        if (defaultToTemplate) {
          defaultValue = templateDefaultValue;
        } else {
          defaultValue = userInputValue;
        }
      } else {
        defaultValue = templateDefaultValue;
      }
    } else {
      defaultValue = templateDefaultValue;
    }
    return defaultValue;
  };

export const determineIfInputFieldIsDisabled = (
  userConfigurable: boolean,
  defaultToTemplate: boolean,
): boolean => !userConfigurable || defaultToTemplate;

export const determineIfDefaultToParentFieldIsVisible = (
  userConfigurable: boolean,
  templateDefaultValue: string | undefined,
  isAdmin: boolean | undefined,
): boolean => !!templateDefaultValue && (isAdmin || userConfigurable);

export const determineInputFieldValue = (
  userInputValue: string,
  templateDefaultValue: string,
  defaultToTemplate: boolean,
): string | undefined =>
  defaultToTemplate ? templateDefaultValue : userInputValue;

export const determineDefaultToTemplateValue = (
  defaultToTemplate: boolean,
  templateDefaultValue: string,
  isEditing: boolean,
): boolean => (isEditing ? defaultToTemplate : !!templateDefaultValue);

export const buildWorkflowParamsPayload = (
  inputs?: SaasTemplateInput[],
): SaasTemplateInput[] =>
  (inputs || []).map((input) => {
    const inputReturnValue: SaasTemplateInput = {
      description: input.description,
      fieldName: input.fieldName,
      mappingName: input.mappingName,
      userConfigurable: input.userConfigurable || false,
    };

    if (!!input.defaultValue) {
      inputReturnValue.defaultToWorkflowEngine = input.defaultToWorkflowEngine;
    }

    if (!input.defaultToWorkflowEngine && input.value) {
      inputReturnValue.value = input.value;
    }

    return inputReturnValue;
  });
