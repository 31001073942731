import React, { ReactElement, useMemo } from 'react';
import { Alert } from 'dcloud-shared-ui';
import {
  buildWarningAlterTextPart1,
  buildWarningAlterTextPart2,
  buildWarningAlterTextPart3,
} from './utils';

export type ConfigurationEditWarningProps = {
  isActiveTemplate: boolean;
  isDemoPublished: boolean;
};

export const ConfigurationEditWarning = ({
  isActiveTemplate,
  isDemoPublished,
}: ConfigurationEditWarningProps): ReactElement => {
  const alertText = useMemo<string>(() => {
    return `${buildWarningAlterTextPart1()}${buildWarningAlterTextPart2(
      isActiveTemplate,
      isDemoPublished,
    )}${buildWarningAlterTextPart3()}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTemplate, isDemoPublished]);

  if (!isActiveTemplate && !isDemoPublished) {
    return <></>;
  }

  return (
    <Alert showIcon colour="warning" data-test-id={'something'}>
      {alertText}
    </Alert>
  );
};
