import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual, orderBy } from 'lodash';
import styles from '../Integrations/Integrations.module.css';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { PageHeading } from '../../components/PageHeading';
import {
  ConfirmModal,
  ControlledSelect,
  ControlledTextarea,
  ControlledTextfield,
} from '../../components';
import { integrationFormFieldsSchema } from '../../validation';
import {
  INTEGRATION_TYPE_OPTIONS_FOR_EDIT,
  UNAVAILABLE_STATUS,
} from '../../constants';
import { useLeaveFormPrompt } from '../EditTopology/hooks/useLeaveFormPrompt';
import { FormUnsavedChangesPrompt } from '../../components/FormUnsavedChangesPrompt';
import { ControlledIconPicker } from '../../components/Form/ControlledIconPickerButton/ControlledIconPicker';
import {
  DEFAULT_MAX_AMOUNT_PER_TOPOLOGY,
  DEFAULT_SESSION_VIEW,
  DESCRIPTION_FIELD_NUM_ROWS,
  DETAILS_FIELD_NUM_ROWS,
  SESSION_VIEW_OPTIONS,
  STATUS_MESSAGE_FIELD_NUM_ROWS,
} from '../CreateIntegration/constants';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import {
  parseChangeEventToNumber,
  parseNumberToString,
} from '../EditTopology/configuration/components/VirtualMachineEntities/Edit/utils';
import { useTriggerIntegrationFormValidation } from '../hooks/use-trigger-integration-form-validation/use-trigger-integration-form-validation';
import { IntegrationInputAccordions } from '../Integrations/components/IntegrationInput/IntegrationInputAccordions';
import { getAvailableIconsForIntegrationType } from '../EditTopology/configuration/SaasIntegrations/Configure/components/ConfigureFormIconInput/utils';
import { ConditionalControlledTextarea } from '../components/ConditionalControlledTextarea/ConditionalControlledTextarea';
import { useClearIntegrationStatusMessage } from '../hooks/use-clear-integration-status-message/use-clear-integration-status-message';
import { buildWorkflowParamsPayload } from '../utils/workFlowParameterMappingUtil';
import { ConnectedLicenseForm } from './components/LicenseForm/container';
import { ConnectedTagForm } from './components/TagsForm/container';
import { EditIntegrationProps } from './container';
import {
  getIconOnIntegrationTypeChange,
  prepareWorkflowParamsForComparison,
} from './utils/utils';

export function EditIntegration({
  integration,
  integrations,
  updateIntegration,
  integrationToUpdate,
  integrationAvailabilityOptions,
  setInputsToEdit,
  setFetchedInputsToEdit,
}: EditIntegrationProps): ReactElement {
  const [selectedIcon, setSelectedIcon] = useState<string>(
    integration?.icon || '',
  );
  const [tagError, setTagError] = useState(false);
  const { t } = useTranslation();

  const form = useForm<IntegrationTemplateDetailsFormFields>({
    defaultValues: {
      availabilityStatus: integration?.availability.status || '',
      availabilityStatusMessage: integration?.availability.message || '',
      description: integration?.description,
      details: integration?.details,
      icon: integration?.icon,
      inventoryWorkflowName: integration?.inventoryWorkflowName,
      maxAmountPerTopology:
        integration?.maxAmountPerTopology || DEFAULT_MAX_AMOUNT_PER_TOPOLOGY,
      name: integration?.name,
      sessionView: integration?.sessionView || DEFAULT_SESSION_VIEW,
      type: integration?.type,
    },
    mode: 'all',
    resolver: yupResolver(
      integrationFormFieldsSchema(
        integrations
          ?.filter((integrationRow) => integrationRow.uid !== integration?.uid)
          .map((integration) => integration.name) || [],
      ),
    ),
  });

  const {
    control,
    control: { setValue },
    errors,
    formState: { isValid, dirtyFields },
    reset,
    watch,
    trigger,
    handleSubmit,
  } = form;

  const watchAvailabilityStatus = watch('availabilityStatus');

  useTriggerIntegrationFormValidation<IntegrationTemplateDetailsFormFields>({
    fieldsToValidate: {
      availabilityStatusMessage: watchAvailabilityStatus === UNAVAILABLE_STATUS,
    },
    trigger,
  });

  const isFormDirty =
    Object.keys(dirtyFields).length > 0 ||
    !isEqual(
      prepareWorkflowParamsForComparison(integration?.inputs),
      prepareWorkflowParamsForComparison(integrationToUpdate?.inputs),
    ) ||
    !isEqual(integrationToUpdate?.tags || [], integration?.tags || []) ||
    !isEqual(
      integrationToUpdate?.integrationLicenses || [],
      integration?.integrationLicenses || [],
    );

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isFormDirty);

  const watchAvailabilityStatusMessage = watch('availabilityStatusMessage');

  const onSaveClick = ({
    availabilityStatusMessage,
    availabilityStatus,
    description,
    details,
    maxAmountPerTopology,
    name,
    sessionView,
    type,
  }: IntegrationTemplateDetailsFormFields) => {
    const availabilityStatusMessageProperty =
      availabilityStatus === UNAVAILABLE_STATUS
        ? { message: availabilityStatusMessage }
        : {};
    updateIntegration({
      availability: {
        ...availabilityStatusMessageProperty,
        status: availabilityStatus,
      },
      description: description,
      details: details,
      icon: selectedIcon,
      inputs: buildWorkflowParamsPayload(integrationToUpdate?.inputs),
      integrationLicenses: integrationToUpdate?.integrationLicenses,
      maxAmountPerTopology: maxAmountPerTopology,
      name: name,
      sessionView: sessionView,
      tags: integrationToUpdate?.tags,
      type: type,
      uid: integration?.uid,
    });
    reset();
  };

  const renderCtaBtn = () => {
    const isParametersInvalid = Object.values(
      integrationToUpdate?.inputStatuses || {},
    ).includes(false);
    return (
      <PrimaryButton
        colour="success"
        disabled={!isValid || tagError || !isFormDirty || isParametersInvalid}
        onClick={handleSubmit(onSaveClick)}
      >
        {t('buttons.save')}
      </PrimaryButton>
    );
  };

  const handleUpdateIcon = (newSelection: string) => {
    setSelectedIcon(newSelection);
    setValue('icon', newSelection, {
      shouldDirty: true,
    });
  };

  const integrationType = watch('type');
  const icons = integrationType
    ? getAvailableIconsForIntegrationType(integrationType)
    : [];

  const sessionViewOptions = SESSION_VIEW_OPTIONS.map((option) => ({
    name: t(`integrations.forms.common.sessionView.values.${option.label}`),
    value: option.value,
  }));

  const isStatusAvailabilityMessageFieldVisible =
    watchAvailabilityStatus === UNAVAILABLE_STATUS;

  useEffect(() => {
    if (!isEqual(integration?.inputs, integrationToUpdate?.fetchedInputs)) {
      setInputsToEdit(integration?.inputs);
      setFetchedInputsToEdit(integration?.inputs);
    }
  }, [
    integration,
    setInputsToEdit,
    integrationToUpdate,
    setFetchedInputsToEdit,
  ]);

  useClearIntegrationStatusMessage({
    availabilityStatusMessage: watchAvailabilityStatusMessage,
    isStatusAvailabilityMessageFieldVisible,
    setValue,
  });

  const [showChangeIntegrationTypeModal, setShowChangeIntegrationTypeModal] =
    useState(false);

  const handleIntegrationTypeChange = (selectedIntegrationType: string) => {
    if (integration) {
      const newIcon = getIconOnIntegrationTypeChange(
        selectedIntegrationType,
        integration,
      );
      setSelectedIcon(newIcon);
      if (integration.icon !== newIcon) {
        setShowChangeIntegrationTypeModal(true);
      }
    }
  };

  return (
    <DefaultLayout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <PageHeading
              ctaBtn={renderCtaBtn()}
              pageTitle={t('integrations.forms.edit.title')}
              withBackBtn={true}
            />
            <form>
              <div className={styles.form}>
                <div className="row">
                  <div className="col-6">
                    <ControlledTextfield<IntegrationTemplateDetailsFormFields>
                      control={control}
                      error={errors?.name?.message}
                      label={t('integrations.forms.common.name.label')}
                      name="name"
                      required={true}
                    />
                  </div>
                  <div className="col-6">
                    <ControlledTextfield<IntegrationTemplateDetailsFormFields>
                      control={control}
                      label={t('integrations.forms.common.recipe.label')}
                      name="inventoryWorkflowName"
                      required={true}
                      disabled
                    />
                  </div>
                </div>
                <div className="row half-padding-top half-padding-bottom">
                  <div className="col-6 flex flex-middle">
                    <ControlledTextfield<IntegrationTemplateDetailsFormFields>
                      control={control}
                      label={t(
                        'integrations.forms.common.maxAmountPerTopology.label',
                      )}
                      name="maxAmountPerTopology"
                      required={true}
                      testId="edit-integration-maxAmountPerTopology"
                      isNumber={true}
                      error={errors?.maxAmountPerTopology?.message}
                      transform={{
                        input: parseNumberToString,
                        output: parseChangeEventToNumber,
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <ControlledSelect
                          control={control}
                          label={t(
                            'integrations.forms.common.integrationType.name',
                          )}
                          name="type"
                          required={true}
                          testId="create-integration-type"
                          options={INTEGRATION_TYPE_OPTIONS_FOR_EDIT}
                          customOnChange={(event) => {
                            handleIntegrationTypeChange(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row half-padding-top half-padding-bottom">
                  <div className="col-3">
                    <ControlledSelect
                      control={control}
                      label={t(
                        'integrations.forms.common.availabilityStatus.label',
                      )}
                      name="availabilityStatus"
                      required={true}
                      testId="create-integration-availability"
                      error={errors?.availabilityStatus?.message}
                      options={integrationAvailabilityOptions}
                    />
                  </div>
                </div>
                <ConditionalControlledTextarea
                  className="half-padding-top half-padding-bottom"
                  condition={isStatusAvailabilityMessageFieldVisible}
                  control={control}
                  error={errors?.availabilityStatusMessage?.message}
                  label={t(
                    'integrations.forms.common.availabilityStatusMessage.label',
                  )}
                  placeholder={t(
                    'integrations.forms.common.availabilityStatusMessage.placeholder',
                  )}
                  name="availabilityStatusMessage"
                  rows={STATUS_MESSAGE_FIELD_NUM_ROWS}
                  required={true}
                />
                <div className="half-padding-top half-padding-bottom">
                  <ControlledTextarea<IntegrationTemplateDetailsFormFields>
                    control={control}
                    error={errors?.description?.message}
                    label={t('integrations.forms.common.description.label')}
                    name="description"
                    rows={DESCRIPTION_FIELD_NUM_ROWS}
                    required={true}
                  />
                  <ControlledTextarea<IntegrationTemplateDetailsFormFields>
                    control={control}
                    error={errors?.details?.message}
                    label={t('integrations.forms.common.details.label')}
                    name="details"
                    rows={DETAILS_FIELD_NUM_ROWS}
                    required={true}
                  />
                </div>
                <div className="row half-padding">
                  <div className="col-6">
                    <ControlledSelect
                      options={sessionViewOptions}
                      control={control}
                      label={t('integrations.forms.common.sessionView.label')}
                      name="sessionView"
                      required={true}
                    />
                    <ControlledIconPicker
                      control={control}
                      icons={icons}
                      onChange={handleUpdateIcon}
                      selectedIcon={selectedIcon}
                      iconPickerLabel={t(
                        'integrations.forms.common.icon.label',
                      )}
                      changeIconLabel={t('buttons.changeIcon')}
                    />
                  </div>
                  <div className="col-6">
                    <ConnectedLicenseForm
                      selectedLicenses={integration?.integrationLicenses || []}
                    />
                    <ConnectedTagForm
                      tagError={tagError}
                      setTagError={setTagError}
                      selectedTags={integration?.tags || []}
                    />
                  </div>
                </div>
                <div className="half-padding">
                  <IntegrationInputAccordions
                    isEditing={true}
                    inputsToEdit={orderBy(integration?.inputs || [], [
                      'mappingName',
                    ])}
                    inputStatuses={integrationToUpdate?.inputStatuses}
                  />
                </div>
              </div>
            </form>
            <FormUnsavedChangesPrompt
              showModal={showModal}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
            />
            <ConfirmModal
              heading={t('integrations.forms.edit.typeChangeWarning.heading')}
              clickScreenToClose={true}
              show={showChangeIntegrationTypeModal}
              onCancel={() => setShowChangeIntegrationTypeModal(false)}
              onClose={() => setShowChangeIntegrationTypeModal(false)}
              onConfirm={() => setShowChangeIntegrationTypeModal(false)}
              loading={false}
              subHeading={t(
                'integrations.forms.edit.typeChangeWarning.subheading',
              )}
              confirmButtonColour="primary"
              confirmButtonLabel={t('buttons.close')}
              showCancel={false}
              testId="change-integration-type-modal"
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
