import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionBarButton } from '../ActionsBarButton';
import { determineTypesFromIntegrations } from '../../../../../../utils/integrations';
import styles from './ActionsBarCenter.module.css';
import { ActionsBarCenterProps } from './container';

export function ActionsBarCenter({
  actions,
  isSessionExpired,
  setShowRenameSessionModal,
  setShowSaveAndEndSessionModal,
  setLeftPanelType,
  setShowEndSessionModal,
  setShowResetSessionModal,
  sessionDetails,
  isSessionDetailsLoading,
}: ActionsBarCenterProps): ReactElement {
  const { t } = useTranslation();
  const { saas, publicCloud } = determineTypesFromIntegrations(
    sessionDetails?.saasIntegrations,
  );
  return (
    <div className={classNames('header-item', styles.root)}>
      {!isSessionDetailsLoading && (
        <>
          {publicCloud && actions?.publicCloud && (
            <ActionBarButton
              title={t('sessions.panels.publicCloud.title')}
              iconName="cloud"
              onClick={() => setLeftPanelType('publicCloud')}
              isDisabled={isSessionExpired}
            />
          )}
          {saas && actions?.saas && (
            <ActionBarButton
              title={t('sessions.panels.saas.title')}
              iconName="cloud-config-16"
              onClick={() => setLeftPanelType('saas')}
              isDisabled={isSessionExpired}
            />
          )}
        </>
      )}
      {actions?.info && (
        <ActionBarButton
          title="Info"
          iconName="info"
          onClick={() => setLeftPanelType('info')}
          isDisabled={isSessionExpired}
        />
      )}
      {actions?.end && (
        <ActionBarButton
          title="End"
          iconName="trash"
          isDisabled={isSessionExpired}
          onClick={() => setShowEndSessionModal(true)}
        />
      )}
      {actions?.saveAndEnd && (
        <ActionBarButton
          title="Save &amp; End"
          iconName="save"
          isDisabled={isSessionExpired}
          onClick={() => setShowSaveAndEndSessionModal(true)}
        />
      )}
      {actions?.reset && (
        <ActionBarButton
          title="Reset"
          iconName="reset"
          isDisabled={isSessionExpired}
          onClick={() => setShowResetSessionModal(true)}
        />
      )}
      {actions?.rename && (
        <ActionBarButton
          title="Rename"
          iconName="edit"
          onClick={() => setShowRenameSessionModal(true)}
          isDisabled={isSessionExpired}
        />
      )}
      {actions?.documentation && (
        <ActionBarButton
          title="Documentation"
          iconName="document"
          onClick={() => setLeftPanelType('documentation')}
          isDisabled={isSessionExpired}
        />
      )}
    </div>
  );
}
