import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LinkButton } from 'dcloud-shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateTopologyRoutes } from '../../constants';
import { generalFieldsSchema } from '../../../../validation';
import {
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../components/Form';
import { SubmitButton } from '../../../../components/Buttons';
import { MainRoutes } from '../../../../constants/routes';

export interface DetailsProps {
  demoToImport?: InventoryDemo & Pick<TopologyGeneralFields, 'notes'>;
  demoType: string;
  submitting: boolean;
  onSubmit: (values: TopologyGeneralFields) => void;
  templateToImport?: InventoryTopology;
}

export function Details({
  onSubmit,
  submitting,
  demoToImport,
  demoType,
  templateToImport,
}: DetailsProps): ReactElement {
  const { t } = useTranslation();
  let description,
    name = '';

  if (demoType === 'IMPORT' && demoToImport) {
    description = demoToImport.description;
    name = demoToImport.name;
  } else if (demoType === 'TEMPLATE' && templateToImport?.name) {
    description = templateToImport.description;
    name = templateToImport.name;
  }

  const { handleSubmit, errors, control, formState } =
    useForm<TopologyGeneralFields>({
      defaultValues: {
        description,
        name,
        notes: demoToImport?.notes || '',
      },
      mode: 'all',
      resolver: yupResolver(generalFieldsSchema),
    });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="row flex-center base-margin-top"
    >
      <div className="col-md-8">
        <ControlledTextfield<TopologyGeneralFields>
          error={errors?.name?.message}
          name="name"
          label={t('general.form.name.label')}
          labelClass="col-md-3"
          control={control}
          required={true}
        />
        <ControlledTextarea<TopologyGeneralFields>
          name="description"
          control={control}
          error={errors?.description?.message}
          label={t('general.form.description.label')}
          labelClass="col-md-3"
          required={true}
          rows={4}
        />
        {demoType !== 'TEMPLATE' && (
          <ControlledTextarea<TopologyGeneralFields>
            name="notes"
            control={control}
            error={errors?.notes?.message}
            label={t('general.form.notes.label')}
            labelClass="col-md-3"
            required={false}
            rows={4}
          />
        )}
        <div className="flex flex-center dbl-margin-top">
          <LinkButton
            to={MainRoutes.Topologies}
            colour="ghost"
            size="large"
            testId="create-topology-details-cancel"
          >
            {t('buttons.cancel')}
          </LinkButton>
          <LinkButton
            to={`${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoType}`}
            colour="ghost"
            size="large"
            testId="create-topology-details-back-button"
          >
            {t('buttons.back')}
          </LinkButton>
          <SubmitButton
            loading={submitting}
            disabled={!formState.isValid}
            testId="create-topology-details-create-button"
          >
            {t('buttons.create')}
          </SubmitButton>
        </div>
      </div>
    </form>
  );
}
