import * as d3 from 'd3';
import { DOMAIN_DIVISION, SPACE_DIMENSION, Zoom } from '../constants';

export const x = d3.scaleLinear();
export const y = d3.scaleLinear();

export const zoom = d3
  .zoom<HTMLDivElement, unknown>()
  .scaleExtent([Zoom.Min, Zoom.Max])
  .translateExtent([
    [-SPACE_DIMENSION, -SPACE_DIMENSION],
    [SPACE_DIMENSION, SPACE_DIMENSION],
  ]);

export const domain = {
  height: window.innerHeight / DOMAIN_DIVISION,
  width: window.innerWidth / DOMAIN_DIVISION,
};

/**
 * Method to setup the domain and x,y linear scale.
 * These values are used to calculate the whole diagram
 * and are used on resize and initialize to recalculate dimensions.
 */
export function setXY(): void {
  const canvas = d3.select<HTMLDivElement, unknown>('#canvas').node()!;
  const { width, height } = canvas.getBoundingClientRect();

  domain.height = height / DOMAIN_DIVISION;
  domain.width = width / DOMAIN_DIVISION;

  x.domain([-domain.width, domain.width]).range([0, width]);
  y.domain([-domain.height, domain.height]).range([height, 0]);
}

export function calculateDefaultPositionOnGraphBasedOnType(
  node: HTMLDivElement,
): { x: number; x1: number; x2: number; y: number; y1: number; y2: number } {
  setXY();
  const canvasNode = d3.select<HTMLDivElement, unknown>('#canvas').node()!;
  const canvasRect = canvasNode.getBoundingClientRect();
  const xPosition = x.invert(node.clientWidth / 2 + canvasRect.width / 2);
  const yPosition = y.invert(node.clientHeight / 2 + canvasRect.height / 2);

  return {
    x: -xPosition,
    x1: -xPosition,
    x2: xPosition,
    y: -yPosition,
    y1: -yPosition,
    y2: yPosition,
  };
}

export function calculateLineAngleByEndpoints(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): number {
  return (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
}

export function calculateLineLengthByEndpoints(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): number {
  const xDelta = Math.abs(x2 - x1);
  const yDelta = Math.abs(y2 - y1);
  return Math.sqrt(xDelta * xDelta + yDelta * yDelta);
}
