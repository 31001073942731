import React, { ReactElement } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { ConnectedEditHardware } from './Edit/container';
import { ConnectedAllHardware } from './All/container';
import { ConnectedAddHardware } from './Add/container';

export function Router({ match: { path } }: RouteComponentProps): ReactElement {
  return (
    <div>
      <Switch>
        <Route path={path} component={ConnectedAllHardware} exact={true} />
        <Route
          path={`${path}/add`}
          component={ConnectedAddHardware}
          exact={true}
        />
        <Route
          path={`${path}/:hardwareUid`}
          component={ConnectedEditHardware}
          exact={true}
        />
      </Switch>
    </div>
  );
}
