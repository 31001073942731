import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledTextfield } from '../../../../../../../../../../components';
import { FORM_SECTION_CLASSES } from '../../../../../constants';
import formStyles from '../../../../../../../EditFormStyles.module.css';
import styles from './dhcpConfig.module.css';

export function DhcpConfig(): ReactElement {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const formSectionClasses = classnames(
    FORM_SECTION_CLASSES,
    styles.formSection,
  );
  const rowClasses = classnames('row', styles.dhcpConfigRow);

  return (
    <div className={formStyles.sectionPadding}>
      <div className={rowClasses}>
        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.dhcpConfig?.defaultGatewayIp?.message}
            label={t('virtualMachines.edit.dhcpConfig.labels.defaultGateway')}
            name="dhcpConfig.defaultGatewayIp"
            testId="edit-vm-dhcp-defaultGateway"
          />
        </div>

        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.dhcpConfig?.primaryDnsIp?.message}
            label={t('virtualMachines.edit.dhcpConfig.labels.primaryDnsIp')}
            name="dhcpConfig.primaryDnsIp"
            required={false}
            testId="edit-vm-dhcp-primaryDns"
          />
        </div>

        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.dhcpConfig?.secondaryDnsIp?.message}
            label={t('virtualMachines.edit.dhcpConfig.labels.secondaryDnsIp')}
            name="dhcpConfig.secondaryDnsIp"
            required={false}
            testId="edit-vm-dhcp-secondaryDns"
          />
        </div>
      </div>
    </div>
  );
}
