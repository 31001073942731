import React, { ReactElement } from 'react';
import { CenteredSpinner } from '../../../../../components';
import { sessionApi } from '../../../../../redux/views/session-view/session/api/api';
import { retrieveVisibleConnections } from '../../../utils';
import { SessionDiagram } from '.';

export function ConnectedDCloudSessionDiagram({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const { data, isLoading } = sessionApi.endpoints.getSession.useQueryState({
    sessionUid,
    versionUid,
  });

  if (!data || isLoading) {
    return <CenteredSpinner />;
  }
  const { nodes, connections } = data.diagram;
  return (
    <SessionDiagram
      nodes={nodes}
      connections={retrieveVisibleConnections(connections, nodes)}
    />
  );
}
