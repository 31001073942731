import { format } from 'date-fns';
import { LAST_UPDATED_DATE_FORMAT } from '../../../EditTopology/configuration/VirtualMachines/constants';
import i18n from '../../../../i18n';

export const buildEditTemplateColsData = (
  vmTemplate: VmTemplate,
): InfoPanelData[] => {
  return [
    {
      data: buildEditCol1Data(vmTemplate),
    },
    {
      data: buildEditCol2Data(vmTemplate),
    },
    {
      data: buildEditCol3Data(vmTemplate),
    },
  ];
};

export const buildEditCol1Data = (
  vmTemplate: VmTemplate,
): TemplateModalDataItem[] => {
  const { datacenter, lastUpdated, inventoryVm } = vmTemplate;

  return [
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.datacenter'),
      value: datacenter,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.lastUpdated'),
      value: lastUpdated
        ? format(new Date(lastUpdated), LAST_UPDATED_DATE_FORMAT)
        : undefined,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.vmId'),
      value: inventoryVm ? inventoryVm.id : '',
    },
  ];
};

export const buildEditCol2Data = (
  vmTemplate: VmTemplate,
): TemplateModalDataItem[] => {
  const { inventoryVm } = vmTemplate;

  return [
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.cpu'),
      value: inventoryVm ? inventoryVm.cpuQty : null,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.memory'),
      value: inventoryVm ? inventoryVm.memoryMb : null,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.vmName'),
      value: inventoryVm ? inventoryVm.name : null,
    },
  ];
};

export const buildEditCol3Data = (
  vmTemplate: VmTemplate,
): TemplateModalDataItem[] => {
  const { inventoryVm } = vmTemplate;
  if (inventoryVm) {
    return [
      {
        label: i18n.t('inventoryVirtualMachines.infoModal.headings.sshEnabled'),
        value:
          inventoryVm.remoteAccess?.sshEnabled !== undefined
            ? inventoryVm.remoteAccess.sshEnabled
              ? 'Yes'
              : 'No'
            : undefined,
      },
      {
        label: i18n.t('inventoryVirtualMachines.infoModal.headings.rdpEnabled'),
        value:
          inventoryVm.remoteAccess?.rdpEnabled !== undefined
            ? inventoryVm.remoteAccess.rdpEnabled
              ? 'Yes'
              : 'No'
            : undefined,
      },
      {
        label: i18n.t('inventoryVirtualMachines.infoModal.headings.autoLogin'),
        value:
          inventoryVm.remoteAccess?.rdpAutoLogin !== undefined
            ? inventoryVm.remoteAccess.rdpAutoLogin
              ? 'Yes'
              : 'No'
            : undefined,
      },
    ];
  }
  return [];
};

export const prepTemplatedVmsForTable = (
  templatedInventoryVms: TemplatedInventoryVm[],
): VmTemplate[] =>
  templatedInventoryVms
    .map((current) => ({
      ...current.vmTemplate!,
      inventoryVm: current.inventoryVm,
      inventoryVmId: current.inventoryVmId,
    }))
    .map((inventoryTemplateVm) => ({
      ...inventoryTemplateVm,
      architectureNames: inventoryTemplateVm.architectures?.map(
        (architecture) => architecture.name || '',
      ),
      description: inventoryTemplateVm.description
        ? inventoryTemplateVm.description
        : inventoryTemplateVm.inventoryVm?.description || '',
      inventoryOnly: inventoryTemplateVm.name !== undefined,
      name: inventoryTemplateVm.name
        ? inventoryTemplateVm.name
        : inventoryTemplateVm.inventoryVm?.name || '',
      userEnabled: inventoryTemplateVm.userEnabled,
    }));

export const prepTemplateForEdit = (vmTemplate: VmTemplate): VmTemplate => {
  return {
    ...vmTemplate,
    architectureNames: vmTemplate.architectures?.map(
      (architecture) => architecture.name || '',
    ),
  };
};

export const prepTemplateDataPayload = (
  formData: TemplateVmEditFormData,
  templateToEdit: VmTemplate,
  currentDc: DataCenter,
  selectedArchitectures?: Architecture[],
): VmTemplate => {
  return {
    ...templateToEdit,
    architectures: !selectedArchitectures ? undefined : selectedArchitectures,
    contact: formData.contact === '' ? undefined : formData.contact,
    datacenter: currentDc,
    description: formData.templateDescription,
    licensed: formData.licensed === 'yes',
    managementIp:
      formData.managementIp === '' ? undefined : formData.managementIp,
    name: formData.templateName,
    password: formData.password === '' ? undefined : formData.password,
    templateVersion:
      formData.templateVersion === '' ? undefined : formData.templateVersion,
    userEnabled: formData.userEnabled || false,
    username: formData.userName === '' ? undefined : formData.userName,
  };
};
