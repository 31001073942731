import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NetworkingForm/NetworkingForm.module.css';

type Props = { limit: number };

export const NicLimitError = ({ limit }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <div role="alert" className={styles.errorMaxLimitForNetwork}>
      <span>
        {t('virtualMachines.edit.networking.maxLimitInterfacesForNetworkPart1')}{' '}
        {limit}{' '}
        {t('virtualMachines.edit.networking.maxLimitInterfacesForNetworkPart2')}
      </span>
    </div>
  );
};
