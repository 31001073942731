import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../components';
import { TableLoader } from '../../../../../../components/Table/TableLoader';
import { AccessRoleAssignmentTableActions } from './TableActions';

type AccessRoleAssignmentTableProps = {
  isError: boolean;
  isLoading: boolean;
  accessRoleAssignments: AccessRoleAssignment[];
  setAccessRoleAssignmentToDelete: (
    accessRoleAssignment: AccessRoleAssignment,
  ) => void;
};

export function AccessRoleAssignmentTable({
  isError,
  isLoading,
  accessRoleAssignments,
  setAccessRoleAssignmentToDelete,
}: AccessRoleAssignmentTableProps): ReactElement | null {
  const { t } = useTranslation();

  const columns = useMemo<Column<AccessRoleAssignment>[]>(
    () => [
      {
        Header: () => t('accessRoleAssignment.table.headings.email'),
        accessor: ({ email }) => email,
        id: 'email',
        width: 50,
      },
    ],
    [t],
  );

  const renderActions = (
    accessRoleAssignment: AccessRoleAssignment,
  ): ReactNode => {
    return (
      <AccessRoleAssignmentTableActions
        onDeleteClick={() =>
          setAccessRoleAssignmentToDelete(accessRoleAssignment)
        }
      />
    );
  };

  if (isError || isLoading || !accessRoleAssignments) {
    return <TableLoader />;
  }

  return (
    <Table<AccessRoleAssignment>
      actions={renderActions}
      data={accessRoleAssignments}
      columns={columns}
    />
  );
}
