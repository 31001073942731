import {
  DATACENTERS_PATH,
  DEMO_TEMPLATES,
  TEMPLATED_TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { setSelectedArchitectures, setSelectedDemoTemplate } from '../slice';
import { TAGS } from '../../api/constants';

export const demoTemplateApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createDemoTemplate: build.mutation<DemoTemplate, CreateDemoTemplatePayload>(
      {
        invalidatesTags: [TAGS.DEMO_TEMPLATES],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            return;
          } finally {
            dispatch(setSelectedDemoTemplate(undefined));
            dispatch(setSelectedArchitectures(undefined));
          }
        },
        query: (payload) => ({
          data: payload,
          errorMessage: i18n.t('templateManager.demoTemplates.create.error'),
          method: 'POST',
          successMessage: i18n.t(
            'templateManager.demoTemplates.create.success',
          ),
          url: `${DEMO_TEMPLATES}`,
        }),
      },
    ),
    getAllDemoTemplates: build.query<TemplatedDemo[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DEMO_TEMPLATES],
      query: (dataCenter) => ({
        errorMessage: i18n.t('templateManager.demoTemplates.fetch.errors.many'),
        method: 'get',
        url: `${DATACENTERS_PATH}/${dataCenter.toLocaleLowerCase()}${TEMPLATED_TOPOLOGIES_PATH}`,
      }),
      transformResponse: (response: DemoTemplateResponse) =>
        response.templatedTopologies,
    }),
    getDemoTemplate: build.query<DemoTemplate, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSelectedArchitectures(data.architectures));
        } catch (err) {
          throw err;
        }
      },
      providesTags: [TAGS.DEMO_TEMPLATE],
      query: (templateName) => ({
        errorMessage: i18n.t('templateManager.demoTemplates.fetch.errors.one'),
        method: 'get',
        url: `${DEMO_TEMPLATES}/${templateName}`,
      }),
    }),
    updateDemoTemplate: build.mutation<DemoTemplate, DemoTemplateUpdatePayload>(
      {
        invalidatesTags: [TAGS.DEMO_TEMPLATES],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            return;
          } finally {
            dispatch(setSelectedDemoTemplate(undefined));
            dispatch(setSelectedArchitectures(undefined));
          }
        },
        query: (payload) => ({
          data: payload.demoTemplate,
          errorMessage: i18n.t('templateManager.demoTemplates.update.error'),
          method: 'PUT',
          successMessage: i18n.t(
            'templateManager.demoTemplates.update.success',
          ),
          url: `${DEMO_TEMPLATES}/${payload.templateId}`,
        }),
      },
    ),
  }),
});

export const {
  useCreateDemoTemplateMutation,
  useGetAllDemoTemplatesQuery,
  useGetDemoTemplateQuery,
  useUpdateDemoTemplateMutation,
} = demoTemplateApi;
