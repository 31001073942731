import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SaasState {
  inventorySaasTemplateToConfigure?: SaasTemplate;
  inventorySaasTemplateToDelete?: SaasTemplate;
}

export const initialState: SaasState = {
  inventorySaasTemplateToConfigure: undefined,
  inventorySaasTemplateToDelete: undefined,
};

const slice = createSlice({
  initialState,
  name: 'saas',
  reducers: {
    setInventorySaasTemplateToConfigure: (
      state,
      action: PayloadAction<SaasTemplate | undefined>,
    ) => {
      state.inventorySaasTemplateToConfigure = action.payload;
    },
    setInventorySaasTemplateToDelete: (
      state,
      action: PayloadAction<SaasTemplate | undefined>,
    ) => {
      state.inventorySaasTemplateToDelete = action.payload;
    },
  },
});

export const getSaas = (state: RootState): SaasState =>
  state.configuration[slice.name];

export const { reducer: saasReducer } = slice;
export const {
  setInventorySaasTemplateToConfigure,
  setInventorySaasTemplateToDelete,
} = slice.actions;

export const saasSelectors = {
  getInventorySaasTemplateToConfigure: createSelector(
    getSaas,
    ({ inventorySaasTemplateToConfigure }) => inventorySaasTemplateToConfigure,
  ),
  getInventorySaasTemplateToDelete: createSelector(
    getSaas,
    ({ inventorySaasTemplateToDelete }) => inventorySaasTemplateToDelete,
  ),
};
