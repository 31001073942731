import React, { ReactElement } from 'react';

type WebLinkProps = {
  sessionDocument: SessionDocument;
};

export function WebLink({ sessionDocument }: WebLinkProps): ReactElement {
  return (
    <li>
      <a
        href={sessionDocument.documentLink}
        target="_blank"
        rel="noreferrer noopener"
      >
        {sessionDocument.name}
      </a>
    </li>
  );
}
