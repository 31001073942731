import { Alert, SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../NatRulesVmInfo.module.css';

export type NoConfiguredVmsWithIpAlertProps = {
  vmTargetOptions: SelectOption<string>[];
};

export function NoConfiguredVmsWithIpAlert({
  vmTargetOptions,
}: NoConfiguredVmsWithIpAlertProps): ReactElement {
  const { t } = useTranslation();

  if (vmTargetOptions.length > 1) {
    return <></>;
  }

  return (
    <Alert className={styles.alert} colour="info">
      {t('traffic.natRules.noConfiguredVms')}
    </Alert>
  );
}
