import { Icon, IconNames } from 'dcloud-shared-ui';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { camelize } from '../../utils/helpers';
import styles from './AccordionRow.module.css';

export type AccordionRowProps = PropsWithChildren<{
  className?: string;
  id: string;
  isOpen?: boolean;
  onRowClick: VoidFunction;
  title: string;
  noPadding?: boolean;
  iconName?: IconNames;
  iconClassName?: string;
  imageComponent?: ReactElement;
}>;

export function AccordionRow({
  children,
  className,
  id,
  isOpen = false,
  onRowClick,
  title,
  noPadding = false,
  iconName,
  iconClassName,
  imageComponent,
}: AccordionRowProps): ReactElement {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const controlsId = camelize(title);

  useEffect(() => {
    const height =
      isOpen && contentRef.current ? contentRef.current.scrollHeight : 0;
    setContentHeight(height);
  }, [isOpen]);

  return (
    <div
      className={classnames(styles.root, 'half-margin-bottom', {
        [styles.active]: isOpen,
      })}
    >
      <button
        type="button"
        className={classnames(
          className,
          styles.button,
          'text-size-24 text-weight-200 flex flex-between flex-middle',
        )}
        onClick={onRowClick}
        data-test="accordion-row"
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls={controlsId}
        id={id}
      >
        {imageComponent}
        {iconName ? (
          <div>
            {title}{' '}
            <Icon
              name={iconName}
              className={classnames('text-size-18', iconClassName)}
            />
          </div>
        ) : (
          title
        )}
        <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </button>
      <div
        ref={contentRef}
        style={
          isOpen
            ? { height: 'auto', minHeight: `${contentHeight}px` }
            : { height: '0' }
        }
        className={styles.content}
        role="region"
        id={controlsId}
        aria-labelledby={id}
      >
        <div
          className={classnames({
            [styles.childrenNoPadding]: !noPadding,
            [styles.childrenWrap]: noPadding,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
