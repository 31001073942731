import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { LicencesTable } from '../../../../entities/licences/components/LicencesTable';

export interface LicencesContentProps {
  alertText: string;
  setLicenceToEdit: ActionCreatorWithOptionalPayload<string | undefined>;
  setLicenceToDelete: ActionCreatorWithOptionalPayload<
    Licence | undefined,
    string
  >;
  loading: boolean;
  licences: Licence[];
}

export function LicencesContent({
  alertText,
  setLicenceToEdit,
  loading,
  licences,
  setLicenceToDelete,
}: LicencesContentProps): ReactElement {
  if (licences.length) {
    return (
      <LicencesTable
        licences={licences}
        loading={loading}
        onDelete={setLicenceToDelete}
        onEdit={setLicenceToEdit}
      />
    );
  }

  return <Alert colour="info">{alertText}</Alert>;
}
