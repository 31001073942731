import React, { ReactElement } from 'react';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../components/Modals';
import { DemoTemplateForm } from '../DemoTemplateForm';
import { prepDemoTemplateForEdit } from '../../utils';
import styles from '../DemoTemplates.module.css';

interface AddDemoTemplateModalProps {
  addSelectedArchitecture: ActionCreatorWithPayload<Architecture, string>;
  allArchitectures: Architecture[];
  currentDc: DataCenter;
  colsContent: InfoPanelData[];
  showAddModal: boolean;
  selectedArchitectures?: Architecture[];
  removeArchitecture: ActionCreatorWithPayload<string, string>;
  createDemoTemplate: RTKMutation<CreateDemoTemplatePayload, DemoTemplate>;
  setSelectedDemoTemplate: ActionCreatorWithOptionalPayload<
    DemoTemplate | undefined,
    string
  >;
  templateToEdit: DemoTemplate;
  isCreating: boolean;
}

export function AddDemoTemplateModal({
  addSelectedArchitecture,
  allArchitectures,
  createDemoTemplate,
  currentDc,
  setSelectedDemoTemplate,
  showAddModal,
  selectedArchitectures,
  removeArchitecture,
  colsContent,
  isCreating,
  templateToEdit,
}: AddDemoTemplateModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.root}
      clickScreenToClose={true}
      show={showAddModal}
      onClose={() => setSelectedDemoTemplate(undefined)}
      title={t('templateManager.demoTemplates.form.titles.create')}
      headerAlignment="center"
    >
      <div className={styles.container}>
        <DemoTemplateForm
          isEdit={false}
          onClose={() => setSelectedDemoTemplate(undefined)}
          submitAction={createDemoTemplate}
          addSelectedArchitecture={addSelectedArchitecture}
          currentDc={currentDc}
          removeArchitecture={removeArchitecture}
          selectedArchitectures={selectedArchitectures}
          allArchitectures={allArchitectures}
          colsContent={colsContent}
          isUpdating={isCreating}
          templateToEdit={prepDemoTemplateForEdit(templateToEdit)}
        />
      </div>
    </Modal>
  );
}
