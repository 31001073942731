import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { PageHeading } from '../../../../../components';

export function InboundProxyRulesLoader(): ReactElement {
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeading text={t('traffic.inboundProxyRules.name')} />
      <TableLoader />
    </section>
  );
}
