import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../../../../constants';
import i18n from '../../../../../i18n';
import { WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME } from '../../../../components/DefaultToParentField/DefaultToParentFieldConstants';

type DefaultValue = { [key: string]: string | boolean };

export const buildDefaultValuesForEdit = (
  saasIntegrationToEdit: SaasIntegration,
): { [key: string]: unknown } => {
  const { saasTemplate } = saasIntegrationToEdit;
  const defaultValues: DefaultValue = {
    TBV3_userSaasIntegrationIconDefaultValue: saasIntegrationToEdit?.icon,
    TBV3_userSaasIntegrationNameDefaultValue: saasIntegrationToEdit?.name,
    hiddenFromSessionView:
      saasTemplate.sessionView === 'HIDE' ||
      (saasIntegrationToEdit?.hiddenFromSessionView === true &&
        saasTemplate.sessionView === 'ALLOW'),
  };
  saasTemplate.inputs?.forEach((templateInput) => {
    const userInput = saasIntegrationToEdit?.inputs?.find(
      (input) => input.mappingName === templateInput.mappingName,
    );
    defaultValues[templateInput.mappingName] = templateInput.userConfigurable
      ? userInput?.value || ''
      : templateInput.defaultValue || '';
    if (typeof userInput?.defaultToTemplate === 'boolean') {
      defaultValues[
        `${WORKFLOW_PARAMETER_MAPPING_DEFAULT_TO_PARENT_CHECKBOX_NAME}-${templateInput.mappingName}`
      ] = userInput?.defaultToTemplate;
    }
  });
  return defaultValues;
};

export const determineEditPageTitle = (
  integrationType: IntegrationType,
): string => {
  switch (integrationType) {
    case PUBLIC_CLOUD_INTEGRATION_TYPE:
      return i18n.t('saas.edit.headings.publicCloud');
    case SAAS_INTEGRATION_TYPE:
      return i18n.t('saas.edit.headings.saas');
    default:
      return i18n.t('saas.edit.headings.saas');
  }
};
