import classNames from 'classnames';
import { Button } from 'dcloud-shared-ui';
import React, { ReactElement, ReactNode } from 'react';
import { ButtonGroup } from '../../../../../components';
import styles from './ActionsButtonGroup.module.css';

interface SessionAction {
  content: ReactNode;
  onClick?: VoidFunction;
}

export interface ActionsButtonGroupProps {
  actions: SessionAction[];
  isIconContent?: boolean;
}

export function ActionsButtonGroup({
  actions,
  isIconContent = false,
}: ActionsButtonGroupProps): ReactElement {
  const renderIfAvailable = (
    { content, onClick }: SessionAction,
    index: number,
  ) => {
    if (!onClick) return;

    return (
      <Button key={`${content?.toString()}-${index}`} onClick={onClick}>
        {content}
      </Button>
    );
  };

  return (
    <ButtonGroup
      className={classNames(styles.root, { [styles.icon]: isIconContent })}
    >
      {actions.map(renderIfAvailable)}
    </ButtonGroup>
  );
}
