import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useSkeletonColours } from '../../../../../hooks/use-skeleton-colours';

export function RemoteAccessFormLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  return (
    <ContentLoader
      className="half-margin-top"
      speed={1.5}
      width="100%"
      height={50}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
      <rect x="0" y="30" rx="3" ry="3" width="150" height="20" />
    </ContentLoader>
  );
}
