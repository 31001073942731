import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface OperatingSystemsState {
  operatingSystems?: OperatingSystem[];
}

export const initialState: OperatingSystemsState = {
  operatingSystems: undefined,
};

const slice = createSlice({
  initialState,
  name: 'operatingSystems',
  reducers: {
    setOperatingSystems: (
      state,
      action: PayloadAction<OperatingSystem[] | undefined>,
    ) => {
      state.operatingSystems = action.payload;
    },
  },
});

export const getOperatingSystems = (state: RootState): OperatingSystemsState =>
  state.entities[slice.name];

export const { reducer: operatingSystemsReducer } = slice;
export const { setOperatingSystems } = slice.actions;
