import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Button, IconButton } from 'dcloud-shared-ui';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { Table } from '../../../../../../../../../components';
import { Search } from '../../../../../../../../../components/Search';
import { ArchitectureLabelGroup } from '../../../../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { SEARCH_MIN_CHARS } from '../../../../../../constants';
import { filterItems } from '../../../../../../searchUtils';
import styles from './InventoryTopologiesTable.module.css';

export interface InventoryTopologiesTableProps {
  loading: boolean;
  inventoryTopologies: InventoryTopologies;
  setShowMoreInfoForDemoTemplate: ActionCreatorWithPayload<
    InventoryTopology | undefined,
    string
  >;
  setSelectedTopologyUid: ActionCreatorWithPayload<string | undefined, string>;
  isDiagramView?: boolean;
}

const DEMOS_PAGE_SIZE_LIMIT = 15;

export function InventoryTopologiesTable({
  loading,
  inventoryTopologies,
  setShowMoreInfoForDemoTemplate,
  setSelectedTopologyUid,
  isDiagramView = false,
}: InventoryTopologiesTableProps): ReactElement {
  const [filteredItems, setFilteredItems] =
    useState<InventoryTopologies>(inventoryTopologies);
  const [searchValue, setSearchValue] = useState<string>('');

  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { url } = useRouteMatch();

  const i18nPrefix = 'inventoryTopologies.table.headings.';

  const filterKeys = ['name', 'description', 'architectureNames', 'demoId'];
  function handleSearchChange(newSearchValue: string): void {
    setSearchValue(newSearchValue);
    if (newSearchValue.length === 0) {
      setFilteredItems(inventoryTopologies);
    } else if (newSearchValue.length >= SEARCH_MIN_CHARS) {
      const searchText = newSearchValue.toLowerCase();
      const result = filterItems({
        filterKeys,
        list: inventoryTopologies,
        searchText,
      });
      setFilteredItems(result);
    }
  }

  const columns = useMemo<Column<InventoryTopology>[]>(
    () => [
      {
        Cell: ({ row, value }: CellProps<InventoryTopology>) =>
          isDiagramView ? (
            <Button
              colour="link"
              onClick={() => setSelectedTopologyUid(row.original.uid)}
              className={styles.nameButton}
            >
              {value}
            </Button>
          ) : (
            <Link
              to={`${url.replace(':uid', topologyUid)}/vms`}
              onClick={() => setSelectedTopologyUid(row.original.uid)}
              className={styles.nameLink}
            >
              {value}
            </Link>
          ),
        Header: () => t(`${i18nPrefix}name`),
        accessor: 'name',
      },
      {
        Header: () => t(`${i18nPrefix}description`),
        accessor: 'description',
      },
      {
        Header: () => t(`${i18nPrefix}demoId`),
        accessor: 'demoId',
        maxWidth: 55,
      },
      {
        Cell: ({ row }: CellProps<InventoryTopology>) =>
          row.original.architectureNames ? (
            <ArchitectureLabelGroup
              architectures={row.original.architectureNames}
            />
          ) : null,
        Header: () => t(`${i18nPrefix}architecture`),
        accessor: 'architectures',
        disableSortBy: true,
      },
      {
        Cell: ({ row }: CellProps<InventoryTopology>) => (
          <IconButton
            colour="link"
            onClick={() => setShowMoreInfoForDemoTemplate(row.original)}
            className={styles.nameLink}
            icon="info-outline"
            testId={`inventory-template-info-modal-icon-${row.original.uid}`}
          />
        ),
        Header: () => '',
        accessor: 'uid',
        disableSortBy: true,
        maxWidth: 35,
        minWidth: 35,
      },
    ],
    [
      isDiagramView,
      setSelectedTopologyUid,
      setShowMoreInfoForDemoTemplate,
      t,
      topologyUid,
      url,
    ],
  );

  return (
    <>
      <div className="row flex-right">
        <Search
          className="base-padding"
          id="add-vm-demo-search"
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder={t('common.search')}
          value={searchValue}
        />
      </div>
      <Table<InventoryTopology>
        ariaLabel="Inventory Topologies Table"
        columns={columns}
        loading={loading}
        data={filteredItems}
        initialState={{
          pageSize: DEMOS_PAGE_SIZE_LIMIT,
          sortBy: [{ desc: false, id: 'name' }],
        }}
      />
    </>
  );
}
