import React, { ReactElement } from 'react';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import { PageHeading } from '../../../../../components/PageHeading';
import { determinePageTitle } from '../utils/utils';

type SaasIntegrationsSkeletonLoaderProps = {
  integrationType: IntegrationType;
};

export function SaasIntegrationsSkeletonLoader({
  integrationType,
}: SaasIntegrationsSkeletonLoaderProps): ReactElement {
  return (
    <>
      <div className="half-padding-left">
        <PageHeading pageTitle={determinePageTitle(integrationType)} />
      </div>
      <TableLoader />
    </>
  );
}
