import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomLabelPicker } from '../Labels/CustomLabels/CustomLabelPicker';
import styles from './TagsForm.module.css';
import { filterTagsByAvailability, filterTagsByText } from './utils/utils';

export type TagSuggestionProps = {
  enteredText: string;
  allTags: Tag[];
  selectedTags: Tag[];
  addTag: (tag: Tag) => void;
};

export default function TagSuggestions({
  enteredText,
  allTags,
  addTag,
  selectedTags,
}: TagSuggestionProps): ReactElement {
  const { t } = useTranslation();
  const tagSuggestions = filterTagsByText(
    filterTagsByAvailability(allTags, selectedTags),
    enteredText,
  );
  return (
    <div className={styles.suggestionBox}>
      <hr />
      <div className="half-margin-left">{t('tags.suggestions')}</div>
      <div className="half-margin-left">
        {tagSuggestions.length > 0 ? (
          <CustomLabelPicker
            className={styles.tagCloud}
            labels={tagSuggestions}
            onAdd={addTag}
          />
        ) : (
          <div className={styles.suggestionError}>
            {t('tags.errors.search')}
          </div>
        )}
      </div>
    </div>
  );
}
