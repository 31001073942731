import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudAnyConnect } from './dCloudContainer';
import { ConnectedExpoAnyConnect } from './expoContainer';

export function AnyConnectContainer(): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoAnyConnect
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudAnyConnect
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type AnyConnectProps = {
  anyConnectNode?: AnyConnectNode;
  isLoading: boolean;
  isError: boolean;
};
