import React, { ReactElement } from 'react';
import { TopologiesNoResults } from '../../../../entities/topologies/components';
import { ConnectedTopologiesView } from '../../../../entities/topologies/components/TopologiesView/container';
import { ConnectedSaveCustomContentModal } from '../SaveCustomContentModal/container';

interface TopologiesContentProps {
  topologies: Topology[];
}

export function TopologiesContent({
  topologies,
}: TopologiesContentProps): ReactElement {
  if (topologies.length === 0) {
    return <TopologiesNoResults />;
  }

  return (
    <>
      <ConnectedTopologiesView />
      <ConnectedSaveCustomContentModal />
    </>
  );
}
