import React, { ComponentType, ReactElement } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import i18n from '../../../i18n';
import UserRoleGuardContainer from '../../../auth/components/UserRoleGuard/container';
import { VirtualMachinesRouter } from './VirtualMachines/VirtualMachinesRouter';
import { ConnectedNetworks } from './Networks/container';
import { Router as HardwareRouter } from './Hardware/Router';
import { VMStart } from './StartStopOrder/VMStart';
import { HardwareStart } from './StartStopOrder/HardwareStart';
import { VMStop } from './StartStopOrder/VMStop';
import { RemoteAccessContainer } from './RemoteAccess/container';
import { ConnectedNatRules } from './Traffic/NatRules/container';
import { ConnectedInboundProxyRules } from './Traffic/InboundProxyRules/container';
import { ConnectMailServer } from './Traffic/MailServer/container';
import { ConnectExternalDns } from './Traffic/ExternalDNS/container';
import { ConnectEditExternalDns } from './Traffic/ExternalDNS/ExternalDnsSRV/container';
import { ConnectedLicences } from './Licences/container';
import { GeneralContainer } from './General/container';
import { ConnectedAccessRoleAssignment } from './Share/container';
import { DocumentationContainer } from './Documentation/container';
import { TelephonyContainer } from './Telephony/container';
import { ConnectedScenarios } from './Scenarios/container';
import { SaasRouter } from './SaasIntegrations/SaasRouter';

export interface ConfigurationRoute {
  exact: boolean;
  label: string;
  path: Routes | string;
  requiredRoles?: UserRoles[];
  component: ComponentType<RouteComponentProps>;
  replace?: { part: string; value: string };
}

export interface NestedConfigurationRoute {
  nestedRoutes: ConfigurationRoute[];
  label: string;
  requiredRoles?: UserRoles[];
}

export type ConfigurationRoutes = Array<
  ConfigurationRoute | NestedConfigurationRoute
>;

export enum Routes {
  AccessRoleAssignment = '/share',
  Documentation = '/documentation',
  ExternalDns = '/external-dns',
  General = '/general',
  Hardware = '/hardware',
  HardwareStart = '/hardware-start',
  InboundProxyRules = '/inbound-proxy-rules',
  Integrations = '/integrations/:integrationType',
  Licences = '/licenses',
  MailServers = '/mail-servers',
  NatRules = '/nat-rules',
  Networks = '/networks',
  PublicCloud = 'public-cloud',
  RemoteAccess = '/remote-access',
  Scenarios = '/scenarios',
  Saas = 'saas',
  StartAndStopOrder = '/start-and-stop-order',
  Telephony = '/telephony',
  Traffic = '/traffic',
  VirtualMachines = '/virtual-machines',
  VMStart = '/vm-start',
  VMStop = '/vm-stop',
}

export const routes: ConfigurationRoutes = [
  {
    component: GeneralContainer,
    exact: true,
    label: i18n.t('general.name'),
    path: Routes.General,
  },
  {
    component: ConnectedNetworks,
    exact: true,
    label: i18n.t('networks.name'),
    path: Routes.Networks,
  },
  {
    component: VirtualMachinesRouter,
    exact: false,
    label: i18n.t('virtualMachines.name'),
    path: Routes.VirtualMachines,
  },
  {
    component: HardwareRouter,
    exact: false,
    label: i18n.t('hardware.name'),
    path: Routes.Hardware,
  },
  {
    component: ConnectedLicences,
    exact: true,
    label: i18n.t('licences.name'),
    path: Routes.Licences,
    requiredRoles: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
  },
  {
    label: i18n.t('startStopOrder.name'),
    nestedRoutes: [
      {
        component: VMStart,
        exact: true,
        label: i18n.t('startStopOrder.vmStart.name'),
        path: `${Routes.StartAndStopOrder}${Routes.VMStart}`,
      },
      {
        component: VMStop,
        exact: true,
        label: i18n.t('startStopOrder.vmStop.name'),
        path: `${Routes.StartAndStopOrder}${Routes.VMStop}`,
      },
      {
        component: HardwareStart,
        exact: true,
        label: i18n.t('startStopOrder.hardwareStart.name'),
        path: `${Routes.StartAndStopOrder}${Routes.HardwareStart}`,
      },
    ],
  },
  {
    component: RemoteAccessContainer,
    exact: true,
    label: i18n.t('remoteAccess.name'),
    path: Routes.RemoteAccess,
  },
  {
    component: ConnectedScenarios,
    exact: true,
    label: i18n.t('scenarios.name'),
    path: Routes.Scenarios,
  },
  {
    label: i18n.t('traffic.name'),
    nestedRoutes: [
      {
        component: ConnectedNatRules,
        exact: true,
        label: i18n.t('traffic.natRules.name'),
        path: `${Routes.Traffic}${Routes.NatRules}`,
      },
      {
        component: ConnectedInboundProxyRules,
        exact: true,
        label: i18n.t('traffic.inboundProxyRules.name'),
        path: `${Routes.Traffic}${Routes.InboundProxyRules}`,
      },
      {
        component: ConnectExternalDns,
        exact: true,
        label: i18n.t('traffic.externalDns.name'),
        path: `${Routes.Traffic}${Routes.ExternalDns}`,
      },
      {
        component: ConnectMailServer,
        exact: true,
        label: i18n.t('traffic.mailServer.name'),
        path: `${Routes.Traffic}${Routes.MailServers}`,
      },
    ],
  },
  {
    label: i18n.t('saas.sideBar.root'),
    nestedRoutes: [
      {
        component: SaasRouter,
        exact: false,
        label: i18n.t('saas.sideBar.saas'),
        path: Routes.Integrations,
        replace: { part: ':integrationType', value: Routes.Saas },
      },
      {
        component: SaasRouter,
        exact: false,
        label: i18n.t('saas.sideBar.publicCloud'),
        path: Routes.Integrations,
        replace: { part: ':integrationType', value: Routes.PublicCloud },
        requiredRoles: [
          'ROLE_ADMIN',
          'ROLE_INTEGRATION_MANAGER',
          'ROLE_PUBLIC_CLOUD_USER',
        ],
      },
    ],
  },
  {
    component: DocumentationContainer,
    exact: true,
    label: i18n.t('documentation.name'),
    path: Routes.Documentation,
  },
  {
    component: ConnectedAccessRoleAssignment,
    exact: true,
    label: i18n.t('accessRoleAssignment.name'),
    path: Routes.AccessRoleAssignment,
  },
  {
    component: TelephonyContainer,
    exact: true,
    label: i18n.t('telephony.name'),
    path: Routes.Telephony,
  },
];

export function ConfigurationRouter(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      {routes.map((route) => {
        if ('nestedRoutes' in route) {
          return route.nestedRoutes.map((nestedRoute) => {
            const { component: Component } = nestedRoute;
            return (
              <Route
                {...nestedRoute}
                key={nestedRoute.path}
                path={`${match.path}${nestedRoute.path}`}
                render={(props) => (
                  <UserRoleGuardContainer
                    requiredRoles={route.requiredRoles || []}
                    redirectToMainPageIfNotPermitted={true}
                  >
                    <Component {...props} />
                  </UserRoleGuardContainer>
                )}
              />
            );
          });
        } else {
          const { component: Component, ...rest } = route;
          return (
            <Route
              {...rest}
              key={route.path}
              path={`${match.path}${route.path}`}
              render={(props) => (
                <UserRoleGuardContainer
                  requiredRoles={route.requiredRoles || []}
                  redirectToMainPageIfNotPermitted={true}
                >
                  <Component {...props} />
                </UserRoleGuardContainer>
              )}
            />
          );
        }
      })}

      <Route
        path={`${match.path}${Routes.Traffic}${Routes.ExternalDns}/:externalDnsUid`}
        component={ConnectEditExternalDns}
        exact={true}
      />
      <Redirect to={`${match.path}${Routes.General}`} />
    </Switch>
  );
}
