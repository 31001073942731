import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../../../../components/Dropdown';
import { DropdownItem } from '../../../../../../../components/Dropdown/components/DropdownItem';
import { VirtualMachinesTableActionsCellRendererProps } from './container';

export const VirtualMachinesTableActionsCellRenderer = ({
  mor,
  name,
  nodeType,
  setShowConfirmForActionForVm,
}: VirtualMachinesTableActionsCellRendererProps): ReactElement => {
  const { t } = useTranslation();
  const handleOnClick = (actionType: SessionVmActions) => {
    setShowConfirmForActionForVm({
      actionType,
      mor,
      name,
      nodeType,
    });
  };

  return (
    <Dropdown>
      <DropdownItem
        label={t('sessionsInventory.virtualMachines.table.actions.reset')}
        onClick={() => handleOnClick('VM_RESET')}
      />
      <DropdownItem
        label={t('sessionsInventory.virtualMachines.table.actions.powerOn')}
        onClick={() => handleOnClick('VM_POWER_ON')}
      />
      <DropdownItem
        label={t('sessionsInventory.virtualMachines.table.actions.powerOff')}
        onClick={() => handleOnClick('VM_POWER_OFF')}
      />
      <DropdownItem
        label={t(
          'sessionsInventory.virtualMachines.table.actions.guestOsReboot',
        )}
        onClick={() => handleOnClick('GUEST_OS_REBOOT')}
      />
      <DropdownItem
        label={t(
          'sessionsInventory.virtualMachines.table.actions.guestOsShutdown',
        )}
        onClick={() => handleOnClick('GUEST_OS_SHUTDOWN')}
      />
    </Dropdown>
  );
};
