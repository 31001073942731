import React, { ReactElement } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../components';

export interface ResetSessionModalProps {
  resetSession: VoidFunction;
  isLoading: boolean;
  isSuccess: boolean;
  setExpired: ActionCreatorWithPayload<boolean, string>;
  setShowResetSessionModal: ActionCreatorWithPayload<boolean, string>;
  showResetSessionModal: boolean;
}

export function ResetSessionModal({
  resetSession,
  isLoading,
  isSuccess,
  setExpired,
  setShowResetSessionModal,
  showResetSessionModal,
}: ResetSessionModalProps): ReactElement {
  const { t } = useTranslation();

  const handleSuccess = () => {
    setExpired(true);
    setShowResetSessionModal(false);
  };

  isSuccess && handleSuccess();
  return (
    <ConfirmModal
      heading={t('sessions.modals.resetSession.heading')}
      subHeading={t('sessions.modals.resetSession.subHeading')}
      loading={isLoading}
      onCancel={() => setShowResetSessionModal(false)}
      onClose={() => setShowResetSessionModal(false)}
      onConfirm={() => resetSession()}
      confirmButtonLabel={t('buttons.confirm')}
      cancelButtonLabel={t('buttons.cancel')}
      show={showResetSessionModal}
      size="small"
      testId="reset-session-confirm-modal"
    />
  );
}
