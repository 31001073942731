import { MAX_TOPOLOGY_REMOTE_ACCESS_COUNT } from '../../../constants';

export const determineShowRdpCountAlert = (
  currentRemoteAccessCount: number,
  watchIsRDPEnabled: boolean,
): boolean => {
  return (
    currentRemoteAccessCount >= MAX_TOPOLOGY_REMOTE_ACCESS_COUNT &&
    !watchIsRDPEnabled
  );
};

export const determineShowSshCountAlert = (
  validAdaptersLength: number,
  currentRemoteAccessCount: number,
  watchIsSSHEnabled: boolean,
): boolean => {
  return (
    validAdaptersLength > 0 &&
    currentRemoteAccessCount >= MAX_TOPOLOGY_REMOTE_ACCESS_COUNT &&
    !watchIsSSHEnabled
  );
};
