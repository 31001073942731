import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  clearTopologyToClone,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { useCloneTopologyMutation } from '../../../../redux/topologies/api';
import { CloneTopologyModal } from '.';

export function CloneTopologyFormContainer({
  topologyToClone,
  clearTopologyToClone,
}: PropsFromRedux): ReactElement {
  const [cloneTopology, { isLoading: isCloning }] = useCloneTopologyMutation();

  return (
    <CloneTopologyModal
      isCloning={isCloning}
      cloneTopology={cloneTopology}
      topologyToClone={topologyToClone}
      clearTopologyToClone={clearTopologyToClone}
    />
  );
}
export type PropsFromRedux = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  topologyToClone: topologiesSelectors.getTopologyToClone(state),
});

const mapDispatchToProps = {
  clearTopologyToClone,
};

export const ConnectedCloneTopologyModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloneTopologyFormContainer);
