import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { searchHw, searchVms } from './slice-helpers';

export interface SessionInventoryState {
  showConfirmForActionForHw?: SessionInventoryConfirmActionForHwPayload;
  showConfirmForActionForVm?: SessionInventoryConfirmActionForVmPayload;
  searchHwValue?: string;
  searchVmValue?: string;
  allHwItems?: SessionInventoryHardwareData[];
  allVmItems?: SessionInventoryVirtualMachineData[];
  allVisibleHwItems?: SessionInventoryHardwareData[];
  allVisibleVmItems?: SessionInventoryVirtualMachineData[];
  hasCalledVmStatusesAPICalls: boolean;
  isCallingVmStatusesAPICalls: boolean;
  numberOfMultiActionItemsCompleted?: number;
  selectedAction?: SessionVmActions | SessionHwActionReset;
  selectedHwItemsToAction: SessionInventoryHardwareData[];
  selectedVmItemsToAction: SessionInventoryVirtualMachineData[];
  hasAcceptedMultiSelectActionConfirm?: boolean;
  selectedNodeType?: 'HW' | 'VM';
  isSelectAllHwChecked: boolean;
  isSelectAllVmChecked: boolean;
  numOfMultiSelectApiErrors: number;
  multiSelectVmApiErrorItems: AddMultiSelectApiErrorItemPayload[];
  multiSelectHwApiErrorItems: AddMultiSelectApiErrorItemPayload[];
  showMultiActionProgressBar: boolean;
  vmsRequiringVmStatusesApiCalls?: SessionInventoryVirtualMachineData[];
}

export const initialState: SessionInventoryState = {
  allVisibleHwItems: undefined,
  allVisibleVmItems: undefined,
  hasAcceptedMultiSelectActionConfirm: undefined,
  hasCalledVmStatusesAPICalls: false,
  isCallingVmStatusesAPICalls: false,
  isSelectAllHwChecked: false,
  isSelectAllVmChecked: false,
  multiSelectHwApiErrorItems: [],
  multiSelectVmApiErrorItems: [],
  numOfMultiSelectApiErrors: 0,
  numberOfMultiActionItemsCompleted: undefined,
  searchHwValue: undefined,
  searchVmValue: undefined,
  selectedAction: undefined,
  selectedHwItemsToAction: [],
  selectedNodeType: undefined,
  selectedVmItemsToAction: [],
  showConfirmForActionForHw: undefined,
  showConfirmForActionForVm: undefined,
  showMultiActionProgressBar: false,
  vmsRequiringVmStatusesApiCalls: undefined,
};

export const START_GET_ALL_VM_STATUSES_ACTION =
  'sessionInventory/startGetAllVmStatuses';

export const STOP_GET_ALL_VM_STATUSES_ACTION =
  'sessionInventory/stopGetAllVmStatuses';

export const UNSUBSCRIBE_FROM_ALL_VM_STATUSES_DATA_ACTION =
  'sessionInventory/unsubscribeFromGetAllVmStatuses';

export const unsubscribeFromGetAllVmStatusesData = createAction<undefined>(
  UNSUBSCRIBE_FROM_ALL_VM_STATUSES_DATA_ACTION,
);

const slice = createSlice({
  initialState,
  name: 'sessionInventory',
  reducers: {
    addMultiSelectHwApiErrorItem: (
      state,
      { payload }: PayloadAction<AddMultiSelectApiErrorItemPayload>,
    ) => {
      state.multiSelectHwApiErrorItems.push(payload);
    },
    addMultiSelectVmApiErrorItem: (
      state,
      { payload }: PayloadAction<AddMultiSelectApiErrorItemPayload>,
    ) => {
      state.multiSelectVmApiErrorItems.push(payload);
    },
    addSelectedItemToAction: (
      state,
      { payload }: PayloadAction<AddRemoveSelectedItemToActionPayload>,
    ) => {
      if (payload.page === 'hardware') {
        state.selectedHwItemsToAction.push(
          payload.item as SessionInventoryHardwareData,
        );
      } else {
        state.selectedVmItemsToAction.push(
          payload.item as SessionInventoryVirtualMachineData,
        );
      }
    },
    clearAllSelectedItems: (state) => {
      state.selectedHwItemsToAction = initialState.selectedHwItemsToAction;
      state.selectedVmItemsToAction = initialState.selectedVmItemsToAction;
      state.selectedAction = initialState.selectedAction;
      state.selectedNodeType = initialState.selectedNodeType;
      state.allVisibleHwItems = initialState.allVisibleHwItems;
      state.allVisibleVmItems = initialState.allVisibleVmItems;
      state.allHwItems = initialState.allHwItems;
      state.allVmItems = initialState.allVmItems;
    },
    clearHwMultiSelectErrorItems: (state) => {
      state.multiSelectHwApiErrorItems =
        initialState.multiSelectHwApiErrorItems;
    },
    clearVmMultiSelectErrorItems: (state) => {
      state.multiSelectVmApiErrorItems =
        initialState.multiSelectVmApiErrorItems;
    },
    increamentMultiSelectApiErrors: (state) => {
      state.numOfMultiSelectApiErrors = state.numOfMultiSelectApiErrors + 1;
    },
    incrementNumOfActionsCompleted: (state) => {
      state.numberOfMultiActionItemsCompleted
        ? (state.numberOfMultiActionItemsCompleted =
            state.numberOfMultiActionItemsCompleted + 1)
        : (state.numberOfMultiActionItemsCompleted = 1);
    },
    removeSelectedItemToAction: (
      state,
      { payload }: PayloadAction<AddRemoveSelectedItemToActionPayload>,
    ) => {
      if (payload.page === 'hardware') {
        state.selectedHwItemsToAction = state.selectedHwItemsToAction.filter(
          (item) =>
            item.uid !== (payload.item as SessionInventoryHardwareData).uid,
        );
        state.isSelectAllHwChecked = false;
      } else {
        state.selectedVmItemsToAction = state.selectedVmItemsToAction.filter(
          (item) =>
            item.mor !==
            (payload.item as SessionInventoryVirtualMachineData).mor,
        );
        state.isSelectAllVmChecked = false;
      }
    },
    resetMultiSelect: (state) => {
      state.allVisibleHwItems = initialState.allVisibleHwItems;
      state.allVisibleVmItems = initialState.allVisibleVmItems;
      state.allHwItems = initialState.allHwItems;
      state.allVmItems = initialState.allVmItems;
      state.searchHwValue = initialState.searchHwValue;
      state.searchVmValue = initialState.searchVmValue;
      state.numberOfMultiActionItemsCompleted =
        initialState.numberOfMultiActionItemsCompleted;
      state.selectedAction = initialState.selectedAction;
      state.selectedHwItemsToAction = initialState.selectedHwItemsToAction;
      state.selectedVmItemsToAction = initialState.selectedVmItemsToAction;
      state.hasAcceptedMultiSelectActionConfirm =
        initialState.hasAcceptedMultiSelectActionConfirm;
      state.selectedNodeType = initialState.selectedNodeType;
      state.showMultiActionProgressBar =
        initialState.showMultiActionProgressBar;
      state.isSelectAllHwChecked = initialState.isSelectAllHwChecked;
      state.isSelectAllVmChecked = initialState.isSelectAllVmChecked;
      state.multiSelectHwApiErrorItems =
        initialState.multiSelectHwApiErrorItems;
      state.multiSelectVmApiErrorItems =
        initialState.multiSelectVmApiErrorItems;
      state.numOfMultiSelectApiErrors = initialState.numOfMultiSelectApiErrors;
      state.showConfirmForActionForHw = initialState.showConfirmForActionForHw;
      state.showConfirmForActionForVm = initialState.showConfirmForActionForVm;
    },
    resetNumOfActionsCompleted: (state) => {
      state.numOfMultiSelectApiErrors = initialState.numOfMultiSelectApiErrors;
    },
    setAllHwItems: (
      state,
      { payload }: PayloadAction<SessionInventoryHardwareData[]>,
    ) => {
      state.allHwItems = payload;
      if (state.searchHwValue) {
        state.allVisibleHwItems = searchHw(
          state.allHwItems || [],
          state.searchHwValue,
        );
      } else {
        state.allVisibleHwItems = payload;
      }
    },
    setAllVmItems: (
      state,
      { payload }: PayloadAction<SessionInventoryVirtualMachineData[]>,
    ) => {
      state.allVmItems = payload;
      if (state.searchVmValue) {
        state.allVisibleVmItems = searchVms(
          state.allVmItems || [],
          state.searchVmValue,
        );
      } else {
        state.allVisibleVmItems = payload;
      }
    },
    setHasAcceptedMultiSelectActionConfirm: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.hasAcceptedMultiSelectActionConfirm = payload;
    },
    setHasCalledVmStatusesAPICalls: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.hasCalledVmStatusesAPICalls = payload;
    },
    setIsCallingVmStatusesAPICalls: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isCallingVmStatusesAPICalls = payload;
    },
    setIsSelectAllHwChecked: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectAllHwChecked = payload;
      if (payload === true) {
        state.selectedHwItemsToAction =
          state.allVisibleHwItems?.filter(
            (hwItem) => hwItem.powerControl === 'Yes',
          ) || [];
      } else {
        state.selectedHwItemsToAction = initialState.selectedHwItemsToAction;
      }
    },
    setIsSelectAllVmChecked: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectAllVmChecked = payload;
      if (payload === true) {
        state.selectedVmItemsToAction = state.allVisibleVmItems || [];
      } else {
        state.selectedVmItemsToAction = initialState.selectedVmItemsToAction;
      }
    },
    setNumberOfMultiActionItemsCompleted: (
      state,
      { payload }: PayloadAction<number | undefined>,
    ) => {
      state.numberOfMultiActionItemsCompleted = payload;
    },
    setSearchHwValue: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.searchHwValue = payload;
      state.isSelectAllHwChecked = false;
      state.selectedHwItemsToAction = initialState.selectedHwItemsToAction;
      if (payload === undefined || payload.length === 0) {
        state.searchHwValue = initialState.searchHwValue;
        state.allVisibleHwItems = state.allHwItems;
      } else if (payload.length >= 3) {
        if (state.allVisibleHwItems) {
          state.allVisibleHwItems = searchHw(state.allHwItems || [], payload);
        }
      }
    },
    setSearchVmValue: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.searchVmValue = payload;
      state.isSelectAllVmChecked = false;
      state.selectedVmItemsToAction = initialState.selectedVmItemsToAction;
      if (payload === undefined || payload.length === 0) {
        state.searchVmValue = initialState.searchVmValue;
        state.allVisibleVmItems = state.allVmItems;
      } else if (payload.length >= 3) {
        if (state.allVisibleVmItems) {
          state.allVisibleVmItems = searchVms(state.allVmItems || [], payload);
        }
      }
    },
    setSelectedAction: (
      state,
      {
        payload,
      }: PayloadAction<SessionVmActions | SessionHwActionReset | undefined>,
    ) => {
      if (payload) {
        state.selectedAction = payload;
        state.hasAcceptedMultiSelectActionConfirm = false;
        state.selectedNodeType = ['POWER_ON', 'POWER_OFF', 'RESET'].includes(
          payload,
        )
          ? 'HW'
          : 'VM';
      } else {
        state.selectedAction = payload;
        state.hasAcceptedMultiSelectActionConfirm = undefined;
      }
    },
    setSelectedNodeType: (state, { payload }: PayloadAction<'HW' | 'VM'>) => {
      state.selectedNodeType = payload;
    },
    setShowConfirmForActionForHw: (
      state,
      {
        payload,
      }: PayloadAction<SessionInventoryConfirmActionForHwPayload | undefined>,
    ) => {
      state.showConfirmForActionForHw = payload;
    },
    setShowConfirmForActionForVm: (
      state,
      {
        payload,
      }: PayloadAction<SessionInventoryConfirmActionForVmPayload | undefined>,
    ) => {
      state.showConfirmForActionForVm = payload;
    },
    setShowMultiActionProgressBar: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showMultiActionProgressBar = payload;
    },
    startGetAllVmStatuses: (
      state,
      {
        payload,
      }: PayloadAction<
        StartGetAllVmStatusesForSessionInventoryPayload | undefined
      >,
    ) => {
      if (payload) {
        state.vmsRequiringVmStatusesApiCalls = payload;
      }
    },
    stopGetAllVmStatuses: (state) => {
      state.vmsRequiringVmStatusesApiCalls =
        initialState.vmsRequiringVmStatusesApiCalls;
      state.isCallingVmStatusesAPICalls = false;
    },
  },
});

export const getSessionInventoryState = (
  state: RootState,
): SessionInventoryState => state[slice.name];

export const { reducer: sessionInventoryReducer } = slice;

export const {
  addMultiSelectHwApiErrorItem,
  addMultiSelectVmApiErrorItem,
  clearHwMultiSelectErrorItems,
  clearVmMultiSelectErrorItems,
  addSelectedItemToAction,
  removeSelectedItemToAction,
  clearAllSelectedItems,
  startGetAllVmStatuses,
  stopGetAllVmStatuses,
  setIsCallingVmStatusesAPICalls,
  setHasCalledVmStatusesAPICalls,
  setSearchHwValue,
  setSearchVmValue,
  setShowConfirmForActionForHw,
  setShowConfirmForActionForVm,
  setSelectedAction,
  setSelectedNodeType,
  setIsSelectAllHwChecked,
  setIsSelectAllVmChecked,
  setAllVmItems,
  setAllHwItems,
  setNumberOfMultiActionItemsCompleted,
  setShowMultiActionProgressBar,
  setHasAcceptedMultiSelectActionConfirm,
  incrementNumOfActionsCompleted,
  increamentMultiSelectApiErrors,
  resetNumOfActionsCompleted,
  resetMultiSelect,
} = slice.actions;

export const sessionInventorySelectors = {
  getAllHwItems: createSelector(
    getSessionInventoryState,
    ({ allHwItems }) => allHwItems,
  ),
  getAllVisibleHwItems: createSelector(
    getSessionInventoryState,
    ({ allVisibleHwItems }) => allVisibleHwItems,
  ),
  getAllVisibleVmItems: createSelector(
    getSessionInventoryState,
    ({ allVisibleVmItems }) => allVisibleVmItems,
  ),
  getAllVmItems: createSelector(
    getSessionInventoryState,
    ({ allVmItems }) => allVmItems,
  ),
  getHasAcceptedMultiSelectActionConfirm: createSelector(
    getSessionInventoryState,
    ({ hasAcceptedMultiSelectActionConfirm }) =>
      hasAcceptedMultiSelectActionConfirm,
  ),
  getHasCalledVmStatusesAPICalls: createSelector(
    getSessionInventoryState,
    ({ hasCalledVmStatusesAPICalls }) => hasCalledVmStatusesAPICalls,
  ),
  getIsCallingVmStatusesAPICalls: createSelector(
    getSessionInventoryState,
    ({ isCallingVmStatusesAPICalls }) => isCallingVmStatusesAPICalls,
  ),
  getIsSelectAllHwChecked: createSelector(
    getSessionInventoryState,
    ({ isSelectAllHwChecked }) => isSelectAllHwChecked,
  ),
  getIsSelectAllVmChecked: createSelector(
    getSessionInventoryState,
    ({ isSelectAllVmChecked }) => isSelectAllVmChecked,
  ),
  getMultiSelectHwApiErrorItems: createSelector(
    getSessionInventoryState,
    ({ multiSelectHwApiErrorItems }) => multiSelectHwApiErrorItems,
  ),
  getMultiSelectVmApiErrorItems: createSelector(
    getSessionInventoryState,
    ({ multiSelectVmApiErrorItems }) => multiSelectVmApiErrorItems,
  ),
  getNumOfMultiSelectApiErrors: createSelector(
    getSessionInventoryState,
    ({ numOfMultiSelectApiErrors }) => numOfMultiSelectApiErrors,
  ),
  getNumberOfMultiActionItemsCompleted: createSelector(
    getSessionInventoryState,
    ({ numberOfMultiActionItemsCompleted }) =>
      numberOfMultiActionItemsCompleted,
  ),
  getSearchHwValue: createSelector(
    getSessionInventoryState,
    ({ searchHwValue }) => searchHwValue,
  ),
  getSearchVmValue: createSelector(
    getSessionInventoryState,
    ({ searchVmValue }) => searchVmValue,
  ),
  getSelectedAction: createSelector(
    getSessionInventoryState,
    ({ selectedAction }) => selectedAction,
  ),
  getSelectedHwItemsToAction: createSelector(
    getSessionInventoryState,
    ({ selectedHwItemsToAction }) => selectedHwItemsToAction,
  ),
  getSelectedNodeType: createSelector(
    getSessionInventoryState,
    ({ selectedNodeType }) => selectedNodeType,
  ),
  getSelectedVmItemsToAction: createSelector(
    getSessionInventoryState,
    ({ selectedVmItemsToAction }) => selectedVmItemsToAction,
  ),
  getShowConfirmForActionForHw: createSelector(
    getSessionInventoryState,
    ({ showConfirmForActionForHw }) => showConfirmForActionForHw,
  ),
  getShowConfirmForActionForVm: createSelector(
    getSessionInventoryState,
    ({ showConfirmForActionForVm }) => showConfirmForActionForVm,
  ),
  getShowMultiActionProgressBar: createSelector(
    getSessionInventoryState,
    ({ showMultiActionProgressBar }) => showMultiActionProgressBar,
  ),
  getVmsRequiringVmStatusesApiCalls: createSelector(
    getSessionInventoryState,
    ({ vmsRequiringVmStatusesApiCalls }) => vmsRequiringVmStatusesApiCalls,
  ),
};
