import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../redux/views/session-view/session/api/expoApi';
import { PanelHeaderButtons } from '../index';
import { IntegrationLeftPanelHeaderButtonsProps } from './container';

type ConnectedExpoIntegrationLeftPanelHeaderButtonsProps =
  IntegrationLeftPanelHeaderButtonsProps & FetchExpoSessionArgs;

export function ConnectedExpoIntegrationLeftPanelHeaderButtons({
  engagementUid,
  sessionUid,
  setLeftPanelType,
  versionUid,
}: ConnectedExpoIntegrationLeftPanelHeaderButtonsProps): ReactElement {
  const { refetch } =
    expoSessionApi.endpoints.getExpoSession.useQuerySubscription({
      engagementUid,
      sessionUid,
      versionUid,
    });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
