import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from '../../../../../../../../../components/Dropdown/components/DropdownItem';

type PowerControlDropdownItemProps = {
  deviceId?: number;
  isPowerControlEnabled: boolean;
  handlePowerResetOnClick: VoidFunction;
};

export const PowerControlDropdownItem = ({
  deviceId,
  isPowerControlEnabled,
  handlePowerResetOnClick,
}: PowerControlDropdownItemProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <DropdownItem
      onClick={
        deviceId && isPowerControlEnabled
          ? () => handlePowerResetOnClick()
          : undefined
      }
      label={t('sessionsInventory.hardware.table.actions.resetPower')}
    />
  );
};
