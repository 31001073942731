import React, { ReactElement } from 'react';
import { GridApi } from 'ag-grid-community';
import { Icon } from 'dcloud-shared-ui';
import classnames from 'classnames';
import styles from './GridEditor.module.css';
import { determineEditAriaLabel } from './utils/util';
import {
  DELETE_ARIA_LABEL,
  INFO_ARIA_LABEL,
  MODIFY_ARIA_LABEL,
  UPDATE_ARIA_LABEL,
} from './constants';

export type GridActionsProps = {
  data: GridEditorRecord;
  rowIndex: number;
  editingRowIndex?: number | null;
  onEditClick?: (cell: GridActionsProps) => void;
  onDeleteClick?: (cell: GridActionsProps) => void;
  onInfoClick?: (cell: GridActionsProps) => void;
  gridApi: GridApi;
  editAriaLabel?: string;
  infoAriaLabel?: string;
  deleteAriaLabel?: string;
  updateAriaLabel?: string;
};

const GridActions = (props: GridActionsProps): ReactElement | null => {
  const {
    gridApi,
    onDeleteClick,
    onEditClick,
    onInfoClick,
    updateAriaLabel = UPDATE_ARIA_LABEL,
    editAriaLabel = MODIFY_ARIA_LABEL,
    deleteAriaLabel = DELETE_ARIA_LABEL,
    infoAriaLabel = INFO_ARIA_LABEL,
    editingRowIndex,
    rowIndex,
  } = props;
  const isEditing = rowIndex === editingRowIndex;
  const onKeyUpInfo = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' && onInfoClick) {
      onInfoClick(props);
    }
  };
  const onKeyUpEdit = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      if (isEditing) {
        gridApi?.stopEditing(false);
      } else if (onEditClick) {
        onEditClick(props);
      }
    }
  };
  const onKeyUpDelete = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' && onDeleteClick) {
      onDeleteClick(props);
    }
  };
  const handleOnEditClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (isEditing) {
      gridApi?.stopEditing(false);
    } else if (onEditClick) {
      onEditClick(props);
    }
  };
  const handleOnDeleteClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onDeleteClick) {
      onDeleteClick(props);
    }
  };
  const handleOnInfoClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onInfoClick) {
      onInfoClick(props);
    }
  };
  return (
    <div className={isEditing ? '' : styles.hidden}>
      <div className="flex">
        {onInfoClick && (
          <a
            aria-label={infoAriaLabel}
            className={classnames(
              'half-padding-left half-padding-right',
              styles.icon,
            )}
            href=""
            onClick={handleOnInfoClick}
            onKeyUp={onKeyUpInfo}
            tabIndex={1}
          >
            <Icon name="info-outline" />
          </a>
        )}
        {onEditClick && (
          <a
            aria-label={determineEditAriaLabel(
              isEditing,
              editAriaLabel,
              updateAriaLabel,
            )}
            className={classnames(
              'half-padding-left half-padding-right',
              styles.icon,
            )}
            href=""
            onClick={handleOnEditClick}
            onKeyUp={onKeyUpEdit}
            tabIndex={1}
          >
            <Icon name={isEditing ? 'check' : 'pencil'} />
          </a>
        )}
        {onDeleteClick && (
          <a
            tabIndex={1}
            aria-label={deleteAriaLabel}
            className={classnames(
              'half-padding-left half-padding-right',
              styles.icon,
            )}
            href=""
            onClick={handleOnDeleteClick}
            onKeyUp={onKeyUpDelete}
          >
            <Icon name="delete" />
          </a>
        )}
      </div>
    </div>
  );
};

export default GridActions;
