import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useGetInventoryDnsAssetsQuery,
  useGetVmNicTargetsQuery,
} from '../../../../../redux/configuration/traffic/api';
import { useGetMailServersQuery } from '../../../../../redux/configuration/traffic/mail-server/api';
import {
  getMailServers,
  setMailServerToDelete,
} from '../../../../../redux/configuration/traffic/mail-server/slice';
import { RootState } from '../../../../../redux/store';
import { MailServerLoader } from './components/MailServerLoader';
import { MailServers } from '.';

export function MailServerContainer({
  mailServerToDelete,
  setMailServerToDelete,
}: MailServerContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isError, isLoading } = useGetMailServersQuery(topologyUid);
  const {
    data: vmTargetsData,
    isLoading: isLoadingVms,
    isFetching: isFetchingVms,
  } = useGetVmNicTargetsQuery(topologyUid);
  const { data: dnsAssets } = useGetInventoryDnsAssetsQuery(topologyUid);

  if (isLoading || isLoadingVms || isFetchingVms) {
    return <MailServerLoader />;
  }

  return (
    <MailServers
      dnsAssets={dnsAssets?.inventoryDnsAssets}
      mailServers={data?.mailServers}
      isError={isError}
      isLoading={isLoading}
      mailServerToDelete={mailServerToDelete}
      setMailServerToDelete={setMailServerToDelete}
      vmTargets={vmTargetsData ? vmTargetsData.internalIps : []}
    />
  );
}

export type MailServerContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

interface MailServerRTKProps {
  mailServers?: MailServer[];
  dnsAssets?: InventoryDnsAsset[];
  isError: boolean;
  isLoading: boolean;
  vmTargets?: VmNicTarget[];
}

export type MailServerProps = MailServerContainerProps & MailServerRTKProps;

const mapStateToProps = (state: RootState) => ({
  mailServerToDelete: getMailServers(state).mailServerToDelete,
});

const mapDispatchToProps = {
  setMailServerToDelete,
};

export const ConnectMailServer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MailServerContainer);
