import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { documentationFieldsSchema } from '../../../../../../validation';
import { ControlledTextfield } from '../../../../../../components';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { DocumentationProps } from '../../container';
import { FormUnsavedChangesPrompt } from '../../../../../../components/FormUnsavedChangesPrompt';
import { useLeaveFormPrompt } from '../../../../hooks/useLeaveFormPrompt';
import styles from './Documentation.module.css';

interface DocumentationFormProps {
  documentation: Documentation;
  updateDocumentation: DocumentationProps['updateDocumentation'];
}

export function DocumentationForm({
  documentation,
  updateDocumentation,
}: DocumentationFormProps): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const defaultValues: DocumentationFields = {
    documentationUrl: documentation.documentationUrl || '',
  };
  const {
    handleSubmit,
    errors,
    control,
    formState: { dirtyFields, isValid, isSubmitting, isDirty },
  } = useForm<DocumentationFields>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(documentationFieldsSchema),
  });

  const onSubmit = (data: DocumentationFields): void => {
    updateDocumentation({ ...data, uid: topologyUid });
  };

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <ControlledTextfield
          defaultValue={defaultValues.documentationUrl}
          control={control}
          error={errors.documentationUrl?.message}
          name="documentationUrl"
          label={t('documentation.documentationUrl')}
          labelClass="col-3"
        />
        <PrimaryButton
          loading={isSubmitting}
          disabled={!Object.keys(dirtyFields).length || !isValid}
          className="pull-right dbl-margin-top"
          type="submit"
          colour="success"
        >
          {t('buttons.save')}
        </PrimaryButton>
      </form>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
}
