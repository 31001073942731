import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledTextfield } from '../../../../../../../../components';
import { parseChangeEventToNumber, parseNumberToString } from '../../utils';

export function CpuInput(): ReactElement {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();

  return (
    <ControlledTextfield
      transform={{
        input: parseNumberToString,
        output: parseChangeEventToNumber,
      }}
      control={control}
      error={errors?.cpuQty?.message}
      label={t('virtualMachines.edit.cpuQty')}
      name="cpuQty"
      id="cpuQty"
      required={true}
      testId="edit-vm-cpuQty"
      isNumber={true}
    />
  );
}
