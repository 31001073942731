import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetSaasIntegrationQuery,
  useGetSaasIntegrationsQuery,
  useUpdateSaasIntegrationMutation,
} from '../../../../../redux/configuration/saas/api';
import { EditSaaSIntegrationLoader } from './components/EditSaaSIntegrationLoader';
import { SaasEdit } from '.';

type ConnectedSaasEditProps = {
  integrationType: IntegrationType;
};

export const ConnectedSaasEdit = ({
  integrationType,
}: ConnectedSaasEditProps): ReactElement => {
  const [updateSaasIntegration, { isLoading: isUpdating }] =
    useUpdateSaasIntegrationMutation();
  const { saasIntegrationToEditUid } = useParams<{
    saasIntegrationToEditUid: string;
  }>();
  const {
    data: saasIntegrationToEdit,
    isLoading: isLoadingSaasIntegration,
    isFetching: isFetchingSaasIntegration,
  } = useGetSaasIntegrationQuery(saasIntegrationToEditUid);
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    data: userSaasIntegrations,
    isLoading: isLoadingUserSaasIntegrations,
    isError: isErrorUserSaasIntegrations,
  } = useGetSaasIntegrationsQuery(topologyUid);

  return isLoadingSaasIntegration ||
    isFetchingSaasIntegration ||
    !saasIntegrationToEdit ||
    isUpdating ||
    isLoadingUserSaasIntegrations ||
    isErrorUserSaasIntegrations ? (
    <EditSaaSIntegrationLoader integrationType={integrationType} />
  ) : (
    <SaasEdit
      isUpdating={isUpdating}
      updateSaasIntegration={updateSaasIntegration}
      saasIntegrationToEdit={saasIntegrationToEdit}
      userSaasIntegrations={userSaasIntegrations?.saasIntegrations || []}
      integrationType={integrationType}
    />
  );
};

type SaasEditRTKProps = {
  isUpdating: boolean;
  updateSaasIntegration: RTKMutation<
    PutSaasIntegrationPayload,
    SaasIntegration
  >;
  userSaasIntegrations: SaasIntegration[];
};

export type SaasEditProps = SaasEditRTKProps & {
  saasIntegrationToEdit: SaasIntegration;
  integrationType: IntegrationType;
};
