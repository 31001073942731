import { connect } from 'react-redux';
import {
  getVirtualMachines,
  setShowMoreInfoForInventoryTopology,
} from '../../../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../../../../redux/store';
import { InventoryTopologiesTableInfoModal } from '.';

export type InventoryTopologiesTableInfoModalProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  inventoryTopology: getVirtualMachines(state).showMoreInfoForInventoryTopology,
});

const mapDispatchToProps = {
  setShowMoreInfoForInventoryTopology,
};

export const InventoryTopologiesTableInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryTopologiesTableInfoModal);
