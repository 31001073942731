import React, { ReactElement } from 'react';
import { Control, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledTextfield } from '../../../../../../components';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../../../components/FormUnsavedChangesPrompt';
import { useLeaveFormPrompt } from '../../../../hooks/useLeaveFormPrompt';
import styles from './AccessRoleAssignmentAddForm.module.css';

interface AccessRoleAssignmentAddFormProps {
  control: Control<AccessRoleAssignmentFormData>;
  errors: UseFormMethods['errors'];
  formState: UseFormMethods['formState'];
  handleSubmit: UseFormMethods['handleSubmit'];
  isCreating: boolean;
  handleCreate: SubmitHandler<AccessRoleAssignmentFormData>;
}

export function AccessRoleAssignmentAddForm({
  control,
  errors,
  isCreating,
  formState,
  handleCreate,
  handleSubmit,
}: AccessRoleAssignmentAddFormProps): ReactElement | null {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <>
      <form
        aria-label="Add access role assignment form"
        className={styles.root}
        onSubmit={handleSubmit(handleCreate)}
      >
        <div className="flex flex-left align-items-center">
          <div className={styles.emailInput}>
            <ControlledTextfield
              control={control}
              label={t('accessRoleAssignment.table.headings.email')}
              name="email"
              required={true}
              error={errors?.email?.message}
              testId="add-access-role-email-input"
            />
          </div>
          <PrimaryButton
            disabled={!isDirty || !isValid}
            loading={isCreating}
            type="submit"
            className="dbl-margin-left"
            testId="add-access-role-button"
          >
            {t('buttons.add')}
          </PrimaryButton>
        </div>
      </form>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
}
