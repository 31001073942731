import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../components/Accordion';
import { ControlContainer } from './components/Control/container';
import { InfoContainer } from './components/Info/container';
import { VmPanelRemoteAccessContainer } from './components/RemoteAccess/container';
import { IpAddressesTableContainer } from './components/IpAdresses/container';

export function VMPanel(): ReactElement {
  const { t } = useTranslation();

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <InfoContainer />,
        title: t('sessions.panels.vm.info.sectionHeader'),
      },
      {
        children: <VmPanelRemoteAccessContainer />,
        title: t('sessions.panels.vm.remoteAccess.sectionHeader'),
      },
      {
        children: <ControlContainer />,
        title: t('sessions.panels.vm.control.sectionHeader'),
      },
      {
        children: <IpAddressesTableContainer />,
        title: t('sessions.panels.vm.ipAddress.sectionHeader'),
      },
    ],
    [t],
  );

  return <Accordion rows={accordionRows} size="small" />;
}
