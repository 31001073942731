import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { determineConfigurePageTitle } from '../../utils';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '../../../../../../../components/PageHeading';
import { useSkeletonColours } from '../../../../../../../hooks/use-skeleton-colours';

type ConfigureIntegrationLoaderProps = {
  integrationType: IntegrationType;
};

export function ConfigureIntegrationLoader({
  integrationType,
}: ConfigureIntegrationLoaderProps): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeading
        useCustomStyling={true}
        withBackBtn={true}
        ctaBtn={
          <PrimaryButton
            disabled={true}
            colour="success"
            type="submit"
            loading={false}
          >
            {t('buttons.save')}
          </PrimaryButton>
        }
        pageTitle={determineConfigurePageTitle(integrationType)}
      />
      <ContentLoader
        className="qtr-margin-top"
        speed={1.5}
        width="100%"
        height={640}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" width="539" height="48" />
        <rect x="0" y="80" width="539" height="68" />
        <rect x="0" y="190" width="539" height="80" />
        <rect x="0" y="300" width="539" height="80" />
        <rect x="0" y="400" width="24" height="24" />
        <rect x="38" y="400" width="200" height="24" />
        <rect x="0" y="450" width="78" height="70" />
        <rect x="100" y="465" width="200" height="40" />
        <rect x="0" y="540" width="100%" height="100" />
      </ContentLoader>
    </section>
  );
}
