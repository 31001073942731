import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setVideoToPlay, videoSelectors } from '../../../../redux/video/slice';
import VideoModal from './';

const VideoModalContainer = ({ videoToPlay, setVideoToPlay }: Props) =>
  videoToPlay ? (
    <VideoModal videoToPlay={videoToPlay} setVideoToPlay={setVideoToPlay} />
  ) : null;

export type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  videoToPlay: videoSelectors.getVideoToPlay(state),
});

const mapDispatchToProps = {
  setVideoToPlay,
};

export const ConnectedVideoModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoModalContainer);
