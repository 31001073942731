import { FunctionComponent, SVGProps } from 'react';
import {
  Aws,
  Duo,
  PublicCloudDefault,
  SaaSConnect,
  SecureEndPoint,
  ThousandEyes,
  Umbrella,
} from '../../assets/images/icons';

type IntegrationIcon = {
  component: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: IntegrationIconLabel;
};

export const INTEGRATION_ICONS: IntegrationIcon[] = [
  { component: SaaSConnect, label: 'saaSConnect' },
  { component: Duo, label: 'duo' },
  {
    component: SecureEndPoint,
    label: 'secureEndPoint',
  },
  {
    component: ThousandEyes,
    label: 'thousandEyes',
  },
  {
    component: Umbrella,
    label: 'umbrella',
  },
];

export const CLOUD_INTEGRATION_ICONS: IntegrationIcon[] = [
  { component: PublicCloudDefault, label: 'cloud' },
  { component: Aws, label: 'aws' },
];
