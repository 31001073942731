import React, { PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';

type ButtonGroupType = 'default' | 'square';

export type ButtonGroupProps = PropsWithChildren<{
  className?: string;
  type?: ButtonGroupType;
}>;

export function ButtonGroup({
  className,
  children,
  type = 'default',
}: ButtonGroupProps): ReactElement {
  const classes = classnames('btn-group', `btn-group--${type}`, className);

  return <div className={classes}>{children}</div>;
}
