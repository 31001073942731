import { Button } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface PushActionPushButtonProps {
  onClick: VoidFunction;
  isDisabled?: boolean;
}

export function PushActionPushButton({
  onClick,
  isDisabled,
}: PushActionPushButtonProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Button
      colour="primary"
      onClick={onClick}
      size="small"
      disabled={isDisabled}
    >
      {t('topologies.push.pushButtonText')}
    </Button>
  );
}
