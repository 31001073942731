import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { SESSION_INVENTORY_SIDE_TABS_LABELS } from '../../../../pages/Views/SessionInventory/constants';

export interface SessionViewState {
  expired: boolean;
  sharedWithUsers: ShareUser[];
  showExpiredModal: boolean;
  showEndSessionModal: boolean;
  showSaveAndEndSessionModal: boolean;
  showResetSessionModal: boolean;
  showRenameSessionModal: boolean;
  showExtendSessionModal: boolean;
  leftPanelType?: SessionPanel;
  leftPanelDeviceUid?: string;
  sessionInventoryActiveTab: string;
}

export const initialState: SessionViewState = {
  expired: false,
  sessionInventoryActiveTab: SESSION_INVENTORY_SIDE_TABS_LABELS.hardware,
  sharedWithUsers: [],
  showEndSessionModal: false,
  showExpiredModal: false,
  showExtendSessionModal: false,
  showRenameSessionModal: false,
  showResetSessionModal: false,
  showSaveAndEndSessionModal: false,
};

const slice = createSlice({
  initialState,
  name: 'sessionView',
  reducers: {
    addSharedUser: (state, { payload }: PayloadAction<ShareUser>) => {
      state.sharedWithUsers = [...state.sharedWithUsers, payload];
    },
    removeSharedUser(state, { payload }: PayloadAction<ShareUser>) {
      state.sharedWithUsers = state.sharedWithUsers.filter(
        (sharedUser) => sharedUser.email !== payload.email,
      );
    },
    setExpired: (state, { payload }: PayloadAction<boolean>) => {
      state.expired = payload;
    },
    setLeftPanelDeviceUid: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.leftPanelDeviceUid = payload;
      if (!payload) {
        state.leftPanelType = undefined;
      }
    },
    setLeftPanelType: (
      state,
      { payload }: PayloadAction<SessionPanel | undefined>,
    ) => {
      state.leftPanelType = payload;
      if (!payload) {
        state.leftPanelDeviceUid = undefined;
      }
    },
    setSessionInventoryActiveTab: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.sessionInventoryActiveTab = payload;
    },
    setSharedWithUsers: (state, { payload }: PayloadAction<ShareUser[]>) => {
      state.sharedWithUsers = payload;
    },
    setShowEndSessionModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showEndSessionModal = payload;
    },
    setShowExpiredModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showExpiredModal = payload;
    },
    setShowExtendSessionModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showExtendSessionModal = payload;
    },
    setShowRenameSessionModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showRenameSessionModal = payload;
    },
    setShowResetSessionModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showResetSessionModal = payload;
    },
    setShowSaveAndEndSessionModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showSaveAndEndSessionModal = payload;
    },
  },
});

export const getSessionViewState = (state: RootState): SessionViewState =>
  state[slice.name];

export const { reducer: sessionViewReducer } = slice;
export const {
  addSharedUser,
  removeSharedUser,
  setExpired,
  setSharedWithUsers,
  setShowExpiredModal,
  setShowEndSessionModal,
  setShowSaveAndEndSessionModal,
  setShowResetSessionModal,
  setShowRenameSessionModal,
  setShowExtendSessionModal,
  setLeftPanelType,
  setLeftPanelDeviceUid,
  setSessionInventoryActiveTab,
} = slice.actions;

export const sessionViewSelectors = {
  getExpired: createSelector(getSessionViewState, ({ expired }) => expired),
  getLeftPanelDeviceUid: createSelector(
    getSessionViewState,
    ({ leftPanelDeviceUid }) => leftPanelDeviceUid,
  ),
  getLeftPanelType: createSelector(
    getSessionViewState,
    ({ leftPanelType }) => leftPanelType,
  ),
  getSessionInventoryActiveTab: createSelector(
    getSessionViewState,
    ({ sessionInventoryActiveTab }) => sessionInventoryActiveTab,
  ),
  getSharedWithUsers: createSelector(
    getSessionViewState,
    ({ sharedWithUsers }) => sharedWithUsers,
  ),
  getShowEndSessionModal: createSelector(
    getSessionViewState,
    ({ showEndSessionModal }) => showEndSessionModal,
  ),
  getShowExpiredModal: createSelector(
    getSessionViewState,
    ({ showExpiredModal }) => showExpiredModal,
  ),
  getShowExtendSessionModal: createSelector(
    getSessionViewState,
    ({ showExtendSessionModal }) => showExtendSessionModal,
  ),
  getShowRenameSessionModal: createSelector(
    getSessionViewState,
    ({ showRenameSessionModal }) => showRenameSessionModal,
  ),
  getShowResetSessionModal: createSelector(
    getSessionViewState,
    ({ showResetSessionModal }) => showResetSessionModal,
  ),
  getShowSaveAndEndSessionModal: createSelector(
    getSessionViewState,
    ({ showSaveAndEndSessionModal }) => showSaveAndEndSessionModal,
  ),
};
