import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { EndpointKits } from '.';

export function ConnectedExpoEndpointKits({
  engagementUid,
  sessionUid,
  versionUid,
}: FetchExpoSessionArgs): ReactElement {
  const { endpoints, isLoading, isFetching, isError } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          endpoints: data?.sessionDetails.endpoints,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <EndpointKits
      endpoints={endpoints}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
