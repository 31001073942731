import React, { ReactElement } from 'react';
import { DefaultIntegrationIcon } from '../components/SVGIcons/DefaultIntegrationIcon';

export const getSelectedIconFromAvailableIcons = (
  selectedIcon: string,
  availableIcons: ReactElement[],
): ReactElement =>
  availableIcons.find((icon) => icon.key === selectedIcon) || (
    <DefaultIntegrationIcon />
  );
