import classNames from 'classnames';
import { Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './EndpointKitHeader.module.css';

export function EndpointKitHeader({
  status,
  name,
}: EndpointKitHeaderType): ReactElement {
  const renderIcon = () => {
    switch (status) {
      case 'UKNOWN':
        return (
          <span
            data-testid="unknown-endpoint-kit-header-icon"
            className={classNames(styles.unknown, styles.icon)}
          >
            ?
          </span>
        );
      case 'ACTIVE':
        return (
          <Icon
            testId="active-endpoint-kit-header-icon"
            name="check"
            className={classNames(styles.enabled, styles.icon)}
          />
        );
      case 'DISCONNECTED':
        return (
          <Icon
            testId="disconnected-endpoint-kit-header-icon"
            name="close"
            className={classNames(styles.disabled, styles.icon)}
          />
        );
      default:
        return (
          <span
            data-testid="unknown-endpoint-kit-header-icon"
            className={classNames(styles.unknown, styles.icon)}
          >
            ?
          </span>
        );
    }
  };

  return (
    <div className={classNames('base-padding-bottom', styles.root)}>
      <span>{renderIcon()}</span>
      <span className={styles.label}>{name}</span>
    </div>
  );
}
