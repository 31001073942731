import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAvailableInventoryNetworksByType,
  getIsLastRouted,
  getNetworkInventoryIdsByType,
  getNetworkNames,
} from '../../../../pages/EditTopology/configuration/Networks/utils';
import { GeneralFormLoader } from '../../../../pages/EditTopology/configuration/components/VirtualMachineEntities/Edit/components/GeneralFormLoader';
import {
  networksApi,
  useGetNetworkQuery,
  useUpdateNetworkMutation,
} from '../../../../redux/configuration/networks/api';
import { networksSelectors } from '../../../../redux/configuration/networks/slice';
import { RootState } from '../../../../redux/store';
import {
  useGetNodeQuery,
  useUpdateNodeMutation,
} from '../../../../redux/diagram/api';
import { extractIdFromUrl } from '../../../../utils/helpers';
import { EditNetworkForm } from './EditNetworkForm';

function EditNetworkFormContainer({
  networkToEdit,
  customOnSubmit,
}: EditNetworkFormContainerProps): ReactElement {
  const [updateNetwork, { isLoading: isUpdating }] = useUpdateNetworkMutation();
  const [updateNode] = useUpdateNodeMutation();
  const { data: nodeData, isLoading: isLoadingNodeData } = useGetNodeQuery(
    extractIdFromUrl(networkToEdit!._links!.node.href),
  );
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    data: networks,
    networkNames,
    isLastRouted,
  } = networksApi.endpoints.getNetworks.useQueryState(topologyUid, {
    selectFromResult: ({ data }) => ({
      data,
      isLastRouted: getIsLastRouted(data || []),
      networkNames: getNetworkNames(data || []),
    }),
  });

  const { availableRouted, availableUnRouted } =
    networksApi.endpoints.getInventoryNetworks.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        availableRouted: getAvailableInventoryNetworksByType(
          getNetworkInventoryIdsByType(networks || [], 'ROUTED'),
          'ROUTED',
          data || [],
        ),
        availableUnRouted: getAvailableInventoryNetworksByType(
          getNetworkInventoryIdsByType(networks || [], 'UNROUTED'),
          'UNROUTED',
          data || [],
        ),
        data,
      }),
    });

  const { data: network } = useGetNetworkQuery(networkToEdit.uid);

  if (!network || isLoadingNodeData) {
    return <GeneralFormLoader />;
  }

  return (
    <EditNetworkForm
      editing={isUpdating}
      availableRouted={availableRouted}
      availableUnrouted={availableUnRouted}
      networkNames={networkNames}
      updateNetwork={updateNetwork}
      nodeData={nodeData}
      updateNode={updateNode}
      networkToEdit={network!}
      isLastRouted={isLastRouted}
      customOnSubmit={customOnSubmit}
    />
  );
}

type OwnProps = {
  onCustomSubmit?: VoidFunction;
  isDiagramView?: boolean;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  customOnSubmit: ownProps.onCustomSubmit,
  editing: !!networksSelectors.getNetworkToEdit(state),
  networkToEdit: networksSelectors.getNetworkToEdit(state) as Network,
});

type EditNetworkFormContainerProps = ReturnType<typeof mapStateToProps>;

type EditNetworkRTKProps = {
  updateNetwork: RTKMutation<NetworkToUpdate, Network>;
  editing: boolean;
  availableRouted: InventoryNetwork[];
  availableUnrouted: InventoryNetwork[];
  networkNames: string[];
  nodeData?: DiagramNode;
  isLastRouted: boolean;
  updateNode: RTKMutation<UpdateDiagramNodeProps, DiagramNode>;
};

export type EditNetworkFormProps = EditNetworkFormContainerProps &
  EditNetworkRTKProps;

export const ConnectedEditNetworkForm = connect(mapStateToProps)(
  EditNetworkFormContainer,
);
