import React, { ReactElement } from 'react';
import { Button } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';

export type IntegrationInUseCloseButtonProps = {
  onClose: VoidFunction;
};

export const IntegrationInUseCloseButton = ({
  onClose,
}: IntegrationInUseCloseButtonProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-center dbl-margin-top">
      <Button
        onClick={onClose}
        colour="ghost"
        size="large"
        aria-label="Close modal"
      >
        {t('buttons.close')}
      </Button>
    </div>
  );
};
