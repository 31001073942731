import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomLabelPicker } from '../../../../components/Labels/CustomLabels/CustomLabelPicker';
import { CustomLabelGroup } from '../../../../components/Labels/CustomLabels/CustomLabelGroup';
import { computeAvailableLicenses } from './utils/utils';

export type LicensesFormProps = {
  integrationToSave?: IntegrationToSave;
  labels: CustomLabel[];
  addLabel: (label: CustomLabel) => void;
  removeLabel: (label: CustomLabel) => void;
};

export default function LicenseForm({
  labels,
  integrationToSave,
  addLabel,
  removeLabel,
}: LicensesFormProps): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <div className="half-margin-bottom half-margin-left half-margin-right">
        {t('integrations.forms.common.licenses.available')}
      </div>
      <div className="half-margin-bottom half-margin-left half-margin-right qtr-margin-top">
        <CustomLabelPicker
          labels={computeAvailableLicenses(
            labels,
            integrationToSave?.integrationLicenses || [],
          )}
          onAdd={addLabel}
        />
      </div>
      <div className="half-margin">
        {t('integrations.forms.common.licenses.selected')}
      </div>
      <div className="half-margin">
        <CustomLabelGroup
          labels={integrationToSave?.integrationLicenses || []}
          onDismiss={removeLabel}
        />
      </div>
    </>
  );
}
