import React, { ReactElement } from 'react';
import { Control, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { buildVmNameOptions } from '../../utils';
import { NatRulesAddFormProps } from '../NatRulesAddForm';
import { NoConfiguredVmsWithIpAlert } from './NoConfiguredVmsWithIpAlert';
import { NatRulesAddFormFieldsForType } from './NatRulesAddFormFieldsForType';

interface NatRulesVmInfoProps {
  control: Control<NatRuleFormData>;
  errors: DeepMap<NatRuleFormData, FieldError>;
  isCreating: boolean;
  formState: UseFormMethods['formState'];
  setVmIpAddressOptions: NatRulesAddFormProps['setVmIpAddressOptions'];
  vmIpAddressOptions: NatRulesAddFormProps['vmIpAddressOptions'];
  vmTargets: NatRulesAddFormProps['vmTargets'];
}

export function NatRulesVmInfo({
  control,
  errors,
  vmTargets,
  isCreating,
  formState,
  setVmIpAddressOptions,
  vmIpAddressOptions,
}: NatRulesVmInfoProps): ReactElement {
  const vmTargetOptions = buildVmNameOptions(vmTargets || []);

  return (
    <>
      <NatRulesAddFormFieldsForType
        vmTargetOptions={vmTargetOptions}
        control={control}
        errors={errors}
        vmTargets={vmTargets}
        isCreating={isCreating}
        formState={formState}
        setVmIpAddressOptions={setVmIpAddressOptions}
        vmIpAddressOptions={vmIpAddressOptions}
      />
      <NoConfiguredVmsWithIpAlert vmTargetOptions={vmTargetOptions} />
    </>
  );
}
