import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { linkToNewTab } from '../../../../../../../../utils/helpers';
import {
  ActionsButtonGroup,
  ActionsButtonGroupProps,
} from '../../../../ActionsButtonGroup';
import { PanelGrid } from '../../../PanelGrid';
import { RemoteAccessProps } from './container';

export function RemoteAccess({ data }: RemoteAccessProps): ReactElement {
  const { t } = useTranslation();

  const buildPanelGridData = (
    remoteAccess: VmPanelRemoteAccess,
  ): PanelGridDataItem[] => {
    const {
      internalUrl1,
      internalUrl1Description,
      internalUrl2,
      internalUrl2Description,
      password,
      user,
      ssh,
      rdp,
    } = remoteAccess;

    return [
      {
        key: 'webRdpIpAddress',
        name: t('sessions.panels.vm.remoteAccess.webRdpIpAddress'),
        value: rdp?.ipAddress,
      },
      {
        key: 'sshIpAddress',
        name: t('sessions.panels.vm.remoteAccess.sshIpAddress'),
        value: ssh?.ipAddress,
      },
      {
        key: 'user',
        name: t('sessions.panels.vm.remoteAccess.user'),
        value: user,
      },
      {
        key: 'password',
        name: t('sessions.panels.vm.remoteAccess.password'),
        value: password,
      },
      {
        key: 'internalUrl1',
        name: t('sessions.panels.vm.remoteAccess.internalUrl1'),
        value: internalUrl1 ? (
          <a href={internalUrl1} target="_blank" rel="noopener noreferrer">
            {internalUrl1Description}
          </a>
        ) : undefined,
      },
      {
        key: 'internalUrl2',
        name: t('sessions.panels.vm.remoteAccess.internalUrl2'),
        value: internalUrl2 ? (
          <a href={internalUrl2} target="_blank" rel="noopener noreferrer">
            {internalUrl2Description}
          </a>
        ) : undefined,
      },
    ];
  };

  const buildButtonActions = (
    remoteAccess: VmPanelRemoteAccess,
  ): ActionsButtonGroupProps['actions'] => {
    const { ssh, rdp, console } = remoteAccess;

    return [
      {
        content: t('sessions.panels.vm.remoteAccess.ssh'),
        onClick: ssh?.href ? () => linkToNewTab(ssh.href) : undefined,
      },
      {
        content: t('sessions.panels.vm.remoteAccess.webRDP'),
        onClick: rdp?.href ? () => linkToNewTab(rdp.href) : undefined,
      },
      {
        content: t('sessions.panels.vm.remoteAccess.vmConsole'),
        onClick: console?.href ? () => linkToNewTab(console.href) : undefined,
      },
    ];
  };

  if (
    !data ||
    (!data.internalUrl1 &&
      !data.internalUrl1Description &&
      !data.internalUrl2 &&
      !data.internalUrl2Description &&
      !data.password &&
      !data.user &&
      !data.ssh &&
      !data.rdp &&
      !data.console)
  ) {
    return (
      <Alert>
        {t('sessions.panels.vm.remoteAccess.noRemoteAccessConfigured')}
      </Alert>
    );
  }

  return (
    <>
      <PanelGrid
        data={buildPanelGridData(data)}
        wideCol="value"
        wideColRatio={data.rdp?.ipAddress || data.ssh?.ipAddress ? 1 : 2}
        nameBold={true}
      />
      <div className="base-padding-top base-padding-bottom">
        <ActionsButtonGroup actions={buildButtonActions(data)} />
      </div>
    </>
  );
}
