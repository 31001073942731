import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { Tooltip } from '../../../../components/Tooltip';
import styles from './index.module.css';
import { buildTopologyWarningsList } from './utils/utils';

export type TopologyWarningsProps = {
  className?: string;
  topologyUid: string;
  warnings?: TopologyWarning[];
};

export const TopologyWarnings = ({
  className,
  topologyUid,
  warnings,
}: TopologyWarningsProps): ReactElement => {
  if (!warnings || !warnings.length) return <></>;

  return (
    <Tooltip
      position="right"
      text={buildTopologyWarningsList(warnings) || ''}
      id={`${topologyUid}-warnings`}
      className={className}
    >
      <span aria-label="Topology warnings">
        <Icon name="warning-outline" className={styles.icon} />
      </span>
    </Tooltip>
  );
};
