import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { generateId } from '../../utils';

export interface TabProps {
  active?: boolean;
  children: ReactNode;
  title: string;
  id: string;
  icon?: ReactElement;
}

export function Tab({ active, children, title, id }: TabProps): ReactElement {
  return (
    <div
      role="tabpanel"
      aria-hidden={!active}
      className={classnames('tab-pane', { active })}
      aria-labelledby={generateId(id, title, 'button')}
      id={generateId(id, title, 'panel')}
    >
      {children}
    </div>
  );
}
