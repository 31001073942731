import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { setInput, setInputStatus } from '../../../../redux/integrations/slice';
import { RootState } from '../../../../redux/store';
import IntegrationInput from '../../../Integrations/components/IntegrationInput';

const mapDispatchToProps = {
  setInput,
  setInputStatus,
};

type ConnectedIntegrationInputProps = {
  input: IntegrationInput;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedIntegrationInputProps,
) => ({
  input: ownProps.input,
});

type IntegrationInputContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

function IntegrationInputContainer({
  input,
  setInput,
  setInputStatus,
}: IntegrationInputContainerProps): ReactElement {
  return (
    <IntegrationInput
      input={input}
      setInput={setInput}
      setInputStatus={setInputStatus}
    />
  );
}

export const ConnectedIntegrationInput = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationInputContainer);
