import React, { ReactElement } from 'react';
import { Label } from 'dcloud-shared-ui';
import styles from './CustomLabel.module.css';

export type CustomLabelProps = {
  label: CustomLabel;
  onDismiss?: VoidFunction;
  onAdd?: VoidFunction;
};

export const CustomLabel = ({
  label,
  onDismiss,
  onAdd,
}: CustomLabelProps): ReactElement => {
  return (
    <Label className={styles.label} size="small" colour="dark">
      {onDismiss ? (
        <>
          <span>{label.name}</span>
          <span onClick={onDismiss} className="icon-close"></span>
        </>
      ) : (
        <span onClick={onAdd}>{label.name}</span>
      )}
    </Label>
  );
};
