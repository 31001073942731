import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudProxy } from './dCloudContainer';
import { ConnectedExpoProxy } from './expoContainer';

export function ProxyContainer(): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoProxy
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudProxy sessionUid={sessionUid} versionUid={versionUid} />
  );
}

export type ProxyProps = {
  sessionProxyAddresses?: SessionProxyAddress[];
  isLoading: boolean;
  isError: boolean;
};
