import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Image, LinkButton } from 'dcloud-shared-ui';
import { Container } from '../../../../components/Container';
import { MainRoutes } from '../../../../constants/routes';
import heroSvg from '../../../../assets/images/app/hero.svg';
import { ExternalLink } from '../../../../components/ExternalLink';
import styles from './Hero.module.css';

export type HeroProps = {
  userHasAccess: boolean;
};

export function Hero({ userHasAccess }: HeroProps): ReactElement {
  const { t } = useTranslation();

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div className={classnames('row', styles.content)}>
          <div className="base-padding-right col-md-6">
            <h1 className="display-2 text-weight-300 base-margin-bottom">
              {t('home.carousel.default.heading')}
            </h1>
            <h2
              className={classnames(
                styles.subheading,
                'subheading text-size-20 text-weight-300',
              )}
            >
              {t('home.carousel.default.subHeading')}
            </h2>
            {userHasAccess ? (
              <LinkButton
                testId="hero-get-started-btn"
                to={MainRoutes.Topologies}
                colour="primary"
                className={classnames(styles.getStarted, 'dbl-margin-top')}
                size="large"
              >
                {t('home.carousel.default.button')}
              </LinkButton>
            ) : (
              <ExternalLink
                testId="hero-request-access-btn"
                linkText={t('buttons.requestAccess')}
                href="https://dcloud2-rtp.cisco.com/support/"
                target="_blank"
                className={classnames(
                  styles.getStarted,
                  'btn btn--large btn--primary dbl-margin-top',
                )}
              />
            )}
          </div>
          <div className={`col-md-6 ${styles.image}`}>
            <Image alt="Hero image" src={heroSvg} />
          </div>
        </div>
      </Container>
    </section>
  );
}
