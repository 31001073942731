import React from 'react';
import { connect } from 'react-redux';
import {
  diagramSelectors,
  setNetworkToConfigureInDiagram,
  setSelectedNodeToDelete,
} from '../../../../redux/diagram/slice';
import { RootState } from '../../../../redux/store';
import { NetworkNode } from '.';

const NetworkNodeContainer = ({
  asset,
  isReadOnly,
  setNetworkToConfigureInDiagram,
  display,
  uid,
  setSelectedNodeToDelete,
  isAddingLogicalConnection,
}: NetworkNodeContainerProps) => {
  return (
    <NetworkNode
      asset={asset}
      isReadOnly={isReadOnly}
      setNetworkToConfigureInDiagram={setNetworkToConfigureInDiagram}
      display={display}
      uid={uid}
      setSelectedNodeToDelete={setSelectedNodeToDelete}
      isAddingLogicalConnection={isAddingLogicalConnection}
    />
  );
};

const mapDispatchToProps = {
  setNetworkToConfigureInDiagram,
  setSelectedNodeToDelete,
};

const mapStateToProps = (state: RootState) => ({
  isAddingLogicalConnection:
    diagramSelectors.getIsAddingLogicalConnection(state),
});

type ConnectedNetworkNodeProps = BaseNodeProps & {
  asset?: Network;
};

export type NetworkNodeContainerRTKProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type NetworkNodeContainerProps = ConnectedNetworkNodeProps &
  NetworkNodeContainerRTKProps;

export const ConnectedNetworkNode = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NetworkNodeContainer);
