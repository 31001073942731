import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface NetworksState {
  showAddModal: boolean;
  networkToDelete?: Network;
  networkToEdit?: Network;
}

export const initialState: NetworksState = {
  networkToDelete: undefined,
  networkToEdit: undefined,
  showAddModal: false,
};

const slice = createSlice({
  initialState,
  name: 'networks',
  reducers: {
    reset: () => initialState,
    setNetworkToDelete: (
      state,
      { payload }: PayloadAction<Network | undefined>,
    ) => {
      state.networkToDelete = payload;
    },
    setNetworkToEdit: (
      state,
      { payload }: PayloadAction<Network | undefined>,
    ) => {
      state.networkToEdit = payload;
    },
    setShowAddModal: (state, action: PayloadAction<boolean>) => {
      state.showAddModal = action.payload;
    },
  },
});

export const { reducer: networksReducer } = slice;

export const { reset, setNetworkToDelete, setShowAddModal, setNetworkToEdit } =
  slice.actions;

const getNetworks = (state: RootState) => state.configuration[slice.name];

export const networksSelectors = {
  getNetworkToDelete: createSelector(
    getNetworks,
    ({ networkToDelete }) => networkToDelete,
  ),
  getNetworkToEdit: createSelector(
    getNetworks,
    ({ networkToEdit }) => networkToEdit,
  ),
  getShowAddModal: createSelector(
    getNetworks,
    ({ showAddModal }) => showAddModal,
  ),
};
