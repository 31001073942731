import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setExpired,
  setShowResetSessionModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { usePostSessionActionMutation } from '../../../../../../redux/views/session-view/session/api/api';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { ResetSessionModal } from '.';

function ResetModalContainer({
  setExpired,
  setShowResetSessionModal,
  showResetSessionModal,
}: ResetSessionModalContainerProps): ReactElement {
  const { sessionUid, versionUid } = useSessionUserContext();
  const [postSessionAction, { isSuccess, isLoading }] =
    usePostSessionActionMutation();

  const resetSession = () => {
    postSessionAction({
      action: 'RESET',
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });
  };

  return (
    <ResetSessionModal
      isSuccess={isSuccess}
      isLoading={isLoading}
      setExpired={setExpired}
      resetSession={resetSession}
      setShowResetSessionModal={setShowResetSessionModal}
      showResetSessionModal={showResetSessionModal}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  showResetSessionModal: sessionViewSelectors.getShowResetSessionModal(state),
});

const mapDispatchToProps = {
  setExpired,
  setShowResetSessionModal,
};

export type ResetSessionModalContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const ConnectedResetSessionModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetModalContainer);
