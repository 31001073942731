import React, { ReactElement } from 'react';
import { DefaultLineNodeDisplay } from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLine/constants';
import { DiagramLine } from './DiagramLine';

interface DiagramLinesProps {
  nodes: DiagramNodeTypes[];
}

export const DiagramLines = ({ nodes }: DiagramLinesProps): ReactElement => {
  const lineNodes = nodes.filter(
    (node) => node?.display?.drawingType === DefaultLineNodeDisplay.drawingType,
  );
  return (
    <g id="diagramLinesGroup">
      {lineNodes.map((node) => (
        <DiagramLine
          key={node!.uid}
          display={node!.display! as DiagramLineDisplay}
          uid={node?.uid || ''}
        />
      ))}
    </g>
  );
};
