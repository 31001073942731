import React, { ReactElement, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icon, IconButton, SelectOption } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../components';
import { Tooltip } from '../../../../../../../components/Tooltip';
import styles from './ConfigureFormFields.module.css';
import {
  determineIpErrorToDisplay,
  determineMacErrorToDisplay,
  determineNetworkErrorToDisplay,
  determineShowDeleteIcon,
} from './utils';

export interface ConfigureFormFieldsProps {
  interfaces: NetworkToConfigure[];
  options: SelectOption<string>[];
  networks: Network[];
}

export const ConfigureFormFields = ({
  networks,
  options,
  interfaces,
}: ConfigureFormFieldsProps): ReactElement => {
  const [hiddenRows, setHiddenRows] = useState<number[]>([]);
  const { t } = useTranslation();
  const { control, errors, trigger, watch, getValues } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'interfaces',
  });

  const interfacersWatcher = watch(['interfaces']);

  useEffect(() => {
    interfaces.forEach((_, index) => {
      trigger(`interfaces.[${index}].ipAddress`);
      trigger(`interfaces.[${index}].macAddress`);
      trigger(`interfaces.[${index}].assignDhcp`);
      trigger(`interfaces.[${index}].network`);
    });
  }, [trigger, interfacersWatcher, interfaces]);

  const determineShouldHideInput = (index: number) => {
    const values = getValues();
    return values.interfaces?.[index]?.network;
  };

  const determineShouldHideRow = (index: number) => {
    return !hiddenRows.includes(index);
  };

  return (
    <div className={styles.content}>
      <div className={styles.headerRow}>
        <div>{t('virtualMachines.configure.table.headings.name')}</div>
        <div>{t('virtualMachines.configure.table.headings.network')}</div>
        <div>{t('virtualMachines.configure.table.headings.ipAddress')}</div>
        <div></div>
        <div>{t('virtualMachines.configure.table.headings.assignDhcp')}</div>
        <div>{t('virtualMachines.configure.table.headings.macAddress')}</div>
        <div></div>
        <div></div>
      </div>

      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            {determineShouldHideRow(index) && (
              <div className={styles.configureGrid}>
                <div>
                  <ControlledTextfield
                    control={control}
                    label=""
                    name={`interfaces.${index}.name`}
                    required={false}
                    disabled={true}
                    defaultValue={interfaces[index].name}
                  />
                </div>
                <div>
                  <ControlledSelect
                    control={control}
                    error={determineNetworkErrorToDisplay(
                      errors,
                      index,
                      getValues().interfaces?.[index].network || undefined,
                      networks,
                    )}
                    label=""
                    name={`interfaces.${index}.network`}
                    options={options}
                    defaultValue={interfaces[index].network}
                    formGroupTextClass={styles.networkSelect}
                    testId="network-select"
                  />
                </div>
                <div>
                  {determineShouldHideInput(index) && (
                    <ControlledTextfield
                      control={control}
                      error={determineIpErrorToDisplay(
                        errors,
                        index,
                        networks,
                        getValues().interfaces[index].network,
                      )}
                      label=""
                      name={`interfaces.${index}.ipAddress`}
                      required={false}
                      defaultValue={interfaces[index].ipAddress}
                      testId={`interfaces.${index}.ipAddress-input`}
                    />
                  )}
                </div>
                <div>
                  {determineShouldHideInput(index) &&
                    errors.interfaces?.[index]?.ipAddress?.message ===
                      t('virtualMachines.add.duplicateIp') && (
                      <Tooltip
                        position="left"
                        size="medium"
                        text={t('virtualMachines.add.duplicateIp')}
                        id={`duplicate-warning-interfaces-${index}-ipAddress`}
                      >
                        <Icon
                          className={styles.duplicateIcon}
                          name="warning"
                          testId={`duplicate-warning-interfaces-${index}-ipAddress`}
                        ></Icon>
                      </Tooltip>
                    )}
                </div>
                <div>
                  <div className={styles.flex}>
                    {determineShouldHideInput(index) && (
                      <ControlledCheckbox
                        control={control}
                        defaultValue={interfaces[index].assignDhcp}
                        label=""
                        id={`interfaces.${index}.assignDhcp`}
                      />
                    )}
                  </div>
                </div>

                <div>
                  {determineShouldHideInput(index) && (
                    <ControlledTextfield
                      control={control}
                      label=""
                      name={`interfaces.${index}.macAddress`}
                      required={false}
                      error={determineMacErrorToDisplay(
                        errors,
                        index,
                        networks,
                        getValues().interfaces[index].network,
                      )}
                      defaultValue={interfaces[index].macAddress}
                      testId={`interfaces.${index}.macAddress-input`}
                    />
                  )}
                </div>
                <div>
                  {determineShouldHideInput(index) &&
                    errors.interfaces?.[index]?.macAddress?.message ===
                      t('virtualMachines.add.duplicateMac') && (
                      <Tooltip
                        position="left"
                        size="medium"
                        text={t('virtualMachines.add.duplicateMac')}
                        id={`duplicate-warning-interfaces-${index}-macAddress`}
                      >
                        <Icon
                          className={styles.duplicateIcon}
                          name="warning"
                          testId={`duplicate-warning-interfaces-${index}-macAddress`}
                        ></Icon>
                      </Tooltip>
                    )}
                </div>
                <div>
                  <div className={styles.flex}>
                    {determineShowDeleteIcon(getValues().interfaces) && (
                      <IconButton
                        icon="delete"
                        colour="link"
                        onClick={() => setHiddenRows([...hiddenRows, index])}
                        testId={`vm-configure-networking-table-delete-${
                          getValues().interfaces?.[index].name
                        }`}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
