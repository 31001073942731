import { Alert, Heading } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionsButtonGroup,
  ActionsButtonGroupProps,
} from '../../../../ActionsButtonGroup';
import { HwControlsProps } from '../HWControls';

export interface HWPowerControlProps {
  powerButtonActions: ActionsButtonGroupProps['actions'];
  pduControl: HwControlsProps['pduControl'];
}

export function HWPowerControl({
  pduControl,
  powerButtonActions,
}: HWPowerControlProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Heading level="h2" size="18" align="left">
        {t('sessions.panels.hardware.controls.power')}
      </Heading>
      {pduControl ? (
        <div className="dbl-padding-bottom">
          <ActionsButtonGroup actions={powerButtonActions} />
        </div>
      ) : (
        <Alert>
          {t('sessions.panels.hardware.controls.powerControlNotAvailable')}
        </Alert>
      )}
    </>
  );
}
