export const getHwInfoFromNodeDetails = (
  data: SessionHwNode,
): HwPanelInfo | undefined => {
  if (!data) return undefined;

  const { asset, sessionDetails } = data;

  return {
    description: asset.inventoryHardwareItem.description,
    deviceType: asset.inventoryHardwareItem.name,
    hwConsole: sessionDetails?.consoleUrl ? 'True' : 'False',
    name: asset.name,
    powerControl: sessionDetails?.pduControl ? 'True' : 'False',
    sessionDevice: sessionDetails?.name,
  };
};
