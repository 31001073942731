import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import {
  refreshAuthTokens,
  swapAuthCodeForTokens,
} from '../../api/sso/cloudSSO';
import {
  AuthStatusValues,
  OauthQueryParams,
  buildSSOFullAuthURL,
  generateCodeChallenge,
  generateCodeVerifier,
  getAccessToken,
  getCurrentURL,
  getRefreshToken,
  getSearchParamFromURL,
  removeTokens,
  redirectTo,
  replaceURLWithIntendedURL,
  storeTokens,
  storeCodeVerifier,
  clearCodeVerifier,
  getIdPRedirectUri,
  getAndRemoveSessionItem,
  storeSessionItem,
  generateStateParam,
  removeToken,
  config,
} from '../../auth';
import { addErrorToast } from '../toast/slice';
import i18n from '../../i18n';
import { MainRoutes } from '../../constants/routes';
import { setAuthStatus } from './slice';

export function* fullAuthWorker(): SagaIterator {
  const currentURL = yield call(getCurrentURL);
  const stateParam = yield call(generateStateParam);
  const redirectUri = yield call(getIdPRedirectUri);
  if (!currentURL.startsWith(redirectUri)) {
    yield call(storeSessionItem, stateParam, currentURL);
  }
  const codeVerifier = yield call(generateCodeVerifier);
  const codeChallenge = yield call(generateCodeChallenge, codeVerifier);
  yield call(storeCodeVerifier, codeVerifier);
  const fullAuthURL = yield call(
    buildSSOFullAuthURL,
    redirectUri,
    codeChallenge,
    stateParam,
  );
  yield call(redirectTo, fullAuthURL);
}

export function* handle401Worker(): SagaIterator {
  yield put(setAuthStatus(AuthStatusValues.RefreshingTokens));
  yield call(removeToken, config.SSO.ACCESS_TOKEN_NAME);
  const hasRefreshToken = yield call(getRefreshToken);
  if (hasRefreshToken) {
    yield call(refreshAccessTokenWorker);
  } else {
    yield call(fullAuthWorker);
  }
}

export function* handle503Worker(): SagaIterator {
  yield call(showSomethingWentWrong);
}

export function* handleUnexpectedAuthErrorWorker(
  authStatus: AuthStatusValues,
): SagaIterator {
  yield call(removeTokens);
  yield put(setAuthStatus(authStatus));
  yield put(push(MainRoutes.NotAuthorisedGenericError));
  yield call(showSomethingWentWrong);
}

export function* hasAccessTokenWorker(): SagaIterator {
  yield put(setAuthStatus(AuthStatusValues.Authenticated));
}

export function* refreshAccessTokenWorker(): SagaIterator {
  const refreshToken = yield call(getRefreshToken);
  try {
    const result = yield call(refreshAuthTokens, refreshToken);
    yield call(
      storeTokens,
      result.data.access_token,
      result.data.refresh_token,
    );
    yield put(setAuthStatus(AuthStatusValues.Authenticated));
  } catch (e) {
    yield call(removeTokens);
    yield call(fullAuthWorker);
  }
}

export function* showSomethingWentWrong(): SagaIterator {
  yield put(addErrorToast({ message: i18n.t('common.somethingWentWrong') }));
}

export function* startAuthWatcher(): SagaIterator {
  yield all([
    takeLatest('auth/start', startAuthWorker),
    takeLatest('auth/startSwap', swapCodeWorker),
  ]);
}

export function* startAuthWorker(): SagaIterator {
  const hasAccessToken = yield call(getAccessToken);
  if (hasAccessToken) {
    yield call(hasAccessTokenWorker);
  } else {
    const hasRefreshToken = yield call(getRefreshToken);
    if (hasRefreshToken) {
      yield call(refreshAccessTokenWorker);
    } else {
      yield call(fullAuthWorker);
    }
  }
}

export function* swapCodeWorker(): SagaIterator {
  const stateParam = yield call(
    getSearchParamFromURL,
    OauthQueryParams.StateParam,
  );
  const targetUrl: string = yield call(getAndRemoveSessionItem, stateParam);
  const code = yield call(getSearchParamFromURL, OauthQueryParams.AccessCode);

  if (!code || !targetUrl) {
    yield call(
      handleUnexpectedAuthErrorWorker,
      AuthStatusValues.NotAuthenticated,
    );
    return;
  }

  try {
    const redirectUri = yield call(getIdPRedirectUri);
    const result: AxiosResponse<SSOSwapCodeResponse> = yield call(
      swapAuthCodeForTokens,
      code,
      redirectUri,
    );

    yield call(
      storeTokens,
      result.data.access_token,
      result.data.refresh_token,
    );
    yield call(clearCodeVerifier);
    yield put(setAuthStatus(AuthStatusValues.Authenticated));
    yield call(replaceURLWithIntendedURL, targetUrl);
  } catch (e) {
    yield call(
      handleUnexpectedAuthErrorWorker,
      AuthStatusValues.NotAuthenticated,
    );
  }
}
