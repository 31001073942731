import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { addErrorToast } from '../../../../toast/slice';
import {
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
  setVmToAddFromDemoTab,
} from '../../../../configuration/topologyLimits/slice';
import i18n from '../../../../../i18n';
import {
  willCpuLimitBeExceeded,
  willMemoryLimitBeExceeded,
} from '../../../../../pages/EditTopology/configuration/utils';
import { virtualMachineEntitiesApi } from '../../../../configuration/virtual-machine-entities/api';
import { setShowAddVmModal, setShowAddVmSavingModal } from '../../../slice';
import { refetchDiagramDataHelper } from '../../shared/sharedSagas';
import { prepMappingsForClone } from '../../../../../pages/EditTopology/configuration/VirtualMachines/Configure/Demos';

export function* addVirtualMachineFromDemosTabHelper(
  topology: TopologyUid,
  vmNetworkInterfaces: VmToCloneVmNetworkInterface[],
  vmUid?: string,
): SagaIterator {
  if (!vmUid) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
    return;
  }

  let putResult;

  try {
    yield put(setShowAddVmSavingModal(true));
    putResult = yield put(
      yield call(virtualMachineEntitiesApi.endpoints.cloneVm.initiate, {
        payload: {
          topology,
          vmNetworkInterfaces: prepMappingsForClone(vmNetworkInterfaces),
          vmUid,
        },
      }),
    );
    yield putResult;
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    if (putResult) {
      yield call(putResult.unsubscribe);
    }
    yield call(refetchDiagramDataHelper, topology.uid);
    yield put(setShowAddVmSavingModal(false));
    yield put(resetTopologyLimits());
  }
}

export function* handleAddVmFromDemosTabFromDiagramWorker({
  payload: { vm, topologyLimits, topologyUid, vmNetworkInterfaces },
}: PayloadAction<VirtualMachineToAddFromDemosTabFromDiagram>): SagaIterator {
  try {
    yield put(setShowAddVmModal(false));

    if (!vm) {
      yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
      return;
    }

    if (!topologyLimits) {
      yield call(
        addVirtualMachineFromDemosTabHelper,
        topologyUid,
        vmNetworkInterfaces,
        vm.uid,
      );
      yield call(refetchDiagramDataHelper, topologyUid.uid);
      yield put(resetTopologyLimits());
      return;
    }

    const memoryLimitExceeded = yield call(
      willMemoryLimitBeExceeded,
      vm,
      topologyLimits.memoryMb,
    );

    const cpuLimitExceeded = yield call(
      willCpuLimitBeExceeded,
      vm,
      topologyLimits.cpuCores,
    );

    if (memoryLimitExceeded) {
      if (cpuLimitExceeded) {
        yield put(
          setShowTopologyLimitWarningForEntity(['Memory (GB)', 'vCPUs']),
        );
        yield put(
          setVmToAddFromDemoTab({ topologyUid, vm, vmNetworkInterfaces }),
        );
      } else {
        yield put(setShowTopologyLimitWarningForEntity('Memory (GB)'));
        yield put(
          setVmToAddFromDemoTab({ topologyUid, vm, vmNetworkInterfaces }),
        );
      }
    } else {
      if (cpuLimitExceeded) {
        yield put(setShowTopologyLimitWarningForEntity('vCPUs'));
        yield put(
          setVmToAddFromDemoTab({ topologyUid, vm, vmNetworkInterfaces }),
        );
      } else {
        yield call(
          addVirtualMachineFromDemosTabHelper,
          topologyUid,
          vmNetworkInterfaces,
          vm.uid,
        );
        yield call(refetchDiagramDataHelper, topologyUid.uid);
        yield put(resetTopologyLimits());
      }
    }
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    yield put(setShowAddVmModal(false));
  }
}
