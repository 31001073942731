import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addToast } from '../../redux/toast/slice';

interface UseErrorToast {
  showErrorToast: (message: string) => void;
}

export function useErrorToast(): UseErrorToast {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function showErrorToast(message: string) {
    dispatch(addToast({ message, title: t('common.error'), type: 'error' }));
  }

  return { showErrorToast };
}
