import React, { ReactElement } from 'react';
import { filterIconLinks } from '../../utils/utils';
import { IconPanelLink } from './components/IconPanelLink';

export type IconPanelLinksDataProps = {
  links: DiagramAddIconFormFieldsDatum[];
};

export function IconPanelLinksData({
  links,
}: IconPanelLinksDataProps): ReactElement {
  return (
    <>
      {filterIconLinks(links).map((link, index) => (
        <IconPanelLink key={index} link={link} />
      ))}
    </>
  );
}
