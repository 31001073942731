import React, { ReactElement } from 'react';
import { PSTNServicesForm } from '../PSTNServicesForm';

interface TelephonyCreateProps {
  onCreate: () => void;
}
export function TelephonyCreate({
  onCreate,
}: TelephonyCreateProps): ReactElement {
  return (
    <section className="half-padding-left">
      <PSTNServicesForm defaultValue={false} onSubmit={onCreate} />
    </section>
  );
}
