import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../components/Accordion';
import { filterIconFormFieldsByFieldType } from '../../../../Diagram/components/DiagramModals/IconModals/components/IconForm/components/KeyValuePairInputs/utils/utils';
import { IconFormKeyValuePairTypes } from '../../../../Diagram/components/DiagramModals/IconModals/components/IconForm/constants';
import { IconInfoProps } from './container';
import { IconPanelProperties } from './components/IconPanelProperties';
import { IconPanelLinks } from './components/IconPanelLinks';
import styles from './IconPanel.module.css';

export function IconInfo({ data, title }: IconInfoProps): ReactElement {
  const { t } = useTranslation();
  const links = filterIconFormFieldsByFieldType(
    data,
    IconFormKeyValuePairTypes.link,
  );
  const properties = filterIconFormFieldsByFieldType(
    data,
  ) as PanelGridDataItem[];
  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <IconPanelLinks links={links} />,
        title: t('diagram.modals.addIcon.form.tabs.links.title'),
      },
      {
        children: <IconPanelProperties properties={properties} />,
        title: t('diagram.modals.addIcon.form.tabs.properties.title'),
      },
    ],
    [links, properties, t],
  );

  return (
    <>
      <div className={styles.iconPanelTitle}>{title}</div>
      <Accordion rows={accordionRows} size="small" />
    </>
  );
}
