import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLoader } from '../../../../../../components/Table/TableLoader';
import formStyles from '../../../../../EditTopology/configuration/EditFormStyles.module.css';
import { DhcpConfig } from '../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/Networking/components/DhcpConfig';
import { TemplateNetworkingForm } from './components/TemplateNetworkingForm';
import { TemplateNetworkingTable } from './components/TemplateNetworkingTable';
import { TemplateNetworkingProps } from './container';
import { EditTemplateNetworkingForm } from './components/EditTemplateNetworkingForm';

export function TemplateNetworking({
  addErrorToast,
  isErrorLoadingNicTypes,
  isErrorLoadingNetworks,
  networkingToEdit,
  nicToEdit,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  nicTypes,
  isLoadingLimits,
  isLoadingNicTypes,
  limits,
  setNicToEdit,
  updateVMNetworkInterface,
  vmToEdit,
}: TemplateNetworkingProps): ReactElement | null {
  const { t } = useTranslation();
  const [hasAssignDhcp, setHasAssignDhcp] = useState(false);

  const onDeleteClick = useCallback(
    (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => {
      return networkingToEdit && networkingToEdit?.length <= 1
        ? addErrorToast({
            message: t('virtualMachines.edit.networking.minLimit'),
          })
        : deleteVMNetworkInterface(vmNetworkInterfaceToEdit);
    },
    [addErrorToast, deleteVMNetworkInterface, networkingToEdit, t],
  );

  const onEditClick = useCallback(
    (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => {
      setNicToEdit(vmNetworkInterfaceToEdit);
    },
    [setNicToEdit],
  );

  useEffect(() => {
    const interfacesWithAssignDhcp = networkingToEdit?.some(
      (nic) => nic.assignDhcp,
    );
    setHasAssignDhcp(interfacesWithAssignDhcp || false);
  }, [networkingToEdit, vmToEdit]);

  if (
    isLoadingLimits ||
    isLoadingNicTypes ||
    isErrorLoadingNetworks ||
    isErrorLoadingNicTypes
  ) {
    return <TableLoader />;
  }

  return (
    <section className={formStyles.sectionPadding}>
      {!nicToEdit && limits && (
        <TemplateNetworkingForm
          addVMNetworkInterface={addVMNetworkInterface}
          nicTypes={nicTypes || []}
          addErrorToast={addErrorToast}
          totalNics={networkingToEdit?.length || 0}
          setNicToEdit={setNicToEdit}
          vmNetworkInterfaces={networkingToEdit}
          vmToEdit={vmToEdit}
          limits={limits}
        />
      )}
      {networkingToEdit && networkingToEdit.length === 0 && (
        <Alert colour="info">
          {t('virtualMachines.edit.networking.empty.alertText')}
        </Alert>
      )}
      {nicToEdit && limits && (
        <EditTemplateNetworkingForm
          limits={limits}
          nicTypes={nicTypes || []}
          nicToEdit={nicToEdit}
          setNicToEdit={setNicToEdit}
          updateVMNetworkInterface={updateVMNetworkInterface}
          vmNetworkInterfaces={networkingToEdit}
          vmToEdit={vmToEdit}
        />
      )}
      {hasAssignDhcp && !nicToEdit && <DhcpConfig />}
      {networkingToEdit && !nicToEdit && networkingToEdit.length > 0 && (
        <TemplateNetworkingTable
          vmNetworkInterfaces={networkingToEdit || []}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          isLoading={isLoadingNicTypes || isErrorLoadingNicTypes}
        />
      )}
    </section>
  );
}
