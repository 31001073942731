import React, { ReactElement, useState } from 'react';
import { RowNode } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../../components';
import styles from './Description.module.css';

export const Description = ({
  data: {
    name,
    saasTemplate: { description, details },
  },
}: RowNode): ReactElement => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <>
      {description}{' '}
      <a className={styles.readMoreLink} onClick={() => setShow(true)}>
        {t('buttons.seeFullDetails')}
      </a>
      {show && (
        <Modal
          clickScreenToClose={true}
          show={true}
          onClose={() => setShow(false)}
          title={name}
          testId={`info-modal-${name}`}
          headerAlignment="left"
          size="large"
        >
          {details}
        </Modal>
      )}
    </>
  );
};
