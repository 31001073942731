import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { EditTopology } from '../pages/EditTopology';
import { MainRoutes } from '../constants/routes';
import { TopologiesContainer } from '../pages/Topologies/container';
import { CreateTopologyContainer } from '../pages/CreateTopology/container';
import AuthenticatedGuard from '../auth/components/AuthenticatedGuard/container';
import { PageNotFound } from '../pages/Errors/PageNotFound';
import { GenericError } from '../pages/Errors/GenericError';
import NotAuthorizedGenericError from '../pages/Errors/NotAuthorizedGenericError/container';
import { ConnectedTemplatePortal } from '../pages/ManageTemplates/container';
import UserRoleGuardContainer from '../auth/components/UserRoleGuard/container';
import { HomeContainer } from '../pages/Home/container';
import { FullScreenSpinner } from '../components/Loaders/FullScreenSpinner';
import { ConnectedVideosView } from '../pages/Videos/container';
import { ConnectedIntegrations } from '../pages/Integrations/container';
import { ConnectedCreateIntegration } from '../pages/CreateIntegration/container';
import { ConnectedEditIntegration } from '../pages/EditIntegration/container';
import AuthenticateContainer from '../pages/Authenticate/container';
import { ConnectedSession } from '../pages/Views/Session/container';

export function MainRouter(): ReactElement {
  return (
    <Switch>
      <Route
        path={MainRoutes.IDP_CALLBACK}
        exact={true}
        render={() => <AuthenticateContainer />}
      />
      <Route
        path={MainRoutes.Edit}
        render={() => (
          <AuthenticatedGuard>
            <UserRoleGuardContainer
              requiredRoles={['ROLE_USER']}
              loadingComponent={<FullScreenSpinner />}
              redirectToMainPageIfNotPermitted={true}
            >
              <EditTopology />
            </UserRoleGuardContainer>
          </AuthenticatedGuard>
        )}
      />
      <Route
        path={MainRoutes.Main}
        exact={true}
        render={() => (
          <AuthenticatedGuard>
            <UserRoleGuardContainer
              requiredRoles={[]}
              loadingComponent={<FullScreenSpinner />}
              redirectToMainPageIfNotPermitted={false}
            >
              <HomeContainer />
            </UserRoleGuardContainer>
          </AuthenticatedGuard>
        )}
      />
      <Route
        path={MainRoutes.Topologies}
        exact={true}
        render={() => (
          <AuthenticatedGuard>
            <UserRoleGuardContainer
              requiredRoles={['ROLE_USER']}
              redirectToMainPageIfNotPermitted={true}
              loadingComponent={<FullScreenSpinner />}
            >
              <TopologiesContainer />
            </UserRoleGuardContainer>
          </AuthenticatedGuard>
        )}
      />
      <Route
        path={MainRoutes.CreateTopology}
        render={() => (
          <AuthenticatedGuard>
            <CreateTopologyContainer />
          </AuthenticatedGuard>
        )}
      />
      <Route
        path={MainRoutes.Sessions}
        render={() => (
          <AuthenticatedGuard>
            <ConnectedSession showPage="session-view" />
          </AuthenticatedGuard>
        )}
      />
      <Route
        path={MainRoutes.SessionsExpo}
        render={() => <ConnectedSession showPage="session-view" />}
      />
      <Route
        path={MainRoutes.SessionsInventory}
        render={() => (
          <AuthenticatedGuard>
            <ConnectedSession showPage="session-inventory" />
          </AuthenticatedGuard>
        )}
      />
      <Route
        path={MainRoutes.CreateIntegration}
        render={() => (
          <AuthenticatedGuard>
            <ConnectedCreateIntegration />
          </AuthenticatedGuard>
        )}
        exact={true}
      />
      <Route
        path={MainRoutes.EditIntegration}
        render={() => (
          <AuthenticatedGuard>
            <UserRoleGuardContainer
              loadingComponent={<FullScreenSpinner />}
              requiredRoles={['ROLE_ADMIN', 'ROLE_INTEGRATION_MANAGER']}
              redirectToMainPageIfNotPermitted={true}
            >
              <ConnectedEditIntegration />
            </UserRoleGuardContainer>
          </AuthenticatedGuard>
        )}
        exact={true}
      />
      <Route
        path={MainRoutes.Integrations}
        render={() => (
          <AuthenticatedGuard>
            <UserRoleGuardContainer
              loadingComponent={<FullScreenSpinner />}
              requiredRoles={['ROLE_ADMIN', 'ROLE_INTEGRATION_MANAGER']}
              redirectToMainPageIfNotPermitted={true}
            >
              <ConnectedIntegrations />
            </UserRoleGuardContainer>
          </AuthenticatedGuard>
        )}
        exact={true}
      />
      <Route
        path={MainRoutes.Templates}
        render={() => (
          <AuthenticatedGuard>
            <UserRoleGuardContainer
              requiredRoles={['ROLE_ADMIN', 'ROLE_TEMPLATE_MANAGER']}
              redirectToMainPageIfNotPermitted={true}
            >
              <ConnectedTemplatePortal />
            </UserRoleGuardContainer>
          </AuthenticatedGuard>
        )}
      />
      <Route
        path={MainRoutes.Videos}
        render={() => (
          <AuthenticatedGuard>
            <ConnectedVideosView />
          </AuthenticatedGuard>
        )}
      />
      <Route path={MainRoutes.GenericError} component={GenericError} />
      <Route
        path={MainRoutes.NotAuthorisedGenericError}
        component={NotAuthorizedGenericError}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
}
