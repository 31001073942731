import { extractNamesFromSessionLicenses } from './components/SessionLicenses/utils/sessionLicensesUtil';

export const getSessionInformationFromSession = (
  session: Session | undefined,
): SessionInformation | undefined => {
  if (!session) return undefined;

  const { sessionDetails } = session;

  return {
    endTime: sessionDetails.stop,
    lastModified: sessionDetails.updated,
    licenses: extractNamesFromSessionLicenses(sessionDetails.sessionLicenses),
    owner: sessionDetails.owner,
    parentDemo: sessionDetails.parentDemoName,
    sessionId: sessionDetails.id,
    virtualCenter: sessionDetails.virtualCenterId,
    vpnAvailable: !!sessionDetails.anyConnect,
  };
};
