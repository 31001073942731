import classnames from 'classnames';
import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import styles from '../videos.module.css';
import { DefaultLayout } from '../../../layouts/DefaultLayout';
import { useSkeletonColours } from '../../../hooks/use-skeleton-colours';
import FeaturedVideoTile from './FeaturedVideoTile';

export const VideoLoader = (): ReactElement => {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  return (
    <DefaultLayout>
      <div className={classnames('base-margin-bottom', styles.heroWrapper)}>
        <FeaturedVideoTile />
      </div>
      <div className={styles.body}>
        <ContentLoader
          speed={1.5}
          width="100%"
          height={300}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
        >
          <rect x="14" y="10" width="250" height="30" />
          <rect x="14" y="66" width="265" height="150" />
          <rect x="314" y="66" width="265" height="150" />
        </ContentLoader>
      </div>
    </DefaultLayout>
  );
};
