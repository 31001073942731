import React, { ReactElement, ReactNode } from 'react';
import { Alert, Heading } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PanelGrid } from '../../../../../PanelGrid';
import { PanelTableLoader } from '../../../../../../PanelTableLoader';
import { PublicIProps } from './container';

export function PublicIP({
  sessionPublicAddresses,
  isLoading,
  isError,
}: PublicIProps): ReactElement {
  const { t } = useTranslation();

  const buildGridData = ({
    publicAddress,
    privateAddress,
    description,
  }: SessionPublicAddress): PanelGridDataItem[] => {
    return [
      {
        key: 'public',
        name: t('sessions.panels.info.publicIpProxy.publicIpAddress'),
        value: publicAddress,
      },
      {
        key: 'private',
        name: t('sessions.panels.info.publicIpProxy.privateIpAddress'),
        value: privateAddress,
      },
      {
        key: 'desc',
        name: t('sessions.panels.info.publicIpProxy.description'),
        value: description,
      },
    ];
  };

  const renderGrids = (
    sessionPublicAddresses: SessionPublicAddress[],
  ): ReactNode => {
    return sessionPublicAddresses.map(function (sessionPublicAddress, index) {
      return (
        <PanelGrid
          key={index}
          data={buildGridData(sessionPublicAddress)}
          wideCol="value"
          nameBold={true}
          wideColRatio={1}
        />
      );
    });
  };

  if (isLoading || isError) {
    return <PanelTableLoader />;
  }

  if (!sessionPublicAddresses || !sessionPublicAddresses.length) {
    return (
      <Alert>
        {t('sessions.panels.info.publicIpProxy.noPublicIpConfigured')}
      </Alert>
    );
  }

  return (
    <>
      <Heading level="h2" align="left" size="18">
        {t('sessions.panels.info.publicIpProxy.publicIP')}
      </Heading>

      {renderGrids(sessionPublicAddresses)}
    </>
  );
}
