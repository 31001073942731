export const calculateTopologyRemoteAccessCount = (
  vms: VirtualMachine[] | undefined,
): number => {
  let count = 0;
  if (!vms) {
    return count;
  }

  vms.map((vm) => {
    vm.vmNetworkInterfaces.map((vmNetworkInterface) => {
      vmNetworkInterface.ssh?.enabled ? count++ : null;
      vmNetworkInterface.rdp?.enabled ? count++ : null;
    });
  });
  return count;
};
