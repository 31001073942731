import { LoaderSpinner, LoaderSpinnerProps } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './FullScreenSpinner.module.css';

export function FullScreenSpinner({
  size = 'default',
}: LoaderSpinnerProps): ReactElement {
  const classNames = classnames(styles.root, 'flex flex-center');

  return (
    <div className={classNames}>
      <LoaderSpinner size={size} />
    </div>
  );
}
