import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface NicTypesState {
  nicTypes?: VmNetworkInterfaceType[];
}

export const initialState: NicTypesState = {
  nicTypes: undefined,
};

const slice = createSlice({
  initialState,
  name: 'nicTypes',
  reducers: {
    setNicTypes: (
      state,
      action: PayloadAction<VmNetworkInterfaceType[] | undefined>,
    ) => {
      state.nicTypes = action.payload;
    },
  },
});

export const getNicTypes = (state: RootState): NicTypesState =>
  state.entities[slice.name];

export const { reducer: nicTypesReducer } = slice;
export const { setNicTypes } = slice.actions;
