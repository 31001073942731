import { dcloudApi } from '../../api';
import { OPERATING_SYSTEMS_PATH } from '../../../api/paths';
import i18n from '../../../i18n';
import { setOperatingSystems } from '../../entities/operating-systems/slice';
import { TAGS } from '../../api/constants';

export const operatingSystemsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getOperatingSystems: build.query<OperatingSystem[], undefined>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setOperatingSystems(data));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.OPERATING_SYSTEMS],
      query: () => ({
        errorMessage: i18n.t('operatingSystems.fetch.error'),
        method: 'get',
        url: OPERATING_SYSTEMS_PATH,
      }),
      transformResponse: (response: OperatingSystemsResponse) =>
        response.osFamilies,
    }),
  }),
});

export const { useGetOperatingSystemsQuery } = operatingSystemsApi;
