import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { ColDef, GridApi } from 'ag-grid-community';
import classnames from 'classnames';
import { GridEditor } from '../../../../../../../../components/GridEditor';
import {
  hasDuplicateMacAddress,
  hasDuplicateIpAddress,
} from '../../../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/Networking/utils';
import { CustomTickRenderer } from '../../../../../../../../components/GridEditor/CellTick';

type NetworkingTableProps = {
  vmNetworkInterfaces: VmNetworkingToEdit;
  onDeleteClick: (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => void;
  onEditClick: (vmNetworkInterfaceToEdit: VmNetworkInterfaceToEdit) => void;
  isLoading: boolean;
};

export function TemplateNetworkingTable({
  vmNetworkInterfaces,
  onEditClick,
  onDeleteClick,
}: NetworkingTableProps): ReactElement | null {
  const { t } = useTranslation();
  const hasDuplicateMacs = hasDuplicateMacAddress(vmNetworkInterfaces);
  const hasDuplicateIps = hasDuplicateIpAddress(vmNetworkInterfaces);

  const columns: ColDef[] = [
    {
      autoHeight: true,
      editable: false,
      field: 'name',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.adapterName'),
      minWidth: 130,
      sort: 'asc',
    },
    {
      autoHeight: true,
      field: 'type',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.type'),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellClassRules: {
        'cell-warn-text': (params) => {
          const macAddress = params.value;
          const duplicateMacs = vmNetworkInterfaces.filter(
            (nic) => nic.macAddress === macAddress,
          );
          return duplicateMacs.length > 1;
        },
      },
      field: 'macAddress',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.macAddress'),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellClassRules: {
        'cell-warn-text': (params) => {
          const ipAddress = params.value;
          const ipAddressesOnSameNetwork = vmNetworkInterfaces.filter(
            (nic) => nic.ipAddress === ipAddress,
          );
          return ipAddressesOnSameNetwork.length > 1;
        },
      },
      field: 'ipAddress',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.ipAddress'),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellRenderer: 'tickBox',
      field: 'assignDHCP',
      flex: 1,
      headerName: t('virtualMachines.edit.networking.dhcp.title'),
      minWidth: 20,
    },
  ];

  const onGridReady = (gridApi: GridApi) => {
    gridApi.sizeColumnsToFit();
  };

  return (
    <>
      <GridEditor
        columns={columns}
        data={vmNetworkInterfaces}
        onGridReady={onGridReady}
        className={classnames('base-padding-left')}
        onEditClick={(row) => onEditClick(row.data)}
        onDeleteClick={(element) => {
          onDeleteClick(element.data);
        }}
        customComponents={{
          tickBox: CustomTickRenderer,
        }}
        deleteAriaLabel="delete-nic-item"
        editAriaLabel="edit-nic-item"
        updateAriaLabel="update-nic-item"
      />
      {hasDuplicateIps && (
        <Alert colour="warning" showIcon>
          {t('virtualMachines.edit.networking.warnings.duplicateIp')}
        </Alert>
      )}
      {hasDuplicateMacs && (
        <Alert colour="warning" showIcon>
          {t('virtualMachines.edit.networking.warnings.duplicateMac')}
        </Alert>
      )}
    </>
  );
}
