import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterItem } from '../FilterItem';

export type FilterItemsProps = {
  availableFilters: TopologiesFilter[];
  selectedFilters: string[];
  deleteFilter: (val: string) => void;
  addFilter: (val: string) => void;
};

export const FilterItems = ({
  availableFilters,
  selectedFilters,
  deleteFilter,
  addFilter,
}: FilterItemsProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      {availableFilters.map(({ filterKey, label }) => {
        const isChecked = selectedFilters.includes(filterKey as string);
        return (
          <FilterItem
            key={label}
            label={t(label)}
            isChecked={isChecked}
            onChange={() =>
              isChecked ? deleteFilter(filterKey) : addFilter(filterKey)
            }
          />
        );
      })}
    </>
  );
};
