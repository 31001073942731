import { CallEffect, call } from 'redux-saga/effects';
import {
  getVmStatusHelper,
  postHwActionHelper,
  postVmActionHelper,
} from './sagas';

export function addEffectToUnsubscribeEffectsHelper(
  effect: CallEffect<unknown>,
  sessionInventoryVmStatusUnsubscribeEffects: CallEffect<unknown>[],
): void {
  sessionInventoryVmStatusUnsubscribeEffects.push(effect);
}

export function buildArrayOfCallEffects(
  virtualMachines: SessionInventoryVirtualMachineData[],
): CallEffect<unknown>[] {
  return virtualMachines.map(
    ({ sessionUid, versionUid, mor }: SessionInventoryVirtualMachineData) =>
      call(getVmStatusHelper, {
        mor,
        sessionUid,
        versionUid,
      }),
  );
}

export function resetEffectsToUnsubscribeHelper(
  sessionInventoryVmStatusUnsubscribeEffects: CallEffect<unknown>[],
): void {
  sessionInventoryVmStatusUnsubscribeEffects.length = 0;
}

export function getLengthOfSessionInventoryVmStatusUnsubscribeEffects(
  sessionInventoryVmStatusUnsubscribeEffects: CallEffect<unknown>[],
): number {
  return sessionInventoryVmStatusUnsubscribeEffects.length;
}

export function buildArrayOfMultiSelectHwCallEffects(
  hardwareItems: SessionInventoryHardwareData[],
  action: SessionHwActionReset,
): CallEffect<unknown>[] {
  return hardwareItems.map(
    ({
      sessionUid,
      deviceId,
      versionUid,
      name,
    }: SessionInventoryHardwareData) =>
      call(postHwActionHelper, {
        action,
        deviceId: deviceId!,
        name,
        sessionId: sessionUid,
        suppressErrorToast: true,
        suppressSuccessToast: true,
        topologyVersion: { uid: versionUid },
      }),
  );
}

export function buildArrayOfMultiSelectVmCallEffects(
  vmItems: SessionInventoryVirtualMachineData[],
  action: SessionVmActions,
): CallEffect<unknown>[] {
  return vmItems.map(
    ({
      sessionUid,
      mor,
      versionUid,
      name,
    }: SessionInventoryVirtualMachineData) =>
      call(postVmActionHelper, {
        action,
        mor,
        name,
        sessionId: sessionUid,
        suppressErrorToast: true,
        suppressSuccessToast: true,
        topologyVersion: { uid: versionUid },
      }),
  );
}
