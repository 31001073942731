import { AnyAction } from '@reduxjs/toolkit';
import { getUserPrefs, setUserPrefs, UserPrefKey } from '../../utils/userPref';

export const userPrefMiddleware =
  () =>
  (next: (action: AnyAction) => void) =>
  (action: AnyAction): void => {
    const existingUserPrefsObject = getUserPrefs();
    switch (action.type) {
      case 'topologiesFilters/addFilter':
        {
          const existingFilters = existingUserPrefsObject.filters;
          if (!existingFilters.includes(action.payload)) {
            const updatedFilters = [...existingFilters, action.payload];
            setUserPrefs(UserPrefKey.filters, updatedFilters);
          }
        }
        break;
      case 'topologiesFilters/deleteFilter':
        {
          const existingFilters = existingUserPrefsObject.filters;
          const updatedFilters = existingFilters.filter(
            (filter) => filter !== action.payload,
          );
          setUserPrefs(UserPrefKey.filters, updatedFilters);
        }
        break;
      case 'topologiesFilters/addDataCenterFilter':
        {
          const existingFilters = existingUserPrefsObject.dataCenterFilters;
          if (!existingFilters.includes(action.payload)) {
            const updatedFilters = [...existingFilters, action.payload];
            setUserPrefs(UserPrefKey.dataCenterFilters, updatedFilters);
          }
        }
        break;
      case 'topologiesFilters/deleteDataCenterFilter':
        {
          const existingFilters = existingUserPrefsObject.dataCenterFilters;
          const updatedFilters = existingFilters.filter(
            (filter) => filter !== action.payload,
          );
          setUserPrefs(UserPrefKey.dataCenterFilters, updatedFilters);
        }
        break;
      case 'topologiesFilters/setTopologiesViewType':
        {
          const updatedUserPrefsObject = {
            ...existingUserPrefsObject,
            topologiesViewType: action.payload,
          };
          localStorage.setItem(
            'userPrefs',
            JSON.stringify(updatedUserPrefsObject),
          );
        }
        break;
    }
    return next(action);
  };
