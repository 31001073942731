import { connect } from 'react-redux';
import {
  sessionInventorySelectors,
  setIsSelectAllHwChecked,
  setIsSelectAllVmChecked,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import { RootState } from '../../../../../redux/store';
import { SelectAllCellRenderer } from '.';

const mapDispatchToProps = {
  setIsSelectAllHwChecked,
  setIsSelectAllVmChecked,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: RootState, ownProps: any) => ({
  data: ownProps.data,
  isDisabled:
    sessionInventorySelectors.getShowMultiActionProgressBar(state) ||
    sessionInventorySelectors.getIsCallingVmStatusesAPICalls(state) ||
    ownProps.isDisabled,
  isSelectAllHwChecked:
    sessionInventorySelectors.getIsSelectAllHwChecked(state),
  isSelectAllVmChecked:
    sessionInventorySelectors.getIsSelectAllVmChecked(state),
  page: ownProps.page,
  selectedItemsToAction:
    ownProps.page === 'hardware'
      ? sessionInventorySelectors.getSelectedHwItemsToAction(state)
      : sessionInventorySelectors.getSelectedVmItemsToAction(state),
});

export type SelectAllCellRendererProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const SelectAllCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectAllCellRenderer);
