import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetInventoryTelephonyItemsQuery } from '../../../../../redux/configuration/telephony/api';
import { PSTNValueLoader } from '../PSTNValueLoader';
import { extractPstnItemFromTelephonyItems } from '../utils';
import { TelephonyCreateContainer } from '../TelephonyCreate/container';
import { PSTNNotSupportedBanner } from '../PSTNNotSupportedBanner';

export function InventoryTelephonyContainer(): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const { isLoading, isFetching, isError, data } =
    useGetInventoryTelephonyItemsQuery(topologyUid);
  if (data === undefined || isLoading || isFetching || isError) {
    return <PSTNValueLoader />;
  }

  const pstnTelephonyItem = extractPstnItemFromTelephonyItems(
    data.inventoryTelephonyItems,
  ) as InventoryTelephonyItem;

  if (pstnTelephonyItem) {
    return (
      <TelephonyCreateContainer
        inventoryTelephonyItemId={pstnTelephonyItem.id}
      />
    );
  }

  return <PSTNNotSupportedBanner />;
}
