import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ColDef } from 'ag-grid-community';
import { orderBy } from 'lodash';
import { GridEditor } from '../../../../components/GridEditor';
import { tagComparator } from '../../utils/utils';
import history from '../../../../redux/history';
import { MainRoutes } from '../../../../constants/routes';
import { Tags } from './components/Tags';
import { IntegrationTypeCellRenderer } from './components/IntegrationType';
import { AvailabilityCellRendererContainer } from './components/AvailabilityCellRenderer/AvailabilityContainer';

type IntegrationsTableProps = {
  integrations: Integration[];
  setIntegrationToDelete: (integration?: Integration) => void;
  setIntegrationToDisplayUid: (integrationUid?: string) => void;
};

export const IntegrationsTable = ({
  integrations,
  setIntegrationToDelete,
  setIntegrationToDisplayUid,
}: IntegrationsTableProps): ReactElement => {
  const { t } = useTranslation();

  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'name',
      flex: 1,
      headerName: t('integrations.table.integration'),
      minWidth: 130,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'description',
      flex: 3,
      headerName: t('integrations.table.description'),
      minWidth: 770,
    },
    {
      autoHeight: true,
      cellRenderer: 'tags',
      cellStyle: { 'white-space': 'normal' },
      comparator: tagComparator,
      field: 'tags',
      flex: 1,
      headerName: t('integrations.table.tags'),
      minWidth: 250,
    },
    {
      autoHeight: true,
      cellRenderer: 'integrationType',
      cellStyle: { 'white-space': 'normal' },
      field: 'type',
      flex: 1,
      headerName: t('integrations.table.type'),
      minWidth: 130,
    },
    {
      cellRenderer: 'availability',
      cellStyle: { 'white-space': 'normal' },
      field: 'availability',
      flex: 1,
      headerName: t('integrations.table.availability'),
      minWidth: 130,
    },
    {
      field: 'createdBy',
      flex: 1,
      headerName: t('integrations.table.owner'),
      minWidth: 200,
    },
    {
      field: 'lastUpdated',
      hide: true,
      sortingOrder: ['desc'],
    },
  ];
  return (
    <GridEditor
      columns={columns}
      data={orderBy(integrations, ['lastUpdated'], ['desc'])}
      className={classnames('base-padding-left')}
      deleteAriaLabel={t('integrations.delete.ariaLabel')}
      editAriaLabel={t('integrations.forms.edit.ariaLabel')}
      onGridReady={() => undefined}
      customComponents={{
        availability: AvailabilityCellRendererContainer,
        integrationType: IntegrationTypeCellRenderer,
        tags: Tags,
      }}
      customProps={{
        suppressNoRowsOverlay: true,
      }}
      onInfoClick={(props) => {
        setIntegrationToDisplayUid(props.data.uid);
      }}
      onEditClick={(props) => {
        history.push(`${MainRoutes.Integrations}/${props.data.uid}`);
      }}
      onDeleteClick={(props) => {
        setIntegrationToDelete(props.data);
      }}
    />
  );
};
