import React, { ReactElement } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { ConnectedVirtualMachinesEdit } from '../components/VirtualMachineEntities/Edit/container';
import { ConnectedConfigure } from '../components/VirtualMachineEntities/Configure/Templates';
import { ConnectedVirtualMachines } from './All/container';
import { ConnectedDemoConfigure } from './Configure/Demos/container';
import { VirtualMachinesAddContainer } from './Add/container';

export enum VirtualMachinesRoutes {
  Add = '/add',
  ConfigureTemplateVM = '/templates/configure',
  ConfigureDemoVM = '/demos/vms/democonfigure',
  Edit = '/:virtualMachineUid',
}

export function VirtualMachinesRouter(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path} component={ConnectedVirtualMachines} />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Add}${VirtualMachinesRoutes.ConfigureTemplateVM}`}
        render={(props) => <ConnectedConfigure {...props} />}
      />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Add}${VirtualMachinesRoutes.ConfigureDemoVM}`}
        component={ConnectedDemoConfigure}
      />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Add}`}
        component={VirtualMachinesAddContainer}
      />
      <Route
        path={`${match.path}${VirtualMachinesRoutes.Edit}`}
        component={ConnectedVirtualMachinesEdit}
      />
      <Redirect to={match.url} />
    </Switch>
  );
}
