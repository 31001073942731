import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import { sessionViewSelectors } from '../../../redux/views/session-view/session/slice';
import { SessionView } from '.';

const mapStateToProps = (state: RootState) => ({
  leftPanelType: sessionViewSelectors.getLeftPanelType(state),
});

export type SessionViewProps = ReturnType<typeof mapStateToProps>;

export const SessionViewContainer = connect(mapStateToProps)(SessionView);
