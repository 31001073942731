import React, { ReactElement } from 'react';
import { getSelectedIconForIntegrationType } from '../../Configure/components/ConfigureFormIconInput/utils';
import styles from './NameWithIconCellRenderer.module.css';

export const NameWithIconCellRenderer = (props: {
  data: SaasIntegration;
}): ReactElement => {
  const {
    data: {
      icon,
      name,
      saasTemplate: { type },
    },
  }: { data: SaasIntegration } = props;
  return (
    <div className="flex">
      <div className={styles.namedIconImage}>
        {getSelectedIconForIntegrationType(icon, type)}
      </div>
      <div className="qtr-padding">{name}</div>
    </div>
  );
};
