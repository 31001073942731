import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudDocuments } from './dCloudContainer';
import { ConnectedExpoDocuments } from './expoContainer';

export function DocumentsContainer(): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoDocuments
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      ></ConnectedExpoDocuments>
    );
  }
  return (
    <ConnectedDCloudDocuments
      sessionUid={sessionUid}
      versionUid={versionUid}
    ></ConnectedDCloudDocuments>
  );
}
