import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './TableActions.module.css';

export interface AccessRoleAssignmentTableActionsProps {
  onDeleteClick: VoidFunction;
}

export function AccessRoleAssignmentTableActions({
  onDeleteClick,
}: AccessRoleAssignmentTableActionsProps): ReactElement {
  return (
    <ul aria-label="Delete access role assignment" className={styles.root}>
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={onDeleteClick}
          testId="access-role-assignment-table-actions-delete"
        >
          <Icon name="delete" />
        </Button>
      </li>
    </ul>
  );
}
