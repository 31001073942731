import axios, { AxiosResponse } from 'axios';
import { DOCUMENTS_PATH, EXPO_ENGAGEMENTS } from '../../../paths';
import { API_PATH } from './apiPath';

const documentsEndpoint = `${API_PATH}${EXPO_ENGAGEMENTS}${DOCUMENTS_PATH}`;

export async function getExpoDocument(
  documentUid: number,
  engagementUid: string,
  versionUid: string,
): Promise<AxiosResponse<Blob>> {
  const endpoint = `${documentsEndpoint}/${documentUid}?versionUid=${versionUid}&engagementId=${engagementUid}`;

  return await axios.get<Blob>(endpoint, {
    headers: {},
    responseType: 'blob',
  });
}
