import { dcloudApi } from '../../api';
import { DATACENTERS_PATH } from '../../../api/paths';
import i18n from '../../../i18n';
import { ONE_DAY } from '../../../utils/constants';
import { TAGS } from '../../api/constants';

export const dataCentersApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getDataCenters: build.query<DataCenterFromApi[], undefined>({
      keepUnusedDataFor: ONE_DAY,
      providesTags: [TAGS.DATA_CENTERS],
      query: () => ({
        errorMessage: i18n.t('dataCenters.fetch.error'),
        method: 'get',
        url: `${DATACENTERS_PATH}`,
      }),
      transformResponse: (response: GetDataCentersResponse) =>
        response.datacenters,
    }),
  }),
});

export const { useGetDataCentersQuery } = dataCentersApi;
