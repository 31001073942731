import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './PageHero.module.css';

interface PageHeroProps {
  title: string;
}

export default function PageHero({ title }: PageHeroProps): ReactElement {
  const classes = classnames('text-center', styles.root);

  return (
    <section className={classes}>
      <h1 className="display-1">{title}</h1>
    </section>
  );
}
