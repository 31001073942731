import { IconButton } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './LicenceActions.module.css';

export interface LicenceActionsProps {
  className?: string;
  canDelete?: boolean;
  onDelete: VoidFunction;
  onEdit: VoidFunction;
  uid: string;
}

export function LicenceActions({
  className = undefined,
  canDelete = false,
  onDelete,
  onEdit,
  uid,
}: LicenceActionsProps): ReactElement {
  return (
    <ul
      aria-label="Licence actions"
      className={classnames(styles.root, className)}
    >
      {canDelete && (
        <li>
          <IconButton
            className={styles.action}
            icon="delete"
            colour="link"
            onClick={onDelete}
            testId={`licence-table-delete-${uid}`}
          />
        </li>
      )}
      <li>
        <IconButton
          className={styles.action}
          icon="edit"
          colour="link"
          onClick={onEdit}
          testId={`licence-table-edit-${uid}`}
          aria-label="Edit License"
        />
      </li>
    </ul>
  );
}
