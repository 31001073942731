import React, { MouseEvent, ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import styles from './index.module.css';

export type NodeEditButtonProps = {
  name: string;
  onClick: VoidFunction;
};

export const NodeEditButton = ({
  name,
  onClick,
}: NodeEditButtonProps): ReactElement => {
  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <div
      className={styles.root}
      onClick={(event) => handleOnClick(event)}
      aria-label={`Edit ${name} Node`}
      role="button"
    >
      <Icon name="pencil" className={styles.icon} />
    </div>
  );
};
