import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import styles from './index.module.css';
import { NodeIconDeleteButtonProps } from './container';

export const NodeIconDeleteButton = ({
  setSelectedIconToDelete,
  iconToDelete,
}: NodeIconDeleteButtonProps): ReactElement => {
  const handleOnClick = () => {
    setSelectedIconToDelete(iconToDelete);
  };

  return (
    <div
      className={styles.root}
      onClick={handleOnClick}
      aria-label={`Delete ${iconToDelete.name} Icon`}
      role="button"
    >
      <Icon name="trash" className={styles.icon} />
    </div>
  );
};
