import { STRING_DATA_SEPARATOR } from '../../../../../../../../../../../constants/grid';
import i18n from '../../../../../../../../../../../i18n';

export const extractNamesFromSessionLicenses = (
  sessionLicenses?: SessionLicense[],
): string =>
  sessionLicenses && sessionLicenses.length > 0
    ? sessionLicenses
        .map((sessionLicense) => sessionLicense.name)
        .join(STRING_DATA_SEPARATOR)
    : i18n.t('sessions.panels.info.sessionInformation.licenses.errors.noData');
