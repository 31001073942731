import { NumberInput } from 'dcloud-shared-ui';
import React, { ComponentPropsWithoutRef, ReactElement } from 'react';
import {
  MAX_START_DELAY,
  MIN_START_DELAY,
} from '../../../../../../redux/configuration/start-stop-order/constants';
import styles from './StartStopDelayInput.module.css';

type StartStopDelayInputProps = ComponentPropsWithoutRef<'input'>;

export function StartStopDelayInput({
  ...inputProps
}: StartStopDelayInputProps): ReactElement {
  const invalidChars = ['-', '+', '.', 'e'];

  return (
    <NumberInput
      {...inputProps}
      className={styles.root}
      onKeyPress={(e) => {
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      }}
      min={MIN_START_DELAY}
      max={MAX_START_DELAY}
    />
  );
}
