import { FunctionComponent } from 'react';
import { TopologySVGIcon } from '../../../../components/SVGIcons/TopologySVGIcon';
import { MainRoutes } from '../../../../constants/routes';
import {
  USER_GUIDE_IDs,
  USER_GUIDE_URL,
} from '../../../../constants/user-guide';
import { REQUEST_A_FEATURE_URL } from '../../../../constants';
import i18n from '../../../../i18n';
import { TileProps } from './components/Tile';
import { ReactComponent as DocumentationImage } from './images/docs.svg';
import { ReactComponent as TemplateIcon } from './images/template.svg';
import { ReactComponent as VideosImage } from './images/videos.svg';
import { ReactComponent as Integrations } from './images/integrations.svg';
import { ReactComponent as RequestAFeature } from './images/request-a-feature.svg';

export const TILES: TileProps[] = [
  {
    Image: TopologySVGIcon as FunctionComponent,
    description: 'home.myTopologies.description',
    disabled: false,
    disabledWithoutRoles: ['ROLE_USER'],
    link: MainRoutes.Topologies,
    title: 'home.myTopologies.title',
  },
  {
    Image: DocumentationImage,
    description: 'home.documentation.description',
    externalLink: true,
    link: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.ABOUT}`,
    title: 'home.documentation.title',
  },
  {
    Image: TemplateIcon,
    description: 'home.templates.description',
    link: MainRoutes.Templates,
    requiredRoles: ['ROLE_TEMPLATE_MANAGER', 'ROLE_TEMPLATE_MANAGER'],
    title: 'home.templates.title',
  },
  {
    Image: VideosImage,
    description: `<span>
            ${i18n.t('home.videos.description.learn')} 
            <strong>${i18n.t('home.videos.description.whatsNew')}</strong>
            ${i18n.t('home.videos.description.or')} 
            <strong>${i18n.t('home.videos.description.becomeAnExpert')}</strong>
            ${i18n.t('home.videos.description.suffix')} 
          </span>`,
    link: MainRoutes.Videos,
    title: i18n.t('home.videos.title'),
  },
  {
    Image: Integrations,
    description: 'home.integrations.description',
    link: MainRoutes.Integrations,
    requiredRoles: ['ROLE_ADMIN', 'ROLE_INTEGRATION_MANAGER'],
    title: 'home.integrations.title',
  },
  {
    Image: RequestAFeature,
    description: 'home.requestAFeature.description',
    disabledWithoutRoles: ['ROLE_USER'],
    externalLink: true,
    link: REQUEST_A_FEATURE_URL,
    requiredRoles: ['ROLE_USER'],
    title: 'home.requestAFeature.title',
  },
];

export const TILE_CLASSES = 'base-padding text-center col-sm-6 col-md-4';

export const TILE_CLASS_4_COLUMN_GRID_XL = 'col-xl-3';

export const TILE_CLASS_3_COLUMN_GRID_XL = 'col-xl-4';
