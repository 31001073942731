import React, { ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './ScrollToTop.module.css';

interface Props {
  children: ReactElement;
}

const ScrollToTop = ({ children }: Props): ReactElement => {
  const [isButtonVisible, setButtonVisible] = useState<boolean>(false);
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        setButtonVisible(true);
      } else {
        setButtonVisible(false);
      }
    };
  }, []);

  const onClick = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  return (
    <div
      onClick={onClick}
      className={classnames(
        styles.scrollTop,
        isButtonVisible && styles.visible,
      )}
      data-testid="scroll-to-top-element"
    >
      <div className={styles.scrollToTopButton}>{children}</div>
    </div>
  );
};

export default ScrollToTop;
