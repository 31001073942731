import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import {
  sessionViewSelectors,
  setLeftPanelType,
  setShowEndSessionModal,
  setShowRenameSessionModal,
  setShowResetSessionModal,
  setShowSaveAndEndSessionModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudActionsBarCenter } from './dCloudContainer';
import { ConnectedExpoActionsBarCenter } from './expoContainer';

export function ActionsBarCenterContainer({
  setLeftPanelType,
  setShowEndSessionModal,
  setShowRenameSessionModal,
  setShowResetSessionModal,
  setShowSaveAndEndSessionModal,
  isSessionExpired,
}: ActionsBarCenterContainerProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoActionsBarCenter
        setLeftPanelType={setLeftPanelType}
        setShowEndSessionModal={setShowEndSessionModal}
        setShowRenameSessionModal={setShowRenameSessionModal}
        setShowResetSessionModal={setShowResetSessionModal}
        setShowSaveAndEndSessionModal={setShowSaveAndEndSessionModal}
        isSessionExpired={isSessionExpired}
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudActionsBarCenter
      setLeftPanelType={setLeftPanelType}
      setShowEndSessionModal={setShowEndSessionModal}
      setShowRenameSessionModal={setShowRenameSessionModal}
      setShowResetSessionModal={setShowResetSessionModal}
      setShowSaveAndEndSessionModal={setShowSaveAndEndSessionModal}
      isSessionExpired={isSessionExpired}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapDispatchToProps = {
  setLeftPanelType,
  setShowEndSessionModal,
  setShowRenameSessionModal,
  setShowResetSessionModal,
  setShowSaveAndEndSessionModal,
};

const mapStateToProps = (state: RootState) => ({
  isSessionExpired: sessionViewSelectors.getExpired(state),
});

export type ActionsBarCenterContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

type RTKProps = {
  actions?: HalLinks;
  sessionDetails?: SessionDetails;
  isSessionDetailsLoading: boolean;
};

export type ActionsBarCenterProps = ActionsBarCenterContainerProps & RTKProps;

export const ConnectedActionsBarCenter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionsBarCenterContainer);
