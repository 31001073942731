import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsReadOnly } from '../../../../../redux/views/slice';
import { useSessionUserContext } from '../../../../../hooks/use-session-user-context';
import { ConnectedDCloudSessionDiagram } from './dCloudContainer';
import { ConnectedExpoSessionDiagram } from './expoContainer';

export function SessionDiagramContainer(): ReactElement {
  const dispatch = useDispatch(); // FIXME: Map this dispatch to props.
  // Get context from URL
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  useEffect(() => {
    dispatch(setIsReadOnly(true));
  }, [dispatch]);

  if (engagementUid) {
    return (
      <ConnectedExpoSessionDiagram
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudSessionDiagram
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}
