import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetHardwareTemplatesQuery } from '../../../../../../redux/configuration/hardware/api';
import { convertScriptsToOptions } from '../FormScripts/utils';
import { FormTemplateConfig } from '.';

export function FormTemplatesContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data: templates, isLoading } =
    useGetHardwareTemplatesQuery(topologyUid);
  const templateOptions = [
    { name: 'None', value: '' },
    ...convertScriptsToOptions(templates || []),
  ];

  return (
    <FormTemplateConfig
      isLoading={isLoading}
      templateOptions={templateOptions}
    />
  );
}
