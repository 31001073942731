import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../redux/views/session-view/session/api/expoApi';
import { ActionsBarTimerContainerProps } from './container';
import { ActionsBarTimer } from '.';

type ConnectedExpoActionsBarTimerProps = ActionsBarTimerContainerProps &
  FetchExpoSessionArgs;

export function ConnectedExpoActionsBarTimer({
  expired,
  setShowExpiredModal,
  setExpired,
  engagementUid,
  sessionUid,
  versionUid,
}: ConnectedExpoActionsBarTimerProps): ReactElement {
  const { stop, isError } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isError }) => ({
          isError,
          stop: data?.sessionDetails.stop,
        }),
      },
    );

  if (!stop || isError) {
    return <></>;
  }

  return (
    <ActionsBarTimer
      expired={expired}
      stopDateString={stop}
      setExpired={setExpired}
      setShowExpiredModal={setShowExpiredModal}
    />
  );
}
