import { DeepMap, FieldError } from 'react-hook-form';
import { calculateNicLimits, getSubnet } from '../Utils';
import i18n from '../../../../../../../i18n';

const orderNics = (mappings: NetworkToConfigure[]): NetworkToConfigure[] => {
  return mappings.sort(
    (a, b) =>
      parseInt((a.name || '').replace(/[^0-9]/g, '')) -
      parseInt((b.name || '').replace(/[^0-9]/g, '')),
  );
};

export const prepInterfacesForTemplatesConfigureScreen = (
  interfaces: NetworkToConfigure[],
  networks: Network[],
): NetworkToConfigure[] => {
  const mappings: NetworkToConfigure[] = interfaces.map((networkInterface) => {
    let networkUid: string | undefined;
    networks.forEach((network) =>
      network.inventoryNetwork.id === networkInterface.inventoryNetworkId
        ? (networkUid = network.uid)
        : null,
    );
    return networkUid
      ? {
          ...networkInterface,
          network: networkUid,
        }
      : {
          ...networkInterface,
        };
  });

  return orderNics(calculateNicLimits(mappings, networks));
};

export const prepInterfacesForDemosConfigureScreen = (
  interfaces: NetworkToConfigure[],
  networks: Network[],
): NetworkToConfigure[] => {
  const mappings: NetworkToConfigure[] = interfaces.map((networkInterface) => {
    let networkUid: string | undefined;
    networks.some((network) =>
      network.inventoryNetwork.id ===
      networkInterface.network?.inventoryNetwork?.id
        ? (networkUid = network.uid)
        : null,
    );
    return networkUid
      ? {
          assignDhcp: networkInterface.assignDhcp,
          ipAddress: networkInterface.ipAddress,
          macAddress: networkInterface.macAddress,
          name: networkInterface.name,
          network: networkUid,
        }
      : {
          assignDhcp: networkInterface.assignDhcp,
          ipAddress: networkInterface.ipAddress,
          macAddress: networkInterface.macAddress,
          name: networkInterface.name,
        };
  });

  return orderNics(calculateNicLimits(mappings, networks));
};

export const determineIpErrorToDisplay = (
  errors: DeepMap<AddVmConfigureFormValues, FieldError>,
  index: number,
  networks: Network[],
  selectedNetworkUid: string | undefined,
): string | undefined => {
  if (!errors.interfaces?.[index]?.ipAddress?.message || !selectedNetworkUid) {
    return undefined;
  }
  if (
    errors.interfaces?.[index]?.ipAddress?.message ===
    'The IP must be in the correct range for this network'
  ) {
    const subnet = getSubnet(selectedNetworkUid, networks);
    return `${errors.interfaces?.[index]?.ipAddress?.message} ${subnet}`;
  }
  if (
    errors.interfaces?.[index]?.ipAddress?.message !==
    i18n.t('virtualMachines.add.duplicateIp')
  ) {
    return errors.interfaces?.[index]?.ipAddress?.message;
  } else {
    return undefined;
  }
};

export const determineMacErrorToDisplay = (
  errors: DeepMap<AddVmConfigureFormValues, FieldError>,
  index: number,
  networks: Network[],
  selectedNetworkUid: string | undefined,
): string | undefined => {
  if (!errors.interfaces?.[index]?.macAddress?.message || !selectedNetworkUid) {
    return undefined;
  }
  if (
    errors.interfaces?.[index]?.macAddress?.message !==
    i18n.t('virtualMachines.add.duplicateMac')
  ) {
    return errors.interfaces?.[index]?.macAddress?.message;
  } else {
    return undefined;
  }
};

export const determineNetworkErrorToDisplay = (
  errors: DeepMap<AddVmConfigureFormValues, FieldError>,
  index: number,
  selectedNetworkUid: string | undefined,
  networks: Network[],
): string | undefined => {
  if (
    errors.interfaces?.[index]?.network?.message === 'Please select a network'
  ) {
    return errors.interfaces?.[index]?.network?.message;
  }

  if (!selectedNetworkUid) {
    return undefined;
  }

  if (
    errors.interfaces?.[index]?.network?.message ===
    'Network connection limit of'
  ) {
    const network = networks.filter(
      (network) => network.uid === selectedNetworkUid,
    )[0];
    const limit = network.vmConnectionInfo.maxConnections;

    return `${i18n.t(
      'virtualMachines.configure.select.limitErrorPt1',
    )} ${limit} ${i18n.t('virtualMachines.configure.select.limitErrorPt2')}`;
  } else {
    return errors.interfaces?.[index]?.network?.message;
  }
};

export const prepMappingsForCreate = (
  interfaces: AddVmConfigureFormValues['interfaces'],
): NetworkToConfigure[] => {
  const interfacesLessNulls = interfaces.filter((item) => item !== null);
  return interfacesLessNulls.map((interfaceItem) => {
    const result = {
      ...interfaceItem,
      ipAddress: interfaceItem.ipAddress || undefined,
      macAddress: interfaceItem.macAddress || undefined,
      network: {
        uid: interfaceItem.network,
      },
    };
    return result;
  });
};

export const determineShowDeleteIcon = (
  interfaces: AddVmConfigureFormValues['interfaces'],
): boolean => {
  if (!interfaces) {
    return false;
  }
  const interfacesLessNulls = interfaces.filter((item) => item);
  return interfacesLessNulls.length > 1;
};
