import { dcloudApi } from '../../api';
import {
  ACCESS_ROLE_ASSIGNMENT_PATH,
  TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { TAGS } from '../../api/constants';

export const accessRoleAssignmentApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    addAccessRoleAssignment: build.mutation<
      AccessRoleAssignment,
      AccessRoleAssignmentPostPayload
    >({
      invalidatesTags: [TAGS.ACCESS_ROLE_ASSIGNMENT],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('accessRoleAssignment.add.error'),
        method: 'post',
        successMessage: i18n.t('accessRoleAssignment.add.success'),
        url: `${ACCESS_ROLE_ASSIGNMENT_PATH}`,
      }),
    }),
    deleteAccessRoleAssignment: build.mutation<undefined, string>({
      invalidatesTags: [TAGS.ACCESS_ROLE_ASSIGNMENT],
      query: (uid) => ({
        errorMessage: i18n.t('accessRoleAssignment.delete.error'),
        method: 'DELETE',
        successMessage: i18n.t('accessRoleAssignment.delete.success'),
        url: `${ACCESS_ROLE_ASSIGNMENT_PATH}/${uid}`,
      }),
    }),
    getAccessRoleAssignments: build.query<
      FetchAccessRoleAssignmentResponse,
      string
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.ACCESS_ROLE_ASSIGNMENT],
      query: (topologyUid) => ({
        errorMessage: i18n.t('accessRoleAssignment.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${ACCESS_ROLE_ASSIGNMENT_PATH}`,
      }),
    }),
  }),
});

export const {
  useGetAccessRoleAssignmentsQuery,
  useAddAccessRoleAssignmentMutation,
  useDeleteAccessRoleAssignmentMutation,
} = accessRoleAssignmentApi;
