import React, { ReactElement } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../components/PageHeading';
import { DemoTemplateTable } from './Table';
import { ConnectedEditDemoTemplateModal } from './Components/EditDemoTemplate/container';
import { ConnectedAddDemoTemplateModal } from './Components/AddDemoTemplate/container';

interface DemoTemplateProps {
  demoTemplates: DemoTemplate[];
  setSelectedDemoTemplate: ActionCreatorWithOptionalPayload<
    DemoTemplate | undefined,
    string
  >;
  selectedDemoTemplate?: DemoTemplate;
  isLoading: boolean;
}

export function DemoTemplates({
  demoTemplates,
  isLoading,
  setSelectedDemoTemplate,
  selectedDemoTemplate,
}: DemoTemplateProps): ReactElement {
  const { t } = useTranslation();
  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('templateManager.demoTemplates.tabTitle')} />
      <div className="row flex-right">
        <DemoTemplateTable
          setSelectedDemoTemplate={setSelectedDemoTemplate}
          demoTemplates={demoTemplates}
          loading={isLoading}
        />
        {selectedDemoTemplate && selectedDemoTemplate.inventoryOnly ? (
          <ConnectedEditDemoTemplateModal />
        ) : (
          <ConnectedAddDemoTemplateModal />
        )}
      </div>
    </section>
  );
}
