import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ScenariosState {
  scenariosToSave?: Scenarios;
}

export const initialState: ScenariosState = {
  scenariosToSave: undefined,
};

export const slice = createSlice({
  initialState,
  name: 'scenarios',
  reducers: {
    setScenariosToSave: (
      state,
      action: PayloadAction<Scenarios | undefined>,
    ) => {
      state.scenariosToSave = action.payload;
    },
  },
});

export const {
  reducer: scenariosReducer,
  actions: { setScenariosToSave },
} = slice;

const getScenariosToSave = (state: RootState) => state[slice.name];

export const scenariosSelectors = {
  getScenariosToSave: createSelector(
    getScenariosToSave,
    ({ scenariosToSave }) => scenariosToSave,
  ),
};
