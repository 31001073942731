import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { networkFieldsSchema } from '../../../validation';
import { EditNetworkFormProps } from '../components/EditNetworkForm';
import {
  getEditNetworkAlertMessage,
  getEditNetworkCheckboxState,
  getNetworkSubnetOptions,
  handleRoutedChange,
} from '../utils';

type UseEditNetworkFormProps = Omit<EditNetworkFormProps, 'editing'> & {
  networkToEdit: Network;
  isLastRouted: boolean;
  updateNode: RTKMutation<UpdateDiagramNodeProps, DiagramNode>;
  customOnSubmit?: VoidFunction;
  nodeData?: DiagramNode;
};

export function useEditNetworkForm({
  availableRouted,
  availableUnrouted,
  networkToEdit,
  updateNetwork,
  nodeData,
  updateNode,
  isLastRouted,
  customOnSubmit,
}: UseEditNetworkFormProps): UseNetworkForm {
  const [isRouted, setIsRouted] = useState<boolean>(
    networkToEdit?.inventoryNetwork.type === 'ROUTED',
  );

  const isNetworkCheckboxDisabled = getEditNetworkCheckboxState({
    availableRouted,
    availableUnrouted,
    inventoryToEdit: networkToEdit.inventoryNetwork,
    isLastRouted,
  });

  const alertMessage = getEditNetworkAlertMessage({
    availableRouted,
    availableUnrouted,
    inventoryToEdit: networkToEdit.inventoryNetwork,
    isLastRouted,
    isNetworkCheckboxDisabled,
  });

  const subnetOptions = getNetworkSubnetOptions({
    availableRouted,
    availableUnrouted,
    inventoryToEdit: networkToEdit.inventoryNetwork,
    isRouted,
  });

  const {
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<NetworkFormFields>({
    defaultValues: {
      description: networkToEdit.description,
      hiddenFromSession: nodeData?.display?.hidden === true,
      name: networkToEdit.name,
      subnet: networkToEdit.inventoryNetwork.id,
    },
    mode: 'all',
    resolver: yupResolver(networkFieldsSchema),
  });

  const { isDirty, isValid, dirtyFields } = formState;

  const onSubmit: UseNetworkForm['onSubmit'] = async (formData) => {
    const networkUpdatePayload: NetworkToUpdate = {
      description: formData.description,
      inventoryNetwork: { id: formData.subnet },
      name: formData.name,
      topology: networkToEdit.topology,
      uid: networkToEdit.uid,
    };
    await updateNetwork(networkUpdatePayload);
    if (dirtyFields.hiddenFromSession) {
      await updateNode({
        display: {
          ...nodeData?.display,
          hidden: formData.hiddenFromSession,
        },
        nodeUid: nodeData?.uid || '',
      })
        .unwrap()
        .catch(() => {
          return;
        });
    }
    customOnSubmit && customOnSubmit();
  };

  const onRoutedChange: UseNetworkForm['onRoutedChange'] = (e) => {
    handleRoutedChange({
      getValues,
      newRoutedValue: e.target.checked,
      setIsRouted,
      setValue,
      trigger,
    });
  };

  return {
    alertMessage,
    control,
    errors,
    handleSubmit,
    isDirty,
    isNetworkCheckboxDisabled,
    isRouted,
    isValid,
    onRoutedChange,
    onSubmit,
    subnetOptions,
  };
}
