import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useSkeletonColours } from '../../../../../../../../../../hooks/use-skeleton-colours';

export function RemoteAccessFormLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  return (
    <ContentLoader
      className="half-margin-top"
      speed={1.5}
      width="100%"
      height="200"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0" y="0" rx="3" ry="3" width="500" height="50" />
      <rect x="0" y="75" rx="3" ry="3" width="500" height="50" />
      <rect x="0" y="150" rx="3" ry="3" width="500" height="50" />
      <rect x="550" y="0" rx="3" ry="3" width="50" height="50" />
      <rect x="550" y="75" rx="3" ry="3" width="50" height="50" />
      <rect x="550" y="150" rx="3" ry="3" width="50" height="50" />
      <rect x="620" y="0" rx="3" ry="3" width="500" height="50" />
      <rect x="620" y="75" rx="3" ry="3" width="500" height="50" />
      <rect x="620" y="150" rx="3" ry="3" width="500" height="50" />
    </ContentLoader>
  );
}
