import React, { ReactElement } from 'react';
import { IpAddressesModalTable } from '../IpAddressesModalTable';
import { IpAddressesTableNoDataAlert } from '../IpAddressesTableNoDataAlert';
import { IpAddressesModalCloseButton } from '../IpAddressesModalCloseButton';

export type IpAddressesModalContentProps = {
  ipAddresses: SessionInventoryVirtualMachineDataIpAddress[];
  onClose: VoidFunction;
};

export const IpAddressesModalContent = ({
  ipAddresses,
  onClose,
}: IpAddressesModalContentProps): ReactElement => {
  return (
    <>
      {ipAddresses.length > 0 ? (
        <IpAddressesModalTable ipAddresses={ipAddresses} />
      ) : (
        <IpAddressesTableNoDataAlert />
      )}
      <IpAddressesModalCloseButton onClose={onClose} />
    </>
  );
};
