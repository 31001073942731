import i18n from '../../../../../i18n';
import { NO_TEXT } from '../../constants';

export const prepVMItemsForSessionInventoryVirtualMachines = (
  sessionUid: string,
  versionUid: string,
  nodes?: DiagramNode[],
): SessionInventoryVirtualMachineData[] | undefined => {
  if (!nodes) {
    return undefined;
  }

  const filteredNodes = nodes.filter((node) => node.assetType === 'VM');

  return filteredNodes.map((node) => {
    return {
      cpuQty: (node as SessionVmNode).asset?.cpuQty || NO_TEXT,
      description: (node as SessionVmNode)?.asset?.description || NO_TEXT,
      ipAddresses: buildIpAddressesForSessionInventoryVmData(
        node as DiagramNodeVM,
      ),
      memory: (node as SessionVmNode)?.asset?.memoryMb
        ? (node as SessionVmNode).asset.memoryMb / 1000
        : 0,
      mor: (node as SessionVmNode).sessionDetails.mor,
      name: (node as SessionVmNode).asset?.name || NO_TEXT,
      nics: (node as SessionVmNode).asset?.vmNetworkInterfaces?.length || 0,
      sessionUid,
      versionUid,
    };
  });
};

export const buildIpAddressesForSessionInventoryVmData = (
  node: DiagramNodeVM,
): SessionInventoryVirtualMachineData['ipAddresses'] => {
  if (!node.asset?.vmNetworkInterfaces) {
    return [];
  }

  return node
    ? node.asset.vmNetworkInterfaces.reduce(
        (
          ipAddresses: SessionInventoryVirtualMachineDataIpAddress[],
          vmNetworkInterface,
        ) => {
          vmNetworkInterface.ipAddress &&
            ipAddresses.push({
              ipAddress: vmNetworkInterface.ipAddress as string,
              name: vmNetworkInterface.name || '',
              networkName: vmNetworkInterface.network?.name || '',
            });
          return ipAddresses;
        },
        [],
      )
    : [];
};

export const buildRefreshButtonLabels = ({
  isCallingVmStatusAPICalls,
  hasCalledVmStatusApiCalls,
}: {
  isCallingVmStatusAPICalls: boolean;
  hasCalledVmStatusApiCalls: boolean;
}): string => {
  const pathToText =
    'sessionsInventory.virtualMachines.getStatusesButtonLabels';
  return isCallingVmStatusAPICalls
    ? hasCalledVmStatusApiCalls
      ? i18n.t(`${pathToText}.refreshing`)
      : i18n.t(`${pathToText}.getting`)
    : hasCalledVmStatusApiCalls
    ? i18n.t(`${pathToText}.refresh`)
    : i18n.t(`${pathToText}.get`);
};
