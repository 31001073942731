import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useSkeletonColours } from '../../../../../hooks/use-skeleton-colours';
import { PageHeadingWithCTA } from '../../../components/PageHeadingWithCTA/PageHeadingWithCTA';

export function PSTNValueLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();
  const { t } = useTranslation();

  return (
    <section className="half-padding-left">
      <PageHeadingWithCTA pageTitle={t('telephony.name')} />
      <ContentLoader
        className="margin-top"
        speed={1.5}
        width="100%"
        height={150}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" rx="3" ry="3" width="170" height="20" />
      </ContentLoader>
    </section>
  );
}
