import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../components';
import { useDeleteHardwareMutation } from '../../../../../../redux/configuration/hardware/api';
export interface DeleteModalProps {
  hardware?: Hardware;
  onClose: VoidFunction;
}

export function DeleteModal({
  hardware,
  onClose,
}: DeleteModalProps): ReactElement | null {
  const { t } = useTranslation();
  const [deleteHardware, { isLoading, isSuccess, isError }] =
    useDeleteHardwareMutation();

  if (!hardware) {
    return null;
  }

  (isSuccess || isError) && onClose();

  return (
    <ConfirmModal
      heading={t('hardware.delete.heading').replace(
        ':hardware',
        hardware.name as string,
      )}
      highlightedSelection={hardware.name}
      subHeading={t('hardware.delete.subHeading')}
      loading={isLoading}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={() => deleteHardware(hardware.uid)}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="hardware-delete-modal"
    />
  );
}
