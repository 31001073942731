type UpdateTextNode = {
  updateNode: RTKMutation<UpdateDiagramNodeProps, UpdateDiagramNodeProps>;
  display?: DiagramDisplay;
  uid: string;
  x: number;
  y: number;
};

export const updateTextNode = ({
  updateNode,
  display,
  uid,
  x,
  y,
}: UpdateTextNode): void => {
  updateNode({ display: { ...display, x, y }, nodeUid: uid });
};

export const calculateCenterPointsOfNode = (nodeUid: string): number[] => {
  const element = document.querySelector(`[data-component="${nodeUid}"]`);

  return [
    element
      ? element.getBoundingClientRect().x +
        element.getBoundingClientRect().width / 2
      : 0,
    element
      ? element.getBoundingClientRect().y +
        element.getBoundingClientRect().height / 2
      : 0,
  ];
};

type DetermineIsRotating = {
  rotateIconObject: HTMLObjectElement | null;
  target: HTMLObjectElement;
};

export const determineIsRotating = ({
  rotateIconObject,
  target,
}: DetermineIsRotating): boolean => rotateIconObject?.contains(target) || false;
