import { ThemeValues } from 'dcloud-shared-ui';
import { DefaultLineNodeDisplay } from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLine/constants';
import { convertColorNameToHtmlColorValue } from '../../../../components/DrawingPalettes/components/DrawingColors/utils';
import { DiagramDrawingColorName } from '../../../../typings/drawing-palettes';

export const determineColorFromSelectedColorAndTheme = (
  selectedColor: DiagramDrawingColorName,
  selectedTheme: ThemeValues,
): HtmlColorValue => {
  const { strokeColorNameForLightTheme, strokeColorNameForDarkTheme } =
    DefaultLineNodeDisplay;
  const isLightTheme = selectedTheme === 'light';
  const defaultColorNameForSelectedTheme = isLightTheme
    ? strokeColorNameForLightTheme
    : strokeColorNameForDarkTheme;
  const isDefaultColorSelected = selectedColor === strokeColorNameForDarkTheme;
  const strokeColorName =
    isLightTheme && isDefaultColorSelected
      ? strokeColorNameForLightTheme
      : selectedColor || defaultColorNameForSelectedTheme;
  return convertColorNameToHtmlColorValue(strokeColorName);
};
