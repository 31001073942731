import { SagaIterator } from 'redux-saga';
import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { PayloadAction } from '@reduxjs/toolkit';
import { addErrorToast } from '../../../toast/slice';
import i18n from '../../../../i18n';
import { API_ERROR_CODES } from '../../../../utils/constants';
import { removeTokens } from '../../../../auth';
import { MainRoutes } from '../../../../constants/routes';
import { getDocument } from '../../../../api/views/sessionView/getInternalDocument/api';
import { getExpoDocument } from '../../../../api/views/sessionView/getInternalDocument/expoApi';
import { addLinkToDomAndClick, removeObjectUrl } from './utils';

export function* getInternalDocumentWorker({
  payload,
}: PayloadAction<GetInternalDocumentPayload>): SagaIterator {
  const { documentUid, versionUid } =
    payload as unknown as GetInternalDocumentPayload;
  let documentLink: HTMLAnchorElement | undefined;
  try {
    const response = yield call(getDocument, documentUid, versionUid);
    yield call(addLinkToDomAndClick, response, documentLink);
  } catch (e) {
    const error = e as AxiosError;
    yield put(addErrorToast({ message: i18n.t('sessions.error') }));
    if (error.response?.status === API_ERROR_CODES.NOT_AUTHORIZED) {
      yield call(removeTokens);
      yield put(push(MainRoutes.NotAuthorisedGenericError));
    }
  } finally {
    yield call(removeObjectUrl, documentLink);
  }
}

export function* getExpoInternalDocumentWorker({
  payload,
}: PayloadAction<GetExpoInternalDocumentPayload>): SagaIterator {
  const { documentUid, engagementUid, versionUid } =
    payload as unknown as GetExpoInternalDocumentPayload;
  let documentLink: HTMLAnchorElement | undefined;
  try {
    const response = yield call(
      getExpoDocument,
      documentUid,
      engagementUid,
      versionUid,
    );
    yield call(addLinkToDomAndClick, response, documentLink);
  } catch (e) {
    yield put(addErrorToast({ message: i18n.t('sessions.error') }));
  } finally {
    yield call(removeObjectUrl, documentLink);
  }
}
