import { useState } from 'react';
import {
  useCreateTopologyApiMutation,
  useCreateTopologyFromTemplateMutation,
} from '../../../redux/topologies/api';

interface UseCreateTopology {
  creating: boolean;
  datacenter?: DataCenter;
  demoType: string;
  demoToImport?: InventoryDemo;
  templateToImport?: InventoryTopology;
  onCreate: (fields: TopologyGeneralFields) => void;
  setDatacenter: (datacenter: DataCenter) => void;
  setDemoType: (demoType: string) => void;
  setDemoToImport: (demo?: InventoryDemo) => void;
  setTemplateToImport: (template?: InventoryTopology) => void;
}

export function useCreateTopology(): UseCreateTopology {
  const [creating, setCreating] = useState(false);
  const [demoType, setDemoType] = useState('');
  const [demoToImport, setDemoToImport] = useState<InventoryDemo | undefined>(
    undefined,
  );
  const [templateToImport, setTemplateToImport] = useState<
    InventoryTopology | undefined
  >(undefined);
  const [datacenter, setDatacenter] = useState<DataCenter | undefined>(
    undefined,
  );

  const [createTopology] = useCreateTopologyApiMutation();
  const [createTopologyFromTemplate] = useCreateTopologyFromTemplateMutation();

  async function onCreate(fields: TopologyGeneralFields): Promise<void> {
    if (!datacenter) return;
    if (demoType === 'IMPORT' && !demoToImport) return;

    setCreating(true);

    const payload: TopologyToCreate = {
      ...fields,
      datacenter,
    };

    if (demoType === 'IMPORT' && demoToImport) {
      payload.demoId = demoToImport.id;
      await createTopology(payload);
    } else if (demoType === 'TEMPLATE' && templateToImport) {
      payload.topologyUid = templateToImport.uid;
      await createTopologyFromTemplate(payload);
    } else {
      await createTopology(payload);
    }

    setCreating(false);
  }

  return {
    creating,
    datacenter,
    demoToImport,
    demoType,
    onCreate,
    setDatacenter,
    setDemoToImport,
    setDemoType,
    setTemplateToImport,
    templateToImport,
  };
}
