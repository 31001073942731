import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stripGzipFromEtag } from '../../../api/utils';
import { RootState } from '../../store';

export interface etagCollectionState {
  etags: CustomEtag[];
}

export const initialState: etagCollectionState = {
  etags: [],
};

const slice = createSlice({
  initialState,
  name: 'etagCollections',
  reducers: {
    addCustomEtag: (state, action: PayloadAction<CustomEtag>) => {
      state.etags = [
        ...state.etags,
        {
          etag: stripGzipFromEtag(action.payload.etag),
          referenceName: action.payload.referenceName,
        },
      ];
    },
    clearGivenEtag: (state, action: PayloadAction<string>) => {
      state.etags = state.etags.filter(
        (etag) => !etag.referenceName.includes(action.payload),
      );
    },
  },
});

export const getEtagCollectionsState = (
  state: RootState,
): etagCollectionState => state[slice.name];

export const customEtagSelectors = {
  getAllCustomEtags: (state: RootState): CustomEtag[] =>
    getEtagCollectionsState(state).etags,
};

export const { reducer: etagCollectionsReducer } = slice;
export const { addCustomEtag, clearGivenEtag } = slice.actions;
