import { Button, Icon, IconNames } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { TopologyViewTypes } from '../../constants';
import styles from './ViewToggle.module.css';

export interface ViewToggleProps {
  toggleView: (view: TopologyViewTypes) => void;
  view: TopologyViewTypes;
}

interface ToggleButton {
  type: TopologyViewTypes;
  icon: IconNames;
}

const BUTTONS: ToggleButton[] = [
  {
    icon: 'grid-view',
    type: TopologyViewTypes.List,
  },
  {
    icon: 'list-view',
    type: TopologyViewTypes.Table,
  },
];

export function ViewToggle({
  toggleView,
  view,
}: ViewToggleProps): ReactElement {
  return (
    <ButtonGroup className={styles.root}>
      {BUTTONS.map((button) => (
        <Button
          key={button.type}
          onClick={() => toggleView(button.type)}
          colour={view === button.type ? 'primary' : 'ghost'}
          testId={`view-toggle-${button.type}`}
        >
          <Icon name={button.icon} />
        </Button>
      ))}
    </ButtonGroup>
  );
}
