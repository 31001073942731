import { SelectOption } from 'dcloud-shared-ui/dist/components/Select';
import React, { ReactElement } from 'react';
import { Control, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UserRoleGuardContainer from '../../../../../../auth/components/UserRoleGuard/container';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import {
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../components/Form';
import { FormUnsavedChangesPrompt } from '../../../../../../components/FormUnsavedChangesPrompt';
import { FormWrapper } from '../../../../../../components/FormWrapper';
import { useLeaveFormPrompt } from '../../../../hooks/useLeaveFormPrompt';
import styles from './ExternalDnsAddForm.module.css';

interface ExternalDnsAddFormProps {
  control: Control<ExternalDnsFormData>;
  errors: UseFormMethods['errors'];
  formState: UseFormMethods['formState'];
  handleSubmit: UseFormMethods['handleSubmit'];
  assetOptions: SelectOption<string>[];
  natRuleOptions: SelectOption<string>[];
  isCreating: boolean;
  handleCreate: SubmitHandler<ExternalDnsFormData>;
}

export function ExternalDnsAddForm({
  control,
  errors,
  isCreating,
  formState,
  handleCreate,
  handleSubmit,
  assetOptions,
  natRuleOptions,
}: ExternalDnsAddFormProps): ReactElement | null {
  const { t } = useTranslation();
  const { isDirty, isValid } = formState;

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <>
      <FormWrapper
        aria-label="Add External DNS form"
        className={styles.root}
        onSubmit={handleSubmit(handleCreate)}
      >
        <ControlledSelect
          control={control}
          label={t('traffic.externalDns.table.headings.nat')}
          name="natRule"
          required={true}
          error={errors?.natRule?.message}
          className={styles.natTarget}
          options={natRuleOptions}
        />
        <UserRoleGuardContainer
          requiredRoles={['ROLE_DEVELOPER', 'ROLE_ADMIN']}
        >
          <ControlledSelect
            control={control}
            label={t('traffic.externalDns.table.headings.asset')}
            name="asset"
            error={errors?.asset?.message}
            options={assetOptions}
            className={styles.asset}
          />
        </UserRoleGuardContainer>
        <ControlledTextfield
          control={control}
          label={t('traffic.externalDns.table.headings.hostname')}
          name="hostname"
          required={true}
          error={errors?.hostname?.message}
          className={styles.hostname}
          isNumber={true}
        />
        <PrimaryButton
          disabled={!isDirty || !isValid}
          loading={isCreating}
          type="submit"
        >
          {t('traffic.externalDns.addButton')}
        </PrimaryButton>
      </FormWrapper>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
}
