import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';
import { PropsFromRedux } from './container';

export interface ConfirmDeleteTopologyModalProps extends PropsFromRedux {
  clearTopologyToDelete: VoidFunction;
  deleteTopology: VoidFunction;
  isDeleting: boolean;
}

export function ConfirmDeleteTopologyModal({
  clearTopologyToDelete,
  deleteTopology,
  isDeleting,
  shouldConfirmDelete,
  topologyToDelete,
}: ConfirmDeleteTopologyModalProps): ReactElement | null {
  const { t } = useTranslation();

  if (!shouldConfirmDelete || !topologyToDelete) {
    return null;
  }

  return (
    <ConfirmModal
      heading={t('topologies.delete.heading').replace(
        ':topology',
        topologyToDelete.name as string,
      )}
      subHeading={t('topologies.delete.confirmWarning')}
      highlightedSelection={topologyToDelete?.name}
      loading={isDeleting}
      onCancel={clearTopologyToDelete}
      onClose={clearTopologyToDelete}
      onConfirm={deleteTopology}
      challenge={true}
      challengePrompt={t('topologies.delete.confirmPrompt')}
      challengeValue="delete"
      confirmButtonLabel={t('buttons.delete')}
      show={!!shouldConfirmDelete}
      size="small"
      testId="confirm-topology-delete-modal"
    />
  );
}
