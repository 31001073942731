import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { ControlledSelect } from '../../../../../components';

export interface NetworkSubnetSelectProps {
  control: UseFormMethods<NetworkFormFields>['control'];
  errorMesage?: string;
  options: NetworkSubnetOptions;
}

export function NetworkSubnetSelect({
  control,
  errorMesage,
  options,
}: NetworkSubnetSelectProps): ReactElement {
  return (
    <ControlledSelect<NetworkFormFields>
      control={control}
      error={errorMesage}
      name="subnet"
      options={options}
      label="Subnet"
      required={true}
      testId="network-form-subnet-select"
    />
  );
}
