import { Switch } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { PanelGrid } from '../PanelGrid';
import { EndpointKitsPanelProps } from './container';

export function EndpointKitsPanel({
  data,
}: EndpointKitsPanelProps): ReactElement {
  const panelGridData: PanelGridDataItem[] = Object.keys(data).map(function (
    item,
  ) {
    return {
      key: item,
      name: `Router ${item}`,
      value: <Switch checked={data[item]} onChange={() => null} />,
    };
  });

  return (
    <div>
      <PanelGrid data={panelGridData} wideCol="name" />
    </div>
  );
}
