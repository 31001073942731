import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';
import { ConfirmDeleteAdminIntegrationModalProps } from './container';

export function ConfirmDeleteAdminIntegrationModal({
  deleteIntegration,
  integrationToDelete,
  clearIntegrationToDelete,
  isDeleting,
}: ConfirmDeleteAdminIntegrationModalProps): ReactElement {
  const { t } = useTranslation();
  return !!integrationToDelete ? (
    <ConfirmModal
      heading={t('integrations.delete.heading').replace(
        ':name',
        integrationToDelete.name,
      )}
      subHeading={t('integrations.delete.subHeading')}
      loading={isDeleting}
      onCancel={clearIntegrationToDelete}
      onClose={clearIntegrationToDelete}
      onConfirm={() => deleteIntegration(integrationToDelete.uid)}
      confirmButtonLabel={t('buttons.delete')}
      size="small"
      show={true}
      testId="admin-integration-delete-modal"
    />
  ) : (
    <></>
  );
}
