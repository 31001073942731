import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../../components/PageHeading';
import { useSkeletonColours } from '../../../../../../hooks/use-skeleton-colours';

export function EditVmTemplateFormLoader(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  const { t } = useTranslation();
  return (
    <section className="half-padding-left">
      <PageHeading
        pageTitle={t('templateManager.vmTemplates.form.titles.edit')}
        withBackBtn={true}
      />
      <ContentLoader
        className="dbl-margin-top"
        speed={1.5}
        width="100%"
        height={340}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="-10" y="0" rx="3" ry="3" width="100%" height="60" />
        <rect x="40" y="120" rx="3" ry="3" width="50%" height="50" />
        <rect x="40" y="210" rx="3" ry="3" width="50%" height="100" />
      </ContentLoader>
    </section>
  );
}
