import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Button, LoaderSpinner, ButtonProps } from 'dcloud-shared-ui';
import styles from './SubmitButton.module.css';

export interface SubmitButtonProps {
  className?: string;
  colour?: ButtonProps['colour'];
  disabled?: boolean;
  loading?: boolean;
  children?: string;
  testId?: string;
}

export function SubmitButton({
  children,
  colour = 'primary',
  className,
  disabled = false,
  loading = false,
  testId,
}: SubmitButtonProps): ReactElement {
  const classes = classnames(className, {
    [styles.loading]: loading,
  });

  return (
    <Button
      className={classes}
      colour={colour}
      size="large"
      type="submit"
      disabled={disabled || loading}
      testId={testId}
    >
      {loading ? <LoaderSpinner size="small" /> : children}
    </Button>
  );
}
