import { connect } from 'react-redux';
import { setLeftPanelType } from '../../../../../../redux/views/session-view/session/slice';
import { PanelHeaderButtons } from '.';

const mapDispatchToProps = {
  closeOnClick: () => setLeftPanelType(undefined),
  refreshOnClick: undefined,
};

export type PanelHeaderButtonsProps = typeof mapDispatchToProps;

export const PanelHeaderButtonsContainer = connect(
  null,
  mapDispatchToProps,
)(PanelHeaderButtons);
