import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { TopologiesList } from '../TopologiesList';
import { TopologiesTable } from '../TopologiesTable';
import { TOPOLOGIES_LIMIT, TopologyViewTypes } from '../../constants';
import { ViewToggle } from '../ViewToggle';
import { Search } from '../../../../components/Search';
import { MainRoutes } from '../../../../constants/routes';
import { CenteredSpinner } from '../../../../components';
import { FilterContainer } from '../TopologiesFilters/container';
import { TopologiesViewProps } from './container';
import styles from './TopologiesView.module.css';

export function TopologiesView({
  onClone,
  onDelete,
  onPush,
  onUpdate,
  topologies = [],
  setSearchValue,
  searchValue,
  topologiesViewType,
  setTopologiesViewType,
  isApplyingFilters,
  setFilteredToplogies,
  setTopologies,
}: TopologiesViewProps): ReactElement {
  const { t } = useTranslation();

  const [viewType, setViewType] = useState(
    topologiesViewType
      ? topologiesViewType
      : topologies.length > TOPOLOGIES_LIMIT
      ? TopologyViewTypes.Table
      : TopologyViewTypes.List,
  );

  const Component = topologiesViewType
    ? topologiesViewType === TopologyViewTypes.List
      ? TopologiesList
      : TopologiesTable
    : viewType === TopologyViewTypes.List
    ? TopologiesList
    : TopologiesTable;

  useEffect(() => {
    return () => {
      setFilteredToplogies([]);
      setTopologies([]);
    };
  }, [setFilteredToplogies, setTopologies]);

  useEffect(() => {
    if (topologiesViewType) {
      setViewType(topologiesViewType);
    }
  }, [topologiesViewType]);
  return (
    <>
      <div
        className={classnames(
          'dbl-padding-bottom',
          'base-padding-left',
          'base-padding-right',

          viewType === TopologyViewTypes.Table
            ? styles.tableView
            : styles.cardView,
        )}
      >
        <div className={classnames('', styles.searchWrapper)}>
          <Search
            className={classnames(
              'base-padding-top half-padding-bottom',
              styles.search,
            )}
            id="topologies-search-input"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('common.search')}
            value={searchValue}
          />
          <span
            className={classnames({
              [styles.searchFeedback]:
                searchValue.length > 0 && searchValue.length < 3,
              [styles.searchFeedbackHidden]:
                searchValue.length === 0 || searchValue.length > 2,
            })}
          >
            {t('topologies.pleaseEnterThreeCharacters')}
          </span>
          <FilterContainer />
        </div>
        <div className="flex flex-between flex-middle">
          <LinkButton
            to={MainRoutes.CreateTopology}
            className="base-margin-top base-margin-bottom"
            size="large"
            colour="success"
            testId="topologies-create"
          >
            {t('buttons.create')}
          </LinkButton>
          <div className="flex flex-right">
            <ViewToggle
              view={topologiesViewType || viewType}
              toggleView={setTopologiesViewType}
            />
          </div>
        </div>

        {topologies.length ? (
          <Component
            topologies={topologies}
            onDelete={onDelete}
            onClone={onClone}
            onPush={onPush}
            onUpdate={onUpdate}
          />
        ) : isApplyingFilters ? (
          <CenteredSpinner />
        ) : (
          <Alert>{t('topologies.filter.empty')}</Alert>
        )}
      </div>
    </>
  );
}
