import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton } from 'dcloud-shared-ui';
import { NotifyPanel } from '../../../../components';
import { CreateTopologyRoutes } from '../../../../constants/routes';

export function TopologiesNoResults(): ReactElement {
  const { t } = useTranslation();

  return (
    <NotifyPanel
      callToAction={
        <LinkButton
          to={CreateTopologyRoutes.DataCenter}
          className="base-margin-top base-margin-bottom"
          size="large"
          colour="success"
          testId="no-topologies-create-button"
        >
          {t('buttons.create')}
        </LinkButton>
      }
      heading={t('topologies.noTopologies.heading')}
      subHeading={t('topologies.noTopologies.subHeading')}
    />
  );
}
