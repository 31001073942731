import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { NodeStrokeTypes } from '../../../../../../components/DrawingPalettes/constants';
import styles from './styles.module.css';

export type BoxSelectorProps = {
  box: DiagramNodeDisplayOnly;
  selectedItem: string;
  toggleSelectedItem: (val: string) => void;
  isSessionView: boolean;
};

export const BoxSelector = ({
  box,
  selectedItem,
  toggleSelectedItem,
  isSessionView,
}: BoxSelectorProps): ReactElement => {
  if (isSessionView) return <></>;

  const classes = classNames(styles.root, {
    [styles.selected]: selectedItem === box.uid,
  });

  const STROKE = 'transparent';
  const KEY = `${box.uid}-selector`;
  const FILL = 'none';
  const WIDTH = 0;
  const HEIGHT = 0;
  const X = 0;
  const Y = 0;
  const STROKE_WIDTH = 6;
  const STROKE_TYPE = NodeStrokeTypes.solid;

  const handleOnClick = () => {
    toggleSelectedItem(box.uid);
  };

  return (
    <rect
      className={classes}
      data-box-rect-selector-uid={box.uid}
      key={KEY}
      stroke={STROKE}
      fill={FILL}
      width={WIDTH}
      height={HEIGHT}
      x={X}
      y={Y}
      strokeWidth={STROKE_WIDTH}
      onClick={handleOnClick}
      stroke-type={STROKE_TYPE}
    ></rect>
  );
};
