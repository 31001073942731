import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../redux/views/session-view/session/api/api';
import { getHwInfoFromNodeDetails } from './utils';
import { HwInfoContainerProps } from './container';
import { HWInfo } from '.';

type ConnectedDCloudHWInfoProps = HwInfoContainerProps & FetchSessionArgs;

export function ConnectedDCloudHWInfo({
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedDCloudHWInfoProps): ReactElement {
  const { hWInfoData } = sessionApi.endpoints.getSession.useQueryState(
    { sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        hWInfoData: getHwInfoFromNodeDetails(
          data?.diagram.nodes.filter(
            (node) => node.uid === nodeUid,
          )[0] as SessionHwNode,
        ),
      }),
    },
  );

  return <HWInfo data={hWInfoData || {}} />;
}
