import { useEffect } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

export type UseResetInventorySaasTemplateToConfigureArgs = {
  setInventorySaasTemplateToConfigure: ActionCreatorWithOptionalPayload<
    SaasTemplate | undefined,
    string
  >;
};

export function useResetInventorySaasTemplateToConfigure({
  setInventorySaasTemplateToConfigure,
}: UseResetInventorySaasTemplateToConfigureArgs): void {
  useEffect(() => {
    return () => {
      setInventorySaasTemplateToConfigure();
    };
  }, [setInventorySaasTemplateToConfigure]);
}
