import React, { ChangeEvent, ReactElement } from 'react';
import { Checkbox } from 'dcloud-shared-ui';
import classnames from 'classnames';
import { SelectCellRendererProps } from './container';
import styles from './SellectCellRenderer.module.css';

export const SelectCellRenderer = ({
  addSelectedItemToAction,
  removeSelectedItemToAction,
  cellData,
  isChecked,
  isDisabled,
  isHidden,
  page,
}: SelectCellRendererProps): ReactElement => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? addSelectedItemToAction({ item: cellData, page })
      : removeSelectedItemToAction({ item: cellData, page });
  };
  return (
    <div className={classnames(styles.root, { [styles.hidden]: isHidden })}>
      <Checkbox
        onChange={handleOnChange}
        checked={isChecked}
        disabled={isDisabled}
        data-testid="session-inventory-select-checkbox"
      />
    </div>
  );
};
