import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { PLEASE_SELECT_OPTION } from '../../../../../../../constants/form';
import {
  useCreateIpNatRuleMutation,
  useCreateVmNatRuleMutation,
} from '../../../../../../../redux/configuration/traffic/nat-rules/api';
import { natRulesSchema } from '../../../../../../../validation';
import { buildInternalIps, buildNatRulePayload } from '../../utils';
import {
  topologyLimitsSelectors,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
} from '../../../../../../../redux/configuration/topologyLimits/slice';
import { RootState } from '../../../../../../../redux/store';
import { topologyLimitsApi } from '../../../../../../../redux/configuration/topologyLimits/api';
import { NAT_RULE_TARGET_TYPES } from '../../../../../../../constants/nat-rules';
import { determineNatRuleTargetTypeFromNatRuleType } from './utils/utils';
import { NatRulesAddForm } from '.';

type ConnectedNatRulesAddFormOwnProps = {
  internalIps?: VmNicTarget[];
};

export function NatRulesAddFormContainer({
  internalIps,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
  topologyLimitsWarningAccepted,
}: NatRulesAddFormContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const [createVmNat, { error: vmError, isLoading: isCreatingVm }] =
    useCreateVmNatRuleMutation();
  const { topologyLimit } =
    topologyLimitsApi.endpoints.getTopologyLimits.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        topologyLimit: data?.natRules,
      }),
    });

  const usedInternalIps = internalIps ? buildInternalIps(internalIps) : [];
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
    watch,
  } = useForm<NatRuleFormData>({
    defaultValues: {
      eastWest: false,
      ipAddress: '',
      name: '',
      targetItem: { uid: '' },
      type: '',
    },
    mode: 'all',
    resolver: yupResolver(natRulesSchema(usedInternalIps)),
  });
  const [vmIpAddressOptions, setVmIpAddressOptions] = useState<
    SelectOption<string>[]
  >([PLEASE_SELECT_OPTION]);
  const [createIpNat, { error: ipError, isLoading: isCreatingIp }] =
    useCreateIpNatRuleMutation();

  const error = ipError || vmError;
  const isCreating = isCreatingIp || isCreatingVm;
  const natType = watch('type');

  const handleCreate: SubmitHandler<NatRuleFormData> = async (formData) => {
    const natRuleTargetType = determineNatRuleTargetTypeFromNatRuleType(
      formData.type,
    );
    const payload = buildNatRulePayload({ formData, topologyUid });
    if (natRuleTargetType === NAT_RULE_TARGET_TYPES.ip) {
      await createIpNat(payload);
    } else if (natRuleTargetType === NAT_RULE_TARGET_TYPES.vmNic) {
      await createVmNat(payload);
    }

    !error && resetForm();
  };

  return (
    <NatRulesAddForm
      control={control}
      errors={errors}
      formState={formState}
      handleCreate={handleCreate}
      handleSubmit={handleSubmit}
      isCreating={isCreating}
      natType={natType}
      vmTargets={internalIps}
      vmIpAddressOptions={vmIpAddressOptions}
      setVmIpAddressOptions={setVmIpAddressOptions}
      resetTopologyLimits={resetTopologyLimits}
      topologyLimit={topologyLimit}
      setShowTopologyLimitWarningForEntity={
        setShowTopologyLimitWarningForEntity
      }
      topologyLimitsWarningAccepted={topologyLimitsWarningAccepted}
    />
  );
}

type NatRulesAddFormContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ConnectedNatRulesAddFormOwnProps;

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedNatRulesAddFormOwnProps,
) => ({
  internalIps: ownProps.internalIps,
  topologyLimitsWarningAccepted:
    topologyLimitsSelectors.getTopologyLimitsWarningAccepted(state),
});

const mapDispatchToProps = {
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
};

export const ConnectedNatRulesAddForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NatRulesAddFormContainer);
