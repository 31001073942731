import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TableLoader } from '../../../../../../components/Table/TableLoader';
import { useGetExternalDnsItemQuery } from '../../../../../../redux/configuration/traffic/external-dns/api';
import { EditExternalDnsForm } from './Form';

function EditExternalDnsContainer(): ReactElement {
  const { externalDnsUid } = useParams<{
    externalDnsUid: string;
  }>();

  const { data: externalDnsRecord, isLoading } =
    useGetExternalDnsItemQuery(externalDnsUid);
  if (!externalDnsRecord || isLoading) {
    return <TableLoader />;
  }

  return (
    <section>
      <EditExternalDnsForm
        externalDnsRecord={externalDnsRecord}
        isUpdating={false}
        isSuccess={false}
      />
    </section>
  );
}

export interface EditExternalDnsRTKProps {
  externalDnsRecord: ExternalDnsRecord;
  isUpdating: boolean;
  isSuccess: boolean;
}

export type EditExternalDnsProps = EditExternalDnsRTKProps;

export const ConnectEditExternalDns = connect()(EditExternalDnsContainer);
