import React, { ReactElement } from 'react';
import { useTheme } from 'dcloud-shared-ui';
import { determineColorForConnection } from '../../utils';
import { NODE_CONNECTION_DEFAULT_PADDING } from '../../../../constants';
import { logicalConnectionLineDefaults } from '../../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLogicalConnection/constants';

export type LogicalConnectionProps = {
  connection: DiagramConnection;
};

export const LogicalConnection = ({
  connection,
}: LogicalConnectionProps): ReactElement => {
  const [theme] = useTheme();

  const connectionType = 'LOGICAL';

  return (
    <line
      stroke={determineColorForConnection(
        connection.display?.connection?.color,
        theme,
        connection.uid,
      )}
      strokeWidth={logicalConnectionLineDefaults.strokeWidth}
      data-uid={connection.uid}
      data-component={connection.uid}
      data-connection-from={connection.nodes.from.uid}
      data-connection-to={connection.nodes.to.uid}
      data-connection-order={connection.display?.connection?.order}
      data-connection-derived-order={connection.derived?.order}
      data-connection-padding={
        connection.display?.connection?.padding ||
        NODE_CONNECTION_DEFAULT_PADDING
      }
      data-connection-total={connection.derived?.total}
      data-connection-from-to={connection.derived?.fromTo}
      data-connection-type={connectionType}
      data-connection-stroke-type={
        connection.display?.connection?.strokeType ||
        logicalConnectionLineDefaults.strokeType
      }
    ></line>
  );
};
