/* eslint @typescript-eslint/no-explicit-any: 0 */
import { Textarea } from 'dcloud-shared-ui';
import React, { ChangeEvent, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { ControlledFieldProps } from '../types';
import { ControlledTextareaSkeleton } from './ControlledTextareaSkeleton';

export interface ControlledTextareaProps<T extends Record<string, any>>
  extends ControlledFieldProps<T> {
  rows?: number;
  id?: string;
  customOnChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function ControlledTextarea<T extends Record<string, any>>({
  control,
  defaultValue = '',
  error,
  label,
  labelClass,
  loading = false,
  name,
  placeholder = '',
  required = false,
  rows,
  testId,
  id,
  customOnChange,
}: ControlledTextareaProps<T>): ReactElement {
  if (loading) {
    return <ControlledTextareaSkeleton />;
  }

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ name: fieldName, onChange, onBlur, value }) => (
        <Textarea
          error={error}
          rows={rows}
          label={label}
          horizontal={false}
          labelClass={labelClass}
          name={fieldName}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            customOnChange && customOnChange(e);
            onChange(e.target.value);
          }}
          onBlur={onBlur}
          value={value}
          required={required}
          data-testid={testId}
          id={id}
          placeholder={placeholder}
        />
      )}
    />
  );
}
