import { useCallback, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { x, y, domain } from '../d3';

type GSelection = d3.Selection<SVGGElement, DiagramNode, HTMLElement, unknown>;

export function useGrid(): VoidFunction {
  const debugMode = useRef(false);
  const xAxis = useRef(d3.axisBottom(x));
  const yAxis = useRef(d3.axisRight(y));
  const gx = useRef<GSelection | undefined>(undefined);
  const gy = useRef<GSelection | undefined>(undefined);

  useEffect(() => {
    const grid = d3.select<SVGSVGElement, DiagramNode>('#grid');

    gx.current = grid
      .append('g')
      .attr('stroke-opacity', 0.1)
      .call(xAxis.current);

    gy.current = grid
      .append('g')
      .attr('stroke-opacity', 0.1)
      .call(yAxis.current);
  }, []);

  const drawBasedOnZoom = useCallback((transform: d3.ZoomTransform): void => {
    const zx = transform.rescaleX(x).interpolate(d3.interpolateRound);
    const zy = transform.rescaleY(y).interpolate(d3.interpolateRound);

    gx.current!.call(xAxis.current.scale(zx));
    gy.current!.call(yAxis.current.scale(zy));
  }, []);

  const draw = useCallback(() => {
    const canvasNode = d3.select<HTMLDivElement, unknown>('#canvas').node()!;
    const transform = d3.zoomTransform(canvasNode);
    const { width, height } = canvasNode.getBoundingClientRect();

    xAxis.current
      .ticks(domain.width)
      .tickSize(height)
      .tickPadding(-height)
      .tickFormat();

    yAxis.current
      .ticks(domain.height)
      .tickSize(width)
      .tickPadding(8 - width);

    if (!debugMode.current) {
      xAxis.current.tickFormat(() => '');
      yAxis.current.tickFormat(() => '');
    }

    drawBasedOnZoom(transform);
  }, [drawBasedOnZoom]);

  return draw;
}
