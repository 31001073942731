import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../components';
import { SaasIntegrationDeleteModalProps } from './container';

export function SaasIntegrationDeleteModal({
  setInventorySaasTemplateToDelete,
  saasIntegrationtoDelete,
  isDeleting,
  isSuccess,
  isError,
  deleteSaasIntegration,
}: SaasIntegrationDeleteModalProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess || isError) {
      setInventorySaasTemplateToDelete(undefined);
    }
  }, [isSuccess, isError, setInventorySaasTemplateToDelete]);

  return (
    <ConfirmModal
      clickScreenToClose={true}
      heading={t('saas.delete.confirm.heading').replace(
        ':saasIntegration',
        saasIntegrationtoDelete?.name || '',
      )}
      subHeading={t('saas.delete.confirm.subheading')}
      loading={isDeleting}
      onCancel={() => setInventorySaasTemplateToDelete(undefined)}
      onClose={() => setInventorySaasTemplateToDelete(undefined)}
      onConfirm={() => deleteSaasIntegration(saasIntegrationtoDelete!.uid)}
      confirmButtonLabel={t('buttons.delete')}
      show={!!saasIntegrationtoDelete}
      size="default"
      testId="saas-integration-delete-modal"
    />
  );
}
