import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import { IconButton } from 'dcloud-shared-ui';
import styles from './RotateIcon.module.css';

export type RotateIconProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function RotateIcon(rest: RotateIconProps): ReactElement {
  return (
    <IconButton size="large" icon="refresh" className={styles.root} {...rest} />
  );
}
