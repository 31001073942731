import { useEffect, useState } from 'react';
import { filterItems } from './utilities';

interface UseFilter<T extends Record<string, unknown>> {
  filteredItems: T[];
  handleSearchChange: (value: string) => void;
  searchValue: string;
}

export interface UseFilterProps<T extends Record<string, unknown>> {
  charCountTrigger?: number;
  filterKeys: (keyof T)[];
  items: T[];
}

export function useFilter<T extends Record<string, unknown>>({
  charCountTrigger = 3,
  filterKeys,
  items,
}: UseFilterProps<T>): UseFilter<T> {
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  function handleSearchChange(newSearchValue: string): void {
    setSearchValue(newSearchValue);

    if (newSearchValue.length === 0) {
      setFilteredItems(items);
    } else if (newSearchValue.length >= charCountTrigger) {
      const searchText = newSearchValue.toLowerCase();
      const result = filterItems({ filterKeys, list: items, searchText });
      setFilteredItems(result);
    }
  }

  return { filteredItems, handleSearchChange, searchValue };
}
