import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface CurrentUserState {
  userRoles?: UserRole[];
}

export const initialState: CurrentUserState = {
  userRoles: undefined,
};

const slice = createSlice({
  initialState,
  name: 'currentUser',
  reducers: {
    setCurrentUserRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.userRoles = action.payload;
    },
  },
});

export const getCurrentUserState = (state: RootState): CurrentUserState =>
  state[slice.name];

export const currentUserSelectors = {
  CurrentUserRoles: createSelector(
    getCurrentUserState,
    ({ userRoles }) => userRoles,
  ),
};

export const { reducer: CurrentUserReducer } = slice;
export const { setCurrentUserRoles } = slice.actions;
