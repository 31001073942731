import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../../../../constants';
import i18n from '../../../../../i18n';

export const addConfiguredQuantityToSaasTemplatesAndFilterByType = (
  saasTemplates: SaasTemplate[],
  userSaasIntegrations: SaasIntegration[],
  integrationType: IntegrationType,
): SaasTemplateWithConfiguredQuantity[] => {
  return saasTemplates
    .filter((saasTemplate) => saasTemplate.type === integrationType)
    .map((saasTemplate) => ({
      ...saasTemplate,
      configuredQuantity: userSaasIntegrations.reduce(
        (total, userSaasIntegration) =>
          userSaasIntegration.saasTemplate.uid == saasTemplate.uid
            ? ++total
            : total,
        0,
      ),
    }));
};

export const getInventoryTemplatesAlertText = (
  integrationType: IntegrationType,
): string => {
  switch (integrationType) {
    case SAAS_INTEGRATION_TYPE:
      return i18n.t('saas.add.noItemsAlert');
    case PUBLIC_CLOUD_INTEGRATION_TYPE:
      return i18n.t('saas.add.noItemsAlertCloud');
    default:
      return i18n.t('saas.add.noItemsAlert');
  }
};

export const determineAddPageTitle = (
  integrationType: IntegrationType,
): string => {
  switch (integrationType) {
    case SAAS_INTEGRATION_TYPE:
      return i18n.t('saas.add.headings.saas');
    case PUBLIC_CLOUD_INTEGRATION_TYPE:
      return i18n.t('saas.add.headings.publicCloud');
    default:
      return i18n.t('saas.add.headings.saas');
  }
};
