import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { CustomLabelPicker } from '../../../../components/Labels/CustomLabels/CustomLabelPicker';
import { CustomLabelGroup } from '../../../../components/Labels/CustomLabels/CustomLabelGroup';
import { computeAvailableLicenses } from '../../../CreateIntegration/components/LicenseForm/utils/utils';

export type LicensesFormProps = {
  integrationToUpdate?: IntegrationToUpdate;
  labels: CustomLabel[];
  addLabel: (label: CustomLabel) => void;
  removeLabel: (label: CustomLabel) => void;
  setFetchedLabelsToEdit: (labels: CustomLabel[]) => void;
  updateLabels: (labels: CustomLabel[]) => void;
  selectedLabels: CustomLabel[];
};

export default function LicenseForm({
  labels,
  integrationToUpdate,
  addLabel,
  removeLabel,
  selectedLabels,
  setFetchedLabelsToEdit,
  updateLabels,
}: LicensesFormProps): ReactElement {
  useEffect(() => {
    if (
      !isEqual(selectedLabels, integrationToUpdate?.fetchedIntegrationLicenses)
    ) {
      updateLabels(selectedLabels);
      setFetchedLabelsToEdit(selectedLabels);
    }
  }, [
    selectedLabels,
    integrationToUpdate,
    updateLabels,
    setFetchedLabelsToEdit,
  ]);
  const { t } = useTranslation();
  return (
    <>
      <div className="half-margin">
        {t('integrations.forms.common.licenses.available')}
      </div>
      <div className="half-margin">
        <CustomLabelPicker
          labels={computeAvailableLicenses(
            labels,
            integrationToUpdate?.integrationLicenses || [],
          )}
          onAdd={addLabel}
        />
      </div>
      <div className="half-margin">
        {t('integrations.forms.common.licenses.selected')}
      </div>
      <div className="half-margin">
        <CustomLabelGroup
          labels={integrationToUpdate?.integrationLicenses || []}
          onDismiss={removeLabel}
        />
      </div>
    </>
  );
}
