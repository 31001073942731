import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useSkeletonColours } from '../../../hooks/use-skeleton-colours';

export function CreateIntegrationSkeleton(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();
  return (
    <section className="dbl-padding">
      <ContentLoader
        className="margin-top"
        speed={1.5}
        width="100%"
        height={450}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" rx="3" ry="3" width="490" height="50" />
        <rect x="510" y="0" rx="3" ry="3" width="490" height="50" />
        <rect x="0" y="100" rx="3" ry="3" width="1000" height="80" />
        <rect x="0" y="200" rx="3" ry="3" width="1000" height="150" />
      </ContentLoader>
    </section>
  );
}
