import * as d3 from 'd3';
import { RefObject, useEffect } from 'react';
import { useUpdateNodeMutation } from '../../../../../redux/diagram/api';
import { x, y } from '../../../d3';
import { updateIconNode } from '../utils/utils';
import { calculateNewX1andY1 } from '../../../utils';
import { UseConnection } from '../../Connections/hooks/use-connection';

interface UseIconDrag extends BaseNodeProps {
  nodeRef: RefObject<HTMLDivElement>;
  defaultPosition: DiagramDisplay;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
  isAddingLogicalConnection: boolean;
}

export function useIconDrag({
  defaultPosition,
  display,
  isReadOnly,
  nodeRef,
  uid,
  redrawNodeConnections,
  isSnapToGridEnabled = true,
  isAddingLogicalConnection,
}: UseIconDrag): void {
  const [updateNode] = useUpdateNodeMutation();

  useEffect(() => {
    if (isReadOnly || isAddingLogicalConnection) return;

    const currentRef = nodeRef.current;

    const drag = d3
      .drag<HTMLDivElement, DiagramDisplay>()
      .subject(function (_, d) {
        const transform = d3.zoomTransform(this);

        return {
          x: transform.rescaleX(x)(
            d?.x1 !== undefined ? d?.x1 : defaultPosition.x1!,
          ),
          y: transform.rescaleY(y)(
            d?.y1 !== undefined ? d?.y1 : defaultPosition.y1!,
          ),
        };
      })
      .on('drag', function (event) {
        const component = d3.select(this);
        const transform = d3.zoomTransform(this);

        component.attr(
          'style',
          `transform: translate(${event.x}px, ${event.y}px) scale(${transform.k}); z-index: 1`,
        );

        redrawNodeConnections && redrawNodeConnections(uid);
      })
      .on('end', function (event) {
        const transform = d3.zoomTransform(this);

        const { x1, y1 } = calculateNewX1andY1({
          display,
          event,
          isSnapToGridEnabled,
          transform,
          x,
          y,
        });

        updateIconNode({
          display,
          uid,
          updateNode,
          x1,
          y1,
        });

        redrawNodeConnections && redrawNodeConnections(uid);

        if (currentRef) {
          currentRef.style.zIndex = '1';
        }
      });

    if (currentRef) {
      d3.select<HTMLDivElement, DiagramDisplay>(currentRef)
        .datum(display || ({} as DiagramDisplay))
        .call(drag);
    }

    return () => {
      if (currentRef) {
        d3.select<HTMLDivElement, DiagramDisplay>(currentRef).on(
          'mousedown.drag',
          null,
        );
      }
    };
  }, [
    defaultPosition.x1,
    defaultPosition.y1,
    display,
    isAddingLogicalConnection,
    isReadOnly,
    isSnapToGridEnabled,
    nodeRef,
    redrawNodeConnections,
    uid,
    updateNode,
  ]);
}
