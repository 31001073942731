import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { Table } from '../../../../../../components';
import { CreateTopologyRoutes } from '../../../../constants';
import { MainRoutes } from '../../../../../../constants/routes';
import { ArchitectureLabelGroup } from '../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { Search } from '../../../../../../components/Search';
import { SEARCH_MIN_CHARS } from '../../../../../EditTopology/configuration/constants';
import { filterItems } from '../../../../../EditTopology/configuration/searchUtils';

export interface TopologyTemplateTableProps {
  demos: InventoryTopology[];
  isLoading: boolean;
  setTemplateToImport: (template: InventoryTopology) => void;
  setShowMoreInfoForInventoryTemplate: (template: InventoryTopology) => void;
}

export const DEMOS_TABLE_ROW_LIMIT = 15;

export function ImportTemplatesTable({
  demos,
  isLoading,
  setTemplateToImport,
}: TopologyTemplateTableProps): ReactElement {
  const { t } = useTranslation();
  const columns = useMemo<Column<InventoryTopology>[]>(
    () => [
      {
        Cell: ({ row, value }: CellProps<InventoryTopology>) => (
          <Link
            to={`${MainRoutes.CreateTopology}${CreateTopologyRoutes.Details}`}
            onClick={() => {
              setTemplateToImport(row.original);
            }}
          >
            {value}
          </Link>
        ),
        Header: () => t('createTopology.demo.import.demos.name'),
        accessor: 'name',
      },
      {
        Header: () => t('createTopology.demo.template.description'),
        accessor: 'description',
      },
      {
        Header: () => t('createTopology.demo.template.demoId'),
        accessor: 'demoId',
      },
      {
        Cell: ({ value }: CellProps<InventoryTopology>) =>
          value ? <ArchitectureLabelGroup architectures={value} /> : null,
        Header: () => t('inventoryVirtualMachines.table.headings.architecture'),
        accessor: 'architectureNames',
      },
    ],
    [setTemplateToImport, t],
  );

  const [filteredItems, setFilteredItems] =
    useState<InventoryTopology[]>(demos);
  const [searchValue, setSearchValue] = useState<string>('');
  const filterKeys = ['name', 'description', 'demoId', 'architectureNames'];

  function handleSearchChange(newSearchValue: string): void {
    setSearchValue(newSearchValue);
    if (newSearchValue.length === 0) {
      setFilteredItems(demos);
    } else if (newSearchValue.length >= SEARCH_MIN_CHARS) {
      const searchText = newSearchValue.toLowerCase();
      const result = filterItems({ filterKeys, list: demos, searchText });
      setFilteredItems(result);
    }
  }

  return (
    <>
      <div className="row flex-right">
        <Search
          className="base-padding"
          id="demo-import-search"
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder={t('common.search')}
          value={searchValue}
        />
      </div>
      <Table<InventoryTopology>
        ariaLabel="Import Templates table"
        columns={columns}
        loading={isLoading}
        data={filteredItems}
        initialState={{
          pageSize: DEMOS_TABLE_ROW_LIMIT,
          sortBy: [{ desc: false, id: 'name' }],
        }}
      />
    </>
  );
}
