import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const getValidAdaptersHelper = (
  networkingToEdit: VmNetworkInterfacesToEdit | undefined,
): VMRemoteAccessValidAdapter[] => {
  return networkingToEdit
    ? networkingToEdit?.reduce(function (
        adapters: VMRemoteAccessValidAdapter[],
        vmNetworkInterface,
      ) {
        vmNetworkInterface.ipAddress &&
          adapters.push({
            ipAddress: vmNetworkInterface.ipAddress,
            uid: vmNetworkInterface.uid as string,
          });
        return adapters;
      },
      [])
    : [];
};

interface VirtualMachineEntitiesState {
  selectedTopologyUid?: string;
  virtualMachineToConfigure?: InventoryVirtualMachine;
  demoVirtualMachineToConfigure?: VirtualMachine;
  virtualMachineToDelete?: VirtualMachine;
  virtualMachineToEdit?: VirtualMachine;
  networkingToEdit?: VmNetworkInterfacesToEdit;
  nicToEdit?: VmNetworkInterfaceToEdit;
  interfaceToConfirmDelete?: VMNetworkInterface;
  showMoreInfoForVmTemplate?: InventoryVirtualMachine;
  showMoreInfoForInventoryTopology?: InventoryTopology;
  showRemoteAccessCountExceededModal: boolean;
}

export const initialState: VirtualMachineEntitiesState = {
  demoVirtualMachineToConfigure: undefined,
  interfaceToConfirmDelete: undefined,
  networkingToEdit: [],
  nicToEdit: undefined,
  selectedTopologyUid: undefined,
  showMoreInfoForInventoryTopology: undefined,
  showMoreInfoForVmTemplate: undefined,
  showRemoteAccessCountExceededModal: false,
  virtualMachineToConfigure: undefined,
  virtualMachineToDelete: undefined,
  virtualMachineToEdit: undefined,
};

const slice = createSlice({
  initialState,
  name: 'virtualMachineEntities',
  reducers: {
    addVMNetworkInterface: (
      state,
      action: PayloadAction<VmNetworkInterfaceToEdit>,
    ) => {
      if (state.networkingToEdit) {
        state.networkingToEdit = [
          ...state.networkingToEdit,
          {
            ...action.payload,
          },
        ];
      } else {
        state.networkingToEdit = [action.payload];
      }
    },
    clearVirtualMachines: (state) => {
      state.virtualMachineToConfigure = undefined;
      state.selectedTopologyUid = undefined;
      state.demoVirtualMachineToConfigure = undefined;
    },
    deleteVMNetworkInterface: (
      state,
      action: PayloadAction<VmNetworkInterfaceToEdit>,
    ) => {
      if (state.networkingToEdit) {
        state.networkingToEdit = state.networkingToEdit.filter(
          (item) => item.uid !== action.payload.uid,
        );
      }
      if (state.interfaceToConfirmDelete) {
        state.interfaceToConfirmDelete = undefined;
      }
    },
    setAutoLogin: (state, action: PayloadAction<boolean>) => {
      state.networkingToEdit = state.networkingToEdit?.map((item) =>
        item.rdp?.enabled
          ? {
              ...item,
              rdp: { ...item.rdp, autoLogin: action.payload },
            }
          : { ...item },
      );
    },
    setDemoVirtualMachineToConfigure: (
      state,
      action: PayloadAction<VirtualMachine | undefined>,
    ) => {
      state.demoVirtualMachineToConfigure = action.payload;
    },
    setInterfaceToConfirmDelete: (
      state,
      action: PayloadAction<VMNetworkInterface | undefined>,
    ) => {
      state.interfaceToConfirmDelete = action.payload;
    },
    setNetworkingToEdit: (
      state,
      action: PayloadAction<VmNetworkInterfacesToEdit | undefined>,
    ) => {
      state.networkingToEdit = action.payload;
    },
    setNicToEdit: (
      state,
      action: PayloadAction<VmNetworkInterfaceToEdit | undefined>,
    ) => {
      state.nicToEdit = action.payload;
    },
    setSSHAdapter: (state, action: PayloadAction<string>) => {
      if (action.payload.length > 0) {
        state.networkingToEdit?.forEach((item) => {
          delete item.ssh;
        });
        state.networkingToEdit = state.networkingToEdit?.map((item) =>
          item.uid === action.payload
            ? {
                ...item,
                ssh: {
                  ...item.ssh,
                  enabled: true,
                },
              }
            : { ...item },
        );
      } else {
        state.networkingToEdit?.forEach((item) => {
          delete item.ssh;
        });
      }
    },
    setSelectedTopologyUid: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedTopologyUid = action.payload;
    },
    setShowMoreInfoForInventoryTopology: (
      state,
      action: PayloadAction<InventoryTopology | undefined>,
    ) => {
      state.showMoreInfoForInventoryTopology = action.payload;
    },
    setShowMoreInfoForVmTemplate: (
      state,
      action: PayloadAction<InventoryVirtualMachine | undefined>,
    ) => {
      state.showMoreInfoForVmTemplate = action.payload;
    },
    setShowRemoteAccessCountExceededModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showRemoteAccessCountExceededModal = action.payload;
    },
    setVirtualMachineToConfigure: (
      state,
      action: PayloadAction<InventoryVirtualMachine | undefined>,
    ) => {
      state.virtualMachineToConfigure = action.payload;
    },
    setVirtualMachineToDelete: (
      state,
      action: PayloadAction<VirtualMachine | undefined>,
    ) => {
      state.virtualMachineToDelete = action.payload;
    },
    setVirtualMachineToEdit: (
      state,
      action: PayloadAction<VirtualMachine | undefined>,
    ) => {
      state.virtualMachineToEdit = action.payload;
    },
    setWebRDPAdapter: (
      state,
      action: PayloadAction<VMSetRDPAdapterActionPayload>,
    ) => {
      if (action.payload.uid.length > 0) {
        state.networkingToEdit?.forEach((item) => {
          delete item.rdp;
        });
        state.networkingToEdit = state.networkingToEdit?.map((item) =>
          item.uid === action.payload.uid
            ? {
                ...item,
                rdp: {
                  ...item.rdp,
                  autoLogin: action.payload.isAutoLoginChecked,
                  enabled: true,
                },
              }
            : { ...item },
        );
      } else {
        state.networkingToEdit?.forEach((item) => {
          delete item.rdp;
        });
      }
    },
    updateVMNetworkInterface: (
      state,
      action: PayloadAction<VmNetworkInterfaceToEdit>,
    ) => {
      if (state.networkingToEdit) {
        const updated = state.networkingToEdit.map((item) => {
          if (item.uid !== action.payload.uid) {
            return item;
          }

          return {
            ...item,
            ...action.payload,
          };
        });
        state.networkingToEdit = updated;
      } else {
        state.networkingToEdit = [action.payload];
      }
    },
  },
});

export const getVirtualMachines = (
  state: RootState,
): VirtualMachineEntitiesState => state.configuration[slice.name];

export const { reducer: virtualMachineEntitiesReducer } = slice;
export const {
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  clearVirtualMachines,
  setInterfaceToConfirmDelete,
  setVirtualMachineToConfigure,
  setVirtualMachineToEdit,
  setVirtualMachineToDelete,
  setShowMoreInfoForInventoryTopology,
  setShowMoreInfoForVmTemplate,
  setNetworkingToEdit,
  setShowRemoteAccessCountExceededModal,
  setNicToEdit,
  setWebRDPAdapter,
  setSelectedTopologyUid,
  setSSHAdapter,
  setAutoLogin,
  updateVMNetworkInterface,
  setDemoVirtualMachineToConfigure,
} = slice.actions;

export const virtualMachinesSelectors = {
  getDemoVirtualMachineToConfigure: createSelector(
    getVirtualMachines,
    ({ demoVirtualMachineToConfigure }) => demoVirtualMachineToConfigure,
  ),
  getSelectedTopologyUid: createSelector(
    getVirtualMachines,
    ({ selectedTopologyUid }) => selectedTopologyUid,
  ),
  getShowRemoteAccessCountExceededModal: createSelector(
    getVirtualMachines,
    ({ showRemoteAccessCountExceededModal }) =>
      showRemoteAccessCountExceededModal,
  ),
  getValidAdapters: createSelector(getVirtualMachines, ({ networkingToEdit }) =>
    getValidAdaptersHelper(networkingToEdit),
  ),
  getVirtualMachineToConfigure: createSelector(
    getVirtualMachines,
    ({ virtualMachineToConfigure }) => virtualMachineToConfigure,
  ),
};
