import React, { ReactElement } from 'react';
import { ConfigurationEditTopologyWarning } from './components/ConfigurationEditTopologyWarning';

export type ConfigurationEditTopologyWarningsProps = {
  warnings?: TopologyWarning[];
};

export const ConfigurationEditTopologyWarnings = ({
  warnings,
}: ConfigurationEditTopologyWarningsProps): ReactElement => {
  if (!warnings) return <></>;

  return (
    <>
      {warnings.map((warning) => (
        <ConfigurationEditTopologyWarning warning={warning} key={warning.uid} />
      ))}
    </>
  );
};
