import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface AccessRoleAssignmentsState {
  accessRoleAssignmentToDelete?: AccessRoleAssignment;
}

export const initialState: AccessRoleAssignmentsState = {
  accessRoleAssignmentToDelete: undefined,
};

const slice = createSlice({
  initialState,
  name: 'accessRoleAssignments',
  reducers: {
    setAccessRoleAssignmentToDelete: (
      state,
      action: PayloadAction<AccessRoleAssignment | undefined>,
    ) => {
      state.accessRoleAssignmentToDelete = action.payload;
    },
  },
});

export const getAccessRoleAssignments = (
  state: RootState,
): AccessRoleAssignmentsState => state.configuration[slice.name];

export const { reducer: accessRoleAssignmentsReducer } = slice;
export const { setAccessRoleAssignmentToDelete } = slice.actions;
