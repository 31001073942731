import React, { KeyboardEvent, ReactElement, useRef } from 'react';
import * as d3 from 'd3';
import classnames from 'classnames';
import { useDiagramContext } from '..';
import { Node } from '../Node';
import { useDeleteNodeMutation } from '../../../../redux/diagram/api';
import { usePosition } from './hooks/use-position';
import { useDrag } from './hooks/use-drag';
import styles from './TwoHandleLineNode.module.css';
import { useResize } from './hooks/use-resize';
import { TwoHandleLineNodeProps } from './container';

export function TwoHandleLineNode({
  className,
  display,
  isReadOnly,
  uid,
  isSnapToGridEnabled,
}: TwoHandleLineNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { toggleSelectedItem, selectedItem } = useDiagramContext();
  const isSelected = !isReadOnly && selectedItem === uid;
  const hookProps = { display, nodeRef, uid };
  const [deleteNode] = useDeleteNodeMutation();

  usePosition({ ...hookProps });
  useResize({ ...hookProps, isReadOnly, isSnapToGridEnabled });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });
  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      !isReadOnly &&
      isSelected &&
      ['Backspace', 'Delete'].includes(event.key)
    ) {
      deleteNode(uid);
      nodeRef?.current?.remove();
      d3.selectAll<HTMLElement, unknown>(
        `[data-line-component-uid="${uid}"]`,
      ).remove();
      toggleSelectedItem(uid);
    }
  };
  return (
    <Node
      uid={uid}
      ref={nodeRef}
      className={classnames(styles.root, className, {
        [styles.selected]: isSelected,
      })}
      isDisabled={display.hidden}
      isReadOnly={isReadOnly}
      isSelected={isSelected}
      data-component={uid}
      isRemovable={true}
      isDrawingType={true}
      isLineNode={true}
    >
      <div
        data-selector-component-uid={uid}
        className={classnames(styles.lineSelector, {
          [styles.hidden]: isReadOnly,
        })}
        onClick={() => toggleSelectedItem(uid)}
        onKeyUp={onKeyUp}
        tabIndex={0}
      ></div>
      <div className={styles.handles}>
        <div
          onClick={() => toggleSelectedItem(uid)}
          className={classnames(styles.handle, styles.left, {
            [styles.selected]: isSelected,
          })}
          data-handle="left"
        ></div>
        <div
          onClick={() => toggleSelectedItem(uid)}
          className={classnames(styles.handle, styles.right, {
            [styles.selected]: isSelected,
          })}
          data-handle="right"
        ></div>
      </div>
    </Node>
  );
}
