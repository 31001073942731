import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../redux/views/session-view/session/api/api';
import { retrieveVisibleNodes } from '../../utils';
import { NodesContainerProps } from './container';
import { Nodes } from '.';

type ConnectedDCloudSessionNodesProps = NodesContainerProps & FetchSessionArgs;

export function ConnectedDCloudSessionNodes({
  isReadOnly,
  isAddingBox,
  isAddingLine,
  isAddingText,
  sessionUid,
  versionUid,
  isSnapToGridEnabled,
}: ConnectedDCloudSessionNodesProps): ReactElement {
  const { data } = sessionApi.endpoints.getSession.useQueryState({
    sessionUid,
    versionUid,
  });
  return (
    <Nodes
      nodes={retrieveVisibleNodes(data!.diagram.nodes)}
      isReadOnly={isReadOnly}
      isAddingBox={isAddingBox}
      isAddingLine={isAddingLine}
      isAddingText={isAddingText}
      isSnapToGridEnabled={isSnapToGridEnabled}
      isAddingLogicalConnection={false}
    />
  );
}
