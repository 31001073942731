import { connect } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {
  sessionInventorySelectors,
  setSelectedAction,
  setHasAcceptedMultiSelectActionConfirm,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import { SessionInventoryActionsConfirmModal } from '.';

const mapDispatchToProps = {
  setHasAcceptedMultiSelectActionConfirm,
  setSelectedAction,
};

const mapStateToProps = (state: RootState) => ({
  hasAcceptedMultiSelectActionConfirm:
    sessionInventorySelectors.getHasAcceptedMultiSelectActionConfirm(state),
  selectedAction: sessionInventorySelectors.getSelectedAction(state),
});

export const SessionInventoryActionsConfirmModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionInventoryActionsConfirmModal);

export type SessionInventoryActionsConfirmModalProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;
