import { useEffect } from 'react';

export function useWindowResize(cb: VoidFunction): void {
  useEffect(() => {
    window.addEventListener('resize', cb);

    return () => {
      window.removeEventListener('resize', cb);
    };
  }, [cb]);
}
