import { connect } from 'react-redux';
import { setAuthStatus } from '../../../redux/auth/slice';
import { NotAuthorizedGenericError } from '.';

const mapDispatchToProps = {
  setAuthStatus,
};

export type NotAuthorizedGenericErrorProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(NotAuthorizedGenericError);
