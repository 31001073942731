import i18n from '../../../../../i18n';

export const prepHardwareItemsForSessionInventoryHardware = (
  sessionUid: string,
  versionUid: string,
  nodes?: DiagramNode[],
): SessionInventoryHardwareData[] => {
  if (!nodes) {
    return [];
  }

  const NO_TEXT = '--';
  const filteredNodes = nodes.filter(
    (node) => node.assetType === 'HARDWARE_ITEM',
  );
  return filteredNodes.map((node) => {
    return {
      description:
        (node as DiagramNodeHardwareItem).asset?.inventoryHardwareItem
          .description || NO_TEXT,
      deviceId: (node as DiagramNodeHardwareItem).sessionDetails?.deviceId,
      deviceType:
        (node as DiagramNodeHardwareItem).sessionDetails?.deviceType || NO_TEXT,
      hwConsole: (node as DiagramNodeHardwareItem).asset?.hardwareConsoleEnabled
        ? i18n.t('common.yes')
        : i18n.t('common.no'),
      hwConsoleHref: (node as DiagramNodeHardwareItem).sessionDetails
        ?.consoleUrl,
      interfaces:
        (node as DiagramNodeHardwareItem).asset?.inventoryHardwareItem
          ?.networkInterfaces.length || 0,
      name: (node as DiagramNodeHardwareItem).asset?.name || NO_TEXT,
      powerControl: (node as DiagramNodeHardwareItem).asset?.powerControlEnabled
        ? i18n.t('common.yes')
        : i18n.t('common.no'),
      sessionUid,
      uid: node.uid,
      versionUid,
    };
  });
};
