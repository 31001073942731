import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudSessionInformation } from './dCloudContainer';
import { ConnectedExpoSessionInformation } from './expoContainer';
import { SessionInformation } from '.';

export function SessionInformationContainer(): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoSessionInformation
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudSessionInformation
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type SessionInformationProps = {
  sessionInformation?: SessionInformation;
  isLoading: boolean;
  isError: boolean;
};
