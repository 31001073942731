import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

interface MailServersState {
  selectedVM?: string;
  mailServerToDelete?: MailServer;
  vmNicTargets?: VmNicTarget[];
}

export const initialState: MailServersState = {
  mailServerToDelete: undefined,
};

const slice = createSlice({
  initialState,
  name: 'mailServers',
  reducers: {
    setMailServerToDelete: (
      state,
      action: PayloadAction<MailServer | undefined>,
    ) => {
      state.mailServerToDelete = action.payload;
    },
  },
});

export const getMailServers = (state: RootState): MailServersState =>
  state.configuration[slice.name];

export const { reducer: mailServersReducer } = slice;
export const { setMailServerToDelete } = slice.actions;
