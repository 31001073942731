import { Alert, Heading } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionsButtonGroup,
  ActionsButtonGroupProps,
} from '../../../../ActionsButtonGroup';
import { HwControlsProps } from '../HWControls';

export interface HWConsoleProps {
  hardwareConsoleButtonActions: ActionsButtonGroupProps['actions'];
  consoleHref: HwControlsProps['consoleHref'];
}

export function HWConsole({
  consoleHref,
  hardwareConsoleButtonActions,
}: HWConsoleProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Heading level="h2" size="18" align="left">
        {t('sessions.panels.hardware.controls.hardwareConsole')}
      </Heading>
      {consoleHref ? (
        <div className="dbl-padding-bottom">
          <ActionsButtonGroup actions={hardwareConsoleButtonActions} />
        </div>
      ) : (
        <Alert>
          {t('sessions.panels.hardware.controls.consoleNotAvailable')}
        </Alert>
      )}
    </>
  );
}
