import React, { ReactElement } from 'react';
import { ControlledTextFieldSkeleton } from '../../../../../../components';

export function DocumentationFormSkeleton(): ReactElement {
  return (
    <>
      <ControlledTextFieldSkeleton />
      <ControlledTextFieldSkeleton />
    </>
  );
}
