import React, { ReactElement } from 'react';
import { Label } from 'dcloud-shared-ui';
import styles from './ArchitectureLabel.module.css';

export type ArchitectureLabelProps = {
  architecture: Architecture | string;
  onDismiss?: VoidFunction;
  onAdd?: VoidFunction;
  hideMissing?: boolean;
};

function isArchitecture(
  architecture: Architecture | Architecture['name'],
): architecture is Architecture {
  return (architecture as Architecture).uid !== undefined;
}

export const ArchitectureLabel = ({
  architecture,
  onDismiss,
  onAdd,
  hideMissing = false,
}: ArchitectureLabelProps): ReactElement => {
  if (!isArchitecture(architecture)) {
    return hideMissing && architecture === '' ? (
      <></>
    ) : (
      <Label
        className={styles.label}
        size="small"
        colour={architecture !== '' ? 'dark' : 'danger'}
      >
        {architecture !== '' ? architecture : 'Missing'}
      </Label>
    );
  }

  return hideMissing && architecture.name === undefined ? (
    <></>
  ) : (
    <Label
      className={styles.label}
      size="small"
      colour={architecture.name ? 'dark' : 'danger'}
    >
      {onDismiss !== undefined ? (
        <>
          <span>{architecture.name || 'Missing'}</span>
          <span onClick={onDismiss} className="icon-close"></span>
        </>
      ) : architecture.name === undefined ? (
        'Missing'
      ) : onAdd !== undefined ? (
        <span onClick={onAdd}>{architecture.name}</span>
      ) : (
        architecture.name
      )}
    </Label>
  );
};
