import { SEARCH_MIN_CHARS } from '../../pages/EditTopology/configuration/constants';

export const searchAndFilterIntegrations = (
  integrations: Integration[],
  searchText: string,
  filterByTypes: IntegrationType[],
): Integration[] => {
  const integrationsByType = integrations.filter((integration) =>
    filterByTypes.includes(integration.type),
  );

  if (searchText.length < SEARCH_MIN_CHARS) {
    return integrationsByType;
  }

  const searchTextLowerCase = searchText.toLocaleLowerCase();

  return integrationsByType.filter(
    (integration) =>
      integration.description
        .toLocaleLowerCase()
        .indexOf(searchTextLowerCase) !== -1 ||
      integration.createdBy.toLocaleLowerCase().indexOf(searchTextLowerCase) !==
        -1 ||
      integration.name.toLocaleLowerCase().indexOf(searchTextLowerCase) !==
        -1 ||
      (integration.tags &&
        integration.tags.some(
          (tag) => tag.name.toLowerCase().indexOf(searchTextLowerCase) !== -1,
        )),
  );
};
