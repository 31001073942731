import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredSpinner } from '../../../../../components';

export function ConfigureDeviceFromDiagramLoader(): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <CenteredSpinner />
      <p className="text-center dbl-padding">
        {t('diagram.modals.configureDevice.loading.text')}
      </p>
    </>
  );
}
