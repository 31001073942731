import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setShowExtendSessionModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { ExtendSessionModal } from '.';

const mapStateToProps = (state: RootState) => ({
  showExtendSessionModal: sessionViewSelectors.getShowExtendSessionModal(state),
});

const mapDispatchToProps = {
  setShowExtendSessionModal,
};

export type ExtendSessionModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ExtendSessionModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExtendSessionModal);
