import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';
import { DeleteNetworkModalProps } from './container';

export function DeleteNetworkModal({
  deleteNetwork,
  network,
  setNetworkToDelete,
  isDeleting,
}: DeleteNetworkModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      heading={t('networks.delete.heading').replace(
        ':network',
        network?.name as string,
      )}
      highlightedSelection={network?.name}
      subHeading={t('networks.delete.subHeading')}
      loading={isDeleting}
      onCancel={() => setNetworkToDelete(undefined)}
      onClose={() => setNetworkToDelete(undefined)}
      onConfirm={() => deleteNetwork(network as Network)}
      confirmButtonLabel={t('buttons.delete')}
      show={!!network}
      size="small"
      testId="network-delete-modal"
    />
  );
}
