import { filterItems } from '../../hooks/use-filter/utilities';
import { availableShowFilters, SEARCH_TEXT_MIN_LENGTH } from './constants';

export const filterTopologies = (
  topologies: Topology[],
  showFilters: string[],
  dataCenterFilters: string[],
  searchText: string,
): Topology[] => {
  const filtersResult = filterByShowFilters(topologies, showFilters).filter(
    (topology) => dataCenterFilters.includes(topology.datacenter),
  );

  if (searchText.length >= SEARCH_TEXT_MIN_LENGTH) {
    return filterItems({
      filterKeys: ['name', 'description', 'datacenter', 'demoId', 'owner'],
      list: filtersResult,
      searchText: searchText.toLowerCase(),
    });
  }

  return filtersResult;
};

export const filterByShowFilters = (
  topologies: Topology[],
  showFilters: string[],
): Topology[] => {
  let newFilteredTopologies: Topology[] = [];
  const unselectedShowFilters = determineUnCheckedShowFilters(
    showFilters,
    availableShowFilters,
  );

  if (showFilters.includes('all')) {
    newFilteredTopologies = topologies.filter((topology) => {
      const checkForSelectedFiltersResult = checkForSelectedFilters(
        topology,
        showFilters,
      );
      const checkHiddenFiltersResult = checkHiddenFilters(
        topology,
        unselectedShowFilters,
      );
      return checkForSelectedFiltersResult || !checkHiddenFiltersResult;
    });
  } else {
    newFilteredTopologies = topologies.filter((topology) => {
      return showFilters.some((showFilter) => {
        return (
          (topology[
            showFilter as keyof TopologyGeneralFields
          ] as unknown as boolean) &&
          (topology[
            showFilter as keyof TopologyGeneralFields
          ] as unknown as boolean) === true
        );
      });
    });
  }

  return newFilteredTopologies;
};

export const checkForSelectedFilters = (
  topology: Topology,
  filters: string[],
): boolean =>
  filters.some((filter) => {
    return (
      (topology[filter as keyof TopologyGeneralFields] as unknown as boolean) &&
      (topology[
        filter as keyof TopologyGeneralFields
      ] as unknown as boolean) === true
    );
  });

export const checkHiddenFilters = (
  topology: Topology,
  filters: string[],
): boolean =>
  filters.some((filter) => {
    return (
      (topology[filter as keyof TopologyGeneralFields] as unknown as boolean) &&
      (topology[
        filter as keyof TopologyGeneralFields
      ] as unknown as boolean) !== false
    );
  });

export const determineUnCheckedShowFilters = (
  showFilters: string[],
  availableShowFilters: string[],
): string[] => {
  return availableShowFilters.filter(
    (availableShowFilter) => !showFilters.includes(availableShowFilter),
  );
};

export const isCalculateFiltersRequiredCheck = (
  type: string,
  searchValue: string,
): boolean => {
  return (
    type !== 'topologiesFilters/setSearchValue' ||
    (type === 'topologiesFilters/setSearchValue' &&
      searchValue.length >= SEARCH_TEXT_MIN_LENGTH) ||
    (type === 'topologiesFilters/setSearchValue' && searchValue.length === 0)
  );
};
