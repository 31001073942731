import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetCount } from '../AssetCount';
import { Search } from '../../../../../components/Search';
import { MultiActionProgressBarContainer } from '../MultiActionProgressBar/container';
import { MultiActionFailureAlertContainer } from '../MultiActionFailureAlert/container';
import { SessionInventoryHardwareTable } from './components/SessionInventoryHardwareTable';
import { SessionInventoryHwContentProps } from './container';

export const SessionInventoryHardware = ({
  hardware,
  setSearchHwValue,
  searchHwValue,
  setAllHwItems,
  allVisibleHwItems,
}: SessionInventoryHwContentProps): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    setAllHwItems(hardware);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllHwItems]);

  return (
    <>
      <div className="row flex-left">
        <Search
          className="base-padding-right base-padding-bottom"
          id="session-inventory-search"
          onChange={(e) => setSearchHwValue(e.target.value)}
          placeholder={t('common.search')}
          value={searchHwValue || ''}
        />
      </div>

      <div className="half-margin-top dbl-margin-bottom">
        <AssetCount
          filteredCount={allVisibleHwItems?.length || 0}
          totalCount={hardware.length}
        />
      </div>

      <div className="base-margin-top">
        <MultiActionProgressBarContainer page="hardware" />
      </div>

      <SessionInventoryHardwareTable hardware={allVisibleHwItems} />
      <MultiActionFailureAlertContainer page="hardware" />
    </>
  );
};
