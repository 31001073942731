import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import {
  ActionsButtonGroup,
  ActionsButtonGroupProps,
} from '../../ActionsButtonGroup';

type PanelHeaderButtonsProps = {
  refreshOnClick?: VoidFunction;
  closeOnClick: VoidFunction;
};
export function PanelHeaderButtons({
  refreshOnClick,
  closeOnClick,
}: PanelHeaderButtonsProps): ReactElement {
  const buttonActions: ActionsButtonGroupProps['actions'] = [
    {
      content: <Icon name="close" />,
      onClick: closeOnClick,
    },
  ];

  if (refreshOnClick) {
    buttonActions.unshift({
      content: <Icon name="refresh" />,
      onClick: refreshOnClick,
    });
  }

  return (
    <div className="base-margin-bottom base-margin-top">
      <ActionsButtonGroup actions={buttonActions} isIconContent={true} />
    </div>
  );
}
