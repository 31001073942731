import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import {
  AccessPoint,
  Cloud,
  Firewall,
  GenericBuilding,
  Laptop,
  RouterIcon,
  Server,
  Storage,
  SwitchIcon,
  Telephone,
  WLC,
} from '../../assets/images/icons';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { VmIcon } from '../VmIcon';
import styles from './IconPicker.module.css';

export interface IconPickerProps {
  selectedIcon: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleUpdate: (selection: string) => void;
}

export function IconPicker({
  setShowModal,
  selectedIcon,
  handleUpdate,
}: IconPickerProps): ReactElement {
  const { t } = useTranslation();
  const [pickerSelectedIcon, setPcikerSelectedIcon] = useState<string>(
    selectedIcon || '',
  );

  const iconArray = [
    <Laptop title={t('icons.laptop')} key="Laptop" className={styles.vmIcon} />,
    <AccessPoint
      title={t('icons.accessPoint')}
      key="AccessPoint"
      className={styles.vmIcon}
    />,
    <Cloud title={t('icons.cloud')} key="Cloud" className={styles.vmIcon} />,
    <Firewall
      title={t('icons.firewall')}
      key="Firewall"
      className={styles.vmIcon}
    />,

    <GenericBuilding
      title={t('icons.genericBuilding')}
      key="GenericBuilding"
      className={styles.vmIcon}
    />,
    <RouterIcon
      title={t('icons.router')}
      key="RouterIcon"
      className={styles.vmIcon}
    />,
    <Server title={t('icons.server')} key="Server" className={styles.vmIcon} />,
    <Storage
      title={t('icons.storage')}
      key="Storage"
      className={styles.vmIcon}
    />,
    <SwitchIcon
      title={t('icons.switch')}
      key="Switch"
      className={styles.vmIcon}
    />,
    <Telephone
      title={t('icons.telephone')}
      key="Telephone"
      className={styles.vmIcon}
    />,
    <WLC title={t('icons.wlc')} key="WLC" className={styles.vmIcon} />,
  ];

  return (
    <div className={styles.container}>
      <div className={styles.gallery}>
        {iconArray.map((icon) => (
          <VmIcon
            key={icon.key}
            isSelected={icon.props.title === pickerSelectedIcon}
            title={icon.props.title}
            onSelect={setPcikerSelectedIcon}
          >
            {icon}
          </VmIcon>
        ))}
      </div>
      <div className="flex flex-center dbl-margin-top">
        <Button colour="ghost" onClick={() => setShowModal(false)}>
          {t('buttons.cancel')}
        </Button>
        <PrimaryButton
          onClick={() => handleUpdate(pickerSelectedIcon)}
          disabled={selectedIcon === pickerSelectedIcon}
        >
          {t('buttons.use')}
        </PrimaryButton>
      </div>
    </div>
  );
}
