import React, { ReactElement } from 'react';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';

export type InventorySaasTemplatesTableLoaderProps = {
  isLoading: boolean;
};

export function InventorySaasTemplatesTableLoader({
  isLoading,
}: InventorySaasTemplatesTableLoaderProps): ReactElement {
  if (isLoading) {
    return <TableLoader />;
  } else {
    return <></>;
  }
}
