import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { ACTION_LABEL_MAPPINGS } from '../../constants';
import styles from './MultiActionFailureAlert.module.css';
import { MultiActionFailureAlertProps } from './container';

export const MultiActionFailureAlert = ({
  multiSelectApiErrorItems,
  page,
  clearVmMultiSelectErrorItems,
  clearHwMultiSelectErrorItems,
  selectedAction,
}: MultiActionFailureAlertProps): ReactElement => {
  const { t } = useTranslation();
  if (multiSelectApiErrorItems.length < 1) {
    return <></>;
  }

  const handleOnDismiss = () => {
    page === 'vm'
      ? clearVmMultiSelectErrorItems()
      : clearHwMultiSelectErrorItems();
  };

  return (
    <div className={styles.alertWrapper}>
      <div className={styles.alert}>
        <Alert colour="warning-alt" showIcon={true} onDismiss={handleOnDismiss}>
          {`${
            selectedAction ? `${ACTION_LABEL_MAPPINGS[selectedAction]}` : ''
          } ${t(
            'sessionsInventory.multiActionFailureAlert.failedForTheFollowingNodes',
          )}`}
          <ol className="base-padding">
            {multiSelectApiErrorItems.map(({ name, logref, message }) => {
              return (
                <li key={name}>
                  {name}: {message} (Ref: {logref})
                </li>
              );
            })}
          </ol>
        </Alert>
      </div>
    </div>
  );
};
