import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedInventoryItem,
  templateManagerSelectors,
} from '../../../../../../redux/templateManager/slice';
import {
  useCreateVmTemplateMutation,
  useUpdateVmTemplateMutation,
} from '../../../../../../redux/templateManager/VmTemplates/api';
import { AddVmTemplateForm } from '.';

function CreateVmTemplateContainer({
  addSelectedArchitecture,
  allArchitectures,
  currentDc,
  setSelectedInventoryItem,
  removeSelectedArchitecture,
  selectedArchitectures,
  selectedInventoryItem,
  isEdit,
}: CreateVmTemplateRTKProps): ReactElement {
  const [
    createVmTemplate,
    { isLoading: isCreating, isSuccess, data: responseData },
  ] = useCreateVmTemplateMutation();
  const [
    updateVmTemplate,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      data: updateResponseData,
    },
  ] = useUpdateVmTemplateMutation();

  return (
    <AddVmTemplateForm
      addSelectedArchitecture={addSelectedArchitecture}
      allArchitectures={allArchitectures}
      selectedArchitectures={selectedArchitectures}
      removeArchitecture={removeSelectedArchitecture}
      createVmTemplate={createVmTemplate}
      isLoading={isCreating || isUpdating}
      responseData={responseData || updateResponseData}
      currentDc={currentDc!}
      updateVmTemplate={updateVmTemplate}
      isEdit={isEdit}
      selectedInventoryItem={selectedInventoryItem!}
      setSelectedInventoryItem={setSelectedInventoryItem}
      isSuccess={isSuccess || isUpdateSuccess}
    />
  );
}
type CreateVmTemplateOwnProps = { isEdit: boolean };

const mapStateToProps = (
  state: RootState,
  ownProps: CreateVmTemplateOwnProps,
) => ({
  allArchitectures: templateManagerSelectors.getAllArchitectures(state),
  currentDc: templateManagerSelectors.getSelectedDataCenter(state),
  isEdit: ownProps.isEdit,
  selectedArchitectures:
    templateManagerSelectors.getSelectedArchitectures(state),
  selectedInventoryItem:
    templateManagerSelectors.getSelectedInventoryItem(state),
});

const mapDispatchToProps = {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedInventoryItem,
};

export type CreateVmTemplateRTKProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  CreateVmTemplateOwnProps;

export const ConnectedCreateVMTemplate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateVmTemplateContainer);
