import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';

type LogicalConnectionsState = {
  isCreatingLogicalConnection: boolean;
  isDrawingLogicalConnectionFrom?: string;
  isDrawingLogicalConnectionTo?: string;
};

export const initialState: LogicalConnectionsState = {
  isCreatingLogicalConnection: false,
  isDrawingLogicalConnectionFrom: undefined,
  isDrawingLogicalConnectionTo: undefined,
};

export const createLogicalConnection = createAction<CreateConnectionPayload>(
  'logicalConnections/create',
);

const slice = createSlice({
  initialState,
  name: 'logicalConnections',
  reducers: {
    resetLogicalConnections: (state) => {
      state.isDrawingLogicalConnectionFrom = undefined;
      state.isDrawingLogicalConnectionTo = undefined;
    },

    setIsCreatingLogicalConnection: (state, action: PayloadAction<boolean>) => {
      state.isCreatingLogicalConnection = action.payload;
    },

    setIsDrawingLogicalConnectionFrom: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.isDrawingLogicalConnectionFrom = action.payload;
    },

    setIsDrawingLogicalConnectionTo: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.isDrawingLogicalConnectionTo = action.payload;
    },
  },
});

export const getLogicalConnections = (
  state: RootState,
): LogicalConnectionsState => state[slice.name];

export const { reducer: logicalConnectionsReducer } = slice;

export const {
  setIsCreatingLogicalConnection,
  setIsDrawingLogicalConnectionFrom,
  setIsDrawingLogicalConnectionTo,
  resetLogicalConnections,
} = slice.actions;

export const logicalConnectionsSelectors = {
  getIsCreatingLogicalConnectionTo: createSelector(
    getLogicalConnections,
    ({ isCreatingLogicalConnection }) => isCreatingLogicalConnection,
  ),

  getIsDrawingLogicalConnectionFrom: createSelector(
    getLogicalConnections,
    ({ isDrawingLogicalConnectionFrom }) => isDrawingLogicalConnectionFrom,
  ),

  getIsDrawingLogicalConnectionTo: createSelector(
    getLogicalConnections,
    ({ isDrawingLogicalConnectionTo }) => isDrawingLogicalConnectionTo,
  ),
};
