import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../../../../constants';
import i18n from '../../../../../i18n';

export const searchIntegrations = (
  integrations: SaasIntegration[],
  searchText: string,
): SaasIntegration[] => {
  return integrations.filter(
    (integration) =>
      integration.saasTemplate.description
        .toLocaleLowerCase()
        .indexOf(searchText) !== -1 ||
      integration.name.toLocaleLowerCase().indexOf(searchText) !== -1 ||
      integration.saasTemplate.tags.some(
        (tag) => tag.name.toLowerCase().indexOf(searchText) !== -1,
      ),
  );
};

export const buildTakenNamesForSaasIntegrationForm = (
  userSaasIntegrations: SaasIntegration[],
  saasIntegrationToEditUid: string | undefined = undefined,
): string[] =>
  userSaasIntegrations
    .filter(
      (userSaasIntegration) =>
        userSaasIntegration.uid !== saasIntegrationToEditUid,
    )
    .map((userSaasIntegration) => userSaasIntegration.name);

export const getIntegrationType = (type: string): IntegrationType => {
  switch (type) {
    case 'public-cloud':
      return PUBLIC_CLOUD_INTEGRATION_TYPE;
    case 'saas':
      return SAAS_INTEGRATION_TYPE;
    default:
      return SAAS_INTEGRATION_TYPE;
  }
};

export const filterIntegrationsByType = (
  integrationType: IntegrationType,
  integrations?: SaasIntegration[],
): SaasIntegration[] =>
  integrations?.filter(
    (integration) => integration.saasTemplate.type === integrationType,
  ) || [];

export const determinePageTitle = (
  integrationType: IntegrationType,
): string => {
  switch (integrationType) {
    case PUBLIC_CLOUD_INTEGRATION_TYPE:
      return i18n.t('saas.nameCloud');
    case SAAS_INTEGRATION_TYPE:
      return i18n.t('saas.nameSaas');
    default:
      return i18n.t('saas.nameIntegrations');
  }
};
