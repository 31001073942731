import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../../components';
import { useDeleteInboundProxyRuleMutation } from '../../../../../../../redux/configuration/traffic/inbound-proxy-rules/api';

export interface InboundProxyRuleDeleteModalProps {
  setInboundProxyRuleToDelete: (inboundProxyRule?: InboundProxyRule) => void;
  inboundProxyRule: InboundProxyRule;
}

export function InboundProxyRuleDeleteModal({
  setInboundProxyRuleToDelete,
  inboundProxyRule,
}: InboundProxyRuleDeleteModalProps): ReactElement {
  const { t } = useTranslation();
  const [deleteInboundProxyRule, { isLoading, isSuccess, isError }] =
    useDeleteInboundProxyRuleMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setInboundProxyRuleToDelete(undefined);
    }
  }, [isError, isSuccess, setInboundProxyRuleToDelete]);

  return (
    <ConfirmModal
      heading={t('traffic.inboundProxyRules.delete.heading')}
      subHeading={t('traffic.inboundProxyRules.delete.subHeading')}
      loading={isLoading}
      onCancel={() => setInboundProxyRuleToDelete(undefined)}
      onClose={() => setInboundProxyRuleToDelete(undefined)}
      onConfirm={() => deleteInboundProxyRule(inboundProxyRule)}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="inbound-proxy-rule-delete-modal"
    />
  );
}
