import i18n from '../../../../../i18n';

export const buildMessage = (
  labels: TopologyLimitsDisplayLabels | Array<TopologyLimitsDisplayLabels>,
): string => {
  const newLabel: string = buildStringReplace(labels);

  return i18n
    .t('topologies.topologyLimitsWarningModal.message')
    .replace(':label', newLabel);
};

export const buildStringReplace = (
  labels: TopologyLimitsDisplayLabels | Array<TopologyLimitsDisplayLabels>,
): string => {
  if (typeof labels === 'string') {
    return labels;
  }

  return labels.join(' and ');
};
