import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateTelephonyItemMutation } from '../../../../../redux/configuration/telephony/api';
import { PSTNValueLoader } from '../PSTNValueLoader';
import { TelephonyCreate } from './';

interface TelephonyCreateContainerProps {
  inventoryTelephonyItemId: string;
}
export function TelephonyCreateContainer({
  inventoryTelephonyItemId,
}: TelephonyCreateContainerProps): ReactElement {
  const [createTelephonyItem, { isLoading, isError }] =
    useCreateTelephonyItemMutation();
  const { uid: topologyUid } = useParams<TopologyUid>();

  if (isLoading || isError) {
    return <PSTNValueLoader />;
  }

  return (
    <TelephonyCreate
      onCreate={() =>
        createTelephonyItem({ inventoryTelephonyItemId, topologyUid })
      }
    />
  );
}
