import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TopologyLimitsToolBarItem } from './components/TopologyLimitsToolBarItem';
import { TopologyLimitsToolBarItemNoData } from './components/TopologyLimitsToolBarItemNoData';
import { TopologyLimitsToolBarProps } from './container';
import styles from './TopologyLimitsToolBar.module.css';

export const TopologyLimitsToolBar = ({
  topologyLimits,
  isLoading,
}: TopologyLimitsToolBarProps): ReactElement => {
  const { t } = useTranslation();
  const textPath = 'topologies.topologyLimitsToolbar.labels.';

  if (isLoading || !topologyLimits)
    return (
      <div className={styles.root} data-testid="demo-max-toolbar">
        <div className={styles.innerWrapper}>
          <TopologyLimitsToolBarItemNoData label={t(`${textPath}memoryGB`)} />
          <TopologyLimitsToolBarItemNoData label={t(`${textPath}cpus`)} />
          <TopologyLimitsToolBarItemNoData label={t(`${textPath}devices`)} />
          <TopologyLimitsToolBarItemNoData label={t(`${textPath}natRules`)} />
        </div>
      </div>
    );

  const { memoryMb, cpuCores, hardware, natRules } = topologyLimits;

  return (
    <div className={styles.root} data-testid="demo-max-toolbar">
      <div className={styles.innerWrapper}>
        <TopologyLimitsToolBarItem
          topologyLimit={memoryMb}
          label={t(`${textPath}memoryGB`)}
        />
        <TopologyLimitsToolBarItem
          topologyLimit={cpuCores}
          label={t(`${textPath}cpus`)}
        />
        <TopologyLimitsToolBarItem
          topologyLimit={hardware}
          label={t(`${textPath}devices`)}
        />
        <TopologyLimitsToolBarItem
          topologyLimit={natRules}
          label={t(`${textPath}natRules`)}
        />
      </div>
    </div>
  );
};
