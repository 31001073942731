import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../components/Accordion';
import { AnyConnectContainer } from './components/AnyConnectCredentials/container';
import { DNSContainer } from './components/DNS/container';
import { EndpointKitsContainer } from './components/EndpointKits/container';
import { PhoneNumbersContainer } from './components/PhoneNumbers/container';
import { PublicIPProxy } from './components/PublicIP&Proxy';
import { SessionInformationContainer } from './components/SessionInformation/container';

export function InfoPanel(): ReactElement {
  const { t } = useTranslation();

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <SessionInformationContainer />,
        title: t('sessions.panels.info.sessionInformation.sectionHeader'),
      },
      {
        children: <EndpointKitsContainer />,
        title: t('sessions.panels.info.endpointKits.sectionHeader'),
      },
      {
        children: <PublicIPProxy />,
        title: t('sessions.panels.info.publicIpProxy.sectionHeader'),
      },
      {
        children: <AnyConnectContainer />,
        title: t('sessions.panels.info.anyConnect.sectionHeader'),
      },
      {
        children: <PhoneNumbersContainer />,
        title: t('sessions.panels.info.phoneNumbers.sectionHeader'),
      },
      {
        children: <DNSContainer />,
        title: t('sessions.panels.info.dns.sectionHeader'),
      },
    ],
    [t],
  );

  return <Accordion rows={accordionRows} size="small" />;
}
