import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setShowExpiredModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { TimerExpiredModal } from '.';

const mapStateToProps = (state: RootState) => ({
  showExpiredModal: sessionViewSelectors.getShowExpiredModal(state),
});

const mapDispatchToProps = {
  setShowExpiredModal,
};

export type TimerExpiredModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const TimerExpiredModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimerExpiredModal);
