import React, { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { Icon } from 'dcloud-shared-ui';
import { HelpButtonLink } from './components/HelpButtonLink';
import styles from './HelpButton.module.css';

export interface HelpButtonProps {
  links?: HelpButtonLink[];
}

export function HelpButton({ links }: HelpButtonProps): ReactElement {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div className={styles.root}>
      <div
        className={classnames({
          [styles.hideLinks]: !showLinks,
        })}
      >
        <div className={styles.buttonWrapper}>
          {links?.map((link) => (
            <HelpButtonLink
              key={link.label}
              label={link.label}
              href={link.href}
              ariaLabel={link.ariaLabel}
              show={showLinks}
            />
          ))}
        </div>
      </div>
      <div className={styles.parentButton}>
        <button
          className="btn btn--circle btn--large btn-primary"
          onClick={() => setShowLinks(!showLinks)}
          data-testid="help-link-button"
        >
          <Icon name="info-outline" className={styles.icon} />
        </button>
      </div>
    </div>
  );
}
