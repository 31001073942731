import { connect } from 'react-redux';
import {
  getVirtualMachines,
  setShowRemoteAccessCountExceededModal,
} from '../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../redux/store';
import { VirtualMachinesAdd } from '.';

export type VirtualMachinesAddProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  showRemoteAccessCountExceededModal:
    getVirtualMachines(state).showRemoteAccessCountExceededModal,
});

const mapDispatchToProps = {
  setShowRemoteAccessCountExceededModal,
};

export const VirtualMachinesAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachinesAdd);
