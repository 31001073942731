import React, { ReactElement } from 'react';
import { Dropdown } from '../../../../../components/Dropdown';
import { DropdownItem } from '../../../../../components/Dropdown/components/DropdownItem';
import i18n from '../../../../../i18n';
import { MultiSelectDropDownVmProps } from './container';

const PATH_TO_TEXT = 'sessionsInventory.virtualMachines.table.dropdown.labels';

export const MultiSelectDropDownVm = ({
  setSelectedAction,
  isDisabled,
}: MultiSelectDropDownVmProps): ReactElement => {
  return (
    <Dropdown isDisabled={isDisabled} testId="multi-select-dropdown-button">
      <DropdownItem
        onClick={() => setSelectedAction('VM_POWER_OFF')}
        label={i18n.t(`${PATH_TO_TEXT}.powerOff`)}
      />
      <DropdownItem
        onClick={() => setSelectedAction('VM_RESET')}
        label={i18n.t(`${PATH_TO_TEXT}.reset`)}
      />
      <DropdownItem
        onClick={() => setSelectedAction('VM_POWER_ON')}
        label={i18n.t(`${PATH_TO_TEXT}.powerOn`)}
      />
      <DropdownItem
        onClick={() => setSelectedAction('GUEST_OS_REBOOT')}
        label={i18n.t(`${PATH_TO_TEXT}.rebootGuestOs`)}
      />
      <DropdownItem
        onClick={() => setSelectedAction('GUEST_OS_SHUTDOWN')}
        label={i18n.t(`${PATH_TO_TEXT}.shutdownGuestOs`)}
      />
    </Dropdown>
  );
};
