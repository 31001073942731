import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthStatusValues } from '../../auth';
import { RootState } from '../store';

export const initialState = AuthStatusValues.NotAuthenticated;
export const startAuth = createAction('auth/start');
export const swapAccessCode = createAction('auth/startSwap');

const slice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setAuthStatus: (_, action: PayloadAction<AuthStatusValues>) =>
      action.payload,
  },
});

export const getAuthStatus = (state: RootState): AuthStatusValues =>
  state[slice.name];

export const {
  reducer: authReducer,
  actions: { setAuthStatus },
} = slice;
