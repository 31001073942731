import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import { getExpoInternalDocument } from '../../../../../../../../../../redux/views/slice';
import { Attachment } from './index';

const mapDispatchToProps = {
  getExpoInternalDocument,
};

type ExpoAttachmentContentContainerProps = {
  engagementUid: string;
  sessionDocument: SessionDocument;
  versionUid: string;
} & typeof mapDispatchToProps;

export function ExpoAttachmentContentContainer({
  engagementUid,
  sessionDocument,
  versionUid,
  getExpoInternalDocument,
}: ExpoAttachmentContentContainerProps): ReactElement {
  const getInternalDocumentExpo = () =>
    getExpoInternalDocument({
      documentUid: sessionDocument.uid,
      engagementUid,
      versionUid,
    });
  return (
    <Attachment
      sessionDocument={sessionDocument}
      getInternalDocument={getInternalDocumentExpo}
    ></Attachment>
  );
}

export const ExpoAttachmentContainer = connect(
  null,
  mapDispatchToProps,
)(ExpoAttachmentContentContainer);
