import React, { ReactElement } from 'react';
import { DemoHeaderTitleProps } from './container';
import styles from './DemoHeaderTitle.module.css';

export const DEMO_HEADER_TITLE_TEST_ID = 'demo-header-title';

export const DemoHeaderTitle = ({
  demoTitle,
  isLoading,
  isError,
}: DemoHeaderTitleProps): ReactElement => {
  const shouldRenderTitle = !isLoading && !isError && demoTitle;

  return (
    <span data-testid={DEMO_HEADER_TITLE_TEST_ID} className={styles.root}>
      {shouldRenderTitle ? demoTitle : ''}
    </span>
  );
};
