type IconData = {
  keyValuePairs: PanelGridDataItem[];
  title: string;
};

export const getIconDataFromNodeDetails = (node: SessionIconNode): IconData => {
  const data = node.display.data as DiagramAddIconFormFieldsDatum[];

  return {
    keyValuePairs: data.map((dataItem, index: number) => ({
      key: index.toString(),
      name: dataItem.key as string,
      type: dataItem.type,
      value: dataItem.value,
    })),
    title: node.display?.name || '',
  };
};
