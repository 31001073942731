import { LoaderSpinner, LoaderSpinnerProps } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';

export function CenteredSpinner({
  size = 'default',
}: LoaderSpinnerProps): ReactElement {
  return (
    <div className="flex flex-center dbl-margin-top">
      <LoaderSpinner size={size} />
    </div>
  );
}
