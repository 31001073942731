export const getRemoteAccessFromNodeDetails = (
  data: SessionVmNode,
): VmPanelRemoteAccess | undefined => {
  if (!data || (!data.asset.remoteAccess && !data.sessionDetails.remoteAccess))
    return undefined;

  const { asset, sessionDetails } = data;

  return {
    console: sessionDetails.remoteAccess?.console,
    internalUrl1: asset.remoteAccess?.internalUrls?.[0]?.location,
    internalUrl1Description: asset.remoteAccess?.internalUrls?.[0]?.description,
    internalUrl2: asset.remoteAccess?.internalUrls?.[1]?.location,
    internalUrl2Description: asset.remoteAccess?.internalUrls?.[1]?.description,
    password: sessionDetails.remoteAccess?.password,
    rdp: sessionDetails.remoteAccess?.rdp,
    ssh: sessionDetails.remoteAccess?.ssh,
    user: sessionDetails.remoteAccess?.username,
  };
};
