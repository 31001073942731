import { connect } from 'react-redux';
import {
  setPublicCloudFilter,
  setSaasFilter,
} from '../../../../../src/redux/integrationsFilter/slice';
import { IntegrationsFilter } from '.';

const mapDispatchToProps = {
  setPublicCloudFilter,
  setSaasFilter,
};

export type IntegrationsFilterProps = typeof mapDispatchToProps;

export const IntegrationsFilterContainer = connect(
  undefined,
  mapDispatchToProps,
)(IntegrationsFilter);
