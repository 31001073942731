import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stripGzipFromEtag } from '../../../api/utils';

const editEtagSlice = createSlice({
  initialState: '',
  name: 'etag',
  reducers: {
    setEtag: (_, action: PayloadAction<string>) =>
      stripGzipFromEtag(action.payload),
  },
});

export const {
  reducer: editEtagReducer,
  actions: { setEtag },
} = editEtagSlice;
