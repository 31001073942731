import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpButton } from '../../../../components/HelpButton';
import { GENERAL_HELP_LINKS } from '../../../../constants/user-guide';
import { PageHeading } from '../../components';
import { GeneralForm } from './components/GeneralForm';
import { GeneralFormSkeleton } from './components/GeneralFormSkeleton';
import styles from './General.module.css';

export interface GeneralProps {
  isLoading: boolean;
  topologyToEdit?: Topology;
  updateTopology: RTKMutation<TopologyUpdatePayload, Topology>;
  isError: boolean;
}

export function General({
  isLoading,
  topologyToEdit,
  updateTopology,
  isError,
}: GeneralProps): ReactElement {
  const { t } = useTranslation();

  const onHandleSubmit = (data: TopologyGeneralFields) => {
    if (topologyToEdit) {
      updateTopology({ ...topologyToEdit, ...data });
    }
  };

  return (
    <div className="half-padding-left">
      <div className="dbl-margin-bottom">
        <PageHeading text={t('general.name')} />
      </div>
      <div className={styles.root}>
        {!topologyToEdit || isLoading || isError ? (
          <GeneralFormSkeleton />
        ) : (
          <GeneralForm
            defaultValues={topologyToEdit}
            onHandleSubmit={onHandleSubmit}
            updating={isLoading}
          />
        )}
      </div>
      <HelpButton links={GENERAL_HELP_LINKS} />
    </div>
  );
}
