import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { Button, Icon } from 'dcloud-shared-ui';
import classNames from 'classnames';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../../../components/Accordion';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '../../../../../../../../components/PageHeading';
import { vmEditFieldsSchema } from '../../../../../../../../validation';
import { GeneralForm } from '../GeneralForm';
import { GuestAutomationForm } from '../GuestAutomationForm';
import {
  addVMNetworkInterfacesToPayload,
  convertMbToGb,
  prepareRDPForPayload,
  prepareSSHForPayload,
  removeDhcpConfigStringsWithNulls,
  removeDisplayCredentialsIfDisabled,
  removeDisplayDifferentCredentials,
  removeEmptyBios,
  removeGuestAutomationIfDisabled,
  removeIsGuestAutomationEnabled,
  removeIsShutdownAutomationEnabled,
  removeLinks,
  removeMemoryGb,
  removeShutdownAutomationIfDisabled,
  removeUnusedInternalUrls,
  replaceRAEmptyStringsWithNulls,
} from '../../utils';
import { deepClone } from '../../../../../../../../utils/helpers';
import { AdvancedSettingsForm } from '../AdvancedSettingsForm';
import { ConnectedNetworking } from '../Networking/container';
import { ConnectedRemoteAccessForm } from '../RemoteAccessForm/container';
import { FormUnsavedChangesPrompt } from '../../../../../../../../components/FormUnsavedChangesPrompt';
import { REMOTE_SHUTDOWN_DEFAULT_DELAY } from '../../../../../../../../utils/constants';
import { useLeaveFormPrompt } from '../../../../../../hooks/useLeaveFormPrompt';
import ScrollToTop from '../../../../../../../../components/ScrollTop/ScrollToTop';
import { useShowTopologyLimitsWarningEditVm } from './hooks/use-show-topology-limits-warning-edit-vm';
import styles from './form.module.css';
import { extractNetworkSubnets } from './utils';

export type FormProps = {
  operatingSystems: OperatingSystem[];
  isNodeUpdating: boolean;
  isUpdating: boolean;
  nodeData: DiagramNode;
  updateNode: RTKMutation<UpdateDiagramNodeProps, DiagramNode>;
  vmNames: TakenVmNames;
  updateVM: RTKMutation<VmToUpdatePayload, VmToUpdatePayload>;
  vmToEdit: VirtualMachine;
  vmNetworkingToEdit?: VmNetworkingToEdit;
  rdpEnabledNic?: VMNetworkInterface;
  setNetworkingToEdit: (networkingToEdit: VmNetworkInterfacesToEdit) => void;
  sshEnabledNic?: VMNetworkInterface;
  resetTopologyLimits: ActionCreatorWithoutPayload<string>;
  setShowTopologyLimitWarningForEntity: ActionCreatorWithOptionalPayload<
    TopologyLimitsDisplayLabels | TopologyLimitsDisplayLabels[] | undefined,
    string
  >;
  topologyLimitsWarningAccepted?: boolean;
  topologyLimits?: TopologyLimits;
  isModal?: boolean;
};

export function Form({
  operatingSystems,
  isNodeUpdating,
  isUpdating,
  nodeData,
  setNetworkingToEdit,
  updateNode,
  updateVM,
  vmNames,
  vmToEdit,
  vmNetworkingToEdit,
  rdpEnabledNic,
  sshEnabledNic,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
  topologyLimitsWarningAccepted,
  topologyLimits,
  isModal,
}: FormProps): ReactElement {
  const { t } = useTranslation();
  const formMethods = useForm<VmEditFormData>({
    defaultValues: {
      advancedSettings: {
        allDisksNonPersistent: vmToEdit.advancedSettings.allDisksNonPersistent,
        biosUuid: vmToEdit.advancedSettings.biosUuid || '',
        hiddenFromSession: nodeData?.display?.hidden === true,
        nameInHypervisor: vmToEdit.advancedSettings.nameInHypervisor || '',
        notStarted: vmToEdit.advancedSettings.notStarted,
      },
      cpuQty: vmToEdit.cpuQty,
      description: vmToEdit.description,
      dhcpConfig: {
        defaultGatewayIp: vmToEdit.dhcpConfig?.defaultGatewayIp || '',
        primaryDnsIp: vmToEdit.dhcpConfig?.primaryDnsIp || '',
        secondaryDnsIp: vmToEdit.dhcpConfig?.secondaryDnsIp || '',
      },
      displayDifferentCredentials:
        'displayCredentials' in vmToEdit.remoteAccess,
      guestAutomation: {
        command: vmToEdit.guestAutomation?.command || '',
        delaySecs: vmToEdit.guestAutomation?.delaySecs || 0,
      },
      icon: nodeData?.display?.icon || '',
      isGuestAutomationEnabled: 'guestAutomation' in vmToEdit,
      isShutdownAutomationEnabled: 'shutdownAutomation' in vmToEdit,
      memoryGb: convertMbToGb(vmToEdit.memoryMb, { roundCeil: false }),
      memoryMb: vmToEdit.memoryMb,
      name: vmToEdit.name,
      nestedHypervisor: vmToEdit.nestedHypervisor,
      osFamily: vmToEdit.osFamily || 'NONE',
      remoteAccess: {
        displayCredentials: {
          password: vmToEdit.remoteAccess.displayCredentials?.password || '',
          username: vmToEdit.remoteAccess.displayCredentials?.username || '',
        },
        internalUrls: [
          {
            description:
              vmToEdit.remoteAccess.internalUrls?.[0]?.description || '',
            location: vmToEdit.remoteAccess.internalUrls?.[0]?.location || '',
          },
          {
            description:
              vmToEdit.remoteAccess.internalUrls?.[1]?.description || '',
            location: vmToEdit.remoteAccess.internalUrls?.[1]?.location || '',
          },
        ],
        password: vmToEdit.remoteAccess.password || '',
        rdp: {
          autoLogin: rdpEnabledNic?.rdp?.autoLogin || false,
          vmNetworkInterface: {
            isEnabled: rdpEnabledNic?.rdp?.enabled || false,
            uid: rdpEnabledNic?.uid || '',
          },
        },
        ssh: {
          vmNetworkInterface: {
            isEnabled: sshEnabledNic?.ssh?.enabled || false,
            uid: sshEnabledNic?.uid || '',
          },
        },
        username: vmToEdit.remoteAccess.username || '',
        vmConsoleEnabled: vmToEdit.remoteAccess.vmConsoleEnabled,
      },
      shutdownAutomation: {
        command: vmToEdit.shutdownAutomation?.command || '',
        delaySecs:
          vmToEdit.shutdownAutomation?.delaySecs ||
          REMOTE_SHUTDOWN_DEFAULT_DELAY,
      },
    },
    mode: 'all',
    resolver: yupResolver(
      vmEditFieldsSchema(
        vmNames,
        extractNetworkSubnets(vmNetworkingToEdit || []),
      ),
    ),
  });
  const { dirtyFields, isValid } = formMethods.formState;
  const handleUpdateClicked: SubmitHandler<VmEditFormData> = async (
    formData,
  ) => {
    const vmToEditCopy = deepClone(vmToEdit);
    const formDataCopy = deepClone(formData);

    let vmToUpdatePayload: VmToUpdatePayload = {
      ...vmToEditCopy,
      ...formDataCopy,
      osFamily: formData.osFamily === 'NONE' ? undefined : formData.osFamily,
    };
    removeLinks(vmToUpdatePayload);
    removeGuestAutomationIfDisabled(vmToUpdatePayload);
    removeShutdownAutomationIfDisabled(vmToUpdatePayload);
    removeEmptyBios(vmToUpdatePayload);
    removeIsGuestAutomationEnabled(vmToUpdatePayload);
    removeIsShutdownAutomationEnabled(vmToUpdatePayload);
    removeDisplayCredentialsIfDisabled(vmToUpdatePayload);
    removeDisplayDifferentCredentials(vmToUpdatePayload);
    removeDhcpConfigStringsWithNulls(vmToUpdatePayload);
    replaceRAEmptyStringsWithNulls(vmToUpdatePayload);
    removeMemoryGb(vmToUpdatePayload);
    prepareRDPForPayload(vmToUpdatePayload);
    prepareSSHForPayload(vmToUpdatePayload);
    vmToUpdatePayload = removeUnusedInternalUrls(vmToUpdatePayload);
    const isPatchRequestNeeded =
      dirtyFields.icon || dirtyFields.advancedSettings?.hiddenFromSession;
    if (vmNetworkingToEdit) {
      vmToUpdatePayload = addVMNetworkInterfacesToPayload(
        vmToUpdatePayload,
        JSON.parse(JSON.stringify(vmNetworkingToEdit)),
      );
    }
    if (!isPatchRequestNeeded && isModal) {
      vmToUpdatePayload.isInvalidateDiagramTagNeeded = true;
    }
    if (isPatchRequestNeeded) {
      await updateVM(vmToUpdatePayload);
      await updateNode({
        display: {
          ...nodeData.display,
          hidden: formData.advancedSettings.hiddenFromSession,
          icon: formData.icon,
        },
        isInvalidateDiagramTagNeeded: isModal,
        nodeUid: nodeData.uid,
      })
        .unwrap()
        .then(() => formMethods.reset(formData))
        .catch(() => {
          return;
        });
    } else {
      await updateVM(vmToUpdatePayload)
        .unwrap()
        .then(() => formMethods.reset(formData))
        .catch(() => {
          return;
        });
    }
  };

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: (
          <GeneralForm
            operatingSystems={operatingSystems}
            nodeData={nodeData}
          />
        ),
        title: t('virtualMachines.edit.sectionTitles.general'),
      },
      {
        children: <ConnectedNetworking />,
        title: t('virtualMachines.edit.sectionTitles.networking'),
      },
      {
        children: <ConnectedRemoteAccessForm />,
        title: t('virtualMachines.edit.sectionTitles.remoteAccess'),
      },
      {
        children: <GuestAutomationForm />,
        title: t('virtualMachines.edit.sectionTitles.guestAutomation'),
      },
      {
        children: <AdvancedSettingsForm />,
        title: t('virtualMachines.edit.sectionTitles.advancedSettings'),
      },
    ],
    [operatingSystems, t, nodeData],
  );

  const { onAddClicked } = useShowTopologyLimitsWarningEditVm({
    resetTopologyLimits,
    setShowTopologyLimitWarningForEntity,
    topologyLimits,
    topologyLimitsWarningAccepted,
    updateVm: handleUpdateClicked,
    vm: vmToEdit,
  });
  const hasFormChanged =
    !!Object.keys(dirtyFields).length ||
    !isEqual(vmToEdit.vmNetworkInterfaces, vmNetworkingToEdit);

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(hasFormChanged);
  const disableUpdateButton = !hasFormChanged || !isValid;
  const onResetClick = () => {
    formMethods.reset();
    setNetworkingToEdit(vmToEdit.vmNetworkInterfaces);
  };

  return (
    <section className="half-padding-left">
      <FormProvider {...formMethods}>
        <form>
          <PageHeading
            ctaBtn={
              <div className={styles.wrapper}>
                <Button
                  disabled={!hasFormChanged}
                  type="button"
                  testId="vm-edit-form-reset-button"
                  className={classNames(
                    styles.reset,
                    'btn btn--ghost btn--wide btn--large',
                  )}
                  onClick={onResetClick}
                  id="vmEditResetButton"
                >
                  {t('userPreferences.reset')}
                </Button>
                <PrimaryButton
                  type="submit"
                  colour="success"
                  onClick={formMethods.handleSubmit(onAddClicked)}
                  disabled={disableUpdateButton}
                  loading={isUpdating || isNodeUpdating}
                >
                  {t('buttons.update')}
                </PrimaryButton>
              </div>
            }
            pageTitle={t('virtualMachines.edit.title')}
            withBackBtn={true}
          />
          <Accordion rows={accordionRows} collapsed={isModal} />
          <FormUnsavedChangesPrompt
            showModal={showModal}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
        </form>
      </FormProvider>
      <ScrollToTop>
        <Icon name="arrow-up-tail" className={styles.scrollToTopIcon} />
      </ScrollToTop>
    </section>
  );
}
