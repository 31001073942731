import React, { ReactElement } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { ExternalDnsTable } from '../ExternalDnsTable';

type ExternalDnsTableHelperProps = {
  externalDNSRecord?: ExternalDnsRecord[];
  isLoading: boolean;
  isError: boolean;
  setExternalDnsToDelete: ActionCreatorWithOptionalPayload<
    ExternalDnsRecord | undefined,
    string
  >;
};
export const ExternalDnsTableHelper = ({
  externalDNSRecord,
  isLoading,
  isError,
  setExternalDnsToDelete,
}: ExternalDnsTableHelperProps): ReactElement => {
  if (!externalDNSRecord?.length && !isLoading) return <></>;

  return (
    <ExternalDnsTable
      onDeleteClick={setExternalDnsToDelete}
      dnsRecords={externalDNSRecord || []}
      isError={isError}
      isLoading={isLoading}
    />
  );
};
