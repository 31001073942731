import React, { ReactElement } from 'react';
import { useTheme } from 'dcloud-shared-ui';
import { DefaultLineNodeDisplay } from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLine/constants';
import { NodeStrokeTypes } from '../../../../components/DrawingPalettes/constants';
import { determineColorFromSelectedColorAndTheme } from './utils';

interface DiagramLineProps {
  display: DiagramLineDisplay;
  uid: string;
}

export const DiagramLine = ({
  display,
  uid,
}: DiagramLineProps): ReactElement | null => {
  const [theme] = useTheme();
  return (
    <line
      stroke={determineColorFromSelectedColorAndTheme(
        display.strokeColorName,
        theme,
      )}
      strokeDasharray={
        display.strokeType === NodeStrokeTypes.dashed
          ? DefaultLineNodeDisplay.dashSize
          : undefined
      }
      strokeWidth={DefaultLineNodeDisplay.strokeWidth}
      opacity={'100%'}
      data-line-component-stroke-type={
        display.strokeType || DefaultLineNodeDisplay.strokeType
      }
      data-line-component-uid={uid}
      x1={display.x1}
      y1={display.y1}
      x2={display.x2}
      y2={display.y2}
    ></line>
  );
};
