import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../redux/views/session-view/session/api/api';
import { getSessionInformationFromSession } from './utils';
import { SessionInformation } from '.';

export function ConnectedDCloudSessionInformation({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const { sessionInformation, isError, isLoading, isFetching } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionInformation: getSessionInformationFromSession(data),
        }),
      },
    );

  return (
    <SessionInformation
      sessionInformation={sessionInformation}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
