import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../../../../../redux/store';
import {
  setAutoLogin,
  setWebRDPAdapter,
  setSSHAdapter,
  virtualMachinesSelectors,
} from '../../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { virtualMachineEntitiesApi } from '../../../../../../../../redux/configuration/virtual-machine-entities/api';
import { calculateTopologyRemoteAccessCount } from '../../../utils';
import { RemoteAccessFormLoader } from './components/RemoteAccessFormLoader';
import { RemoteAccessForm } from '.';

const RemoteAccessFormContainer = ({
  setAutoLogin,
  setSSHAdapter,
  setWebRDPAdapter,
  validAdapters,
}: RemoteAccessFormContainerProps): ReactElement => {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { topologyRemoteAccessCount, isFetching, isLoading, isError } =
    virtualMachineEntitiesApi.endpoints.getAll.useQueryState(topologyUid, {
      selectFromResult: ({ data, isError, isLoading, isFetching }) => ({
        isError,
        isFetching,
        isLoading,
        topologyRemoteAccessCount: calculateTopologyRemoteAccessCount(data),
      }),
    });

  if (isFetching || isLoading || isError) {
    return <RemoteAccessFormLoader />;
  }

  return (
    <RemoteAccessForm
      setAutoLogin={setAutoLogin}
      setSSHAdapter={setSSHAdapter}
      setWebRDPAdapter={setWebRDPAdapter}
      topologyRemoteAccessCount={topologyRemoteAccessCount}
      validAdapters={validAdapters}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  validAdapters: virtualMachinesSelectors.getValidAdapters(state),
});

const mapDispatchToProps = {
  setAutoLogin,
  setSSHAdapter,
  setWebRDPAdapter,
};

type RemoteAccessFormContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedRemoteAccessForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoteAccessFormContainer);
