import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../components';
import { buildMessage } from './utils';

export type TopologyLimitsToolBarModalProps = {
  onClose: VoidFunction;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  show: boolean;
  label: TopologyLimitsDisplayLabels;
};

export const TopologyLimitsToolBarModal = ({
  onClose,
  onCancel,
  onConfirm,
  show,
  label,
}: TopologyLimitsToolBarModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      heading={t('common.warning')}
      subHeading={buildMessage(label)}
      showCancel={false}
      show={show}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
      loading={false}
      confirmButtonLabel={t('common.close')}
      confirmButtonColour="primary"
    />
  );
};
