import React, { PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';
import styles from './Tooltip.module.css';

export type TooltipProps = PropsWithChildren<{
  id: string;
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'fitted';
  position?: 'up' | 'right' | 'down' | 'left';
  text: string;
}>;

export function Tooltip({
  id,
  className,
  children,
  size = 'medium',
  position = 'up',
  text,
}: TooltipProps): ReactElement {
  return (
    <span
      tabIndex={0}
      className={classnames(styles.root, className)}
      data-balloon={text}
      data-balloon-pos={position}
      data-balloon-length={size}
      aria-describedby={id}
    >
      <span role="tooltip" id={id}>
        {children}
      </span>
    </span>
  );
}
