import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../redux/views/session-view/session/api/api';
import { ActionsBarTimerContainerProps } from './container';
import { ActionsBarTimer } from '.';

type ConnectedDCloudActionsBarTimerProps = ActionsBarTimerContainerProps &
  FetchSessionArgs;

export function ConnectedDCloudActionsBarTimer({
  expired,
  setShowExpiredModal,
  setExpired,
  sessionUid,
  versionUid,
}: ConnectedDCloudActionsBarTimerProps): ReactElement {
  const { stop, isError } = sessionApi.endpoints.getSession.useQueryState(
    { sessionUid, versionUid },
    {
      selectFromResult: ({ data, isError }) => ({
        isError,
        stop: data?.sessionDetails.stop,
      }),
    },
  );

  if (!stop || isError) {
    return <></>;
  }

  return (
    <ActionsBarTimer
      expired={expired}
      stopDateString={stop}
      setExpired={setExpired}
      setShowExpiredModal={setShowExpiredModal}
    />
  );
}
