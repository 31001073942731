export const SHOW_FILTERS: TopologiesFilter[] = [
  {
    filterKey: 'all',
    label: 'topologies.filters.show.myCustomContent',
  },
  {
    filterKey: 'sharedWithCurrentUser',
    label: 'topologies.filters.show.sharedWithMe',
  },
  {
    filterKey: 'demoPublished',
    label: 'topologies.filters.show.published',
  },
  {
    filterKey: 'activeTemplate',
    label: 'topologies.filters.show.activeTemplates',
  },
];

export const DATA_CENTER_FILTERS: TopologiesFilter[] = [
  {
    filterKey: 'LON',
    label: 'topologies.filters.dataCenters.EMEA',
  },
  {
    filterKey: 'SYD',
    label: 'topologies.filters.dataCenters.ANZ',
  },
  {
    filterKey: 'SNG',
    label: 'topologies.filters.dataCenters.APJ',
  },
  {
    filterKey: 'RTP',
    label: 'topologies.filters.dataCenters.USEast',
  },
  {
    filterKey: 'SJC',
    label: 'topologies.filters.dataCenters.USWest',
  },
];
