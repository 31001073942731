import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../redux/views/session-view/session/api/expoApi';
import { InfoLeftPanelProps } from './infoContainer';
import { PanelHeaderButtons } from '.';

type ConnectedExpoInfoLeftPanelHeaderButtonsProps = InfoLeftPanelProps &
  FetchExpoSessionArgs;

export function ConnectedExpoInfoLeftPanelHeaderButtons({
  setLeftPanelType,
  engagementUid,
  sessionUid,
  versionUid,
}: ConnectedExpoInfoLeftPanelHeaderButtonsProps): ReactElement {
  const { refetch } =
    expoSessionApi.endpoints.getExpoSession.useQuerySubscription({
      engagementUid,
      sessionUid,
      versionUid,
    });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
