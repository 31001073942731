import { dcloudApi } from '../../api';
import {
  INVENTORY_TELEPHONY_ITEMS_PATH,
  TELEPHONY_ITEMS_PATH,
  TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { TAGS } from '../../api/constants';

interface CreateTelephonyItemPayload {
  inventoryTelephonyItemId: string;
  topologyUid: string;
}

interface CreateTelephonyItemResponse {
  inventoryTelephonyItem: TelephonyItem;
  uid: string;
  name: string;
  topology: { uid: string };
}

interface DeleteTelephonyItemResponse {
  telephonyItems: TelephonyItem[];
}

interface GetInventoryTelephonyItemsResponse {
  inventoryTelephonyItems: InventoryTelephonyItem[];
}
interface GetTelephonyItemsResponse {
  telephonyItems: TelephonyItem[];
}

export const topologyItemsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createTelephonyItem: build.mutation<
      CreateTelephonyItemResponse,
      CreateTelephonyItemPayload
    >({
      invalidatesTags: [TAGS.TELEPHONY_ITEMS, TAGS.INVENTORY_TELEPHONY_ITEMS],
      query: ({ inventoryTelephonyItemId, topologyUid }) => ({
        data: {
          inventoryTelephonyItem: { id: inventoryTelephonyItemId },
          topology: { uid: topologyUid },
        },
        errorMessage: i18n.t('telephony.addError'),
        method: 'post',
        successMessage: i18n.t('telephony.addSuccess'),
        url: TELEPHONY_ITEMS_PATH,
      }),
    }),
    deleteTelephonyItem: build.mutation<DeleteTelephonyItemResponse, string>({
      invalidatesTags: [TAGS.TELEPHONY_ITEMS, TAGS.INVENTORY_TELEPHONY_ITEMS],
      query: (telephonyUId) => ({
        errorMessage: i18n.t('telephony.deleteError'),
        method: 'delete',
        successMessage: i18n.t('telephony.deleteSuccess'),
        url: `${TELEPHONY_ITEMS_PATH}/${telephonyUId}`,
      }),
    }),
    getInventoryTelephonyItems: build.query<
      GetInventoryTelephonyItemsResponse,
      string
    >({
      providesTags: [TAGS.INVENTORY_TELEPHONY_ITEMS],
      query: (topologyId) => ({
        errorMessage: i18n.t('telephony.inventoryFetchError'),
        method: 'GET',
        url: `${TOPOLOGIES_PATH}/${topologyId}${INVENTORY_TELEPHONY_ITEMS_PATH}`,
      }),
    }),
    getTelephonyItems: build.query<GetTelephonyItemsResponse, string>({
      providesTags: [TAGS.TELEPHONY_ITEMS],
      query: (topologyId) => ({
        errorMessage: i18n.t('telephony.fetchError'),
        method: 'GET',
        url: `${TOPOLOGIES_PATH}/${topologyId}${TELEPHONY_ITEMS_PATH}`,
      }),
    }),
  }),
});

export const {
  useCreateTelephonyItemMutation,
  useDeleteTelephonyItemMutation,
  useGetInventoryTelephonyItemsQuery,
  useGetTelephonyItemsQuery,
} = topologyItemsApi;
