export const addSelectedTag = (allTags: Tag[], selectedTag: Tag): Tag[] => {
  return [...allTags, selectedTag];
};

export const filterTagsByAvailability = (
  allTags: Tag[],
  selectedTags: Tag[],
): Tag[] =>
  allTags.filter(
    (tag) => !selectedTags.find((selectedTag) => tag.name === selectedTag.name),
  );

export const filterTagsByText = (tags: Tag[], enteredText: string): Tag[] =>
  tags.filter(
    (tag) =>
      enteredText &&
      tag.name.toLowerCase().indexOf(enteredText.toLowerCase()) !== -1,
  );
