import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAvailableInventoryNetworksByType,
  getNetworkInventoryIdsByType,
  getNetworkNames,
} from '../../../../pages/EditTopology/configuration/Networks/utils';
import {
  networksApi,
  useCreateNetworkMutation,
} from '../../../../redux/configuration/networks/api';
import { AddNetworkForm } from './AddNetworkForm';

export function AddNetworkFormContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const [createNetwork, { isLoading: isCreating }] = useCreateNetworkMutation();

  const { data: networks, networkNames } =
    networksApi.endpoints.getNetworks.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        data,
        networkNames: getNetworkNames(data || []),
      }),
    });

  const { availableRouted, availableUnrouted } =
    networksApi.endpoints.getInventoryNetworks.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        availableRouted: getAvailableInventoryNetworksByType(
          getNetworkInventoryIdsByType(networks || [], 'ROUTED'),
          'ROUTED',
          data || [],
        ),
        availableUnrouted: getAvailableInventoryNetworksByType(
          getNetworkInventoryIdsByType(networks || [], 'UNROUTED'),
          'UNROUTED',
          data || [],
        ),
        data,
      }),
    });

  return (
    <AddNetworkForm
      addNetwork={createNetwork}
      adding={isCreating}
      availableRouted={availableRouted}
      availableUnrouted={availableUnrouted}
      networkNames={networkNames}
    />
  );
}

type AddNetworkRTKProps = {
  addNetwork: RTKMutation<NetworkCreatePayload, undefined>;
  adding: boolean;
  availableRouted: InventoryNetwork[];
  availableUnrouted: InventoryNetwork[];
  networkNames: string[];
};

export type AddNetworkFormProps = AddNetworkRTKProps;
