import React, { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredSpinner } from '../../components';
import PageHero from '../../components/PageHero';
import { DefaultLayout } from '../DefaultLayout';

type TopologiesLayoutProps = PropsWithChildren<{
  showLoadingSpinner?: boolean;
}>;

export function TopologiesLayout({
  children,
  showLoadingSpinner = false,
}: TopologiesLayoutProps): ReactElement {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <PageHero title={t('topologies.title')} />
      {showLoadingSpinner ? <CenteredSpinner size="small" /> : children}
    </DefaultLayout>
  );
}
