import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { INTEGRATION_TYPE_LABEL_KEYS } from '../../../../../../../../../constants';

type SaasPanelNoDataAlertProps = {
  integrationType: IntegrationType;
};

export function SaasPanelNoDataAlert({
  integrationType,
}: SaasPanelNoDataAlertProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Alert>
      {t(
        `sessions.panels.${INTEGRATION_TYPE_LABEL_KEYS[integrationType]}.noItemsAlert`,
      )}
    </Alert>
  );
}
