import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Alert } from 'dcloud-shared-ui';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../../../components/Buttons/PrimaryButton';
import { vmNetworkInterfaceFieldsSchema } from '../../../../../../../../../../validation';
import { getNetworkOptions } from '../../../../../Configure/Utils';
import {
  findInventoryNetwork,
  findNetworkName,
  getNicTypeOptions,
  hasNicLimitReached,
} from '../../utils';
import { TemplateToastToAdd } from '../../../../../../../../../../redux/toast/slice';
import { NicLimitError } from '../NicLimitError';
import { DEFAULT_NETWORK_MAX_CONNECTIONS } from '../../../../../../../constants';
import styles from './NetworkingForm.module.css';

export interface NetworkingFormData {
  assignDhcp?: boolean;
  ipAddress?: string;
  macAddress?: string;
  networkName: string;
  type: string;
}

interface NetworkingFormProps {
  addVMNetworkInterface: ActionCreatorWithPayload<
    VmNetworkInterfaceToEdit,
    string
  >;
  addErrorToast: ActionCreatorWithPayload<TemplateToastToAdd, string>;
  networks: Network[];
  nicTypes: VmNetworkInterfaceType[];
  totalNics: number;
  vmNetworkInterfaces?: VmNetworkingToEdit;
  vmToEdit?: VirtualMachine;
  limits: Limits;
}

export function NetworkingForm({
  addVMNetworkInterface,
  addErrorToast,
  networks,
  nicTypes,
  totalNics,
  vmNetworkInterfaces,
  vmToEdit,
  limits,
}: NetworkingFormProps): ReactElement {
  const { t } = useTranslation();
  const [isNwLimitReached, setIsNwLimitReached] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(0);
  const [subnet, setSubnet] = useState<string>('');
  const formSectionClasses = classnames('col-2', styles.formSection);
  const checkBoxClasses = classnames('col-2', styles.checkboxGroup);
  const buttonGroupClasses = classnames('col-4', styles.formSection);

  const { control, errors, handleSubmit, reset, formState, trigger, setValue } =
    useForm<NetworkingFormData>({
      defaultValues: {
        assignDhcp: true,
        ipAddress: '',
        macAddress: '',
        networkName: '',
        type: '',
      },
      mode: 'all',
      resolver: yupResolver(
        vmNetworkInterfaceFieldsSchema(limits, subnet, networks),
      ),
    });

  const onAddClicked: SubmitHandler<NetworkingFormData> = async (formData) => {
    if (totalNics >= 10) {
      addErrorToast({
        message: t('virtualMachines.edit.networking.maxLimit'),
      });
      return;
    }
    const networkName = findNetworkName(networks, formData);
    const inventoryNetwork = findInventoryNetwork(networks, formData);

    if (networkName) {
      const formDataWithTempUid = {
        ...formData,
        inUse: false,
        network: {
          inventoryNetwork: inventoryNetwork,
          name: networkName,
          uid: formData.networkName,
        },
        uid: `temp-${v4()}`,
      };

      addVMNetworkInterface(formDataWithTempUid);
      reset();
    }
  };
  const { isDirty, isValid } = formState;

  useEffect(() => {
    triggerNicLimitCheck(control.getValues().networkName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vmNetworkInterfaces?.length]);

  const triggerNicLimitCheck = (selectedNwUid: string) => {
    if (selectedNwUid) {
      const savedNics = vmToEdit?.vmNetworkInterfaces || [];
      const nicsToSave = vmNetworkInterfaces || [];
      const selectedNetwork = networks.find((nw) => nw.uid === selectedNwUid);

      const limitReached = hasNicLimitReached(
        savedNics,
        nicsToSave,
        selectedNetwork!,
        false,
      );
      setIsNwLimitReached(limitReached);
      if (limitReached) {
        setLimit(
          selectedNetwork?.vmConnectionInfo?.maxConnections ||
            DEFAULT_NETWORK_MAX_CONNECTIONS,
        );
      }

      selectedNetwork
        ? setSubnet(selectedNetwork.inventoryNetwork.subnet)
        : setSubnet('');
    }
  };

  const handleDhcpChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await setValue('assignDhcp', e.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await setValue('ipAddress', control.getValues().ipAddress, {
      shouldDirty: true,
      shouldValidate: true,
    });
    trigger('ipAddress');
  };

  const showError = isNwLimitReached && !!control.getValues().networkName;
  return (
    <>
      <div aria-label="VM Edit Networking form" className={styles.root}>
        <div className={formSectionClasses}>
          <ControlledSelect
            className={showError ? styles.errorSelect : ''}
            control={control}
            label={t('virtualMachines.edit.networking.network')}
            horizontal={false}
            options={getNetworkOptions(networks)}
            name="networkName"
            loading={false}
            testId="edit-vm-networking-nic-network-select"
            required={true}
            error={errors?.networkName?.message}
            customOnChange={(event) => triggerNicLimitCheck(event.target.value)}
          />
        </div>
        <div className={formSectionClasses}>
          <ControlledSelect
            control={control}
            label={t('virtualMachines.edit.networking.type')}
            horizontal={false}
            options={getNicTypeOptions(nicTypes)}
            name="type"
            loading={false}
            testId="edit-vm-networking-nic-type-select"
            required={true}
            error={errors?.type?.message}
          />
        </div>
        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.macAddress?.message}
            label={t('virtualMachines.edit.networking.macAddress')}
            name="macAddress"
            required={false}
            testId="edit-vm-networking-mac-address-input"
          />
        </div>
        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.ipAddress?.message}
            label={t('virtualMachines.edit.networking.ipAddress')}
            name="ipAddress"
            required={control.getValues().assignDhcp}
            testId="edit-vm-networking-ip-address-input"
          />
        </div>
        <div className={checkBoxClasses}>
          <p>{t('virtualMachines.edit.networking.dhcp.title')} </p>
          <ControlledCheckbox
            className={styles.checkbox}
            control={control}
            customOnChange={async (e) => handleDhcpChange(e)}
            id="assignDhcp"
            label=""
            testId="edit-vm-networking-enable-dhcp"
          />
        </div>
        <div className={buttonGroupClasses}>
          <PrimaryButton
            className={styles.button}
            type="button"
            disabled={!isDirty || !isValid || showError}
            onClick={handleSubmit(onAddClicked)}
            testId="edit-vm-networking-add-button"
          >
            {t('buttons.add')}
          </PrimaryButton>
        </div>
      </div>
      {showError && <NicLimitError limit={limit} />}

      <Alert colour="info">
        {t('virtualMachines.edit.networking.warnings.changesInfo')}
      </Alert>
    </>
  );
}
