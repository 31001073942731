import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './history';
import { entitiesReducer } from './entities/reducer';
import { toastReducer } from './toast/slice';
import { configurationReducer } from './configuration/reducer';
import { topologiesReducer } from './topologies/slice';
import { dcloudApi } from './api';
import { viewsReducer } from './views/slice';
import { authReducer } from './auth/slice';
import { sessionInventoryReducer } from './views/session-view/session-inventory/slice';
import { sessionViewReducer } from './views/session-view/session/slice';
import { templateManagerReducer } from './templateManager/slice';
import { CurrentUserReducer } from './configuration/currentUser/slice';
import { topologiesFiltersReducer } from './topologiesFilters/slice';
import { videoReducer } from './video/slice';
import { scenariosReducer } from './scenarios/slice';
import { etagCollectionsReducer } from './configuration/etagCollection/slice';
import { integrationReducer } from './integrations/slice';
import { integrationsFiltersReducer } from './integrationsFilter/slice';
import { diagramReducer } from './diagram/slice';
import { logicalConnectionsReducer } from './logicalConnections/slice';

export const rootReducer = combineReducers({
  [dcloudApi.reducerPath]: dcloudApi.reducer,
  auth: authReducer,
  configuration: configurationReducer,
  currentUser: CurrentUserReducer,
  diagram: diagramReducer,
  entities: entitiesReducer,
  etagCollections: etagCollectionsReducer,
  integrations: integrationReducer,
  integrationsFilters: integrationsFiltersReducer,
  logicalConnections: logicalConnectionsReducer,
  router: connectRouter(history),
  scenarios: scenariosReducer,
  sessionInventory: sessionInventoryReducer,
  sessionView: sessionViewReducer,
  templateManager: templateManagerReducer,
  toast: toastReducer,
  topologies: topologiesReducer,
  topologiesFilters: topologiesFiltersReducer,
  video: videoReducer,
  views: viewsReducer,
});
