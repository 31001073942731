import { dcloudApi } from '../api';
import i18n from '../../i18n';
import { LIMITATIONS_PATH } from '../../api/paths';

export const limitationsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getLimits: build.query<Limits, undefined>({
      keepUnusedDataFor: 0,
      query: () => ({
        errorMessage: i18n.t('general.fetch.error'),
        method: 'get',
        url: `${LIMITATIONS_PATH}`,
      }),
    }),
  }),
});

export const { useGetLimitsQuery } = limitationsApi;
