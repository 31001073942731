import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import {
  willCpuLimitBeExceeded,
  willMemoryLimitBeExceeded,
} from '../../../../../utils';

type UseShowLimitsWarningAddVmReturn = {
  onAddClicked: VoidFunction;
};

export type UseShowLimitsWarningAddVmProps = {
  topologyLimitsWarningAccepted?: boolean;
  resetTopologyLimits: VoidFunction;
  addVm: () => void;
  vm?: InventoryVirtualMachine;
  topologyLimits?: TopologyLimits;
  setShowTopologyLimitWarningForEntity: ActionCreatorWithOptionalPayload<
    | TopologyLimitsDisplayLabels
    | Array<TopologyLimitsDisplayLabels>
    | undefined,
    string
  >;
};

export function useShowTopologyLimitsWarningAddVm({
  topologyLimitsWarningAccepted,
  resetTopologyLimits,
  addVm,
  vm,
  topologyLimits,
  setShowTopologyLimitWarningForEntity,
}: UseShowLimitsWarningAddVmProps): UseShowLimitsWarningAddVmReturn {
  useEffect(() => {
    if (topologyLimitsWarningAccepted) {
      resetTopologyLimits();
      addVm();
    }
  }, [addVm, resetTopologyLimits, topologyLimitsWarningAccepted]);

  const onAddClicked = () => {
    if (!vm || !topologyLimits) {
      addVm();
      resetTopologyLimits();
      return;
    }

    const memoryLimitExceeded = willMemoryLimitBeExceeded(
      vm,
      topologyLimits.memoryMb,
    );
    const cpuLimitExceeded = willCpuLimitBeExceeded(
      vm,
      topologyLimits.cpuCores,
    );

    switch (memoryLimitExceeded) {
      case true:
        cpuLimitExceeded
          ? setShowTopologyLimitWarningForEntity(['Memory (GB)', 'vCPUs'])
          : setShowTopologyLimitWarningForEntity('Memory (GB)');
        break;
      case false:
        cpuLimitExceeded
          ? setShowTopologyLimitWarningForEntity('vCPUs')
          : addVm();
        break;
      default:
        addVm();
        resetTopologyLimits();
        break;
    }
  };

  return { onAddClicked };
}
