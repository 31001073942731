import { connect } from 'react-redux';
import { RowNode } from 'ag-grid-community';
import { setInventorySaasTemplateToConfigure } from '../../../../../../../redux/configuration/saas/slice';
import { RootState } from '../../../../../../../redux/store';
import { AddCellRenderer } from '.';

const mapDispatchToProps = {
  setInventorySaasTemplateToConfigure,
};

type OwnProps = {
  data: RowNode['data'];
};

const mapStateToProps = (_: RootState, ownProps: OwnProps) => ({
  inventorySaasTemplate: ownProps.data as SaasTemplateWithConfiguredQuantity,
});

export const AddCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCellRenderer);

export type AddCellRendererProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
