import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderDots } from 'dcloud-shared-ui';
import { TableLoader } from '../../../../../../../../components/Table/TableLoader';
import { PanelGrid } from '../../../PanelGrid';
import { InfoProps } from './container';
import './Info.module.css';

export function Info({
  staticVmInfo,
  dynamicVmInfo,
  isLoadingDynamicVmInfo,
}: InfoProps): ReactElement {
  const { t } = useTranslation();

  if (!staticVmInfo) {
    return <TableLoader />;
  }

  const { name, description, memory, vCPUs, osFamily } = staticVmInfo;

  const panelGridData: PanelGridDataItem[] = [
    {
      key: 'name',
      name: t('sessions.panels.vm.info.name'),
      value: name,
    },
    {
      key: 'description',
      name: t('sessions.panels.vm.info.description'),
      value: description,
    },
    {
      key: 'osFamily',
      name: t('sessions.panels.vm.info.osFamily'),
      value: osFamily,
    },
    {
      key: 'vCPUs',
      name: t('sessions.panels.vm.info.vCPUs'),
      value: vCPUs,
    },
    {
      key: 'memory',
      name: t('sessions.panels.vm.info.memory'),
      value: memory,
    },
    {
      key: 'guestOs',
      name: t('sessions.panels.vm.info.guestOs'),
      value:
        isLoadingDynamicVmInfo || !dynamicVmInfo ? (
          <LoaderDots />
        ) : (
          dynamicVmInfo.guestOs
        ),
    },
    {
      key: 'vmWareTools',
      name: t('sessions.panels.vm.info.vmWareTools'),
      value:
        isLoadingDynamicVmInfo || !dynamicVmInfo ? (
          <LoaderDots />
        ) : (
          dynamicVmInfo.vmWareTools
        ),
    },
  ];

  return <PanelGrid data={panelGridData} wideCol="value" nameBold={true} />;
}
