export const INTERVAL_END: Duration = {
  days: 0,
  hours: 0,
  minutes: 0,
  months: 0,
  seconds: 0,
  years: 0,
};

export const EXPIRED_TIMER_VALUE = '00:00:00';

export enum SESSION_VM_ACTIONS {
  GUEST_OS_REBOOT = 'OS Reboot',
  GUEST_OS_SHUTDOWN = 'OS Shutdown',
  VM_POWER_ON = 'VM Power On',
  VM_POWER_OFF = 'VM Power Off',
  VM_RESET = 'VM Reset',
}

export const SESSION_NAV_BUTTON_TEST_ID =
  'session-view-navigation-button-session';
export const SESSION_INVENTORY_NAV_BUTTON_TEST_ID =
  'session-view-navigation-button-inventory';
export const SESSION_INVENTORY_SIDE_TAB_HARDWARE_TEST_ID =
  'session-inventory-side-tab-hardware-Hardware';
