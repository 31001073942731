import {
  DATACENTERS_PATH,
  METADATA_ARCHITECTURES,
  TEMPLATED_VMS,
  VM_TEMPLATES,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { clearGivenEtag } from '../../configuration/etagCollection/slice';
import {
  setAllArchitectures,
  setSelectedArchitectures,
  setSelectedInventoryItem,
} from '../slice';
import { TAGS } from '../../api/constants';

export const vmTemplateApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createVmTemplate: build.mutation<VmTemplate, CreateVmTemplatePayload>({
      invalidatesTags: [TAGS.VIRTUAL_MACHINE_TEMPLATES],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          return;
        } finally {
          dispatch(setSelectedArchitectures(undefined));
        }
      },
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('templateManager.vmTemplates.create.error'),
        method: 'POST',
        successMessage: i18n.t('templateManager.vmTemplates.create.success'),
        url: `${VM_TEMPLATES}`,
      }),
    }),
    deleteVmTemplate: build.mutation<undefined, string>({
      invalidatesTags: [TAGS.VIRTUAL_MACHINE_TEMPLATES],
      query: (templateName) => ({
        errorMessage: i18n.t('templateManager.vmTemplates.delete.error'),
        method: 'delete',
        successMessage: i18n.t('templateManager.vmTemplates.delete.success'),
        url: `${VM_TEMPLATES}/${templateName}`,
      }),
    }),
    getAllArchitectures: build.query<ArchitechtureResponse, undefined>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAllArchitectures(data.architectures));
        } catch (err) {
          throw err;
        }
      },
      providesTags: [TAGS.ARCHITECTURES],
      query: () => ({
        errorMessage: i18n.t('templateManager.metadata.fetch.error'),
        method: 'get',
        url: `${METADATA_ARCHITECTURES}`,
      }),
    }),
    getAllVmTemplates: build.query<TemplatedInventoryVm[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VIRTUAL_MACHINE_TEMPLATES],
      query: (dataCenter) => ({
        errorMessage: i18n.t('templateManager.vmTemplates.fetch.errors.many'),
        method: 'get',
        url: `${DATACENTERS_PATH}/${dataCenter.toLocaleLowerCase()}${TEMPLATED_VMS}`,
      }),
      transformResponse: (response: VmTemplateResponse) =>
        response.templatedInventoryVms,
    }),
    getVmTemplate: build.query<VmTemplate, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSelectedArchitectures(data.architectures));
        } catch (err) {
          throw err;
        }
      },
      providesTags: [TAGS.VIRTUAL_MACHINE_TEMPLATE],
      query: (templateName) => ({
        errorMessage: i18n.t('templateManager.vmTemplates.fetch.errors.one'),
        method: 'get',
        url: `${VM_TEMPLATES}/${templateName}`,
      }),
    }),
    updateVmTemplate: build.mutation<VmTemplate, VmTemplateUpdatePayload>({
      invalidatesTags: [TAGS.VIRTUAL_MACHINE_TEMPLATES, TAGS.VIRTUAL_MACHINE],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSelectedInventoryItem(data));
          dispatch(clearGivenEtag('/vm-templates/'));
        } catch (err) {
          throw err;
        }
      },
      query: (payload) => ({
        data: payload.vmTemplate,
        errorMessage: i18n.t('templateManager.vmTemplates.update.error'),
        method: 'PUT',
        overrideEtag: payload.overrideEtag,
        successMessage: i18n.t('templateManager.vmTemplates.update.success'),
        url: `${VM_TEMPLATES}/${payload.templateId}`,
      }),
    }),
  }),
});

export const {
  useCreateVmTemplateMutation,
  useGetAllArchitecturesQuery,
  useGetAllVmTemplatesQuery,
  useGetVmTemplateQuery,
  useUpdateVmTemplateMutation,
  useDeleteVmTemplateMutation,
} = vmTemplateApi;
