import React, { ReactElement } from 'react';
import { ArchitectureLabel } from '../ArchitectureLabel';
import styles from './ArchitectureLabelGroup.module.css';

export type ArchitectureLabelGroupProps = {
  architectures: Architecture[] | string[];
  onDismiss?: (uid: string) => void;
  hideMissing?: boolean;
};

function isArchitectures(
  architectures: Architecture[] | string[],
): architectures is Architecture[] {
  return (architectures as Architecture[])[0].uid !== undefined;
}

export const ArchitectureLabelGroup = ({
  architectures,
  onDismiss,
  hideMissing = false,
}: ArchitectureLabelGroupProps): ReactElement => {
  if (architectures.length < 1) {
    return <div className={styles.container}></div>;
  }

  if (!isArchitectures(architectures)) {
    return (
      <>
        {architectures.map((architecture) => {
          return (
            <ArchitectureLabel
              key={architecture}
              architecture={architecture}
              hideMissing={hideMissing}
            />
          );
        })}
      </>
    );
  }

  return (
    <div className={styles.container}>
      {architectures.map((architecture, index) => {
        return onDismiss ? (
          <ArchitectureLabel
            key={architecture.uid}
            architecture={architecture}
            onDismiss={() => onDismiss(architecture.uid)}
            hideMissing={hideMissing}
          />
        ) : (
          <ArchitectureLabel
            key={index}
            architecture={architecture}
            hideMissing={hideMissing}
          />
        );
      })}
    </div>
  );
};
