import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Button } from 'dcloud-shared-ui';
import { isEqual } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../../components/Accordion';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../../../../components/FormUnsavedChangesPrompt';
import { PageHeading } from '../../../../../../../components/PageHeading';
import { deepClone } from '../../../../../../../utils/helpers';
import { templatedVmEditFieldsSchema } from '../../../../../../../validation';
import { AdvancedSettingsForm } from '../../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/AdvancedSettingsForm';
import { GeneralForm } from '../../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/GeneralForm';
import { GuestAutomationForm } from '../../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/GuestAutomationForm';
import { ConnectedRemoteAccessForm } from '../../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/RemoteAccessForm/container';
import {
  addVMNetworkInterfacesToPayload,
  convertMbToGb,
  prepareRDPForPayload,
  prepareSSHForPayload,
  removeDhcpConfigStringsWithNulls,
  removeDisplayCredentialsIfDisabled,
  removeDisplayDifferentCredentials,
  removeEmptyBios,
  removeGuestAutomationIfDisabled,
  removeIsGuestAutomationEnabled,
  removeIsShutdownAutomationEnabled,
  removeLinks,
  removeMemoryGb,
  removeShutdownAutomationIfDisabled,
  removeUnusedInternalUrls,
  replaceRAEmptyStringsWithNulls,
} from '../../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/utils';
import { REMOTE_SHUTDOWN_DEFAULT_DELAY } from '../../../../../../../utils/constants';
import { useLeaveFormPrompt } from '../../../../../../EditTopology/hooks/useLeaveFormPrompt';
import { ConnectedTemplateNetworking } from '../../TemplateNetworks/container';
import { prepTemplateDataPayload } from '../../../utils';
import { VmTemplateForm } from '../../VmTemplateForm';
import styles from './form.module.css';

interface TemplateVmFormProps {
  addSelectedArchitecture: ActionCreatorWithPayload<Architecture, string>;
  allArchitectures: Architecture[];
  selectedArchitectures?: Architecture[];
  operatingSystems: OperatingSystem[];
  isUpdating: boolean;
  currentDc?: DataCenter;
  updateVM: RTKMutation<VmToUpdatePayload, VmToUpdatePayload>;
  removeArchitecture: ActionCreatorWithPayload<string, string>;
  vmToEdit: VirtualMachine;
  vmNetworkingToEdit?: VmNetworkingToEdit;
  rdpEnabledNic?: VMNetworkInterface;
  templateToEdit: VmTemplate;
  updateVmTemplate: RTKMutation<VmTemplateUpdatePayload, VmTemplate>;
  setNetworkingToEdit: (networkingToEdit: VmNetworkInterfacesToEdit) => void;
  setSelectedArchitectures: (architectures: Architecture[]) => void;
  sshEnabledNic?: VMNetworkInterface;
  vmEtag?: string;
  templateEtag?: string;
}

export function TemplateVmForm({
  operatingSystems,
  isUpdating,
  currentDc,
  removeArchitecture,
  addSelectedArchitecture,
  allArchitectures,
  setNetworkingToEdit,
  selectedArchitectures,
  updateVM,
  updateVmTemplate,
  templateToEdit,
  vmToEdit,
  vmNetworkingToEdit,
  setSelectedArchitectures,
  rdpEnabledNic,
  sshEnabledNic,
  vmEtag,
  templateEtag,
}: TemplateVmFormProps): ReactElement {
  const { t } = useTranslation();

  const vmFormMethods = useForm<TemplateVmEditFormData>({
    defaultValues: {
      advancedSettings: {
        allDisksNonPersistent: vmToEdit.advancedSettings.allDisksNonPersistent,
        biosUuid: vmToEdit.advancedSettings.biosUuid || '',
        hiddenFromSession: false,
        nameInHypervisor: vmToEdit.advancedSettings.nameInHypervisor || '',
        notStarted: vmToEdit.advancedSettings.notStarted,
      },
      architecture: selectedArchitectures || [],
      contact: templateToEdit.contact || '',
      cpuQty: vmToEdit.cpuQty,
      description: vmToEdit.description,
      dhcpConfig: {
        defaultGatewayIp: vmToEdit.dhcpConfig?.defaultGatewayIp || '',
        primaryDnsIp: vmToEdit.dhcpConfig?.primaryDnsIp || '',
        secondaryDnsIp: vmToEdit.dhcpConfig?.secondaryDnsIp || '',
      },
      displayDifferentCredentials:
        'displayCredentials' in vmToEdit.remoteAccess,
      guestAutomation: {
        command: vmToEdit.guestAutomation?.command || '',
        delaySecs: vmToEdit.guestAutomation?.delaySecs || 30,
      },
      icon: '',
      isGuestAutomationEnabled: 'guestAutomation' in vmToEdit,
      isShutdownAutomationEnabled: 'shutdownAutomation' in vmToEdit,
      licensed: templateToEdit.licensed ? 'yes' : 'no',
      managementIp: templateToEdit.managementIp || '',
      memoryGb: convertMbToGb(vmToEdit.memoryMb, { roundCeil: false }),
      memoryMb: vmToEdit.memoryMb,
      name: vmToEdit.name,
      nestedHypervisor: vmToEdit.nestedHypervisor,
      osFamily: vmToEdit.osFamily || 'NONE',
      password: templateToEdit.password || '',
      remoteAccess: {
        displayCredentials: {
          password: vmToEdit.remoteAccess.displayCredentials?.password || '',
          username: vmToEdit.remoteAccess.displayCredentials?.username || '',
        },
        internalUrls: [
          {
            description:
              vmToEdit.remoteAccess.internalUrls?.[0]?.description || '',
            location: vmToEdit.remoteAccess.internalUrls?.[0]?.location || '',
          },
          {
            description:
              vmToEdit.remoteAccess.internalUrls?.[1]?.description || '',
            location: vmToEdit.remoteAccess.internalUrls?.[1]?.location || '',
          },
        ],
        password: vmToEdit.remoteAccess.password || '',
        rdp: {
          autoLogin: rdpEnabledNic?.rdp?.autoLogin || false,
          vmNetworkInterface: {
            isEnabled: rdpEnabledNic?.rdp?.enabled || false,
            uid: rdpEnabledNic?.uid || '',
          },
        },
        ssh: {
          vmNetworkInterface: {
            isEnabled: sshEnabledNic?.ssh?.enabled || false,
            uid: sshEnabledNic?.uid || '',
          },
        },
        username: vmToEdit.remoteAccess.username || '',
        vmConsoleEnabled: vmToEdit.remoteAccess.vmConsoleEnabled,
      },
      shutdownAutomation: {
        command: vmToEdit.shutdownAutomation?.command || '',
        delaySecs:
          vmToEdit.shutdownAutomation?.delaySecs ||
          REMOTE_SHUTDOWN_DEFAULT_DELAY,
      },
      templateDescription: templateToEdit.description || '',
      templateName: templateToEdit.name || '',
      templateVersion: templateToEdit.templateVersion || '',
      userEnabled: templateToEdit.userEnabled || false,
      userName: templateToEdit.username || '',
    },
    mode: 'all',
    resolver: yupResolver(
      templatedVmEditFieldsSchema({ hypervisorNames: [], names: [] }),
    ),
  });

  const { dirtyFields: dirtyVmFields, isValid: isValidVm } =
    vmFormMethods.formState;

  const updateClicked: SubmitHandler<VmEditFormData> = async (formData) => {
    const vmToEditCopy = deepClone(vmToEdit);
    const formDataCopy = deepClone(formData);

    let vmToUpdatePayload: VmToUpdatePayload = {
      ...vmToEditCopy,
      ...formDataCopy,
      etag: vmEtag,
      osFamily: formData.osFamily === 'NONE' ? undefined : formData.osFamily,
    };
    removeLinks(vmToUpdatePayload);
    removeDhcpConfigStringsWithNulls(vmToUpdatePayload);
    removeGuestAutomationIfDisabled(vmToUpdatePayload);
    removeShutdownAutomationIfDisabled(vmToUpdatePayload);
    removeEmptyBios(vmToUpdatePayload);
    removeIsGuestAutomationEnabled(vmToUpdatePayload);
    removeIsShutdownAutomationEnabled(vmToUpdatePayload);
    removeDisplayCredentialsIfDisabled(vmToUpdatePayload);
    removeDisplayDifferentCredentials(vmToUpdatePayload);
    replaceRAEmptyStringsWithNulls(vmToUpdatePayload);
    removeMemoryGb(vmToUpdatePayload);
    prepareRDPForPayload(vmToUpdatePayload);
    prepareSSHForPayload(vmToUpdatePayload);
    vmToUpdatePayload = removeUnusedInternalUrls(vmToUpdatePayload);
    if (vmNetworkingToEdit) {
      vmToUpdatePayload = addVMNetworkInterfacesToPayload(
        vmToUpdatePayload,
        JSON.parse(JSON.stringify(vmNetworkingToEdit)),
      );
    }
    await updateVM(vmToUpdatePayload);
    const payload = prepTemplateDataPayload(
      formData,
      templateToEdit,
      currentDc!,
      selectedArchitectures,
    );
    await updateVmTemplate({
      overrideEtag: templateEtag!,
      templateId: templateToEdit.uid,
      vmTemplate: payload,
    });
    vmFormMethods.reset(formData);
  };

  const [hasMissingArchitecture, setHasMissingArchitecture] = useState(false);
  const handleOnChange = useCallback(() => {
    if (selectedArchitectures?.some((item) => !item.name)) {
      setHasMissingArchitecture(true);
    } else {
      setHasMissingArchitecture(false);
    }
  }, [selectedArchitectures]);

  useEffect(() => {
    handleOnChange();
  }, [handleOnChange, selectedArchitectures]);

  const archiAreEqual = isEqual(
    selectedArchitectures,
    templateToEdit.architectures,
  );
  const hasFormChanged =
    !!Object.keys(dirtyVmFields).length ||
    !isEqual(vmToEdit.vmNetworkInterfaces, vmNetworkingToEdit) ||
    !archiAreEqual;

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(hasFormChanged);

  const isUpdateTemplateDisabled =
    ((!hasFormChanged || !isValidVm) && archiAreEqual) ||
    hasMissingArchitecture;

  const disableUpdateButtonVm = !isValidVm || !hasFormChanged;
  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: (
          <VmTemplateForm
            addSelectedArchitecture={addSelectedArchitecture}
            allArchitectures={allArchitectures}
            selectedArchitectures={selectedArchitectures}
            removeArchitecture={removeArchitecture}
            hasMissingArchitecture={hasMissingArchitecture}
            lastUpdated={templateToEdit.lastUpdated}
            isEdit={true}
          />
        ),
        title: t('templateManager.vmTemplates.form.titles.templateData'),
      },
      {
        children: <GeneralForm operatingSystems={operatingSystems} />,
        title: t('virtualMachines.edit.sectionTitles.general'),
      },
      {
        children: <ConnectedTemplateNetworking />,
        title: t('virtualMachines.edit.sectionTitles.networking'),
      },
      {
        children: <ConnectedRemoteAccessForm />,
        title: t('virtualMachines.edit.sectionTitles.remoteAccess'),
      },
      {
        children: <GuestAutomationForm />,
        title: t('virtualMachines.edit.sectionTitles.guestAutomation'),
      },
      {
        children: <AdvancedSettingsForm hideDiagramFields={true} />,
        title: t('virtualMachines.edit.sectionTitles.advancedSettings'),
      },
    ],
    [
      addSelectedArchitecture,
      allArchitectures,
      selectedArchitectures,
      removeArchitecture,
      hasMissingArchitecture,
      operatingSystems,
      templateToEdit.lastUpdated,
      t,
    ],
  );

  const onResetClick = () => {
    vmFormMethods.reset();
    setNetworkingToEdit(vmToEdit.vmNetworkInterfaces);
    setSelectedArchitectures(templateToEdit.architectures || []);
  };

  return (
    <section className="half-padding-left">
      <PageHeading
        ctaBtn={
          <div className={styles.wrapper}>
            <Button
              disabled={!hasFormChanged}
              type="button"
              testId="vm-edit-form-reset-button"
              className={classNames(
                styles.reset,
                'btn btn--ghost btn--wide btn--large',
              )}
              onClick={onResetClick}
            >
              {t('userPreferences.reset')}
            </Button>
            <PrimaryButton
              type="submit"
              colour="success"
              form="vm-template-form"
              disabled={isUpdateTemplateDisabled || disableUpdateButtonVm}
              onClick={vmFormMethods.handleSubmit(updateClicked)}
              loading={isUpdating}
            >
              {t('buttons.save')}
            </PrimaryButton>
          </div>
        }
        pageTitle={t('templateManager.vmTemplates.form.titles.edit')}
        withBackBtn={true}
      />
      <FormProvider {...vmFormMethods}>
        <Accordion rows={accordionRows} />
        <FormUnsavedChangesPrompt
          showModal={showModal}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      </FormProvider>
    </section>
  );
}
