import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../../components';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { MailServerTableActions } from './TableActions';

type MailServersTableProps = {
  isError: boolean;
  isLoading: boolean;
  mailServers: MailServer[];
  setMailServerToDelete: (mailServer: MailServer) => void;
};

export function MailServersTable({
  isError,
  isLoading,
  mailServers,
  setMailServerToDelete,
}: MailServersTableProps): ReactElement | null {
  const { t } = useTranslation();

  const columns = useMemo<Column<MailServer>[]>(
    () => [
      {
        Header: () => t('traffic.mailServer.table.headings.virtualMachine'),
        accessor: ({ vmNicTarget }) => vmNicTarget.vm?.name,
        id: 'virtualMachine',
        width: 50,
      },
      {
        Header: () => t('traffic.mailServer.table.headings.ipAddress'),
        accessor: ({ vmNicTarget }) => vmNicTarget.ipAddress,
        id: 'ipAddress',
        width: 50,
      },
      {
        Header: () => t('traffic.mailServer.table.headings.asset'),
        accessor: ({ inventoryDnsAsset }) =>
          inventoryDnsAsset ? inventoryDnsAsset.name : '',
        id: 'asset',
        width: 50,
      },
    ],
    [t],
  );

  const renderActions = (mailServer: MailServer): ReactNode => {
    return (
      <MailServerTableActions
        onDeleteClick={() => setMailServerToDelete(mailServer)}
      />
    );
  };

  if (isError || isLoading || !mailServers) {
    return <TableLoader />;
  }

  return (
    <Table<MailServer>
      actions={renderActions}
      data={mailServers}
      columns={columns}
    />
  );
}
