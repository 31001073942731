import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../components';
import { DocumentationForm } from './components/DocumentationForm';
import { DocumentationFormSkeleton } from './components/DocumentationFormSkeleton';
import { DocumentationProps } from './container';
import styles from './Documentation.module.css';

export function Documentation({
  isLoading,
  updateDocumentation,
  documentation,
}: DocumentationProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="half-padding-left">
      <div className="dbl-margin-bottom">
        <PageHeading text={t('documentation.name')} />
      </div>
      <div className={styles.container}>
        {!isLoading && documentation ? (
          <DocumentationForm
            documentation={documentation}
            updateDocumentation={updateDocumentation}
          />
        ) : (
          <DocumentationFormSkeleton />
        )}
      </div>
    </div>
  );
}
