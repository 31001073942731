export const prepTemplatesForTable = (
  inventoryTemplates: InventoryTopology[],
): InventoryTopology[] =>
  inventoryTemplates?.map((inventoryTemplate) => ({
    ...inventoryTemplate,
    architectureNames: inventoryTemplate.architectures?.map(
      (architecture: Architecture) => architecture.name || '',
    ),
    description:
      inventoryTemplate.description || inventoryTemplate.originalDescription,
    name: inventoryTemplate.name || inventoryTemplate.originalName,
  })) || [];
