import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  updateInput,
  updateInputStatus,
} from '../../../../redux/integrations/slice';
import IntegrationInput from '.';

const mapDispatchToProps = {
  updateInput,
  updateInputStatus,
};

type ConnectedIntegrationInputToEditProps = {
  input: SaasTemplateInput;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedIntegrationInputToEditProps,
) => ({
  input: ownProps.input,
});

type IntegrationInputContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

function IntegrationInputToEditContainer({
  input,
  updateInput,
  updateInputStatus,
}: IntegrationInputContainerProps): ReactElement {
  return (
    <IntegrationInput
      input={input}
      updateInput={updateInput}
      updateInputStatus={updateInputStatus}
    />
  );
}

export const ConnectedIntegrationInputToEdit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationInputToEditContainer);
