import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  reset,
  setLicenceToEdit,
  setLicenceToDelete,
  setShowInventoryModal,
  getLicences,
} from '../../../../redux/configuration/licences/slice';
import { RootState } from '../../../../redux/store';
import {
  useDeleteLicenceMutation,
  useGetLicencesQuery,
} from '../../../../redux/configuration/licences/api';
import { Licences } from '.';

function LicencesContainer({
  licenceToDelete,
  licenceToEdit,
  showInventoryModal,
  reset,
  setLicenceToDelete,
  setLicenceToEdit,
  setShowInventoryModal,
}: LicencesReduxProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    data,
    isError: isErrorFetching,
    isLoading,
  } = useGetLicencesQuery(topologyUid);

  const [deleteLicence, { isLoading: isDeleting }] = useDeleteLicenceMutation();

  return (
    <Licences
      isDeleting={isDeleting}
      isErrorFetching={isErrorFetching}
      licences={data?.licenses}
      deleteLicence={deleteLicence}
      licenceToDelete={licenceToDelete}
      licenceToEdit={licenceToEdit}
      showInventoryModal={showInventoryModal}
      reset={reset}
      setLicenceToDelete={setLicenceToDelete}
      setShowInventoryModal={setShowInventoryModal}
      setLicenceToEdit={setLicenceToEdit}
      isLoading={isLoading}
    />
  );
}

interface LicencesRTKProps {
  licences?: Licence[];
  isDeleting: boolean;
  isErrorFetching: boolean;
  isLoading: boolean;
  deleteLicence: RTKMutation<LicenceUid, undefined>;
}

export type LicencesReduxProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export type LicencesProps = LicencesReduxProps & LicencesRTKProps;

const mapStateToProps = (state: RootState) => ({
  licenceToDelete: getLicences(state).licenceToDelete,
  licenceToEdit: getLicences(state).licenceToEdit,
  showInventoryModal: getLicences(state).showInventoryModal,
});

const mapDispatchToProps = {
  reset,
  setLicenceToDelete,
  setLicenceToEdit,
  setShowInventoryModal,
};

export const ConnectedLicences = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LicencesContainer);
