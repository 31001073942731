import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import { useFetchAllHardwareQuery } from '../../../../../redux/configuration/hardware/api';
import {
  getHardware,
  setHardwareToDelete,
} from '../../../../../redux/configuration/hardware/slice';
import { RootState } from '../../../../../redux/store';
import { All } from './index';

function AllHardwareContainer({
  hardwareToDelete,
  setHardwareToDelete,
}: AllHardwareContainerProps): ReactElement {
  const { uid } = useParams<EditTopologyParams>();

  const {
    data: allHardware,
    isLoading,
    isError,
  } = useFetchAllHardwareQuery(uid);

  if (!allHardware || isLoading) {
    return <TableLoader />;
  }

  return (
    <All
      isLoading={isLoading}
      hardware={allHardware}
      hardwareToDelete={hardwareToDelete}
      setHardwareToDelete={setHardwareToDelete}
      isError={isError}
    />
  );
}

type AllHardwareContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

interface AllHardwareRTKProps {
  hardware: Hardware[];
  isError: boolean;
  isLoading: boolean;
}

export type AllHardwareProps = AllHardwareContainerProps & AllHardwareRTKProps;

const mapStateToProps = (state: RootState) => ({
  hardwareToDelete: getHardware(state).hardwareToDelete,
});

const mapDispatchToProps = {
  setHardwareToDelete,
};

export const ConnectedAllHardware = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllHardwareContainer);
