import { Textfield } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './ReadonlyField.module.css';

interface ReadonlyFieldProps {
  label: string;
  value: string;
}

export function ReadonlyField({
  label,
  value,
}: ReadonlyFieldProps): ReactElement {
  return (
    <Textfield
      readOnly={true}
      label={label}
      labelClass="text-size-16 text-weight-200"
      defaultValue={value}
      className={styles.root}
      horizontal={false}
    />
  );
}
