import React, { ReactElement } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Icon, IconNames } from 'dcloud-shared-ui';
import { ButtonGroup } from '../../../..';
import { EditTopologyRoutes } from '../../../../../pages/EditTopology/EditTopologyRouter';
import { TopologySVGIcon } from '../../../../SVGIcons/TopologySVGIcon';
import { NavigationButton } from './NavigationButton';
import styles from './NavigationButtons.module.css';

interface NavigationButton {
  label: string;
  icon: IconNames;
  to: string;
}

export function NavigationButtons(): ReactElement {
  const match = useRouteMatch();
  const location = useLocation<{ to: string }>();

  const CONFIGURATION_ROUTE = `${match.url}${EditTopologyRoutes.Configuration}`;
  const DIAGRAM_ROUTE = `${match.url}${EditTopologyRoutes.Diagram}`;

  const backLink = location.state?.to
    ? location.state.to
    : `${match.url}${EditTopologyRoutes.Configuration}`;
  return (
    <ButtonGroup className={styles.root}>
      <NavigationButton
        to={backLink}
        selected={location.pathname.includes(CONFIGURATION_ROUTE)}
        testId="navigation-button-config"
      >
        <Icon name="cog" />
      </NavigationButton>
      <NavigationButton
        to={{
          pathname: `${match.url}${EditTopologyRoutes.Diagram}`,
          state: { to: location.pathname },
        }}
        selected={location.pathname.includes(DIAGRAM_ROUTE)}
        testId="navigation-button-diagram"
      >
        <TopologySVGIcon />
      </NavigationButton>
    </ButtonGroup>
  );
}
