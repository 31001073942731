import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { sessionApi } from '../../../../../redux/views/session-view/session/api/api';
import {
  sessionInventorySelectors,
  setIsSelectAllHwChecked,
  setSearchHwValue,
  setAllHwItems,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import { useSessionUserContext } from '../../../../../hooks/use-session-user-context';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import { RootState } from '../../../../../redux/store';
import { prepHardwareItemsForSessionInventoryHardware } from './utils';
import { SessionInventoryHardware } from '.';

export function SessionInventoryHwContentContainer({
  searchHwValue,
  allVisibleHwItems,
  setAllHwItems,
  setSearchHwValue,
}: SessionInventoryHwContentContainerProps): ReactElement {
  const { sessionUid, versionUid } = useSessionUserContext();
  const { hardware, isLoading, isFetching, isError } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          hardware: prepHardwareItemsForSessionInventoryHardware(
            sessionUid,
            versionUid,
            data?.diagram.nodes,
          ),
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  if (isLoading || isFetching || isError) {
    return <TableLoader />;
  }

  return (
    <SessionInventoryHardware
      setAllHwItems={setAllHwItems}
      allVisibleHwItems={allVisibleHwItems}
      searchHwValue={searchHwValue}
      setSearchHwValue={setSearchHwValue}
      setIsSelectAllHwChecked={setIsSelectAllHwChecked}
      isLoading={!hardware || isLoading || isFetching}
      hardware={hardware}
    />
  );
}
const mapStateToProps = (state: RootState) => ({
  allHwItems: sessionInventorySelectors.getAllHwItems(state),
  allVisibleHwItems: sessionInventorySelectors.getAllVisibleHwItems(state),
  searchHwValue: sessionInventorySelectors.getSearchHwValue(state),
});

const mapDispatchToProps = {
  setAllHwItems,
  setIsSelectAllHwChecked,
  setSearchHwValue,
};

type SessionInventoryHwContentContainerProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type SessionInventoryHwContentRTKProps = {
  isLoading: boolean;
  hardware: SessionInventoryHardwareData[];
};

export type SessionInventoryHwContentProps = Omit<
  SessionInventoryHwContentContainerProps,
  'allHwItems'
> &
  SessionInventoryHwContentRTKProps;

export const ConnectedSessionInventoryHwContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionInventoryHwContentContainer);
