import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { Icon } from 'dcloud-shared-ui';
import { format } from 'date-fns';
import { TwoLineTruncatedCell } from '../../../../../entities/topologies/components/TopologiesTable/TwoLineTruncatedCell';
import { Table } from '../../../../../components';
import { ArchitectureLabelGroup } from '../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { SEARCH_MIN_CHARS } from '../../../../EditTopology/configuration/constants';
import { filterItems } from '../../../../EditTopology/configuration/searchUtils';
import { Search } from '../../../../../components/Search';
import { TOPOLOGIES_LAST_UPDATED_FORMAT } from '../../../../../entities/topologies/constants';
import { VmTemplateTableActions } from './TableActions';
import styles from './TableActions.module.css';

export interface VmTemplatesTableProps {
  loading: boolean;
  vmTemplates: VmTemplate[];
  deleteVmTemplate: (templateId: string) => void;
  setSelectedInventoryItem: ActionCreatorWithOptionalPayload<
    VmTemplate | undefined,
    string
  >;
}

export function VmTemplateTable({
  loading,
  vmTemplates,
  deleteVmTemplate,
  setSelectedInventoryItem,
}: VmTemplatesTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<VmTemplate>[]>(
    () => [
      {
        Cell: ({ row }: CellProps<VmTemplate>) =>
          row.original.inventoryVm === undefined ? (
            <span className={styles.corrupt}>
              <Icon className={styles.icon} name="info-outline" />
              {row.original.name}
            </span>
          ) : (
            <span className="truncate-two-lines">{row.original.name}</span>
          ),
        Header: () => t('templateManager.vmTemplates.table.headings.name'),
        accessor: 'name',
        minWidth: 200,
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () =>
          t('templateManager.vmTemplates.table.headings.description'),
        accessor: 'description',
        minWidth: 200,
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () => t('templateManager.vmTemplates.table.headings.version'),
        accessor: 'templateVersion',
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () => t('templateManager.vmTemplates.table.headings.active'),
        accessor: (row) => (row.userEnabled ? 'Enabled' : 'Disabled'),
        id: 'userEnabled',
      },
      {
        Cell: ({ row }: CellProps<VmTemplate>) =>
          row.original.inventoryVm === undefined ? (
            <span className={styles.corrupt}>
              <Icon className={styles.icon} name="info-outline" />
              {t('templateManager.vmTemplates.form.validations.missingVm')}
            </span>
          ) : (
            <>{row.original.inventoryVmId}</>
          ),
        Header: () => t('templateManager.vmTemplates.table.headings.vmId'),
        accessor: (row) => row.inventoryVm?.id,
        id: 'vmId',
      },
      {
        Header: () => t('templateManager.vmTemplates.table.headings.licensed'),
        accessor: (row) => (row.licensed ? t('common.yes') : t('common.no')),
        id: 'licensed',
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () => t('templateManager.vmTemplates.table.headings.contact'),
        accessor: 'contact',
      },
      {
        Cell: ({ row }: CellProps<VmTemplate>) =>
          row.original.architectureNames ? (
            <ArchitectureLabelGroup
              architectures={row.original.architectureNames}
            />
          ) : null,
        Header: () =>
          t('templateManager.vmTemplates.table.headings.architecture'),
        accessor: 'architectures',
        minWidth: 200,
      },
      {
        Cell: ({ row }: CellProps<VmTemplate>) =>
          row.original.lastUpdated
            ? format(
                new Date(row.original.lastUpdated),
                TOPOLOGIES_LAST_UPDATED_FORMAT,
              )
            : null,
        Header: () =>
          t('templateManager.vmTemplates.table.headings.lastUpdated'),
        accessor: 'lastUpdated',
        minWidth: 170,
      },
    ],
    [t],
  );

  const [filteredItems, setFilteredItems] = useState<VmTemplate[]>(vmTemplates);
  const [searchValue, setSearchValue] = useState<string>('');
  const filterKeys = [
    'name',
    'description',
    'architectureNames',
    'inventoryVmId',
    'description',
    'contact',
    'templateVersion',
    'userEnabled',
    'lastUpdated',
  ];

  function handleSearchChange(newSearchValue: string): void {
    setSearchValue(newSearchValue);
    if (newSearchValue.length === 0) {
      setFilteredItems(vmTemplates);
    } else if (newSearchValue.length >= SEARCH_MIN_CHARS) {
      const searchText = newSearchValue.toLowerCase();
      const result = filterItems({
        filterKeys,
        list: vmTemplates,
        searchText,
      });
      setFilteredItems(result);
    }
  }
  const renderActions = (vmTemplate: VmTemplate): ReactNode => (
    <VmTemplateTableActions
      vmTemplate={vmTemplate}
      onDeleteClick={deleteVmTemplate}
      canBeDeleted={vmTemplate.inventoryVm === undefined}
      onEditClick={setSelectedInventoryItem}
    />
  );

  return (
    <>
      <div className="row flex-right">
        <Search
          className="base-padding"
          id="vm-template-search"
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder={t('common.search')}
          value={searchValue}
        />
      </div>
      <div className={styles.tableOuterWrapper}>
        <div
          className={styles.tableInnerWrapper}
          data-testid="topologies-table"
        >
          <Table<VmTemplate>
            actions={renderActions}
            columns={columns}
            loading={loading}
            data={filteredItems}
            initialState={{
              pageSize: 15,
              sortBy: [{ desc: false, id: 'name' }],
            }}
          />
        </div>
      </div>
    </>
  );
}
