import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, takeLatest, select, put } from 'redux-saga/effects';
import {
  topologiesFiltersSelectors,
  setFilteredToplogies,
  setIsApplyingFilters,
} from './slice';
import { filterTopologies, isCalculateFiltersRequiredCheck } from './utils';

export function* runFilterWorker({
  type,
}: PayloadAction<string, string>): SagaIterator {
  const searchValue = yield select(topologiesFiltersSelectors.getSearchValue);
  const isCalculateFiltersRequired: boolean = yield call(
    isCalculateFiltersRequiredCheck,
    type,
    searchValue,
  );
  if (isCalculateFiltersRequired) {
    yield put(setIsApplyingFilters(true));
    const topologies = yield select(topologiesFiltersSelectors.getTopologies);
    const showFilters = yield select(topologiesFiltersSelectors.getShowFilters);
    const dataCenterFilters = yield select(
      topologiesFiltersSelectors.getDataCentersFilters,
    );

    const filteredTopoliges = yield call(
      filterTopologies,
      topologies,
      showFilters,
      dataCenterFilters,
      searchValue,
    );

    yield put(setFilteredToplogies(filteredTopoliges));
    yield put(setIsApplyingFilters(false));
  }
}

export function* addFilterWatcher(): SagaIterator {
  yield all([takeLatest('topologiesFilters/addFilter', runFilterWorker)]);
}

export function* addDataCenterFilterWatcher(): SagaIterator {
  yield all([
    takeLatest('topologiesFilters/addDataCenterFilter', runFilterWorker),
  ]);
}

export function* deleteFilterWatcher(): SagaIterator {
  yield all([takeLatest('topologiesFilters/deleteFilter', runFilterWorker)]);
}

export function* deleteDataCenterFilterWatcher(): SagaIterator {
  yield all([
    takeLatest('topologiesFilters/deleteDataCenterFilter', runFilterWorker),
  ]);
}

export function* setSearchValueWatcher(): SagaIterator {
  yield all([takeLatest('topologiesFilters/setSearchValue', runFilterWorker)]);
}

export function* setTopologiesWatcher(): SagaIterator {
  yield all([takeLatest('topologiesFilters/setTopologies', runFilterWorker)]);
}

export function* topologiesFiltersWatchers(): SagaIterator {
  yield fork(addFilterWatcher);
  yield fork(deleteFilterWatcher);
  yield fork(addDataCenterFilterWatcher);
  yield fork(deleteDataCenterFilterWatcher);
  yield fork(setSearchValueWatcher);
  yield fork(setTopologiesWatcher);
}
