import React, { ReactElement } from 'react';
import { Tooltip } from '../../../../../components/Tooltip';
import { PushActionPushButton } from '../PushActionPushButton';

export interface DisabledPushActionPushButtonProps {
  tooltipMessage: string;
  tooltipId: string;
}

export function DisabledPushActionPushButton({
  tooltipMessage,
  tooltipId,
}: DisabledPushActionPushButtonProps): ReactElement {
  return (
    <Tooltip id={tooltipId!} text={tooltipMessage}>
      <PushActionPushButton onClick={() => null} isDisabled={true} />
    </Tooltip>
  );
}
