import React, { ReactElement } from 'react';
import i18n from '../../i18n';
import {
  PushActionPushButton,
  PushActionUpdateButton,
} from './components/TopologiesPushActions';
import { DisabledPushActionPushButton } from './components/TopologiesPushActions/DisabledPushActionPushButton';
import { DisabledPushActionUpdateButton } from './components/TopologiesPushActions/DisabledPushActionUpdateButton';

export const determinePushAction = (
  { inSyncWithDcloud, _links, status, uid }: Topology,
  onPush: VoidFunction,
  onUpdate: VoidFunction,
): ReactElement | null => {
  if (inSyncWithDcloud || inSyncWithDcloud === undefined) {
    return null;
  }

  switch (status) {
    case 'DRAFT':
      return 'push' in _links ? (
        <PushActionPushButton onClick={onPush} />
      ) : (
        <DisabledPushActionPushButton
          tooltipId={`disable-push-${uid}`}
          tooltipMessage={i18n.t('topologies.push.disabledButtonTooltip')}
        />
      );
    case 'CUSTOM_CONTENT':
    case 'SAVED_CONTENT':
      return 'updatePush' in _links ? (
        <PushActionUpdateButton onClick={onUpdate} />
      ) : (
        <DisabledPushActionUpdateButton
          tooltipId={`disable-push-update-${uid}`}
          tooltipMessage={i18n.t('topologies.updatePush.disabledButtonTooltip')}
        />
      );
    default:
      return null;
  }
};

export const addOnPushActionToTopologies = (
  topologies: Topology[],
  onPush: TopologyAction,
  onUpdate: TopologyAction,
): TopologyWithOnPushActions[] => {
  return topologies.map((topology) => ({
    ...topology,
    onPush: () => onPush(topology),
    onUpdate: () => onUpdate(topology),
  }));
};

export const getTopologyOwnersEmail = (
  accessRoleAssignments?: AccessRoleAssignment[],
): string => {
  return (
    accessRoleAssignments?.filter(
      (accessRoleAssignment) => accessRoleAssignment.accessRole === 'OWNER',
    )[0]?.email || ''
  );
};

export const prepTopologiesWithOwner = (
  topologies: Topology[] | undefined,
): Topology[] => {
  if (!topologies) return [];

  return topologies.map((topology) => {
    return {
      ...topology,
      owner: getTopologyOwnersEmail(topology.accessRoleAssignments),
    };
  });
};
