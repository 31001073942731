import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, SelectOption } from 'dcloud-shared-ui';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../components';
import { PrimaryButton } from '../../../../../../../components/Buttons/PrimaryButton';
import { FormUnsavedChangesPrompt } from '../../../../../../../components/FormUnsavedChangesPrompt';
import { FormWrapper } from '../../../../../../../components/FormWrapper';
import { PLEASE_SELECT_OPTION } from '../../../../../../../constants/form';
import { inboundProxyRulesSchema } from '../../../../../../../validation';
import { useLeaveFormPrompt } from '../../../../../hooks/useLeaveFormPrompt';
import {
  parseChangeEventToNumber,
  parseNumberToString,
} from '../../../../components/VirtualMachineEntities/Edit/utils';
import { buildIPAddressOptionsNicTarget } from '../../../utils';
import { buildInboundProxyRulePayload, isTargetInUse } from '../../utils';
import { useErrorToast } from '../../../../../../../hooks/toasts/use-error-toast';
import styles from './InboundProxyRulesAddForm.module.css';

export interface InboundProxyRulesAddFormProps {
  ipAddressOptions: SelectOption<string>[];
  targetOptions: SelectOption<string>[];
  isCreating: boolean;
  createInboundProxyRule: RTKMutation<
    InboundProxyRulePostPayload,
    InboundProxyRule
  >;
  inboundProxyRules: InboundProxyRule[];
  limits: Limits;
  vmTargets: VmNicTarget[];
  setVmIpAddressOptions: Dispatch<SetStateAction<SelectOption<string>[]>>;
}

export function InboundProxyRulesAddForm({
  vmTargets,
  ipAddressOptions,
  targetOptions,
  isCreating,
  inboundProxyRules,
  limits,
  setVmIpAddressOptions,
  createInboundProxyRule,
}: InboundProxyRulesAddFormProps): ReactElement | null {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const { t } = useTranslation();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
  } = useForm<InboundProxyRuleFormData>({
    defaultValues: {
      hyperLinkText: '',
      ipAddress: '',
      showHyperlink: true,
      ssl: true,
      target: '',
      tcpPort: undefined,
      urlPath: '',
    },
    mode: 'all',
    resolver: yupResolver(inboundProxyRulesSchema),
  });
  const { isDirty, isValid } = formState;

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  const [selectedVm, setLocalSelectedVm] = useState<string>(
    PLEASE_SELECT_OPTION.value,
  );
  const [targetInUse, setTargetInUse] = useState<boolean>(false);

  const { showErrorToast } = useErrorToast();

  useEffect(() => {
    const inUse = isTargetInUse(inboundProxyRules, selectedVm);
    inUse ? setTargetInUse(true) : setTargetInUse(false);
  }, [selectedVm, inboundProxyRules]);

  const handleCreate: SubmitHandler<InboundProxyRuleFormData> = async (
    formData,
  ) => {
    const payload = buildInboundProxyRulePayload({ formData, topologyUid });
    const limit = limits.validationLimits.maxInboundProxyRules;
    if (inboundProxyRules.length >= limit) {
      showErrorToast(
        `${t(
          'traffic.inboundProxyRules.formErrors.maxRulesPart1',
        )} ${limit} ${t('traffic.inboundProxyRules.formErrors.maxRulesPart2')}`,
      );
    } else {
      const { error } = await createInboundProxyRule(payload);
      !error && resetForm();
    }
  };

  return (
    <>
      <FormWrapper
        aria-label="Add inbound proxy rule form"
        className={styles.root}
        onSubmit={handleSubmit(handleCreate)}
      >
        <ControlledSelect
          control={control}
          options={targetOptions}
          label={t('traffic.inboundProxyRules.table.target')}
          name="target"
          required={true}
          error={errors?.target?.message}
          className={styles.targetInput}
          customOnChange={(e) => {
            setLocalSelectedVm(e.target.value);
            setVmIpAddressOptions(
              buildIPAddressOptionsNicTarget(e.target.value, vmTargets),
            );
          }}
        />
        {(ipAddressOptions.length > 1 && !targetInUse) ||
        selectedVm === PLEASE_SELECT_OPTION.value ? (
          <>
            <ControlledSelect
              control={control}
              options={ipAddressOptions}
              label={t('traffic.inboundProxyRules.table.ipAddress')}
              name="ipAddress"
              required={true}
              error={errors?.ipAddress?.message}
              className={styles.ipAddressInput}
            />
            <ControlledTextfield
              control={control}
              label={t('traffic.inboundProxyRules.table.tcpPort')}
              name="tcpPort"
              required={true}
              error={errors?.tcpPort?.message}
              className={styles.tcpPortInput}
              isNumber={true}
              transform={{
                input: parseNumberToString,
                output: parseChangeEventToNumber,
              }}
            />
            <ControlledCheckbox
              className={styles.verticalAlign}
              control={control}
              label={t('traffic.inboundProxyRules.table.ssl')}
              id="ssl"
            />
            <ControlledTextfield
              control={control}
              label={t('traffic.inboundProxyRules.table.urlPath')}
              name="urlPath"
              required={true}
              testId="inbound-proxy-rules-url-path-input"
              error={errors?.urlPath?.message}
            />
            <ControlledCheckbox
              className={styles.verticalAlign}
              control={control}
              label={t('traffic.inboundProxyRules.table.showHyperlink')}
              id="showHyperlink"
            />
            <ControlledTextfield
              control={control}
              label={t('traffic.inboundProxyRules.table.hyperlinkText')}
              name="hyperLinkText"
              required={true}
              error={errors?.hyperLinkText?.message}
            />
            <PrimaryButton
              disabled={!isDirty || !isValid}
              loading={isCreating}
              type="submit"
            >
              {t('buttons.add')}
            </PrimaryButton>
          </>
        ) : (
          <Alert colour="info">
            {targetInUse
              ? t('traffic.inboundProxyRules.formErrors.inUse')
              : t('traffic.noAvailableNicTargets')}
          </Alert>
        )}
      </FormWrapper>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
}
