import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Alert } from 'dcloud-shared-ui';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '../../../../../../components/PageHeading';
import { vmTemplateEditSchema } from '../../../../../../validation';
import { VmTemplateForm } from '../VmTemplateForm';
import styles from '../VmTemplates.module.css';
import { prepTemplateDataPayload } from '../../utils';
import { MainRoutes } from '../../../../../../constants/routes';
import { TemplateManagerRoutes } from '../../../TemplateManager/TemplateManagerRouter';

interface AddVmTemplateFormProps {
  addSelectedArchitecture: ActionCreatorWithPayload<Architecture, string>;
  allArchitectures: Architecture[];
  selectedArchitectures?: Architecture[];
  createVmTemplate: RTKMutation<VmTemplate, CreateVmTemplatePayload>;
  updateVmTemplate: RTKMutation<VmTemplateUpdatePayload, VmTemplate>;
  currentDc: DataCenter;
  setSelectedInventoryItem: ActionCreatorWithOptionalPayload<
    VmTemplate | undefined,
    string
  >;
  isEdit: boolean;
  removeArchitecture: ActionCreatorWithPayload<string, string>;
  isSuccess: boolean;
  isLoading: boolean;
  selectedInventoryItem: VmTemplate;
  responseData?: VmTemplate;
}

export function AddVmTemplateForm({
  addSelectedArchitecture,
  allArchitectures,
  currentDc,
  createVmTemplate,
  selectedArchitectures,
  selectedInventoryItem,
  isSuccess,
  isLoading,
  setSelectedInventoryItem,
  removeArchitecture,
  updateVmTemplate,
  responseData,
  isEdit,
}: AddVmTemplateFormProps): ReactElement {
  const { t } = useTranslation();
  const templateToCreate = selectedInventoryItem!;
  const templateFormMethods = useForm<EditVmTemplateFormData>({
    defaultValues: {
      architecture: selectedArchitectures || [],
      contact: templateToCreate.contact || '',
      licensed: templateToCreate.licensed ? 'yes' : 'no',
      managementIp: templateToCreate.managementIp || '',
      password: templateToCreate.password || '',
      templateDescription: templateToCreate.description || '',
      templateName: templateToCreate.name || '',
      templateVersion: templateToCreate.templateVersion || '',
      userEnabled: templateToCreate.userEnabled || false,
      userName: templateToCreate.username || '',
    },

    mode: 'all',
    resolver: yupResolver(vmTemplateEditSchema),
  });

  const [hasMissingArchitecture, setHasMissingArchitecture] = useState(false);
  const history = useHistory();

  const handleOnChange = useCallback(() => {
    if (selectedArchitectures?.some((item) => !item.name)) {
      setHasMissingArchitecture(true);
    } else {
      setHasMissingArchitecture(false);
    }
  }, [selectedArchitectures]);

  useEffect(() => {
    handleOnChange();
  }, [handleOnChange, selectedArchitectures]);

  const handleSave: SubmitHandler<EditVmTemplateFormData> = async (
    formData,
  ) => {
    const payload = prepTemplateDataPayload(
      formData,
      templateToCreate,
      currentDc!,
      selectedArchitectures,
    );
    if (isEdit) {
      updateVmTemplate({
        templateId: selectedInventoryItem.uid,
        vmTemplate: payload,
      });
    } else {
      createVmTemplate(payload);
    }
  };

  const archiAreEqual = isEqual(
    selectedArchitectures,
    templateToCreate.architectures,
  );
  isSuccess && setSelectedInventoryItem(responseData);
  isSuccess &&
    history.push(
      `${MainRoutes.Templates}${TemplateManagerRoutes.VmTemplates}${TemplateManagerRoutes.EditVmTemplate}`,
    );

  const { isValid, dirtyFields } = templateFormMethods.formState;
  let isSaveButtonDisabled =
    ((!Object.keys(dirtyFields).length || !isValid) && archiAreEqual) ||
    hasMissingArchitecture;
  if (isEdit) {
    isSaveButtonDisabled = hasMissingArchitecture || !isValid;
  }

  return (
    <div className={styles.container}>
      <FormProvider {...templateFormMethods}>
        <form onSubmit={templateFormMethods.handleSubmit(handleSave)}>
          <PageHeading
            ctaBtn={
              <div className={styles.wrapper}>
                <PrimaryButton
                  type="submit"
                  colour="success"
                  disabled={isSaveButtonDisabled}
                  loading={isLoading}
                >
                  {isEdit ? t('buttons.save') : t('buttons.create')}
                </PrimaryButton>
              </div>
            }
            pageTitle={
              isEdit
                ? t('templateManager.vmTemplates.form.titles.edit')
                : t('templateManager.vmTemplates.create.title')
            }
            withBackBtn={true}
          />
          <VmTemplateForm
            addSelectedArchitecture={addSelectedArchitecture}
            removeArchitecture={removeArchitecture}
            selectedArchitectures={selectedArchitectures}
            allArchitectures={allArchitectures}
            hasMissingArchitecture={hasMissingArchitecture}
            isEdit={false}
          />
        </form>
      </FormProvider>
      {isEdit && (
        <Alert showIcon colour="warning">
          {t('templateManager.vmTemplates.form.labels.alert')}
        </Alert>
      )}
    </div>
  );
}
