import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PanelGrid } from '../../../PanelGrid';
import { HwInfoProps } from './container';

export function HWInfo({
  data: {
    name,
    description,
    deviceType,
    hwConsole,
    sessionDevice,
    powerControl,
  },
}: HwInfoProps): ReactElement {
  const { t } = useTranslation();
  const panelGridData = useMemo<PanelGridDataItem[]>(
    () => [
      {
        key: 'name',
        name: t('sessions.panels.hardware.info.name'),
        value: name,
      },
      {
        key: 'deviceType',
        name: t('sessions.panels.hardware.info.deviceType'),
        value: deviceType,
      },
      {
        key: 'description',
        name: t('sessions.panels.hardware.info.description'),
        value: description,
      },

      {
        key: 'powerControl',
        name: t('sessions.panels.hardware.info.powerControl'),
        value: powerControl,
      },
      {
        key: 'hwConsole',
        name: t('sessions.panels.hardware.info.hwConsole'),
        value: hwConsole,
      },
      {
        key: 'sessionDevice',
        name: t('sessions.panels.hardware.info.sessionDevice'),
        value: sessionDevice,
      },
    ],
    [description, deviceType, hwConsole, name, powerControl, sessionDevice, t],
  );

  return <PanelGrid data={panelGridData} wideCol="value" nameBold={true} />;
}
