import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RowNode } from 'ag-grid-community';
import styles from '../../../Integrations.module.css';
import { INTEGRATION_TYPE_LABEL_KEYS } from '../../../../../constants';

export const IntegrationTypeCellRenderer = (props: RowNode): ReactElement => {
  const { t } = useTranslation();
  const {
    data: { type },
  }: { data: Integration } = props;
  const integrationTypeLabelValue = INTEGRATION_TYPE_LABEL_KEYS[type];
  return (
    <div className={styles.tag}>
      <small>
        {t(`integrations.table.integrationTypes.${integrationTypeLabelValue}`)}
      </small>
    </div>
  );
};
