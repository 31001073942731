import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { setSelectedIconToDelete } from '../../../../../../redux/diagram/slice';
import { NodeIconDeleteButton } from '.';

const mapDispatchToProps = {
  setSelectedIconToDelete,
};

export type NodeIconDeleteButtonContainerOwnProps = {
  iconToDelete: DiagramIconToDeleteActionPayload;
};

const mapStateToProps = (
  state: RootState,
  { iconToDelete }: NodeIconDeleteButtonContainerOwnProps,
) => ({
  iconToDelete,
});

export type NodeIconDeleteButtonProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const NodeIconDeleteButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NodeIconDeleteButton);
