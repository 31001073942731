import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTopologiesQuery } from '../../redux/topologies/api';
import { Topologies } from '.';

export function TopologiesContainer(): ReactElement {
  const { isLoading, isFetching, isError } = useGetTopologiesQuery(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('siteTitle');
  }, [t]);
  const isLoadingTopologies = isLoading || isFetching || isError;
  return <Topologies isLoading={isLoadingTopologies} />;
}
