import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { useSessionUserContext } from '../../../../../hooks/use-session-user-context';
import { sessionApi } from '../../../../../redux/views/session-view/session/api/api';
import {
  sessionInventorySelectors,
  startGetAllVmStatuses,
  stopGetAllVmStatuses,
  setIsSelectAllVmChecked,
  setSearchVmValue,
  setAllVmItems,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import { RootState } from '../../../../../redux/store';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import { prepVMItemsForSessionInventoryVirtualMachines } from './utils';
import { SessionInventoryVirtualMachines } from '.';

export function SessionInventoryVmContentContainer({
  startGetAllVmStatusesForSessionInventory,
  stopGetAllVmStatusesForSessionInventory,
  isCallingVmStatusAPICalls,
  hasCalledVmStatusApiCalls,
  setIsSelectAllVmChecked,
  setSearchVmValue,
  searchVmValue,
  allVisibleVmItems,
  setAllVmItems,
}: SessionInventoryVmContentContainerProps): ReactElement {
  const { sessionUid, versionUid } = useSessionUserContext();

  const { virtualMachines, isError, isLoading, isFetching } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          virtualMachines: prepVMItemsForSessionInventoryVirtualMachines(
            sessionUid,
            versionUid,
            data?.diagram.nodes,
          ),
        }),
      },
    );

  if (isLoading || isFetching || isError) {
    return <TableLoader />;
  }

  return (
    <SessionInventoryVirtualMachines
      setAllVmItems={setAllVmItems}
      allVisibleVmItems={allVisibleVmItems}
      searchVmValue={searchVmValue}
      setSearchVmValue={setSearchVmValue}
      setIsSelectAllVmChecked={setIsSelectAllVmChecked}
      startGetAllVmStatusesForSessionInventory={
        startGetAllVmStatusesForSessionInventory
      }
      isLoading={!virtualMachines || isLoading || isFetching}
      virtualMachines={virtualMachines as SessionInventoryVirtualMachineData[]}
      stopGetAllVmStatusesForSessionInventory={
        stopGetAllVmStatusesForSessionInventory
      }
      isCallingVmStatusAPICalls={isCallingVmStatusAPICalls}
      hasCalledVmStatusApiCalls={hasCalledVmStatusApiCalls}
    />
  );
}

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => ({
  setAllVmItems: (vms: SessionInventoryVirtualMachineData[]) =>
    dispatch(setAllVmItems(vms)),
  setIsSelectAllVmChecked: (isSelected: boolean) =>
    dispatch(setIsSelectAllVmChecked(isSelected)),
  setSearchVmValue: (val: string) => dispatch(setSearchVmValue(val)),
  startGetAllVmStatusesForSessionInventory: (
    vms: SessionInventoryVirtualMachineData[],
  ) => dispatch(startGetAllVmStatuses(vms)),
  stopGetAllVmStatusesForSessionInventory: () =>
    dispatch(stopGetAllVmStatuses()),
});

const mapStateToProps = (state: RootState) => ({
  allVisibleVmItems: sessionInventorySelectors.getAllVisibleVmItems(state),
  allVmItems: sessionInventorySelectors.getAllVmItems(state),
  hasCalledVmStatusApiCalls:
    sessionInventorySelectors.getHasCalledVmStatusesAPICalls(state),
  isCallingVmStatusAPICalls:
    sessionInventorySelectors.getIsCallingVmStatusesAPICalls(state),
  searchVmValue: sessionInventorySelectors.getSearchVmValue(state),
});

type SessionInventoryVmContentRTKProps = {
  isLoading: boolean;
  virtualMachines: SessionInventoryVirtualMachineData[];
};

type SessionInventoryVmContentContainerProps = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps>;

export type SessionInventoryVirtualMachinesProps = Omit<
  SessionInventoryVmContentContainerProps,
  'allVmItems'
> &
  SessionInventoryVmContentRTKProps;

export const ConnectedSessionInventoryVmContent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionInventoryVmContentContainer);
