import { SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';
import i18n from '../../i18n';

interface NetworkUtilsProps {
  availableRouted: InventoryNetwork[];
  availableUnrouted: InventoryNetwork[];
  isLastRouted: boolean;
  isRouted: boolean;
}

interface GetNetworkAlertMessage
  extends Omit<NetworkUtilsProps, 'isRouted' | 'isLastRouted'> {
  isNetworkCheckboxDisabled: boolean;
}

interface GetEditNetworkAlertMessage
  extends Omit<NetworkUtilsProps, 'isRouted'> {
  inventoryToEdit: Network['inventoryNetwork'];
  isNetworkCheckboxDisabled: boolean;
}

interface GetEditNetworkCheckboxState
  extends Omit<NetworkUtilsProps, 'isRouted'> {
  inventoryToEdit: Network['inventoryNetwork'];
}

interface GetNetworkSubnetOptionsProps
  extends Omit<NetworkUtilsProps, 'isLastRouted'> {
  inventoryToEdit?: Network['inventoryNetwork'];
}

interface HandleRoutedChangeProps
  extends Pick<
    UseFormMethods<NetworkFormFields>,
    'getValues' | 'setValue' | 'trigger'
  > {
  newRoutedValue: boolean;
  setIsRouted: (value: SetStateAction<boolean>) => void;
}

export const getNetworkSubnetOptions = ({
  availableRouted,
  availableUnrouted,
  inventoryToEdit,
  isRouted,
}: GetNetworkSubnetOptionsProps): NetworkSubnetOptions => {
  const routedNetworks =
    inventoryToEdit?.type === 'ROUTED'
      ? [...availableRouted, inventoryToEdit]
      : availableRouted;
  const unroutedNetworks =
    inventoryToEdit?.type === 'UNROUTED'
      ? [...availableUnrouted, inventoryToEdit]
      : availableUnrouted;
  const networks = isRouted ? routedNetworks : unroutedNetworks;

  return [
    { name: i18n.t('common.pleaseSelect'), value: '' },
    ...networks.map((network) => ({
      name: network.subnet,
      value: network.id,
    })),
  ];
};

export const getEditNetworkCheckboxState = ({
  availableRouted,
  availableUnrouted,
  inventoryToEdit,
  isLastRouted,
}: GetEditNetworkCheckboxState): boolean => {
  const isLimitReached = !availableUnrouted.length || !availableRouted.length;
  const isRoutedSwitchAvailable =
    (inventoryToEdit.type === 'ROUTED' && !!availableUnrouted.length) ||
    (inventoryToEdit.type === 'UNROUTED' && !!availableRouted.length);

  if (isRoutedSwitchAvailable && isLimitReached) {
    return false;
  }

  return (inventoryToEdit.type === 'ROUTED' && isLastRouted) || isLimitReached;
};

export const getNetworkAlertMessage = ({
  availableRouted,
  availableUnrouted,
  isNetworkCheckboxDisabled,
}: GetNetworkAlertMessage): NetworkAlertMessage => {
  const isRoutedLimitReached = !availableRouted.length;
  const isUnroutedLimitReached = !availableUnrouted.length;

  if (!isNetworkCheckboxDisabled) {
    return undefined;
  }

  if (isRoutedLimitReached && !isUnroutedLimitReached) {
    return i18n.t('networks.form.maxRoutedInfo');
  }

  if (isUnroutedLimitReached && !isRoutedLimitReached) {
    return i18n.t('networks.form.maxUnroutedInfo');
  }
};

export const getEditNetworkAlertMessage = ({
  availableRouted,
  availableUnrouted,
  inventoryToEdit,
  isLastRouted,
  isNetworkCheckboxDisabled,
}: GetEditNetworkAlertMessage): NetworkAlertMessage => {
  const isRoutedLimitReached = !availableRouted.length;
  const isUnroutedLimitReached = !availableUnrouted.length;
  const isRoutedNetworkToEdit = inventoryToEdit.type === 'ROUTED';
  const isUnroutedNetworkToEdit = inventoryToEdit.type === 'UNROUTED';

  if (isRoutedLimitReached && isUnroutedNetworkToEdit) {
    return i18n.t('networks.form.maxRoutedInfo');
  }

  if (isUnroutedLimitReached && isRoutedNetworkToEdit) {
    return i18n.t('networks.form.maxUnroutedInfo');
  }

  if (isRoutedNetworkToEdit && isLastRouted) {
    return i18n.t('networks.form.minRoutedInfo');
  }

  return getNetworkAlertMessage({
    availableRouted,
    availableUnrouted,
    isNetworkCheckboxDisabled,
  });
};

export const handleRoutedChange = ({
  getValues,
  newRoutedValue,
  setIsRouted,
  setValue,
  trigger,
}: HandleRoutedChangeProps): void => {
  const subnetPreviousVal = getValues('subnet');
  setIsRouted(newRoutedValue);
  setValue('subnet', '');
  subnetPreviousVal !== '' && trigger('subnet');
};
