import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Alert, Button } from 'dcloud-shared-ui';
import { isEqual } from 'lodash';
import {
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../../../components';
import { ArchitectureLabelGroup } from '../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { TemplateModalColumn } from '../../../Components/InfoCol';
import { ArchitectureLabelPicker } from '../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelPicker';
import { ControlledSwitch } from '../../../../../../components/Form/ControlledSwitch';
import { demoTemplateEditSchema } from '../../../../../../validation';
import { removeSelectedArchitectures } from '../../../utils';
import styles from '../DemoTemplates.module.css';
import { prepDemoTemplatePayload } from '../../utils';

export interface DemoTemplateFormProps {
  allArchitectures: Architecture[];
  currentDc: DataCenter;
  submitAction: (templatePayload: DemoTemplate) => void;
  removeArchitecture: ActionCreatorWithPayload<string, string>;
  addSelectedArchitecture: ActionCreatorWithPayload<Architecture, string>;
  isUpdating: boolean;
  isEdit: boolean;
  selectedArchitectures?: Architecture[];
  onClose: VoidFunction;
  templateToEdit: DemoTemplate;
  colsContent: InfoPanelData[];
}

export function DemoTemplateForm({
  addSelectedArchitecture,
  currentDc,
  allArchitectures,
  isEdit,
  templateToEdit,
  isUpdating,
  submitAction,
  selectedArchitectures,
  colsContent,
  onClose,
  removeArchitecture,
}: DemoTemplateFormProps): ReactElement {
  const { t } = useTranslation();
  const {
    errors,
    control,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm<EditDemoTemplateFormData>({
    defaultValues: {
      architecture: selectedArchitectures || [],
      contact: templateToEdit.contact || '',
      description: templateToEdit.description || '',
      name: templateToEdit.name || '',
      userEnabled: templateToEdit.userEnabled || false,
    },

    mode: 'all',
    resolver: yupResolver(demoTemplateEditSchema),
  });
  const [hasMissingArchitecture, setHasMissingArchitecture] = useState(false);

  const archiAreEqual = isEqual(
    selectedArchitectures,
    templateToEdit.architectures,
  );

  const handleOnChange = useCallback(() => {
    if (selectedArchitectures?.some((item) => !item.name)) {
      setHasMissingArchitecture(true);
    } else {
      setHasMissingArchitecture(false);
    }
  }, [selectedArchitectures]);

  useEffect(() => {
    handleOnChange();
  }, [handleOnChange, selectedArchitectures]);

  const isUpdateButtonDisabled =
    ((!isDirty || !isValid) && archiAreEqual) || hasMissingArchitecture;

  const handleSave: SubmitHandler<EditDemoTemplateFormData> = async (
    formData,
  ) => {
    const payload = prepDemoTemplatePayload(
      formData,
      templateToEdit,
      currentDc,
      selectedArchitectures,
    );
    submitAction(payload);
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="row">
        <div className="col-6">
          <ControlledTextfield<EditVmTemplateFormData>
            control={control}
            error={errors?.name?.message}
            label={t('templateManager.demoTemplates.form.labels.name')}
            labelClass="col-2"
            name="name"
            required={true}
          />
          <ControlledTextfield<EditVmTemplateFormData>
            control={control}
            error={errors?.contact?.message}
            label={t('templateManager.demoTemplates.form.labels.contact')}
            labelClass="col-3"
            name="contact"
          />
        </div>
        <div className="col-6">
          <label className={styles.archLabel}>
            {t('templateManager.demoTemplates.form.labels.available')}
          </label>
          <div className={styles.archContainer}>
            <ArchitectureLabelPicker
              architectures={removeSelectedArchitectures(
                allArchitectures,
                selectedArchitectures || [],
              )}
              onAdd={addSelectedArchitecture}
            />
          </div>
          <label className={styles.archLabel}>
            {t(
              'templateManager.demoTemplates.form.labels.selectedArchitectures',
            )}
          </label>
          <div className={styles.archContainer}>
            {selectedArchitectures && (
              <ArchitectureLabelGroup
                architectures={selectedArchitectures}
                onDismiss={(id: string) => removeArchitecture(id)}
                hideMissing={false}
              />
            )}
            {hasMissingArchitecture && (
              <Alert colour="warning">
                {t(
                  'templateManager.demoTemplates.form.validations.missingArchitecture',
                )}
              </Alert>
            )}
          </div>

          <ControlledSwitch<EditVmTemplateFormData>
            control={control}
            label={t('templateManager.demoTemplates.form.labels.userEnabled')}
            id="userEnabled"
          />
        </div>
      </div>
      <div className={styles.description}>
        <ControlledTextarea<EditVmTemplateFormData>
          control={control}
          error={errors?.description?.message}
          required
          label={t('templateManager.demoTemplates.form.labels.description')}
          labelClass="col-12"
          rows={5}
          name="description"
        />
      </div>

      <div className={styles.colsContainer}>
        {colsContent.map((colContent, index) => {
          return (
            <div key={index} className={styles.col}>
              <TemplateModalColumn data={colContent.data} />
            </div>
          );
        })}
      </div>

      <div className="flex flex-center dbl-margin-top">
        <Button type="reset" onClick={() => onClose()}>
          {t('buttons.cancel')}
        </Button>
        <PrimaryButton
          loading={isUpdating}
          disabled={isUpdateButtonDisabled}
          type="submit"
        >
          {isEdit ? t('buttons.update') : t('buttons.create')}
        </PrimaryButton>
      </div>
    </form>
  );
}
