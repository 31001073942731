import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Image } from 'dcloud-shared-ui';
import { Container } from '../../../../components/Container';
import webex from '../../../../assets/images/app/webex.svg';
import { ExternalLink } from '../../../../components/ExternalLink';
import { HERO_WEBEX_URL } from '../../constants';
import styles from './HeroWebex.module.css';

export function HeroWebex(): ReactElement {
  const { t } = useTranslation();

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div className={classnames('row', styles.content)}>
          <div className="base-padding-right col-md-8">
            <h1 className="display-2 text-weight-300 base-margin-bottom">
              {t('home.carousel.webex.heading')}
            </h1>
            <h2
              className={classnames(
                styles.subheading,
                'subheading text-size-20 text-weight-300',
              )}
            >
              {t('home.carousel.webex.subHeading')}
            </h2>
            <ExternalLink
              color="success"
              className={classnames(
                styles.getStarted,
                'btn btn--large btn--success dbl-margin-top',
              )}
              href={HERO_WEBEX_URL}
              target="_blank"
              linkText={t('home.carousel.webex.button')}
            />
          </div>
          <div className={classnames('col-md-4', styles.webexImage)}>
            <Image alt="Hero Webex image" src={webex} />
          </div>
        </div>
      </Container>
    </section>
  );
}
