export function parseFileName(header: string): string {
  const FILENAME_PART = 'filename=';
  const DEFAULT_FILENAME = 'download';
  const DOUBLE_QUOTE = '"';
  const SEMI_COLON = ';';

  let fileNameToReturn = '';
  if (header && header.includes(FILENAME_PART)) {
    const passedFileName = header.split(FILENAME_PART)[1].split(SEMI_COLON)[0];
    fileNameToReturn = passedFileName.split(DOUBLE_QUOTE).join('');
  }
  return fileNameToReturn || DEFAULT_FILENAME;
}
