import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetCount } from '../AssetCount';
import { Search } from '../../../../../components/Search';
import { MultiActionProgressBarContainer } from '../MultiActionProgressBar/container';
import { MultiActionFailureAlertContainer } from '../MultiActionFailureAlert/container';
import { SessionInventoryVirtualMachinesTable } from './components/SessionInventoryVirtualMachinesTable';
import { SessionInventoryVirtualMachinesProps } from './container';
import { useStopGetAllVmStatusesApiCallsOnUnmount } from './hooks/useStopGetAllVmStatusesApiCallsOnUnmount';
import { GetVmStatusButtonContainer } from './components/GetVmStatusButton/container';

export const SessionInventoryVirtualMachines = ({
  virtualMachines,
  stopGetAllVmStatusesForSessionInventory,
  setSearchVmValue,
  searchVmValue,
  allVisibleVmItems,
  setAllVmItems,
}: SessionInventoryVirtualMachinesProps): ReactElement => {
  const { t } = useTranslation();

  useStopGetAllVmStatusesApiCallsOnUnmount({
    stopGetAllVmStatusesForSessionInventory,
  });

  useEffect(() => {
    setAllVmItems(virtualMachines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllVmItems]);

  return (
    <>
      <div className="row flex-left">
        <Search
          className="base-padding-right base-padding-bottom"
          id="session-inventory-vms-search"
          onChange={(e) => setSearchVmValue(e.target.value)}
          placeholder={t('common.search')}
          value={searchVmValue || ''}
        />
      </div>

      <div className="half-margin-top dbl-margin-bottom">
        <AssetCount
          filteredCount={allVisibleVmItems?.length || 0}
          totalCount={virtualMachines.length}
        />
      </div>

      <MultiActionProgressBarContainer page="vm" />
      <MultiActionFailureAlertContainer page="vm" />

      <div className="row flex-right">
        <GetVmStatusButtonContainer virtualMachines={virtualMachines} />
      </div>

      <SessionInventoryVirtualMachinesTable
        virtualMachines={allVisibleVmItems}
      />
    </>
  );
};
