import React, { ReactElement } from 'react';
import { AddingLogicalConnectionGuideLine } from '../AddingLogicalConnectionGuideLine';
import { ConnectionLine } from './ConnectionLine';
import { useClickConnection } from './hooks/use-click-connection';

export interface ConnectionsProps {
  connections: DiagramConnection[];
  isSessionView?: boolean;
}

export function Connections({
  connections,
  isSessionView,
}: ConnectionsProps): ReactElement {
  useClickConnection();

  return (
    <g id="connections">
      {connections.map((connection) => {
        return (
          <ConnectionLine
            key={connection.uid}
            connection={connection}
            isSessionView={isSessionView}
          />
        );
      })}

      {!isSessionView && <AddingLogicalConnectionGuideLine />}
    </g>
  );
}
