import { connect } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { sessionInventorySelectors } from '../../../../../redux/views/session-view/session-inventory/slice';
import { MultiActionProgressBar } from '.';

type MultiActionProgressBarOwnProps = {
  page: 'hardware' | 'vm';
};

const mapStateToProps = (
  state: RootState,
  ownProps: MultiActionProgressBarOwnProps,
) => ({
  complete:
    sessionInventorySelectors.getNumberOfMultiActionItemsCompleted(state) || 0,
  errors: sessionInventorySelectors.getNumOfMultiSelectApiErrors(state),
  page: ownProps.page,
  selectedAction: sessionInventorySelectors.getSelectedAction(state),
  show: sessionInventorySelectors.getShowMultiActionProgressBar(state),
  total:
    ownProps.page === 'hardware'
      ? sessionInventorySelectors.getSelectedHwItemsToAction(state).length
      : sessionInventorySelectors.getSelectedVmItemsToAction(state).length,
});

export const MultiActionProgressBarContainer = connect(mapStateToProps)(
  MultiActionProgressBar,
);

export type MultiActionProgressBarProps = ReturnType<typeof mapStateToProps>;
