import React, { ReactElement } from 'react';
import { format } from 'date-fns';
import { TopologyCard } from '../TopologyCard';
import { TOPOLOGIES_LAST_UPDATED_FORMAT } from '../../constants';

export interface TopologiesListProps {
  onClone: TopologyAction;
  onDelete: TopologyAction;
  onPush: TopologyAction;
  onUpdate: TopologyAction;
  topologies: Topology[];
}

export function TopologiesList({
  onClone,
  onDelete,
  onPush,
  onUpdate,
  topologies,
}: TopologiesListProps): ReactElement {
  return (
    <section className="row base-margin-top" data-testid="topologies-list">
      {topologies.map((topology) => (
        <div className="col-md-6 no-padding" key={topology.uid}>
          <TopologyCard
            className="half-margin"
            onClone={() => onClone(topology)}
            onDelete={() => onDelete(topology)}
            onPush={() => onPush(topology)}
            onUpdate={() => onUpdate(topology)}
            lastUpdatedDate={format(
              new Date(topology.lastUpdated),
              TOPOLOGIES_LAST_UPDATED_FORMAT,
            )}
            topology={topology}
          />
        </div>
      ))}
    </section>
  );
}
