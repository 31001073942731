import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetTopologyQuery,
  useUpdateTopologyMutation,
} from '../../../../redux/topologies/api';
import { General } from '.';

export function GeneralContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const [updateTopology, { isLoading: isUpdating, isError: updateIsError }] =
    useUpdateTopologyMutation();
  const {
    isLoading,
    isFetching,
    isError,
    data: topology,
  } = useGetTopologyQuery(topologyUid);

  return (
    <General
      isLoading={isLoading || isUpdating || isFetching}
      updateTopology={updateTopology}
      topologyToEdit={topology}
      isError={isError || updateIsError}
    />
  );
}
