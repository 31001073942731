import { useCallback, useEffect } from 'react';
import * as d3 from 'd3';
import { setXY, x, y, zoom } from '../../../d3';
import { useWindowResize } from '../../../../../hooks/use-window-resize';
import { HEADER_OFFSET_VAR } from '../../../constants';
import { updateBoxSelector } from '../../utils/utils';

interface UsePosition {
  display: DiagramBoxDisplay;
  nodeRef: React.RefObject<HTMLDivElement>;
  uid: string;
}

export function usePosition({ display, nodeRef, uid }: UsePosition): void {
  const [headerHeightStr] = getComputedStyle(document.body)
    .getPropertyValue(HEADER_OFFSET_VAR)
    .split('px');

  const position = useCallback(() => {
    let displayX = 0;
    let displayY = 0;
    let width = 0;
    let height = 0;
    let transform: d3.ZoomTransform = { k: 1 } as d3.ZoomTransform;
    let zx;
    let zy;
    let tx = 0;
    let ty = 0;

    d3.select<HTMLDivElement, DiagramNode>(nodeRef.current!)
      .datum(display)
      .attr('style', function (d) {
        transform = d3.zoomTransform(this);
        zx = transform.rescaleX(x);
        zy = transform.rescaleY(y);
        displayX = d.x;
        displayY = d.y;
        width = d.width;
        height = d.height;
        tx = zx(displayX);
        ty = zy(displayY);
        return `transform: translate(${tx}px, ${ty}px) scale(${transform.k}); width: ${width}px; height: ${height}px`;
      });

    updateBoxSelector({
      headerHeight: headerHeightStr,
      newHeight: height,
      newWidth: width,
      newX: tx,
      newY: ty,
      scaleFactor: transform.k,
      selection: d3
        .select<SVGRectElement, unknown>(
          `rect[data-box-rect-selector-uid="${uid}"]`,
        )
        .node(),
    });
  }, [display, headerHeightStr, nodeRef, uid]);

  useWindowResize(() => {
    setXY();
    position();
  });

  useEffect(() => {
    setXY();
    position();

    zoom.on(`zoom.${uid}`, function () {
      position();
    });

    return () => {
      zoom.on(`zoom.${uid}`, null);
    };
  }, [display, position, uid]);
}
