import React, { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { shareSessionSchema } from '../../../../../../../../validation';
import { ControlledTextfield } from '../../../../../../../../components/Form';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
export interface ShareFromPanelProps {
  isLoading: boolean;
  sharedWith: ShareUser[];
  addUser: (userEmail: ShareUser) => void;
}

export function SharePanelForm({
  addUser,
  isLoading,
  sharedWith,
}: ShareFromPanelProps): ReactElement {
  const { t } = useTranslation();
  const { errors, control, formState, reset, handleSubmit } =
    useForm<SessionShareFields>({
      defaultValues: { email: '' },
      mode: 'all',
      resolver: yupResolver(shareSessionSchema(sharedWith)),
    });

  const { isDirty, isValid } = formState;

  const handleAdd: SubmitHandler<SessionShareFields> = async (formData) => {
    addUser({ email: formData.email });
    reset();
  };

  return (
    <form>
      <ControlledTextfield<SessionShareFields>
        control={control}
        error={errors?.email?.message}
        label={t('sessions.forms.share.email.label')}
        testId="share-session-email"
        labelClass="col-3"
        name="email"
        required={true}
      />
      <PrimaryButton
        className="base-margin-top dbl-margin-bottom"
        disabled={!isDirty || !isValid}
        testId="share-session-form-submit"
        loading={isLoading}
        onClick={handleSubmit(handleAdd)}
        type="submit"
        colour="secondary"
      >
        {t('buttons.add')}
      </PrimaryButton>
    </form>
  );
}
