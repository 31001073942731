import React, { ReactElement } from 'react';
import classNames from 'classnames';
import {
  ControlledTextarea,
  ControlledTextareaProps,
} from '../../../components';
import styles from './ConditionalControlledTextarea.module.css';

export interface ConditionalControlledTextareaProps<
  T extends Record<string, unknown>,
> extends ControlledTextareaProps<T> {
  condition: boolean;
}

export const ConditionalControlledTextarea = <
  T extends Record<string, unknown>,
>({
  condition,
  className,
  ...rest
}: ConditionalControlledTextareaProps<T>): ReactElement => (
  <div className={classNames({ [styles.hidden]: !condition }, className)}>
    <ControlledTextarea<T> {...rest} />
  </div>
);
