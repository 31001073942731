import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';

export type InternalIpNoDataAlertProps = {
  sessionInternalAddresses?: SessionInternalAddress[];
  isLoading: boolean;
  isError: boolean;
};

export const InternalIpNoDataAlert = ({
  sessionInternalAddresses,
  isLoading,
  isError,
}: InternalIpNoDataAlertProps): ReactElement => {
  const { t } = useTranslation();

  if (
    isLoading ||
    isError ||
    (!isLoading && !isError && sessionInternalAddresses?.length)
  )
    return <></>;

  return (
    <Alert>
      {t('sessions.panels.info.publicIpProxy.noInternalIpConfigured')}
    </Alert>
  );
};
