import * as d3 from 'd3';
import { useCallback } from 'react';
import {
  HEADER_OFFSET_VAR,
  NODE_CONNECTION_DEFAULT_PADDING,
} from '../../../constants';
import {
  calculateNodeMidPoint,
  reDrawMulitpleConnections,
} from '../../Node/utils/utils';
import { updateStrokeDashArrayAttribute } from '../utils';

export interface UseConnection {
  redrawNodeConnections: (uid: string) => void;
}

export function useConnection(): UseConnection {
  const redrawNodeConnections = useCallback((uid) => {
    const currentNode = d3
      .select<HTMLDivElement, unknown>(`[data-component="${uid}"]`)
      .node();

    if (!currentNode) return;

    const currentNodeBounds = currentNode.getBoundingClientRect();

    const [headerHeightStr] = getComputedStyle(document.body)
      .getPropertyValue(HEADER_OFFSET_VAR)
      .split('px');

    const { x: xMid, y: yMid } = calculateNodeMidPoint({
      headerHeight: Number(headerHeightStr),
      nodeBounds: currentNodeBounds,
    });

    d3.selectAll<SVGLineElement, unknown>(`[data-connection-to="${uid}"]`).each(
      function () {
        const { k: scaleFactor } = d3.zoomTransform(this);
        const connection = d3.select(this);
        const strokeType = this.getAttribute('data-connection-stroke-type');

        updateStrokeDashArrayAttribute({
          connection,
          scaleFactor,
          strokeType,
        });

        const currentTotal = this.getAttribute('data-connection-total');
        if (Number(currentTotal) === 1 || Number(currentTotal) === undefined) {
          this.setAttribute('x2', xMid.toString());
          this.setAttribute('y2', yMid.toString());
        } else {
          const toNodeUidFrom = this.getAttribute('data-connection-from');
          const toNode = d3
            .select<HTMLDivElement, unknown>(
              `[data-component="${toNodeUidFrom}"]`,
            )
            .node();

          if (!toNode) return;

          const otherNodeBounds = toNode.getBoundingClientRect();

          const { x: otherXMid, y: otherYMid } = calculateNodeMidPoint({
            headerHeight: Number(headerHeightStr),
            nodeBounds: otherNodeBounds,
          });

          reDrawMulitpleConnections({
            otherXMid,
            otherYMid,
            padding: NODE_CONNECTION_DEFAULT_PADDING * scaleFactor,
            selectedConnection: this,
            xMid,
            yMid,
          });
        }
      },
    );

    d3.selectAll<SVGLineElement, unknown>(
      `[data-connection-from="${uid}"]`,
    ).each(function () {
      const { k: scaleFactor } = d3.zoomTransform(this);

      const currentTotal = this.getAttribute('data-connection-total');
      if (Number(currentTotal) === 1 || Number(currentTotal) === undefined) {
        this.setAttribute('x1', xMid.toString());
        this.setAttribute('y1', yMid.toString());
      } else {
        const otherNodeUidTo = this.getAttribute('data-connection-to');
        const otherNode = d3
          .select<HTMLDivElement, unknown>(
            `[data-component="${otherNodeUidTo}"]`,
          )
          .node();

        if (!otherNode) return;

        const otherNodeBounds = otherNode.getBoundingClientRect();
        const otherXMid = otherNodeBounds.x + otherNodeBounds.width / 2;
        const otherYMid =
          otherNodeBounds.y +
          otherNodeBounds.height / 2 -
          Number(headerHeightStr);

        reDrawMulitpleConnections({
          otherXMid: xMid,
          otherYMid: yMid,
          padding: NODE_CONNECTION_DEFAULT_PADDING * scaleFactor,
          selectedConnection: this,
          xMid: otherXMid,
          yMid: otherYMid,
        });
      }
    });
  }, []);

  return {
    redrawNodeConnections,
  };
}
