import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { ActionsBarLeft } from '../ActionsBarLeft';
import { ActionsBarRight } from '../ActionsBarRight';
import { ConnectedActionsBarCenter } from '../ActionsBarCenter/container';
import styles from './ActionsBar.module.css';

export function ActionsBar(): ReactElement {
  return (
    <div className={classNames('header', styles.root)}>
      <div className="header-panels dbl-padding-left dbl-padding-right">
        <ActionsBarLeft />
        <ConnectedActionsBarCenter />
        <ActionsBarRight />
      </div>
    </div>
  );
}
