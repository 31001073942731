import React, { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Button, SelectOption, Textfield } from 'dcloud-shared-ui';
import {
  ControlledSelect,
  ControlledTextfield,
  Heading,
} from '../../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../../components/Buttons/PrimaryButton';
import { useSessionUserContext } from '../../../../../../../../../hooks/use-session-user-context';
import { addDnsTxtRecord } from '../../../../../../../../../validation';
import styles from './addTxtRecord.module.css';

export interface AddTxtRecordProps {
  isLoading: boolean;
  setShowAddForm: (value: boolean) => void;
  createDnsTxtRecord: RTKMutation<DnsTxtRecord, DnsTxtRecord>;
  domainOptions: SelectOption<string>[];
}

export function AddTxtRecord({
  isLoading,
  createDnsTxtRecord,
  setShowAddForm,
  domainOptions,
}: AddTxtRecordProps): ReactElement {
  const { t } = useTranslation();
  const { errors, control, formState, handleSubmit } =
    useForm<DnsTxtRecordFields>({
      defaultValues: {
        domainName: domainOptions.length == 2 ? domainOptions[1].value : '',
        value: '',
      },
      mode: 'all',
      resolver: yupResolver(addDnsTxtRecord),
    });

  const { sessionUid, versionUid } = useSessionUserContext();

  const { isDirty, isValid } = formState;
  const handleSave: SubmitHandler<DnsTxtRecord> = async (formData) => {
    const { error } = await createDnsTxtRecord({
      domainName: formData.domainName,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
      value: formData.value,
    });
    !error && setShowAddForm(false);
  };

  return (
    <form>
      <Heading
        className="dbl-padding"
        headingText={t('sessions.panels.info.dns.addForm.labels.recordType')}
        level="h5"
        size="16"
        align="left"
        weight="200"
      />
      <Textfield
        disabled
        data-testid="add-dns-txt-label"
        value={t('sessions.panels.info.dns.addForm.labels.txt')}
      ></Textfield>
      <ControlledSelect<DnsTxtRecord>
        control={control}
        options={domainOptions}
        className={styles.domainSelect}
        error={errors?.domainName?.message}
        data-testid="add-dns-txt-select"
        name="domainName"
        label={t('sessions.panels.info.dns.addForm.labels.domain')}
        required={true}
      />
      <ControlledTextfield<DnsTxtRecord>
        control={control}
        error={errors?.value?.message}
        label={t('sessions.panels.info.dns.addForm.labels.name')}
        data-testid="add-dns-txt-name"
        name="value"
        required={true}
      />

      <div className={'dbl-margin-top'}>
        <PrimaryButton
          disabled={!isDirty || !isValid || isLoading}
          testId="dns-record-save"
          size="default"
          onClick={handleSubmit(handleSave)}
          type="submit"
          colour="success"
        >
          {t('buttons.save')}
        </PrimaryButton>
        <Button
          disabled={isLoading}
          size="default"
          colour="ghost"
          type="button"
          onClick={() => setShowAddForm(false)}
        >
          {t('buttons.cancel')}
        </Button>
      </div>
    </form>
  );
}
