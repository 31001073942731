import * as d3 from 'd3';
import { useCallback, useEffect } from 'react';
import { useWindowResize } from '../../../../../hooks/use-window-resize';
import { setXY, x, y, zoom } from '../../../d3';
import { UseConnection } from '../../Connections/hooks/use-connection';

interface UsePosition {
  display?: DiagramDisplay;
  nodeRef: React.RefObject<HTMLDivElement>;
  uid: string;
  redrawNodeConnections?: UseConnection['redrawNodeConnections'];
  defaultPosition: DiagramDisplay;
}

export function usePosition({
  display,
  nodeRef,
  uid,
  redrawNodeConnections,
  defaultPosition,
}: UsePosition): void {
  const position = useCallback(() => {
    d3.select<HTMLDivElement, DiagramNode>(nodeRef.current!)
      .datum(display)
      .attr('style', function (d) {
        const transform = d3.zoomTransform(this);
        const zx = transform.rescaleX(x).interpolate(d3.interpolateRound);
        const zy = transform.rescaleY(y).interpolate(d3.interpolateRound);

        return `transform: translate(${zx(
          d?.x1 !== undefined ? d?.x1 : defaultPosition.x1!,
        )}px, ${zy(d?.y1 !== undefined ? d?.y1 : defaultPosition.y1!)}px) scale(${transform.k});`;
      });
  }, [defaultPosition.x1, defaultPosition.y1, display, nodeRef]);

  useWindowResize(() => {
    setXY();
    position();
    redrawNodeConnections && redrawNodeConnections(uid);
  });

  useEffect(() => {
    position();

    zoom.on(`zoom.${uid}`, function () {
      position();
      redrawNodeConnections && redrawNodeConnections(uid);
    });

    return () => {
      zoom.on(`zoom.${uid}`, null);
    };
  }, [position, uid, redrawNodeConnections]);
}
