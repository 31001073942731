import { LinkButton, IconButton, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { MainRoutes } from '../../../../constants/routes';
import styles from './TopologyActions.module.css';

export interface TopologyActionsProps {
  className?: string;
  canClone?: boolean;
  canDelete?: boolean;
  onClone: VoidFunction;
  onDelete: VoidFunction;
  uid: string;
}

export function TopologyActions({
  className = undefined,
  canClone = false,
  onClone,
  onDelete,
  uid,
}: TopologyActionsProps): ReactElement {
  const TOPOLOGY_PATH = MainRoutes.Edit.replace(':uid', uid);

  return (
    <ul
      aria-label="Card actions"
      className={classnames(styles.root, className)}
    >
      <li>
        <LinkButton
          to={TOPOLOGY_PATH}
          className={styles.action}
          colour="link"
          testId={`topology-card-edit-${uid}`}
        >
          <Icon name="pencil" />
        </LinkButton>
      </li>
      <li>
        <IconButton
          className={styles.action}
          icon="delete"
          colour="link"
          onClick={onDelete}
          testId={`topology-card-delete-${uid}`}
        />
      </li>
      {canClone && (
        <li>
          <IconButton
            className={styles.action}
            icon="clone"
            colour="link"
            onClick={onClone}
            testId={`topology-card-clone-${uid}`}
          />
        </li>
      )}
    </ul>
  );
}
