import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './NatRulesTableActions.module.css';

export interface NatRulesTableActionsProps {
  onDeleteClick: VoidFunction;
}

export function NatRulesTableActions({
  onDeleteClick,
}: NatRulesTableActionsProps): ReactElement {
  return (
    <ul aria-label="Card actions" className={styles.root}>
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={onDeleteClick}
          testId="nat-rules-table-actions-delete"
        >
          <Icon name="delete" />
        </Button>
      </li>
    </ul>
  );
}
