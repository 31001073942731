import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from 'dcloud-shared-ui';
import { isEqual } from 'lodash';
import styles from '../Integrations/Integrations.module.css';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { PageHeading } from '../../components/PageHeading';
import {
  ControlledSelect,
  ControlledTextarea,
  ControlledTextfield,
} from '../../components';
import { integrationFormFieldsSchema } from '../../validation';
import { useLeaveFormPrompt } from '../EditTopology/hooks/useLeaveFormPrompt';
import { FormUnsavedChangesPrompt } from '../../components/FormUnsavedChangesPrompt';
import { ControlledIconPicker } from '../../components/Form/ControlledIconPickerButton/ControlledIconPicker';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import { IntegrationInputAccordions } from '../Integrations/components/IntegrationInput/IntegrationInputAccordions';
import {
  parseChangeEventToNumber,
  parseNumberToString,
} from '../EditTopology/configuration/components/VirtualMachineEntities/Edit/utils';
import {
  INTEGRATION_TYPE_OPTIONS,
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
  UNAVAILABLE_STATUS,
} from '../../constants';
import { getAvailableIconsForIntegrationType } from '../EditTopology/configuration/SaasIntegrations/Configure/components/ConfigureFormIconInput/utils';
import { ConditionalControlledTextarea } from '../components/ConditionalControlledTextarea/ConditionalControlledTextarea';
import { useClearIntegrationStatusMessage } from '../hooks/use-clear-integration-status-message/use-clear-integration-status-message';
import { useTriggerIntegrationFormValidation } from '../hooks/use-trigger-integration-form-validation/use-trigger-integration-form-validation';
import { buildWorkflowParamsPayload } from '../utils/workFlowParameterMappingUtil';
import { ConnectedLicenseForm } from './components/LicenseForm/LicenseFormContainer';
import { CreateIntegrationSkeleton } from './components/CreateIntegrationSkeleton';
import {
  DEFAULT_MAX_AMOUNT_PER_TOPOLOGY,
  DEFAULT_PUBLIC_CLOUD_ICON,
  DEFAULT_SAAS_ICON,
  DEFAULT_SESSION_VIEW,
  DESCRIPTION_FIELD_NUM_ROWS,
  DETAILS_FIELD_NUM_ROWS,
  SESSION_VIEW_OPTIONS,
  STATUS_MESSAGE_FIELD_NUM_ROWS,
} from './constants';
import { ConnectedTagForm } from './components/TagsForm/container';
import { SelectIntegrationIcon } from './components/SelectIcon';

type CreateIntegrationProps = {
  addIntegration: (integrationToSave: IntegrationPayload) => void;
  integrationToSave?: IntegrationToSave;
  isLoading: boolean;
  integrations?: Integration[];
  integrationAvailabilityOptions: SelectOption<string>[];
};

export function CreateIntegration({
  integrations,
  addIntegration,
  integrationToSave,
  isLoading,
  integrationAvailabilityOptions,
}: CreateIntegrationProps): ReactElement {
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [tagError, setTagError] = useState(false);
  const { t } = useTranslation();

  const {
    control,
    control: { setValue },
    errors,
    formState: { isValid, dirtyFields },
    reset,
    watch,
    handleSubmit,
    trigger,
  } = useForm<IntegrationTemplateDetailsFormFields>({
    defaultValues: {
      availabilityStatus: '',
      availabilityStatusMessage: '',
      description: '',
      details: '',
      icon: '',
      inventoryWorkflowName: integrationToSave?.inventoryWorkflowName ?? '',
      maxAmountPerTopology: DEFAULT_MAX_AMOUNT_PER_TOPOLOGY,
      name: integrationToSave?.name ?? '',
      sessionView: DEFAULT_SESSION_VIEW,
      type: undefined,
    },
    mode: 'all',
    resolver: yupResolver(
      integrationFormFieldsSchema(
        integrations?.map((integration) => integration.name) || [],
      ),
    ),
  });

  const watchAvailabilityStatus = watch('availabilityStatus');

  useTriggerIntegrationFormValidation<IntegrationTemplateDetailsFormFields>({
    fieldsToValidate: {
      availabilityStatusMessage: watchAvailabilityStatus === UNAVAILABLE_STATUS,
    },
    trigger,
  });

  const isFormDirty =
    Object.keys(dirtyFields).length > 0 ||
    !isEqual(integrationToSave?.fetchedInputs, integrationToSave?.inputs) ||
    !!integrationToSave?.tags?.length ||
    !!integrationToSave?.integrationLicenses?.length;
  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isFormDirty);
  const watchAvailabilityStatusMessage = watch('availabilityStatusMessage');
  const isStatusAvailabilityMessageFieldVisible =
    watchAvailabilityStatus === UNAVAILABLE_STATUS;

  useClearIntegrationStatusMessage({
    availabilityStatusMessage: watchAvailabilityStatusMessage,
    isStatusAvailabilityMessageFieldVisible,
    setValue,
  });

  const onSaveClick = ({
    availabilityStatus,
    availabilityStatusMessage,
    ...formValues
  }: IntegrationTemplateDetailsFormFields) => {
    const availabilityStatusMessageProperty =
      availabilityStatus === UNAVAILABLE_STATUS
        ? { message: availabilityStatusMessage }
        : {};
    addIntegration({
      ...formValues,
      availability: {
        ...availabilityStatusMessageProperty,
        status: availabilityStatus,
      },
      icon: selectedIcon,
      inputs: buildWorkflowParamsPayload(
        integrationToSave?.inputs?.map((input) => ({
          ...input,
          defaultValue:
            integrationToSave?.fetchedInputs?.find(
              (fetchedInput) => fetchedInput.name === input.name,
            )?.default || '',
          mappingName: input.name,
          value: input.default,
        })),
      ),
      integrationLicenses: integrationToSave?.integrationLicenses,
      inventoryWorkflowId: integrationToSave?.inventoryWorkflowId || '',
      tags: integrationToSave?.tags,
    });
    reset();
  };

  const renderCtaBtn = () => {
    const isParametersInvalid = Object.values(
      integrationToSave?.inputStatuses || {},
    ).includes(false);
    return (
      <PrimaryButton
        colour="success"
        disabled={!isValid || isParametersInvalid || tagError}
        onClick={handleSubmit(onSaveClick)}
      >
        {t('buttons.save')}
      </PrimaryButton>
    );
  };

  const handleUpdateIcon = (newSelection: string) => {
    setSelectedIcon(newSelection);
    setValue('icon', newSelection, {
      shouldDirty: true,
    });
  };

  const integrationType = watch('type');
  const icons = integrationType
    ? getAvailableIconsForIntegrationType(integrationType)
    : [];

  const sessionViewOptions = SESSION_VIEW_OPTIONS.map((option) => ({
    name: t(`integrations.forms.common.sessionView.values.${option.label}`),
    value: option.value,
  }));

  const handleIntegrationTypeChange = (integrationType: string) => {
    switch (integrationType) {
      case SAAS_INTEGRATION_TYPE:
        setSelectedIcon(DEFAULT_SAAS_ICON);
        break;
      case PUBLIC_CLOUD_INTEGRATION_TYPE:
        setSelectedIcon(DEFAULT_PUBLIC_CLOUD_ICON);
        break;
      default:
        setSelectedIcon('');
    }
  };

  return (
    <DefaultLayout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <PageHeading
              ctaBtn={renderCtaBtn()}
              pageTitle={t('integrations.forms.add.title')}
              withBackBtn={true}
            />
            {isLoading ? (
              <CreateIntegrationSkeleton />
            ) : (
              <>
                <form>
                  <div className={styles.form}>
                    <div className="row">
                      <div className="col-6">
                        <ControlledTextfield<IntegrationTemplateDetailsFormFields>
                          control={control}
                          error={errors?.name?.message}
                          label={t('integrations.forms.common.name.label')}
                          name="name"
                          required={true}
                        />
                      </div>
                      <div className="col-6">
                        <ControlledTextfield<IntegrationTemplateDetailsFormFields>
                          control={control}
                          label={t('integrations.forms.common.recipe.label')}
                          name="inventoryWorkflowName"
                          required={true}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row half-padding-top half-padding-bottom">
                      <div className="col-6 flex flex-middle">
                        <ControlledTextfield
                          control={control}
                          label={t(
                            'integrations.forms.common.maxAmountPerTopology.label',
                          )}
                          name="maxAmountPerTopology"
                          required={true}
                          testId="create-integration-maxAmountPerTopology"
                          isNumber={true}
                          error={errors?.maxAmountPerTopology?.message}
                          transform={{
                            input: parseNumberToString,
                            output: parseChangeEventToNumber,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <ControlledSelect
                              control={control}
                              label={t(
                                'integrations.forms.common.integrationType.name',
                              )}
                              name="type"
                              required={true}
                              testId="create-integration-type"
                              error={errors?.type?.message}
                              options={INTEGRATION_TYPE_OPTIONS}
                              customOnChange={(event) =>
                                handleIntegrationTypeChange(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row half-padding-top half-padding-bottom">
                      <div className="col-3">
                        <ControlledSelect
                          control={control}
                          label={t(
                            'integrations.forms.common.availabilityStatus.label',
                          )}
                          name="availabilityStatus"
                          required={true}
                          testId="create-integration-availability"
                          error={errors?.availabilityStatus?.message}
                          options={integrationAvailabilityOptions}
                        />
                      </div>
                    </div>
                    <ConditionalControlledTextarea
                      className="half-padding-top half-padding-bottom"
                      condition={watchAvailabilityStatus === UNAVAILABLE_STATUS}
                      control={control}
                      error={errors?.availabilityStatusMessage?.message}
                      label={t(
                        'integrations.forms.common.availabilityStatusMessage.label',
                      )}
                      placeholder={t(
                        'integrations.forms.common.availabilityStatusMessage.placeholder',
                      )}
                      name="availabilityStatusMessage"
                      rows={STATUS_MESSAGE_FIELD_NUM_ROWS}
                      required={true}
                    />
                    <div>
                      <div className="half-padding-top half-padding-bottom">
                        <ControlledTextarea<IntegrationTemplateDetailsFormFields>
                          control={control}
                          error={errors?.description?.message}
                          label={t(
                            'integrations.forms.common.description.label',
                          )}
                          name="description"
                          rows={DESCRIPTION_FIELD_NUM_ROWS}
                          required={true}
                        />
                        <ControlledTextarea<IntegrationTemplateDetailsFormFields>
                          control={control}
                          error={errors?.details?.message}
                          label={t('integrations.forms.common.details.label')}
                          name="details"
                          rows={DETAILS_FIELD_NUM_ROWS}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row half-padding">
                      <div className="col-6">
                        <ControlledSelect
                          options={sessionViewOptions}
                          control={control}
                          label={t(
                            'integrations.forms.common.sessionView.label',
                          )}
                          name="sessionView"
                          required={true}
                        />
                        {integrationType ? (
                          <ControlledIconPicker
                            control={control}
                            icons={icons}
                            onChange={handleUpdateIcon}
                            selectedIcon={selectedIcon}
                            iconPickerLabel={t(
                              'integrations.forms.common.icon.label',
                            )}
                            changeIconLabel={t('buttons.changeIcon')}
                          />
                        ) : (
                          <SelectIntegrationIcon />
                        )}
                      </div>
                      <div className="col-6">
                        <ConnectedLicenseForm />
                        <ConnectedTagForm
                          tagError={tagError}
                          setTagError={setTagError}
                        />
                      </div>
                    </div>
                    <div className="half-padding">
                      <IntegrationInputAccordions
                        inputsToCreate={integrationToSave?.fetchedInputs}
                        inputStatuses={integrationToSave?.inputStatuses}
                      />
                    </div>
                  </div>
                </form>
                <FormUnsavedChangesPrompt
                  showModal={showModal}
                  onCancel={handleCancel}
                  onConfirm={handleConfirm}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
