import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button } from 'dcloud-shared-ui';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ControlledSelect,
  ControlledTextfield,
  Modal,
} from '../../../../components';
import { PLEASE_SELECT_OPTION } from '../../../../constants/form';
import { MainRoutes } from '../../../../constants/routes';
import { createIntegrationModalFieldsSchema } from '../../../../validation';
import styles from './../../Integrations.module.css';
import { CreateIntegrationModalSkeleton } from './components/CreateIntegrationModalSkeleton';

type CreateIntegrationModalProps = {
  isLoading: boolean;
  onClose: () => void;
  recipes?: Recipe[];
  setIntegrationToSave: (integrationToSave: IntegrationToSave) => void;
  integrations?: Integration[];
};

export const CreateIntegrationModal = ({
  isLoading,
  onClose,
  recipes,
  setIntegrationToSave,
  integrations,
}: CreateIntegrationModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    control,
    errors,
    formState: { isValid },
  } = useForm<AddIntegrationModalFormFields>({
    defaultValues: {
      inventoryWorkflowId: '',
      name: '',
    },
    mode: 'all',
    resolver: yupResolver(
      createIntegrationModalFieldsSchema(
        integrations?.map((integration) => integration.name) || [],
      ),
    ),
  });
  const history = useHistory();
  const onAddClick = () => {
    const selectedRecipe = recipes!.find(
      (recipe) => recipe.id === control.getValues().inventoryWorkflowId,
    );
    setIntegrationToSave({
      fetchedInputs: [],
      inputs: [],
      inventoryWorkflowId: control.getValues().inventoryWorkflowId,
      inventoryWorkflowName: selectedRecipe!.name,
      name: control.getValues().name,
    });
    history.push(MainRoutes.CreateIntegration);
  };
  return (
    <Modal
      title={t('integrations.modals.add.title')}
      clickScreenToClose={true}
      show={true}
      testId={'new-integration-modal'}
      headerAlignment="center"
      onClose={onClose}
    >
      {isLoading ? (
        <CreateIntegrationModalSkeleton />
      ) : (
        <form>
          <ControlledTextfield<AddIntegrationModalFormFields>
            control={control}
            error={errors?.name?.message}
            label={t('integrations.forms.common.name.label')}
            name="name"
            required={true}
          />
          <ControlledSelect<AddIntegrationModalFormFields>
            control={control}
            error={errors?.inventoryWorkflowId?.message}
            name="inventoryWorkflowId"
            options={
              recipes
                ? [
                    PLEASE_SELECT_OPTION,
                    ...recipes.map((recipe) => ({
                      name: recipe.name,
                      value: recipe.id,
                    })),
                  ]
                : []
            }
            label={t('integrations.forms.common.recipe.label')}
            required={true}
            testId="integration-form-recipe-select"
          />
          <div className="flex flex-center">
            <Button
              colour="ghost"
              className={classnames(styles.addFormButtons, 'dbl-margin-top')}
              onClick={onClose}
              testId="integrations-form-cancel-btn"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className={classnames(styles.addFormButtons, 'dbl-margin-top')}
              testId="integrations-form-submit-btn"
              onClick={onAddClick}
              disabled={!isValid}
            >
              {t('buttons.add')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
