import React, { ReactElement } from 'react';
import { IntegrationPanelParameters } from './IntegrationPanelParameters';
import { IntegrationPanelActionButtons } from './IntegrationPanelActionButtons';

type IntegrationPanelSectionProps = {
  section: SessionIntegrationSection;
};

export function IntegrationPanelSection({
  section,
}: IntegrationPanelSectionProps): ReactElement {
  return (
    <div className="half-margin-bottom">
      <div className="half-padding-bottom">{section.title}</div>
      <div>
        <IntegrationPanelParameters parameters={section.parameters} />
      </div>
      <div>
        <IntegrationPanelActionButtons actions={section.actions} />
      </div>
    </div>
  );
}
