import { Checkbox, FormGroup } from 'dcloud-shared-ui';
import React, { ChangeEvent, ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ControlledFieldProps } from '../types';

export interface ControlledCheckboxProps<T extends Record<string, unknown>>
  extends Pick<ControlledFieldProps<T>, 'className' | 'control' | 'label'> {
  defaultValue?: boolean;
  disabled?: boolean;
  id: keyof T;
  customId?: string;
  inline?: boolean;
  testId?: string;
  customOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function ControlledCheckbox<T extends Record<string, unknown>>({
  className,
  control,
  defaultValue = false,
  disabled = false,
  label,
  id,
  customId,
  inline = false,
  testId,
  customOnChange,
}: ControlledCheckboxProps<T>): ReactElement {
  const typedId = id as string;

  return (
    <Controller
      name={typedId}
      control={control as Control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <FormGroup inline={inline} className={className}>
          <Checkbox
            label={label}
            disabled={disabled}
            id={customId || typedId}
            onChange={(event) => {
              customOnChange && customOnChange(event);
              return onChange(event.target.checked);
            }}
            checked={value}
            data-testid={testId}
          />
        </FormGroup>
      )}
    />
  );
}
