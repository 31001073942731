import { Button } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { ButtonGroup } from '../../../../../../components';
import styles from './StartStopButtonGroup.module.css';

export interface StartStopButtonGroupProps {
  buttonUnorderedLabel: string;
  buttonOrderedLabel: string;
  handleButtonUnorderedOnClick: VoidFunction;
  handleButtonOrderedOnClick: VoidFunction;
  isUnorderedButtonSelected: boolean;
}

export function StartStopButtonGroup({
  buttonUnorderedLabel,
  buttonOrderedLabel,
  handleButtonUnorderedOnClick,
  handleButtonOrderedOnClick,
  isUnorderedButtonSelected = false,
}: StartStopButtonGroupProps): ReactElement {
  return (
    <div className="dbl-margin-bottom">
      <ButtonGroup className={styles.root} type="default">
        <Button
          className={classnames(
            styles.startStopButton,
            isUnorderedButtonSelected ? styles.active : styles.inactive,
          )}
          colour="ghost"
          onClick={
            !isUnorderedButtonSelected
              ? handleButtonUnorderedOnClick
              : undefined
          }
        >
          {buttonUnorderedLabel}
        </Button>
        <Button
          className={classnames(
            styles.startStopButton,
            !isUnorderedButtonSelected ? styles.active : styles.inactive,
          )}
          colour="ghost"
          onClick={
            isUnorderedButtonSelected ? handleButtonOrderedOnClick : undefined
          }
        >
          {buttonOrderedLabel}
        </Button>
      </ButtonGroup>
    </div>
  );
}
