import React, { ReactElement } from 'react';
import { Dropdown } from '../../../../../../../components/Dropdown';
import { HardwareTableActionsCellRendererProps } from './container';
import { ConsoleHrefDropdownItem } from './components/ConsoleHrefDropdownItem';
import { PowerControlDropdownItem } from './components/PowerControlDropdownItem';

export const HardwareTableActionsCellRenderer = ({
  consoleHref,
  deviceId,
  isPowerControlEnabled,
  name,
  nodeType,
  nodeUid,
  setShowConfirmForActionForHw,
}: HardwareTableActionsCellRendererProps): ReactElement => {
  const handlePowerResetOnClick = () => {
    setShowConfirmForActionForHw({
      actionType: 'RESET',
      deviceId: deviceId!,
      name,
      nodeType,
      nodeUid,
    });
  };

  return (
    <Dropdown>
      <ConsoleHrefDropdownItem consoleHref={consoleHref} />
      <PowerControlDropdownItem
        deviceId={deviceId}
        isPowerControlEnabled={isPowerControlEnabled}
        handlePowerResetOnClick={handlePowerResetOnClick}
      />
    </Dropdown>
  );
};
