type NatRuleTypesObject = Record<string, NatRuleType>;

type NatRuleScopesObject = Record<string, NatRuleScope>;

type NatRuleTargetTypesObject = Record<string, NatRuleTargetType>;

export const NAT_RULE_TYPES: NatRuleTypesObject = {
  internalIp: 'INTERNAL_IP',
  internalVmNic: 'INTERNAL_VM_NIC',
  publicIp: 'PUBLIC_IP',
  publicVmNic: 'PUBLIC_VM_NIC',
};

export const NAT_RULE_TARGET_TYPES: NatRuleTargetTypesObject = {
  ip: 'IP',
  vmNic: 'VM_NIC',
};

export const NAT_RULE_SCOPES: NatRuleScopesObject = {
  internal: 'INTERNAL',
  public: 'PUBLIC',
};
