import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TopologyViewTypes } from '../../entities/topologies/constants';
import { prepTopologiesWithOwner } from '../../entities/topologies/utils';
import { RootState } from '../store';
import { getUserPrefs } from '../../utils/userPref';

interface TopologiesFiltersState {
  filteredTopologies: Topology[];
  showFilters: string[];
  dataCenterFilters: string[];
  topologies: Topology[];
  isOpen: boolean;
  searchValue: string;
  topologiesViewType?: TopologyViewTypes;
  isApplyingFilters: boolean;
}

const existingUserPrefs = getUserPrefs();

export const initialState: TopologiesFiltersState = {
  dataCenterFilters: existingUserPrefs.dataCenterFilters,
  filteredTopologies: [],
  isApplyingFilters: false,
  isOpen: false,
  searchValue: '',
  showFilters: existingUserPrefs.filters,
  topologies: [],
  topologiesViewType: existingUserPrefs.topologiesViewType,
};

export const topologiesFiltersSlice = createSlice({
  initialState,
  name: 'topologiesFilters',
  reducers: {
    addDataCenterFilter: (state, action: PayloadAction<string>) => {
      state.dataCenterFilters.push(action.payload);
    },
    addFilter: (state, action: PayloadAction<string>) => {
      state.showFilters.push(action.payload);
    },
    deleteDataCenterFilter: (state, action: PayloadAction<string>) => {
      state.dataCenterFilters = state.dataCenterFilters.filter(
        (dataCenterFilter) => dataCenterFilter !== action.payload,
      );
    },
    deleteFilter: (state, action: PayloadAction<string>) => {
      state.showFilters = state.showFilters.filter(
        (showFilter) => showFilter !== action.payload,
      );
    },
    setFilteredToplogies: (state, action: PayloadAction<Topology[]>) => {
      state.filteredTopologies = action.payload;
    },
    setIsApplyingFilters: (state, action: PayloadAction<boolean>) => {
      state.isApplyingFilters = action.payload;
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setTopologies: (state, action: PayloadAction<Topology[]>) => {
      state.topologies = prepTopologiesWithOwner(action.payload);
    },
    setTopologiesViewType: (
      state,
      action: PayloadAction<TopologyViewTypes>,
    ) => {
      state.topologiesViewType = action.payload;
    },
  },
});

export const {
  reducer: topologiesFiltersReducer,
  actions: {
    addFilter,
    addDataCenterFilter,
    deleteFilter,
    deleteDataCenterFilter,
    setTopologies,
    setIsOpen,
    setSearchValue,
    setTopologiesViewType,
    setFilteredToplogies,
    setIsApplyingFilters,
  },
} = topologiesFiltersSlice;

const getTopologiesFilters = (state: RootState) => state.topologiesFilters;

export const topologiesFiltersSelectors = {
  getDataCentersFilters: createSelector(
    getTopologiesFilters,
    ({ dataCenterFilters }) => dataCenterFilters,
  ),
  getFilteredTopologies: createSelector(
    getTopologiesFilters,
    ({ filteredTopologies }) => filteredTopologies,
  ),
  getIsApplyingFilters: createSelector(
    getTopologiesFilters,
    ({ isApplyingFilters }) => isApplyingFilters,
  ),
  getIsOpen: createSelector(getTopologiesFilters, ({ isOpen }) => isOpen),
  getSearchValue: createSelector(
    getTopologiesFilters,
    ({ searchValue }) => searchValue,
  ),
  getShowFilters: createSelector(
    getTopologiesFilters,
    ({ showFilters }) => showFilters,
  ),
  getTopologies: createSelector(
    getTopologiesFilters,
    ({ topologies }) => topologies,
  ),
  getTopologiesViewType: createSelector(
    getTopologiesFilters,
    ({ topologiesViewType }) => topologiesViewType,
  ),
};
