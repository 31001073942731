import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useGetIntegrationTagsQuery } from '../../../../redux/integrations/api';
import {
  addTag,
  integrationSelectors,
  setTags,
} from '../../../../redux/integrations/slice';
import { RootState } from '../../../../redux/store';
import { TagsFormSkeleton } from '../../../../components/Tags/TagsFormSkeleton';
import TagsForm from '.';

const TagsFormContainer = ({
  addTag,
  integrationToSave,
  setTags,
  tagError,
  setTagError,
}: CreateIntegrationContainerProps): ReactElement => {
  const {
    data: fetchedIntegrationTags,
    isLoading,
    isError,
  } = useGetIntegrationTagsQuery(undefined);
  return !fetchedIntegrationTags || isLoading || isError ? (
    <TagsFormSkeleton />
  ) : (
    <TagsForm
      addTag={addTag}
      integrationToSave={integrationToSave || {}}
      setTags={setTags}
      tags={fetchedIntegrationTags.tags}
      setTagError={setTagError}
      tagError={tagError}
    />
  );
};

type OwnProps = {
  tagError: boolean;
  setTagError: (value: boolean) => void;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  integrationToSave: integrationSelectors.getIntegrationToSave(state),
  setTagError: ownProps.setTagError,
  tagError: ownProps.tagError,
});

const mapDispatchToProps = {
  addTag,
  setTags,
};

type CreateIntegrationContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedTagForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TagsFormContainer);
