import { dcloudApi } from '../../api';
import { VIDEOS_PATH } from '../../../api/paths';
import i18n from '../../../i18n';

interface GetVideosResponse {
  videoShowcases: VideoCategory[];
}

export const videosApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getVideos: build.query<GetVideosResponse, undefined>({
      query: () => ({
        errorMessage: i18n.t('videos.fetchError'),
        method: 'GET',
        url: VIDEOS_PATH,
      }),
    }),
  }),
});

export const { useGetVideosQuery } = videosApi;
