import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { Table } from '../../../../../../components/Table';
import { CreateTopologyRoutes } from '../../../../constants';
import { MainRoutes } from '../../../../../../constants/routes';
import { PUBLISHED_DATE_FORMAT } from './constants';

export interface ImportDemosTableProps {
  demos: InventoryDemo[];
  isLoading: boolean;
  setDemoToImport: (demo: InventoryDemo) => void;
}

export const DEMOS_TABLE_ROW_LIMIT = 15;

export function ImportDemosTable({
  demos,
  isLoading,
  setDemoToImport,
}: ImportDemosTableProps): ReactElement {
  const { t } = useTranslation();
  const columns = useMemo<Column<InventoryDemo>[]>(
    () => [
      {
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }: CellProps<InventoryDemo>) => (
          <Link
            to={`${MainRoutes.CreateTopology}${CreateTopologyRoutes.Details}`}
            onClick={() => {
              setDemoToImport(row.original);
            }}
          >
            {value}
          </Link>
        ),
        Header: () => t('createTopology.demo.import.demos.name'),
        accessor: 'name',
      },
      {
        Header: () => t('createTopology.demo.import.demos.id'),
        accessor: 'id',
        maxWidth: 25,
      },
      {
        Cell: ({ value }) =>
          value ? format(new Date(value), PUBLISHED_DATE_FORMAT) : null,
        Header: () => t('createTopology.demo.import.demos.publishDate'),
        accessor: 'publishedOn',
        maxWidth: 50,
      },
    ],
    [setDemoToImport, t],
  );

  return (
    <Table<InventoryDemo>
      ariaLabel="Import Demos table"
      columns={columns}
      loading={isLoading}
      data={demos}
      initialState={{
        pageSize: DEMOS_TABLE_ROW_LIMIT,
        sortBy: [{ desc: false, id: 'name' }],
      }}
    />
  );
}
