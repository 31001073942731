import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import {
  determineRootStyles,
  determineTopologyLimitsToolBarItemIcon,
  determineTopologyLimitsToolBarItemIconStyles,
} from '../TopologyLimitsToolBarItem/utils';
import styles from '../TopologyLimitsToolBarItem/TopologyLimitsToolBarItem.module.css';

export type TopologyLimitsToolBarItemNoDataProps = {
  label: TopologyLimitsDisplayLabels;
};

export const TopologyLimitsToolBarItemNoData = ({
  label,
}: TopologyLimitsToolBarItemNoDataProps): ReactElement => {
  return (
    <>
      <div
        className={determineRootStyles(true)}
        data-testid={`demo-max-toolbar-item-${label}`}
      >
        <span className={determineTopologyLimitsToolBarItemIconStyles(true)}>
          {<Icon name={determineTopologyLimitsToolBarItemIcon(true)} />}
        </span>
        <span className={styles.label}>{`${label}: --/--`}</span>
      </div>
    </>
  );
};
