import { useEffect, useRef, RefObject } from 'react';
import * as d3 from 'd3';
import { setXY, zoom } from '../d3';
import { useGrid } from './use-grid';

interface UseCanvasReturn {
  canvasRef: RefObject<HTMLDivElement>;
}

/**
 * This is the number that is used to cacluate the x,y domain.
 * A larger number will give you a larger grid.
 */

export function useCanvas(): UseCanvasReturn {
  const canvasRef = useRef<HTMLDivElement>(null);
  const draw = useGrid();

  /**
   * This effect is to run *only* on load. This is to initialize the d3 zoom,
   * domain and x,y. This can't be merged with the effect below as
   * this will cause the zoom to re-initialize when the props change.
   */
  useEffect(() => {
    const canvas = d3.select<HTMLDivElement, unknown>(canvasRef.current!);
    setXY();
    canvas.call(zoom).call(zoom.transform, d3.zoomIdentity);
  }, []);

  /**
   * Effect that is run on every prop change at the moment.
   */
  useEffect(() => {
    function onResize() {
      setXY();
      draw();
    }

    draw();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [draw]);

  /**
   * Effect to setup the zoom functionality.
   * This has to be re-initialized everytime the "diagramNodes" changes
   */
  useEffect(() => {
    const zoomed = () => {
      if (!canvasRef.current) return;
      draw();
    };

    zoom.on('zoom', zoomed);

    return () => {
      zoom.on('zoom', null);
    };
  }, [draw]);

  return {
    canvasRef,
  };
}
