import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { SESSION_INVENTORY_SIDE_TABS } from '../../constants';
import { SessionInventorySideTab } from './components/SessionInventorySideTab';
import { SessionInventorySideTabsProps } from './container';

export function SessionInventorySideTabs({
  activeTab,
  setSessionInventoryActiveTab,
}: SessionInventorySideTabsProps): ReactElement {
  return (
    <nav aria-label="side-nav" className="">
      <ul
        className={classNames(
          'tabs',
          'tabs--vertical',
          'hidden-xs',
          'hidden-sm',
        )}
      >
        {SESSION_INVENTORY_SIDE_TABS.map(({ label }) => (
          <SessionInventorySideTab
            key={label}
            label={label}
            isActive={activeTab === label}
            handleOnClick={() => setSessionInventoryActiveTab(label)}
          />
        ))}
      </ul>
    </nav>
  );
}
