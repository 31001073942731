import i18n from '../../../../../i18n';
const PATH_TO_TEXT = 'sessionsInventory.progressBar';

export const buildMessage = (
  complete: number,
  total: number,
  errors: number,
): string => {
  const part1 = `${complete} of ${total} ${i18n.t(`${PATH_TO_TEXT}.complete`)}`;
  const part2 =
    errors === 0
      ? ``
      : errors > 1
      ? `(${i18n.t(`${PATH_TO_TEXT}.with`)} ${errors} ${i18n.t(
          `${PATH_TO_TEXT}.errors`,
        )})`
      : `(${i18n.t(`${PATH_TO_TEXT}.with`)} ${errors} ${i18n.t(
          `${PATH_TO_TEXT}.error`,
        )})`;
  return `${part1} ${part2}`;
};

export const buildPercentage = (complete: number, total: number): number => {
  return Math.round(100 * (complete / total));
};
