import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { Toast } from '../Toast';
import { ToastsContainerReduxProps } from './container';
import styles from './ToastsContainer.module.css';

interface ToastsContainerProps extends ToastsContainerReduxProps {
  className?: string;
}

export function ToastsContainer({
  className,
  toasts,
}: ToastsContainerProps): ReactElement {
  return (
    <div className={classnames(className, styles.root)}>
      {toasts.map((toast) => (
        <Toast {...toast} key={toast.uid} />
      ))}
    </div>
  );
}
