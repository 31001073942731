export const mergeGridData = (
  existingRecords: GridEditorRecord[],
  modifiedRecord: GridEditorRecord,
  modifiedRowIndex: number | null | undefined,
): GridEditorRecord[] => {
  if (modifiedRowIndex !== undefined && modifiedRowIndex !== null) {
    let n = 0;
    return existingRecords.map((existingRecord) =>
      n++ === modifiedRowIndex
        ? {
            ...existingRecord,
            ...modifiedRecord,
          }
        : existingRecord,
    );
  }
  return existingRecords;
};

export const determineEditAriaLabel = (
  isEditing: boolean,
  editAriaLabel: string,
  updateAriaLabel: string,
): string => (isEditing ? updateAriaLabel : editAriaLabel);
