import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './Handle.module.css';

export interface HandleProps {
  className?: string;
  type: string;
}

export function Handle({ className, type }: HandleProps): ReactElement {
  return (
    <span className={classnames(styles.root, className)} data-handle={type} />
  );
}
