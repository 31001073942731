import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaxLengths } from '../../../../validation';
import { TagsInput } from '../../../../components/Tags/TagsInput';
import TagSuggestions from '../../../../components/Tags/TagSuggestions';
import styles from './TagsForm.module.css';

export type TagsFormProps = {
  integrationToSave: IntegrationToSave;
  tags: Tag[];
  addTag: (tag: Tag) => void;
  setTags: (tags: Tag[]) => void;
  tagError: boolean;
  setTagError: (value: boolean) => void;
};

export default function TagsForm({
  integrationToSave,
  tags,
  addTag,
  setTags,
  tagError,
  setTagError,
}: TagsFormProps): ReactElement {
  const { t } = useTranslation();
  const [enteredText, setEnteredText] = useState('');
  const setText = (event: ChangeEvent<HTMLInputElement>) => {
    setEnteredText(event.target.value);
  };
  const isSuggestionsVisible = enteredText.length > 2 && !tagError;
  useEffect(() => {
    setEnteredText('');
  }, [integrationToSave.tags, setEnteredText]);
  useEffect(() => {
    if (enteredText.length > MaxLengths.IntegrationTag) {
      setTagError(true);
    } else {
      setTagError(false);
    }
  }, [enteredText, setTagError]);
  return (
    <>
      <div className="half-margin">{t('tags.label')}</div>
      <div className="half-margin">
        <TagsInput
          enteredText={enteredText}
          tags={integrationToSave.tags || []}
          setTags={setTags}
          setText={setText}
          error={tagError}
        />
      </div>
      {isSuggestionsVisible && (
        <TagSuggestions
          enteredText={enteredText}
          allTags={tags}
          addTag={addTag}
          selectedTags={integrationToSave?.tags || []}
        />
      )}
      {tagError && (
        <div className={styles.maxLengthError}>
          {t('tags.errors.maxLength')}
        </div>
      )}
    </>
  );
}
