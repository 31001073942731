export const searchInventorySaasTemplates = (
  inventorySaasTemplates: SaasTemplate[],
  searchText: string,
): SaasTemplate[] => {
  return inventorySaasTemplates.filter(
    (inventorySaasTemplate) =>
      inventorySaasTemplate.description
        .toLocaleLowerCase()
        .indexOf(searchText) !== -1 ||
      inventorySaasTemplate.details.toLocaleLowerCase().indexOf(searchText) !==
        -1 ||
      inventorySaasTemplate.name.toLocaleLowerCase().indexOf(searchText) !==
        -1 ||
      inventorySaasTemplate.tags.some(
        (tag) => tag.name.toLowerCase().indexOf(searchText) !== -1,
      ),
  );
};
