import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import styles from '../IntegrationPanelInfo.module.css';
import { IntegrationPanelSection } from './IntegrationPanelSection';
import { IntegrationPanelError } from './IntegrationPanelError';

type IntegrationPanelSectionsProps = {
  integrationSessionItem: SessionIntegrationItem;
};

export function IntegrationPanelSections({
  integrationSessionItem,
}: IntegrationPanelSectionsProps): ReactElement {
  const { t } = useTranslation();
  switch (integrationSessionItem.status) {
    case 'ACTIVE':
      return (
        <>
          {integrationSessionItem.sections.map((section, index) => (
            <IntegrationPanelSection key={index} section={section} />
          ))}
        </>
      );
    case 'STARTING':
      return (
        <div className={styles.accordionAlerts}>
          <Alert colour="info" showIcon>
            {t('sessions.panels.saas.sessionNotStartedAlert')}
          </Alert>
        </div>
      );
    default:
      return (
        <IntegrationPanelError
          errorDetails={integrationSessionItem.errorDetails!}
        />
      );
  }
}
