import React, { ReactElement } from 'react';
import { PanelGrid } from '../../../../../../../PanelGrid';
import { buildInternalIpGridData } from '../../utils/utils';

export type InternalIpGridProps = {
  sessionInternalAddresses?: SessionInternalAddress[];
  isLoading: boolean;
  isError: boolean;
};

export const InternalIpGrid = ({
  sessionInternalAddresses,
  isLoading,
  isError,
}: InternalIpGridProps): ReactElement => {
  if (
    isLoading ||
    isError ||
    !sessionInternalAddresses ||
    !sessionInternalAddresses.length
  )
    return <></>;

  return (
    <>
      {sessionInternalAddresses.map(function (sessionInternalAddress, index) {
        return (
          <PanelGrid
            key={index}
            data={buildInternalIpGridData(sessionInternalAddress)}
            wideCol="value"
            nameBold={true}
            wideColRatio={1}
          />
        );
      })}
    </>
  );
};
