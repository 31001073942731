import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useGetRecipesQuery } from '../../../../redux/integrations/api';
import { setIntegrationToSave } from '../../../../redux/integrations/slice';
import { RootState } from '../../../../redux/store';
import { CreateIntegrationModal } from '.';

export const CreateIntegrationModalContainer = ({
  integrations,
  onClose,
  setIntegrationToSave,
}: CreateIntegrationModalContainerProps): ReactElement => {
  const {
    data: { inventoryWorkflows: recipes } = {},
    isLoading: isRecipesLoading,
    isError: isRecipesError,
  } = useGetRecipesQuery(undefined);
  return (
    <CreateIntegrationModal
      isLoading={isRecipesError || isRecipesLoading}
      setIntegrationToSave={setIntegrationToSave}
      onClose={onClose}
      recipes={recipes}
      integrations={integrations}
    />
  );
};

const mapDispatchToProps = {
  setIntegrationToSave,
};

type CreateIntegrationModalOwnProps = {
  integrations: Integration[];
  onClose: () => void;
};

const mapStateToProps = (
  state: RootState,
  ownProps: CreateIntegrationModalOwnProps,
) => ({
  integrations: ownProps.integrations,
  onClose: ownProps.onClose,
});

type CreateIntegrationModalContainerProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export const ConnectedCreateIntegrationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateIntegrationModalContainer);
