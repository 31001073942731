import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

interface NatRulesState {
  natRuleToDelete?: NatRule;
}

export const initialState: NatRulesState = {
  natRuleToDelete: undefined,
};

const slice = createSlice({
  initialState,
  name: 'natRules',
  reducers: {
    setNatRuleToDelete: (state, action: PayloadAction<NatRule | undefined>) => {
      state.natRuleToDelete = action.payload;
    },
  },
});

export const getNatRules = (state: RootState): NatRulesState =>
  state.configuration[slice.name];

export const { reducer: natRulesReducer } = slice;
export const { setNatRuleToDelete } = slice.actions;
