import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'dcloud-shared-ui';
import history from './redux/history';
import { store } from './redux/store';
import { App } from './App';
import './i18n';
import 'dcloud-shared-ui/dist/dcloud-shared-ui.css';
import './styles';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
