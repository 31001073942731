import React, { ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import { Alert, LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { HelpButton } from '../../../../../components/HelpButton';
import { PageHeading } from '../../../../../components/PageHeading';
import { HARDWARE_ALL_HELP_LINKS } from '../../../../../constants/user-guide';
import { AllHardwareProps } from './container';
import { DeleteModal } from './DeleteModal';
import { Table } from './Table';
import styles from './All.module.css';

export function All({
  hardware,
  hardwareToDelete,
  isError,
  isLoading,
  setHardwareToDelete,
}: AllHardwareProps): ReactElement {
  const { t } = useTranslation();
  const match = useRouteMatch();

  const onDeleteClick = useCallback(
    (hardware: Hardware) => {
      setHardwareToDelete(hardware);
    },
    [setHardwareToDelete],
  );

  return (
    <>
      <PageHeading
        pageTitle={t('hardware.physicalHardware.name')}
        useCustomStyling={true}
        ctaBtn={
          <LinkButton
            size="wide"
            to={`${match.url}/add`}
            testId="hardware-all-new-button"
            className={classNames(styles.button, 'btn--large')}
          >
            {t('buttons.new')}
          </LinkButton>
        }
      />
      {!isLoading && !hardware?.length ? (
        <Alert colour="info">{t('hardware.empty.alertText')}</Alert>
      ) : (
        <Table
          hardware={hardware}
          loading={isLoading}
          isError={isError}
          isLoading={isLoading}
          onDeleteClick={onDeleteClick}
        />
      )}
      {hardwareToDelete && (
        <DeleteModal
          hardware={hardwareToDelete}
          onClose={() => setHardwareToDelete(undefined)}
        />
      )}
      <HelpButton links={HARDWARE_ALL_HELP_LINKS} />
    </>
  );
}
