import React, { ReactElement } from 'react';
import { CarouselWrapper } from '../CarouselWrapper';

type CarouselWrapperForAnimationProps = {
  items: ReactElement[];
  updateIndex: (newIndex: number) => void;
  activeIndex: number;
};

export const CarouselWrapperForAnimation = ({
  items,
  updateIndex,
  activeIndex,
}: CarouselWrapperForAnimationProps): ReactElement => {
  return (
    <CarouselWrapper
      updateIndex={updateIndex}
      activeIndex={activeIndex}
      items={items}
      isAnimating={true}
    />
  );
};
