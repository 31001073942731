import React, { ReactElement } from 'react';
import { InfoModal } from '../../../../../../../../../components/Modals/InfoModal';
import { buildInfoColsData, buildInfoModalFooter } from '../../utils';
import { InventoryTopologiesTableInfoModalProps } from './container';

export function InventoryTopologiesTableInfoModal({
  inventoryTopology,
  setShowMoreInfoForInventoryTopology,
}: InventoryTopologiesTableInfoModalProps): ReactElement {
  return (
    <>
      {inventoryTopology ? (
        <InfoModal
          size="large"
          colsContent={buildInfoColsData(inventoryTopology)}
          title={inventoryTopology.name!}
          show={true}
          footerContent={buildInfoModalFooter(inventoryTopology)}
          onClose={() => setShowMoreInfoForInventoryTopology(undefined)}
        />
      ) : null}
    </>
  );
}
