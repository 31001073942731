import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  clearIntegrationToDelete,
  integrationSelectors,
} from '../../../../redux/integrations/slice';
import { useDeleteIntegrationMutation } from '../../../../redux/integrations/api';
import { ConfirmDeleteAdminIntegrationModal } from './';

function ConfirmDeleteAdminIntegrationModalContainer({
  integrationToDelete,
  clearIntegrationToDelete,
}: ConfirmDeleteAdminIntegrationModalContainerProps): ReactElement {
  const [deleteIntegration, { isLoading: isDeleting }] =
    useDeleteIntegrationMutation();
  return (
    <ConfirmDeleteAdminIntegrationModal
      integrationToDelete={integrationToDelete}
      clearIntegrationToDelete={clearIntegrationToDelete}
      deleteIntegration={deleteIntegration}
      isDeleting={isDeleting}
    />
  );
}

export type ConfirmDeleteAdminIntegrationModalProps =
  ConfirmDeleteAdminIntegrationModalRTKProps &
    ConfirmDeleteAdminIntegrationModalContainerProps;

type ConfirmDeleteAdminIntegrationModalRTKProps = {
  deleteIntegration: RTKMutation<string, Integration>;
  isDeleting: boolean;
};

export type ConfirmDeleteAdminIntegrationModalContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  integrationToDelete: integrationSelectors.getIntegrationToDelete(state),
});

const mapDispatchToProps = {
  clearIntegrationToDelete,
};

export const ConnectedConfirmDeleteAdminIntegrationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDeleteAdminIntegrationModalContainer);
