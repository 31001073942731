import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useUpdateVmMutation } from '../../../../../../../redux/configuration/virtual-machine-entities/api';
import { getVirtualMachines } from '../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { getOperatingSystems } from '../../../../../../../redux/entities/operating-systems/slice';
import {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedArchitectures,
  setSelectedInventoryItem,
  templateManagerSelectors,
} from '../../../../../../../redux/templateManager/slice';
import { RootState } from '../../../../../../../redux/store';
import {
  useGetVmTemplateQuery,
  useUpdateVmTemplateMutation,
} from '../../../../../../../redux/templateManager/VmTemplates/api';
import { EditVmTemplateFormLoader } from '../../FormLoader';
import { ConnectedCreateVMTemplate } from '../../AddTemplate/container';
import { TemplateVmForm } from '.';

interface TemplateVmFormContainerProps {
  networkingToEdit?: VmNetworkingToEdit;
  operatingSystems?: OperatingSystem[];
  rdpEnabledNic?: VMNetworkInterface;
  setNetworkingToEdit: (networkingToEdit: VmNetworkInterfacesToEdit) => void;
  sshEnabledNic?: VMNetworkInterface;
  vmToEdit?: VirtualMachine;
  customEtags: CustomEtag[];
}

export function InventoryVmFormContainer({
  networkingToEdit,
  operatingSystems = [],
  rdpEnabledNic,
  setNetworkingToEdit,
  allArchitectures,
  selectedArchitectures,
  selectedInventoryItem,
  sshEnabledNic,
  setSelectedArchitectures,
  currentDc,
  removeSelectedArchitecture,
  addSelectedArchitecture,
  vmToEdit,
  customEtags,
}: TemplateVmFormRTKProps): ReactElement {
  const [updateVM, { isLoading: isUpdatingVm }] = useUpdateVmMutation();

  const [updateVmTemplate, { isLoading: isUpdatingTemplate }] =
    useUpdateVmTemplateMutation();

  const {
    data: vmTemplateToEdit,
    isLoading,
    isFetching,
  } = useGetVmTemplateQuery(selectedInventoryItem!.uid);
  if (
    !selectedInventoryItem ||
    !vmTemplateToEdit ||
    isUpdatingVm ||
    isLoading ||
    isUpdatingTemplate ||
    isFetching
  ) {
    return <EditVmTemplateFormLoader />;
  }

  const vmOverrideETag = customEtags.find((etag: CustomEtag) =>
    etag.referenceName.includes(`/vms/${vmToEdit?.uid}`),
  );
  const templateOverrideETag = customEtags.find((etag: CustomEtag) =>
    etag.referenceName.includes(`/vm-templates/${vmTemplateToEdit?.uid}`),
  );

  return (
    <>
      {vmToEdit && (
        <TemplateVmForm
          currentDc={currentDc}
          templateEtag={
            templateOverrideETag ? templateOverrideETag.etag : undefined
          }
          vmEtag={vmOverrideETag ? vmOverrideETag.etag : undefined}
          isUpdating={isUpdatingVm || isUpdatingTemplate}
          updateVmTemplate={updateVmTemplate}
          vmToEdit={vmToEdit}
          updateVM={updateVM}
          setNetworkingToEdit={setNetworkingToEdit}
          selectedArchitectures={selectedArchitectures}
          vmNetworkingToEdit={networkingToEdit}
          operatingSystems={operatingSystems}
          rdpEnabledNic={rdpEnabledNic}
          sshEnabledNic={sshEnabledNic}
          addSelectedArchitecture={addSelectedArchitecture}
          allArchitectures={allArchitectures}
          removeArchitecture={removeSelectedArchitecture}
          templateToEdit={vmTemplateToEdit}
          setSelectedArchitectures={setSelectedArchitectures}
        />
      )}
      {!vmToEdit && <ConnectedCreateVMTemplate isEdit={true} />}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  allArchitectures: templateManagerSelectors.getAllArchitectures(state),
  currentDc: templateManagerSelectors.getSelectedDataCenter(state),
  networkingToEdit: getVirtualMachines(state).networkingToEdit,
  operatingSystems: getOperatingSystems(state).operatingSystems,
  selectedArchitectures:
    templateManagerSelectors.getSelectedArchitectures(state),
  selectedInventoryItem:
    templateManagerSelectors.getSelectedInventoryItem(state),
});

export type TemplateVmFormRTKProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  TemplateVmFormContainerProps;

const mapDispatchToProps = {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedArchitectures,
  setSelectedInventoryItem,
};

export const ConnectedInventoryTemplateVmEdit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryVmFormContainer);
