import {
  SAAS_TEMPLATES_PATH,
  TOPOLOGIES_PATH,
  INTEGRATION_LICENSE_PATH,
  TAGS_PATH,
  RECIPES_PATH,
  INTEGRATIONS_PATH,
  SAAS_TEMPLATE_AVAILABILITY_VALUES_PATH,
} from '../../api/paths';
import i18n from '../../i18n';
import { dcloudApi } from '../api';
import history from '../history';
import { MainRoutes } from '../../constants/routes';
import { setIntegrations } from '../integrationsFilter/slice';
import { TAGS } from '../api/constants';
import {
  clearIntegrationToDelete,
  clearIntegrationToSave,
  clearIntegrationToUpdate,
} from './slice';

export const integrationsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    addIntegration: build.mutation<Integration, IntegrationPayload>({
      invalidatesTags: [TAGS.INTEGRATIONS],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(clearIntegrationToSave());
          history.push(MainRoutes.Integrations);
        } catch (err) {
          throw err;
        }
      },
      query: (data) => ({
        data,
        errorMessage: i18n.t('integrations.forms.add.error'),
        method: 'post',
        successMessage: i18n.t('integrations.forms.add.success'),
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATES_PATH}`,
      }),
    }),
    deleteIntegration: build.mutation<Integration, string>({
      invalidatesTags: [TAGS.INTEGRATIONS],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          throw err;
        } finally {
          dispatch(clearIntegrationToDelete());
        }
      },
      query: (integrationUid) => ({
        errorMessage: i18n.t('integrations.delete.error'),
        method: 'delete',
        successMessage: i18n.t('integrations.delete.success'),
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATES_PATH}/${integrationUid}`,
      }),
    }),
    getIntegration: build.query<Integration, { integrationUid: string }>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INTEGRATION],
      query: ({ integrationUid }) => ({
        errorMessage: i18n.t('integrations.fetch.error'),
        method: 'get',
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATES_PATH}/${integrationUid}`,
      }),
    }),
    getIntegrationLicenses: build.query<
      { integrationLicenses: CustomLabel[] },
      void
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INTEGRATION_LICENSES],
      query: () => ({
        errorMessage: i18n.t('integrations.forms.add.licenses.errors.fetch'),
        method: 'get',
        url: INTEGRATION_LICENSE_PATH,
      }),
    }),
    getIntegrationTags: build.query<IntegrationTagsResponse, void>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INTEGRATION_TAGS],
      query: () => ({
        errorMessage: i18n.t('tags.errors.fetch'),
        method: 'get',
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATES_PATH}${TAGS_PATH}`,
      }),
    }),
    getIntegrationTopologies: build.query<
      FetchIntegrationTopologiesResponse,
      FetchIntegrationTopologiesPayload
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INTEGRATION_TOPOLOGIES],
      query: ({ integrationUid }) => ({
        errorMessage: i18n.t('integrations.info.uses.fetch.error'),
        method: 'get',
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATES_PATH}/${integrationUid}${TOPOLOGIES_PATH}`,
      }),
    }),
    getIntegrations: build.query<GetIntegrationsResponse, void>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegrations(data.saasTemplates));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.INTEGRATIONS],
      query: () => ({
        errorMessage: i18n.t('integrations.fetch.error'),
        method: 'get',
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATES_PATH}`,
      }),
    }),
    getRecipe: build.query<Recipe, string>({
      keepUnusedDataFor: 0,
      query: (recipeUid) => ({
        errorMessage: i18n.t('integrations.fetch.recipeError'),
        method: 'get',
        url: `${RECIPES_PATH}/${recipeUid}`,
      }),
    }),
    getRecipes: build.query<GetRecipesResponse, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        errorMessage: i18n.t('integrations.fetch.recipesError'),
        method: 'get',
        url: RECIPES_PATH,
      }),
    }),
    getSaasTemplateAvailabilityValues: build.query<
      SaasTemplateAvailabilityValuesResponse,
      void
    >({
      providesTags: [TAGS.SAAS_TEMPLATE_AVAILABILITY_VALUES],
      query: () => ({
        errorMessage: i18n.t(
          'integrations.forms.common.saasTemplateAvailabilityValues.errors.fetch',
        ),
        method: 'get',
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATE_AVAILABILITY_VALUES_PATH}`,
      }),
    }),
    updateIntegration: build.mutation<Integration, IntegrationToUpdate>({
      invalidatesTags: [TAGS.INTEGRATIONS, TAGS.INTEGRATION],
      async onQueryStarted(_, { dispatch }) {
        try {
          dispatch(clearIntegrationToUpdate());
        } catch (err) {
          throw err;
        }
      },
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('integrations.forms.edit.error'),
        method: 'PUT',
        successMessage: i18n.t('integrations.forms.edit.success'),
        url: `${INTEGRATIONS_PATH}${SAAS_TEMPLATES_PATH}/${payload.uid}`,
      }),
    }),
  }),
});

export const {
  useAddIntegrationMutation,
  useDeleteIntegrationMutation,
  useGetIntegrationQuery,
  useGetIntegrationLicensesQuery,
  useGetIntegrationTagsQuery,
  useGetIntegrationTopologiesQuery,
  useGetIntegrationsQuery,
  useGetRecipeQuery,
  useGetRecipesQuery,
  useUpdateIntegrationMutation,
  useGetSaasTemplateAvailabilityValuesQuery,
} = integrationsApi;
