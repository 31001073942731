import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../redux/views/session-view/session/api/expoApi';
import { ActionsBarCenterContainerProps } from './container';
import { ActionsBarCenter } from '.';

type ConnectedExpoActionsBarCenterProps = ActionsBarCenterContainerProps &
  FetchExpoSessionArgs;

export function ConnectedExpoActionsBarCenter({
  setLeftPanelType,
  setShowEndSessionModal,
  setShowRenameSessionModal,
  setShowResetSessionModal,
  setShowSaveAndEndSessionModal,
  isSessionExpired,
  engagementUid,
  sessionUid,
  versionUid,
}: ConnectedExpoActionsBarCenterProps): ReactElement {
  const { actions, data, isError, isLoading } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      {
        engagementUid,
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isError }) => ({
          actions: data?._links,
          data: data?.sessionDetails,
          isError,
          isLoading,
        }),
      },
    );

  return (
    <ActionsBarCenter
      actions={actions}
      setLeftPanelType={setLeftPanelType}
      setShowEndSessionModal={setShowEndSessionModal}
      setShowRenameSessionModal={setShowRenameSessionModal}
      setShowResetSessionModal={setShowResetSessionModal}
      setShowSaveAndEndSessionModal={setShowSaveAndEndSessionModal}
      isSessionExpired={isSessionExpired}
      sessionDetails={data}
      isSessionDetailsLoading={isError || isLoading}
    />
  );
}
