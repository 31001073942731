import i18n from '../../../i18n';

export const NO_TEXT = '--';
export const SESSION_INVENTORY_SIDE_TABS_LABELS = {
  hardware: i18n.t('sessionsInventory.sideTabs.labels.hardware'),
  virtualMachines: i18n.t('sessionsInventory.sideTabs.labels.virtualMachines'),
};

export const SESSION_INVENTORY_SIDE_TABS = [
  {
    label: SESSION_INVENTORY_SIDE_TABS_LABELS.hardware,
  },
  {
    label: SESSION_INVENTORY_SIDE_TABS_LABELS.virtualMachines,
  },
];

const ACTION_LABELS_PATH =
  'sessionsInventory.multiSelectConfirmModal.actionLabels';

export const ACTION_LABEL_MAPPINGS: Record<SessionVmActions | 'RESET', string> =
  {
    GUEST_OS_REBOOT: i18n.t(`${ACTION_LABELS_PATH}.rebootGuestOs`),
    GUEST_OS_SHUTDOWN: i18n.t(`${ACTION_LABELS_PATH}.shutdownGuestOs`),
    RESET: i18n.t(`${ACTION_LABELS_PATH}.resetPower`),
    VM_POWER_OFF: i18n.t(`${ACTION_LABELS_PATH}.powerOff`),
    VM_POWER_ON: i18n.t(`${ACTION_LABELS_PATH}.powerOn`),
    VM_RESET: i18n.t(`${ACTION_LABELS_PATH}.reset`),
  };
