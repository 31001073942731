import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { useSkeletonColours } from '../../hooks/use-skeleton-colours';

export function TagsFormSkeleton(): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();
  return (
    <section className="dbl-padding">
      <ContentLoader
        className="margin-top"
        speed={1.5}
        width="474"
        height={62}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" rx="3" ry="3" width="474" height="50" />
      </ContentLoader>
    </section>
  );
}
