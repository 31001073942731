import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../../../hooks/use-session-user-context';
import { DCloudAttachmentContainer } from './dCloudContainer';
import { ExpoAttachmentContainer } from './expoContainer';

type AttachmentContentContainerProps = {
  sessionDocument: SessionDocument;
  key: number;
};

export function AttachmentContainer({
  sessionDocument,
}: AttachmentContentContainerProps): ReactElement {
  const { engagementUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ExpoAttachmentContainer
        engagementUid={engagementUid}
        sessionDocument={sessionDocument}
        versionUid={versionUid}
      ></ExpoAttachmentContainer>
    );
  }

  return (
    <DCloudAttachmentContainer
      sessionDocument={sessionDocument}
      versionUid={versionUid}
    ></DCloudAttachmentContainer>
  );
}
