import React, { ReactElement } from 'react';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { InventoryTopologiesTable } from './components/InventoryTopologiesTable';
import { ConnectedVmsTable } from './components/VmsTable/container';
import { InventoryTopologiesTableInfoModalContainer } from './components/InventoryTopologiesTableInfoModal/container';

interface DemosTabContentProps {
  inventoryTopologies: InventoryTopologies;
  isFetching: boolean;
  setSelectedTopologyUid: ActionCreatorWithOptionalPayload<
    string | undefined,
    string
  >;
  setShowMoreInfoForInventoryTopology: ActionCreatorWithPayload<
    InventoryTopology | undefined,
    string
  >;
  isDiagramView: boolean;
  selectedTopologyUid?: string;
}

export function DemosTabContent({
  inventoryTopologies,
  setSelectedTopologyUid,
  setShowMoreInfoForInventoryTopology,
  isDiagramView,
  selectedTopologyUid,
}: DemosTabContentProps): ReactElement {
  const { path } = useRouteMatch();

  const renderInventoryTopologiesTable = () => {
    return (
      <>
        <InventoryTopologiesTable
          isDiagramView={isDiagramView}
          inventoryTopologies={inventoryTopologies}
          loading={false}
          setShowMoreInfoForDemoTemplate={setShowMoreInfoForInventoryTopology}
          setSelectedTopologyUid={setSelectedTopologyUid}
        />
        <InventoryTopologiesTableInfoModalContainer />
      </>
    );
  };

  if (isDiagramView) {
    if (selectedTopologyUid) {
      return <ConnectedVmsTable isDiagramView={true} />;
    } else {
      return renderInventoryTopologiesTable();
    }
  }

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => renderInventoryTopologiesTable()}
      />
      <Route
        path={`${path}/vms`}
        render={() => <ConnectedVmsTable isDiagramView={false} />}
      />
    </Switch>
  );
}
