import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setExpired,
  setShowExpiredModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudActionsBarTimer } from './dCloudContainer';
import { ConnectedExpoActionsBarTimer } from './expoContainer';

export function ActionsBarTimerContainer({
  expired,
  setShowExpiredModal,
  setExpired,
}: ActionsBarTimerContainerProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoActionsBarTimer
        expired={expired}
        setExpired={setExpired}
        setShowExpiredModal={setShowExpiredModal}
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudActionsBarTimer
      expired={expired}
      setExpired={setExpired}
      setShowExpiredModal={setShowExpiredModal}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}
const mapDispatchToProps = {
  setExpired,
  setShowExpiredModal,
};

const mapStateToProps = (state: RootState) => ({
  expired: sessionViewSelectors.getExpired(state),
});

type RTKProps = {
  stopDateString: string;
};

export type ActionsBarTimerContainerProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;
export type ActionsBarTimerProps = ActionsBarTimerContainerProps & RTKProps;

export const ConnectedActionBarTimer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionsBarTimerContainer);
