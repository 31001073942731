import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { put, call, all } from 'redux-saga/effects';
import { willDevicesLimitBeExceeded } from '../../entities/hardware/Add/utils/utils';
import { hardwareApi } from '../configuration/hardware/api';
import { addErrorToast } from '../toast/slice';
import {
  resetTopologyLimits,
  setItemsToAdd,
  setShowTopologyLimitWarningForEntity,
} from '../configuration/topologyLimits/slice';
import i18n from '../../i18n';
import {
  setShowAddHardwareLoader,
  setShowAddHardwareModal,
  setShowAddHardwareSavingModal,
} from './slice';
import { refetchDiagramDataHelper } from './sagas/shared/sharedSagas';

export function* addHardwareItemsHelper(
  selectedHardwareItems: InventoryHardwareItemToAdd[],
): SagaIterator {
  try {
    yield put(setShowAddHardwareSavingModal(true));

    const effects = selectedHardwareItems.map((item) =>
      call(addHardwareItemHelper, item),
    );

    yield all([...effects]);
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    yield put(setShowAddHardwareSavingModal(false));
  }
}

export function* addHardwareItemHelper(
  selectedHardwareItem: InventoryHardwareItemToAdd,
): SagaIterator {
  let putResult;
  try {
    putResult = yield put(
      yield call(
        hardwareApi.endpoints.addHardwareItem.initiate,
        selectedHardwareItem,
      ),
    );
    yield putResult;
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    if (putResult) {
      yield call(putResult.unsubscribe);
    }
  }
}

export function* handleAddHardwareFromDiagramWorker({
  payload: { items, topologyUid, topologyLimit },
}: PayloadAction<InventoryHardwareItemsToAddFromDiagram>): SagaIterator {
  try {
    yield put(setShowAddHardwareModal(false));
    let limitsWillBeExceeded = false;
    if (topologyLimit) {
      limitsWillBeExceeded = yield call(
        willDevicesLimitBeExceeded,
        items.length,
        topologyLimit,
      );
      if (limitsWillBeExceeded) {
        yield put(setShowTopologyLimitWarningForEntity('Devices'));
        yield put(setItemsToAdd({ items, topologyUid }));
      } else {
        yield call(addHardwareItemsHelper, items);
        yield call(refetchDiagramDataHelper, topologyUid);
        yield put(resetTopologyLimits());
      }
    } else {
      yield call(addHardwareItemsHelper, items);
      yield call(refetchDiagramDataHelper, topologyUid);
      yield put(resetTopologyLimits());
    }
  } catch (error) {
    yield put(addErrorToast({ message: i18n.t('common.errorMessage') }));
  } finally {
    yield put(setShowAddHardwareLoader(false));
  }
}
