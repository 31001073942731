import React, { ReactElement } from 'react';
import { useDiagramContext } from '../DiagramProvider';
import { BoxSelector } from './components/BoxSelector';
import { BoxSelectorsGroup } from './components/BoxSelectorsGroup';

export type BoxSelectorsProps = {
  boxes: DiagramNodeDisplayOnly[];
  isSessionView: boolean;
};

export const BoxSelectors = ({
  boxes,
  isSessionView,
}: BoxSelectorsProps): ReactElement => {
  const { selectedItem, toggleSelectedItem } = useDiagramContext();

  return (
    <BoxSelectorsGroup>
      {boxes.map((box) => (
        <BoxSelector
          box={box}
          key={box.uid}
          selectedItem={selectedItem}
          toggleSelectedItem={toggleSelectedItem}
          isSessionView={isSessionView}
        />
      ))}
    </BoxSelectorsGroup>
  );
};
