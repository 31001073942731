import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { ReactComponent as TopologySVG } from './topo.svg';
import styles from './TopologySVGIcon.module.css';

export interface TopologySVGIconProps {
  className?: string;
}

export function TopologySVGIcon({
  className,
}: TopologySVGIconProps): ReactElement {
  return (
    <span className={classnames(styles.root, className)}>
      <TopologySVG />
    </span>
  );
}
