import { connect } from 'react-redux';
import { getCurrentUserState } from '../../redux/configuration/currentUser/slice';
import { RootState } from '../../redux/store';
import { Home } from '.';

const mapStateToProps = (state: RootState) => ({
  userRoles: getCurrentUserState(state).userRoles,
});

export type HomeProps = ReturnType<typeof mapStateToProps>;

export const HomeContainer = connect(mapStateToProps)(Home);
