import React, { ReactElement } from 'react';
import { Label } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';

export function TopologiesActiveTemplateLabel(): ReactElement {
  const { t } = useTranslation();

  return (
    <Label colour="warning-alt" size="small">
      {t('topologies.activeTemplate')}
    </Label>
  );
}
