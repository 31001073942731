import React, { ReactElement } from 'react';
import { IconButton } from 'dcloud-shared-ui';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { PanelGrid } from '../PanelGrid';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { SharePanelForm } from './components/SharePanelForm';
import styles from './SharePanel.module.css';

export interface SharePanelProps {
  addUser: ActionCreatorWithPayload<ShareUser>;
  isLoading: boolean;
  users: ShareUser[];
  removeUser: ActionCreatorWithPayload<ShareUser>;
  shareSession: RTKMutation<ShareSessionPayload, Session>;
  setLeftPanelType: ActionCreatorWithOptionalPayload<
    SessionPanel | undefined,
    string
  >;
}

export function SharePanel({
  addUser,
  users,
  isLoading,
  removeUser,
  shareSession,
  setLeftPanelType,
}: SharePanelProps): ReactElement {
  const { t } = useTranslation();
  const { sessionUid, versionUid } = useSessionUserContext();

  const submitSharedUsers = async () => {
    const { error } = await shareSession({
      sessionId: sessionUid,
      sharedWith: users,
      topologyVersion: { uid: versionUid },
    });
    !error && setLeftPanelType(undefined);
  };

  const panelGridData: PanelGridDataItem[] = users.map((user) => {
    return {
      key: user.email,
      name: user.email,
      value: (
        <IconButton
          icon="trash"
          onClick={() => removeUser({ email: user.email })}
          colour="link"
        />
      ),
    };
  });

  return (
    <>
      <SharePanelForm
        isLoading={isLoading}
        addUser={addUser}
        sharedWith={users}
      />
      <PanelGrid data={panelGridData} />
      <PrimaryButton
        className={styles.shareBtn}
        testId="share-session-submit"
        loading={isLoading}
        onClick={submitSharedUsers}
        type="submit"
        colour="primary"
      >
        {t('sessions.forms.share.email.shareButton')}
      </PrimaryButton>
    </>
  );
}
