const { IDP_CODE } = window.__TB_CONFIG__.SSO;

export enum AuthStatusValues {
  NotAuthenticated = 'not-authenticated',
  Authenticated = 'authenticated',
  RefreshingTokens = 'refreshing-tokens',
}

export enum OauthQueryParams {
  AccessCode = 'code',
  CodeVerifier = 'code_verifier',
  StateParam = 'state',
}

export const BASE_URL = window.__TB_CONFIG__.BASE_PATH ?? '';

export const config: AuthConfig = {
  ...window.__TB_CONFIG__,
  SSO: {
    ...window.__TB_CONFIG__.SSO,
    ACCESS_TOKEN_NAME: `tb_${IDP_CODE}_a`,
    CODE_CHALLENGE_METHOD: 'S256',
    CODE_VERIFIER_NAME: `tb_${IDP_CODE}_c`,
    REFRESH_TOKEN_NAME: `tb_${IDP_CODE}_r`,
    RESPONSE_TYPE: 'code',
    SCOPE: 'openid profile email offline_access cci_coimemberOf groups',
  },
};
