import React, { ReactElement } from 'react';
import { useDeleteNodeMutation } from '../../../../redux/diagram/api';
import { TwoHandleLineNode } from './index';

export type ConnectedTwoHandleLineNodeProps = {
  display: DiagramLineDisplay;
  isReadOnly: boolean;
  uid: string;
  className?: string;
  isSnapToGridEnabled?: boolean;
};

type TwoHandleLineNodeRTKProps = {
  deleteNode: RTKMutation<string, undefined>;
};

export type TwoHandleLineNodeProps = ConnectedTwoHandleLineNodeProps &
  TwoHandleLineNodeRTKProps;

export function ConnectedTwoHandleLineNode({
  className,
  display,
  isReadOnly,
  uid,
  isSnapToGridEnabled,
}: ConnectedTwoHandleLineNodeProps): ReactElement {
  const [deleteNode] = useDeleteNodeMutation();
  return (
    <TwoHandleLineNode
      className={className}
      display={display}
      isReadOnly={isReadOnly}
      uid={uid}
      deleteNode={deleteNode}
      isSnapToGridEnabled={isSnapToGridEnabled}
    />
  );
}
