import React, { ReactElement } from 'react';
import {
  expoSessionApi,
  usePostExpoSessionHwActionMutation,
} from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import {
  getConsoleHrefFromSessionDetails,
  getDeviceIdFromSessionDetails,
  getPduControlFromSessionDetails,
} from '../../../../../utils';
import { ControlContainerProps } from './container';
import { HWControls } from '.';

type ConnectedExpoControlProps = ControlContainerProps & FetchExpoSessionArgs;

export function ConnectedExpoControl({
  engagementUid,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedExpoControlProps): ReactElement {
  const { consoleHref, deviceId, pduControl } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          consoleHref: getConsoleHrefFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
          deviceId: getDeviceIdFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
          pduControl: getPduControlFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
        }),
      },
    );
  const [postSessionHwAction] = usePostExpoSessionHwActionMutation();

  const postHwAction = (action: SessionHwActions) => {
    if (!pduControl || !deviceId) return;

    postSessionHwAction({
      action,
      deviceId: deviceId,
      engagementId: engagementUid,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });
  };

  return (
    <HWControls
      consoleHref={consoleHref}
      postHwAction={postHwAction}
      pduControl={pduControl}
    />
  );
}
