import React, { ReactElement } from 'react';
import { Tooltip } from '../../../../components/Tooltip';
import i18n from '../../../../i18n';

type TopologySharedIconProps = {
  id: string;
  className?: string;
};

export function TopologySharedIcon({
  id,
  className,
}: TopologySharedIconProps): ReactElement {
  return (
    <Tooltip
      position="right"
      text={i18n.t('topologies.sharedWithMeTooltipText')}
      id={id}
      className={className}
    >
      <span
        aria-label={i18n.t('topologies.sharedWithMeTooltipText')}
        className="icon-profile-settings"
      ></span>
    </Tooltip>
  );
}
