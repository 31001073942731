import { dcloudApi } from '../../api';
import {
  INVENTORY_SAAS_INTEGRATIONS,
  SAAS_INTEGRATIONS_PATH,
  TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { TAGS } from '../../api/constants';

export const saasIntegrationsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createSaasIntegration: build.mutation<
      SaasIntegration,
      PostSaasIntegrationPayload
    >({
      invalidatesTags: [TAGS.SAAS_INTEGRATIONS],
      query: (data) => ({
        data,
        errorMessage: i18n.t('saas.create.error'),
        method: 'post',
        successMessage: i18n.t('saas.create.success'),
        url: SAAS_INTEGRATIONS_PATH,
      }),
    }),
    deleteSaasIntegration: build.mutation<undefined, string>({
      invalidatesTags: [TAGS.SAAS_INTEGRATIONS],
      query: (saasIntegrationUId) => ({
        errorMessage: i18n.t('saas.delete.error'),
        method: 'delete',
        successMessage: i18n.t('saas.delete.success'),
        url: `${SAAS_INTEGRATIONS_PATH}/${saasIntegrationUId}`,
      }),
    }),
    getInventorySaasTemplates: build.query<SaasTemplate[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INVENTORY_SAAS_INTEGRATIONS],
      query: () => ({
        errorMessage: i18n.t('saas.add.fetch.error'),
        method: 'get',
        url: `${INVENTORY_SAAS_INTEGRATIONS}`,
      }),
      transformResponse: ({
        saasTemplates,
      }: GetInventorySaasTemplatesResponse) => saasTemplates,
    }),
    getSaasIntegration: build.query<SaasIntegration, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SAAS_INTEGRATION],
      query: (integrationUid) => ({
        errorMessage: i18n.t('saas.fetch.error'),
        method: 'get',
        url: `${SAAS_INTEGRATIONS_PATH}/${integrationUid}`,
      }),
    }),
    getSaasIntegrations: build.query<
      { saasIntegrations: SaasIntegration[] },
      string
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SAAS_INTEGRATIONS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('integrations.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${SAAS_INTEGRATIONS_PATH}`,
      }),
    }),
    updateSaasIntegration: build.mutation<
      SaasIntegration,
      PutSaasIntegrationPayload
    >({
      invalidatesTags: [TAGS.SAAS_INTEGRATION, TAGS.SAAS_INTEGRATIONS],
      query: (data) => ({
        data,
        errorMessage: i18n.t('saas.update.error'),
        method: 'put',
        successMessage: i18n.t('saas.update.success'),
        url: `${SAAS_INTEGRATIONS_PATH}/${data.uid}`,
      }),
    }),
  }),
});

export const {
  useDeleteSaasIntegrationMutation,
  useGetInventorySaasTemplatesQuery,
  useCreateSaasIntegrationMutation,
  useGetSaasIntegrationQuery,
  useGetSaasIntegrationsQuery,
  useUpdateSaasIntegrationMutation,
} = saasIntegrationsApi;
