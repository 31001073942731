import { NodeStrokeTypes } from '../../../../../../../components/DrawingPalettes/constants';
import { DiagramDrawingColorName } from '../../../../../../../typings/drawing-palettes';

export const DefaultLineNodeDisplay = {
  colorPropertyName: 'strokeColorName',
  dashSize: 10,
  drawingType: 'LINE' as DiagramDrawingType,
  strokeColorNameForDarkTheme: 'color-gray-200' as DiagramDrawingColorName,
  strokeColorNameForLightTheme: 'color-gray-600' as DiagramDrawingColorName,
  strokeType: NodeStrokeTypes.solid,
  strokeWidth: 2,
  x1: 140,
  x2: 290,
  y1: 125,
  y2: 280,
};
