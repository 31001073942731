import { useEffect } from 'react';

interface UseTriggerFormValidation<T> {
  fieldsToValidate: Partial<Record<keyof T, boolean>>;
  trigger: (name?: keyof T | (keyof T)[] | undefined) => Promise<boolean>;
}

export const useTriggerIntegrationFormValidation = <T,>({
  fieldsToValidate,
  trigger,
}: UseTriggerFormValidation<T>): void => {
  useEffect(() => {
    Object.entries(fieldsToValidate).forEach(
      ([fieldName, isToValidate]) =>
        isToValidate && trigger(fieldName as keyof T),
    );
  }, [fieldsToValidate, trigger]);
};
