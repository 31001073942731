import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetNetworksQuery } from '../../../../../../../redux/configuration/networks/api';
import {
  topologyLimitsSelectors,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
} from '../../../../../../../redux/configuration/topologyLimits/slice';
import { handleAddVmFromTemplatesTabFromDiagramWorker } from '../../../../../../../redux/diagram/slice';
import {
  useGetVmQuery,
  useGetVmsQuery,
} from '../../../../../../../redux/configuration/virtual-machine-entities/api';
import { virtualMachinesSelectors } from '../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../../redux/store';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { VirtualMachinesConfigure } from './Configure';

function VirtualMachinesConfigureContainer({
  interfaces,
  topologyLimitsWarningAccepted,
  setShowTopologyLimitWarningForEntity,
  resetTopologyLimits,
  templateVmUid,
  isDiagramView,
  backButtonOverride,
  handleAddVmFromTemplatesTabFromDiagramWorker,
}: VirtualMachinesConfigureContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data: networks = [], isLoading: isLoadingNetworks } =
    useGetNetworksQuery(topologyUid);
  const { data: vms = [], isLoading: isLoadingVms } =
    useGetVmsQuery(topologyUid);
  const { data: templateVm, isLoading } = useGetVmQuery(templateVmUid || '', {
    skip: !templateVmUid,
  });

  if (isLoading || isLoadingNetworks || isLoadingVms) {
    return <TableLoader />;
  }

  return (
    <VirtualMachinesConfigure
      handleAddVmFromTemplatesTabFromDiagramWorker={
        handleAddVmFromTemplatesTabFromDiagramWorker
      }
      isDiagramView={isDiagramView}
      backButtonOverride={backButtonOverride}
      interfaces={templateVm?.vmNetworkInterfaces || interfaces}
      networks={networks}
      topologyLimitsWarningAccepted={topologyLimitsWarningAccepted}
      setShowTopologyLimitWarningForEntity={
        setShowTopologyLimitWarningForEntity
      }
      templateVmUid={templateVmUid}
      resetTopologyLimits={resetTopologyLimits}
      vms={vms}
    />
  );
}

type ConnectedConfigureOwnProps = {
  isDiagramView?: boolean;
  backButtonOverride?: VoidFunction;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedConfigureOwnProps,
) => ({
  backButtonOverride: ownProps.backButtonOverride,
  interfaces:
    virtualMachinesSelectors.getVirtualMachineToConfigure(state)
      ?.networkInterfaces,
  isDiagramView: ownProps.isDiagramView,
  templateVmUid:
    virtualMachinesSelectors.getVirtualMachineToConfigure(state)?.vm?.uid,
  topologyLimitsWarningAccepted:
    topologyLimitsSelectors.getTopologyLimitsWarningAccepted(state),
});

const mapDispatchToProps = {
  handleAddVmFromTemplatesTabFromDiagramWorker,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
};

type VirtualMachinesConfigureRTKProps = {
  networks: Network[];
  vms: VirtualMachine[];
};

type VirtualMachinesConfigureContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export type VirtualMachinesConfigureProps =
  VirtualMachinesConfigureContainerProps & VirtualMachinesConfigureRTKProps;

export const ConnectedConfigure = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachinesConfigureContainer);
