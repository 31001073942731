import React, { ReactElement, useEffect, useState } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { TopologyLimitsToolBarModal } from '../TopologyLimitsToolBarModal';
import styles from './TopologyLimitsToolBarItem.module.css';
import {
  determineTopologyLimitsToolBarItemIcon,
  determineTopologyLimitsToolBarItemIconStyles,
  determineTopologyLimitsToolBarItemLabel,
  determineRootStyles,
} from './utils';

export type TopologyLimitsToolBarItemProps = {
  topologyLimit: TopologyLimit;
  label: TopologyLimitsDisplayLabels;
};

export const TopologyLimitsToolBarItem = ({
  topologyLimit,
  label,
}: TopologyLimitsToolBarItemProps): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [approvedLimitExceeded, setApprovedLimitExceeded] = useState(
    topologyLimit.current > topologyLimit.limit,
  );

  useEffect(() => {
    setApprovedLimitExceeded(topologyLimit.current > topologyLimit.limit);
  }, [topologyLimit]);

  return (
    <>
      <div
        className={determineRootStyles(approvedLimitExceeded)}
        onClick={approvedLimitExceeded ? () => setShowModal(true) : () => null}
        role="button"
        aria-label={label}
        tabIndex={0}
        data-testid={`demo-max-toolbar-item-${label}`}
      >
        <span
          className={determineTopologyLimitsToolBarItemIconStyles(
            approvedLimitExceeded,
          )}
        >
          {
            <Icon
              name={determineTopologyLimitsToolBarItemIcon(
                approvedLimitExceeded,
              )}
            />
          }
        </span>
        <span className={styles.label}>
          {determineTopologyLimitsToolBarItemLabel(topologyLimit, label)}
        </span>
      </div>
      <TopologyLimitsToolBarModal
        show={showModal}
        label={label}
        onClose={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        onConfirm={() => setShowModal(false)}
      />
    </>
  );
};
