import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDataCentersQuery } from '../../redux/entities/data-centers/api';
import { CreateTopology } from '.';

export function CreateTopologyContainer(): ReactElement {
  const { data, isLoading } = useGetDataCentersQuery(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('siteTitle');
  }, [t]);

  return <CreateTopology isLoading={isLoading} datacenters={data} />;
}

export interface CreateTopologyProps {
  datacenters?: DataCenterFromApi[];
  isLoading: boolean;
}
