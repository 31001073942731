import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';

export type IconPanelLinksEmptyAlertProps = {
  links: DiagramAddIconFormFieldsDatum[];
};

export function IconPanelLinksEmptyAlert({
  links,
}: IconPanelLinksEmptyAlertProps): ReactElement {
  const { t } = useTranslation();
  if (links.length > 0) return <></>;

  return (
    <>
      <Alert>{t('sessions.panels.icon.sections.links.messages.empty')}</Alert>
    </>
  );
}
