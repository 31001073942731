export const addSelectedLicense = (
  allLicenses: CustomLabel[],
  selectedLicense: CustomLabel,
): CustomLabel[] => {
  return [...allLicenses, selectedLicense];
};

export const removeSelectedLicense = (
  allLicenses: CustomLabel[],
  selectedLicense: CustomLabel,
): CustomLabel[] => {
  return allLicenses.filter((license) => license.uid !== selectedLicense.uid);
};

export const computeAvailableLicenses = (
  allLicenses: CustomLabel[],
  selectedLicenses: CustomLabel[],
): CustomLabel[] =>
  allLicenses.filter(
    (license) =>
      !selectedLicenses.find(
        (selectedLicense) => license.uid === selectedLicense.uid,
      ),
  );
