import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { ExternalLink } from '../../../../../../../../../components/ExternalLink';
import styles from '../IntegrationPanelInfo.module.css';

type IntegrationPanelSectionErrorProps = {
  errorDetails: SessionIntegrationErrorDetails;
};

export function IntegrationPanelError({
  errorDetails,
}: IntegrationPanelSectionErrorProps): ReactElement {
  const { message, link, linkTitle } = errorDetails;
  return message || linkTitle ? (
    <div className={styles.accordionAlerts}>
      <Alert colour="warning" showIcon>
        {message}
        {linkTitle && (
          <div>
            <ExternalLink
              target="_blank"
              href={link}
              linkText={linkTitle}
              testId="integration-panel-error-details-link"
            />
          </div>
        )}
      </Alert>
    </div>
  ) : (
    <></>
  );
}
