import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { diagramApi } from '../../../api';
import { DefaultTextNodeDisplay } from '../../../../../pages/Views/Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddText/constants';
import { setIsDiagramProcessing } from '../../../slice';

export function* updateNodeInDiagramWorker({
  payload,
}: PayloadAction<UpdateDiagramNodeProps>): SagaIterator {
  yield put(setIsDiagramProcessing(true));
  yield delay(DefaultTextNodeDisplay.delayInFontSizeUpdates);
  const callResult = yield call(
    diagramApi.endpoints.updateNode.initiate,
    payload,
  );
  yield put(callResult);
  yield put(setIsDiagramProcessing(false));
}
