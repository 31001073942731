import { RowNode } from 'ag-grid-community';
import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../constants/form';

export const filterIntegrationsByType = <T extends { type: IntegrationType }>(
  integrations: undefined | T[],
  integrationType: IntegrationType,
): T[] =>
  integrations?.filter((integration) => integration.type === integrationType) ||
  [];

export const determineTypesFromIntegrations = (
  integrations: SessionIntegrationItem[] | undefined,
): { saas: boolean; publicCloud: boolean } => {
  if (!integrations) {
    return { publicCloud: false, saas: false };
  }
  let isSaasTypeFound = false;
  let isPublicCloudTypeFound = false;
  const areBothTypesFound = integrations.some((integration) => {
    if (integration.type === 'SAAS') {
      isSaasTypeFound = true;
    } else if (integration.type === 'CLOUD') {
      isPublicCloudTypeFound = true;
    }
    return isSaasTypeFound && isPublicCloudTypeFound;
  });
  return {
    publicCloud: areBothTypesFound || isPublicCloudTypeFound,
    saas: areBothTypesFound || isSaasTypeFound,
  };
};

export const getFormIntegrationAvailabilityOptions = (
  availabilityOptions: SaasTemplateAvailabilityValue[] | undefined,
): SelectOption<string>[] => {
  const options =
    availabilityOptions?.map((option) => ({
      name: option.name,
      value: option.id,
    })) ?? [];

  return [PLEASE_SELECT_OPTION, ...options];
};

export const getIntegrationAvailabilityDisplayText = (
  { data }: RowNode,
  availabilityOptions: SaasTemplateAvailabilityValue[],
): string | undefined =>
  availabilityOptions.find(({ id }) => id === data.availability.status)?.name;
