import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';
import { useDeleteVMMutation } from '../../../../redux/configuration/virtual-machine-entities/api';

interface DeleteVMModalProps {
  setVirtualMachineToDelete: (virtualMachine?: VirtualMachine) => void;
  virtualMachine: VirtualMachine;
}
export function DeleteVMModal({
  setVirtualMachineToDelete,
  virtualMachine,
}: DeleteVMModalProps): ReactElement {
  const { t } = useTranslation();
  const [deleteVM, { isLoading, isSuccess, isError }] = useDeleteVMMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setVirtualMachineToDelete(undefined);
    }
  }, [isError, isSuccess, setVirtualMachineToDelete]);

  return (
    <ConfirmModal
      heading={t('virtualMachines.delete.heading').replace(
        ':virtualMachine',
        virtualMachine?.name as string,
      )}
      highlightedSelection={virtualMachine?.name}
      subHeading={t('virtualMachines.delete.subHeading')}
      loading={isLoading}
      onCancel={() => setVirtualMachineToDelete(undefined)}
      onClose={() => setVirtualMachineToDelete(undefined)}
      onConfirm={() => deleteVM({ id: virtualMachine.uid })}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="vm-delete-modal"
    />
  );
}
