import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setLeftPanelType,
} from '../../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../../redux/store';
import { useSessionUserContext } from '../../../../../../../hooks/use-session-user-context';
import { ConnectedExpoVmLeftPanelHeaderButtons } from './expoContainer';
import { ConnectedDCloudVmLeftPanelHeaderButtons } from './dCloudContainer';

export function VmLeftPanelHeaderButtons({
  deviceUid,
  setLeftPanelType,
}: VmLeftPanelProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoVmLeftPanelHeaderButtons
        deviceUid={deviceUid}
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        setLeftPanelType={setLeftPanelType}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudVmLeftPanelHeaderButtons
      deviceUid={deviceUid}
      sessionUid={sessionUid}
      setLeftPanelType={setLeftPanelType}
      versionUid={versionUid}
    />
  );
}

const mapDispatchToProps = {
  setLeftPanelType,
};

const mapStateToProps = (state: RootState) => ({
  deviceUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type VmLeftPanelProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const VmLeftPanelHeaderButtonsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VmLeftPanelHeaderButtons);
