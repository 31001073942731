import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import styles from '../videos.module.css';

interface Props {
  description?: string;
  title: string;
  image?: string;
  id: string;
  isRecent: boolean;
  setVideoToPlay: (video: VideoToPlay) => void;
}

const Video = ({
  description,
  title,
  id,
  isRecent,
  image,
  setVideoToPlay,
}: Props): ReactElement => {
  const onVideoClick = (event: { preventDefault: VoidFunction }) => {
    setVideoToPlay({ id, title });
    event.preventDefault();
  };
  const { t } = useTranslation();
  return (
    <div
      data-testid={`video-${id}`}
      className={styles.videoContainer}
      onClick={onVideoClick}
    >
      <a href="" className={styles.videoLink}>
        <div className={styles.videoWrapper}>
          <div className={styles.video}>
            {image && <img src={image} alt={title} />}
          </div>
          <div className={styles.metaData}>
            <h3 className={styles.title}>
              {isRecent && (
                <>
                  <strong>{t('videos.updated')}</strong> -{' '}
                </>
              )}
              {title}
            </h3>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        <Icon
          name="right-arrow-closed-contained"
          className={styles.videoTileIcon}
        />
      </a>
    </div>
  );
};

export default Video;
