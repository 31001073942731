import React, { ReactElement } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { TableLoader } from '../../../../../components/Table/TableLoader';
import {
  useAddHardwareItemMutation,
  useFetchInventoryHardwareItemsQuery,
} from '../../../../../redux/configuration/hardware/api';
import { RootState } from '../../../../../redux/store';
import {
  topologyLimitsSelectors,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
} from '../../../../../redux/configuration/topologyLimits/slice';
import { topologyLimitsApi } from '../../../../../redux/configuration/topologyLimits/api';
import { useSelectHardwareInventoryItems } from '../../../../../entities/hardware/Add/use-select-hardware-inventory-items';
import { Add } from '.';

export function AddHardwareContainer({
  topologyLimitsWarningAccepted,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
}: AddHardwareContainerProps): ReactElement {
  const match = useRouteMatch();
  const { push } = useHistory();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isLoading } = useFetchInventoryHardwareItemsQuery(topologyUid);

  const { topologyLimit } =
    topologyLimitsApi.endpoints.getTopologyLimits.useQueryState(topologyUid, {
      selectFromResult: ({ data }) => ({
        topologyLimit: data?.hardware,
      }),
    });

  const [addHardware, { isError }] = useAddHardwareItemMutation();

  const handleAdd = async () => {
    await Promise.allSettled(
      selectedHardwareItems.map((item) => addHardware(item)),
    );
    push(match.url.replace('/add', ''));
  };

  const { handleOnSelect, selectedHardwareItems } =
    useSelectHardwareInventoryItems({
      topologyUid,
    });

  if (!data || isLoading) {
    return <TableLoader />;
  }

  return (
    <Add
      hardware={data.inventoryHardwareItems}
      isLoading={isLoading}
      addHardware={handleAdd}
      handleOnSelect={handleOnSelect}
      selectedHardwareItems={selectedHardwareItems}
      isError={isError}
      topologyLimit={topologyLimit}
      topologyLimitsWarningAccepted={topologyLimitsWarningAccepted}
      resetTopologyLimits={resetTopologyLimits}
      setShowTopologyLimitWarningForEntity={
        setShowTopologyLimitWarningForEntity
      }
    />
  );
}

type AddHardwareContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  topologyLimitsWarningAccepted:
    topologyLimitsSelectors.getTopologyLimitsWarningAccepted(state),
});

const mapDispatchToProps = {
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
};

export const ConnectedAddHardware = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddHardwareContainer);

type AddHardwareRTKProps = {
  hardware?: InventoryHardwareItem[];
  addHardware: VoidFunction;
  handleOnSelect: (
    isSelected: boolean,
    hardwareItem: InventoryHardwareItem,
  ) => void;
  selectedHardwareItems: InventoryHardwareItemToAdd[];
  isError: boolean;
  isLoading: boolean;
  topologyLimit?: TopologyLimit;
};

export type AddHardwareProps = AddHardwareRTKProps & AddHardwareContainerProps;
