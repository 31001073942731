import React, { ReactElement } from 'react';
import { RowNode } from 'ag-grid-community';
import styles from '../../../../Integrations.module.css';
import { getIntegrationAvailabilityDisplayText } from '../../../../../../utils/integrations';

export type AvailabilityCellRendererProps = {
  rowNode: RowNode;
  integrationAvailabilityOptions: SaasTemplateAvailabilityValue[];
};

export const AvailabilityCellRenderer = ({
  rowNode,
  integrationAvailabilityOptions,
}: AvailabilityCellRendererProps): ReactElement => {
  const value = getIntegrationAvailabilityDisplayText(
    rowNode,
    integrationAvailabilityOptions,
  );

  return (
    <div className={styles.tag}>
      <small>{value}</small>
    </div>
  );
};
