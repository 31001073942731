import { Select, SelectOption } from 'dcloud-shared-ui';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { PLEASE_SELECT_OPTION } from '../../../../../../constants/form';
import styles from './AddNetwork.module.css';

interface AddNetworkProps {
  interfaces: SelectOption<string>[];
  networks: SelectOption<string>[];
  onAddClick: (fields: AddNetworkFields) => void;
}

export function AddNetwork({
  interfaces,
  networks,
  onAddClick,
}: AddNetworkProps): ReactElement {
  const { t } = useTranslation();
  const [networkInterface, setInterface] = useState('');
  const [network, setNetwork] = useState('');

  const onSubmit = () => {
    onAddClick({ interface: networkInterface, network });
    setInterface('');
    setNetwork('');
  };

  const onInterfaceChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setInterface(event.target.value);
  };

  const onNetworkChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setNetwork(event.target.value);
  };

  return (
    <div className="row flex-middle">
      <div className="col-4">
        <Select
          name="interface"
          options={[PLEASE_SELECT_OPTION, ...interfaces]}
          label="Interface"
          value={networkInterface}
          onChange={onInterfaceChange}
        />
      </div>
      <div className="col-4">
        <Select
          name="network"
          options={[PLEASE_SELECT_OPTION, ...networks]}
          label="Network"
          value={network}
          onChange={onNetworkChange}
        />
      </div>
      <PrimaryButton
        className={styles.button}
        type="button"
        disabled={!network || !networkInterface}
        onClick={onSubmit}
        testId="add-network-add-button"
      >
        {t('buttons.add')}
      </PrimaryButton>
    </div>
  );
}
