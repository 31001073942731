import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { AnyConnect } from '.';

export function ConnectedExpoAnyConnect({
  engagementUid,
  sessionUid,
  versionUid,
}: FetchExpoSessionArgs): ReactElement {
  const { anyConnectNode, isFetching, isLoading, isError } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      {
        engagementUid,
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          anyConnectNode: data?.sessionDetails.anyConnect,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <AnyConnect
      anyConnectNode={anyConnectNode}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
