import { MAX_START_DELAY, MIN_START_DELAY } from './constants';

export function parseDelayOnBlur(newValue: string): number {
  if (!newValue) {
    return 0;
  } else {
    const integerValue = Math.round(Number(newValue));
    const validValue =
      integerValue > MAX_START_DELAY
        ? MAX_START_DELAY
        : integerValue < MIN_START_DELAY
        ? MIN_START_DELAY
        : integerValue;

    return validValue;
  }
}
