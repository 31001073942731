import React, { ReactElement } from 'react';
import styles from '../../../../Integrations.module.css';

interface TagProps {
  value: Tag;
}

export const Tag = ({ value }: TagProps): ReactElement => (
  <div className={styles.tag}>
    <small>{value.name}</small>
  </div>
);
