import { Alert } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpButton } from '../../../../components/HelpButton';
import { TableLoader } from '../../../../components/Table/TableLoader';
import { NETWORK_ADD_HELP_LINKS } from '../../../../constants/user-guide';
import { AddNetworkModal } from '../../../../entities/networks/components/AddNetworkModal';
import { ConnectedDeleteNetworkModal } from '../../../../entities/networks/components/DeleteNetworkModal/container';
import { EditNetworkModal } from '../../../../entities/networks/components/EditNetworkModal';
import { NetworksTable } from '../../../../entities/networks/components/NetworksTable';
import { PageHeadingWithCTA } from '../../components/PageHeadingWithCTA/PageHeadingWithCTA';
import { NetworksProps } from './container';

export function Networks({
  loading,
  maxNumNetworksReached,
  networkToEdit,
  networkToDelete,
  networks,
  reset,
  setNetworkToDelete,
  setNetworkToEdit,
  setShowAddModal,
  showAddModal,
}: NetworksProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onDeleteClick = useCallback(
    (network: Network) => {
      setNetworkToDelete(network);
    },
    [setNetworkToDelete],
  );

  const onEditClick = useCallback(
    (network: Network) => {
      setNetworkToEdit(network);
    },
    [setNetworkToEdit],
  );

  return (
    <div className="half-padding-left">
      <PageHeadingWithCTA
        buttonLabel={t('buttons.add')}
        buttonAriaLabel="Add Button"
        disableButton={maxNumNetworksReached || loading}
        onButtonClick={() => setShowAddModal(true)}
        pageTitle={t('networks.name')}
      />
      {loading ? (
        <TableLoader />
      ) : (
        <>
          {maxNumNetworksReached && (
            <Alert colour="info">
              {t('networks.form.maxNumNetworksReached')}
            </Alert>
          )}
          <NetworksTable
            networks={networks}
            loading={loading}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
          />
          {showAddModal && (
            <AddNetworkModal onClose={() => setShowAddModal(false)} />
          )}
          {!!networkToEdit && (
            <EditNetworkModal
              network={networkToEdit}
              onClose={() => setNetworkToEdit(undefined)}
            />
          )}
          {networkToDelete && <ConnectedDeleteNetworkModal />}
          <HelpButton links={NETWORK_ADD_HELP_LINKS} />
        </>
      )}
    </div>
  );
}
