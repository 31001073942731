import React, { ReactElement, useEffect } from 'react';
import { LinkButton } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { TemplateManagerRoutes } from '../../../ManageTemplates/pages/TemplateManager/TemplateManagerRouter';
import { LinkImageCard } from '../../../../components/Cards/LinkImageCard';
import { CreateTopologyRoutes } from '../../constants';
import ausFlag from '../../../../assets/images/flags/SYD.svg';
import usaFlag from '../../../../assets/images/flags/USA.svg';
import emeaFlag from '../../../../assets/images/flags/EMEA.svg';
import sngFlag from '../../../../assets/images/flags/SNG.svg';
import { MainRoutes } from '../../../../constants/routes';
import { sortDataCenters } from './utils';
import styles from './DataCenters.module.css';

type FlagByDataCenterRegion = {
  [K in DataCenter]: string;
};

const FLAGS: FlagByDataCenterRegion = {
  LON: emeaFlag,
  RTP: usaFlag,
  SJC: usaFlag,
  SNG: sngFlag,
  SYD: ausFlag,
};

export interface DataCentersProps {
  setDataCenter: (dataCenter: DataCenter) => void;
  resetDemoType: VoidFunction;
  datacenters: DataCenterFromApi[];
  isTemplate?: boolean;
}

export function DataCenters({
  resetDemoType,
  setDataCenter,
  datacenters,
  isTemplate,
}: DataCentersProps): ReactElement {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  useEffect(() => {
    resetDemoType();
  }, [resetDemoType]);

  return (
    <>
      <div className="row flex-center">
        {sortDataCenters(datacenters).map((dataCenter) => (
          <div
            key={dataCenter.region}
            className={classnames('col-md-4', styles.dataCenterCard)}
            data-test="data-center"
          >
            <LinkImageCard
              path={`${path}${
                isTemplate
                  ? TemplateManagerRoutes.VmTemplates
                  : CreateTopologyRoutes.DemoType
              }`}
              imageAltText={dataCenter.location}
              imageSrc={FLAGS[dataCenter.id]}
              onClick={() => setDataCenter(dataCenter.id)}
              subTitle={dataCenter.location}
              testId={`data-center-${dataCenter.id.toLowerCase()}`}
              title={dataCenter.region}
            />
          </div>
        ))}
      </div>
      <div className="text-center dbl-margin-top">
        <LinkButton
          to={isTemplate ? MainRoutes.Main : MainRoutes.Topologies}
          colour="ghost"
          size="large"
        >
          {t('buttons.cancel')}
        </LinkButton>
      </div>
    </>
  );
}
