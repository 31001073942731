import React, {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
} from 'react';
import { useTheme } from 'dcloud-shared-ui';
import classNames from 'classnames';
import styles from './Icon.module.css';

export interface IconProps {
  title?: string;
  onSelect: Dispatch<SetStateAction<string>>;
  isSelected: boolean;
}

export function VmIcon({
  children,
  title,
  isSelected,
  onSelect,
}: PropsWithChildren<IconProps>): ReactElement {
  const [theme] = useTheme();
  const isLightTheme = theme === 'light';

  return (
    <div
      onClick={() => onSelect(title || '')}
      className={classNames(
        styles.root,
        isSelected ? styles.isVmIconSelected : '',
        isLightTheme ? styles.lightIcon : styles.darkIcon,
      )}
      data-testid={`vm-icon-${title}`}
    >
      <div className={styles.iconImage}>{children}</div>
      <p className={styles.iconLabel}>{title}</p>
    </div>
  );
}
