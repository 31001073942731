import { SelectOption } from 'dcloud-shared-ui';
import { NAT_TYPES } from '../../../../../constants/entities';
import { PLEASE_SELECT_OPTION } from '../../../../../constants/form';
import i18n from '../../../../../i18n';
import {
  NAT_RULE_SCOPES,
  NAT_RULE_TARGET_TYPES,
  NAT_RULE_TYPES,
} from '../../../../../constants/nat-rules';
import { NAT_RULE_LABEL_KEYS } from './constants';
import { determineNatRuleTargetTypeFromNatRuleType } from './components/NatRulesAddForm/utils/utils';

interface BuildNatRulePayloadProps {
  formData: NatRuleFormData;
  topologyUid: TopologyUid['uid'];
}

interface BuildNatIpOptionsProps {
  vmTargets: VmNicTarget[];
  vmName: string;
}

export const buildNatTypesOptions = (): SelectOption<string>[] => {
  const options = Object.keys(NAT_TYPES).map((key) => ({
    name: i18n.t(`traffic.natRules.add.type.${key}`),
    value: NAT_TYPES[key],
  }));

  return [PLEASE_SELECT_OPTION, ...options];
};

export const determineScopeFromType = (
  type: NatRuleType | '' | undefined,
): NatRuleScope | undefined => {
  switch (type) {
    case NAT_RULE_TYPES.publicIp:
    case NAT_RULE_TYPES.publicVmNic:
      return NAT_RULE_SCOPES.public;
    case NAT_RULE_TYPES.internalIp:
    case NAT_RULE_TYPES.internalVmNic:
      return NAT_RULE_SCOPES.internal;
    default:
      return undefined;
  }
};

export const buildNatRulePayload = ({
  formData,
  topologyUid,
}: BuildNatRulePayloadProps): NatRulePostPayload => {
  const { eastWest, ...target } = formData;
  const scope = determineScopeFromType(formData.type);

  if (
    determineNatRuleTargetTypeFromNatRuleType(formData.type) ===
    NAT_RULE_TARGET_TYPES.ip
  ) {
    return {
      eastWest,
      scope,
      target: {
        ipAddress: target.ipAddress,
        name: target.name,
      },
      topology: { uid: topologyUid },
    };
  } else {
    return {
      eastWest,
      scope,
      target: { targetItem: target.targetItem },
      topology: { uid: topologyUid },
    };
  }
};

export const buildVmNameOptions = (
  vmTargets: VmNicTarget[],
): SelectOption<string>[] => {
  const vms = vmTargets.filter((target) => target.vm).map(({ vm }) => vm);
  const options = vms.map(({ name }) => ({ name, value: name }));
  const result = options.filter(
    (currentItem, index, options) =>
      options.findIndex((target) => target.name === currentItem.name) === index,
  );
  return [PLEASE_SELECT_OPTION, ...result];
};

export const buildNatIpOptions = ({
  vmTargets,
  vmName,
}: BuildNatIpOptionsProps): SelectOption<string>[] => {
  vmTargets = vmTargets.filter((nic) => (nic.vm ? nic : null));
  const vmTargetsForName = vmTargets.filter(
    (nic) => nic.vm.name === vmName && nic.available,
  );

  const options = vmTargetsForName.map(({ ipAddress, vm }: VmNicTarget) => ({
    name: ipAddress!,
    value: vm.networkInterface.uid!,
  }));

  return [PLEASE_SELECT_OPTION, ...options];
};

export const buildInternalIps = (vmTargets: VmNicTarget[]): string[] => {
  const usedTargets = vmTargets.filter((nic) => !nic.available);
  return usedTargets.map(({ ipAddress }: VmNicTarget) => ipAddress);
};

export const generateNatRuleTypeLabel = ({
  scope,
  target: { type },
}: NatRule): string =>
  i18n.t(`traffic.natRules.add.type.${NAT_RULE_LABEL_KEYS[scope][type]}`);
