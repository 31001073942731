export const searchVms = (
  virtulaMachines: SessionInventoryVirtualMachineData[],
  searchText: string,
): SessionInventoryVirtualMachineData[] => {
  const searchTextLower = searchText.toLocaleLowerCase();

  return virtulaMachines.filter(
    (virtulaMachine) =>
      virtulaMachine.name.toLocaleLowerCase().indexOf(searchTextLower) !== -1 ||
      virtulaMachine.description
        .toLocaleLowerCase()
        .indexOf(searchTextLower) !== -1 ||
      virtulaMachine.memory.toString().indexOf(searchText) !== -1 ||
      virtulaMachine.cpuQty.toString().indexOf(searchText) !== -1,
  );
};

export const searchHw = (
  hardware: SessionInventoryHardwareData[],
  searchText: string,
): SessionInventoryHardwareData[] => {
  const searchTextLower = searchText.toLocaleLowerCase();

  return hardware.filter(
    (hardware) =>
      hardware.name.toLocaleLowerCase().indexOf(searchTextLower) !== -1 ||
      hardware.deviceType.toLocaleLowerCase().indexOf(searchTextLower) !== -1 ||
      hardware.description.toLocaleLowerCase().indexOf(searchTextLower) !== -1,
  );
};
