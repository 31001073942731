import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../../components';
import { TableLoader } from '../../../../../../../components/Table/TableLoader';
import { InboundProxyRulesTableActions } from '../InboundProxyRulesTableActions';

type InboundProxyRulesTableProps = {
  isError: boolean;
  isLoading: boolean;
  inboundProxyRules: InboundProxyRule[];
  onDeleteClick: (inboundProxyRule: InboundProxyRule) => void;
};

export function InboundProxyRulesTable({
  isError,
  isLoading,
  inboundProxyRules,
  onDeleteClick,
}: InboundProxyRulesTableProps): ReactElement | null {
  const { t } = useTranslation();
  const columns = useMemo<Column<InboundProxyRule>[]>(
    () => [
      {
        Header: () => t('traffic.inboundProxyRules.table.target'),
        accessor: ({ vmNicTarget }) => vmNicTarget.vm.name,
        id: 'target',
        width: 150,
      },
      {
        Header: () => t('traffic.inboundProxyRules.table.ipAddress'),
        accessor: ({ vmNicTarget }) => vmNicTarget.ipAddress,
        id: 'ipAddress',
        width: 80,
      },
      {
        Header: () => t('traffic.inboundProxyRules.table.tcpPort'),
        accessor: ({ tcpPort }) => tcpPort,
        id: 'tcpPort',
        width: 50,
      },
      {
        Header: () => t('traffic.inboundProxyRules.table.ssl'),
        accessor: ({ ssl }) => (ssl ? t('common.yes') : t('common.no')),
        id: 'ssl',
        width: 50,
      },
      {
        Header: () => t('traffic.inboundProxyRules.table.urlPath'),
        accessor: ({ urlPath }) => urlPath,
        id: 'urlPath',
        minWidth: 150,
      },
      {
        Header: () => t('traffic.inboundProxyRules.table.showHyperlink'),
        accessor: ({ hyperlink }) =>
          hyperlink.show ? t('common.yes') : t('common.no'),
        id: 'showHyperlink',
        width: 80,
      },
      {
        Header: () => t('traffic.inboundProxyRules.table.hyperlinkText'),
        accessor: ({ hyperlink }) => hyperlink.text,
        id: 'hyperLinkText',
        width: 150,
      },
    ],
    [t],
  );

  const renderActions = (inboundProxyRule: InboundProxyRule): ReactNode => (
    <InboundProxyRulesTableActions
      onDeleteClick={() => onDeleteClick(inboundProxyRule)}
    />
  );

  if (isError || isLoading || !inboundProxyRules) {
    return <TableLoader />;
  }

  return (
    <Table<InboundProxyRule>
      actions={renderActions}
      data={inboundProxyRules}
      columns={columns}
    />
  );
}
