export const checkForDuplicateIpsHelper = (
  interfacesFromForm: NetworkToConfigure[],
  value: string,
  networkId: string,
  vms: VirtualMachine[],
): boolean => {
  const checkIfDupIpExistsInCurrentFormStateResult: boolean =
    checkForDupIpInCurrentFormState(interfacesFromForm, value, networkId);

  if (checkIfDupIpExistsInCurrentFormStateResult === true) {
    return true;
  } else {
    return checkForDupIpInExistingVmsForNetwork(value, networkId, vms);
  }
};

export const checkForDupIpInCurrentFormState = (
  interfacesFromForm: NetworkToConfigure[],
  value: string,
  networkId: string,
): boolean => {
  const usedIps: string[] = interfacesFromForm.reduce(function (
    result: string[],
    interfaceItem,
  ) {
    interfaceItem.network === networkId &&
      interfaceItem.ipAddress &&
      result.push(interfaceItem.ipAddress);

    return result;
  },
  []);

  removeCurrentValueFromArray(usedIps, value);
  return usedIps.filter((item) => item === value).length !== 0;
};

export const removeCurrentValueFromArray = (
  usedIps: string[],
  value: string,
): string[] => {
  const index = usedIps.indexOf(value);
  usedIps.splice(index, 1);
  return usedIps;
};

export const checkForDupIpInExistingVmsForNetwork = (
  value: string,
  networkId: string,
  vms: VirtualMachine[],
): boolean =>
  vms.some((vm) =>
    vm.vmNetworkInterfaces.some(
      (vmNetworkInterface) =>
        vmNetworkInterface.network?.uid === networkId &&
        vmNetworkInterface.ipAddress === value,
    ),
  );

export const calculateCurrentNicCountForNetworkInForm = (
  interfaces: NetworkToConfigure[],
  networkId: string,
): number => {
  const currentNicCountForNetworkInForm: number = interfaces.reduce(function (
    result: number,
    interfaceItem,
  ) {
    interfaceItem.network === networkId && result++;
    return result;
  },
  0);
  return currentNicCountForNetworkInForm;
};

export const checkForDuplicateMacAddresssHelper = (
  interfacesFromForm: NetworkToConfigure[],
  value: string,
  networkId: string,
  vms: VirtualMachine[],
): boolean => {
  const checkIfDupMacExistsInCurrentFormStateResult: boolean =
    checkForDupMacInCurrentFormState(interfacesFromForm, value, networkId);

  if (checkIfDupMacExistsInCurrentFormStateResult === true) {
    return true;
  } else {
    return checkForDupMacInExistingVmsForNetwork(value, networkId, vms);
  }
};

export const checkForDupMacInCurrentFormState = (
  interfacesFromForm: NetworkToConfigure[],
  value: string,
  networkId: string,
): boolean => {
  const usedMacs: string[] = interfacesFromForm.reduce(function (
    result: string[],
    interfaceItem,
  ) {
    interfaceItem.network === networkId &&
      interfaceItem.macAddress &&
      result.push(interfaceItem.macAddress);

    return result;
  },
  []);

  removeCurrentValueFromArray(usedMacs, value);
  return usedMacs.filter((item) => item === value).length !== 0;
};

export const checkForDupMacInExistingVmsForNetwork = (
  value: string,
  networkId: string,
  vms: VirtualMachine[],
): boolean =>
  vms.some((vm) =>
    vm.vmNetworkInterfaces.some(
      (vmNetworkInterface) =>
        vmNetworkInterface.network?.uid === networkId &&
        vmNetworkInterface.macAddress === value,
    ),
  );
