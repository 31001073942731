import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../components';
import { SessionInventoryActionsConfirmModalProps } from './container';
import { buildSubHeading } from './utils';

export const SessionInventoryActionsConfirmModal = ({
  setHasAcceptedMultiSelectActionConfirm,
  hasAcceptedMultiSelectActionConfirm,
  setSelectedAction,
  selectedAction,
}: SessionInventoryActionsConfirmModalProps): ReactElement => {
  const { t } = useTranslation();

  const onConfirm = () => {
    setHasAcceptedMultiSelectActionConfirm(true);
  };

  return (
    <ConfirmModal
      show={hasAcceptedMultiSelectActionConfirm === false}
      heading={t('sessionsInventory.multiSelectConfirmModal.heading')}
      subHeading={buildSubHeading(selectedAction)}
      onCancel={() => setSelectedAction(undefined)}
      onClose={() => setSelectedAction(undefined)}
      onConfirm={onConfirm}
      confirmButtonColour="primary"
      loading={false}
      confirmButtonLabel={t('buttons.confirm')}
      cancelButtonLabel={t('buttons.cancel')}
      testId="session-inventory-action-modal"
    />
  );
};
