import { match } from 'react-router-dom';
import { SideTabsProps } from 'dcloud-shared-ui';
import { isUserPermitted } from '../../../auth/components/UserRoleGuard/utils';
import { ConfigurationRoute, ConfigurationRoutes } from './ConfigurationRouter';

const applyStringReplace = (
  path: string,
  replace: ConfigurationRoute['replace'],
): string =>
  !replace ? path : path.replace(replace['part'], replace['value']);

export const buildLinks = (
  routes: ConfigurationRoutes,
  userRoles: UserRole[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  match: match<{}>,
  uid: string,
): SideTabsProps['tabs'] => {
  return routes.reduce(function (sideTabs: SideTabsProps['tabs'], route) {
    if (
      (route.requiredRoles &&
        isUserPermitted(userRoles, route.requiredRoles)) ||
      !route.requiredRoles
    ) {
      if ('path' in route) {
        sideTabs.push({
          label: route.label,
          pathname: applyStringReplace(
            `${match.url}${route.path?.replace(':uid', uid)}`,
            route.replace,
          ),
        });
      } else {
        sideTabs.push({
          label: route.label,
          sideTabs: route.nestedRoutes
            .filter(
              (route) =>
                (route.requiredRoles &&
                  isUserPermitted(userRoles, route.requiredRoles)) ||
                !route.requiredRoles,
            )
            .map((nestedRoute) => ({
              label: nestedRoute.label,
              pathname: applyStringReplace(
                `${match.url}${nestedRoute.path?.replace(':uid', uid)}`,
                nestedRoute.replace,
              ),
            })),
        });
      }
    }
    return sideTabs;
  }, []);
};

export const willMemoryLimitBeExceeded = (
  vm: InventoryVirtualMachine | VirtualMachine,
  topologyLimit: TopologyLimit,
): boolean => {
  return vm.memoryMb + topologyLimit.current > topologyLimit.limit;
};

export const willCpuLimitBeExceeded = (
  vm: InventoryVirtualMachine | VirtualMachine,
  topologyLimit: TopologyLimit,
): boolean => {
  return vm.cpuQty + topologyLimit.current > topologyLimit.limit;
};
