import { Header, ThemeSwitch } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionInventoryContentContainer } from '../SessionInventory/components/SessionInventoryContent/container';
import { DemoHeaderTitleContainer } from '../SessionView/components/DemoHeaderTitle/container';
import { SessionViewNavigationButtons } from '../components/SessionViewNavigationButtons';
import { SessionViewContainer } from '../SessionView/container';
import { ConnectedSessionInventoryActionConfirmModal } from '../SessionInventory/components/SessionInventoryActionConfirmModal/container';
import { SessionInventoryActionsConfirmModalContainer } from '../SessionInventory/components/SessionInventoryActionsConfirmModal/container';
import { SessionProps } from './container';
import { useUnsubscribeFromGetAllVmStatusesData } from './hooks/useUnsubscribeFromGetAllVmStatusesData';
import { useResetMultiSelectData } from './hooks/useResetMultiSelectData';

export const Session = ({
  showPage,
  unsubscribeFromGetAllVmStatusesData,
  resetMultiSelect,
}: SessionProps): ReactElement => {
  const { t } = useTranslation();

  useUnsubscribeFromGetAllVmStatusesData({
    unsubscribeFromGetAllVmStatusesData,
  });

  useResetMultiSelectData({ resetMultiSelect });

  const mainTitle =
    showPage === 'session-view'
      ? 'sessions.siteTitle'
      : 'sessionsInventory.siteTitle';

  return (
    <>
      <Header
        left={<DemoHeaderTitleContainer />}
        title={t(mainTitle)}
        right={<ThemeSwitch />}
        center={<SessionViewNavigationButtons />}
      />
      {showPage === 'session-view' ? (
        <SessionViewContainer />
      ) : (
        <>
          <SessionInventoryContentContainer />
          <ConnectedSessionInventoryActionConfirmModal />
          <SessionInventoryActionsConfirmModalContainer />
        </>
      )}
    </>
  );
};
