import React, { ReactElement } from 'react';
import { useHeroCarousel } from '../hooks/use-hero-carousel';
import { CarouselWrapper } from '../CarouselWrapper';
import { CarouselWrapperForAnimation } from '../CarouselWrapperForAnimation';
import { RESET_TRANSITION_TIME, TRANSITION_TIME } from '../../constants';

export type HomePageCarouselProps = {
  slides: ReactElement[];
};

export function HomePageCarousel({
  slides,
}: HomePageCarouselProps): ReactElement {
  const loopingSlide = slides[0];
  const items = [loopingSlide, ...slides, loopingSlide];

  const { activeIndex, setActiveIndex } = useHeroCarousel({
    numOfItems: items.length,
    resetTransitionTime: RESET_TRANSITION_TIME,
    transitionTime: TRANSITION_TIME,
  });

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }
    setActiveIndex(newIndex);
  };

  if (activeIndex === 0) {
    return (
      <CarouselWrapper
        activeIndex={activeIndex}
        updateIndex={updateIndex}
        items={items}
        isAnimating={false}
      />
    );
  } else {
    return (
      <CarouselWrapperForAnimation
        activeIndex={activeIndex}
        updateIndex={updateIndex}
        items={items}
      />
    );
  }
}
