import React, { ReactElement } from 'react';
import styles from '../../../../VmNode.module.css';

export type VmIconsFlexWrapperProps = { icons: JSX.Element[] };

export function VmIconsFlexWrapper({
  icons,
}: VmIconsFlexWrapperProps): ReactElement {
  if (icons.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.flexWrapper}>
      {icons.map((icon, index) => {
        return (
          <div key={index} className={styles.flexItem}>
            {icon}
          </div>
        );
      })}
    </div>
  );
}
