import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../components';
import { useDeleteAccessRoleAssignmentMutation } from '../../../../../../redux/configuration/sharing/api';

interface AccessRoleAssignmentDeleteModalProps {
  setAccessRoleAssignmentToDelete: (
    accessRoleAssignment?: AccessRoleAssignment,
  ) => void;
  accessRoleAssignment: AccessRoleAssignment;
}
export function AccessRoleAssignmentDeleteModal({
  setAccessRoleAssignmentToDelete,
  accessRoleAssignment,
}: AccessRoleAssignmentDeleteModalProps): ReactElement {
  const { t } = useTranslation();
  const [deleteAccessRoleAssignment, { isLoading, isSuccess, isError }] =
    useDeleteAccessRoleAssignmentMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setAccessRoleAssignmentToDelete(undefined);
    }
  }, [isError, isSuccess, setAccessRoleAssignmentToDelete]);

  return (
    <ConfirmModal
      heading={t('accessRoleAssignment.delete.heading')}
      subHeading={t('accessRoleAssignment.delete.subHeading')}
      loading={isLoading}
      onCancel={() => setAccessRoleAssignmentToDelete(undefined)}
      onClose={() => setAccessRoleAssignmentToDelete(undefined)}
      onConfirm={() => deleteAccessRoleAssignment(accessRoleAssignment.uid)}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="access-role-assignment-delete-modal"
    />
  );
}
