const INITIAL_LEFT_OFFSET = 180;
const INITIAL_TOP_OFFSET = 25;

export const calculateTopOffset = (
  currentTopValue: number,
  scrollTopValue: number,
): number => {
  const result = currentTopValue + scrollTopValue - INITIAL_TOP_OFFSET;
  return result;
};

export const calculateLeftOffset = (
  currentLeftValue: number,
  scrollLeftValue: number,
): number => {
  const result = currentLeftValue + scrollLeftValue - INITIAL_LEFT_OFFSET;
  return result;
};
