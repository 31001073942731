import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';
import { ConfirmModal } from '../../../../../../components';

interface DeleteNetworkModalProps {
  loading?: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  show: boolean;
}

enum HeadingKeys {
  Heading = 'heading',
  SubHeading = 'subHeading',
}

const translationKey = (key: HeadingKeys): string =>
  `hardware.edit.networkingInterface.delete.${key}`;

export function DeleteNetworkModal({
  loading = false,
  onClose,
  onConfirm,
  show,
}: DeleteNetworkModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      heading={t(translationKey(HeadingKeys.Heading))}
      subHeading={t(translationKey(HeadingKeys.SubHeading))}
      onClose={onClose}
      onCancel={onClose}
      loading={loading}
      onConfirm={onConfirm}
      confirmButtonLabel={t('buttons.delete')}
      show={show}
    />
  );
}
