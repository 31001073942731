import React, { ReactElement } from 'react';
import { AnyconnectNode } from '../AnyconnectNode';
import { EndpointKitNode } from '../EndpointKitNode';
import { HardwareNodeContainer } from '../HardwareNode/container';
import { InternetCloudNode } from '../InternetCloudNode';
import { VMNodeContainer } from '../VmNode/container';
import { VPodGateway } from '../VPodGateway';
import { ConnectedNetworkNode } from '../NetworkNode/NetworkNodeContainer';
import { DefaultBoxNodeDisplay } from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddBox/constants';
import { DefaultLineNodeDisplay } from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLine/constants';
import { ConnectedFourHandleNode } from '../FourHandleNode/container';
import { ConnectedTwoHandleLineNode } from '../TwoHandleLineNode/container';
import { DefaultTextNodeDisplay } from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddText/constants';
import { ConnectedDiagramTextNode } from '../DiagramTextNode/container';
import { DefaultIconNodeDisplay } from '../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddIcon/constants';
import { IconNodeContainer } from '../IconNode/container';
import { useDiagramContext } from '../DiagramProvider';
import { DiagramBoxSkeletonLoader } from './components/DiagramBoxSkeletonLoader';
import { DiagramLineSkeletonLoader } from './components/DiagramLineSkeletonLoader';
import { DiagramTextSkeletonLoader } from './components/DiagramTextSkeletonLoader';
import { determineNodesClasses } from './utils/utils';

interface NodesProps {
  isReadOnly: boolean;
  isAddingBox: boolean;
  isAddingLine: boolean;
  isAddingText: boolean;
  natRules?: NatRule[];
  inBoundProxyRules?: InboundProxyRule[];
  nodes: DiagramNodes;
  isSnapToGridEnabled?: boolean;
  isAddingLogicalConnection: boolean;
}

export function Nodes({
  isReadOnly,
  nodes,
  isAddingBox,
  isAddingLine,
  isAddingText,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
}: NodesProps): ReactElement {
  const { selectedItem } = useDiagramContext();

  const rootStyles = determineNodesClasses({
    isAddingLogicalConnection,
    selectedItem,
  });

  return (
    <div id="nodesCanvas" className={rootStyles} data-clickablearea>
      {nodes.map((node) => {
        switch (node.assetType) {
          case 'NETWORK':
            return (
              <ConnectedNetworkNode
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
              />
            );

          case 'HARDWARE_ITEM':
            return (
              <HardwareNodeContainer
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
              />
            );

          case 'INTERNET_CLOUD':
            return (
              <InternetCloudNode
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
                isSnapToGridEnabled={isSnapToGridEnabled}
                isAddingLogicalConnection={isAddingLogicalConnection}
              />
            );

          case 'VPOD_GATEWAY':
            return (
              <VPodGateway
                display={node.display}
                key={node.uid}
                uid={node.uid}
                isReadOnly={isReadOnly}
                isSnapToGridEnabled={isSnapToGridEnabled}
                isAddingLogicalConnection={isAddingLogicalConnection}
              />
            );

          case 'VM':
            return (
              <VMNodeContainer
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
              />
            );

          case 'ANY_CONNECT':
            return (
              <AnyconnectNode
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
                isSnapToGridEnabled={isSnapToGridEnabled}
                isAddingLogicalConnection={isAddingLogicalConnection}
              />
            );

          case 'ENDPOINT_KIT':
            return (
              <EndpointKitNode
                {...node}
                key={node.uid}
                isReadOnly={isReadOnly}
                isSnapToGridEnabled={isSnapToGridEnabled}
              />
            );
          default:
            switch (node?.display?.drawingType) {
              case DefaultBoxNodeDisplay.drawingType:
                return (
                  <ConnectedFourHandleNode
                    display={node.display as DiagramBoxDisplay}
                    isReadOnly={isReadOnly}
                    uid={node.uid}
                    key={node.uid}
                    isSnapToGridEnabled={isSnapToGridEnabled}
                  />
                );
              case DefaultLineNodeDisplay.drawingType:
                return (
                  <ConnectedTwoHandleLineNode
                    display={node.display as DiagramLineDisplay}
                    isReadOnly={isReadOnly}
                    uid={node.uid}
                    key={node.uid}
                    isSnapToGridEnabled={isSnapToGridEnabled}
                  />
                );
              case DefaultTextNodeDisplay.drawingType:
                return (
                  <ConnectedDiagramTextNode
                    display={node.display as DiagramTextDisplay}
                    isReadOnly={isReadOnly}
                    uid={node.uid}
                    key={node.uid}
                  />
                );
              case DefaultIconNodeDisplay.drawingType:
                return (
                  <IconNodeContainer
                    display={node.display as DiagramIconDisplay}
                    isReadOnly={isReadOnly}
                    uid={node.uid}
                    key={node.uid}
                  />
                );
              default:
                return <span key={node.uid}></span>;
            }
        }
      })}
      {isAddingBox && <DiagramBoxSkeletonLoader />}
      {isAddingLine && <DiagramLineSkeletonLoader />}
      {isAddingText && <DiagramTextSkeletonLoader />}
    </div>
  );
}
