import React, { ReactElement } from 'react';
import { format } from 'date-fns';
import { LAST_UPDATED_DATE_FORMAT } from '../../../../EditTopology/configuration/VirtualMachines/constants';
import { ArchitectureLabelGroup } from '../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import i18n from '../../../../../i18n';

export const buildTemplateInfoColsData = (
  showMoreInfoForInventoryTemplate: InventoryTopology,
): InfoModalData[] => {
  return [
    {
      data: [
        {
          label: i18n.t('inventoryTopologies.infoModal.headings.datacenter'),
          value: showMoreInfoForInventoryTemplate.datacenter,
        },
        {
          label: i18n.t('inventoryTopologies.infoModal.headings.lastUpdated'),
          value: showMoreInfoForInventoryTemplate.lastUpdated
            ? format(
                new Date(showMoreInfoForInventoryTemplate.lastUpdated),
                LAST_UPDATED_DATE_FORMAT,
              )
            : ' - ',
        },
      ],
    },
    {
      data: [
        {
          label: i18n.t('inventoryTopologies.infoModal.headings.demoId'),
          value: showMoreInfoForInventoryTemplate.demoId,
        },
        {
          label: i18n.t('inventoryTopologies.infoModal.headings.topologyUid'),
          value: showMoreInfoForInventoryTemplate.uid,
        },
      ],
    },
  ];
};

export const buildTemplateInfoModalFooter = (
  showMoreInfoForInventoryTemplate: InventoryTopology,
): ReactElement => {
  return (
    <div className="row dbl-padding-right">
      <div className="col-3 dbl-margin-bottom dbl-padding-right">
        <div className="text-left">
          <strong>
            {i18n.t('inventoryTopologies.infoModal.headings.description')}
          </strong>
        </div>
      </div>
      <div className="col-9 base-margin-bottom no-padding-left">
        {showMoreInfoForInventoryTemplate.description}
      </div>
      <div className="col-3 dbl-margin-bottom">
        <div className="text-left">
          <strong>
            {i18n.t('inventoryTopologies.infoModal.headings.originalName')}
          </strong>
        </div>
      </div>
      <div className="col-9 base-margin-bottom no-padding-left">
        {showMoreInfoForInventoryTemplate.originalName}
      </div>
      <div className="col-3">
        <div className="text-left">
          <strong>
            {i18n.t('inventoryTopologies.infoModal.headings.architecture')}
          </strong>
        </div>
      </div>
      <div className="col-9 no-padding-left">
        <div className="text-left">
          {showMoreInfoForInventoryTemplate.architectures ? (
            <ArchitectureLabelGroup
              architectures={showMoreInfoForInventoryTemplate.architectures}
            />
          ) : (
            ' - '
          )}
        </div>
      </div>
    </div>
  );
};
