import { connect } from 'react-redux';
import {
  setSelectedAction,
  setSelectedNodeType,
} from '../../../../../../../redux/views/session-view/session-inventory/slice';
import { ResetPowerDropdownItem } from '.';

const mapDispatchToProps = {
  setSelectedAction,
  setSelectedNodeType,
};

export const ResetPowerDropdownItemContainer = connect(
  null,
  mapDispatchToProps,
)(ResetPowerDropdownItem);

export type ResetPowerDropdownItemProps = typeof mapDispatchToProps;
