import { Label, LabelColours } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './WizardLabel.module.css';

export interface WizardLabelProps {
  labelColour: LabelColours;
  labelText?: string;
  labelTestId: string;
  text: string;
}

export function WizardLabel({
  labelColour,
  labelText,
  labelTestId,
  text,
}: WizardLabelProps): ReactElement {
  return (
    <>
      <span className={styles.text}>{text}</span>
      {labelText && (
        <Label
          colour={labelColour}
          className={styles.label}
          testId={labelTestId}
        >
          {labelText}
        </Label>
      )}
    </>
  );
}
