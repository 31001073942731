import React, { HTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';
import { generateId } from '../../utils';
import styles from './TabsItem.module.css';

export interface TabsItemProps extends HTMLAttributes<HTMLLIElement> {
  active?: boolean;
  bordered?: boolean;
  id: string;
  index: number;
  setSelected(index: number, title: string): void;
  title: string;
  icon?: ReactElement;
}

export function TabsItem({
  active = false,
  bordered = false,
  id,
  index,
  setSelected,
  title,
  icon,
}: TabsItemProps): ReactElement {
  return (
    <li
      className={classnames('tab', { [styles.inactive]: !active })}
      key={title}
      role="presentation"
    >
      <button
        type="button"
        aria-selected={active}
        aria-controls={generateId(id, title, 'panel')}
        className={classnames(styles['button'], {
          [styles.active]: active,
          [styles.bordered]: bordered,
          [styles.borderedActive]: bordered && active,
        })}
        id={generateId(id, title, 'button')}
        onClick={() => setSelected(index, title)}
        role="tab"
        tabIndex={active ? -1 : 0}
      >
        {icon}
        {title}
      </button>
    </li>
  );
}
