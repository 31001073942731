import React, { ReactElement } from 'react';
import { ArchitectureLabel } from '../ArchitectureLabel';
import styles from './ArchitectureLabelPicker.module.css';

export type ArchitectureLabelPickerProps = {
  architectures: Architecture[];
  onAdd: (architechture: Architecture) => void;
  hideMissing?: boolean;
};

export const ArchitectureLabelPicker = ({
  architectures,
  onAdd,
  hideMissing = true,
}: ArchitectureLabelPickerProps): ReactElement => {
  return (
    <div className={styles.pickerContainer}>
      {architectures &&
        architectures.map((architecture, index) => {
          return (
            <ArchitectureLabel
              key={(architecture.uid, index)}
              architecture={architecture}
              onAdd={() => onAdd(architecture)}
              hideMissing={hideMissing}
            />
          );
        })}
    </div>
  );
};
