import React, { ReactElement } from 'react';
import styles from '../../videos.module.css';
import { Modal } from '../../../../components';

interface Props {
  videoToPlay: VideoToPlay;
  setVideoToPlay: (video: Video | undefined) => void;
}

const VideoModal = ({
  videoToPlay,
  setVideoToPlay,
}: Props): ReactElement | null => {
  return videoToPlay ? (
    <Modal
      size="large"
      clickScreenToClose={true}
      show={true}
      onClose={() => setVideoToPlay(undefined)}
    >
      <div className={styles.iframeWrapper}>
        <div className={styles.iframeContainer}>
          <iframe
            className={styles.videoIframe}
            src={`https://player.vimeo.com/video/${videoToPlay.id}?dnt=1&autoplay=1&portrait=0`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default VideoModal;
