import { api } from '.';

type EmbeddedResponse<T extends Record<string, unknown>> = {
  _embedded: T;
};

type GetResponse<T extends Record<string, unknown>> = {
  data: T;
  etag?: string;
};

export const stripGzipFromEtag = (etag = ''): string =>
  etag.replace('--gzip', '');

export async function get<T extends Record<string, unknown>>(
  url: string,
): Promise<GetResponse<T>> {
  const response = {} as GetResponse<T>;
  const { data, headers } = await api.get<EmbeddedResponse<T> | T>(url);

  response.data = (data?._embedded || data) as T;

  if (headers.etag) {
    response.etag = stripGzipFromEtag(headers.etag);
  }

  return response;
}

export const returnApiErrors = (errors: ApiError[]): string[] =>
  errors.map(({ message }) => message);
