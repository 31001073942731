import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useDiagramContext } from '../../../DiagramProvider';
import { physicalConnectionLineDefaults } from '../../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLogicalConnection/constants';
import styles from './index.module.css';

export type PhysicalConnectionSelectorProps = {
  connection: DiagramConnection;
  isHiddenFromSessionView?: boolean;
  isSessionView: boolean;
};

export const PhysicalConnectionSelector = ({
  connection,
  isSessionView,
  isHiddenFromSessionView,
}: PhysicalConnectionSelectorProps): ReactElement => {
  const { selectedItem } = useDiagramContext();
  if (isSessionView || isHiddenFromSessionView) return <></>;

  const connectionType = 'PHYSICAL';
  const cursor = 'pointer';
  const dataUid = `${connection.uid}-selector`;
  const dataComponent = `${connection.uid}-selector`;

  const classes = classNames(styles.hidden, {
    [styles.selected]: selectedItem === connection.uid,
  });

  return (
    <line
      cursor={cursor}
      strokeWidth={physicalConnectionLineDefaults.strokeWidth}
      data-uid={dataUid}
      data-component={dataComponent}
      data-connection-from={connection.nodes.from.uid}
      data-connection-to={connection.nodes.to.uid}
      data-connection-from-to={connection.derived?.fromTo}
      data-connection-type={connectionType}
      data-connection-stroke-type={physicalConnectionLineDefaults.strokeType}
      data-connection-selector={true}
      className={classes}
    ></line>
  );
};
