import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../../../redux/views/session-view/session/api/api';
import { Proxy } from '.';

export function ConnectedDCloudProxy({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const { sessionProxyAddresses, isLoading, isFetching, isError } =
    sessionApi.endpoints.getSession.useQueryState(
      {
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionProxyAddresses: data?.sessionDetails.sessionProxyAddresses,
        }),
      },
    );

  return (
    <Proxy
      sessionProxyAddresses={sessionProxyAddresses}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}

export type ProxyProps = {
  sessionProxyAddresses?: SessionProxyAddress[];
  isLoading: boolean;
  isError: boolean;
};
