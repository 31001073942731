import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import i18n from '../i18n';
import { NetworkingFormData } from '../pages/EditTopology/configuration/components/VirtualMachineEntities/Edit/components/Networking/components/NetworkingForm';
import { TemplateNetworkingFormData } from '../pages/ManageTemplates/pages/VmTemplates/Components/TemplateNetworks/components/TemplateNetworkingForm';
import {
  CISCO_DOMAIN_REGEX,
  DEFAULT_NETWORK_SUBNET,
  HOSTNAME_REGEX,
  IP_ADDRESS_REGEX,
  LINKS_REGEX,
  TRAILING_OR_LEADING_SPACE,
  SRV_SERVICE_REGEX,
  URL_PATH_REGEX,
} from '../utils/constants';
import {
  getFirstIpInSubnet,
  getLastIpInSubnet,
  getNetworkType,
  getSecondIpInSubnet,
  getSecondLastIpInSubnet,
  getSubnet,
  isIpWithinSubnet,
} from '../pages/EditTopology/configuration/components/VirtualMachineEntities/Configure/Utils';
import {
  calculateCurrentNicCountForNetworkInForm,
  checkForDuplicateIpsHelper,
  checkForDuplicateMacAddresssHelper,
} from '../utils/validationHelpers';
import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
  UNAVAILABLE_STATUS,
} from '../constants';
import { NAT_RULE_TYPES } from '../constants/nat-rules';
import { IconFormKeyValuePairTypes } from '../pages/Views/Diagram/components/DiagramModals/IconModals/components/IconForm/constants';

export const SCENARIO_INTERNAL_NAME_REGEX = /^[a-zA-Z0-9\-_]*$/;
export const MAC_ADDRESS_REGEX = /^$|^([\da-fA-F]{2}[:]){5}([\da-fA-F]{2})$/;

export enum MaxLengths {
  TopologyName = 255,
  NatName = 50,
  NetworkName = 255,
  NetworkDescription = 500,
  DNSTextValueMaxLength = 255,
  CloneName = 255,
  DiagramIconName = 255,
  DiagramIconKey = 255,
  DiagramIconValue = 255,
  ScenarioOption = 50,
  ScenariosQuestion = 150,
  SessionDescription = 500,
  SessionName = 255,
  TemplateDescription = 512,
  TemplateName = 255,
  TemplateUserName = 255,
  TemplatePassword = 255,
  VirtualMachineName = 255,
  VirtualMachineDescription = 512,
  VirtualMachineCommandToExecute = 255,
  VirtualMachineRemoteAccessUsername = 255,
  VirtualMachineRemoteAccessPassword = 255,
  VirtualMachineNameInHypervisor = 255,
  VirtualMachineInternalUrlDescription = 255,
  InboundProxyRuleHyperLinkText = 15,
  InboundProxyRuleURLPath = 255,
  IntegrationDescription = 255,
  IntegrationDetails = 4000,
  IntegrationInputValue = 255,
  IntegrationStatusMessage = 255,
  IntegrationName = 255,
  IntegrationTag = 32,
  Hostname = 63,
  Service = 19,
  SaasIntegrationDisplayName = 255,
  SaasWorkflowParamValue = 255,
}

export enum MinLengths {
  Adapter = 1,
  Required = 1,
}

export enum MaxValues {
  VirtualMachineGADelaySecs = 7200,
  VirtualMachineShutdownDelaySecs = 600,
  VirtualMachineCpuQty = 96,
  VirtualMachineCpuQtyRecommended = 32,
  VirtualMachineMemoryMb = 524288,
  VirtualMachineMemoryGb = 512,
  VirtualMachineMemoryMbRecommended = 98304,
  VirtualMachineMemoryGbRecommended = 96,
  InboundProxyRuleTCPPort = 65535,
  SrvPort = 65535,
  MaxAmountPerTopology = 999,
}

export enum MinValues {
  VirtualMachineGADelaySecs = 0,
  VirtualMachineShutdownDelaySecs = 0,
  VirtualMachineCpuQty = 1,
  VirtualMachineMemoryMb = 4,
  VirtualMachineMemoryGb = 1,
  InboundProxyRuleTCPPort = 0,
  MaxAmountPerTopology = 1,
}

export const MAC_ADDRESS_PREFIX = '00:50:56';

interface AddVmFieldSchemaTestContextFrom {
  value: {
    interfaces: NetworkToConfigure[];
  };
}

interface AddVmFieldSchemaTestContextValue {
  from: AddVmFieldSchemaTestContextFrom[];
}

const addVmFieldSchema = (
  networks: Network[],
  vms: VirtualMachine[],
): yup.SchemaOf<AddVmConfigureFormValuesInterfaces> =>
  yup.object().shape({
    assignDhcp: yup.boolean(),
    ipAddress: yup
      .string()
      .matches(
        new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
        'Please enter a valid IP',
      )
      .test(
        'first-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        function (value) {
          if (!value) {
            return true;
          }
          const networkValue = this?.parent.network;
          const subnet = getSubnet(networkValue, networks);
          const networkType = getNetworkType(networkValue, networks);

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }

          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;
          const firstIp = getFirstIpInSubnet(subnet);
          const secondIp = getSecondIpInSubnet(subnet);

          let isValidAddress =
            parts.every(isValidPart) && value !== firstIp && value !== secondIp;

          if (networkType === 'UNROUTED') {
            isValidAddress = parts.every(isValidPart) && value !== firstIp;
          }
          return isValidAddress;
        },
      )
      .test(
        'last-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        function (value) {
          if (!value) {
            return true;
          }
          const networkValue = this?.parent.network;
          const subnet = getSubnet(networkValue, networks);

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }

          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;

          return (
            parts.every(isValidPart) &&
            value !== getLastIpInSubnet(subnet) &&
            value !== getSecondLastIpInSubnet(subnet)
          );
        },
      )
      .test(
        'duplicateIpTest',
        i18n.t('virtualMachines.add.duplicateIp'),
        function (value, context) {
          if (!value) {
            return true;
          }

          const { from } = context as yup.TestContext &
            AddVmFieldSchemaTestContextValue;
          const interfaces: NetworkToConfigure[] = from[1].value.interfaces;

          if (!interfaces) {
            return false;
          } else {
            return !checkForDuplicateIpsHelper(
              interfaces,
              value,
              context.parent.network,
              vms,
            );
          }
        },
      )
      .when('assignDhcp', {
        is: true,
        otherwise: yup.string(),
        then: yup
          .string()
          .required(i18n.t('virtualMachines.edit.formErrors.dhcpRequired'))
          .test(
            'ipInSubnetRangeTest',
            i18n.t('virtualMachines.add.ipRange'),
            function (value) {
              if (!value) {
                return true;
              }

              const networkValue = this?.parent.network;
              const subnet = getSubnet(networkValue, networks);

              if (subnet === '') {
                return true;
              }

              return isIpWithinSubnet(value, subnet);
            },
          ),
      }),
    macAddress: yup
      .string()
      .matches(
        /^$|^([\da-fA-F]{2}[:]){5}([\da-fA-F]{2})$/,
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.patternMatch',
        ),
      )
      .test(
        'prefixPatternMatch',
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.prefixPatternMatch',
        ),
        (value) => {
          if (value && value.length > MAC_ADDRESS_PREFIX.length) {
            return (
              value.substring(0, MAC_ADDRESS_PREFIX.length) ===
              MAC_ADDRESS_PREFIX
            );
          } else {
            return true;
          }
        },
      )
      .test(
        'duplicateMacAddressTest',
        i18n.t('virtualMachines.add.duplicateMac'),
        function (value, context) {
          if (!value) {
            return true;
          }

          const { from } = context as yup.TestContext &
            AddVmFieldSchemaTestContextValue;
          const interfaces: NetworkToConfigure[] = from[1].value.interfaces;

          if (!interfaces) {
            return false;
          } else {
            return !checkForDuplicateMacAddresssHelper(
              interfaces,
              value,
              context.parent.network,
              vms,
            );
          }
        },
      ),
    network: yup
      .string()
      .required('Please select a network')
      .test(
        'maxLimitReached',
        i18n.t('virtualMachines.configure.select.limitErrorPt1'),
        function (value, context) {
          if (!value) {
            return false;
          }
          const network = networks.filter(
            (network) => network.uid === value,
          )[0];
          const maxConnections = network.vmConnectionInfo?.maxConnections;
          const currentConnections =
            network.vmConnectionInfo.currentConnections.length;
          if (currentConnections >= maxConnections) {
            return false;
          } else {
            const { from } = context as yup.TestContext &
              AddVmFieldSchemaTestContextValue;
            const interfaces: NetworkToConfigure[] = from[1].value.interfaces;
            const currentNicCountForNetworkInForm =
              calculateCurrentNicCountForNetworkInForm(interfaces, value);
            const totalNicCountForNetwork =
              currentConnections + currentNicCountForNetworkInForm;
            return totalNicCountForNetwork <= maxConnections;
          }
        },
      ),
  });

export const addVmFieldArraySchema = (
  networks: Network[],
  vms: VirtualMachine[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any =>
  yup.object().shape({
    interfaces: yup.array().of(addVmFieldSchema(networks, vms)),
  });

export const generalFieldsSchema: yup.SchemaOf<
  Omit<TopologyGeneralFields, 'notes'>
> = yup.object().shape({
  description: yup
    .string()
    .required(i18n.t('general.form.description.validation.required')),
  name: yup
    .string()
    .required(i18n.t('general.form.name.validation.required'))
    .max(
      MaxLengths.TopologyName,
      i18n.t('general.form.name.validation.maxLength'),
    ),
});

export const saasIntegrationFieldsSchema = (
  mappings: SaasTemplateInput[] | undefined,
  takenNames: string[],
): yup.SchemaOf<AddSaasIntegrationFormFields> => {
  const workflowParamsSchema: {
    [key: string]: yup.StringSchema<string | undefined, AnyObject>;
  } = {};
  mappings &&
    mappings?.forEach((mapping) => {
      workflowParamsSchema[mapping.mappingName] = yup
        .string()
        .required(i18n.t('saas.add.formErrors.params.required'))
        .max(
          MaxLengths.SaasWorkflowParamValue,
          i18n.t('saas.add.formErrors.params.maxLength'),
        );
    });

  const otherAddFields = {
    TBV3_userSaasIntegrationIconDefaultValue: yup.string().required(),
    TBV3_userSaasIntegrationNameDefaultValue: yup
      .string()
      .max(
        MaxLengths.SaasIntegrationDisplayName,
        i18n.t('saas.configure.formErrors.nameLimit'),
      )
      .required(i18n.t('saas.configure.formErrors.required'))
      .matches(
        /^\w[-\w.\s]*$/,
        i18n.t('saas.configure.formErrors.allowedChars'),
      )
      .notOneOf(takenNames, i18n.t('saas.add.formErrors.nameUnique')),
    hiddenFromSessionView: yup.boolean().required(),
  };

  return yup.object().shape({ ...otherAddFields, ...workflowParamsSchema });
};

export const primaryVlanFieldsSchema: yup.SchemaOf<
  Pick<Network, 'name' | 'description'>
> = yup.object().shape({
  description: yup
    .string()
    .required(i18n.t('networks.form.description.validation.required')),
  name: yup
    .string()
    .required(i18n.t('networks.form.name.validation.required'))
    .max(
      MaxLengths.NetworkName,
      i18n.t('networks.form.name.validation.maxLength'),
    ),
});

export const cloneTopologyFieldsSchema: yup.SchemaOf<CloneFields> = yup
  .object()
  .shape({
    description: yup
      .string()
      .required(
        i18n.t('topologies.clone.form.description.validation.required'),
      ),
    name: yup
      .string()
      .required(i18n.t('topologies.clone.form.name.validation.required'))
      .max(
        MaxLengths.TopologyName,
        i18n.t('topologies.clone.form.name.validation.maxLength'),
      ),
  });

export const documentationFieldsSchema: yup.SchemaOf<DocumentationFields> = yup
  .object()
  .shape({
    documentationUrl: yup
      .string()
      .url(i18n.t('documentation.error.documentationUrl')),
  });

export const networkFieldsSchema: yup.SchemaOf<NetworkFormFields> = yup
  .object()
  .shape({
    description: yup
      .string()
      .required(i18n.t('networks.form.description.validation.required'))
      .max(
        MaxLengths.NetworkDescription,
        i18n.t('networks.form.description.validation.maxLength'),
      ),
    hiddenFromSession: yup.boolean(),
    name: yup
      .string()
      .required(i18n.t('networks.form.name.validation.required'))
      .max(
        MaxLengths.NetworkName,
        i18n.t('networks.form.name.validation.maxLength'),
      ),
    subnet: yup
      .string()
      .required(i18n.t('networks.form.subnet.validation.required'))
      .min(1),
  });

export const scenarioFieldsSchema: yup.SchemaOf<ScenarioFields> = yup
  .object()
  .shape({
    displayName: yup
      .string()
      .max(
        MaxLengths.ScenarioOption,
        i18n.t('scenarios.formErrors.optionLimit'),
      ),
    enabled: yup.boolean().required(),
    internalName: yup
      .string()
      .max(
        MaxLengths.ScenarioOption,
        i18n.t('scenarios.formErrors.optionLimit'),
      )
      .matches(
        SCENARIO_INTERNAL_NAME_REGEX,
        i18n.t('scenarios.formErrors.optionAllowedChars'),
      ),
    question: yup
      .string()
      .required(i18n.t('scenarios.formErrors.questionRequired'))
      .max(
        MaxLengths.ScenariosQuestion,
        i18n.t('scenarios.formErrors.questionLimit'),
      ),
  });

export const vmEditFieldsSchema = (
  takenNames: TakenVmNames,
  networkSubnets: NetworkSubnet[],
): yup.SchemaOf<Pick<VirtualMachine, 'name' | 'description'>> =>
  yup.object().shape({
    advancedSettings: yup.object().shape({
      biosUuid: yup
        .string()
        .optional()
        .matches(
          /^$|([\da-fA-F][\da-fA-F][\s]){7}([\da-fA-F][\da-fA-F])[-]([\da-fA-F][\da-fA-F][\s]){7}([\da-fA-F][\da-fA-F])$/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.biosUuid.patternMatch',
          ),
        ),
      nameInHypervisor: yup
        .string()
        .required(
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.required',
          ),
        )
        .max(
          MaxLengths.VirtualMachineNameInHypervisor,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.maxLength',
          ),
        )
        .matches(
          /^[a-zA-Z0-9_]+/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.allowedCharsStartWith',
          ),
        )
        .matches(
          /^\w[-\w.]*$/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.allowedCharsContains',
          ),
        )
        .notOneOf(
          takenNames.hypervisorNames,
          i18n.t('virtualMachines.edit.formErrors.nameUnique'),
        ),
    }),
    cpuQty: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.cpuQty'))
      .max(
        MaxValues.VirtualMachineCpuQty,
        i18n.t('virtualMachines.edit.formErrors.cpuQty'),
      )
      .min(
        MinValues.VirtualMachineCpuQty,
        i18n.t('virtualMachines.edit.formErrors.cpuQty'),
      ),
    description: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.descriptionRequired'))
      .max(
        MaxLengths.VirtualMachineDescription,
        i18n.t('virtualMachines.edit.formErrors.descriptionMaxLength'),
      ),
    dhcpConfig: yup.object().shape({
      defaultGatewayIp: yup
        .string()
        .matches(
          new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
          i18n.t(
            'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
          ),
        )
        .test(
          'valid-gw-ip-address',
          i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
          (value) => {
            if (!value) {
              return true;
            }

            let isValidAddress = true;

            for (const networkSubnet of networkSubnets) {
              const { subnet } = networkSubnet;
              const parts = value.split('.');

              if (parts.length !== 4) {
                isValidAddress = false;
                break;
              }

              const isValidPart = (part: string) =>
                /^\d+$/.test(part) &&
                parseInt(part) >= 0 &&
                parseInt(part) <= 255;

              isValidAddress =
                isValidAddress &&
                parts.every(isValidPart) &&
                value !== getFirstIpInSubnet(subnet) &&
                value !== getSecondLastIpInSubnet(subnet) &&
                value !== getLastIpInSubnet(subnet);

              if (!isValidAddress) {
                break;
              }
            }

            return isValidAddress;
          },
        ),
      primaryDnsIp: yup
        .string()
        .matches(
          new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
          i18n.t(
            'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
          ),
        ),
      secondaryDnsIp: yup
        .string()
        .matches(
          new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
          i18n.t(
            'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
          ),
        ),
    }),
    guestAutomation: yup.object().shape({
      command: yup
        .string()
        .max(
          MaxLengths.VirtualMachineCommandToExecute,
          i18n.t('virtualMachines.edit.formErrors.commandMaxLength'),
        )
        .test(
          'noTrailingOrLeadingSpaces',
          i18n.t('virtualMachines.edit.formErrors.commandValidation'),
          (value) => {
            if (value?.match(TRAILING_OR_LEADING_SPACE)) {
              return false;
            } else {
              return true;
            }
          },
        ),
      delaySecs: yup
        .number()
        .typeError(i18n.t('virtualMachines.edit.formErrors.delayTypeError'))
        .max(
          MaxValues.VirtualMachineGADelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayMax'),
        )
        .min(
          MinValues.VirtualMachineGADelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayMin'),
        )
        .test(
          'divisibleByFive',
          i18n.t('virtualMachines.edit.formErrors.delayIncrementsOf5'),
          (value) => {
            if (value === 0) return true;
            if (value && value % 5 === 0) return true;
            return false;
          },
        ),
    }),
    memoryGb: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'))
      .max(
        MaxValues.VirtualMachineMemoryGb,
        i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'),
      )
      .min(
        MinValues.VirtualMachineMemoryGb,
        i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'),
      ),
    memoryMb: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'))
      .max(
        MaxValues.VirtualMachineMemoryMb,
        i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'),
      )
      .min(
        MinValues.VirtualMachineMemoryMb,
        i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'),
      )
      .test(
        'divisibleByFour',
        i18n.t('virtualMachines.edit.formErrors.memoryMbMultipleOf4'),
        (value) => {
          if (value === 0) return false;
          if (value && value % 4 === 0) return true;
          return false;
        },
      ),
    name: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.nameRequired'))
      .max(
        MaxLengths.VirtualMachineName,
        i18n.t('virtualMachines.edit.formErrors.nameMaxLength'),
      )
      .notOneOf(
        takenNames.names,
        i18n.t('virtualMachines.edit.formErrors.nameUnique'),
      ),
    remoteAccess: yup.object().shape({
      displayCredentials: yup.object().shape({
        password: yup
          .string()
          .max(
            MaxLengths.VirtualMachineRemoteAccessPassword,
            i18n.t(
              'virtualMachines.edit.formErrors.remoteAccess.displayCredentials.passwordMaxLength',
            ),
          ),
        username: yup
          .string()
          .max(
            MaxLengths.VirtualMachineRemoteAccessUsername,
            i18n.t(
              'virtualMachines.edit.formErrors.remoteAccess.displayCredentials.usernameMaxLength',
            ),
          ),
      }),
      internalUrls: yup.array().of(RemoteAccessInternalUrlSchema),
      password: yup
        .string()
        .max(
          MaxLengths.VirtualMachineRemoteAccessPassword,
          i18n.t(
            'virtualMachines.edit.formErrors.remoteAccess.passwordMaxLength',
          ),
        ),
      rdp: yup.object().shape({
        isAutoLogin: yup.boolean(),
        vmNetworkInterface: yup.object().shape({
          isEnabled: yup.boolean(),
          uid: yup.string().when('isEnabled', {
            is: true,
            then: yup
              .string()
              .min(
                MinLengths.Adapter,
                i18n.t('virtualMachines.edit.formErrors.remoteAccess.adapter'),
              ),
          }),
        }),
      }),
      ssh: yup.object().shape({
        vmNetworkInterface: yup.object().shape({
          isEnabled: yup.boolean(),
          uid: yup.string().when('isEnabled', {
            is: true,
            then: yup
              .string()
              .min(
                MinLengths.Adapter,
                i18n.t('virtualMachines.edit.formErrors.remoteAccess.adapter'),
              ),
          }),
        }),
      }),
      username: yup
        .string()
        .max(
          MaxLengths.VirtualMachineRemoteAccessUsername,
          i18n.t(
            'virtualMachines.edit.formErrors.remoteAccess.usernameMaxLength',
          ),
        ),
    }),
    shutdownAutomation: yup.object().shape({
      command: yup
        .string()
        .max(
          MaxLengths.VirtualMachineCommandToExecute,
          i18n.t('virtualMachines.edit.formErrors.commandMaxLength'),
        )
        .test(
          'noTrailingOrLeadingSpaces',
          i18n.t('virtualMachines.edit.formErrors.commandValidation'),
          (value) => {
            if (value?.match(TRAILING_OR_LEADING_SPACE)) {
              return false;
            } else {
              return true;
            }
          },
        ),
      delaySecs: yup
        .number()
        .typeError(i18n.t('virtualMachines.edit.formErrors.delayTypeError'))
        .max(
          MaxValues.VirtualMachineShutdownDelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayShutdownMax'),
        )
        .min(
          MinValues.VirtualMachineShutdownDelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayMin'),
        )
        .test(
          'divisibleByFive',
          i18n.t('virtualMachines.edit.formErrors.delayIncrementsOf5'),
          (value) => {
            if (value === 0) return true;
            if (value && value % 5 === 0) return true;
            return false;
          },
        ),
    }),
  });

export const vmNetworkInterfaceFieldsSchema = (
  limits: Limits,
  subnet: string,
  networks: Network[],
): yup.SchemaOf<NetworkingFormData> => {
  return yup.object().shape({
    assignDhcp: yup.boolean(),
    ipAddress: yup
      .string()
      .test(
        'first-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        function (value) {
          if (!value) {
            return true;
          }

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }
          const networkValue = this?.parent.networkName;
          const networkType = getNetworkType(networkValue, networks);
          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;
          const firstIp = getFirstIpInSubnet(subnet);
          const secondIp = getSecondIpInSubnet(subnet);
          let isValidAddress =
            parts.every(isValidPart) && value !== firstIp && value !== secondIp;

          if (networkType === 'UNROUTED') {
            isValidAddress = parts.every(isValidPart) && value !== firstIp;
          }
          return isValidAddress;
        },
      )
      .test(
        'last-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        (value) => {
          if (!value) {
            return true;
          }

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }

          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;

          return (
            value !== getSecondLastIpInSubnet(subnet) &&
            parts.every(isValidPart) &&
            value !== getLastIpInSubnet(subnet)
          );
        },
      )
      .when('assignDhcp', {
        is: true,
        otherwise: yup.string(),
        then: yup
          .string()
          .required(i18n.t('virtualMachines.edit.formErrors.dhcpRequired'))
          .test(
            'valid-subnet',
            `${i18n.t('virtualMachines.edit.formErrors.dhcpSubnet')} ${subnet}`,
            function (value) {
              if (subnet === '' || !value) {
                return true;
              }
              const isWithinSubnet = isIpWithinSubnet(value, subnet);
              return isWithinSubnet;
            },
          ),
      })
      .matches(
        new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
        i18n.t(
          'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
        ),
      )
      .test(
        'ipMatchesGwCheck',
        i18n.t('virtualMachines.edit.formErrors.networking.ipAddress.gwMatch'),
        (value) =>
          !limits.validationLimits.invalidInboundProxyIpAddresses.includes(
            value as string,
          ),
      ),
    macAddress: yup
      .string()
      .matches(
        /^$|^([\da-fA-F]{2}[:]){5}([\da-fA-F]{2})$/,
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.patternMatch',
        ),
      )
      .test(
        'prefixPatternMatch',
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.prefixPatternMatch',
        ),
        (value) => {
          if (value && value.length > MAC_ADDRESS_PREFIX.length) {
            return (
              value.substring(0, MAC_ADDRESS_PREFIX.length) ===
              MAC_ADDRESS_PREFIX
            );
          } else {
            return true;
          }
        },
      )
      .test(
        'macMatchesGwCheck',
        i18n.t('virtualMachines.edit.formErrors.networking.macAddress.gwMatch'),
        (value) =>
          !limits.validationLimits.invalidInboundProxyMacAddresses.includes(
            value as string,
          ),
      ),
    networkName: yup
      .string()
      .required(
        i18n.t('virtualMachines.edit.formErrors.networking.networkName'),
      ),
    type: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.networking.type')),
  });
};

export const templateVmNetworkInterfaceFieldsSchema = (
  limits: Limits,
  subnet: string,
): yup.SchemaOf<TemplateNetworkingFormData> => {
  return yup.object().shape({
    assignDhcp: yup.boolean(),
    ipAddress: yup
      .string()
      .test(
        'first-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        (value) => {
          if (!value) {
            return true;
          }

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }

          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;
          const firstIp = getFirstIpInSubnet(subnet);
          const secondIp = getSecondIpInSubnet(subnet);

          const isValidAddress =
            parts.every(isValidPart) && value !== firstIp && value !== secondIp;
          return isValidAddress;
        },
      )
      .test(
        'last-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        (value) => {
          if (!value) {
            return true;
          }

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }

          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;

          const lastIp = getLastIpInSubnet(subnet);

          const isValidAddress = parts.every(isValidPart) && value !== lastIp;
          return isValidAddress;
        },
      )
      .when('assignDhcp', {
        is: true,
        otherwise: yup.string(),
        then: yup
          .string()
          .required(i18n.t('virtualMachines.edit.formErrors.dhcpRequired'))
          .test(
            'valid-subnet',
            `${i18n.t('virtualMachines.edit.formErrors.dhcpSubnet')} ${subnet}`,
            function (value) {
              if (subnet === '' || !value) {
                return true;
              }
              const isWithinSubnet = isIpWithinSubnet(value, subnet);
              return isWithinSubnet;
            },
          ),
      })
      .matches(
        new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
        i18n.t(
          'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
        ),
      )
      .test(
        'ipMatchesGwCheck',
        i18n.t('virtualMachines.edit.formErrors.networking.ipAddress.gwMatch'),
        (value) =>
          !limits.validationLimits.invalidInboundProxyIpAddresses.includes(
            value as string,
          ),
      ),
    macAddress: yup
      .string()
      .matches(
        /^$|^([\da-fA-F]{2}[:]){5}([\da-fA-F]{2})$/,
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.patternMatch',
        ),
      )
      .test(
        'prefixPatternMatch',
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.prefixPatternMatch',
        ),
        (value) => {
          if (value && value.length > MAC_ADDRESS_PREFIX.length) {
            return (
              value.substring(0, MAC_ADDRESS_PREFIX.length) ===
              MAC_ADDRESS_PREFIX
            );
          } else {
            return true;
          }
        },
      )
      .test(
        'macMatchesGwCheck',
        i18n.t('virtualMachines.edit.formErrors.networking.macAddress.gwMatch'),
        (value) =>
          !limits.validationLimits.invalidInboundProxyMacAddresses.includes(
            value as string,
          ),
      ),
    networkName: yup.string(),
    type: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.networking.type')),
  });
};

export const editvmNetworkInterfaceFieldsSchema = (
  limits: Limits,
  subnet: string,
  networks: Network[],
): yup.SchemaOf<NetworkingFormData> => {
  return yup.object().shape({
    assignDhcp: yup.boolean(),
    ipAddress: yup
      .string()
      .when('assignDhcp', {
        is: true,
        otherwise: yup.string(),
        then: yup
          .string()
          .required(i18n.t('virtualMachines.edit.formErrors.dhcpRequired'))
          .test(
            'valid-subnet',
            `${i18n.t('virtualMachines.edit.formErrors.dhcpSubnet')} ${subnet}`,
            function (value) {
              if (subnet === '' || !value) {
                return true;
              }
              const isWithinSubnet = isIpWithinSubnet(value, subnet);
              return isWithinSubnet;
            },
          ),
      })
      .matches(
        new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
        i18n.t(
          'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
        ),
      )
      .test(
        'ipMatchesGwCheck',
        i18n.t('virtualMachines.edit.formErrors.networking.ipAddress.gwMatch'),
        (value) =>
          !limits.validationLimits.invalidInboundProxyIpAddresses.includes(
            value as string,
          ),
      )
      .test(
        'first-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        function (value) {
          if (!value) {
            return true;
          }

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }

          const networkValue = this?.parent.networkName;
          const networkType = getNetworkType(networkValue, networks);

          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;
          const firstIp = getFirstIpInSubnet(subnet);
          const secondIp = getSecondIpInSubnet(subnet);

          let isValidAddress =
            parts.every(isValidPart) && value !== firstIp && value !== secondIp;

          if (networkType === 'UNROUTED') {
            isValidAddress = parts.every(isValidPart) && value !== firstIp;
          }

          return isValidAddress;
        },
      )
      .test(
        'last-ip-address',
        i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
        function (value) {
          if (!value) {
            return true;
          }

          const parts = value.split('.');
          if (parts.length !== 4) {
            return false;
          }

          const isValidPart = (part: string) =>
            /^\d+$/.test(part) && parseInt(part) >= 0 && parseInt(part) <= 255;

          return (
            parts.every(isValidPart) &&
            value !== getLastIpInSubnet(subnet) &&
            value !== getSecondLastIpInSubnet(subnet)
          );
        },
      ),
    macAddress: yup
      .string()
      .matches(
        /^$|^([\da-fA-F]{2}[:]){5}([\da-fA-F]{2})$/,
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.patternMatch',
        ),
      )
      .test(
        'prefixPatternMatch',
        i18n.t(
          'virtualMachines.edit.formErrors.networking.macAddress.prefixPatternMatch',
        ),
        (value) => {
          if (value && value.length > MAC_ADDRESS_PREFIX.length) {
            return (
              value.substring(0, MAC_ADDRESS_PREFIX.length) ===
              MAC_ADDRESS_PREFIX
            );
          } else {
            return true;
          }
        },
      )
      .test(
        'macMatchesGwCheck',
        i18n.t('virtualMachines.edit.formErrors.networking.macAddress.gwMatch'),
        (value) =>
          !limits.validationLimits.invalidInboundProxyMacAddresses.includes(
            value as string,
          ),
      ),
    networkName: yup
      .string()
      .required(
        i18n.t('virtualMachines.edit.formErrors.networking.networkName'),
      ),
    type: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.networking.type')),
  });
};

const RemoteAccessInternalUrlSchema = yup.object().shape({
  description: yup
    .string()
    .max(
      MaxLengths.VirtualMachineInternalUrlDescription,
      i18n.t(
        'virtualMachines.edit.formErrors.internalUrls.description.maxLength',
      ),
    )
    .when(['location'], {
      is: (location: string) => {
        if (!location) {
          return;
        }
        return location.length > 0;
      },
      then: yup
        .string()
        .min(
          MinLengths.Adapter,
          i18n.t(
            'virtualMachines.edit.formErrors.internalUrls.description.emptyUrlWarning',
          ),
        ),
    }),
  location: yup
    .string()
    .url(i18n.t('virtualMachines.edit.formErrors.internalUrls.location.url'))
    .test(
      'descriptionEmptyCheck',
      i18n.t(
        'virtualMachines.edit.formErrors.internalUrls.location.emptyDescriptionWarning',
      ),
      function (value) {
        return !(value?.length === 0 && this?.parent.description.length > 0);
      },
    ),
});

export const natRulesSchema = (
  internalIps: string[],
): yup.SchemaOf<Omit<NatRuleFormData, 'eastWest'>> => {
  return yup.object().shape({
    ipAddress: yup.string().when('type', {
      is: (value: NatRuleType) =>
        [NAT_RULE_TYPES.publicIp, NAT_RULE_TYPES.internalIp].includes(value),
      then: yup
        .string()
        .matches(
          IP_ADDRESS_REGEX,
          i18n.t('traffic.natRules.formErrors.ipAddressInvalid'),
        )
        .test(
          'unique',
          i18n.t('traffic.natRules.formErrors.unique'),
          (value) => !internalIps.includes(value as string),
        )
        .required(i18n.t('traffic.natRules.formErrors.ipAddressInvalid')),
      // eslint-disable-next-line sort-keys
      otherwise: yup
        .string()
        .min(
          MinLengths.Required,
          i18n.t('traffic.natRules.formErrors.ipAddressNotSelected'),
        ),
    }),
    name: yup.string().when('type', {
      is: NAT_RULE_TYPES.publicIp || NAT_RULE_TYPES.internalIp,
      then: yup
        .string()
        .required(i18n.t('traffic.natRules.formErrors.targetRequired'))
        .max(
          MaxLengths.TopologyName,
          i18n.t('traffic.natRules.formErrors.targetMax'),
        ),
      // eslint-disable-next-line sort-keys
      otherwise: yup
        .string()
        .required(i18n.t('traffic.natRules.formErrors.targetNotSelected')),
    }),
    targetItem: yup.object().shape({
      uid: yup
        .string()
        .min(
          MinLengths.Required,
          i18n.t('traffic.natRules.formErrors.ipAddressNotSelected'),
        ),
    }),
    type: yup
      .mixed<NatRuleFormData['type'] | ''>()
      .oneOf(
        Object.values(NAT_RULE_TYPES),
        i18n.t('traffic.natRules.formErrors.typeRequired'),
      ),
  });
};
export const inboundProxyRulesSchema: yup.SchemaOf<
  Omit<InboundProxyRuleFormData, 'ssl' | 'showHyperlink'>
> = yup.object().shape({
  hyperLinkText: yup
    .string()
    .required(
      i18n.t('traffic.inboundProxyRules.formErrors.hyperLinkTextRequired'),
    )
    .max(
      MaxLengths.InboundProxyRuleHyperLinkText,
      i18n.t('traffic.inboundProxyRules.formErrors.hyperLinkTextMax'),
    ),
  ipAddress: yup
    .string()
    .required(i18n.t('traffic.inboundProxyRules.formErrors.ipAddressRequired')),
  target: yup
    .string()
    .required(i18n.t('traffic.inboundProxyRules.formErrors.targetRequired'))
    .test(
      'targetRequiredTest',
      i18n.t('traffic.inboundProxyRules.formErrors.targetRequired'),
      (value) => {
        if (!value) return false;
        return value.length >= 1;
      },
    ),
  tcpPort: yup
    .number()
    .typeError(i18n.t('traffic.inboundProxyRules.formErrors.tcpPortRequired'))
    .min(
      MinValues.InboundProxyRuleTCPPort,
      i18n.t('traffic.inboundProxyRules.formErrors.tcpPortMin'),
    )
    .max(
      MaxValues.InboundProxyRuleTCPPort,
      i18n.t('traffic.inboundProxyRules.formErrors.tcpPortMax'),
    )
    .required(i18n.t('traffic.inboundProxyRules.formErrors.tcpPortRequired')),
  urlPath: yup
    .string()
    .matches(
      URL_PATH_REGEX,
      i18n.t('traffic.inboundProxyRules.formErrors.urlPathMatch'),
    )
    .required(i18n.t('traffic.inboundProxyRules.formErrors.urlPathRequired'))
    .max(
      MaxLengths.InboundProxyRuleURLPath,
      i18n.t('traffic.inboundProxyRules.formErrors.urlPathMax'),
    ),
});

export const mailServerSchema: yup.SchemaOf<MailServerFormData> = yup
  .object()
  .shape({
    asset: yup.string().optional(),
    ipAddress: yup
      .string()
      .required(i18n.t('traffic.mailServer.formErrors.ipAddressNotSelected')),
    virtualMachine: yup
      .string()
      .required(i18n.t('traffic.mailServer.formErrors.vmNotSelected'))
      .test(
        'targetRequiredTest',
        i18n.t('traffic.mailServer.formErrors.vmNotSelected'),
        (value) => {
          if (!value) return false;
          return value.length >= 1;
        },
      ),
  });

export const externalDnsScheme: yup.SchemaOf<ExternalDnsFormData> = yup
  .object()
  .shape({
    asset: yup.string().optional(),
    hostname: yup
      .string()
      .matches(
        HOSTNAME_REGEX,
        i18n.t('traffic.externalDns.formErrors.hostnameMatch'),
      )
      .required(i18n.t('traffic.externalDns.formErrors.hostnameRequired'))
      .max(
        MaxLengths.Hostname,
        i18n.t('traffic.externalDns.formErrors.hostnameMaxLength'),
      ),
    natRule: yup
      .string()
      .required(i18n.t('traffic.externalDns.formErrors.natRuleNotSelected'))
      .test(
        'natRequiredTest',
        i18n.t('traffic.externalDns.formErrors.natRuleNotSelected'),
        (value) => {
          if (!value) return false;
          return value.length >= 1;
        },
      ),
  });

export const srvScheme: yup.SchemaOf<SrvRecord> = yup.object().shape({
  port: yup
    .number()
    .required()
    .typeError(i18n.t('traffic.externalDns.edit.formErrors.portFormat'))
    .min(
      MinValues.InboundProxyRuleTCPPort,
      i18n.t('traffic.externalDns.edit.formErrors.portRange'),
    )
    .max(
      MaxValues.SrvPort,
      i18n.t('traffic.externalDns.edit.formErrors.portRange'),
    ),
  protocol: yup
    .string()
    .required(i18n.t('traffic.externalDns.edit.formErrors.protocolRequired'))
    .test(
      'protocolRequiredTest',
      i18n.t('traffic.externalDns.edit.srv.formErrors.protocolRequired'),
      (value) => {
        if (!value) return false;
        return value.length >= 1;
      },
    ),
  service: yup
    .string()
    .required(i18n.t('traffic.externalDns.edit.formErrors.serviceRequired'))
    .matches(
      SRV_SERVICE_REGEX,
      i18n.t('traffic.externalDns.edit.formErrors.serviceMatch'),
    )
    .max(
      MaxLengths.Service,
      i18n.t('traffic.externalDns.edit.formErrors.serviceMaxLength'),
    ),
});

export const saveAndEndSchema: yup.SchemaOf<
  Omit<SessionSaveAndEndFields, 'retainDocumentation'>
> = yup.object().shape({
  description: yup
    .string()
    .required(
      i18n.t('sessions.forms.saveAndEnd.description.validation.required'),
    )
    .max(
      MaxLengths.SessionDescription,
      i18n.t('sessions.forms.saveAndEnd.description.validation.maxDescription'),
    ),
  name: yup
    .string()
    .max(
      MaxLengths.SessionName,
      i18n.t('sessions.forms.saveAndEnd.description.validation.maxName'),
    )
    .required(i18n.t('sessions.forms.saveAndEnd.name.validation.required')),
});

export const addDnsTxtRecord: yup.SchemaOf<DnsTxtRecordFields> = yup
  .object()
  .shape({
    domainName: yup
      .string()
      .required(
        i18n.t('sessions.panels.info.dns.addForm.errors.domainRequired'),
      ),
    value: yup
      .string()
      .max(
        MaxLengths.DNSTextValueMaxLength,
        i18n.t('sessions.panels.info.dns.addForm.errors.dnsRecordMaxLength'),
      )
      .required(
        i18n.t('sessions.panels.info.dns.addForm.errors.textValueRequired'),
      ),
  });

export const extendSessionSchema: yup.SchemaOf<SessionExtendFields> = yup
  .object()
  .shape({
    newEndDate: yup
      .string()
      .required(i18n.t('sessions.forms.extend.newEndDate.validation.required')),
    newEndTime: yup
      .string()
      .required(i18n.t('sessions.forms.extend.newEndTime.validation.required')),
  });

export const renameSessionSchema: yup.SchemaOf<SessionRenameFields> = yup
  .object()
  .shape({
    newName: yup
      .string()
      .max(
        MaxLengths.SessionName,
        i18n.t('sessions.forms.rename.newName.validation.maxLength'),
      )
      .required(i18n.t('sessions.forms.rename.newName.validation.required')),
  });

export const shareSessionSchema = (
  sharedUsers: ShareUser[],
): yup.SchemaOf<SessionShareFields> => {
  return yup.object().shape({
    email: yup
      .string()
      .email(i18n.t('sessions.forms.share.email.validation.valid'))
      .required(i18n.t('sessions.forms.share.email.validation.required'))
      .test(
        'unique',
        i18n.t('sessions.forms.share.email.validation.unique'),
        (value) =>
          !sharedUsers.some((user) => user.email === (value as string)),
      )
      .matches(
        CISCO_DOMAIN_REGEX,
        i18n.t('sessions.forms.share.email.validation.cisco'),
      ),
  });
};

export const accessRoleAssignmentSchema: yup.SchemaOf<AccessRoleAssignmentFormData> =
  yup.object().shape({
    email: yup
      .string()
      .email(i18n.t('accessRoleAssignment.forms.email.validation.valid'))
      .required(i18n.t('accessRoleAssignment.forms.email.validation.required')),
  });

export const vmTemplateEditSchema: yup.SchemaOf<EditVmTemplateFormData> = yup
  .object()
  .shape({
    architecture: yup.array(),
    contact: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.contactLength'),
      ),
    licensed: yup.string(),
    managementIp: yup
      .string()
      .optional()
      .matches(
        new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
        i18n.t('templateManager.vmTemplates.form.validations.ipAddress'),
      ),
    password: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.passwordLength'),
      ),
    templateDescription: yup
      .string()
      .max(
        MaxLengths.TemplateDescription,
        i18n.t(
          'templateManager.vmTemplates.form.validations.descriptionMaxLength',
        ),
      )
      .required(
        i18n.t('templateManager.vmTemplates.form.validations.description'),
      ),
    templateName: yup
      .string()
      .required(
        i18n.t('templateManager.vmTemplates.form.validations.nameRequired'),
      )
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.nameLength'),
      ),
    templateVersion: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t(
          'templateManager.vmTemplates.form.validations.templateVersionLength',
        ),
      ),
    userEnabled: yup.boolean(),
    userName: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.userNameLength'),
      ),
  });

export const demoTemplateEditSchema: yup.SchemaOf<EditDemoTemplateFormData> =
  yup.object().shape({
    architecture: yup.array(),
    contact: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.demoTemplates.form.validations.contactLength'),
      ),
    description: yup
      .string()
      .max(
        MaxLengths.TemplateDescription,
        i18n.t(
          'templateManager.demoTemplates.form.validations.descriptionMaxLength',
        ),
      )
      .required(
        i18n.t('templateManager.demoTemplates.form.validations.description'),
      ),
    name: yup
      .string()
      .required(
        i18n.t('templateManager.demoTemplates.form.validations.nameRequired'),
      )
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.demoTemplates.form.validations.nameLength'),
      ),
    userEnabled: yup.boolean(),
  });

export const templatedVmEditFieldsSchema = (
  takenNames: TakenVmNames,
): yup.SchemaOf<Pick<VirtualMachine, 'name' | 'description'>> =>
  yup.object().shape({
    advancedSettings: yup.object().shape({
      biosUuid: yup
        .string()
        .optional()
        .matches(
          /^$|([\da-fA-F][\da-fA-F][\s]){7}([\da-fA-F][\da-fA-F])[-]([\da-fA-F][\da-fA-F][\s]){7}([\da-fA-F][\da-fA-F])$/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.biosUuid.patternMatch',
          ),
        ),
      nameInHypervisor: yup
        .string()
        .required(
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.required',
          ),
        )
        .max(
          MaxLengths.VirtualMachineNameInHypervisor,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.maxLength',
          ),
        )
        .matches(
          /^[a-zA-Z0-9_]+/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.allowedCharsStartWith',
          ),
        )
        .matches(
          /^\w[-\w.]*$/,
          i18n.t(
            'virtualMachines.edit.formErrors.advancedSettings.nameInHypervisor.allowedCharsContains',
          ),
        )
        .notOneOf(
          takenNames.hypervisorNames,
          i18n.t('virtualMachines.edit.formErrors.nameUnique'),
        ),
    }),
    architecture: yup.array(),
    contact: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.contactLength'),
      ),
    cpuQty: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.cpuQty'))
      .max(
        MaxValues.VirtualMachineCpuQty,
        i18n.t('virtualMachines.edit.formErrors.cpuQty'),
      )
      .min(
        MinValues.VirtualMachineCpuQty,
        i18n.t('virtualMachines.edit.formErrors.cpuQty'),
      ),
    description: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.descriptionRequired'))
      .max(
        MaxLengths.VirtualMachineDescription,
        i18n.t('virtualMachines.edit.formErrors.descriptionMaxLength'),
      ),
    dhcpConfig: yup.object().shape({
      defaultGatewayIp: yup
        .string()
        .matches(
          new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
          i18n.t(
            'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
          ),
        )
        .test(
          'valid-gw-ip-address',
          i18n.t('virtualMachines.edit.formErrors.dhcpReserved'),
          (value) => {
            if (!value) {
              return true;
            }

            let isValidAddress = true;

            const subnet = DEFAULT_NETWORK_SUBNET;
            const parts = value.split('.');
            if (parts.length !== 4) {
              isValidAddress = false;
              return isValidAddress;
            }

            const isValidPart = (part: string) =>
              /^\d+$/.test(part) &&
              parseInt(part) >= 0 &&
              parseInt(part) <= 255;

            return (
              isValidAddress &&
              parts.every(isValidPart) &&
              value !== getFirstIpInSubnet(subnet) &&
              value !== getSecondLastIpInSubnet(subnet) &&
              value !== getLastIpInSubnet(subnet)
            );
          },
        ),
      primaryDnsIp: yup
        .string()
        .matches(
          new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
          i18n.t(
            'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
          ),
        ),
      secondaryDnsIp: yup
        .string()
        .matches(
          new RegExp(`^$|${IP_ADDRESS_REGEX.source}`),
          i18n.t(
            'virtualMachines.edit.formErrors.networking.ipAddress.patternMatch',
          ),
        ),
    }),
    guestAutomation: yup.object().shape({
      command: yup
        .string()
        .max(
          MaxLengths.VirtualMachineCommandToExecute,
          i18n.t('virtualMachines.edit.formErrors.commandMaxLength'),
        ),
      delaySecs: yup
        .number()
        .typeError(i18n.t('virtualMachines.edit.formErrors.delayTypeError'))
        .max(
          MaxValues.VirtualMachineGADelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayMax'),
        )
        .min(
          MinValues.VirtualMachineGADelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayMin'),
        )
        .test(
          'divisibleByFive',
          i18n.t('virtualMachines.edit.formErrors.delayIncrementsOf5'),
          (value) => {
            if (value === 0) return true;
            if (value && value % 5 === 0) return true;
            return false;
          },
        ),
    }),
    licensed: yup.string(),
    memoryGb: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'))
      .max(
        MaxValues.VirtualMachineMemoryGb,
        i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'),
      )
      .min(
        MinValues.VirtualMachineMemoryGb,
        i18n.t('virtualMachines.edit.formErrors.memoryGbLimits'),
      ),
    memoryMb: yup
      .number()
      .typeError(i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'))
      .max(
        MaxValues.VirtualMachineMemoryMb,
        i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'),
      )
      .min(
        MinValues.VirtualMachineMemoryMb,
        i18n.t('virtualMachines.edit.formErrors.memoryMbLimits'),
      )
      .test(
        'divisibleByFour',
        i18n.t('virtualMachines.edit.formErrors.memoryMbMultipleOf4'),
        (value) => {
          if (value === 0) return false;
          if (value && value % 4 === 0) return true;
          return false;
        },
      ),
    name: yup
      .string()
      .required(i18n.t('virtualMachines.edit.formErrors.nameRequired'))
      .max(
        MaxLengths.VirtualMachineName,
        i18n.t('virtualMachines.edit.formErrors.nameMaxLength'),
      )
      .notOneOf(
        takenNames.names,
        i18n.t('virtualMachines.edit.formErrors.nameUnique'),
      ),
    password: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.passwordLength'),
      ),
    remoteAccess: yup.object().shape({
      displayCredentials: yup.object().shape({
        password: yup
          .string()
          .max(
            MaxLengths.VirtualMachineRemoteAccessPassword,
            i18n.t(
              'virtualMachines.edit.formErrors.remoteAccess.displayCredentials.passwordMaxLength',
            ),
          ),
        username: yup
          .string()
          .max(
            MaxLengths.VirtualMachineRemoteAccessUsername,
            i18n.t(
              'virtualMachines.edit.formErrors.remoteAccess.displayCredentials.usernameMaxLength',
            ),
          ),
      }),
      internalUrls: yup.array().of(RemoteAccessInternalUrlSchema),
      password: yup
        .string()
        .max(
          MaxLengths.VirtualMachineRemoteAccessPassword,
          i18n.t(
            'virtualMachines.edit.formErrors.remoteAccess.passwordMaxLength',
          ),
        ),
      rdp: yup.object().shape({
        isAutoLogin: yup.boolean(),
        vmNetworkInterface: yup.object().shape({
          isEnabled: yup.boolean(),
          uid: yup.string().when('isEnabled', {
            is: true,
            then: yup
              .string()
              .min(
                MinLengths.Adapter,
                i18n.t('virtualMachines.edit.formErrors.remoteAccess.adapter'),
              ),
          }),
        }),
      }),
      ssh: yup.object().shape({
        vmNetworkInterface: yup.object().shape({
          isEnabled: yup.boolean(),
          uid: yup.string().when('isEnabled', {
            is: true,
            then: yup
              .string()
              .min(
                MinLengths.Adapter,
                i18n.t('virtualMachines.edit.formErrors.remoteAccess.adapter'),
              ),
          }),
        }),
      }),
      username: yup
        .string()
        .max(
          MaxLengths.VirtualMachineRemoteAccessUsername,
          i18n.t(
            'virtualMachines.edit.formErrors.remoteAccess.usernameMaxLength',
          ),
        ),
    }),
    shutdownAutomation: yup.object().shape({
      command: yup
        .string()
        .max(
          MaxLengths.VirtualMachineCommandToExecute,
          i18n.t('virtualMachines.edit.formErrors.commandMaxLength'),
        ),
      delaySecs: yup
        .number()
        .typeError(i18n.t('virtualMachines.edit.formErrors.delayTypeError'))
        .max(
          MaxValues.VirtualMachineShutdownDelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayShutdownMax'),
        )
        .min(
          MinValues.VirtualMachineShutdownDelaySecs,
          i18n.t('virtualMachines.edit.formErrors.delayShutdownMin'),
        )
        .test(
          'divisibleByFive',
          i18n.t('virtualMachines.edit.formErrors.delayIncrementsOf5'),
          (value) => {
            if (value === 0) return true;
            if (value && value % 5 === 0) return true;
            return false;
          },
        ),
    }),
    templateDescription: yup
      .string()
      .max(
        MaxLengths.TemplateDescription,
        i18n.t(
          'templateManager.vmTemplates.form.validations.descriptionMaxLength',
        ),
      )
      .required(
        i18n.t('templateManager.vmTemplates.form.validations.description'),
      ),
    templateName: yup
      .string()
      .required(
        i18n.t('templateManager.vmTemplates.form.validations.nameRequired'),
      )
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.nameLength'),
      ),
    templateVersion: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t(
          'templateManager.vmTemplates.form.validations.templateVersionLength',
        ),
      ),
    userEnabled: yup.boolean(),
    userName: yup
      .string()
      .optional()
      .max(
        MaxLengths.TemplatePassword,
        i18n.t('templateManager.vmTemplates.form.validations.userNameLength'),
      ),
  });

export const createIntegrationModalFieldsSchema = (
  takenNames: string[],
): yup.SchemaOf<AddIntegrationModalFormFields> =>
  yup.object().shape({
    inventoryWorkflowId: yup
      .string()
      .required(
        i18n.t('integrations.forms.common.recipe.validations.required'),
      ),
    name: yup
      .string()
      .required(i18n.t('integrations.forms.common.name.validations.required'))
      .max(
        MaxLengths.IntegrationName,
        i18n.t('integrations.forms.common.name.validations.maxLength'),
      )
      .notOneOf(
        takenNames,
        i18n.t('integrations.forms.common.name.validations.uniqueNameError'),
      ),
  });

export const integrationFormFieldsSchema = (
  takenNames: string[],
): yup.SchemaOf<
  Omit<IntegrationTemplateDetailsFormFields, 'inventoryWorkflowName' | 'icon'>
> =>
  yup.object().shape({
    availabilityStatus: yup
      .string()
      .required(
        i18n.t(
          'integrations.forms.common.availabilityStatus.validations.required',
        ),
      ),
    availabilityStatusMessage: yup.string().when('availabilityStatus', {
      is: UNAVAILABLE_STATUS,
      otherwise: yup.string(),
      then: yup
        .string()
        .required(
          i18n.t(
            'integrations.forms.common.availabilityStatusMessage.validations.required',
          ),
        )
        .max(
          MaxLengths.IntegrationStatusMessage,
          i18n.t(
            'integrations.forms.common.availabilityStatusMessage.validations.maxLength',
          ),
        ),
    }),
    description: yup
      .string()
      .required(
        i18n.t('integrations.forms.common.description.validations.required'),
      )
      .max(
        MaxLengths.IntegrationDescription,
        i18n.t('integrations.forms.common.description.validations.maxLength'),
      ),
    details: yup
      .string()
      .required(
        i18n.t('integrations.forms.common.details.validations.required'),
      )
      .max(
        MaxLengths.IntegrationDetails,
        i18n.t('integrations.forms.common.details.validations.maxLength'),
      ),
    maxAmountPerTopology: yup
      .number()
      .required(
        i18n.t(
          'integrations.forms.common.maxAmountPerTopology.validations.required',
        ),
      )
      .typeError(
        i18n.t(
          'integrations.forms.common.maxAmountPerTopology.validations.required',
        ),
      )
      .min(
        MinValues.MaxAmountPerTopology,
        i18n.t(
          'integrations.forms.common.maxAmountPerTopology.validations.required',
        ),
      )
      .max(
        MaxValues.MaxAmountPerTopology,
        i18n.t(
          'integrations.forms.common.maxAmountPerTopology.validations.required',
        ),
      ),
    name: yup
      .string()
      .required(i18n.t('integrations.forms.common.name.validations.required'))
      .max(
        MaxLengths.IntegrationName,
        i18n.t('integrations.forms.common.name.validations.maxLength'),
      )
      .notOneOf(
        takenNames,
        i18n.t('integrations.forms.common.name.validations.uniqueNameError'),
      ),
    sessionView: yup.mixed().oneOf(['ALLOW', 'FORCE', 'HIDE']).required(),
    type: yup
      .mixed()
      .oneOf(
        [PUBLIC_CLOUD_INTEGRATION_TYPE, SAAS_INTEGRATION_TYPE],
        i18n.t('integrations.forms.common.integrationType.errors.required'),
      ),
  });

export const integrationInputParameterFieldsSchema: () => yup.SchemaOf<IntegrationInputParameterFields> =
  () =>
    yup.object().shape({
      default: yup
        .string()
        .test(
          'isRequired',
          i18n.t('integrations.forms.common.inputs.errors.defaultRequired'),
          (value, context) => context.parent.userConfigurable || value,
        )
        .max(
          MaxLengths.IntegrationInputValue,
          i18n.t('integrations.forms.common.inputs.errors.defaultMaxLength'),
        ),
      defaultToWorkflowEngine: yup.boolean(),
      description: yup
        .string()
        .max(
          MaxLengths.IntegrationInputValue,
          i18n.t(
            'integrations.forms.common.inputs.errors.descriptionMaxLength',
          ),
        )
        .required(
          i18n.t('integrations.forms.common.inputs.errors.descriptionRequired'),
        ),
      name: yup
        .string()
        .max(
          MaxLengths.IntegrationInputValue,
          i18n.t('integrations.forms.common.inputs.errors.nameMaxLength'),
        )
        .required(
          i18n.t('integrations.forms.common.inputs.errors.nameRequired'),
        ),
      userConfigurable: yup.boolean().required(),
    });

export const diagramTextFieldsSchema: yup.SchemaOf<
  Pick<DiagramTextDisplay, 'text'>
> = yup.object().shape({
  text: yup
    .string()
    .required(
      i18n.t('diagram.sideMenu.addText.forms.edit.inputs.text.errors.required'),
    ),
});

const diagramAddIconDatumSchema = (): yup.SchemaOf<
  Omit<DiagramAddIconFormFieldsDatum, 'name'>
> =>
  yup.object().shape({
    id: yup.string(),
    key: yup.string().when('type', {
      is: IconFormKeyValuePairTypes.link,
      otherwise: yup
        .string()
        .required(i18n.t('diagram.modals.addIcon.form.errors.key.required'))
        .max(
          MaxLengths.DiagramIconKey,
          i18n.t('diagram.modals.addIcon.form.errors.key.maxLength'),
        ),
      then: yup
        .string()
        .required(
          i18n.t('diagram.modals.addIcon.form.errors.displayText.required'),
        )
        .max(
          MaxLengths.DiagramIconKey,
          i18n.t('diagram.modals.addIcon.form.errors.displayText.maxLength'),
        ),
    }),
    type: yup.mixed<IconFormKeyValuePairType>(),
    value: yup
      .string()
      .max(
        MaxLengths.DiagramIconValue,
        i18n.t('diagram.modals.addIcon.form.errors.value.maxLength'),
      )
      .when('type', {
        is: IconFormKeyValuePairTypes.link,
        then: yup
          .string()
          .matches(
            LINKS_REGEX,
            i18n.t('diagram.modals.addIcon.form.errors.value.invalidLink'),
          ),
      }),
  });

export const diagramAddIconFieldsSchema: yup.SchemaOf<DiagramAddIconFormFields> =
  yup.object().shape({
    data: yup.array().of(diagramAddIconDatumSchema()),
    name: yup
      .string()
      .max(
        MaxLengths.DiagramIconName,
        i18n.t('diagram.modals.addIcon.form.errors.name.maxLength'),
      )
      .required(i18n.t('diagram.modals.addIcon.form.errors.name.required')),
  });
