import { CSSProperties } from 'react';
import { DefaultTextNodeDisplay } from '../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddText/constants';
import { DIAGRAM_DRAWING_COLORS } from '../../../../../components/DrawingPalettes/constants';
import { TEXT_COLOR_CLASS_NAMES } from './constants';

type ClassNamesForTextStyling = { [key: string]: boolean };

export const generateClassNamesForTextStyling = (
  selectedColor?: string,
): ClassNamesForTextStyling => {
  const colorIndex = DIAGRAM_DRAWING_COLORS.findIndex(
    (drawingColor) => drawingColor === selectedColor,
  );

  return colorIndex === -1
    ? {}
    : { [TEXT_COLOR_CLASS_NAMES[colorIndex]]: true };
};

export const buildFontSizeStyle = (selectedOption?: number): CSSProperties =>
  selectedOption !== undefined &&
  DefaultTextNodeDisplay.fontSizes[selectedOption]
    ? {
        fontSize: `${DefaultTextNodeDisplay.fontSizes[selectedOption]}${DefaultTextNodeDisplay.fontSizeUnit}`,
      }
    : {};
