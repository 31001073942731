import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setExpired,
  setShowEndSessionModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { usePostSessionActionMutation } from '../../../../../../redux/views/session-view/session/api/api';
import { useSessionUserContext } from '../../../../../../hooks/use-session-user-context';
import { EndSessionModal } from '.';

export function EndSessionModalContainer({
  setExpired,
  setShowEndSessionModal,
  showEndSessionModal,
}: EndSessionModalProps): ReactElement {
  const { sessionUid, versionUid } = useSessionUserContext();
  const [postSessionAction, { isSuccess, isLoading }] =
    usePostSessionActionMutation();

  const endSession = () => {
    postSessionAction({
      action: 'END',
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });
  };

  return (
    <EndSessionModal
      endSession={endSession}
      isLoading={isLoading}
      isSuccess={isSuccess}
      setExpired={setExpired}
      setShowEndSessionModal={setShowEndSessionModal}
      showEndSessionModal={showEndSessionModal}
    />
  );
}
const mapStateToProps = (state: RootState) => ({
  showEndSessionModal: sessionViewSelectors.getShowEndSessionModal(state),
});

const mapDispatchToProps = {
  setExpired,
  setShowEndSessionModal,
};

export type EndSessionModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedEndSession = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EndSessionModalContainer);
