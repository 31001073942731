import { Button, Icon } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import styles from './NetworksTableActions.module.css';

interface NetworksTableActionsProps {
  canDelete: boolean;
  onDeleteClick: VoidFunction;
  onEditClick: VoidFunction;
}

export function NetworksTableActions({
  canDelete,
  onDeleteClick,
  onEditClick,
}: NetworksTableActionsProps): ReactElement {
  return (
    <ul aria-label="Card actions" className={styles.root}>
      {canDelete && (
        <li className={styles.action}>
          <Button
            colour="link"
            onClick={onDeleteClick}
            data-test="networks-table-actions-delete"
          >
            <Icon name="delete" />
          </Button>
        </li>
      )}
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={onEditClick}
          data-test="networks-table-actions-edit"
        >
          <Icon name="pencil" />
        </Button>
      </li>
    </ul>
  );
}
