import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import {
  getMoreInfoForInventoryTemplate,
  setShowMoreInfoForInventoryTemplate,
} from '../../../../../../redux/configuration/templates/slice';
import { InventoryTemplatesInfoModal } from '.';

export type InventoryTemplatesTableInfoModalProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  inventoryTemplate:
    getMoreInfoForInventoryTemplate(state).showMoreInfoForInventoryTemplate,
});

const mapDispatchToProps = {
  setShowMoreInfoForInventoryTemplate,
};

export const InventoryTemplatesTableInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryTemplatesInfoModal);
