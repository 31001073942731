import React, { ReactElement, useState } from 'react';
import { Alert, Button } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PanelTableLoader } from '../../../../PanelTableLoader';
import { PanelGrid } from '../../../PanelGrid';
import { DNSProps } from './container';
import { AddTxtRecord } from './AddTxtRecord';
import { createDomainOptions } from './utils';

export function DNS({
  dns,
  dnsAssets,
  isLoading,
  isUpdating = false,
  isError,
  createDnsTxtRecord,
}: DNSProps): ReactElement {
  const { t } = useTranslation();
  const [showAddForm, setShowAddForm] = useState(false);
  const buildPanelGridData = (dnsData: DnsItem[]): PanelGridDataItem[] => {
    return dnsData.map(function ({ name, type }) {
      return {
        key: `${name}-${type}`,
        name: type,
        value: name,
      };
    });
  };
  if (isLoading || isError) {
    return <PanelTableLoader />;
  }
  if (!dns || !dns.length) {
    return <Alert>{t('sessions.panels.info.dns.noDnsConfigured')}</Alert>;
  }

  return (
    <>
      <PanelGrid
        data={buildPanelGridData(dns)}
        wideCol="value"
        nameBold={true}
        nameHeader={t('sessions.panels.info.dns.type')}
        valueHeader={t('sessions.panels.info.dns.dnsName')}
        wideColRatio={5}
      />
      {createDnsTxtRecord && dnsAssets?.length ? (
        showAddForm ? (
          <AddTxtRecord
            domainOptions={createDomainOptions(dnsAssets)}
            createDnsTxtRecord={createDnsTxtRecord}
            isLoading={isUpdating}
            setShowAddForm={setShowAddForm}
          ></AddTxtRecord>
        ) : (
          <Button
            colour="ghost"
            size="large"
            onClick={() => setShowAddForm(true)}
          >
            {t('sessions.panels.info.dns.addForm.labels.addButton')}
          </Button>
        )
      ) : null}
    </>
  );
}
