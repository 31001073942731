import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useDeleteTopologyMutation } from '../../../../redux/topologies/api';
import {
  clearTopologyToDelete,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { ConfirmDeleteTopologyModal, ConfirmDeleteTopologyModalProps } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

type ConfirmDeleteTopologyModalContainerProps = Pick<
  ConfirmDeleteTopologyModalProps,
  'clearTopologyToDelete' | 'topologyToDelete' | 'shouldConfirmDelete'
>;

const mapStateToProps = (state: RootState) => ({
  shouldConfirmDelete: topologiesSelectors.getShouldConfirmDelete(state),
  topologyToDelete: topologiesSelectors.getTopologyToDelete(state),
});

const mapDispatchToProps = {
  clearTopologyToDelete,
};

function ConfirmDeleteTopologyModalContainer({
  clearTopologyToDelete,
  shouldConfirmDelete,
  topologyToDelete,
}: ConfirmDeleteTopologyModalContainerProps): ReactElement {
  const [deleteTopology, { isLoading: isDeleting }] =
    useDeleteTopologyMutation();

  return (
    <ConfirmDeleteTopologyModal
      clearTopologyToDelete={clearTopologyToDelete}
      deleteTopology={() => deleteTopology(topologyToDelete!.uid)}
      isDeleting={isDeleting}
      shouldConfirmDelete={shouldConfirmDelete}
      topologyToDelete={topologyToDelete}
    />
  );
}

export const ConnectedConfirmDeleteTopologyModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDeleteTopologyModalContainer);
