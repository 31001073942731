import React, { ReactElement } from 'react';
import { PhysicalConnection } from './components/PhysicalConnection';
import { LogicalConnectionGroup } from './components/LogicalConnectionGroup';
import { LogicalConnectionSelector } from './components/LogicalConnectionSelector';
import { LogicalConnection } from './components/LogicalConnection';
import { PhysicalConnectionGroup } from './components/PhysicalConnectionGroup';
import { PhysicalConnectionSelector } from './components/PhysicalConnectionSelector';

export interface ConnectionLineProps {
  connection: DiagramConnection;
  isSessionView?: boolean;
}

export const ConnectionLine = ({
  connection,
  isSessionView = true,
}: ConnectionLineProps): ReactElement => {
  let opacity = '100%';
  if (connection.display?.hidden) {
    opacity = '40%';
  }
  if (connection.display?.invisible) {
    opacity = '0%';
  }

  if (connection.type !== 'LOGICAL') {
    return (
      <PhysicalConnectionGroup>
        <PhysicalConnection
          connection={connection}
          opacity={opacity}
          isHiddenFromSessionView={connection.display?.hidden || false}
        />
        <PhysicalConnectionSelector
          connection={connection}
          isSessionView={isSessionView}
          isHiddenFromSessionView={connection.display?.hidden || false}
        />
      </PhysicalConnectionGroup>
    );
  } else {
    return (
      <LogicalConnectionGroup>
        <LogicalConnection connection={connection} />
        <LogicalConnectionSelector
          connection={connection}
          isSessionView={isSessionView}
        />
      </LogicalConnectionGroup>
    );
  }
};
