import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { useDiagramContext } from '..';
import styles from './ToggleGridButton.module.css';

export enum TranslateKeys {
  Root = 'diagram.',
  HideGrid = 'hideGrid',
  ShowGrid = 'showGrid',
}

export function returnToolTipText(showGrid: boolean): string {
  return `${TranslateKeys.Root}${
    showGrid ? TranslateKeys.HideGrid : TranslateKeys.ShowGrid
  }`;
}

export function ToggleGridButton(): ReactElement {
  const { showGrid, toggleGrid } = useDiagramContext();
  const { t } = useTranslation();
  const classes = classnames(styles.root, { [styles.active]: showGrid });

  return (
    <button
      onClick={toggleGrid}
      data-balloon={t(returnToolTipText(showGrid))}
      data-balloon-pos="right"
      className={classes}
    >
      <Icon name="dial" />
    </button>
  );
}
