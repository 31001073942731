import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionProps,
} from '../../../../../../components/Accordion';
import { ConnectedHwControl } from './components/HWControls/container';
import { ConnectedHwInfoContainer } from './components/HWInfoPanel/container';

export function HardwarePanel(): ReactElement {
  const { t } = useTranslation();

  const accordionRows = useMemo<AccordionProps['rows']>(
    () => [
      {
        children: <ConnectedHwInfoContainer />,
        title: t('sessions.panels.hardware.info.sectionHeader'),
      },

      {
        children: <ConnectedHwControl />,
        title: t('sessions.panels.hardware.controls.sectionHeader'),
      },
    ],
    [t],
  );

  return <Accordion rows={accordionRows} size="small" />;
}
