import React, { ReactElement } from 'react';
import { expoSessionApi } from '../../../../../../../redux/views/session-view/session/api/expoApi';
import { getMorIdFromSessionDetails } from '../../../../utils';
import { PanelHeaderButtons } from '../index';
import { VmLeftPanelProps } from './container';

type ConnectedExpoVmLeftPanelHeaderButtonsProps = VmLeftPanelProps &
  FetchExpoSessionArgs;

export function ConnectedExpoVmLeftPanelHeaderButtons({
  deviceUid,
  engagementUid,
  sessionUid,
  setLeftPanelType,
  versionUid,
}: ConnectedExpoVmLeftPanelHeaderButtonsProps): ReactElement {
  const { mor } = expoSessionApi.endpoints.getExpoSession.useQueryState(
    { engagementUid, sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        mor: getMorIdFromSessionDetails(
          (
            data?.diagram.nodes.filter(
              (node) => node.uid === deviceUid,
            )[0] as SessionVmNode
          ).sessionDetails,
        ),
      }),
    },
  );

  const { refetch } =
    expoSessionApi.endpoints.getExpoSessionVmStatus.useQuerySubscription({
      engagementUid,
      mor,
      sessionUid,
      versionUid,
    });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
