import { connect } from 'react-redux';
import { setShowConfirmForActionForVm } from '../../../../../../../redux/views/session-view/session-inventory/slice';
import { RootState } from '../../../../../../../redux/store';
import { VirtualMachinesTableActionsCellRenderer } from '.';

const mapDispatchToProps = {
  setShowConfirmForActionForVm,
};

type OwnProps = {
  mor: string;
  name: string;
  nodeType: SessionInventoryConfirmActionPayloadNodeType;
};

const mapStateToProps = (_: RootState, ownProps: OwnProps) => ({
  mor: ownProps.mor,
  name: ownProps.name,
  nodeType: ownProps.nodeType,
});

export type VirtualMachinesTableActionsCellRendererProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const VirtualMachinesTableActionsCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachinesTableActionsCellRenderer);
