import React, {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';

type CellEditorProps = ICellEditorParams & {
  autoFocus: boolean;
  onChange: (value: string) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength: number;
  regex?: RegExp;
};

const CellEditor = forwardRef(
  (
    {
      onChange: onCustomChange,
      onKeyUp: onCustomKeyUp,
      regex,
      autoFocus,
      maxLength,
      value: originalValue,
    }: CellEditorProps,
    ref,
  ) => {
    const [value, setValue] = useState(originalValue);
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (autoFocus) {
        setTimeout(() => inputRef?.current?.focus(), 1);
      }
      if (onCustomChange) {
        onCustomChange(originalValue);
      }
    }, [autoFocus, onCustomChange, originalValue]);
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
      };
    });
    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (regex && !regex.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    };
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      if (onCustomChange) {
        onCustomChange(event.target.value);
      }
    };
    const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
      if (regex && !event.clipboardData.getData('text').match(regex)) {
        event.preventDefault();
      }
    };
    const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onCustomKeyUp) {
        onCustomKeyUp(event);
      }
    };
    return (
      <div className="ag-cell-edit-wrapper">
        <div className="ag-cell-editor">
          <input
            ref={inputRef}
            maxLength={maxLength}
            tabIndex={1}
            className="ag-text-field-input"
            onChange={onChange}
            defaultValue={value}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onPaste={onPaste}
          />
        </div>
      </div>
    );
  },
);

CellEditor.displayName = 'CellEditor';

export default CellEditor;
