import React, { ReactElement } from 'react';
import { HelpButton } from '../../components/HelpButton';
import { MY_TOPOLOGIES_HELP_LINKS } from '../../constants/user-guide';
import { TopologiesLayout } from '../../layouts/TopologiesLayout';
import { ConnectedCloneTopologyModal } from './components/CloneTopologyModal/container';
import { ConnectedDeleteTopologyModal } from './components/DeleteTopologyModal/container';
import { ConnectedTopologiesContent } from './components/TopologiesContent/container';
import { ConnectedConfirmDeleteTopologyModal } from './components/ConfirmDeleteTopologyModal/container';

interface TopologiesProps {
  isLoading: boolean;
}

export function Topologies({ isLoading }: TopologiesProps): ReactElement {
  return (
    <TopologiesLayout showLoadingSpinner={isLoading}>
      <ConnectedTopologiesContent />
      <ConnectedCloneTopologyModal />
      <ConnectedDeleteTopologyModal />
      <ConnectedConfirmDeleteTopologyModal />
      <HelpButton links={MY_TOPOLOGIES_HELP_LINKS} />
    </TopologiesLayout>
  );
}
