import { useTheme } from 'dcloud-shared-ui';

interface UseSkeletonColours {
  backgroundColor: string;
  foregroundColor: string;
}

export function useSkeletonColours(): UseSkeletonColours {
  const [theme] = useTheme();
  const isDarkMode = theme === 'dark';

  const backgroundColor = isDarkMode
    ? 'var(--tb-blue)'
    : 'var(--cui-color-gray-200)';

  const foregroundColor = isDarkMode ? '#1f2d3f' : 'var(--cui-color-gray-300)';

  return {
    backgroundColor,
    foregroundColor,
  };
}
