import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { setInventorySaasTemplateToDelete } from '../../../../../../redux/configuration/saas/slice';
import { IntegrationsTable } from '.';

const mapDispatchToProps = {
  setInventorySaasTemplateToDelete,
};

type OwnProps = {
  integrations: SaasIntegration[];
  integrationType: IntegrationType;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  integrationType: ownProps.integrationType,
  integrations: ownProps.integrations,
});

export type IntegrationsTableProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(IntegrationsTable);
