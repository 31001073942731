import i18n from '../../../../i18n';
import blankIcon from '../../../../assets/images/icons/blank.svg';
import importIcon from '../../../../assets/images/icons/import.svg';
import templateIcon from '../../../../assets/images/icons/template.svg';
import { CreateTopologyRoutes } from '../../constants';
import { MainRoutes } from '../../../../constants/routes';

export interface DemoTypeItem {
  icon: string;
  title: string;
  subTitle: string;
  type: string;
  path: string;
  requiredRoles?: UserRoles[];
}

export const DEMO_TYPES: DemoTypeItem[] = [
  {
    icon: blankIcon,
    path: `${MainRoutes.CreateTopology}${CreateTopologyRoutes.Details}`,
    subTitle: i18n.t('createTopology.demo.blank.subTitle'),
    title: i18n.t('createTopology.demo.blank.title'),
    type: 'BLANK',
  },
  {
    icon: importIcon,
    path: `${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoImport}`,
    requiredRoles: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
    subTitle: i18n.t('createTopology.demo.import.subTitle'),
    title: i18n.t('createTopology.demo.import.title'),
    type: 'IMPORT',
  },
  {
    icon: templateIcon,
    path: `${MainRoutes.CreateTopology}${CreateTopologyRoutes.DemoTemplate}`,
    subTitle: i18n.t('createTopology.demo.template.subTitle'),
    title: i18n.t('createTopology.demo.template.title'),
    type: 'TEMPLATE',
  },
];
