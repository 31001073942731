import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../redux/views/session-view/session/api/api';
import { DemoHeaderTitle } from '.';

export type ConnectedDCloudDemoHeaderTitleProps = {
  sessionUid: string;
  versionUid: string;
};

export function ConnectedDCloudDemoHeaderTitle({
  sessionUid,
  versionUid,
}: ConnectedDCloudDemoHeaderTitleProps): ReactElement {
  const { isError, isFetching, isLoading, demoTitle } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isFetching, isLoading, isError }) => ({
          demoTitle: data?.sessionDetails.name,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <DemoHeaderTitle
      demoTitle={demoTitle}
      isError={isError}
      isLoading={isLoading || isFetching}
    />
  );
}
