import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from '../../../../../../../../../components/Dropdown/components/DropdownItem';

type ConsoleHrefDropdownItemProps = {
  consoleHref?: string;
};

export const ConsoleHrefDropdownItem = ({
  consoleHref,
}: ConsoleHrefDropdownItemProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <DropdownItem
      href={consoleHref}
      label={t('sessionsInventory.hardware.table.actions.hardwareConsole')}
    />
  );
};
