import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../components';
import { TableLoader } from '../../../../../../components/Table/TableLoader';
import { ExternalDnsTableActions } from './TableActions';

type ExternalDnsTableProps = {
  isError: boolean;
  isLoading: boolean;
  dnsRecords: ExternalDnsRecord[];
  onDeleteClick: (externalDnsRecord: ExternalDnsRecord) => void;
};

export function ExternalDnsTable({
  isError,
  isLoading,
  dnsRecords,
  onDeleteClick,
}: ExternalDnsTableProps): ReactElement | null {
  const { t } = useTranslation();
  const columns = useMemo<Column<ExternalDnsRecord>[]>(
    () => [
      {
        Header: () => t('traffic.externalDns.table.headings.nat'),
        accessor: ({ natRule }) => natRule.target.name,
        id: 'natRule',
        width: 80,
      },
      {
        Header: () => t('traffic.externalDns.table.headings.asset'),
        accessor: ({ inventoryDnsAsset }) =>
          inventoryDnsAsset ? inventoryDnsAsset.name : '',
        id: 'asset',
        width: 50,
      },
      {
        Header: () => t('traffic.externalDns.table.headings.hostname'),
        accessor: ({ hostname }) => hostname,
        id: 'hostname',
        width: 50,
      },
      {
        Header: () => t('traffic.externalDns.table.headings.aRecord'),
        accessor: ({ aRecord }) => (aRecord ? aRecord : ''),
        id: 'aRecord',
        width: 80,
      },
    ],
    [t],
  );

  const renderActions = (externalDnsRecord: ExternalDnsRecord): ReactNode => (
    <ExternalDnsTableActions
      externalDnsRecord={externalDnsRecord}
      onDeleteClick={onDeleteClick}
    />
  );

  if (isError || isLoading || !dnsRecords) {
    return <TableLoader />;
  }

  return (
    <Table<ExternalDnsRecord>
      data={dnsRecords}
      columns={columns}
      actions={renderActions}
    />
  );
}
