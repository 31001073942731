import { NodeStrokeTypes } from '../../../../../../../components/DrawingPalettes/constants';
import { DiagramDrawingColorName } from '../../../../../../../typings/drawing-palettes';

export const logicalConnectionLineDefaults = {
  dashSize: 10,
  drawingType: 'LINE_LOGICAL_CONNECTION' as DiagramDrawingType,
  strokeDarkThemeColor: 'color-gray-200' as DiagramDrawingColorName,
  strokeLightThemeColor: 'color-gray-600' as DiagramDrawingColorName,
  strokeType: NodeStrokeTypes.solid,
  strokeWidth: 2,
};

export const physicalConnectionLineDefaults = {
  dashSize: 10,
  defaultColor: 'color-sky',
  drawingType: 'LINE_PHYSICAL_CONNECTION' as DiagramDrawingType,
  strokeDarkThemeColor: 'color-gray-200' as DiagramDrawingColorName,
  strokeLightThemeColor: 'color-gray-600' as DiagramDrawingColorName,
  strokeType: NodeStrokeTypes.solid,
  strokeWidth: 2,
};
