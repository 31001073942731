import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useGetAllDemoTemplatesQuery } from '../../../../redux/templateManager/DemoTemplates/api';
import {
  setSelectedDemoTemplate,
  templateManagerSelectors,
} from '../../../../redux/templateManager/slice';
import { TableLoader } from '../../../../components/Table/TableLoader';
import { prepTemplateDemosForTable } from './utils';
import { DemoTemplates } from '.';

function DemoTemplatesContainer({
  setSelectedDemoTemplate,
  selectedDatacenter,
  selectedDemoItem,
}: ManageVmTemplatesContainerProps): ReactElement {
  const { isFetching, isLoading, isError, transformedTemplateDemos } =
    useGetAllDemoTemplatesQuery(selectedDatacenter!, {
      selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
        data,
        isError,
        isFetching,
        isLoading,
        transformedTemplateDemos: data
          ? prepTemplateDemosForTable(data)
          : undefined,
      }),
    });

  if (!transformedTemplateDemos || isLoading || isFetching || isError) {
    return <TableLoader />;
  }

  return (
    <DemoTemplates
      demoTemplates={transformedTemplateDemos}
      setSelectedDemoTemplate={setSelectedDemoTemplate}
      selectedDemoTemplate={selectedDemoItem}
      isLoading={isLoading || isFetching || isError}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  selectedDatacenter: templateManagerSelectors.getSelectedDataCenter(state),
  selectedDemoItem: templateManagerSelectors.getSelectedDemoTemplate(state),
});

const mapDispatchToProps = {
  setSelectedDemoTemplate,
};

export type ManageVmTemplatesContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export const ConnectedDemoTemplates = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DemoTemplatesContainer);
