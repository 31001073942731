import React, { ReactElement, useEffect, useState } from 'react';
import { RowNode } from 'ag-grid-community';
import { Label } from 'dcloud-shared-ui';
import styles from './TagsCellRenderer.module.css';

export const TagsCellRenderer = (props: RowNode): ReactElement => {
  const [tagsToRender, setTagsToRender] = useState<Tag[]>();

  useEffect(() => {
    const tags =
      (props.data as SaasTemplate).tags ||
      (props.data as SaasIntegration).saasTemplate.tags;
    setTagsToRender(tags);
  }, [props]);

  return (
    <>
      {tagsToRender &&
        tagsToRender.map(({ name }: Tag) => (
          <Label
            className={styles.tagLabel}
            size="small"
            key={name}
            colour="dark"
          >
            {name}
          </Label>
        ))}
    </>
  );
};
