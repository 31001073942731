import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../components';
import { ExtendSessionFormContainer } from './components/ExtendSessionForm/container';
import { ExtendSessionModalProps } from './container';

export function ExtendSessionModal({
  setShowExtendSessionModal,
  showExtendSessionModal,
}: ExtendSessionModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      show={showExtendSessionModal}
      onClose={() => setShowExtendSessionModal(false)}
      title={t('sessions.modals.extendSession.heading')}
    >
      <div>
        <ExtendSessionFormContainer />
      </div>
    </Modal>
  );
}
