import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../redux/views/session-view/session/api/api';
import { EndpointKits } from '.';

export function ConnectedDCloudEndpointKits({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const { endpoints, isLoading, isFetching, isError } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          endpoints: data?.sessionDetails.endpoints,
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <EndpointKits
      endpoints={endpoints}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
