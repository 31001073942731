import React, { ReactElement } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  useParams,
} from 'react-router-dom';
import { FullScreenSpinner } from '../../../../components/Loaders/FullScreenSpinner';
import UserRoleGuardContainer from '../../../../auth/components/UserRoleGuard/container';
import {
  PUBLIC_CLOUD_INTEGRATION_TYPE,
  SAAS_INTEGRATION_TYPE,
} from '../../../../constants';
import { ConnectedSaasEdit } from './Edit/container';
import { ConnectedAddSaasIntegrations } from './Add/container';
import { ConectedSaasConfigure } from './Configure/container';
import { Saas } from './Saas';
import { PublicCloud } from './PublicCloud';

export function SaasRouter({
  match: { path },
}: RouteComponentProps): ReactElement {
  const { integrationType: integrationTypeFromUrl } =
    useParams<UserIntegrationsParams>();
  return (
    <Switch>
      <Route
        path={`${path}/add`}
        exact={true}
        render={() => {
          return integrationTypeFromUrl === 'saas' ? (
            <ConnectedAddSaasIntegrations
              integrationType={SAAS_INTEGRATION_TYPE}
            />
          ) : (
            <UserRoleGuardContainer
              requiredRoles={[
                'ROLE_INTEGRATION_MANAGER',
                'ROLE_ADMIN',
                'ROLE_PUBLIC_CLOUD_USER',
              ]}
              loadingComponent={<FullScreenSpinner />}
              redirectToMainPageIfNotPermitted={true}
            >
              <ConnectedAddSaasIntegrations
                integrationType={PUBLIC_CLOUD_INTEGRATION_TYPE}
              />
            </UserRoleGuardContainer>
          );
        }}
      />
      <Route
        path={`${path}/add/configure`}
        exact={true}
        render={() => {
          return integrationTypeFromUrl === 'saas' ? (
            <ConectedSaasConfigure integrationType={SAAS_INTEGRATION_TYPE} />
          ) : (
            <UserRoleGuardContainer
              requiredRoles={[
                'ROLE_INTEGRATION_MANAGER',
                'ROLE_ADMIN',
                'ROLE_PUBLIC_CLOUD_USER',
              ]}
              loadingComponent={<FullScreenSpinner />}
              redirectToMainPageIfNotPermitted={true}
            >
              <ConectedSaasConfigure
                integrationType={PUBLIC_CLOUD_INTEGRATION_TYPE}
              />
            </UserRoleGuardContainer>
          );
        }}
      />
      <Route
        path={`${path}/:saasIntegrationToEditUid`}
        exact={true}
        render={() => {
          return integrationTypeFromUrl === 'saas' ? (
            <ConnectedSaasEdit integrationType={SAAS_INTEGRATION_TYPE} />
          ) : (
            <UserRoleGuardContainer
              requiredRoles={[
                'ROLE_INTEGRATION_MANAGER',
                'ROLE_ADMIN',
                'ROLE_PUBLIC_CLOUD_USER',
              ]}
              loadingComponent={<FullScreenSpinner />}
              redirectToMainPageIfNotPermitted={true}
            >
              <ConnectedSaasEdit
                integrationType={PUBLIC_CLOUD_INTEGRATION_TYPE}
              />
            </UserRoleGuardContainer>
          );
        }}
      />
      <Route
        path={path}
        render={() => {
          return integrationTypeFromUrl === 'saas' ? (
            <Saas />
          ) : (
            <UserRoleGuardContainer
              requiredRoles={[
                'ROLE_INTEGRATION_MANAGER',
                'ROLE_ADMIN',
                'ROLE_PUBLIC_CLOUD_USER',
              ]}
              loadingComponent={<FullScreenSpinner />}
              redirectToMainPageIfNotPermitted={true}
            >
              <PublicCloud />
            </UserRoleGuardContainer>
          );
        }}
        exact={true}
      />
    </Switch>
  );
}
