import React, { ReactElement } from 'react';
import { IconPanelLinksData } from './components/IconPanelLinksData';
import { IconPanelLinksEmptyAlert } from './components/IconPanelLinksEmptyAlert';

export type IconPanelLinksProps = {
  links: (DiagramAddIconFormFieldsDatum & {
    index: number;
  })[];
};

export function IconPanelLinks({ links }: IconPanelLinksProps): ReactElement {
  return (
    <>
      <IconPanelLinksData links={links} />
      <IconPanelLinksEmptyAlert links={links} />
    </>
  );
}
