import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../components/Table';
import { LicencesContentProps } from '../../../../pages/EditTopology/configuration/Licences/LicencesContent';
import { LicenceActions } from '../LicenceActions';

interface InventoryAccessor {
  inventoryLicense: InventoryLicence;
}

export interface LicencesTableProps {
  onDelete: LicenceAction;
  loading: boolean;
  licences: Licence[];
  onEdit: LicencesContentProps['setLicenceToEdit'];
}

export function LicencesTable({
  onDelete,
  loading,
  licences,
  onEdit,
}: LicencesTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<Licence>[]>(
    () => [
      {
        Header: () => t('licences.table.headings.name'),
        accessor: ({ inventoryLicense: { name } }: InventoryAccessor) => name,
        id: 'name',
        minWidth: 50,
      },
      {
        Header: () => t('licences.table.headings.quantity'),
        accessor: 'quantity',
        minWidth: 100,
      },
    ],
    [t],
  );

  function renderActions(licence: Licence): ReactElement {
    return (
      <LicenceActions
        uid={licence.uid}
        canDelete={!!licence._links.delete}
        onDelete={() => onDelete(licence)}
        onEdit={() => onEdit(licence.uid)}
      />
    );
  }

  return (
    <Table<Licence>
      loading={loading}
      data={licences}
      columns={columns}
      actions={renderActions}
    />
  );
}
