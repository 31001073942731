import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className: string;
  clearAll: (event: { preventDefault: VoidFunction }) => void;
  resetAll: (event: { preventDefault: VoidFunction }) => void;
  selectAll: (event: { preventDefault: VoidFunction }) => void;
  children: ReactElement;
}

const CheckboxWrapper = ({
  className,
  clearAll,
  resetAll,
  selectAll,
  children,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div className={className}>
        <a href="" onClick={selectAll} data-testid="select-all-button-link">
          {t('userPreferences.selectAll')}
        </a>{' '}
        |{' '}
        <a href="" onClick={resetAll} data-testid="reset-button-link">
          {t('userPreferences.reset')}
        </a>{' '}
        |{' '}
        <a href="" onClick={clearAll} data-testid="clear-all-button-link">
          {t('userPreferences.clearAll')}
        </a>
      </div>
      {children}
    </>
  );
};

export default CheckboxWrapper;
