import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface TopologyLimitsState {
  showTopologyLimitWarningForEntity?:
    | TopologyLimitsDisplayLabels
    | Array<TopologyLimitsDisplayLabels>;
  topologyLimitsWarningAccepted?: boolean;
  topologyLimitsWarningAcceptedInDiagram?: boolean;
  itemsToAdd?: Omit<InventoryHardwareItemsToAddFromDiagram, 'topologyLimit'>;
  vmToAddFromTemplateTab?: Omit<
    VirtualMachineToAddFromTemplatesTabFromDiagram,
    'topologyLimits'
  >;
  vmToAddFromDemoTab?: Omit<
    VirtualMachineToAddFromDemosTabFromDiagram,
    'topologyLimits'
  >;
}

export const initialState: TopologyLimitsState = {
  itemsToAdd: undefined,
  showTopologyLimitWarningForEntity: undefined,
  topologyLimitsWarningAccepted: undefined,
  topologyLimitsWarningAcceptedInDiagram: undefined,
};

const slice = createSlice({
  initialState,
  name: 'topologyLimits',
  reducers: {
    resetTopologyLimits: (state) => {
      state.topologyLimitsWarningAccepted =
        initialState.topologyLimitsWarningAccepted;
      state.showTopologyLimitWarningForEntity =
        initialState.showTopologyLimitWarningForEntity;
      state.topologyLimitsWarningAcceptedInDiagram =
        initialState.topologyLimitsWarningAcceptedInDiagram;
      state.itemsToAdd = initialState.itemsToAdd;
      state.vmToAddFromTemplateTab = initialState.vmToAddFromTemplateTab;
    },
    setItemsToAdd: (
      state,
      action: PayloadAction<
        | Omit<InventoryHardwareItemsToAddFromDiagram, 'topologyLimit'>
        | undefined
      >,
    ) => {
      state.itemsToAdd = action.payload;
    },
    setShowTopologyLimitWarningForEntity: (
      state,
      action: PayloadAction<
        | TopologyLimitsDisplayLabels
        | Array<TopologyLimitsDisplayLabels>
        | undefined
      >,
    ) => {
      state.showTopologyLimitWarningForEntity = action.payload;
    },
    setTopologyLimitsWarningAccepted: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.topologyLimitsWarningAccepted = action.payload;
      state.showTopologyLimitWarningForEntity = undefined;
    },
    setTopologyLimitsWarningAcceptedInDiagram: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.topologyLimitsWarningAcceptedInDiagram = action.payload;
    },
    setVmToAddFromDemoTab: (
      state,
      action: PayloadAction<VirtualMachineToAddFromDemosTabFromDiagram>,
    ) => {
      state.vmToAddFromDemoTab = action.payload;
    },
    setVmToAddFromTemplateTab: (
      state,
      action: PayloadAction<VirtualMachineToAddFromTemplatesTabFromDiagram>,
    ) => {
      state.vmToAddFromTemplateTab = action.payload;
    },
  },
});

export const { reducer: topologyLimitsReducer } = slice;
export const {
  setShowTopologyLimitWarningForEntity,
  setTopologyLimitsWarningAccepted,
  setTopologyLimitsWarningAcceptedInDiagram,
  setItemsToAdd,
  resetTopologyLimits,
  setVmToAddFromDemoTab,
  setVmToAddFromTemplateTab,
} = slice.actions;

export const getTopologyLimits = (state: RootState): TopologyLimitsState =>
  state.configuration[slice.name];

export const topologyLimitsSelectors = {
  getItemsToAdd: createSelector(
    getTopologyLimits,
    ({ itemsToAdd }) => itemsToAdd,
  ),
  getShowTopologyLimitWarningForEntity: createSelector(
    getTopologyLimits,
    ({ showTopologyLimitWarningForEntity }) =>
      showTopologyLimitWarningForEntity,
  ),
  getTopologyLimitsWarningAccepted: createSelector(
    getTopologyLimits,
    ({ topologyLimitsWarningAccepted }) => topologyLimitsWarningAccepted,
  ),
  getVmToAddFromDemoTab: createSelector(
    getTopologyLimits,
    ({ vmToAddFromDemoTab }) => vmToAddFromDemoTab,
  ),
  getVmToAddFromTemplateTab: createSelector(
    getTopologyLimits,
    ({ vmToAddFromTemplateTab }) => vmToAddFromTemplateTab,
  ),
};
