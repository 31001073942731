import axios, { AxiosResponse } from 'axios';
import { generateAuthHeaderValue } from '../../../../redux/utils';
import { DOCUMENTS_PATH } from '../../../paths';
import { API_PATH } from './apiPath';

const documentsEndpoint = `${API_PATH}${DOCUMENTS_PATH}`;

export async function getDocument(
  documentUid: number,
  versionUid: string,
): Promise<AxiosResponse<Blob>> {
  return await axios.get<Blob>(
    `${documentsEndpoint}/${documentUid}?versionUid=${versionUid}`,
    {
      headers: { Authorization: generateAuthHeaderValue() },
      responseType: 'blob',
    },
  );
}
