import { connect } from 'react-redux';
import { swapAccessCode } from '../../redux/auth/slice';
import { Authenticate } from '.';

const mapDispatchToProps = {
  swapAccessCode: swapAccessCode,
};

export type AuthenticateProps = typeof mapDispatchToProps;

const AuthenticateContainer = connect(null, mapDispatchToProps)(Authenticate);
export default AuthenticateContainer;
