import i18n from '../i18n';

export const USER_GUIDE_URL =
  'https://dcloud-docs.cisco.com/c/r/dcloud-docs/sites/en_us/Platform/topology_builder-out/b_Topology_Builder_User_Guide.html?dc=rtp';

export const USER_GUIDE_IDs = {
  CONCEPT_TOPICS: {
    ABOUT: 'ID-00000013',
    CONFIGURE: 'ID-000000ef',
    CREATE_TOPOLOGY: 'ID-00000041',
    EDIT_VM_REMOTE_ACCESS: 'ID-edit_vm_remote_acces',
    EXTERNAL_DNS: 'ID-000001df',
    GENERAL: 'ID-000000f1',
    HARDWARE: 'ID-0000015b',
    LANDING_PAGE: 'ID-00000013',
    LICENCES: 'ID-00000137',
    MAIL_SERVER: 'ID-000001f8',
    MY_TOPOLOGIES: 'ID-000000b4',
    NAT_RULES: 'ID-nat_rules',
    REMOTE_ACCESS: 'ID-00000196',
    SCENARIOS: 'ID-000001b3',
    SHARING: 'ID-sharing',
    VIRTUAL_MACHINES: 'ID-00000126',
  },
  TASK_TOPICS: {
    ADD_HARDWARE: 'ID-00000161',
    ADD_LICENSE: 'ID-0000013d',
    ADD_MAIL_SERVER: 'ID-000001fe',
    ADD_NETWORK: 'ID-00000115',
    ADD_SCENARIO: 'ID-000001b9',
    ADD_VM: 'ID-0000012c',
    CONFIGURE_EXTERNAL_DNS: 'ID-000001e5',
    CONFIGURE_NAT_TO_IP_ADDRESS: 'ID-configure_nat_to_ip',
    CONFIGURE_NAT_TO_VM: 'ID-configure_nat_to_vm',
    CREATE_COPY: 'ID-00000099',
    HW_START: 'ID-0000018a',
    VM_START: 'ID-00000172',
    VM_STOP: 'ID-0000017e',
  },
};

export const DEFAULT_HELP_LINKS: HelpButtonLink[] = [
  {
    ariaLabel: i18n.t('helpButtonLinks.demoCreationHelp'),
    href: 'https://eurl.io/#PXrpeYNX0',
    label: i18n.t('helpButtonLinks.demoCreationHelp'),
  },
  {
    ariaLabel: i18n.t('helpButtonLinks.dCloudSupport'),
    href: 'https://dcloud2-rtp.cisco.com/support/',
    label: i18n.t('helpButtonLinks.dCloudSupport'),
  },
];

export const USER_GUIDE_LINK_TEMPLATE: HelpButtonLink = {
  ariaLabel: i18n.t('helpButtonLinks.userGuide'),
  href: USER_GUIDE_URL,
  label: i18n.t('helpButtonLinks.userGuide'),
};

export const GENERAL_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.GENERAL}`,
  },
];

export const CREATE_TOPOLOGY_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.CREATE_TOPOLOGY}`,
  },
];

export const HARDWARE_ADD_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.ADD_HARDWARE}`,
  },
];

export const HARDWARE_ALL_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.HARDWARE}`,
  },
];

export const LICENCES_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.LICENCES}`,
  },
];

export const NETWORK_ADD_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.ADD_NETWORK}`,
  },
];

export const REMOTE_ACCESS_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.REMOTE_ACCESS}`,
  },
];

export const SHARE_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.SHARING}`,
  },
];

export const HW_START_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.HW_START}`,
  },
];

export const VM_START_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.VM_START}`,
  },
];

export const VM_STOP_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.VM_STOP}`,
  },
];

export const EXTERNAL_DNS_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.EXTERNAL_DNS}`,
  },
];

export const MAIL_SERVER_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.MAIL_SERVER}`,
  },
];

export const NAT_RULES_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.NAT_RULES}`,
  },
];

export const VM_ADD_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.TASK_TOPICS.ADD_VM}`,
  },
];

export const VM_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.VIRTUAL_MACHINES}`,
  },
];

export const SCENARIOS_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.SCENARIOS}`,
  },
];

export const HOME_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.LANDING_PAGE}`,
  },
];

export const MY_TOPOLOGIES_HELP_LINKS: HelpButtonLink[] = [
  ...DEFAULT_HELP_LINKS,
  {
    ...USER_GUIDE_LINK_TEMPLATE,
    href: `${USER_GUIDE_URL}#${USER_GUIDE_IDs.CONCEPT_TOPICS.MY_TOPOLOGIES}`,
  },
];
