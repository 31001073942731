import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useGetDataCentersQuery } from '../../redux/entities/data-centers/api';
import { RootState } from '../../redux/store';
import {
  setSelectedDataCenter,
  templateManagerSelectors,
} from '../../redux/templateManager/slice';
import { useGetAllArchitecturesQuery } from '../../redux/templateManager/VmTemplates/api';
import { TemplatePortal } from '.';

function TemplatePortalContainer({
  allArchitectures,
  setSelectedDataCenter,
  selectedDataCenter,
}: TemplatePortalContainerProps): ReactElement {
  const { data: dataCenters } = useGetDataCentersQuery(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('siteTitle');
  }, [t]);

  useGetAllArchitecturesQuery(undefined, {
    skip: allArchitectures ? allArchitectures.length > 0 : false,
  });

  return (
    <TemplatePortal
      setSelectedDataCenter={setSelectedDataCenter}
      selectedDataCenter={selectedDataCenter}
      datacenters={dataCenters}
    />
  );
}

interface TemplatePortalRTKProps {
  datacenters?: DataCenterFromApi[];
  selectedDataCenter: DataCenter | undefined;
}

const mapStateToProps = (state: RootState) => ({
  allArchitectures: templateManagerSelectors.getAllArchitectures(state),
  selectedDataCenter: templateManagerSelectors.getSelectedDataCenter(state),
});

const mapDispatchToProps = {
  setSelectedDataCenter,
};

export type TemplatePortalContainerProps = ReturnType<typeof mapStateToProps> &
  TemplatePortalRTKProps &
  typeof mapDispatchToProps;

export type TemplatePortalProps = Omit<
  TemplatePortalContainerProps,
  'setAllArchitectures' | 'allArchitectures'
>;

export const ConnectedTemplatePortal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplatePortalContainer);
