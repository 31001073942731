import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledTextarea } from '../../../../../components';

export interface NetworkDescriptionInputProps {
  control: UseFormMethods<NetworkFormFields>['control'];
  errorMesage?: string;
}

export function NetworkDescriptionInput({
  control,
  errorMesage,
}: NetworkDescriptionInputProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ControlledTextarea<NetworkFormFields>
      control={control}
      error={errorMesage}
      label={t('networks.form.description.label')}
      labelClass="col-3"
      name="description"
      rows={4}
      required={true}
    />
  );
}
