import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedDemoTemplate,
  templateManagerSelectors,
} from '../../../../../../redux/templateManager/slice';
import { EditDemoTemplateFormLoader } from '../FormLoader';
import { buildDemoTemplateColsData } from '../../utils';
import {
  useGetDemoTemplateQuery,
  useUpdateDemoTemplateMutation,
} from '../../../../../../redux/templateManager/DemoTemplates/api';
import { EditDemoTemplateModal } from '.';

function EditDemoTemplateContainer({
  addSelectedArchitecture,
  allArchitectures,
  removeSelectedArchitecture,
  selectedArchitectures,
  selectedDemoTemplate,
  currentDc,
  setSelectedDemoTemplate,
}: EditDemoTemplateModalRTKProps): ReactElement {
  const { data: demoTemplateToEdit, isLoading } = useGetDemoTemplateQuery(
    selectedDemoTemplate!.uid,
  );

  const [updateDemoTemplate, { isLoading: isUpdating }] =
    useUpdateDemoTemplateMutation();

  if (isLoading || !demoTemplateToEdit || !selectedDemoTemplate) {
    return (
      <EditDemoTemplateFormLoader
        onClose={() => setSelectedDemoTemplate(undefined)}
      />
    );
  }

  return (
    <EditDemoTemplateModal
      addSelectedArchitecture={addSelectedArchitecture}
      allArchitectures={allArchitectures}
      showEditModal={selectedDemoTemplate !== undefined}
      updateDemoTemplate={updateDemoTemplate}
      setSelectedDemoTemplate={setSelectedDemoTemplate}
      isUpdating={isUpdating}
      selectedArchitectures={selectedArchitectures}
      removeArchitecture={removeSelectedArchitecture}
      templateToEdit={demoTemplateToEdit}
      colsContent={buildDemoTemplateColsData(currentDc!, demoTemplateToEdit)}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  allArchitectures: templateManagerSelectors.getAllArchitectures(state),
  currentDc: templateManagerSelectors.getSelectedDataCenter(state),
  selectedArchitectures:
    templateManagerSelectors.getSelectedArchitectures(state),
  selectedDemoTemplate: templateManagerSelectors.getSelectedDemoTemplate(state),
});

const mapDispatchToProps = {
  addSelectedArchitecture,
  removeSelectedArchitecture,
  setSelectedDemoTemplate,
};

export type EditDemoTemplateModalRTKProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedEditDemoTemplateModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDemoTemplateContainer);
