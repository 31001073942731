import React, { ReactElement } from 'react';
import classNames from 'classnames';

export type SessionInventorySideTabProps = {
  label: string;
  handleOnClick: VoidFunction;
  isActive: boolean;
};

export function SessionInventorySideTab({
  label,
  handleOnClick,
  isActive,
}: SessionInventorySideTabProps): ReactElement {
  const classes = classNames('tab', { active: isActive });

  return (
    <li className={classes} key={label}>
      <a
        tabIndex={0}
        onClick={handleOnClick}
        data-testid={`session-inventory-side-tab-hardware-${label}`}
      >
        {label}
      </a>
    </li>
  );
}
