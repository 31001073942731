import React, { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InventoryLicencesContainer } from '../../../../entities/licences/components/InventoryLicencesModal/container';
import { LicenceDeleteModal } from '../../../../entities/licences/components/LicenceDeleteModal';
import { ConnectedEditLicenceModal } from '../../../../entities/licences/components/EditLicenceModal/container';
import { PageHeadingWithCTA } from '../../components/PageHeadingWithCTA/PageHeadingWithCTA';
import { TableLoader } from '../../../../components/Table/TableLoader';
import { LICENCES_HELP_LINKS } from '../../../../constants/user-guide';
import { HelpButton } from '../../../../components/HelpButton';
import { LicencesContent } from './LicencesContent';
import { LicencesProps } from './container';

export function Licences({
  licences = [],
  licenceToDelete,
  licenceToEdit,
  deleteLicence,
  isLoading,
  isDeleting,
  isErrorFetching,
  reset,
  setLicenceToEdit,
  setLicenceToDelete,
  setShowInventoryModal,
  showInventoryModal,
}: LicencesProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleDeleteLicence = useCallback(() => {
    if (licenceToDelete) {
      licenceToDelete && deleteLicence(licenceToDelete.uid);
      setLicenceToDelete(undefined);
    }
  }, [deleteLicence, licenceToDelete, setLicenceToDelete]);

  return (
    <div className="half-padding-left">
      <PageHeadingWithCTA
        disableButton={isLoading || isDeleting}
        buttonLabel={t('buttons.add')}
        buttonAriaLabel="Add Button"
        onButtonClick={() => setShowInventoryModal(true)}
        pageTitle={t('licences.name')}
      />
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <LicencesContent
            setLicenceToDelete={setLicenceToDelete}
            setLicenceToEdit={setLicenceToEdit}
            alertText={t('licences.noLicences.alertText')}
            licences={licences}
            loading={isLoading || isErrorFetching}
          />

          {showInventoryModal && (
            <InventoryLicencesContainer
              onClose={() => setShowInventoryModal(false)}
            />
          )}
          {licenceToDelete && (
            <LicenceDeleteModal
              licence={licenceToDelete}
              isDeleting={isDeleting}
              onClose={() => setLicenceToDelete(undefined)}
              onConfirm={() => handleDeleteLicence()}
            />
          )}
          {licenceToEdit && <ConnectedEditLicenceModal />}
          <HelpButton links={LICENCES_HELP_LINKS} />
        </>
      )}
    </div>
  );
}
