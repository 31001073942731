import React, { ReactElement } from 'react';
import styles from './CarouselWrapper.module.css';
import { HeroIndicatorButtons } from './components/HeroIndicatorButtons';

type CarouselWrapperProps = {
  items: ReactElement[];
  updateIndex: (newIndex: number) => void;
  activeIndex: number;
  isAnimating: boolean;
};

export const CarouselWrapper = ({
  items,
  updateIndex,
  activeIndex,
  isAnimating,
}: CarouselWrapperProps): ReactElement => {
  return (
    <div data-testid="carousel-container" className={styles.carousel}>
      <div
        className={styles.inner}
        style={
          !isAnimating ? {} : { transform: `translate(-${activeIndex * 100}%)` }
        }
      >
        {items.map((item: ReactElement) => {
          return item;
        })}
      </div>
      <HeroIndicatorButtons
        items={items}
        activeIndex={activeIndex}
        updateIndex={updateIndex}
      />
    </div>
  );
};
