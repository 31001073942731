import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';

export type UseResetIntegrationsOnUnmountArgs = {
  reset: ActionCreatorWithoutPayload;
};

export function useResetIntegrationsOnUnmount({
  reset,
}: UseResetIntegrationsOnUnmountArgs): void {
  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);
}
