import { isEqual } from 'lodash';
import {
  DATA_CENTER_FILTERS,
  DEFAULT_CHECKED_SHOW_FILTERS,
} from '../redux/topologiesFilters/constants';
import { TopologyViewTypes } from '../entities/topologies/constants';
import { Storage, StorageKeys } from './storage';

export enum UserPrefKey {
  dataCenterFilters = 'dataCenterFilters',
  filters = 'filters',
  theme = 'theme',
  topologiesViewType = 'topologiesViewType',
  autoAlign = 'autoAlign',
}

interface UserPref {
  [UserPrefKey.dataCenterFilters]: string[];
  [UserPrefKey.filters]: string[];
  [UserPrefKey.theme]: 'dark' | 'light';
  [UserPrefKey.topologiesViewType]: TopologyViewTypes;
  [UserPrefKey.autoAlign]: 'true' | 'false' | 'undefined';
}

const INITIAL_USER_PREFS: UserPref = {
  autoAlign: 'false',
  dataCenterFilters: DATA_CENTER_FILTERS,
  filters: DEFAULT_CHECKED_SHOW_FILTERS,
  theme: 'dark',
  topologiesViewType: TopologyViewTypes.List,
};

export const getUserPrefs = (): UserPref => {
  let existingUserPrefsString = Storage.getItem(StorageKeys.userPrefs);
  if (!existingUserPrefsString) {
    Storage.setItem(StorageKeys.userPrefs, JSON.stringify(INITIAL_USER_PREFS));
    existingUserPrefsString = Storage.getItem(StorageKeys.userPrefs);
  }
  return JSON.parse(existingUserPrefsString!);
};

export const setUserPrefs = (
  key: UserPrefKey,
  value: string | string[],
): void => {
  const existingUserPrefs = getUserPrefs();
  Storage.setItem(
    StorageKeys.userPrefs,
    JSON.stringify({
      ...existingUserPrefs,
      [key]: value,
    }),
  );
};

export const clearUserPrefs = (): void => {
  if (Storage.getItem(StorageKeys.userPrefs)) {
    Storage.removeItem(StorageKeys.userPrefs);
  }
};

export const hasUserPreferenceChanged = (): boolean => {
  return !isEqual(getUserPrefs(), INITIAL_USER_PREFS);
};
