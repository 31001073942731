import { DOCUMENTATION_PATH } from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { TAGS } from '../../api/constants';

export const documentationApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getDocumentation: build.query<Documentation, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DOCUMENTATION],
      query: (topologyUid) => ({
        errorMessage: i18n.t('documentation.fetch.error'),
        method: 'get',
        url: `${DOCUMENTATION_PATH}/${topologyUid}`,
      }),
    }),
    updateDocumentation: build.mutation<
      Documentation,
      DocumentationRequestData
    >({
      invalidatesTags: [TAGS.DOCUMENTATION],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('documentation.update.error'),
        method: 'PUT',
        successMessage: i18n.t('documentation.update.success'),
        url: `${DOCUMENTATION_PATH}/${payload.uid}`,
      }),
    }),
  }),
});

export const { useGetDocumentationQuery, useUpdateDocumentationMutation } =
  documentationApi;
