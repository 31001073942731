import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudPhoneNumbers } from './dCloudContainer';
import { ConnectedExpoPhoneNumbers } from './expoContainer';

export function PhoneNumbersContainer(): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoPhoneNumbers
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudPhoneNumbers
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type PhoneNumbersProps = {
  phoneNumbers?: PhoneNumberItem[];
  isLoading: boolean;
  isError: boolean;
};
