import { NIC_FIXTURE } from '../../entities/networks/api/fixtures';

const CURRENT_CONNECTIONS: NetworkConnection[] = [NIC_FIXTURE];

const CURRENT_CONNECTIONS5: NetworkConnection[] = Array(5).fill(NIC_FIXTURE);

export const CONNECTION_INFO_NO_CONNECTION: ConnectionInfo = {
  currentConnections: [],
  maxConnections: 5,
};

const CONNECTION_INFO_WITH_CONNECTION: ConnectionInfo = {
  currentConnections: CURRENT_CONNECTIONS,
  maxConnections: 5,
};

const CONNECTION_INFO_WITH_5_CONNECTIONS: ConnectionInfo = {
  currentConnections: CURRENT_CONNECTIONS5,
  maxConnections: 5,
};

export const DEFAULT_NETWORK: Network = {
  _links: {
    delete: {
      href: 'https://localhost/networks/londefaultnetwork',
    },
    node: {
      href: 'http://localhost/nodes/londefaultnetwork',
    },
    self: {
      href: 'http://localhost/networks/londefaultnetwork',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
  },
  description: 'Default Network description',
  inventoryNetwork: {
    id: 'VLAN-PRIMARY',
    subnet: '198.18.0.0 /18',
    type: 'ROUTED',
  },
  name: 'Default Network',
  topology: { uid: 'lontopology' },
  uid: 'londefaultnetwork',
  vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
};

export const ROUTED_NETWORK: Network = {
  _links: {
    delete: {
      href: 'https://localhost/networks/routednetwork',
    },
    node: {
      href: 'http://localhost/nodes/routednetwork',
    },
    self: {
      href: 'http://localhost/networks/routednetwork',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
  },
  description: 'Routed Network description',
  inventoryNetwork: {
    id: 'L3-VLAN-1',
    subnet: '198.18.1.0 /24',
    type: 'ROUTED',
  },
  name: 'Routed Network',
  topology: { uid: 'lontopology' },
  uid: 'routednetwork',
  vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
};

export const UNROUTED_NETWORK: Network = {
  _links: {
    delete: {
      href: 'https://localhost/networks/unroutednetwork',
    },
    node: {
      href: 'http://localhost/nodes/unroutednetwork',
    },
    self: {
      href: 'http://localhost/networks/unroutednetwork',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
  },
  description: 'Unrouted Network description',
  inventoryNetwork: {
    id: 'L2-VLAN-1',
    subnet: '198.18.5.0 /24',
    type: 'UNROUTED',
  },
  name: 'Unrouted Network',
  topology: { uid: 'lontopology' },
  uid: 'unroutednetwork',
  vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
};

export const CONNECTED_NICS_NETWORK: Network = {
  _links: {
    delete: {
      href: 'https://localhost/networks/unroutednetwork',
    },
    self: {
      href: 'http://localhost/networks/unroutednetwork',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
  },
  description: 'Unrouted Network description',
  inventoryNetwork: {
    id: 'L2-VLAN-1',
    subnet: '198.18.5.0 /24',
    type: 'UNROUTED',
  },
  name: 'Default Network',
  topology: { uid: 'lontopology' },
  uid: 'londefaultnetwork',
  vmConnectionInfo: CONNECTION_INFO_WITH_CONNECTION,
};

export const CONNECTED_NICS_NETWORK_WITH_5_CONNECTIONS: Network = {
  _links: {
    delete: {
      href: 'https://localhost/networks/unroutednetwork',
    },
    self: {
      href: 'http://localhost/networks/unroutednetwork',
    },
    topology: {
      href: 'http://localhost/topologies/lontopology',
    },
  },
  description: 'Unrouted Network description',
  inventoryNetwork: {
    id: 'L2-VLAN-1',
    subnet: '198.18.5.0 /24',
    type: 'UNROUTED',
  },
  name: 'Default Network',
  topology: { uid: 'lontopology' },
  uid: 'londefaultnetwork',
  vmConnectionInfo: CONNECTION_INFO_WITH_5_CONNECTIONS,
};

export const NETWORKS_FIXTURE: Network[] = [
  DEFAULT_NETWORK,
  ROUTED_NETWORK,
  UNROUTED_NETWORK,
];

export const NETWORKS_API_RESPONSE: GetNetworksResponse = {
  _embedded: {
    networks: NETWORKS_FIXTURE,
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/networks',
    },
  },
};

export const INVENTORY_NETWORKS_FIXTURE: InventoryNetwork[] = [
  {
    id: 'VLAN-PRIMARY',
    subnet: '198.18.128.0 /18',
    type: 'ROUTED',
  },
  {
    id: 'L3-VLAN-1',
    subnet: '198.18.1.0 /24',
    type: 'ROUTED',
  },
  {
    id: 'L3-VLAN-2',
    subnet: '198.18.2.0 /24',
    type: 'ROUTED',
  },
  {
    id: 'L3-VLAN-3',
    subnet: '198.18.3.0 /24',
    type: 'ROUTED',
  },
  {
    id: 'L3-VLAN-4',
    subnet: '198.18.4.0 /24',
    type: 'ROUTED',
  },
  {
    id: 'L2-VLAN-1',
    subnet: '198.18.5.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-2',
    subnet: '198.18.6.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-3',
    subnet: '198.18.7.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-4',
    subnet: '198.18.8.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-5',
    subnet: '198.18.9.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-6',
    subnet: '198.18.10.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-7',
    subnet: '198.18.11.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-8',
    subnet: '198.18.12.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-9',
    subnet: '198.18.13.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-10',
    subnet: '198.18.14.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-11',
    subnet: '198.18.15.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-12',
    subnet: '198.18.16.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-13',
    subnet: '198.18.17.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-14',
    subnet: '198.18.18.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-15',
    subnet: '198.18.19.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-16',
    subnet: '198.18.20.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-17',
    subnet: '198.18.21.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-18',
    subnet: '198.18.22.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-19',
    subnet: '198.18.23.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-20',
    subnet: '198.18.24.0 /24',
    type: 'UNROUTED',
  },
];

export const ROUTED_SUBNET_OPTIONS_FIXTURE = [
  ...INVENTORY_NETWORKS_FIXTURE.filter(({ type }) => type === 'ROUTED'),
];

export const UNROUTED_SUBNET_OPTIONS_FIXTURE = [
  ...INVENTORY_NETWORKS_FIXTURE.filter(({ type }) => type === 'UNROUTED'),
];

export const INVENTORY_NETWORKS_API_RESPONSE: GetInventoryNetworksResponse = {
  _embedded: {
    inventoryNetworks: INVENTORY_NETWORKS_FIXTURE,
  },
  _links: {
    self: {
      href: 'http://localhost/inventories/global/inventory-networks',
    },
  },
};

export const NETWORK_TO_UPDATE: Omit<Network, '_links'> = {
  description: 'Updated Default Network description',
  inventoryNetwork: {
    id: 'UNROUTED',
    subnet: '198.18.5.0 /24',
    type: 'UNROUTED',
  },
  name: 'Updated Default Network',
  topology: { uid: 'lontopology' },
  uid: 'londefaultnetwork',
  vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
};

export const NETWORK_TO_ADD = {
  description: 'Network description',
  inventoryNetwork: {
    id: 'ROUTED',
  },
  name: 'Network',
  topology: { uid: 'lontopology' },
};

const PARTIAL_ALL_NETWORKS: Network[] = [
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/1',
      },
      self: {
        href: 'https://localhost/networks/1',
      },
      topology: {
        href: 'https://localhost/topologies/1',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'VLAN-PRIMARY',
      subnet: '198.18.128.0 /18',
      type: 'ROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '1',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/2',
      },
      self: {
        href: 'https://localhost/networks/2',
      },
      topology: {
        href: 'https://localhost/topologies/2',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'L3-VLAN-2',
      subnet: '198.18.2.0 /24',
      type: 'ROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '2',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/3',
      },
      self: {
        href: 'https://localhost/networks/3',
      },
      topology: {
        href: 'https://localhost/topologies/3',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'L2-VLAN-1',
      subnet: '198.18.5.0 /24',
      type: 'UNROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '3',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/4',
      },
      self: {
        href: 'https://localhost/networks/4',
      },
      topology: {
        href: 'https://localhost/topologies/4',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'L2-VLAN-2',
      subnet: '198.18.6.0 /24',
      type: 'UNROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '4',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
];

const PARTIAL_ALL_L2_NETWORKS_USED: Network[] = [
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/3',
      },
      self: {
        href: 'https://localhost/networks/3',
      },
      topology: {
        href: 'https://localhost/topologies/3',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'L2-VLAN-1',
      subnet: '198.18.5.0 /24',
      type: 'UNROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '3',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/4',
      },
      self: {
        href: 'https://localhost/networks/4',
      },
      topology: {
        href: 'https://localhost/topologies/4',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'L2-VLAN-2',
      subnet: '198.18.6.0 /24',
      type: 'UNROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '4',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
];

const PARTIAL_ALL_L3_NETWORKS_USED: Network[] = [
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/1',
      },
      self: {
        href: 'https://localhost/networks/1',
      },
      topology: {
        href: 'https://localhost/topologies/1',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'VLAN-PRIMARY',
      subnet: '198.18.128.0 /18',
      type: 'ROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '1',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
  {
    _links: {
      delete: {
        href: 'https://localhost/networks/2',
      },
      self: {
        href: 'https://localhost/networks/2',
      },
      topology: {
        href: 'https://localhost/topologies/2',
      },
    },
    description: 'Network Description',
    inventoryNetwork: {
      id: 'L3-VLAN-2',
      subnet: '198.18.2.0 /24',
      type: 'ROUTED',
    },
    name: 'Network Name',
    topology: {
      uid: '1',
    },
    uid: '2',
    vmConnectionInfo: CONNECTION_INFO_NO_CONNECTION,
  },
];

export const PARTIAL_ALL_L3_NETWORKS_USED_RESPONSE: GetNetworksResponse = {
  _embedded: {
    networks: PARTIAL_ALL_L3_NETWORKS_USED,
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/vms',
    },
  },
};

export const PARTIAL_ALL_L2_NETWORKS_USED_RESPONSE: GetNetworksResponse = {
  _embedded: {
    networks: PARTIAL_ALL_L2_NETWORKS_USED,
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/vms',
    },
  },
};

export const PARTIAL_ALL_NETWORKS_USED_RESPONSE: GetNetworksResponse = {
  _embedded: {
    networks: PARTIAL_ALL_NETWORKS,
  },
  _links: {
    self: {
      href: 'http://localhost/topologies/lontopology/vms',
    },
  },
};

export const PARTIAL_ALL_INVENTORY_NETWORKS: InventoryNetwork[] = [
  {
    id: 'VLAN-PRIMARY',
    subnet: '198.18.128.0 /18',
    type: 'ROUTED',
  },
  {
    id: 'L3-VLAN-2',
    subnet: '198.18.2.0 /24',
    type: 'ROUTED',
  },
  {
    id: 'L2-VLAN-1',
    subnet: '198.18.5.0 /24',
    type: 'UNROUTED',
  },
  {
    id: 'L2-VLAN-2',
    subnet: '198.18.6.0 /24',
    type: 'UNROUTED',
  },
];

export const PARTIAL_ALL_INVENTORY_NETWORKS_USED_RESPONSE: GetInventoryNetworksResponse =
  {
    _embedded: {
      inventoryNetworks: PARTIAL_ALL_INVENTORY_NETWORKS,
    },
    _links: {
      self: {
        href: 'http://localhost/inventories/global/inventory-networks',
      },
    },
  };
