import { connect } from 'react-redux';
import {
  sessionInventorySelectors,
  setSelectedAction,
  setSelectedNodeType,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import { RootState } from '../../../../../redux/store';
import { MultiSelectDropDownVm } from '.';

const mapDispatchToProps = {
  setSelectedAction,
  setSelectedNodeType,
};

const mapStateToProps = (state: RootState) => ({
  isDisabled:
    sessionInventorySelectors.getShowMultiActionProgressBar(state) ||
    sessionInventorySelectors.getIsCallingVmStatusesAPICalls(state),
});

export const MultiSelectDropDownVmContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiSelectDropDownVm);

export type MultiSelectDropDownVmProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;
