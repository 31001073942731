import { useEffect } from 'react';

type WorkflowParamFields =
  | 'userConfigurable'
  | 'default'
  | 'name'
  | 'description'
  | 'defaultToWorkflowEngine';

export type UseTriggerWorkflowParamValueField = {
  trigger: (
    name: WorkflowParamFields | WorkflowParamFields[] | undefined,
  ) => Promise<boolean>;
  isDirty: boolean;
  userConfigurable: boolean;
  defaultToParent: boolean;
};

export function useTriggerWorkflowParamValueField({
  isDirty,
  trigger,
  userConfigurable,
  defaultToParent,
}: UseTriggerWorkflowParamValueField): void {
  useEffect(() => {
    (defaultToParent || userConfigurable) &&
      isDirty &&
      trigger &&
      trigger('default');
  }, [defaultToParent, userConfigurable, trigger, isDirty]);
}
