import { Checkbox } from 'dcloud-shared-ui';
import React, { ChangeEventHandler, ReactElement } from 'react';
import { Row } from 'react-table';

export interface RowCheckboxProps<T extends ObjectRecord> {
  indeterminate?: boolean;
  onChange: (isSelected: boolean, inventoryEntity: T) => void;
  row: Row<T>;
}

export function RowCheckbox<T extends ObjectRecord>({
  onChange,
  row,
}: RowCheckboxProps<T>): ReactElement {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { indeterminate, ...props } = row.getToggleRowSelectedProps();
  const handleSelect: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!props.onChange) return;
    props.onChange(e);
    onChange(!row.isSelected, row.original);
  };

  return <Checkbox {...props} onChange={handleSelect} />;
}
