import { MODAL_OVERLAY_ID } from '../constants';

export type HandleMouseDownProps = {
  target: HTMLElement;
  clickScreenToClose: boolean;
  onClose: VoidFunction;
};

export const handleMouseDown = ({
  target,
  clickScreenToClose,
  onClose,
}: HandleMouseDownProps): void => {
  clickScreenToClose &&
    (target.parentElement as HTMLElement)?.id === MODAL_OVERLAY_ID &&
    onClose();
};
