import styles from '../FourHandleNode.module.css';

export const BOX_COLOR_CLASS_NAMES = [
  styles.colorGray200,
  styles.colorGray400,
  styles.colorGray600,
  styles.colorGray800,
  styles.colorBlack,
  styles.colorSky,
  styles.colorLink,
  styles.colorOcean,
  styles.colorWarningAlt,
  styles.colorWarning,
  styles.colorDanger,
  styles.colorSuccess,
];
