import React, { ReactElement } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { PanelGrid } from '../../../PanelGrid';
import { PanelTableLoader } from '../../../../PanelTableLoader';
import { SESSION_INFORMATION_DATE_FORMAT } from './constants';
import { SessionInformationProps } from './container';

export function SessionInformation({
  isLoading,
  sessionInformation,
  isError,
}: SessionInformationProps): ReactElement {
  const { t } = useTranslation();

  const buildPanelGridData = (
    sessionInfo: SessionInformation,
  ): PanelGridDataItem[] => {
    const {
      endTime,
      lastModified,
      owner,
      parentDemo,
      sessionId,
      virtualCenter,
      vpnAvailable,
      licenses,
    } = sessionInfo;
    return [
      {
        key: 'parentDemo',
        name: t('sessions.panels.info.sessionInformation.parentDemo'),
        value: parentDemo,
      },
      {
        key: 'owner',
        name: t('sessions.panels.info.sessionInformation.owner'),
        value: owner,
      },
      {
        key: 'sessionId',
        name: t('sessions.panels.info.sessionInformation.sessionId'),
        value: sessionId,
      },
      {
        key: 'endTime',
        name: t('sessions.panels.info.sessionInformation.endTime'),
        value: format(new Date(endTime), SESSION_INFORMATION_DATE_FORMAT),
      },
      {
        key: 'lastModified',
        name: t('sessions.panels.info.sessionInformation.lastModified'),
        value: format(new Date(lastModified), SESSION_INFORMATION_DATE_FORMAT),
      },
      {
        key: 'vpnAvailable',
        name: t('sessions.panels.info.sessionInformation.vpnAvailable'),
        value: vpnAvailable ? 'Yes' : 'No',
      },
      {
        key: 'virtualCenter',
        name: t('sessions.panels.info.sessionInformation.vitualCenter'),
        value: virtualCenter,
      },
      {
        key: 'licenses',
        name: t('sessions.panels.info.sessionInformation.licenses.title'),
        value: licenses,
      },
    ];
  };

  if (!sessionInformation || isLoading || isError) {
    return <PanelTableLoader />;
  }

  return (
    <>
      <div className="base-margin-bottom">
        {t('sessions.panels.info.sessionInformation.heading')}
      </div>

      <PanelGrid
        data={buildPanelGridData(sessionInformation)}
        wideCol="value"
        nameBold={true}
      />
    </>
  );
}
