import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../redux/views/session-view/session/api/api';
import { getRemoteAccessFromNodeDetails } from './utils';
import { RemoteAccessContainerProps } from './container';
import { RemoteAccess } from '.';

type ConnectedDCloudRemoteAccessProps = RemoteAccessContainerProps &
  FetchSessionArgs;

export function ConnectedDCloudRemoteAccess({
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedDCloudRemoteAccessProps): ReactElement {
  const { remoteAccessPanelData } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          remoteAccessPanelData: getRemoteAccessFromNodeDetails(
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionVmNode,
          ),
        }),
      },
    );

  return <RemoteAccess data={remoteAccessPanelData || {}} />;
}
