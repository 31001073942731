import { connect } from 'react-redux';
import {
  resetTopologyLimits,
  setTopologyLimitsWarningAccepted,
  setTopologyLimitsWarningAcceptedInDiagram,
  topologyLimitsSelectors,
} from '../../../../redux/configuration/topologyLimits/slice';
import { RootState } from '../../../../redux/store';
import { TopologyLimitsWarningModal } from '.';

type TopologyLimitsWarningModalOwnProps = {
  onConfirmOverride?: () => void;
};

const mapStateToProps = (
  state: RootState,
  ownProps: TopologyLimitsWarningModalOwnProps,
) => ({
  label: topologyLimitsSelectors.getShowTopologyLimitWarningForEntity(state),
  onConfirmOverride: ownProps.onConfirmOverride,
});

const mapDispatchToProps = {
  resetTopologyLimits,
  setTopologyLimitsWarningAccepted,
  setTopologyLimitsWarningAcceptedInDiagram,
};

export const TopologyLimitsWarningModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopologyLimitsWarningModal);

export type TopologyLimitsWarningModalProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;
