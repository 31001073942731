import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PanelTableLoader } from '../../../../PanelTableLoader';
import { PanelGrid } from '../../../PanelGrid';
import { AnyConnectProps } from './container';

export function AnyConnect({
  anyConnectNode,
  isLoading,
  isError,
}: AnyConnectProps): ReactElement {
  const { t } = useTranslation();

  const buildPanelGridData = (
    anyConnect: AnyConnectNode,
  ): PanelGridDataItem[] => {
    const { vpnPassword, vpnServer, vpnUserIds } = anyConnect;
    return [
      {
        key: 'vpnServer',
        name: 'VPN',
        value: vpnServer,
      },
      {
        key: 'user',
        name: 'User',
        value: vpnUserIds,
      },
      {
        key: 'password',
        name: 'Password',
        value: vpnPassword,
      },
    ];
  };

  if (isLoading || isError) {
    return <PanelTableLoader />;
  }

  if (!anyConnectNode) {
    return (
      <Alert>
        {t('sessions.panels.info.anyConnect.noAnyConnectCredentialsConfigured')}
      </Alert>
    );
  }

  return (
    <>
      <PanelGrid
        data={buildPanelGridData(anyConnectNode)}
        wideCol="value"
        nameBold={true}
        wideColRatio={4}
      />
    </>
  );
}
