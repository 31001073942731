import { useEffect } from 'react';

type useResetMultiSelectDataArgs = {
  resetMultiSelect: VoidFunction;
};

export function useResetMultiSelectData({
  resetMultiSelect,
}: useResetMultiSelectDataArgs): void {
  useEffect(() => {
    return () => {
      resetMultiSelect();
    };
  }, [resetMultiSelect]);
}
