import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSaasIntegrationsQuery } from '../../../../redux/configuration/saas/api';
import { SaasIntegrationsSkeletonLoader } from './components/SaasIntegrationsSkeletonLoader';
import { filterIntegrationsByType } from './utils/utils';
import { SaasIntegrations } from '.';

type ConnectedUserIntegrationsProps = {
  integrationType: IntegrationType;
};

export function ConnectedUserIntegrations({
  integrationType,
}: ConnectedUserIntegrationsProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const {
    data: integrations,
    isLoading,
    isFetching,
    isError,
  } = useGetSaasIntegrationsQuery(topologyUid, {
    selectFromResult: ({ data, isError, isFetching, isLoading }) => ({
      data: filterIntegrationsByType(integrationType, data?.saasIntegrations),
      isError,
      isFetching,
      isLoading,
    }),
  });

  return isLoading || isFetching || isError || !integrations ? (
    <SaasIntegrationsSkeletonLoader integrationType={integrationType} />
  ) : (
    <SaasIntegrations
      integrations={integrations}
      integrationType={integrationType}
    />
  );
}
