import { SelectOption } from 'dcloud-shared-ui';
import i18n from './i18n';

export const FOOTER_FEEDBACK_LINK =
  'https://app.smartsheet.com/b/form/fda878a3ac2849c289b3718df68629b0';

export const FOOTER_LINKS = [
  {
    href: 'https://www.cisco.com/c/en/us/about/contact-cisco.html',
    name: i18n.t('footer.contacts'),
  },
  {
    href: FOOTER_FEEDBACK_LINK,
    name: i18n.t('footer.feedback'),
  },
  {
    href: 'http://www.cisco.com/c/en/us/about/sitemap.html',
    name: i18n.t('footer.help'),
  },
  {
    href: 'http://www.cisco.com/c/en/us/about/sitemap.html',
    name: i18n.t('footer.siteMap'),
  },
  {
    href: 'https://www.cisco.com/c/en/us/about/legal/terms-conditions.html',
    name: i18n.t('footer.termsAndConditions'),
  },
  {
    href: 'https://www.cisco.com/c/en/us/about/legal/privacy-full.html',
    name: i18n.t('footer.privacyStatement'),
  },
  {
    href: 'https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies',
    name: i18n.t('footer.cookiePolicy'),
  },
  {
    href: 'https://www.cisco.com/c/en/us/about/legal/trademarks.html',
    name: i18n.t('footer.trademarks'),
  },
];

export const REQUEST_A_FEATURE_URL =
  'https://cisco-gde.atlassian.net/servicedesk/customer/portal/2/group/3/create/9';

export const PUBLIC_CLOUD_INTEGRATION_TYPE: IntegrationType = 'CLOUD';
export const SAAS_INTEGRATION_TYPE: IntegrationType = 'SAAS';

export const INTEGRATION_TYPE_LABEL_KEYS: IntegrationTypeLabelKeys = {
  [PUBLIC_CLOUD_INTEGRATION_TYPE]: 'publicCloud',
  [SAAS_INTEGRATION_TYPE]: 'saas',
};

export const INTEGRATION_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    name: i18n.t('common.pleaseSelect'),
    value: '',
  },
  {
    name: i18n.t(
      `integrations.table.integrationTypes.${INTEGRATION_TYPE_LABEL_KEYS[SAAS_INTEGRATION_TYPE]}`,
    ),
    value: SAAS_INTEGRATION_TYPE,
  },
  {
    name: i18n.t(
      `integrations.table.integrationTypes.${INTEGRATION_TYPE_LABEL_KEYS[PUBLIC_CLOUD_INTEGRATION_TYPE]}`,
    ),
    value: PUBLIC_CLOUD_INTEGRATION_TYPE,
  },
];

export const INTEGRATION_TYPE_OPTIONS_FOR_EDIT: SelectOption<string>[] = [
  INTEGRATION_TYPE_OPTIONS[1],
  INTEGRATION_TYPE_OPTIONS[2],
];

export const SESSION_VIEW_HW_ACTIONS = ['POWER_ON', 'POWER_OFF', 'RESET'];
export const SESSION_VIEW_VM_ACTIONS = [
  'GUEST_OS_REBOOT',
  'GUEST_OS_SHUTDOWN',
  'VM_POWER_ON',
  'VM_POWER_OFF',
  'VM_RESET',
];

export const UNAVAILABLE_STATUS = 'UNAVAILABLE';
