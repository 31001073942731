import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface HardwareState {
  hardwareToDelete?: Hardware;
  networkInterfaceToDelete?: string;
}

export const initialState: HardwareState = {
  hardwareToDelete: undefined,
  networkInterfaceToDelete: undefined,
};

const slice = createSlice({
  initialState,
  name: 'hardware',
  reducers: {
    setHardwareToDelete: (
      state,
      action: PayloadAction<Hardware | undefined>,
    ) => {
      state.hardwareToDelete = action.payload;
    },
    setNetworkInterfaceToDelete: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.networkInterfaceToDelete = action.payload;
    },
  },
});

export const getHardware = (state: RootState): HardwareState =>
  state.configuration[slice.name];

export const { reducer: hardwareReducer } = slice;
export const { setHardwareToDelete, setNetworkInterfaceToDelete } =
  slice.actions;
