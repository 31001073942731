import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { sessionViewSelectors } from '../../../../../../../../redux/views/session-view/session/slice';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudHWInfo } from './dCloudContainer';
import { ConnectedExpoHWInfo } from './expoContainer';

export function HWInfoContainer({
  nodeUid,
}: HwInfoContainerProps): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoHWInfo
        engagementUid={engagementUid}
        nodeUid={nodeUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudHWInfo
      nodeUid={nodeUid}
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  nodeUid: sessionViewSelectors.getLeftPanelDeviceUid(state),
});

export type HwInfoContainerProps = ReturnType<typeof mapStateToProps>;

type HwInfoRTKProps = {
  data: HwPanelInfo;
};

export type HwInfoProps = HwInfoRTKProps;

export const ConnectedHwInfoContainer =
  connect(mapStateToProps)(HWInfoContainer);
