import React, { ReactElement } from 'react';
import { format } from 'date-fns';
import i18n from '../../../../../../../../i18n';
import { LAST_UPDATED_DATE_FORMAT } from '../../../constants';
import { ArchitectureLabelGroup } from '../../../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import styles from './styles.module.css';

export const buildInfoColsData = (
  showMoreInfoForVmTemplate: InventoryVirtualMachine,
): InfoModalData[] => {
  return [
    {
      data: buildInfoCol1Data(showMoreInfoForVmTemplate),
    },
    {
      data: buildInfoCol2Data(showMoreInfoForVmTemplate),
    },
    {
      data: buildInfoCol3Data(showMoreInfoForVmTemplate),
    },
  ];
};

export const buildInfoCol1Data = (
  showMoreInfoForVmTemplate: InventoryVirtualMachine,
): InfoModalDataItem[] => {
  const {
    templateVersion,
    username,
    password,
    datacenter,
    lastUpdated,
    originalName,
  } = showMoreInfoForVmTemplate;

  return [
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.version'),
      value: templateVersion,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.username'),
      value: username,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.password'),
      value: password,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.datacenter'),
      value: datacenter,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.lastUpdated'),
      value: lastUpdated
        ? format(new Date(lastUpdated), LAST_UPDATED_DATE_FORMAT)
        : undefined,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.originalName'),
      value: originalName,
    },
  ];
};

export const buildInfoCol2Data = (
  showMoreInfoForVmTemplate: InventoryVirtualMachine,
): InfoModalDataItem[] => {
  const { cpuQty, memoryMb, id, architectures } = showMoreInfoForVmTemplate;

  return [
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.cpu'),
      value: cpuQty,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.memory'),
      value: memoryMb,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.vmId'),
      value: id,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.architecture'),
      value: architectures && (
        <ArchitectureLabelGroup architectures={architectures} />
      ),
    },
  ];
};

export const buildInfoCol3Data = (
  showMoreInfoForVmTemplate: InventoryVirtualMachine,
): InfoModalDataItem[] => {
  const { managementIp, licensed, remoteAccess } = showMoreInfoForVmTemplate;

  return [
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.managementIp'),
      value: managementIp,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.licensed'),
      value: licensed === undefined ? undefined : licensed ? 'Yes' : 'No',
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.sshEnabled'),
      value:
        remoteAccess?.sshEnabled !== undefined
          ? remoteAccess.sshEnabled
            ? 'Yes'
            : 'No'
          : undefined,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.rdpEnabled'),
      value:
        remoteAccess?.rdpEnabled !== undefined
          ? remoteAccess.rdpEnabled
            ? 'Yes'
            : 'No'
          : undefined,
    },
    {
      label: i18n.t('inventoryVirtualMachines.infoModal.headings.autoLogin'),
      value:
        remoteAccess?.rdpAutoLogin !== undefined
          ? remoteAccess.rdpAutoLogin
            ? 'Yes'
            : 'No'
          : undefined,
    },
  ];
};

export const prepTemplateVmsForTable = (
  inventoryTemplateVms: InventoryVirtualMachine[],
): InventoryVirtualMachine[] =>
  inventoryTemplateVms.map((inventoryTemplateVm) => ({
    ...inventoryTemplateVm,
    architectureNames: inventoryTemplateVm.architectures?.map(
      (architecture) => architecture.name || '',
    ),
    description:
      inventoryTemplateVm.description ||
      inventoryTemplateVm.originalDescription,
    name: inventoryTemplateVm.name || inventoryTemplateVm.originalName,
  }));

export const buildInfoModalFooter = (
  showMoreInfoForVmTemplate: InventoryVirtualMachine,
): ReactElement => {
  return (
    <div className="row base-margin-bottom dbl-margin-top">
      <div className="col-12">
        <div className="text-left">
          <span className={styles.descriptionLabel}>Description</span>
          {showMoreInfoForVmTemplate.description}
        </div>
      </div>
    </div>
  );
};
