import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudEndpointKits } from './dCloudContainer';
import { ConnectedExpoEndpointKits } from './expoContainer';

export function EndpointKitsContainer(): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoEndpointKits
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudEndpointKits
      sessionUid={sessionUid}
      versionUid={versionUid}
    />
  );
}

export type EndpointKitsProps = {
  endpoints?: EndpointKit[];
  isLoading: boolean;
  isError: boolean;
};
