import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { Modal } from '../../../../../../components';
import { useSkeletonColours } from '../../../../../../hooks/use-skeleton-colours';

interface EditDemoTemplateFormLoaderProps {
  onClose: VoidFunction;
}
export function EditDemoTemplateFormLoader({
  onClose,
}: EditDemoTemplateFormLoaderProps): ReactElement {
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  return (
    <Modal
      clickScreenToClose={true}
      show={true}
      onClose={onClose}
      headerAlignment="center"
    >
      <ContentLoader
        className="dbl-margin-top"
        speed={1.5}
        width="100%"
        height={600}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="30" y="344" rx="0" ry="0" width="562" height="144" />
        <rect x="28" y="252" rx="0" ry="0" width="273" height="52" />
        <rect x="317" y="252" rx="0" ry="0" width="273" height="53" />
        <rect x="120" y="522" rx="0" ry="0" width="168" height="42" />
        <rect x="315" y="521" rx="0" ry="0" width="168" height="42" />
        <rect x="30" y="188" rx="0" ry="0" width="273" height="52" />
        <rect x="319" y="188" rx="0" ry="0" width="273" height="53" />
        <rect x="29" y="119" rx="0" ry="0" width="273" height="52" />
        <rect x="318" y="119" rx="0" ry="0" width="273" height="53" />
        <rect x="29" y="54" rx="0" ry="0" width="273" height="52" />
        <rect x="320" y="55" rx="0" ry="0" width="273" height="53" />
        <rect x="29" y="-9" rx="0" ry="0" width="273" height="52" />
        <rect x="320" y="-8" rx="0" ry="0" width="273" height="53" />
      </ContentLoader>
    </Modal>
  );
}
