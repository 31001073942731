import classnames from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';
import styles from './SideMenu.module.css';

interface SideMenuProps extends PropsWithChildren<unknown> {
  className?: string;
}

export function SideMenu({ children, className }: SideMenuProps): ReactElement {
  return (
    <div className={classnames(styles.root, className)}>
      <div className={classnames(styles.innerWrapper)}>{children}</div>
    </div>
  );
}
