import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface InventoryTemplatesState {
  showMoreInfoForInventoryTemplate?: InventoryTopology;
}

export const initialState: InventoryTemplatesState = {
  showMoreInfoForInventoryTemplate: undefined,
};

const slice = createSlice({
  initialState,
  name: 'inventoryTemplate',
  reducers: {
    setShowMoreInfoForInventoryTemplate: (
      state,
      action: PayloadAction<InventoryTopology | undefined>,
    ) => {
      state.showMoreInfoForInventoryTemplate = action.payload;
    },
  },
});

export const { reducer: inventoryTemplatesReducer } = slice;

export const getMoreInfoForInventoryTemplate = (
  state: RootState,
): InventoryTemplatesState => state.configuration[slice.name];

export const { setShowMoreInfoForInventoryTemplate } = slice.actions;
