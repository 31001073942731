import React, { ReactElement } from 'react';
import { useSessionUserContext } from '../../../../../../../../../../hooks/use-session-user-context';
import { ConnectedDCloudPublicIP } from './dCloudContainer';
import { ConnectedExpoPublicIP } from './expoContainer';

export function PublicIPContainer(): ReactElement {
  const { engagementUid, sessionUid, versionUid } = useSessionUserContext();

  if (engagementUid) {
    return (
      <ConnectedExpoPublicIP
        engagementUid={engagementUid}
        sessionUid={sessionUid}
        versionUid={versionUid}
      />
    );
  }

  return (
    <ConnectedDCloudPublicIP sessionUid={sessionUid} versionUid={versionUid} />
  );
}

export type PublicIProps = {
  sessionPublicAddresses?: SessionPublicAddress[];
  isLoading: boolean;
  isError: boolean;
};
