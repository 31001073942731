import { Icon, IconButton } from 'dcloud-shared-ui';
import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import styles from './BackButton.module.css';

type BackButtonProps = {
  backButtonOverride?: VoidFunction;
};

export function BackButton({
  backButtonOverride,
}: BackButtonProps): ReactElement {
  const { pathname } = useLocation();

  if (backButtonOverride) {
    return (
      <IconButton
        icon="chevron-left"
        testId="page-back-button"
        onClick={() => backButtonOverride()}
        className={styles.backButtonOverride}
      />
    );
  }

  return (
    <Link
      to={pathname.substring(0, pathname.lastIndexOf('/'))}
      className={classnames(styles.root, 'backButton')}
    >
      <Icon name="chevron-left" testId="page-back-button" />
    </Link>
  );
}
