import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormMethods } from 'react-hook-form';
import classnames from 'classnames';
import { ControlledTextfield } from '../../../../../../components';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { useErrorToast } from '../../../../../../hooks/toasts/use-error-toast';
import styles from './ScenariosForm.module.css';

interface ScenarioOptionsFormProps {
  isEditingScenarioOption: boolean;
  formData: UseFormMethods<ScenarioFields>;
  scenariosToSave: Scenarios;
  setScenariosToSave: (scenarios?: Scenarios) => void;
}

export function ScenarioOptionsForm({
  isEditingScenarioOption,
  formData,
  scenariosToSave,
  setScenariosToSave,
}: ScenarioOptionsFormProps): ReactElement {
  const { control, errors, watch } = formData;
  const { t } = useTranslation();
  const { showErrorToast } = useErrorToast();
  const resetScenarioOptionForm = () => {
    control.setValue('internalName', '');
    control.setValue('displayName', '');
  };
  const onAddClick = () => {
    if (
      scenariosToSave.scenarioOptions?.find(
        (scenario) =>
          scenario.displayName === control.getValues().displayName ||
          scenario.internalName === control.getValues().internalName,
      )
    ) {
      showErrorToast(`${t('scenarios.formErrors.duplicate')}`);
    } else {
      setScenariosToSave({
        ...scenariosToSave,
        scenarioOptions: [
          ...(scenariosToSave.scenarioOptions || []),
          {
            displayName: control.getValues().displayName!,
            internalName: control.getValues().internalName!,
          },
        ],
      });
      resetScenarioOptionForm();
    }
  };
  const isAddDisabled =
    !watch('internalName') ||
    !watch('displayName') ||
    !!errors.internalName ||
    !!errors.displayName ||
    isEditingScenarioOption;
  const isScenarioOptionDisabled =
    !scenariosToSave.enabled || isEditingScenarioOption;
  return (
    <div className={styles.scenarioOptionsForm}>
      <h5>{t('scenarios.add.headerText')}</h5>
      <div className="base-padding row">
        <div className={styles.scenarioOption}>
          <ControlledTextfield
            disabled={isScenarioOptionDisabled}
            control={control}
            error={errors.internalName?.message}
            label={t('scenarios.scenarioName')}
            name="internalName"
            required={true}
            testId="scenario-name-input"
          />
        </div>
        <div className={styles.scenarioOption}>
          <ControlledTextfield
            disabled={isScenarioOptionDisabled}
            control={control}
            error={errors.displayName?.message}
            label={t('scenarios.displayName')}
            name="displayName"
            required={true}
            testId="scenario-display-name-input"
          />
        </div>
        <div className="dbl-margin-top half-padding-left">
          <PrimaryButton
            type="button"
            testId="scenarios-add-button"
            disabled={isAddDisabled}
            onClick={onAddClick}
          >
            {t('buttons.add')}
          </PrimaryButton>
        </div>
      </div>
      <div className={classnames('base-padding-left', styles.footer)}>
        {t('scenarios.footerText')}
      </div>
    </div>
  );
}
