import { Card } from 'dcloud-shared-ui';
import classnames from 'classnames';
import React, { ReactElement, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { TopologySVGIconProps } from '../../../../../components/SVGIcons/TopologySVGIcon';
import { TILE_CLASSES } from '../constants';
import styles from './Tile.module.css';

export interface TileProps {
  className?: string;
  description: string;
  Image: FunctionComponent<TopologySVGIconProps | unknown>;
  link: string;
  title: string;
  disabledWithoutRoles?: UserRoles[];
  disabled?: boolean;
  requiredRoles?: UserRoles[];
  externalLink?: boolean;
}

export function Tile({
  className,
  description,
  Image,
  link,
  title,
}: TileProps): ReactElement {
  return (
    <div className={classnames(styles.root, TILE_CLASSES, className)}>
      <Link to={link} className={styles.cardWrap}>
        <Card className={styles.card}>
          <Image />
        </Card>
      </Link>
      <h3 className="dbl-margin-top base-margin-bottom ">
        <Link
          to={link}
          className={classnames(styles.titleLink, 'text-weight-200')}
        >
          {title}
        </Link>
      </h3>
      <p
        className={classnames(
          styles.description,
          'text-weight-200 text-size-16',
        )}
      >
        <span dangerouslySetInnerHTML={{ __html: description }}></span>
      </p>
    </div>
  );
}
