import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { setupListeners } from '@reduxjs/toolkit/query';
import history from './history';
import { rootReducer } from './reducer';
import { dcloudApi } from './api';
import { rootSaga } from './sagas';
import { userPrefMiddleware } from './middlewares/userPrefMiddleware';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(routerMiddleware(history))
      .concat(dcloudApi.middleware)
      .concat(sagaMiddleware)
      .concat(userPrefMiddleware),
  reducer: rootReducer,
});

sagaMiddleware.run(rootSaga);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
