import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  addSelectedLicense,
  removeSelectedLicense,
} from '../../pages/CreateIntegration/components/LicenseForm/utils/utils';
import { addSelectedTag } from '../../components/Tags/utils/utils';

interface IntegrationState {
  integrationToDelete?: Integration;
  integrationToDisplayUid?: string;
  integrationToSave?: IntegrationToSave;
  integrationToUpdate?: IntegrationToUpdate;
}

export const initialState: IntegrationState = {
  integrationToDelete: undefined,
  integrationToDisplayUid: undefined,
  integrationToSave: undefined,
  integrationToUpdate: undefined,
};

export const slice = createSlice({
  initialState,
  name: 'integrations',
  reducers: {
    addLabel: (state, action: PayloadAction<CustomLabel>) => {
      state.integrationToSave = {
        ...state.integrationToSave,
        integrationLicenses: addSelectedLicense(
          state.integrationToSave?.integrationLicenses || [],
          action.payload,
        ),
      };
    },
    addLabelToIntegrationToUpdate: (
      state,
      action: PayloadAction<CustomLabel>,
    ) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        integrationLicenses: addSelectedLicense(
          state.integrationToUpdate?.integrationLicenses || [],
          action.payload,
        ),
      };
    },
    addTag: (state, action: PayloadAction<Tag>) => {
      state.integrationToSave = {
        ...state.integrationToSave,
        tags: addSelectedTag(
          state.integrationToSave?.tags || [],
          action.payload,
        ),
      };
    },
    addTagToIntegrationToUpdate: (state, action: PayloadAction<Tag>) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        tags: addSelectedTag(
          state.integrationToUpdate?.tags || [],
          action.payload,
        ),
      };
    },
    clearIntegrationToDelete: (state) => {
      state.integrationToDelete = undefined;
    },
    clearIntegrationToDisplayUid: (state) => {
      state.integrationToDisplayUid = undefined;
    },
    clearIntegrationToSave: (state) => {
      state.integrationToSave = undefined;
    },
    clearIntegrationToUpdate: (state) => {
      state.integrationToUpdate = undefined;
    },
    removeLabel: (state, action: PayloadAction<CustomLabel>) => {
      state.integrationToSave = {
        ...state.integrationToSave,
        integrationLicenses: removeSelectedLicense(
          state.integrationToSave?.integrationLicenses || [],
          action.payload,
        ),
      };
    },
    removeLabelFromIntegrationToUpdate: (
      state,
      action: PayloadAction<CustomLabel>,
    ) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        integrationLicenses: removeSelectedLicense(
          state.integrationToUpdate?.integrationLicenses || [],
          action.payload,
        ),
      };
    },
    setFetchedInputsToEdit: (
      state,
      action: PayloadAction<SaasTemplateInput[] | undefined>,
    ) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        fetchedInputs: action.payload,
      };
    },
    setFetchedLabelsToEdit: (state, action: PayloadAction<CustomLabel[]>) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        fetchedIntegrationLicenses: action.payload,
      };
    },
    setInput: (state, action: PayloadAction<SetInputPayload>) => {
      const inputs = (state.integrationToSave?.inputs || []).map((input) =>
        input.name === action.payload.name
          ? { ...input, ...action.payload }
          : input,
      );
      state.integrationToSave = {
        ...state.integrationToSave,
        inputs,
      };
    },
    setInputStatus: (state, action: PayloadAction<SetInputStatusPayload>) => {
      state.integrationToSave = {
        ...state.integrationToSave,
        inputStatuses: {
          ...state.integrationToSave?.inputStatuses,
          [action.payload.name]: action.payload.isValid,
        },
      };
    },
    setInputsAndFetchedInputsToAdd: (
      state,
      action: PayloadAction<IntegrationInput[]>,
    ) => {
      state.integrationToSave = {
        ...state.integrationToSave,
        fetchedInputs: action.payload,
        inputs: action.payload,
      };
    },
    setInputsToEdit: (
      state,
      action: PayloadAction<SaasTemplateInput[] | undefined>,
    ) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        inputs: action.payload,
      };
    },
    setIntegrationToDelete: (
      state,
      action: PayloadAction<Integration | undefined>,
    ) => {
      state.integrationToDelete = action.payload;
    },
    setIntegrationToDisplayUid: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.integrationToDisplayUid = action.payload;
    },
    setIntegrationToSave: (
      state,
      action: PayloadAction<IntegrationToSave | undefined>,
    ) => {
      state.integrationToSave = action.payload;
    },
    setIntegrationToUpdate: (
      state,
      action: PayloadAction<IntegrationToUpdate | undefined>,
    ) => {
      state.integrationToUpdate = action.payload;
    },
    setTags: (state, action: PayloadAction<Tag[]>) => {
      state.integrationToSave = {
        ...state.integrationToSave,
        tags: action.payload,
      };
    },
    updateInput: (state, action: PayloadAction<UpdateInputPayload>) => {
      const inputs = (state.integrationToUpdate?.inputs || []).map((input) =>
        input.mappingName === action.payload.mappingName
          ? { ...input, ...action.payload }
          : input,
      );
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        inputs,
      };
    },
    updateInputStatus: (
      state,
      action: PayloadAction<UpdateInputStatusPayload>,
    ) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        inputStatuses: {
          ...state.integrationToUpdate?.inputStatuses,
          [action.payload.mappingName]: action.payload.isValid,
        },
      };
    },
    updateLabels: (state, action: PayloadAction<CustomLabel[]>) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        integrationLicenses: action.payload,
      };
    },
    updateTags: (state, action: PayloadAction<Tag[]>) => {
      state.integrationToUpdate = {
        ...state.integrationToUpdate,
        tags: action.payload,
      };
    },
  },
});

export const {
  reducer: integrationReducer,
  actions: {
    clearIntegrationToDelete,
    clearIntegrationToDisplayUid,
    clearIntegrationToSave,
    clearIntegrationToUpdate,
    setIntegrationToDelete,
    setIntegrationToDisplayUid,
    setIntegrationToSave,
    setIntegrationToUpdate,
    setInput,
    setInputStatus,
    updateInput,
    updateInputStatus,
    setFetchedInputsToEdit,
    setInputsAndFetchedInputsToAdd,
    setInputsToEdit,
    addLabel,
    addLabelToIntegrationToUpdate,
    removeLabel,
    removeLabelFromIntegrationToUpdate,
    addTag,
    addTagToIntegrationToUpdate,
    setTags,
    setFetchedLabelsToEdit,
    updateLabels,
    updateTags,
  },
} = slice;

const getIntegration = (state: RootState) => state[slice.name];

export const integrationSelectors = {
  getIntegrationToDelete: createSelector(
    getIntegration,
    ({ integrationToDelete }) => integrationToDelete,
  ),
  getIntegrationToDisplayUid: createSelector(
    getIntegration,
    ({ integrationToDisplayUid }) => integrationToDisplayUid,
  ),
  getIntegrationToSave: createSelector(
    getIntegration,
    ({ integrationToSave }) => integrationToSave,
  ),
  getIntegrationToUpdate: createSelector(
    getIntegration,
    ({ integrationToUpdate }) => integrationToUpdate,
  ),
};
