import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { willDevicesLimitBeExceeded } from './utils/utils';

type UseShowLimitsWarningDevicesReturn = {
  onAddClicked: VoidFunction;
};

export type UseShowLimitsWarningDevicesProps = {
  topologyLimitsWarningAccepted?: boolean;
  resetTopologyLimits: VoidFunction;
  addHardware: VoidFunction;
  selectedHardwareItems: InventoryHardwareItemToAdd[];
  topologyLimit?: TopologyLimit;
  setShowTopologyLimitWarningForEntity: ActionCreatorWithOptionalPayload<
    | TopologyLimitsDisplayLabels
    | Array<TopologyLimitsDisplayLabels>
    | undefined,
    string
  >;
};

export function useShowTopologyLimitsWarningDevices({
  topologyLimitsWarningAccepted,
  resetTopologyLimits,
  addHardware,
  selectedHardwareItems,
  topologyLimit,
  setShowTopologyLimitWarningForEntity,
}: UseShowLimitsWarningDevicesProps): UseShowLimitsWarningDevicesReturn {
  useEffect(() => {
    if (topologyLimitsWarningAccepted) {
      resetTopologyLimits();
      addHardware();
    }

    return () => resetTopologyLimits();
  }, [addHardware, resetTopologyLimits, topologyLimitsWarningAccepted]);

  const onAddClicked = () => {
    if (!topologyLimit) {
      addHardware();
      return;
    }
    if (
      willDevicesLimitBeExceeded(selectedHardwareItems.length, topologyLimit)
    ) {
      setShowTopologyLimitWarningForEntity('Devices');
    } else {
      addHardware();
    }
  };

  return { onAddClicked };
}
