import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from '../../../../../../../components/Dropdown/components/DropdownItem';
import { ResetPowerDropdownItemProps } from './container';

export const ResetPowerDropdownItem = ({
  setSelectedAction,
  setSelectedNodeType,
}: ResetPowerDropdownItemProps): ReactElement => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    setSelectedAction('RESET');
    setSelectedNodeType('HW');
  };

  return (
    <DropdownItem
      onClick={handleOnClick}
      label={t('sessionsInventory.hardware.table.dropdown.labels.reset')}
    />
  );
};
