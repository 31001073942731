import classNames from 'classnames';
import styles from '../Nodes.module.css';

type DetermineNodesClasses = {
  selectedItem: string;
  isAddingLogicalConnection: boolean;
};

export const determineNodesClasses = ({
  selectedItem,
  isAddingLogicalConnection,
}: DetermineNodesClasses): string => {
  return classNames(styles.root, {
    [styles.pointerEventsAuto]:
      selectedItem.length > 0 || isAddingLogicalConnection,
    [styles.pointerEventsNone]:
      selectedItem.length === 0 && !isAddingLogicalConnection,
  });
};
