export const determineWillMaxRemoteAccessCountBeExceededByVm = (
  currentTopologyRemoteAccessCount: number,
  vm: VirtualMachine,
  max: number,
): boolean =>
  currentTopologyRemoteAccessCount + getVmRemoteAccessCount(vm) > max;

export const getVmRemoteAccessCount = (vm: VirtualMachine): number => {
  let count = 0;
  vm.vmNetworkInterfaces.map((vmNetworkInterface) => {
    vmNetworkInterface.ssh?.enabled ? count++ : null;
    vmNetworkInterface.rdp?.enabled ? count++ : null;
  });
  return count;
};
