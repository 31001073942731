import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { NODE_CONNECTION_DEFAULT_PADDING } from '../../../../constants';
import { logicalConnectionLineDefaults } from '../../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddLogicalConnection/constants';
import { useDiagramContext } from '../../../DiagramProvider';
import styles from './index.module.css';

export type LogicalConnectionSelectorProps = {
  connection: DiagramConnection;
  isSessionView: boolean;
};

export const LogicalConnectionSelector = ({
  connection,
  isSessionView,
}: LogicalConnectionSelectorProps): ReactElement => {
  const { selectedItem } = useDiagramContext();
  if (connection.uid === 'TEMP_LOGICAL_GUIDE_UID' || isSessionView) {
    return <></>;
  }

  const connectionType = 'LOGICAL';
  const cursor = 'pointer';
  const dataUid = `${connection.uid}-selector`;
  const dataComponent = `${connection.uid}-selector`;

  const classes = classNames(styles.hidden, {
    [styles.selected]: selectedItem === connection.uid,
  });

  return (
    <line
      cursor={cursor}
      strokeWidth={logicalConnectionLineDefaults.strokeWidth}
      data-uid={dataUid}
      data-component={dataComponent}
      data-connection-from={connection.nodes.from.uid}
      data-connection-to={connection.nodes.to.uid}
      data-connection-order={connection.display?.connection?.order}
      data-connection-derived-order={connection.derived?.order}
      data-connection-padding={
        connection.display?.connection?.padding ||
        NODE_CONNECTION_DEFAULT_PADDING
      }
      data-connection-total={connection.derived?.total}
      data-connection-from-to={connection.derived?.fromTo}
      data-connection-type={connectionType}
      data-connection-stroke-type={logicalConnectionLineDefaults.strokeType}
      data-connection-selector={true}
      className={classes}
    ></line>
  );
};
