import React, { ReactElement } from 'react';
import { Button, Icon } from 'dcloud-shared-ui';
import styles from './TableActions.module.css';

interface TableActionsProps {
  demoTemplate: DemoTemplate;
  onEditClick: (demoTemplate: DemoTemplate) => void;
}

export function DemoTemplateTableActions({
  onEditClick,
  demoTemplate,
}: TableActionsProps): ReactElement {
  return (
    <ul aria-label="Card actions" className={styles.root}>
      <li className={styles.action}>
        <Button
          colour="link"
          onClick={() => onEditClick(demoTemplate)}
          data-test="demo-template-table-actions-edit"
        >
          <Icon name="edit" />
        </Button>
      </li>
    </ul>
  );
}
