import { MouseEvent } from 'react';
import * as d3 from 'd3';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { useConnection } from '../../Connections/hooks/use-connection';
import { useCreateConnectionMutation } from '../../../../../redux/connections/api';
import { resetLogicalConnections } from '../../../../../redux/logicalConnections/slice';
import {} from '../../../../../redux/diagram/utils';
import {
  createLogicalConnection,
  determineFromAndToAttributesForNewConnection,
  isLogicalConnectionLimitReached,
  removeNodeCanvasMouseMoveListener,
  resetTempGuideLine,
  setNodeCanvasCursorStyle,
  updateTempGuideLineFromAndToAttributes,
} from '../utils/utils';
import { TEMP_LOGICAL_GUIDE_UID } from '../../../constants';
import { setShowAddLogicalConnectionLimitModal } from '../../../../../redux/diagram/slice';

export type HandleOnClick = {
  event: MouseEvent<HTMLElement>;
  topologyUid: string;
  isAddingLogicalConnection: boolean;
  isConnectionAllowed: boolean;
  isDrawingLogicalConnectionFrom?: string;
  setIsDrawingLogicalConnectionFrom?: ActionCreatorWithOptionalPayload<
    string | undefined
  >;
  onClick?: (event: MouseEvent<HTMLElement> | undefined) => void;
  uid?: string;
  toggleSelectedItem: (uid: string) => void;
};

type UseNodeOnClickHandlerReturn = {
  handleOnClick: (args: HandleOnClick) => void;
};

export function useNodeOnClickHandler(): UseNodeOnClickHandlerReturn {
  const { redrawNodeConnections } = useConnection();
  const dispatch = useDispatch();
  const [create] = useCreateConnectionMutation();

  const handleOnClick = async ({
    event,
    onClick,
    isAddingLogicalConnection,
    isConnectionAllowed,
    isDrawingLogicalConnectionFrom,
    toggleSelectedItem,
    setIsDrawingLogicalConnectionFrom,
    uid,
    topologyUid,
  }: HandleOnClick) => {
    if (!isAddingLogicalConnection) {
      onClick && onClick(event);
    } else {
      if (!isConnectionAllowed) return;

      if (isDrawingLogicalConnectionFrom === undefined) {
        if (
          uid === undefined ||
          setIsDrawingLogicalConnectionFrom === undefined
        ) {
          dispatch(resetLogicalConnections());
        } else {
          setIsDrawingLogicalConnectionFrom(uid);

          const tempGuideLine = d3.select(
            `[data-uid="${TEMP_LOGICAL_GUIDE_UID}"]`,
          );

          tempGuideLine.attr('style', `display: block`);

          updateTempGuideLineFromAndToAttributes({
            from: uid,
            tempGuideLine,
            to: uid,
          });
          redrawNodeConnections(uid);
        }
      } else {
        if (uid === undefined) {
          dispatch(resetLogicalConnections());

          return;
        }
        const isDrawingLogicalConnectionTo = uid;
        if (isDrawingLogicalConnectionTo === isDrawingLogicalConnectionFrom)
          return;

        const tempGuideLine = d3.select(
          `[data-uid="${TEMP_LOGICAL_GUIDE_UID}"]`,
        );

        updateTempGuideLineFromAndToAttributes({
          from: isDrawingLogicalConnectionFrom,
          tempGuideLine,
          to: isDrawingLogicalConnectionTo,
        });

        redrawNodeConnections(isDrawingLogicalConnectionTo);
        redrawNodeConnections(isDrawingLogicalConnectionFrom);

        const nodesCanvas = d3.select('#nodesCanvas');
        setNodeCanvasCursorStyle(nodesCanvas, 'wait');
        removeNodeCanvasMouseMoveListener(nodesCanvas);

        const toSelection = d3.selectAll<SVGLineElement, unknown>(
          `[data-connection-from-to="${isDrawingLogicalConnectionFrom}-${isDrawingLogicalConnectionTo}"]`,
        );
        const fromSelection = d3.selectAll<SVGLineElement, unknown>(
          `[data-connection-from-to="${isDrawingLogicalConnectionTo}-${isDrawingLogicalConnectionFrom}"]`,
        );

        const { fromAttrToUseForNewConnection, toAttrToUseForNewConnection } =
          determineFromAndToAttributesForNewConnection({
            fromNodes: fromSelection.nodes(),
            isDrawingLogicalConnectionFrom,
            isDrawingLogicalConnectionTo,
            toNodes: toSelection.nodes(),
          });

        if (
          isLogicalConnectionLimitReached({
            fromNodes: fromSelection.nodes().length,
            toNodes: toSelection.nodes().length,
          })
        ) {
          dispatch(setShowAddLogicalConnectionLimitModal(true));
        } else {
          tempGuideLine.attr('style', `display: none`);

          const uid = await createLogicalConnection({
            createConnection: create,
            isDrawingLogicalConnectionFrom: fromAttrToUseForNewConnection,
            isDrawingLogicalConnectionTo: toAttrToUseForNewConnection,
            topologyUid,
          });

          uid && toggleSelectedItem(uid);
        }
        setNodeCanvasCursorStyle(nodesCanvas, 'default');
        resetTempGuideLine(tempGuideLine);
        redrawNodeConnections(isDrawingLogicalConnectionTo);
        redrawNodeConnections(isDrawingLogicalConnectionFrom);
        dispatch(resetLogicalConnections());
      }
    }
  };

  return {
    handleOnClick,
  };
}
