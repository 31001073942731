import React, { ReactElement } from 'react';
import { InfoModal } from '../../../../../../components/Modals/InfoModal';
import {
  buildTemplateInfoColsData,
  buildTemplateInfoModalFooter,
} from '../utils';
import { InventoryTemplatesTableInfoModalProps } from './container';

export function InventoryTemplatesInfoModal({
  inventoryTemplate,
  setShowMoreInfoForInventoryTemplate,
}: InventoryTemplatesTableInfoModalProps): ReactElement {
  return (
    <>
      {inventoryTemplate ? (
        <InfoModal
          size="large"
          colsContent={buildTemplateInfoColsData(inventoryTemplate)}
          title={inventoryTemplate.name!}
          show={true}
          footerContent={buildTemplateInfoModalFooter(inventoryTemplate)}
          onClose={() => setShowMoreInfoForInventoryTemplate(undefined)}
        />
      ) : null}
    </>
  );
}
