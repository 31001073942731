import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { ButtonGroup } from '../../../../components';
import { NavigationButton } from '../../../../components/Headers/EditHeader/components/NavigationButtons/NavigationButton';
import { TopologySVGIcon } from '../../../../components/SVGIcons/TopologySVGIcon';
import styles from './SessionViewNavigationButtons.module.css';
import { LocationPathname } from './index';

type DCloudSessionViewNavigationButtonsProps = LocationPathname &
  FetchSessionArgs;

export function DCloudSessionViewNavigationButtons({
  pathname,
  sessionUid,
  versionUid,
}: DCloudSessionViewNavigationButtonsProps): ReactElement {
  const SESSIONS = '/sessions/';
  const SESSIONS_INVENTORY = '/sessions-inventory/';

  const SESSION_VIEW_ROUTE = `${SESSIONS}${sessionUid}?versionUid=${versionUid}`;
  const SESSION_VIEW_INVENTORY_ROUTE = `${SESSIONS_INVENTORY}${sessionUid}?versionUid=${versionUid}`;

  const onInventoryPath = pathname.startsWith(SESSIONS_INVENTORY);

  return (
    <ButtonGroup className={styles.root}>
      <NavigationButton
        to={SESSION_VIEW_INVENTORY_ROUTE}
        selected={onInventoryPath}
        testId="session-view-navigation-button-inventory"
      >
        <Icon name="list-view" />
      </NavigationButton>
      <NavigationButton
        to={SESSION_VIEW_ROUTE}
        selected={!onInventoryPath}
        testId="session-view-navigation-button-session"
      >
        <TopologySVGIcon />
      </NavigationButton>
    </ButtonGroup>
  );
}
