export const willMemoryLimitBeExceeded = (
  originalValue: number,
  newMemoryMb: number,
  topologyLimit: TopologyLimit,
): boolean => {
  if (originalValue > newMemoryMb) {
    const diff = originalValue - newMemoryMb;
    return topologyLimit.current - diff > topologyLimit.limit;
  } else {
    const diff = newMemoryMb - originalValue;
    return topologyLimit.current + diff > topologyLimit.limit;
  }
};

export const willCpuLimitBeExceeded = (
  originalValue: number,
  newCpuQty: number,
  topologyLimit: TopologyLimit,
): boolean => {
  if (originalValue > newCpuQty) {
    const diff = originalValue - newCpuQty;
    return topologyLimit.current - diff > topologyLimit.limit;
  } else {
    const diff = newCpuQty - originalValue;
    return topologyLimit.current + diff > topologyLimit.limit;
  }
};

export function extractNetworkSubnets(
  vmNetworkInterfaces: VMNetworkInterface[],
): NetworkSubnet[] {
  return vmNetworkInterfaces
    .map((networkInterface) => {
      const { network } = networkInterface;
      if (network && network.inventoryNetwork) {
        const { subnet, type } = network.inventoryNetwork;
        return { subnet, type };
      }
      return undefined;
    })
    .filter((networkSubnet): networkSubnet is NetworkSubnet =>
      Boolean(networkSubnet),
    );
}
