import { format } from 'date-fns';
import i18n from '../../../../i18n';
import { TOPOLOGIES_LAST_UPDATED_FORMAT } from '../../../../entities/topologies/constants';

export const buildDemoTemplateColsData = (
  dataCenter: DataCenter,
  demoTemplate: DemoTemplate,
): InfoPanelData[] => {
  return [
    {
      data: buildDemoCol1Data(dataCenter, demoTemplate),
    },
    {
      data: buildDemoCol2Data(demoTemplate),
    },
  ];
};

export const buildDemoCol1Data = (
  dataCenter: DataCenter,
  demoTemplate: DemoTemplate,
): TemplateModalDataItem[] => {
  const { lastUpdated, lastUpdatedBy } = demoTemplate;

  return [
    {
      label: i18n.t('templateManager.demoTemplates.info.datacenter'),
      value: dataCenter,
    },
    {
      label: i18n.t('templateManager.demoTemplates.info.lastUpdated'),
      value: lastUpdated
        ? format(new Date(lastUpdated), TOPOLOGIES_LAST_UPDATED_FORMAT)
        : undefined,
    },
    {
      label: i18n.t('templateManager.demoTemplates.info.lastUpdatedBy'),
      value: lastUpdatedBy,
    },
  ];
};

export const buildDemoCol2Data = (
  demoTemplate: DemoTemplate,
): TemplateModalDataItem[] => {
  const { topology } = demoTemplate;

  return [
    {
      label: i18n.t('templateManager.demoTemplates.info.originalName'),
      value: topology?.name,
    },
    {
      label: i18n.t('templateManager.demoTemplates.info.demoId'),
      value: topology?.demoId,
    },
    {
      label: i18n.t('templateManager.demoTemplates.info.topologyId'),
      value: topology?.uid,
    },
  ];
};

export const prepDemoTemplateForEdit = (
  demoTemplate: DemoTemplate,
): DemoTemplate => {
  return {
    ...demoTemplate,
    architectureNames: demoTemplate.architectures?.map(
      (architecture) => architecture.name || '',
    ),
    datacenter: demoTemplate.topology?.dataCenter,
    demoId: demoTemplate.topology?.demoId,
  };
};

export const prepTemplateDemosForTable = (
  rawDemoTemplates: TemplatedDemo[],
): DemoTemplate[] =>
  rawDemoTemplates
    .map((rawDemo) => ({
      ...rawDemo.topologyTemplate,
      topology: rawDemo.topology,
    }))
    .map((combined) => ({
      ...combined,
      architectureNames: combined.architectures?.map(
        (architecture) => architecture.name || '',
      ),
      demoId: combined.topology.demoId,
      description: combined.description
        ? combined.description
        : combined.topology?.description || '',
      inventoryOnly: combined.name !== undefined,
      name: combined.name ? combined.name : combined.topology?.name || '',
    }));

export const prepDemoTemplatePayload = (
  formData: EditDemoTemplateFormData,
  templateToEdit: DemoTemplate,
  currentDc: DataCenter,
  selectedArchitectures?: Architecture[],
): DemoTemplate => {
  return {
    ...templateToEdit,
    architectures:
      selectedArchitectures === [] ? undefined : selectedArchitectures,
    contact: formData.contact === '' ? undefined : formData.contact,
    datacenter: currentDc,
    description: formData.description,
    name: formData.name,
    userEnabled: formData.userEnabled || false,
  };
};
