import i18n from '../../../../../../../../../../../i18n';

export const buildInternalIpGridData = ({
  internalAddress,
  privateAddress,
  description,
}: SessionInternalAddress): PanelGridDataItem[] => {
  return [
    {
      key: 'internal',
      name: i18n.t('sessions.panels.info.publicIpProxy.internalIpAddress'),
      value: internalAddress,
    },
    {
      key: 'private',
      name: i18n.t('sessions.panels.info.publicIpProxy.privateIpAddress'),
      value: privateAddress,
    },
    {
      key: 'desc',
      name: i18n.t('sessions.panels.info.publicIpProxy.description'),
      value: description,
    },
  ];
};
