import React, { ReactElement } from 'react';
import styles from './IconPanelLink.module.css';

export type IconPanelLinkProps = {
  link: DiagramAddIconFormFieldsDatum;
};

export function IconPanelLink({ link }: IconPanelLinkProps): ReactElement {
  return (
    <div key={link.id}>
      <a
        className={styles.link}
        href={link.value}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link.name}
      </a>
    </div>
  );
}
