import React, { ReactElement } from 'react';
import {
  CLOUD_INTEGRATION_ICONS,
  INTEGRATION_ICONS,
} from '../../../../../../Integrations/constants';
import i18n from '../../../../../../../i18n';
import { SAAS_INTEGRATION_TYPE } from '../../../../../../../constants';
import { getSelectedIconFromAvailableIcons } from '../../../../../../../utils/icons';

export const getSelectedIconForIntegrationType = (
  selectedIcon: string,
  integrationType: IntegrationType,
): ReactElement =>
  getSelectedIconFromAvailableIcons(
    selectedIcon,
    getAvailableIconsForIntegrationType(integrationType),
  );

export const getAvailableIconsForIntegrationType = (
  integrationType: IntegrationType | undefined,
): ReactElement[] =>
  (integrationType === SAAS_INTEGRATION_TYPE
    ? INTEGRATION_ICONS
    : CLOUD_INTEGRATION_ICONS
  ).map((icon) => (
    <icon.component
      key={
        i18n.t(`integrations.forms.common.icon.values.${icon.label}`) as string
      }
    />
  ));
