import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { LinkButton } from 'dcloud-shared-ui';
import classNames from 'classnames';
import { SEARCH_MIN_CHARS } from '../constants';
import { PageHeading } from '../../../../components/PageHeading';
import { Search } from '../../../../components/Search';
import { ConnectedDeleteSaasIntegrationModal } from './components/DeleteConfirmModal/container';
import IntegrationsTableContainer from './components/IntegrationsTable/container';
import styles from './SaasIntegrations.module.css';
import { determinePageTitle, searchIntegrations } from './utils/utils';

type SaasIntegrationsProps = {
  integrations: SaasIntegration[];
  integrationType: IntegrationType;
};

export function SaasIntegrations({
  integrations,
  integrationType,
}: SaasIntegrationsProps): ReactElement {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredItems, setFilteredItems] =
    useState<SaasIntegration[]>(integrations);

  const { t } = useTranslation();
  const match = useRouteMatch();

  useEffect(() => {
    document.title = t('siteTitle');
  }, [t]);

  const handleSearchChange = (newSearchValue: string): void => {
    setSearchValue(newSearchValue);
    if (newSearchValue.length === 0) {
      setFilteredItems(integrations);
    } else if (newSearchValue.length >= SEARCH_MIN_CHARS) {
      const searchText = newSearchValue.toLowerCase();
      setFilteredItems(searchIntegrations(integrations, searchText));
    }
  };
  return (
    <>
      <div className="half-padding-left">
        <PageHeading
          useCustomStyling={true}
          ctaBtn={
            <LinkButton
              size="wide"
              to={`${match.url}/add`}
              testId="saas-all-new-button"
              className={classNames(styles.addButton, 'btn--large')}
            >
              {t('buttons.add')}
            </LinkButton>
          }
          pageTitle={determinePageTitle(integrationType)}
        />
      </div>
      <div data-testid="integrations-table">
        <div className="row flex-right">
          <Search
            className="base-padding-left base-padding-bottom half-padding-right"
            id="integrations-search"
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder={t('common.search')}
            value={searchValue}
          />
        </div>
        <IntegrationsTableContainer
          integrations={filteredItems}
          integrationType={integrationType}
        />
      </div>
      <ConnectedDeleteSaasIntegrationModal />
    </>
  );
}
