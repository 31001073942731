import React, { ReactElement } from 'react';
import { TableLoader } from '../../../../components/Table/TableLoader';
import { useGetDemosByDCQuery } from '../../../../redux/configuration/demos/api';
import { DemoImport } from '.';

interface DemoImportContainerProps {
  datacenter: DataCenter;
  setDemoToImport: (demo: InventoryDemo) => void;
}

export function DemoImportContainer({
  datacenter,
  setDemoToImport,
}: DemoImportContainerProps): ReactElement | null {
  const { data: demos, isFetching, isError } = useGetDemosByDCQuery(datacenter);

  if (!demos) {
    return <TableLoader />;
  }

  return (
    <DemoImport
      demos={demos.inventoryDemos}
      setDemoToImport={setDemoToImport}
      isLoading={isFetching || isError}
    />
  );
}
