import { connect } from 'react-redux';
import {
  getVirtualMachines,
  setShowMoreInfoForVmTemplate,
} from '../../../../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../../../../../redux/store';
import { TemplatesTableInfoModal } from '.';

export type TemplatesTableInfoModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  vmTemplate: getVirtualMachines(state).showMoreInfoForVmTemplate,
});

const mapDispatchToProps = {
  setShowMoreInfoForVmTemplate,
};

export const TemplatesTableInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplatesTableInfoModal);
