import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';
import {
  Button,
  LoaderSpinner,
  ButtonsSizes,
  ButtonColours,
} from 'dcloud-shared-ui';
import styles from './PrimaryButton.module.css';

export interface PrimaryButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  colour?: ButtonColours;
  disabled?: boolean;
  loading?: boolean;
  size?: ButtonsSizes;
  testId?: string;
}

export function PrimaryButton({
  children,
  className,
  colour = 'primary',
  disabled = false,
  loading = false,
  size = 'large',
  testId,
  ...props
}: PrimaryButtonProps): ReactElement {
  const classes = classnames(className, styles.root, {
    [styles.loading]: loading,
  });

  return (
    <Button
      className={classes}
      colour={colour}
      size={size}
      disabled={disabled || loading}
      type="button"
      testId={testId}
      {...props}
    >
      {loading ? <LoaderSpinner size="small" /> : children}
    </Button>
  );
}
