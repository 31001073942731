import classNames from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';
import styles from './FilterColumn.module.css';

export type FilterColumnProps = PropsWithChildren<{
  title: string;
}>;

export const FilterColumn = ({
  title,
  children,
}: FilterColumnProps): ReactElement => {
  return (
    <div
      className={classNames('dbl-margin-left dbl-margin-right', styles.root)}
    >
      <span className={styles.title}>{title}</span>
      {children}
    </div>
  );
};
