import React, { ReactElement } from 'react';
import { HelpButton } from '../../../../../components/HelpButton';
import { HARDWARE_ADD_HELP_LINKS } from '../../../../../constants/user-guide';
import { useShowTopologyLimitsWarningDevices } from '../../../../../entities/hardware/Add/use-show-topology-limits-warning-devices';
import { AddHardwareLayout } from '../../../../../entities/hardware/Add/components/AddHardwareLayout';
import { AddHardwareProps } from './container';

export function Add({
  addHardware,
  hardware,
  isLoading,
  handleOnSelect,
  selectedHardwareItems,
  topologyLimitsWarningAccepted,
  topologyLimit,
  resetTopologyLimits,
  setShowTopologyLimitWarningForEntity,
}: AddHardwareProps): ReactElement {
  const { onAddClicked } = useShowTopologyLimitsWarningDevices({
    addHardware,
    resetTopologyLimits,
    selectedHardwareItems,
    setShowTopologyLimitWarningForEntity,
    topologyLimit,
    topologyLimitsWarningAccepted,
  });

  return (
    <>
      <AddHardwareLayout
        isLoading={isLoading}
        selectedHardwareItems={selectedHardwareItems}
        onAddClicked={onAddClicked}
        hardware={hardware}
        handleOnSelect={handleOnSelect}
      />
      <HelpButton links={HARDWARE_ADD_HELP_LINKS} />
    </>
  );
}
