import React, { ReactElement } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import classNames from 'classnames';
import { renameSessionSchema } from '../../../../../../../../validation';
import { ControlledTextfield } from '../../../../../../../../components';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import styles from '../../../Modals.module.css';
import { useSessionUserContext } from '../../../../../../../../hooks/use-session-user-context';

export interface RenameSessionFormProps {
  renameSession: RTKMutation<UpdateSessionPayload, Session>;
  isLoading: boolean;
  setShowRenameSessionModal: ActionCreatorWithPayload<boolean, string>;
}

export function RenameSessionForm({
  isLoading,
  renameSession,
  setShowRenameSessionModal,
}: RenameSessionFormProps): ReactElement {
  const { t } = useTranslation();
  const { errors, control, formState, handleSubmit } =
    useForm<SessionRenameFields>({
      defaultValues: { newName: '' },
      mode: 'all',
      resolver: yupResolver(renameSessionSchema),
    });

  const { sessionUid, versionUid } = useSessionUserContext();
  const { isDirty, isValid } = formState;

  const handleUpdate: SubmitHandler<SessionRenameFields> = async (formData) => {
    const { error } = await renameSession({
      sessionDetails: {
        id: sessionUid,
        name: formData.newName,
      },
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });

    !error && setShowRenameSessionModal(false);
  };
  return (
    <form>
      <ControlledTextfield<SessionRenameFields>
        control={control}
        error={errors?.newName?.message}
        label={t('sessions.forms.rename.newName.label')}
        labelClass="col-3"
        testId="rename-session"
        name="newName"
        required={true}
      />

      <div className={classNames(styles.buttonGroup, 'dbl-margin-top')}>
        <Button
          size="large"
          colour="ghost"
          type="button"
          onClick={() => setShowRenameSessionModal(false)}
        >
          {t('buttons.cancel')}
        </Button>
        <PrimaryButton
          disabled={!isDirty || !isValid}
          testId="rename-session-form-submit"
          loading={isLoading}
          type="submit"
          onClick={handleSubmit(handleUpdate)}
          colour="success"
        >
          {t('buttons.confirm')}
        </PrimaryButton>
      </div>
    </form>
  );
}
