import React, { ReactElement, ComponentPropsWithoutRef } from 'react';
import { FormGroup, FormGroupProps, FormGroupText } from 'dcloud-shared-ui';
import classnames from 'classnames';

export interface SelectOption<V = string> {
  name: string;
  value: V;
}

export type SelectProps = ComponentPropsWithoutRef<'select'> & {
  className?: string;
  errors?: FormGroupProps['errors'];
  inline?: FormGroupProps['inline'];
  label: string;
  labelClass: string;
  options: SelectOption[];
  required?: boolean;
  horizontal?: boolean;
  formGroupTextClass?: string;
  testId?: string;
};

export function Select({
  className,
  errors = [],
  id,
  inline = false,
  label,
  labelClass,
  options,
  required = false,
  horizontal = false,
  formGroupTextClass,
  ...rest
}: SelectProps): ReactElement {
  return (
    <FormGroup
      horizontal={horizontal}
      errors={errors}
      inline={inline}
      className={className}
    >
      <FormGroupText className={classnames(formGroupTextClass, 'select')}>
        <select id={id} {...rest}>
          {options.map((option) => (
            <option value={option.value} key={option.name}>
              {option.name}
            </option>
          ))}
        </select>
        <label className={labelClass} htmlFor={id}>
          {label} {required && '*'}
        </label>
      </FormGroupText>
    </FormGroup>
  );
}
