import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../../../../constants/form';
import { NetworkingFormData } from './components/NetworkingForm';

export function getNicTypeOptions(
  nicTypes: VmNetworkInterfaceType[],
): SelectOption<string>[] {
  return [
    PLEASE_SELECT_OPTION,
    ...nicTypes.map((nicType) => ({
      name: nicType.name,
      value: nicType.id,
    })),
  ];
}

export function findNetworkName(
  networks: Network[],
  formData: NetworkingFormData,
): string | undefined {
  return networks.find((network) => formData.networkName === network.uid)?.name;
}

export function findInventoryNetwork(
  networks: Network[],
  formData: NetworkingFormData,
): InventoryNetwork | undefined {
  return networks.find((network) => formData.networkName === network.uid)
    ?.inventoryNetwork;
}

function determineUnsavedNics(
  savedNics: VMNetworkInterface[],
  nicsToSave: VMNetworkInterface[],
  selectedNw: Network,
): number {
  return nicsToSave.filter(
    (nicToSave) =>
      nicToSave.network?.uid === selectedNw.uid &&
      !savedNics.find(
        (savedNic) =>
          savedNic.uid === nicToSave.uid &&
          savedNic.network?.uid === selectedNw.uid,
      ),
  ).length;
}

function determineCurrentNics(selectedNw: Network): number {
  return selectedNw.vmConnectionInfo.currentConnections.length;
}

function determineNicLimit(selectedNw: Network): number {
  return selectedNw.vmConnectionInfo.maxConnections;
}

function determineDeletedNics(
  savedNics: VMNetworkInterface[],
  nicsToSave: VMNetworkInterface[],
  selectedNw: Network,
) {
  return savedNics.filter(
    (savedNic) =>
      savedNic.network?.uid === selectedNw.uid &&
      !nicsToSave.find(
        (nicToSave) =>
          nicToSave.uid === savedNic.uid &&
          nicToSave.network?.uid === selectedNw.uid,
      ),
  ).length;
}

export function hasNicLimitReached(
  savedNics: VMNetworkInterface[],
  nicsToSave: VMNetworkInterface[],
  selectedNw: Network,
  isEditing: boolean,
): boolean {
  const totalNicsToSave =
    determineCurrentNics(selectedNw) +
    determineUnsavedNics(savedNics, nicsToSave, selectedNw) -
    determineDeletedNics(savedNics, nicsToSave, selectedNw);
  const limit = determineNicLimit(selectedNw);
  return isEditing ? totalNicsToSave > limit : totalNicsToSave >= limit;
}

export function hasDuplicateMacAddress(
  vmNetworkInterfaces: VmNetworkingToEdit,
): boolean {
  const macAddresses = vmNetworkInterfaces
    .filter((nic) => nic.macAddress && nic.macAddress.trim() !== '')
    .map((nic) => nic.macAddress.trim());

  const uniqueMacAddresses = new Set(macAddresses);

  return macAddresses.length !== uniqueMacAddresses.size;
}

export function hasDuplicateIpAddress(
  vmNetworkInterfaces: VmNetworkingToEdit,
): boolean {
  const ipAddresses = vmNetworkInterfaces
    .filter((nic) => nic.ipAddress && nic.ipAddress.trim() !== '')
    .map((nic) => ({
      ipAddress: nic.ipAddress.trim(),
      networkName: nic.network?.name,
    }));

  const duplicateIpAddresses = ipAddresses.filter((ipAddress, index) => {
    const currentIpAddress = ipAddress.ipAddress;
    const currentNetworkName = ipAddress.networkName;

    return (
      index !==
      ipAddresses.findIndex(
        (ip) =>
          ip.ipAddress === currentIpAddress &&
          ip.networkName === currentNetworkName,
      )
    );
  });

  return duplicateIpAddresses.length > 0;
}
