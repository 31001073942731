import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SelectOption } from 'dcloud-shared-ui';
import { useAddExternalDnsRecordMutation } from '../../../../../../redux/configuration/traffic/external-dns/api';
import { externalDnsScheme } from '../../../../../../validation';
import { buildAssetOptions } from '../../MailServer/utils';
import { buildExternalDnsPayload } from '../utils';
import { ExternalDnsAddForm } from '.';

interface ExternalDnsAddFormContainerProps {
  natTargets: SelectOption<string>[];
}

export function ExternalDnsAddFormContainer({
  natTargets,
}: ExternalDnsAddFormContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
  } = useForm<ExternalDnsFormData>({
    defaultValues: {
      asset: '',
      hostname: '',
      natRule: '',
    },
    mode: 'all',
    resolver: yupResolver(externalDnsScheme),
  });

  const [createExternalDnsRecord, { isError, isLoading }] =
    useAddExternalDnsRecordMutation();

  const handleCreate: SubmitHandler<ExternalDnsFormData> = async (formData) => {
    const payload = buildExternalDnsPayload({ formData, topologyUid });
    await createExternalDnsRecord(payload);

    !isError && resetForm();
  };

  return (
    <ExternalDnsAddForm
      assetOptions={buildAssetOptions([])}
      natRuleOptions={natTargets}
      control={control}
      errors={errors}
      formState={formState}
      handleCreate={handleCreate}
      handleSubmit={handleSubmit}
      isCreating={isLoading}
    />
  );
}
