import { connect } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {
  sessionInventorySelectors,
  clearHwMultiSelectErrorItems,
  clearVmMultiSelectErrorItems,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import { MultiActionFailureAlert } from '.';

type MultiActionFailureAlertContainerOwnProps = {
  page: 'vm' | 'hardware';
};

const mapDispatchToProps = {
  clearHwMultiSelectErrorItems,
  clearVmMultiSelectErrorItems,
};

const mapStateToProps = (
  state: RootState,
  ownProps: MultiActionFailureAlertContainerOwnProps,
) => ({
  multiSelectApiErrorItems:
    ownProps.page === 'vm'
      ? sessionInventorySelectors.getMultiSelectVmApiErrorItems(state)
      : sessionInventorySelectors.getMultiSelectHwApiErrorItems(state),
  page: ownProps.page,
  selectedAction: sessionInventorySelectors.getSelectedAction(state),
});

export const MultiActionFailureAlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiActionFailureAlert);

export type MultiActionFailureAlertProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
