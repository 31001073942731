import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { accessRoleAssignmentSchema } from '../../../../../../validation';
import { useAddAccessRoleAssignmentMutation } from '../../../../../../redux/configuration/sharing/api';
import { buildAccessRoleAssignmentPayload } from '../../utils';
import { AccessRoleAssignmentAddForm } from '.';

export function AccessRoleAssignmentAddFormContainer(): ReactElement {
  const { uid: topologyUid } = useParams<TopologyUid>();
  const {
    control,
    errors,
    formState,
    handleSubmit,
    reset: resetForm,
  } = useForm<AccessRoleAssignmentFormData>({
    defaultValues: {
      email: '',
    },
    mode: 'all',
    resolver: yupResolver(accessRoleAssignmentSchema),
  });

  const [createAccessRoleAssignment, { isLoading }] =
    useAddAccessRoleAssignmentMutation();
  const handleCreate: SubmitHandler<AccessRoleAssignmentFormData> = async (
    formData,
  ) => {
    const payload = buildAccessRoleAssignmentPayload({ formData, topologyUid });
    const response = await createAccessRoleAssignment(payload);
    if (!('error' in response) && 'data' in response) {
      resetForm();
    }
  };

  return (
    <AccessRoleAssignmentAddForm
      control={control}
      errors={errors}
      formState={formState}
      handleCreate={handleCreate}
      handleSubmit={handleSubmit}
      isCreating={isLoading}
    />
  );
}
