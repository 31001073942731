import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import {
  AuthStatusValues,
  config,
  getRefreshToken,
  removeToken,
  removeTokens,
  storeTokens,
} from '../../auth';
import * as cloudSSO from '../../api/sso/cloudSSO';
import { API_ERROR_CODES } from '../../utils/constants';
import { MainRoutes } from '../../constants/routes';
import { setAuthStatus, startAuth } from './slice';

export const refreshAuthTokens = async (
  api: BaseQueryApi,
): Promise<boolean> => {
  api.dispatch(setAuthStatus(AuthStatusValues.RefreshingTokens));
  removeToken(config.SSO.ACCESS_TOKEN_NAME);

  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const refreshingTokensResult = await cloudSSO.refreshAuthTokens(
        refreshToken,
      );

      if (refreshingTokensResult?.data.access_token) {
        storeTokens(
          refreshingTokensResult.data.access_token,
          refreshingTokensResult.data.refresh_token,
        );

        api.dispatch(setAuthStatus(AuthStatusValues.Authenticated));
        return true;
      }
    } catch (refreshTokensAxiosError) {}
  }

  removeTokens();
  api.dispatch(setAuthStatus(AuthStatusValues.NotAuthenticated));
  api.dispatch(startAuth());

  return false;
};

export const handleAuthError = (
  error: AxiosError,
  api: BaseQueryApi,
  startFullAuth: boolean,
): void => {
  if (error.response?.status === API_ERROR_CODES.NOT_AUTHORIZED) {
    removeTokens();
    api.dispatch(setAuthStatus(AuthStatusValues.NotAuthenticated));

    if (startFullAuth) {
      api.dispatch(startAuth());
    } else {
      api.dispatch(push(MainRoutes.NotAuthorisedGenericError));
    }
  }
};
