import React, { ReactElement } from 'react';
import styles from './DropdownItem.module.css';

export interface DropdownItemProps {
  href?: string;
  onClick?: VoidFunction;
  label: string;
}

export const DropdownItem = ({
  href,
  onClick,
  label,
}: DropdownItemProps): ReactElement => {
  if (href) {
    return (
      <a target="_blank" rel="noreferrer noopener" href={href}>
        {label}
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        data-testid="actions-dropdown-option-button"
        className={styles.dropdownItemButton}
        onClick={onClick}
      >
        {label}
      </button>
    );
  }

  return (
    <div className="cursorNotAllowed">
      <button className={styles.disabledDropdownItemButton} disabled={true}>
        {label}
      </button>
    </div>
  );
};
