import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';
import {
  useGetAllQuery,
  useGetVmsQuery,
} from '../../../../../../../../../redux/configuration/virtual-machine-entities/api';
import { RootState } from '../../../../../../../../../redux/store';
import {
  getVirtualMachines,
  setDemoVirtualMachineToConfigure,
  setShowRemoteAccessCountExceededModal,
} from '../../../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { calculateTopologyRemoteAccessCount } from '../../../../../../components/VirtualMachineEntities/utils';
import { VmsTable } from '.';

function VmsTableContainer({
  selectedTopologyUid,
  setDemoVirtualMachineToConfigure,
  setShowRemoteAccessCountExceededModal,
  isDiagramView,
}: VmsTableContainerProps): ReactElement {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    data: virtualMachines = [],
    isLoading,
    isFetching,
    isError,
  } = useGetVmsQuery(selectedTopologyUid!);

  const {
    topologyRemoteAccessCount,
    isError: isErrorAllVms,
    isLoading: isLoadingAllVms,
    isFetching: isFetchingAllVms,
  } = useGetAllQuery(topologyUid, {
    selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
      isError,
      isFetching,
      isLoading,
      topologyRemoteAccessCount: calculateTopologyRemoteAccessCount(data),
    }),
  });

  if (!isLoading && !isFetching && !isError && virtualMachines.length < 1) {
    return (
      <div className="dbl-margin-top">
        <Alert>{t('inventoryTopologies.vms.table.noResultsAlert')}</Alert>
      </div>
    );
  }

  if (!selectedTopologyUid && !isDiagramView) {
    return <Redirect to={url.substring(0, url.lastIndexOf('/add'))} />;
  }

  return (
    <VmsTable
      isLoading={
        isLoading ||
        isFetching ||
        isError ||
        isLoadingAllVms ||
        isFetchingAllVms ||
        isErrorAllVms
      }
      virtualMachines={virtualMachines}
      setDemoVirtualMachineToConfigure={setDemoVirtualMachineToConfigure}
      selectedTopologyUid={selectedTopologyUid!}
      topologyRemoteAccessCount={topologyRemoteAccessCount}
      setShowRemoteAccessCountExceededModal={
        setShowRemoteAccessCountExceededModal
      }
      isDiagramView={isDiagramView}
    />
  );
}

type ConnectedVmsTableOwnProps = {
  isDiagramView: boolean;
};

const mapStateToProps = (
  state: RootState,
  ownProps: ConnectedVmsTableOwnProps,
) => ({
  isDiagramView: ownProps.isDiagramView,
  selectedTopologyUid: getVirtualMachines(state).selectedTopologyUid,
});

const mapDispatchToProps = {
  setDemoVirtualMachineToConfigure,
  setShowRemoteAccessCountExceededModal,
};

export type VmsTableContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type VmsTableRTKProps = {
  isLoading: boolean;
  virtualMachines: VirtualMachine[];
};

export type VirtualMachinesProps = VmsTableContainerProps & VmsTableRTKProps;

export const ConnectedVmsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VmsTableContainer);
