import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface TopologiesState {
  shouldConfirmDelete?: boolean;
  topologyToClone?: Topology;
  topologyToDelete?: Topology;
  topologyToEdit?: Topology;
  topologyToSave?: Topology;
  topologyToUpdate?: Topology;
}

export const initialState: TopologiesState = {
  shouldConfirmDelete: undefined,
  topologyToClone: undefined,
  topologyToDelete: undefined,
  topologyToEdit: undefined,
  topologyToSave: undefined,
  topologyToUpdate: undefined,
};

export const topologiesSlice = createSlice({
  initialState,
  name: 'topologies',
  reducers: {
    clearTopologyToClone: (state) => {
      state.topologyToClone = undefined;
    },
    clearTopologyToDelete: (state) => {
      state.topologyToDelete = undefined;
      state.shouldConfirmDelete = undefined;
    },
    clearTopologyToSave: (state) => {
      state.topologyToSave = undefined;
    },
    clearTopologyToUpdate: (state) => {
      state.topologyToUpdate = undefined;
    },
    reset: () => initialState,
    setShouldConfirmDelete: (state, action: PayloadAction<boolean>) => {
      state.shouldConfirmDelete = action.payload;
    },
    setTopologyToClone: (state, action: PayloadAction<Topology>) => {
      state.topologyToClone = action.payload;
    },
    setTopologyToDelete: (state, action: PayloadAction<Topology>) => {
      state.topologyToDelete = action.payload;
    },
    setTopologyToEdit: (state, action: PayloadAction<Topology>) => {
      state.topologyToEdit = action.payload;
    },
    setTopologyToSave: (state, action: PayloadAction<Topology>) => {
      state.topologyToSave = action.payload;
    },
    setTopologyToUpdate: (state, action: PayloadAction<Topology>) => {
      state.topologyToUpdate = action.payload;
    },
  },
});

export const {
  reducer: topologiesReducer,
  actions: {
    clearTopologyToClone,
    clearTopologyToDelete,
    clearTopologyToSave,
    clearTopologyToUpdate,
    reset,
    setShouldConfirmDelete,
    setTopologyToClone,
    setTopologyToDelete,
    setTopologyToEdit,
    setTopologyToSave,
    setTopologyToUpdate,
  },
} = topologiesSlice;

const getTopologies = (state: RootState) => state.topologies;

export const topologiesSelectors = {
  getShouldConfirmDelete: createSelector(
    getTopologies,
    ({ shouldConfirmDelete }) => shouldConfirmDelete,
  ),
  getTopologyToClone: createSelector(
    getTopologies,
    ({ topologyToClone }) => topologyToClone,
  ),
  getTopologyToDelete: createSelector(
    getTopologies,
    ({ topologyToDelete }) => topologyToDelete,
  ),
  getTopologyToEdit: createSelector(
    getTopologies,
    ({ topologyToEdit }) => topologyToEdit,
  ),
  getTopologyToSave: createSelector(
    getTopologies,
    ({ topologyToSave }) => topologyToSave,
  ),
  getTopologyToUpdate: createSelector(
    getTopologies,
    ({ topologyToUpdate }) => topologyToUpdate,
  ),
};
