import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { expoSessionApi } from '../../../../../../redux/views/session-view/session/api/expoApi';
import { filterIntegrationsByType } from '../../../../../../utils/integrations';
import { TAGS } from '../../../../../../redux/api/constants';
import { IntegrationPanelContainerProps } from './container';
import { IntegrationPanel } from '.';

function ExpoIntegrationPanel({
  engagementUid,
  integrationType,
  onIntegrationTypeChange,
  sessionUid,
  versionUid,
}: ExpoIntegrationPanelProps): ReactElement {
  const { sessionInformation, isError, isLoading, isFetching } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionInformation: data?.sessionDetails,
        }),
      },
    );
  return (
    <IntegrationPanel
      sessionIntegrationItems={filterIntegrationsByType(
        sessionInformation?.saasIntegrations,
        integrationType,
      )}
      isLoading={isLoading || isFetching}
      isError={isError}
      integrationType={integrationType}
      onIntegrationTypeChange={onIntegrationTypeChange}
    />
  );
}

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => ({
  onIntegrationTypeChange: () =>
    dispatch(expoSessionApi.util.invalidateTags([TAGS.SESSIONS])),
});

type ExpoIntegrationPanelProps = IntegrationPanelContainerProps &
  FetchExpoSessionArgs &
  ReturnType<typeof mapDispatchToProps>;

export const ExpoIntegrationPanelContainer = connect(
  undefined,
  mapDispatchToProps,
)(ExpoIntegrationPanel);
