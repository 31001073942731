import React, { ReactElement } from 'react';
import { useGetSessionQuery } from '../../../redux/views/session-view/session/api/api';
import { ConnectedSessionProps } from './container';
import { Session } from '.';

type ConnectedDCloudSessionProps = ConnectedSessionProps & FetchSessionArgs;

export const ConnectedDCloudSession = ({
  showPage,
  unsubscribeFromGetAllVmStatusesData,
  resetMultiSelect,
  sessionUid,
  versionUid,
}: ConnectedDCloudSessionProps): ReactElement => {
  useGetSessionQuery({ sessionUid, versionUid });

  return (
    <Session
      resetMultiSelect={resetMultiSelect}
      showPage={showPage}
      unsubscribeFromGetAllVmStatusesData={unsubscribeFromGetAllVmStatusesData}
    />
  );
};
