import React, { PropsWithChildren, ReactElement, useState } from 'react';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { Icon } from 'dcloud-shared-ui';
import styles from './Dropdown.module.css';
import { useHandleOutsideClick } from './hooks/useHandleOutsideClick';
import { calculateLeftOffset, calculateTopOffset } from './utils/utils';

type DropdownProps = PropsWithChildren<{
  leftOffsetElement?: string;
  testId?: string;
  isDisabled?: boolean;
}>;

export const Dropdown = ({
  children,
  leftOffsetElement = 'ag-body-viewport',
  testId = 'actions-dropdown-button',
  isDisabled,
}: DropdownProps): ReactElement => {
  const [showToggleMenu, setShowToggleMenu] = useState<boolean>(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const dropdownButtonRef = React.useRef<HTMLButtonElement>(null);
  const wrapperLeftOffset =
    document.getElementsByClassName(leftOffsetElement)[0].scrollLeft;

  useHandleOutsideClick({ dropdownRef, setShowToggleMenu });

  const { left = 0, top = 0 } =
    dropdownButtonRef.current?.getBoundingClientRect() || {};

  return (
    <div className={classnames(styles.dropdownButton)}>
      <button
        aria-label="Dropdown Button"
        className={styles.menuButton}
        onClick={() => setShowToggleMenu(!showToggleMenu)}
        ref={dropdownButtonRef}
        data-testid={testId}
        disabled={isDisabled}
      >
        <span>
          <Icon name="more" />
        </span>
      </button>
      {showToggleMenu &&
        createPortal(
          <div
            ref={dropdownRef}
            className={classnames(styles.root, 'tableDropdown')}
            data-testid="actions-dropdown-menu"
          >
            <div
              className={classnames('dropdown', {
                active: showToggleMenu,
                [styles.activeDropDown]: showToggleMenu,
              })}
              style={{
                left: calculateLeftOffset(left, wrapperLeftOffset),
                top: calculateTopOffset(
                  top,
                  document.documentElement.scrollTop || document.body.scrollTop,
                ),
              }}
            >
              <div
                className={classnames('dropdown__menu', {
                  [styles.activeDropDownMenu]: showToggleMenu,
                })}
              >
                {children}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};
