import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'dcloud-shared-ui';
import classNames from 'classnames';
import { Search } from '../../components/Search';
import { SEARCH_MIN_CHARS } from '../EditTopology/configuration/constants';
import PageHero from '../../components/PageHero';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import styles from './Integrations.module.css';
import { IntegrationsTable } from './components/IntegrationsTable';
import { IntegrationsFilterContainer } from './components/IntegrationsFilter/container';
import { ConnectedCreateIntegrationModal } from './components/CreateIntegrationModal/container';
import { ConnectedConfirmDeleteAdminIntegrationModal } from './components/ConfirmDeleteAdminIntegrationModal/container';
import { IntegrationsProps } from './container';
import { useResetIntegrationsOnUnmount } from './hooks/useResetIntegrationsOnUnmount';
import { ConnectedIntegrationInUseInfoModal } from './components/IntegrationInUseInfoModal/container';

export function Integrations({
  reset,
  integrations,
  setIntegrationToDelete,
  setIntegrationToDisplayUid,
  filteredIntegrations,
  setSearchValue,
  integrationToDisplayUid,
}: IntegrationsProps): ReactElement {
  useScrollToTop();

  const [localSearchValue, setLocalSearchValue] = useState<string>('');
  const handleSearchChange = (newSearchValue: string): void => {
    setLocalSearchValue(newSearchValue);
  };

  useEffect(() => {
    setSearchValue(localSearchValue);
  }, [localSearchValue, setSearchValue]);

  useResetIntegrationsOnUnmount({ reset });

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <DefaultLayout>
      <PageHero title={t('integrations.title')} />
      <div className={styles.container}>
        <div className={styles.wrapper} data-testid="integrations-table">
          <div className={styles.table}>
            <div
              className={classNames(
                styles.integrationsHeaderContainer,
                'row flex-right',
              )}
            >
              <div className="flex base-margin">
                <Button
                  className={styles.addFormButtons}
                  onClick={() => setShow(true)}
                >
                  {t('buttons.new')}
                </Button>
              </div>
              <div
                className={classNames(
                  styles.searchContainer,
                  'flex base-margin',
                )}
              >
                <Search
                  id="integrations-search"
                  onChange={(e) => handleSearchChange(e.target.value)}
                  placeholder={t('common.search')}
                  value={localSearchValue}
                />
                {localSearchValue.length > 0 &&
                  localSearchValue.length < SEARCH_MIN_CHARS && (
                    <span className={styles.searchWarning}>
                      {t('integrations.searchWarning')}
                    </span>
                  )}
              </div>
            </div>
            <IntegrationsFilterContainer />
            {filteredIntegrations.length > 0 ? (
              <IntegrationsTable
                integrations={filteredIntegrations}
                setIntegrationToDelete={setIntegrationToDelete}
                setIntegrationToDisplayUid={setIntegrationToDisplayUid}
              />
            ) : (
              <Alert>{t('integrations.filterWarning')}</Alert>
            )}
          </div>
        </div>
        {show && (
          <ConnectedCreateIntegrationModal
            integrations={integrations}
            onClose={() => setShow(false)}
          />
        )}
        <ConnectedConfirmDeleteAdminIntegrationModal />
        {integrationToDisplayUid && <ConnectedIntegrationInUseInfoModal />}
      </div>
    </DefaultLayout>
  );
}
