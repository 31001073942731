import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { Search } from '../../../../../components/Search';
import { useFilter } from '../../../../../hooks/use-filter';
import { Table } from '../../Table';
import { HeaderPrimaryButton } from '../HeaderPrimaryButton';
import styles from './AddHardwareLayout.module.css';

type AddHardwareLayoutProps = {
  isLoading: boolean;
  selectedHardwareItems: InventoryHardwareItemToAdd[];
  onAddClicked: VoidFunction;
  hardware: InventoryHardwareItem[] | undefined;
  handleOnSelect: (
    isSelected: boolean,
    hardwareItem: InventoryHardwareItem,
  ) => void;
};

export const AddHardwareLayout = ({
  isLoading,
  selectedHardwareItems,
  onAddClicked,
  hardware,
  handleOnSelect,
}: AddHardwareLayoutProps): ReactElement => {
  const { t } = useTranslation();

  const { filteredItems, handleSearchChange, searchValue } = useFilter({
    filterKeys: ['name', 'description'],
    items: hardware!,
  });

  return (
    <>
      <PageHeading
        withBackBtn={false}
        pageTitle={t('hardware.physicalHardware.add.title')}
        ctaBtn={
          <div className={styles.wrapper}>
            <HeaderPrimaryButton
              isLoading={isLoading}
              selectedHardwareItems={selectedHardwareItems}
              onAddClicked={onAddClicked}
            />
          </div>
        }
      />
      <div className="row flex-right">
        <Search
          className="base-padding"
          id="add-hw-search"
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder={t('common.search')}
          value={searchValue}
        />
      </div>
      <Table
        inventoryHardwareItems={filteredItems || []}
        isLoading={isLoading}
        handleOnSelect={handleOnSelect}
        selectedHardwareItems={selectedHardwareItems}
      />
    </>
  );
};
