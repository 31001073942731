import { useEffect } from 'react';
import { NetworkingFormData } from '../../NetworkingForm';

type UseTriggerIpValidation = {
  trigger: (
    name?: keyof NetworkingFormData | (keyof NetworkingFormData)[] | undefined,
  ) => Promise<boolean>;
  watchNetworkName: string;
};

export const useTriggerIpValidation = ({
  trigger,
  watchNetworkName,
}: UseTriggerIpValidation): void => {
  useEffect(() => {
    trigger('ipAddress');
  }, [trigger, watchNetworkName]);
};
