import { useCallback, useEffect } from 'react';
import * as d3 from 'd3';
import { x, y, zoom } from '../../../../../d3';
import { useWindowResize } from '../../../../../../../hooks/use-window-resize';
import { DefaultBoxNodeDisplay } from '../../../../../Diagram/components/DiagramSideMenu/components/DiagramSideMenuButtonAddBox/constants';

interface UsePosition {
  nodeRef: React.RefObject<HTMLDivElement>;
  uid: string;
}

export function usePosition({ nodeRef, uid }: UsePosition): void {
  const position = useCallback(() => {
    d3.select<HTMLDivElement, DiagramNode>(nodeRef.current!).attr(
      'style',
      function () {
        const transform = d3.zoomTransform(
          d3.select<HTMLDivElement, unknown>('#canvas').node()!,
        );
        const zx = transform.rescaleX(x);
        const zy = transform.rescaleY(y);
        const left = zx(x.invert(transform.invertX(DefaultBoxNodeDisplay.x)));
        const top = zy(y.invert(transform.invertY(DefaultBoxNodeDisplay.y)));
        return `transform: translate(${left - 15 * transform.k}px, ${
          top - 15 * transform.k
        }px) scale(${transform.k})`;
      },
    );
  }, [nodeRef]);

  useWindowResize(() => {
    position();
  });

  useEffect(() => {
    position();

    zoom.on(`zoom.${uid}`, function () {
      position();
    });

    return () => {
      zoom.on(`zoom.${uid}`, null);
    };
  }, [position, uid]);
}
