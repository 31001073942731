import React, { ReactElement } from 'react';
import {
  expoSessionApi,
  useGetExpoSessionVmStatusQuery,
} from '../../../../../../../../redux/views/session-view/session/api/expoApi';
import { getMorIdFromSessionDetails } from '../../../../../utils';
import { getInfoFromNodeDetails } from './utils';
import { InfoContainerProps } from './container';
import { Info } from '.';

type ConnectedExpoInfoProps = InfoContainerProps & FetchExpoSessionArgs;

export function ConnectedExpoInfo({
  engagementUid,
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedExpoInfoProps): ReactElement {
  const { staticVmInfo, mor } =
    expoSessionApi.endpoints.getExpoSession.useQueryState(
      { engagementUid, sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          mor: getMorIdFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionVmNode
            ).sessionDetails,
          ),
          staticVmInfo: getInfoFromNodeDetails(
            data?.diagram.nodes.filter(
              (node) => node.uid === nodeUid,
            )[0] as SessionVmNode,
          ),
        }),
      },
    );

  const { dynamicVmInfo, isLoadingDynamicVmInfo, isFetchingDynamicVmInfo } =
    useGetExpoSessionVmStatusQuery(
      { engagementUid, mor, sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching }) => ({
          dynamicVmInfo: {
            guestOs: data?.vmwareState.guestState,
            vmWareTools: data?.vmwareState.guestToolsState,
          },
          isFetchingDynamicVmInfo: isFetching,
          isLoadingDynamicVmInfo: isLoading,
        }),
      },
    );

  return (
    <Info
      staticVmInfo={staticVmInfo}
      dynamicVmInfo={dynamicVmInfo}
      isLoadingDynamicVmInfo={isLoadingDynamicVmInfo || isFetchingDynamicVmInfo}
    />
  );
}
