import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { useShareSessionMutation } from '../../../../../../redux/views/session-view/session/api/api';
import {
  addSharedUser,
  removeSharedUser,
  sessionViewSelectors,
  setLeftPanelType,
} from '../../../../../../redux/views/session-view/session/slice';
import { SharePanel } from '.';

function SharePanelContainer({
  addSharedUser,
  removeSharedUser,
  sharedWithUsers,
  setLeftPanelType,
}: SharePanelContainerProps): ReactElement {
  const [shareSession, { isLoading }] = useShareSessionMutation();

  return (
    <SharePanel
      addUser={addSharedUser}
      removeUser={removeSharedUser}
      users={sharedWithUsers || []}
      shareSession={shareSession}
      isLoading={isLoading}
      setLeftPanelType={setLeftPanelType}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  sharedWithUsers: sessionViewSelectors.getSharedWithUsers(state),
});

const mapDispatchToProps = {
  addSharedUser,
  removeSharedUser,
  setLeftPanelType,
};

export type SharePanelContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedSharePanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SharePanelContainer);
