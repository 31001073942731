import React, { ReactElement } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import {
  getVirtualMachines,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setInterfaceToConfirmDelete,
  setNicToEdit,
  updateVMNetworkInterface,
} from '../../../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../../../redux/store';
import { useGetNetworksQuery } from '../../../../../../../../redux/configuration/networks/api';
import { useGetVmNetworkInterfaceTypesQuery } from '../../../../../../../../redux/configuration/virtual-machine-entities/api';
import { getNicTypes } from '../../../../../../../../redux/entities/nic-types/slice';
import { addErrorToast } from '../../../../../../../../redux/toast/slice';
import { useGetLimitsQuery } from '../../../../../../../../redux/limitations/api';
import { Networking } from '.';

function NetworkingContainer({
  addErrorToast,
  setInterfaceToConfirmDelete,
  interfaceToConfirmDelete,
  networkingToEdit,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setNicToEdit,
  nicToEdit,
  nicTypes,
  updateVMNetworkInterface,
  vmToEdit,
}: NetworkingContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { isLoading: isLoadingNicTypes, isError: isErrorLoadingNicTypes } =
    useGetVmNetworkInterfaceTypesQuery(undefined, {
      skip: nicTypes !== undefined,
    });

  const {
    data: networks,
    isLoading: isLoadingNetworks,
    isError: isErrorLoadingNetworks,
  } = useGetNetworksQuery(topologyUid);

  const {
    data: limitsData,
    isLoading: isLimitsLoading,
    isError: isLimitsError,
  } = useGetLimitsQuery(undefined);
  return (
    <Networking
      networkingToEdit={networkingToEdit}
      isErrorLoadingNicTypes={isErrorLoadingNicTypes}
      isErrorLoadingNetworks={isErrorLoadingNetworks}
      addErrorToast={addErrorToast}
      addVMNetworkInterface={addVMNetworkInterface}
      deleteVMNetworkInterface={deleteVMNetworkInterface}
      networks={networks}
      nicToEdit={nicToEdit}
      nicTypes={nicTypes}
      setNicToEdit={setNicToEdit}
      isLoadingLimits={isLimitsLoading || isLimitsError}
      isLoadingNicTypes={isLoadingNicTypes}
      isLoadingNetworks={isLoadingNetworks}
      interfaceToConfirmDelete={interfaceToConfirmDelete}
      limits={limitsData}
      setInterfaceToConfirmDelete={setInterfaceToConfirmDelete}
      updateVMNetworkInterface={updateVMNetworkInterface}
      vmToEdit={vmToEdit}
    />
  );
}

type NetworkingContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

interface NetworkingRTKProps {
  isErrorLoadingNicTypes: boolean;
  isErrorLoadingNetworks: boolean;
  networks?: Network[];
  nicTypes?: VmNetworkInterfaceType[];
  isLoadingLimits: boolean;
  isLoadingNicTypes: boolean;
  isLoadingNetworks: boolean;
  limits?: Limits;
}

export type NetworkingProps = NetworkingContainerProps & NetworkingRTKProps;

const mapStateToProps = (state: RootState) => ({
  interfaceToConfirmDelete: getVirtualMachines(state).interfaceToConfirmDelete,
  networkingToEdit: getVirtualMachines(state).networkingToEdit,
  nicToEdit: getVirtualMachines(state).nicToEdit,
  nicTypes: getNicTypes(state).nicTypes,
  vmToEdit: getVirtualMachines(state).virtualMachineToEdit,
});

const mapDispatchToProps = {
  addErrorToast,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setInterfaceToConfirmDelete,
  setNicToEdit,
  updateVMNetworkInterface,
};

export const ConnectedNetworking = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NetworkingContainer);
