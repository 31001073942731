import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Footer } from 'dcloud-shared-ui';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { EditHeader } from '../../components/Headers/EditHeader';
import { ConnectedSaveCustomContentModal } from '../Topologies/components/SaveCustomContentModal/container';
import { FOOTER_LINKS } from '../../constants';
import { TopologyLimitsWarningModalContainer } from '../../entities/topologies/components/TopologyLimitsWarningModal/container';
import { EditTopologyRouter, EditTopologyRoutes } from './EditTopologyRouter';
import styles from './EditTopology.module.css';
import { ConnectedTopologyLimitsToolBar } from './components/TopologyLimitsToolBar/container';

export function EditTopology(): ReactElement {
  const { t } = useTranslation();
  useScrollToTop();
  const { pathname } = useLocation();
  const shouldShowFooters = !pathname.includes(EditTopologyRoutes.Diagram);

  useEffect(() => {
    document.title = t('siteTitle');
  }, [t]);

  if (!shouldShowFooters) {
    return (
      <>
        <EditHeader />
        <ConnectedSaveCustomContentModal />
        <EditTopologyRouter />
      </>
    );
  }

  return (
    <>
      <EditHeader />
      <div className={styles.root}>
        <div className={styles.content}>
          <ConnectedSaveCustomContentModal />
          <EditTopologyRouter />
        </div>
        <ConnectedTopologyLimitsToolBar />
        <Footer className={styles.footer} links={FOOTER_LINKS} />
      </div>
      <TopologyLimitsWarningModalContainer />
    </>
  );
}
