import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef } from 'ag-grid-community';
import { Alert } from 'dcloud-shared-ui';
import { GridEditor } from '../../../../../../../components/GridEditor';
import { tagComparator } from '../../../../../../Integrations/utils/utils';
import { AddCellRendererContainer } from '../AddCellRenderer/container';
import { DescriptionCellRenderer } from '../DescriptionCellRenderer';
import { Search } from '../../../../../../../components/Search';
import { TagsCellRenderer } from '../../../components/TagsCellRenderer';
import { searchInventorySaasTemplates } from './utils';

type InventorySaasTemplatesTableProps = {
  isLoading: boolean;
  inventorySaasTemplates?: SaasTemplate[];
};

export const InventorySaasTemplatesTable = ({
  isLoading,
  inventorySaasTemplates,
}: InventorySaasTemplatesTableProps): ReactElement => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<SaasTemplate[]>(
    inventorySaasTemplates || [],
  );

  useEffect(() => {
    setFilteredItems(inventorySaasTemplates || []);
  }, [inventorySaasTemplates]);

  const handleSearchChange = (newSearchValue: string): void => {
    setSearchValue(newSearchValue);
    if (newSearchValue.length === 0) {
      setFilteredItems(inventorySaasTemplates || []);
    } else if (newSearchValue.length >= 3) {
      const searchText = newSearchValue.toLowerCase();
      setFilteredItems(
        searchInventorySaasTemplates(inventorySaasTemplates || [], searchText),
      );
    }
  };

  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellStyle: {
        'overflow-wrap': 'anywhere',
        'white-space': 'normal',
      },
      field: 'name',
      flex: 1,
      headerName: t('saas.add.table.headings.name'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellRenderer: 'description',
      cellStyle: { 'white-space': 'normal' },
      field: 'description',
      flex: 3,
      headerName: t('saas.add.table.headings.description'),
      minWidth: 500,
    },
    {
      autoHeight: true,
      cellRenderer: 'tags',
      cellStyle: { 'white-space': 'normal' },
      comparator: tagComparator,
      field: 'saasTemplate.tags',
      flex: 1,
      headerName: t('saas.add.table.headings.tags'),
      minWidth: 250,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'maxAmountPerTopology',
      flex: 1,
      headerName: t('saas.add.table.headings.maxPerTopology'),
      minWidth: 200,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'configuredQuantity',
      flex: 1,
      headerName: t('saas.add.table.headings.configured'),
      minWidth: 110,
    },
    {
      autoHeight: true,
      cellRenderer: 'addCellRenderer',
      cellStyle: { 'white-space': 'normal' },
      field: '',
      flex: 1,
      headerName: '',
      minWidth: 100,
    },
  ];

  if (
    !isLoading &&
    inventorySaasTemplates &&
    inventorySaasTemplates.length > 0
  ) {
    return (
      <>
        <div className="row flex-right">
          <Search
            className="base-padding-right base-padding-bottom"
            id="saas-integrations-add-search"
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder={t('common.search')}
            value={searchValue}
          />
        </div>
        {filteredItems.length > 0 ? (
          <GridEditor
            columns={columns}
            data={filteredItems}
            customComponents={{
              addCellRenderer: AddCellRendererContainer,
              description: DescriptionCellRenderer,
              tags: TagsCellRenderer,
            }}
          />
        ) : (
          <Alert>{t('saas.add.noItemsSearchResult')}</Alert>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
