import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../../../components';
import { useDeleteMailServerMutation } from '../../../../../../../redux/configuration/traffic/mail-server/api';

interface MailServerDeleteModalProps {
  setMailServerToDelete: (mailServer?: MailServer) => void;
  mailServer: MailServer;
}
export function MailServerDeleteModal({
  setMailServerToDelete,
  mailServer,
}: MailServerDeleteModalProps): ReactElement {
  const { t } = useTranslation();
  const [deleteMailServer, { isLoading, isSuccess, isError }] =
    useDeleteMailServerMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setMailServerToDelete(undefined);
    }
  }, [isError, isSuccess, setMailServerToDelete]);

  return (
    <ConfirmModal
      heading={t('traffic.mailServer.delete.heading')}
      subHeading={t('traffic.mailServer.delete.subHeading')}
      loading={isLoading}
      onCancel={() => setMailServerToDelete(undefined)}
      onClose={() => setMailServerToDelete(undefined)}
      onConfirm={() => deleteMailServer(mailServer.uid)}
      confirmButtonLabel={t('buttons.delete')}
      show={true}
      size="small"
      testId="mail-server-delete-modal"
    />
  );
}
