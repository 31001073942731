import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../../../../redux/views/session-view/session/api/api';
import { PublicIP } from '.';

export function ConnectedDCloudPublicIP({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const { sessionPublicAddresses, isLoading, isFetching, isError } =
    sessionApi.endpoints.getSession.useQueryState(
      {
        sessionUid,
        versionUid,
      },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionPublicAddresses: data?.sessionDetails.sessionPublicAddresses,
        }),
      },
    );

  return (
    <PublicIP
      sessionPublicAddresses={sessionPublicAddresses}
      isLoading={isLoading || isFetching}
      isError={isError}
    />
  );
}
