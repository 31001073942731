import React, { ReactElement, useState } from 'react';
import { TabPanels } from '../TabPanels';
import { TabsItems } from '../TabsItems';

export type AlignTypes = 'left' | 'right' | 'centered';

export interface TabsProps {
  align?: AlignTypes;
  bordered?: boolean;
  children: ReactElement[] | ReactElement;
  id: string;
  onTabChange?(title: string): void;
}

export function Tabs({
  align = 'left',
  bordered = false,
  children,
  id,
  onTabChange,
}: TabsProps): ReactElement {
  const [active, setActive] = useState(0);

  const setSelected = (index: number, title: string): void => {
    setActive(index);
    onTabChange && onTabChange(title);
  };

  return (
    <>
      <TabsItems
        activeTab={active}
        bordered={bordered}
        align={align}
        id={id}
        items={children}
        setSelected={setSelected}
      />
      <TabPanels active={active} items={children} id={id} />
    </>
  );
}
