import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../../components';
import { getAdapterOptions } from '../../utils';
import formStyles from '../../../../../EditFormStyles.module.css';
import { FORM_SECTION_CLASSES } from '../../../constants';
import styles from './RemoteAccessForm.module.css';
import { useHandleFormChanges } from './hooks/use-handle-form-changes';
import {
  determineShowRdpCountAlert,
  determineShowSshCountAlert,
} from './utils';

export const TEST_IDS = {
  isRDPEnabledCheckbox: 'edit-vm-remote-access-web-rdp-checkbox',
  isSSHEnabledCheckbox: 'edit-vm-remote-access-vm-ssh-access-checkbox',
  rdpAdapterSelect: 'edit-vm-remote-access-web-rdp-adapter',
  rdpAutoLoginCheckbox: 'edit-vm-remote-access-web-rdp-auto-login-checkbox',
  sshAdapterSelect: 'edit-vm-remote-access-ssh-adapter',
  sshInputs: 'sshInputs',
  webRDPInputs: 'webRDPInputs',
};

export interface RemoteAccessFormProps {
  validAdapters?: VMRemoteAccessValidAdapter[];
  setWebRDPAdapter: ActionCreatorWithPayload<VMSetRDPAdapterActionPayload>;
  setSSHAdapter: ActionCreatorWithPayload<string>;
  setAutoLogin: ActionCreatorWithPayload<boolean>;
  topologyRemoteAccessCount: number;
}

export function RemoteAccessForm({
  validAdapters = [],
  setWebRDPAdapter,
  setSSHAdapter,
  setAutoLogin,
  topologyRemoteAccessCount,
}: RemoteAccessFormProps): ReactElement {
  const { control, errors, watch } = useFormContext();
  const [currentRemoteAccessCount, setCurrentRemoteAccessCount] =
    useState<number>(topologyRemoteAccessCount);
  const { t } = useTranslation();
  const formSectionClasses = classnames(
    FORM_SECTION_CLASSES,
    styles.formSection,
  );

  const watchDisplayDifferentCredentials = watch('displayDifferentCredentials');
  const watchIsRDPEnabled = watch(
    'remoteAccess.rdp.vmNetworkInterface.isEnabled',
  );
  const watchIsSSHEnabled = watch(
    'remoteAccess.ssh.vmNetworkInterface.isEnabled',
  );

  const handleOnSshOrRdpChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? setCurrentRemoteAccessCount(currentRemoteAccessCount + 1)
      : setCurrentRemoteAccessCount(currentRemoteAccessCount - 1);
  };

  const showRdpCountAlert = useCallback(() => {
    return determineShowRdpCountAlert(
      currentRemoteAccessCount,
      watchIsRDPEnabled,
    );
  }, [currentRemoteAccessCount, watchIsRDPEnabled]);

  const showSshCountAlert = useCallback(() => {
    return determineShowSshCountAlert(
      validAdapters.length,
      currentRemoteAccessCount,
      watchIsSSHEnabled,
    );
  }, [validAdapters, currentRemoteAccessCount, watchIsSSHEnabled]);

  useHandleFormChanges({ setAutoLogin, setSSHAdapter, setWebRDPAdapter });
  const rowClasses = classnames('row', styles.remoteAccessRow);
  const rowClassesCreds = classnames('row', styles.remoteAccessDisplayCredsRow);
  const rowClassesAltCreds = styles.remoteAccessDisplayAltCredsRow;
  const alertClasses = classnames(styles.alert, 'col-12 col-2xl-8');

  return (
    <>
      <div className={formStyles.sectionPadding}>
        <div className={rowClassesCreds}>
          <div className={formSectionClasses}>
            <ControlledTextfield
              control={control}
              error={errors?.remoteAccess?.username?.message}
              label={t('virtualMachines.edit.remoteAccess.username')}
              name="remoteAccess.username"
              required={false}
              testId="edit-vm-remote-access-username"
            />
          </div>

          <div className={formSectionClasses}>
            <ControlledTextfield
              control={control}
              error={errors?.remoteAccess?.password?.message}
              label={t('virtualMachines.edit.remoteAccess.password')}
              name="remoteAccess.password"
              required={false}
              testId="edit-vm-remote-access-password"
            />
          </div>
        </div>

        <div className={rowClassesAltCreds}>
          <div
            className={
              watchDisplayDifferentCredentials
                ? 'base-margin-bottom'
                : styles.displayAltCredsClosedPadding
            }
          >
            <ControlledCheckbox
              className={styles.checkbox}
              control={control}
              id="displayDifferentCredentials"
              label={t(
                'virtualMachines.edit.remoteAccess.displayCredentials.enableDisplayDifferentCredentials',
              )}
              testId="edit-vm-remote-access-display-different-credentials-checkbox"
            />
          </div>
        </div>

        <div
          data-testid="displayDifferentCredentialsInputs"
          className={
            watchDisplayDifferentCredentials ? 'dbl-margin-bottom' : styles.hide
          }
        >
          <div className={rowClasses}>
            <div className={formSectionClasses}>
              <div className="dbl-margin-bottom">
                <ControlledTextfield
                  control={control}
                  error={
                    errors?.remoteAccess?.displayCredentials?.username?.message
                  }
                  label={t(
                    'virtualMachines.edit.remoteAccess.displayCredentials.username',
                  )}
                  name="remoteAccess.displayCredentials.username"
                  required={false}
                  testId="edit-vm-remote-access-display-different-credentials-username"
                />
              </div>
            </div>
            <div className={formSectionClasses}>
              <div className="dbl-margin-bottom">
                <ControlledTextfield
                  control={control}
                  error={
                    errors?.remoteAccess?.displayCredentials?.password?.message
                  }
                  label={t(
                    'virtualMachines.edit.remoteAccess.displayCredentials.password',
                  )}
                  name="remoteAccess.displayCredentials.password"
                  required={false}
                  testId="edit-vm-remote-access-display-different-credentials-password"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={alertClasses}>
          {validAdapters.length === 0 ? (
            <Alert>
              {t('virtualMachines.edit.remoteAccess.noValidAdaptersMessage')}
            </Alert>
          ) : (
            showRdpCountAlert() && (
              <Alert>
                {t('virtualMachines.remoteAccessCountWarning.editing.rdp')}
              </Alert>
            )
          )}
        </div>

        <div className={rowClasses}>
          <div className={formSectionClasses}>
            <div className={watchIsRDPEnabled ? 'dbl-margin-bottom' : ''}>
              <div className="base-margin-bottom">
                <ControlledCheckbox
                  className={styles.checkbox}
                  control={control}
                  id="remoteAccess.rdp.vmNetworkInterface.isEnabled"
                  label={t('virtualMachines.edit.remoteAccess.webRDP')}
                  testId={TEST_IDS.isRDPEnabledCheckbox}
                  disabled={validAdapters.length === 0 || showRdpCountAlert()}
                  customOnChange={handleOnSshOrRdpChange}
                />
              </div>
              <div
                data-testid={TEST_IDS.webRDPInputs}
                className={watchIsRDPEnabled ? '' : styles.hide}
              >
                <ControlledSelect
                  control={control}
                  label={t(
                    'virtualMachines.edit.remoteAccess.adapterToConnect',
                  )}
                  horizontal={false}
                  options={getAdapterOptions(validAdapters)}
                  name="remoteAccess.rdp.vmNetworkInterface.uid"
                  loading={false}
                  testId={TEST_IDS.rdpAdapterSelect}
                  required={true}
                  error={
                    errors?.remoteAccess?.rdp?.vmNetworkInterface.uid?.message
                  }
                />
                <ControlledCheckbox
                  className={styles.checkbox}
                  control={control}
                  id="remoteAccess.rdp.autoLogin"
                  label={t('virtualMachines.edit.remoteAccess.autoLogin')}
                  testId={TEST_IDS.rdpAutoLoginCheckbox}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={alertClasses}>
          {showSshCountAlert() && (
            <Alert>
              {t('virtualMachines.remoteAccessCountWarning.editing.ssh')}
            </Alert>
          )}
        </div>
        <div className={rowClasses}>
          <div className={formSectionClasses}>
            <div className={watchIsSSHEnabled ? 'dbl-margin-bottom' : ''}>
              <div className="base-margin-bottom">
                <ControlledCheckbox
                  className={styles.checkbox}
                  control={control}
                  id="remoteAccess.ssh.vmNetworkInterface.isEnabled"
                  label={t('virtualMachines.edit.remoteAccess.sshAccess')}
                  testId={TEST_IDS.isSSHEnabledCheckbox}
                  disabled={validAdapters.length === 0 || showSshCountAlert()}
                  customOnChange={handleOnSshOrRdpChange}
                />
              </div>
              <div
                data-testid={TEST_IDS.sshInputs}
                className={watchIsSSHEnabled ? '' : styles.hide}
              >
                <ControlledSelect
                  control={control}
                  label={t(
                    'virtualMachines.edit.remoteAccess.adapterToConnect',
                  )}
                  horizontal={false}
                  options={getAdapterOptions(validAdapters)}
                  name="remoteAccess.ssh.vmNetworkInterface.uid"
                  loading={false}
                  testId={TEST_IDS.sshAdapterSelect}
                  required={true}
                  error={
                    errors?.remoteAccess?.ssh?.vmNetworkInterface.uid?.message
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={rowClasses}>
          <div className={formSectionClasses}>
            <div className="dbl-margin-bottom">
              <ControlledCheckbox
                className={styles.checkbox}
                control={control}
                id="remoteAccess.vmConsoleEnabled"
                label={t('virtualMachines.edit.remoteAccess.vmConsole')}
                testId="edit-vm-remote-access-vm-console-checkbox"
              />
            </div>
          </div>
        </div>
        <div className={rowClasses}>
          <div className={formSectionClasses}>
            <ControlledTextfield
              control={control}
              error={errors?.remoteAccess?.internalUrls?.[0]?.location?.message}
              label={t('virtualMachines.edit.remoteAccess.internalUrls.url1')}
              name="remoteAccess.internalUrls[0].location"
              required={false}
              testId="edit-vm-remote-access-internal-urls-url1"
            />
          </div>
          <div className={formSectionClasses}>
            <ControlledTextfield
              control={control}
              error={
                errors?.remoteAccess?.internalUrls?.[0]?.description?.message
              }
              label={t(
                'virtualMachines.edit.remoteAccess.internalUrls.description1',
              )}
              name="remoteAccess.internalUrls[0].description"
              required={false}
              testId="edit-vm-remote-access-internal-urls-description1"
            />
          </div>
        </div>

        <div className={rowClasses}>
          <div className={formSectionClasses}>
            <ControlledTextfield
              control={control}
              error={errors?.remoteAccess?.internalUrls?.[1]?.location?.message}
              label={t('virtualMachines.edit.remoteAccess.internalUrls.url2')}
              name="remoteAccess.internalUrls[1].location"
              required={false}
              testId="edit-vm-remote-access-internal-urls-url2"
            />
          </div>
          <div className={formSectionClasses}>
            <ControlledTextfield
              control={control}
              error={
                errors?.remoteAccess?.internalUrls?.[1]?.description?.message
              }
              label={t(
                'virtualMachines.edit.remoteAccess.internalUrls.description2',
              )}
              name="remoteAccess.internalUrls[1].description"
              required={false}
              testId="edit-vm-remote-access-internal-urls-description2"
            />
          </div>
        </div>
      </div>
    </>
  );
}
