import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { RootState } from '../../redux/store';
import {
  integrationSelectors,
  setInputsAndFetchedInputsToAdd,
} from '../../redux/integrations/slice';
import {
  useAddIntegrationMutation,
  useGetIntegrationsQuery,
  useGetRecipeQuery,
  useGetSaasTemplateAvailabilityValuesQuery,
} from '../../redux/integrations/api';
import history from '../../redux/history';
import { getFormIntegrationAvailabilityOptions } from '../../utils/integrations';
import { MainRoutes } from '../../constants/routes';
import { CreateIntegration } from '.';

const CreateIntegrationContainer = ({
  integrationToSave,
  setInputsAndFetchedInputsToAdd,
}: CreateIntegrationContainerProps): ReactElement => {
  const [
    addIntegration,
    { isLoading: isAddIntegrationLoading, isError: isAddIntegrationError },
  ] = useAddIntegrationMutation();

  const {
    data: fetchedIntegrations,
    isLoading: isIntegrationsLoading,
    isError: isIntegrationsError,
  } = useGetIntegrationsQuery(undefined);

  if (
    !integrationToSave?.name ||
    !integrationToSave?.inventoryWorkflowId ||
    !integrationToSave?.inventoryWorkflowName
  ) {
    history.push(MainRoutes.Integrations);
  }

  const {
    data: recipe,
    isLoading: isRecipeLoading,
    isError: isRecipeError,
    isSuccess: isRecipeSuccess,
  } = useGetRecipeQuery(integrationToSave?.inventoryWorkflowId || '', {
    skip: !integrationToSave?.inventoryWorkflowId,
  });

  const {
    availabilityValues,
    isAvailabilityValuesLoading,
    isAvailabilityValuesFetching,
    isAvailabilityValuesError,
  } = useGetSaasTemplateAvailabilityValuesQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
      availabilityValues: getFormIntegrationAvailabilityOptions(
        data?.saasTemplateAvailabilityValues,
      ),
      isAvailabilityValuesError: isError,
      isAvailabilityValuesFetching: isFetching,
      isAvailabilityValuesLoading: isLoading,
    }),
  });

  useEffect(() => {
    if (
      !isEqual(integrationToSave?.fetchedInputs, recipe?.inputs) &&
      isRecipeSuccess === true
    ) {
      setInputsAndFetchedInputsToAdd(recipe?.inputs || []);
    }
  }, [
    recipe,
    setInputsAndFetchedInputsToAdd,
    isRecipeSuccess,
    integrationToSave,
  ]);

  return (
    <CreateIntegration
      integrations={fetchedIntegrations?.saasTemplates}
      isLoading={
        isIntegrationsError ||
        isAddIntegrationError ||
        isRecipeError ||
        isAvailabilityValuesError ||
        isAddIntegrationLoading ||
        isIntegrationsLoading ||
        isRecipeLoading ||
        isAvailabilityValuesLoading ||
        isAvailabilityValuesFetching ||
        !integrationToSave?.fetchedInputs
      }
      integrationToSave={integrationToSave}
      integrationAvailabilityOptions={availabilityValues}
      addIntegration={addIntegration}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  integrationToSave: integrationSelectors.getIntegrationToSave(state),
});

const mapDispatchToProps = {
  setInputsAndFetchedInputsToAdd,
};

type CreateIntegrationContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ConnectedCreateIntegration = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateIntegrationContainer);
