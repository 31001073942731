import React, { ReactElement } from 'react';
import { RowNode } from 'ag-grid-community';
import { integrationsApi } from '../../../../../../redux/integrations/api';
import { AvailabilityCellRenderer } from './Availability';

export const AvailabilityCellRendererContainer = (
  props: RowNode,
): ReactElement => {
  const { data } =
    integrationsApi.endpoints.getSaasTemplateAvailabilityValues.useQueryState(
      undefined,
      {},
    );

  return (
    <AvailabilityCellRenderer
      rowNode={props}
      integrationAvailabilityOptions={
        data?.saasTemplateAvailabilityValues ?? []
      }
    />
  );
};
