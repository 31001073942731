import React, { ReactElement } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { HardwareTableActionsCellRendererContainer } from '../HardwareTableActionsCellRenderer/container';

export type HardwareTableActionsCellRendererWrapperProps = Pick<
  ICellRendererParams,
  'data'
>;

export const HardwareTableActionsCellRendererWrapper = (
  props: HardwareTableActionsCellRendererWrapperProps,
): ReactElement => {
  const { t } = useTranslation();
  const {
    data: { deviceId, powerControl, uid: nodeUid, hwConsoleHref, name },
  }: { data: SessionInventoryHardwareData } = props;

  return (
    <HardwareTableActionsCellRendererContainer
      consoleHref={hwConsoleHref}
      deviceId={deviceId}
      label={t('sessionsInventory.hardware.table.actions.resetPower')}
      name={name}
      nodeType="HARDWARE"
      nodeUid={nodeUid}
      isPowerControlEnabled={powerControl === 'Yes'}
    />
  );
};
