import React, { ReactElement } from 'react';
import { topologiesApi } from '../../../../redux/topologies/api';
import { TopologiesContent } from '.';

export function ConnectedTopologiesContent(): ReactElement {
  const { useQueryState } = topologiesApi.endpoints.getTopologies;
  const { data } = useQueryState(undefined);

  return <TopologiesContent topologies={data || []} />;
}
