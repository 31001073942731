import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';
import { PropsFromRedux } from './container';

export interface DeleteTopologyModalProps extends PropsFromRedux {
  clearTopologyToDelete: VoidFunction;
  setShouldConfirmDelete: (shouldConfirm: boolean) => void;
  deleteTopology: VoidFunction;
  isDeleting: boolean;
}

export function DeleteTopologyModal({
  clearTopologyToDelete,
  deleteTopology,
  isDeleting,
  topologyToDelete,
  setShouldConfirmDelete,
}: DeleteTopologyModalProps): ReactElement | null {
  const { t } = useTranslation();

  if (!topologyToDelete) {
    return null;
  }

  const handleDelete = () => {
    if (topologyToDelete.status === 'DRAFT') {
      deleteTopology();
    } else {
      setShouldConfirmDelete(true);
    }
  };

  return (
    <ConfirmModal
      heading={t('topologies.delete.heading').replace(
        ':topology',
        topologyToDelete.name as string,
      )}
      subHeading={t('topologies.delete.subHeading')}
      highlightedSelection={topologyToDelete?.name}
      loading={isDeleting}
      onCancel={clearTopologyToDelete}
      onClose={clearTopologyToDelete}
      onConfirm={handleDelete}
      confirmButtonLabel={t('buttons.delete')}
      show={!!topologyToDelete}
      size="small"
      testId="topology-delete-modal"
    />
  );
}
