import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { filterAvailableLicences } from '../../../../pages/EditTopology/configuration/Licences/utils';
import {
  useCreateLicenceMutation,
  useGetInventoryLicencesQuery,
  useGetLicencesQuery,
} from '../../../../redux/configuration/licences/api';
import { InventoryLicencesModal } from './InventoryLicencesModal';

interface InventoryLicencesContainerProps {
  onClose: VoidFunction;
}

export function InventoryLicencesContainer({
  onClose,
}: InventoryLicencesContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { isLoading, data: availableLicences } =
    useGetInventoryLicencesQuery(topologyUid);

  const [createLicence, { isLoading: isCreating }] = useCreateLicenceMutation();
  const { data: licenses, isLoading: isLoadingLicenses } =
    useGetLicencesQuery(topologyUid);

  const handleCreate = async (selectedLicences: CreateLicencePayload[]) => {
    await Promise.allSettled(
      selectedLicences.map((item) => createLicence(item)),
    );
    onClose();
  };

  return (
    <InventoryLicencesModal
      loading={isLoading || isLoadingLicenses}
      inventoryLicences={
        availableLicences?.inventoryLicenses
          ? filterAvailableLicences(
              availableLicences?.inventoryLicenses,
              licenses?.licenses || [],
            )
          : []
      }
      createLicences={handleCreate}
      isCreating={isCreating}
      onClose={onClose}
    />
  );
}

export interface InventoryLicencesModalProps {
  loading: boolean;
  inventoryLicences: InventoryLicence[];
  createLicences: (selectedLicences: CreateLicencePayload[]) => Promise<void>;
  isCreating: boolean;
  onClose: VoidFunction;
}
