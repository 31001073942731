import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../components';
import { ConnectedSaveAndEndForm } from './components/SaveAndEndForm/container';
import { SaveAndEndSessionModalProps } from './container';

export function SaveAndEndSessionModal({
  setShowSaveAndEndSessionModal,
  showSaveAndEndSessionModal,
}: SaveAndEndSessionModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      show={showSaveAndEndSessionModal}
      onClose={() => setShowSaveAndEndSessionModal(false)}
      title={t('sessions.modals.saveAndEndSession.heading')}
    >
      <div>
        <p>{t('sessions.modals.saveAndEndSession.subHeading')}</p>
        <ConnectedSaveAndEndForm />
      </div>
    </Modal>
  );
}
