import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getVirtualMachines,
  setVirtualMachineToDelete,
} from '../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../redux/store';
import { useGetVmsQuery } from '../../../../../redux/configuration/virtual-machine-entities/api';
import { VirtualMachines } from '.';

function VirtualMachinesContainer({
  virtualMachineToDelete,
  setVirtualMachineToDelete,
}: VirtualMachinesContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    data: virtualMachines = [],
    isLoading,
    isFetching,
  } = useGetVmsQuery(topologyUid);

  return (
    <VirtualMachines
      isLoading={isLoading || isFetching}
      virtualMachines={virtualMachines}
      virtualMachineToDelete={virtualMachineToDelete}
      setVirtualMachineToDelete={setVirtualMachineToDelete}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  virtualMachineToDelete: getVirtualMachines(state).virtualMachineToDelete,
});

const mapDispatchToProps = {
  setVirtualMachineToDelete,
};

export type VirtualMachinesContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type VirtualMachinesRTKProps = {
  isLoading: boolean;
  virtualMachines: VirtualMachine[];
};

export type VirtualMachinesProps = VirtualMachinesContainerProps &
  VirtualMachinesRTKProps;

export const ConnectedVirtualMachines = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachinesContainer);
