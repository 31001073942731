import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface useHandleFormChangesProps {
  setWebRDPAdapter: ActionCreatorWithPayload<VMSetRDPAdapterActionPayload>;
  setSSHAdapter: ActionCreatorWithPayload<string>;
  setAutoLogin: ActionCreatorWithPayload<boolean>;
}

export const useHandleFormChanges = ({
  setWebRDPAdapter,
  setSSHAdapter,
  setAutoLogin,
}: useHandleFormChangesProps): void => {
  const { watch, trigger, setValue } = useFormContext();

  const watchURL1 = watch('remoteAccess.internalUrls[0].location');
  const watchURL2 = watch('remoteAccess.internalUrls[1].location');
  const watchDescription1 = watch('remoteAccess.internalUrls[0].description');
  const watchDescription2 = watch('remoteAccess.internalUrls[1].description');
  const watchIsRDPEnabled = watch(
    'remoteAccess.rdp.vmNetworkInterface.isEnabled',
  );
  const watchIsSSHEnabled = watch(
    'remoteAccess.ssh.vmNetworkInterface.isEnabled',
  );
  const watchRDPAdapter = watch('remoteAccess.rdp.vmNetworkInterface.uid');
  const watchSSHAdapter = watch('remoteAccess.ssh.vmNetworkInterface.uid');
  const watchAutoLogin = watch('remoteAccess.rdp.autoLogin');

  useEffect(() => {
    setWebRDPAdapter({
      isAutoLoginChecked: watchAutoLogin,
      uid: watchRDPAdapter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWebRDPAdapter, watchRDPAdapter]);

  useEffect(() => {
    setSSHAdapter(watchSSHAdapter);
  }, [setSSHAdapter, watchSSHAdapter]);

  useEffect(() => {
    setAutoLogin(watchAutoLogin);
  }, [setAutoLogin, watchAutoLogin]);

  useEffect(() => {
    trigger('remoteAccess.internalUrls[0].description');
  }, [trigger, watchURL1]);

  useEffect(() => {
    trigger('remoteAccess.internalUrls[1].description');
  }, [trigger, watchURL2]);

  useEffect(() => {
    trigger('remoteAccess.internalUrls[0].location');
  }, [trigger, watchDescription1]);

  useEffect(() => {
    trigger('remoteAccess.internalUrls[1].location');
  }, [trigger, watchDescription2]);

  useEffect(() => {
    if (!watchIsRDPEnabled) {
      setValue('remoteAccess.rdp.vmNetworkInterface.uid', '', {
        shouldDirty: true,
      });
    }
    trigger('remoteAccess.rdp.vmNetworkInterface.uid');
  }, [setValue, trigger, watchIsRDPEnabled]);

  useEffect(() => {
    if (!watchIsSSHEnabled) {
      setValue('remoteAccess.ssh.vmNetworkInterface.uid', '', {
        shouldDirty: true,
      });
    }
    trigger('remoteAccess.ssh.vmNetworkInterface.uid');
  }, [setValue, trigger, watchIsSSHEnabled]);
};
