import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../../redux/views/session-view/session/api/api';
import { getMorIdFromSessionDetails } from '../../../../utils';
import { PanelHeaderButtons } from '../index';
import { VmLeftPanelProps } from './container';

type ConnectedDCloudVmLeftPanelHeaderButtonsProps = VmLeftPanelProps &
  FetchSessionArgs;

export function ConnectedDCloudVmLeftPanelHeaderButtons({
  deviceUid,
  sessionUid,
  setLeftPanelType,
  versionUid,
}: ConnectedDCloudVmLeftPanelHeaderButtonsProps): ReactElement {
  const { mor } = sessionApi.endpoints.getSession.useQueryState(
    { sessionUid, versionUid },
    {
      selectFromResult: ({ data }) => ({
        mor: getMorIdFromSessionDetails(
          (
            data?.diagram.nodes.filter(
              (node) => node.uid === deviceUid,
            )[0] as SessionVmNode
          ).sessionDetails,
        ),
      }),
    },
  );

  const { refetch } =
    sessionApi.endpoints.getSessionVmStatus.useQuerySubscription({
      mor,
      sessionUid,
      versionUid,
    });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
