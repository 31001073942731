import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

interface InboundProxyRulesState {
  ipAddressOptionsByVM?: VmsWithValidIPAddressOptions;
  selectedVM?: string;
  inboundProxyRuleToDelete?: InboundProxyRule;
}

export const initialState: InboundProxyRulesState = {
  inboundProxyRuleToDelete: undefined,
  ipAddressOptionsByVM: undefined,
  selectedVM: undefined,
};

const slice = createSlice({
  initialState,
  name: 'inboundProxyRules',
  reducers: {
    setInboundProxyRuleToDelete: (
      state,
      action: PayloadAction<InboundProxyRule | undefined>,
    ) => {
      state.inboundProxyRuleToDelete = action.payload;
    },
  },
});

export const getInboundProxyRules = (
  state: RootState,
): InboundProxyRulesState => state.configuration[slice.name];

export const { reducer: inboundProxyRulesReducer } = slice;
export const { setInboundProxyRuleToDelete } = slice.actions;
