import { RefObject, useEffect } from 'react';

export type useHandleOutsideClickArgs = {
  dropdownRef: RefObject<HTMLDivElement>;
  setShowToggleMenu: (value: React.SetStateAction<boolean>) => void;
};

export function useHandleOutsideClick({
  dropdownRef,
  setShowToggleMenu,
}: useHandleOutsideClickArgs): void {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setShowToggleMenu(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownRef, setShowToggleMenu]);
}
