import React, { ReactElement } from 'react';
import { PanelGrid } from '../../../PanelGrid';
import { IconPanelPropertiesEmptyAlert } from './components/IconPanelPropertiesEmptyAlert';

export type IconPanelPropertiesProps = {
  properties: PanelGridDataItem[];
};

export function IconPanelProperties({
  properties,
}: IconPanelPropertiesProps): ReactElement {
  return (
    <>
      <PanelGrid
        enableCopy={true}
        data={properties}
        wideCol="value"
        wideColRatio={1}
        nameBold={true}
      />
      <IconPanelPropertiesEmptyAlert properties={properties} />
    </>
  );
}
