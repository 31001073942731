import { Alert, SelectOption } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { PLEASE_SELECT_OPTION } from '../../../../../constants/form';
import { buildVmNameOptions } from '../NatRules/utils';
import { InboundProxyRuleDeleteModal } from './components/InboundProxyRuleDeleteModal';
import { InboundProxyRulesAddForm } from './components/InboundProxyRulesAddForm';
import { InboundProxyRulesTable } from './components/InboundProxyRulesTable';
import { InboundProxyRulesProps } from './container';

export function InboundProxyRules({
  createInboundProxyRule,
  inboundProxyRuleToDelete,
  inboundProxyRules,
  isCreating,
  isError,
  isLoading,
  limits,
  setInboundProxyRuleToDelete,
  vmTargets,
}: InboundProxyRulesProps): ReactElement | null {
  const { t } = useTranslation();

  const onDeleteClick = useCallback(
    (inboundProxyRule: InboundProxyRule) => {
      setInboundProxyRuleToDelete(inboundProxyRule);
    },
    [setInboundProxyRuleToDelete],
  );

  const [vmIpAddressOptions, setVmIpAddressOptions] = useState<
    SelectOption<string>[]
  >([PLEASE_SELECT_OPTION]);

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('traffic.inboundProxyRules.name')} />
      {!vmTargets?.length && !isLoading ? (
        <Alert colour="info">
          {t('traffic.inboundProxyRules.noConfiguredVMs')}
        </Alert>
      ) : (
        <InboundProxyRulesAddForm
          targetOptions={buildVmNameOptions(vmTargets)}
          ipAddressOptions={vmIpAddressOptions}
          vmTargets={vmTargets}
          isCreating={isCreating}
          setVmIpAddressOptions={setVmIpAddressOptions}
          createInboundProxyRule={createInboundProxyRule}
          inboundProxyRules={inboundProxyRules || []}
          limits={limits}
        />
      )}
      {!inboundProxyRules?.length && !isLoading ? (
        <Alert colour="info">{t('traffic.inboundProxyRules.empty')}</Alert>
      ) : (
        <InboundProxyRulesTable
          inboundProxyRules={inboundProxyRules || []}
          isError={isError}
          isLoading={isLoading}
          onDeleteClick={onDeleteClick}
        />
      )}
      {inboundProxyRuleToDelete && (
        <InboundProxyRuleDeleteModal
          inboundProxyRule={inboundProxyRuleToDelete}
          setInboundProxyRuleToDelete={setInboundProxyRuleToDelete}
        />
      )}
    </section>
  );
}
