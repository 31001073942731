import React, { ReactElement, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Modal } from '../../../components';
import { CustomIconPicker } from '../../CustomIconPicker';
import { PrimaryButton } from '../../Buttons/PrimaryButton';
import styles from './ControlledIconPicker.module.css';

type ControlledIconPickerProps = {
  icons: ReactElement[];
  onChange: (newSelection: string) => void;
  selectedIcon: string;
  iconPickerLabel: string;
  changeIconLabel: string;
  control: Control;
  customControlName?: string;
};

export function ControlledIconPicker({
  control,
  icons,
  selectedIcon,
  onChange,
  iconPickerLabel,
  changeIconLabel,
  customControlName,
}: ControlledIconPickerProps): ReactElement {
  const [showIconPickerModal, setShowIconPickerModal] =
    useState<boolean>(false);
  const handleUpdate = (selection: string) => {
    onChange(selection);
    setShowIconPickerModal(false);
  };
  return (
    <>
      <div className="half-margin-top half-margin-left qtr-margin-bottom">
        <label htmlFor="icon">{iconPickerLabel} *</label>
      </div>
      <div className={styles.iconGroup}>
        <Controller
          control={control}
          name={customControlName || 'icon'}
          defaultValue={selectedIcon}
          render={() => (
            <div
              id="icon"
              className={styles.icon}
              data-testid={`selected-icon-${selectedIcon}`}
            >
              {icons.find((icon) => icon.key === selectedIcon)}
            </div>
          )}
        />
        <PrimaryButton
          className={styles.changeIconButton}
          onClick={() => setShowIconPickerModal(true)}
          colour="primary"
          name={changeIconLabel}
        >
          {changeIconLabel}
        </PrimaryButton>
      </div>
      <Modal
        clickScreenToClose={true}
        show={showIconPickerModal}
        onClose={() => setShowIconPickerModal(false)}
        title={changeIconLabel}
        headerAlignment="center"
      >
        <CustomIconPicker
          handleUpdate={handleUpdate}
          selectedIcon={selectedIcon}
          setShowModal={setShowIconPickerModal}
          icons={icons}
        />
      </Modal>
    </>
  );
}
