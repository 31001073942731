import { connect } from 'react-redux';
import {
  addSelectedItemToAction,
  removeSelectedItemToAction,
  sessionInventorySelectors,
} from '../../../../../redux/views/session-view/session-inventory/slice';
import { RootState } from '../../../../../redux/store';
import { SelectCellRenderer } from '.';

const mapDispatchToProps = {
  addSelectedItemToAction,
  removeSelectedItemToAction,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: RootState, ownProps: any) => {
  return {
    cellData: ownProps.data,
    isChecked:
      ownProps.page === 'hardware'
        ? sessionInventorySelectors
            .getSelectedHwItemsToAction(state)
            .some(
              (item) =>
                item.uid ===
                (ownProps.data as SessionInventoryHardwareData).uid,
            )
        : sessionInventorySelectors
            .getSelectedVmItemsToAction(state)
            .some(
              (item) =>
                item.mor ===
                (ownProps.data as SessionInventoryVirtualMachineData).mor,
            ),
    isDisabled:
      sessionInventorySelectors.getShowMultiActionProgressBar(state) ||
      sessionInventorySelectors.getIsCallingVmStatusesAPICalls(state),
    isHidden: ownProps.value?.isHidden,
    page: ownProps.page,
  };
};

export type SelectCellRendererProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

export const SelectCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectCellRenderer);
