import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { TableLoader } from '../../../../components/Table/TableLoader';
import { useGetTemplatesByDCQuery } from '../../../../redux/configuration/templates/api';
import { setShowMoreInfoForInventoryTemplate } from '../../../../redux/configuration/templates/slice';
import { prepTemplatesForTable } from './utils';
import { DemoTemplate } from '.';

interface DemoTemplateContainerProps {
  datacenter: DataCenter;
  setTemplateToImport: (demo: InventoryTopology) => void;
  setShowMoreInfoForInventoryTemplate: (
    inventoryTemplate: InventoryTopology | undefined,
  ) => void;
}

function DemoTemplateContainer({
  datacenter,
  setTemplateToImport,
  setShowMoreInfoForInventoryTemplate,
}: DemoTemplateContainerProps): ReactElement | null {
  const { isFetching, transformedTemplates } = useGetTemplatesByDCQuery(
    datacenter,
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        data,
        isFetching,
        isLoading,
        transformedTemplates: data
          ? prepTemplatesForTable(data.inventoryTopologies)
          : undefined,
      }),
    },
  );

  if (!transformedTemplates) {
    return <TableLoader />;
  }
  return (
    <DemoTemplate
      transformedTemplates={transformedTemplates}
      setTemplateToImport={setTemplateToImport}
      isLoading={isFetching}
      setShowMoreInfoForInventoryTemplate={setShowMoreInfoForInventoryTemplate}
    />
  );
}

const mapDispatchToProps = {
  setShowMoreInfoForInventoryTemplate,
};

export const ConnectedDemoTemplate = connect(
  null,
  mapDispatchToProps,
)(DemoTemplateContainer);
