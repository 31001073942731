import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type HandleOnEditNetworkClick = {
  isNetworkNodeInDiagramView: string | false | undefined;
  setNetworkToConfigureInDiagram: ActionCreatorWithPayload<Network | undefined>;
  asset?: Network;
};

export const handleOnEditNetworkClick = ({
  asset,
  isNetworkNodeInDiagramView,
  setNetworkToConfigureInDiagram,
}: HandleOnEditNetworkClick): void => {
  if (!asset) return;

  if (isNetworkNodeInDiagramView) {
    setNetworkToConfigureInDiagram(asset);
  }
};

type DetermineShouldRenderNetworkNodeActionButtons = {
  isNetworkNodeInDiagramView: string | false | undefined;
  isAddingLogicalConnection: boolean;
};

export const determineShouldRenderNetworkNodeActionButtons = ({
  isNetworkNodeInDiagramView,
  isAddingLogicalConnection,
}: DetermineShouldRenderNetworkNodeActionButtons): boolean => {
  if (
    isNetworkNodeInDiagramView !== false &&
    isNetworkNodeInDiagramView !== undefined
  ) {
    return !isAddingLogicalConnection;
  }
  return false;
};
