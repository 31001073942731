import React, { ReactElement } from 'react';
import { SessionViewSideTabsContainer } from '../SessionInventorySideTabs/container';
import { SESSION_INVENTORY_SIDE_TABS_LABELS } from '../../constants';
import { ConnectedSessionInventoryHwContent } from '../SessionInventoryHardware/container';
import { ConnectedSessionInventoryVmContent } from '../SessionInventoryVirtualMachines/container';
import { SessionInventoryContentProps } from './container';
import styles from './SessionInventoryContent.module.css';

export const SessionInventoryContent = ({
  activeTab,
}: SessionInventoryContentProps): ReactElement => {
  return (
    <div className={styles.root}>
      <aside className={styles.sidebar}>
        <SessionViewSideTabsContainer />
      </aside>
      <main className={styles.content}>
        {activeTab === SESSION_INVENTORY_SIDE_TABS_LABELS.hardware ? (
          <ConnectedSessionInventoryHwContent />
        ) : (
          <ConnectedSessionInventoryVmContent />
        )}
      </main>
    </div>
  );
};
