import { AxiosResponse } from 'axios';
import { config, getCodeVerifier } from '../../auth';
import { GRANT_TYPES } from '../../constants/auth';
import cloudSSO from '.';

export function swapAuthCodeForTokens(
  accessCode: string,
  redirectUri: string,
): Promise<AxiosResponse<SSOSwapCodeResponse>> {
  const verifier = getCodeVerifier();
  const payload = new URLSearchParams();
  payload.append('grant_type', GRANT_TYPES.AUTH_CODE);
  payload.append('code', accessCode);
  payload.append('redirect_uri', redirectUri);
  payload.append('client_id', config.SSO.CLIENT_ID);
  payload.append('code_verifier', verifier);
  return cloudSSO.post('', payload);
}

export function refreshAuthTokens(
  refreshToken: string,
): Promise<AxiosResponse<SSORefreshTokensResponse>> {
  const payload = new URLSearchParams();
  payload.append('client_id', config.SSO.CLIENT_ID);
  payload.append('grant_type', GRANT_TYPES.REFRESH_TOKEN);
  payload.append('refresh_token', refreshToken);
  return cloudSSO.post('', payload);
}
