import React, { ReactElement } from 'react';
import { useGetExpoSessionQuery } from '../../../redux/views/session-view/session/api/expoApi';
import { ConnectedSessionProps } from './container';
import { Session } from '.';

type ConnectedExpoSessionProps = ConnectedSessionProps & FetchExpoSessionArgs;

export const ConnectedExpoSession = ({
  showPage,
  unsubscribeFromGetAllVmStatusesData,
  resetMultiSelect,
  engagementUid,
  sessionUid,
  versionUid,
}: ConnectedExpoSessionProps): ReactElement => {
  useGetExpoSessionQuery({ engagementUid, sessionUid, versionUid });

  return (
    <Session
      resetMultiSelect={resetMultiSelect}
      showPage={showPage}
      unsubscribeFromGetAllVmStatusesData={unsubscribeFromGetAllVmStatusesData}
    />
  );
};
