import React, { ReactElement } from 'react';
import styles from '../../CarouselWrapper.module.css';
import { HeroIndicatorButton } from '../HeroIndicatorButton';

type HeroIndicatorButtonsProps = {
  activeIndex: number;
  items: ReactElement[];
  updateIndex: (newIndex: number) => void;
};

export const HeroIndicatorButtons = ({
  activeIndex,
  items,
  updateIndex,
}: HeroIndicatorButtonsProps): ReactElement => {
  return (
    <div data-testid="indicators" className={styles.indicators}>
      {items.map((item: JSX.Element, index: number) => {
        return (
          <HeroIndicatorButton
            key={index}
            index={index}
            activeIndex={activeIndex}
            updateIndex={updateIndex}
          />
        );
      })}
    </div>
  );
};
