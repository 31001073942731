import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import { getInternalDocument } from '../../../../../../../../../../redux/views/slice';
import { Attachment } from './index';

const mapDispatchToProps = {
  getInternalDocument,
};

type DCloudAttachmentContentContainerProps = {
  sessionDocument: SessionDocument;
  versionUid: string;
} & typeof mapDispatchToProps;

export function DCloudAttachmentContentContainer({
  sessionDocument,
  versionUid,
  getInternalDocument,
}: DCloudAttachmentContentContainerProps): ReactElement {
  const getInternalDocumentDCloud = () =>
    getInternalDocument({
      documentUid: sessionDocument.uid,
      versionUid,
    });
  return (
    <Attachment
      sessionDocument={sessionDocument}
      getInternalDocument={getInternalDocumentDCloud}
    ></Attachment>
  );
}

export const DCloudAttachmentContainer = connect(
  null,
  mapDispatchToProps,
)(DCloudAttachmentContentContainer);
