import { Card } from 'dcloud-shared-ui';
import classnames from 'classnames';
import React, { ReactElement, FunctionComponent } from 'react';
import { TopologySVGIconProps } from '../../../../../components/SVGIcons/TopologySVGIcon';
import { TILE_CLASSES } from '../constants';
import styles from './Tile.module.css';

export interface ExternalLinkTileProps {
  className?: string;
  description: string;
  Image: FunctionComponent<TopologySVGIconProps | unknown>;
  link: string;
  title: string;
  disabled?: boolean;
  externalLink?: boolean;
}

export function ExternalLinkTile({
  className,
  description,
  Image,
  title,
  link,
}: ExternalLinkTileProps): ReactElement {
  return (
    <div className={classnames(styles.root, TILE_CLASSES, className)}>
      <a
        aria-label={title}
        href={link}
        className={styles.cardWrap}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Card className={styles.card}>
          <Image />
        </Card>
      </a>
      <h3 className="dbl-margin-top base-margin-bottom ">
        <a
          aria-label={title}
          href={link}
          className={styles.titleLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          {title}
        </a>
      </h3>
      <p
        className={classnames(
          styles.description,
          'text-weight-200 text-size-16',
        )}
      >
        {description}
      </p>
    </div>
  );
}
