import { connect } from 'react-redux';
import { ENDPOINT_KITS_FIXTURE } from '../fixtures';
import { EndpointKitsPanel } from '.';

const mapStateToProps = () => ({
  data: ENDPOINT_KITS_FIXTURE,
});

export type EndpointKitsPanelProps = ReturnType<typeof mapStateToProps>;

export const EndpointKitsPanelContainer =
  connect(mapStateToProps)(EndpointKitsPanel);
