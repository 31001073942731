import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { useSkeletonColours } from '../../../../../../../../hooks/use-skeleton-colours';
import { PageHeading } from '../../../../../../../../components/PageHeading';

export function EditLoader(): ReactElement {
  const { t } = useTranslation();
  const { backgroundColor, foregroundColor } = useSkeletonColours();

  return (
    <section className="half-padding-left">
      <PageHeading
        pageTitle={t('virtualMachines.edit.title')}
        withBackBtn={true}
      />
      <ContentLoader
        className="dbl-margin-top"
        speed={1.5}
        width="100%"
        height={340}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="-10" y="0" rx="3" ry="3" width="100%" height="60" />
        <rect x="40" y="120" rx="3" ry="3" width="50%" height="50" />
        <rect x="40" y="210" rx="3" ry="3" width="50%" height="100" />
      </ContentLoader>
    </section>
  );
}
