import React, { ReactElement } from 'react';
import { ProxyContainer } from './components/Proxy/container';
import { PublicIPContainer } from './components/PublicIp/container';
import { InternalIPContainer } from './components/InternalIp/container';

export function PublicIPProxy(): ReactElement {
  return (
    <>
      <PublicIPContainer />
      <InternalIPContainer />
      <ProxyContainer />
    </>
  );
}
