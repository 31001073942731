import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { TemplateToastToAdd } from '../../../../../redux/toast/slice';
import i18n from '../../../../../i18n';

export type postActionProps = {
  showConfirmForActionForHw:
    | SessionInventoryConfirmActionForHwPayload
    | undefined;
  showConfirmForActionForVm:
    | SessionInventoryConfirmActionForVmPayload
    | undefined;
  setShowConfirmForActionForHw: ActionCreatorWithOptionalPayload<
    SessionInventoryConfirmActionForHwPayload | undefined,
    string
  >;
  setShowConfirmForActionForVm: ActionCreatorWithOptionalPayload<
    SessionInventoryConfirmActionForVmPayload | undefined,
    string
  >;
  postSessionHwAction: RTKMutation<
    SessionHwActionResponse,
    SessionHwActionArgs
  >;
  postSessionVmAction: RTKMutation<
    SessionVmActionResponse,
    SessionVmActionArgs
  >;
  sessionUid: string;
  versionUid: string;
  addErrorToast: ActionCreatorWithPayload<TemplateToastToAdd, string>;
};

export const postAction = ({
  showConfirmForActionForHw,
  showConfirmForActionForVm,
  setShowConfirmForActionForHw,
  setShowConfirmForActionForVm,
  postSessionHwAction,
  postSessionVmAction,
  sessionUid,
  versionUid,
  addErrorToast,
}: postActionProps): void | undefined => {
  if (showConfirmForActionForHw) {
    if (
      !showConfirmForActionForHw?.actionType ||
      !showConfirmForActionForHw?.deviceId
    ) {
      addErrorToast({ message: i18n.t('common.errorMessage') });
      setShowConfirmForActionForHw(undefined);
      return;
    }

    postSessionHwAction({
      action: showConfirmForActionForHw?.actionType,
      deviceId: showConfirmForActionForHw?.deviceId,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    })
      .unwrap()
      .then(() => setShowConfirmForActionForHw(undefined))
      .catch(() => {
        setShowConfirmForActionForVm(undefined);
      });
  } else if (showConfirmForActionForVm) {
    if (
      !showConfirmForActionForVm?.actionType ||
      !showConfirmForActionForVm?.mor
    ) {
      addErrorToast({ message: i18n.t('common.errorMessage') });
      setShowConfirmForActionForVm(undefined);
      return;
    }
    postSessionVmAction({
      action: showConfirmForActionForVm?.actionType,
      mor: showConfirmForActionForVm?.mor,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    })
      .unwrap()
      .then(() => setShowConfirmForActionForVm(undefined))
      .catch(() => {
        setShowConfirmForActionForVm(undefined);
      });
  } else {
    return undefined;
  }
};
