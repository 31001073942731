import React, { ReactElement } from 'react';
import { Button } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../components';
import { TimerExpiredModalProps } from './container';

export function TimerExpiredModal({
  setShowExpiredModal,
  showExpiredModal,
}: TimerExpiredModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      show={showExpiredModal}
      onClose={() => setShowExpiredModal(false)}
      title={t('sessions.modals.sessionExpired.heading')}
    >
      <p>{t('sessions.modals.sessionExpired.subHeading')}</p>
      <Button onClick={() => setShowExpiredModal(false)}>
        {t('buttons.continue')}
      </Button>
    </Modal>
  );
}
