import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { sessionApi } from '../../../../../../redux/views/session-view/session/api/api';
import { filterIntegrationsByType } from '../../../../../../utils/integrations';
import { TAGS } from '../../../../../../redux/api/constants';
import { IntegrationPanelContainerProps } from './container';
import { IntegrationPanel } from '.';

function DCloudIntegrationPanel({
  integrationType,
  onIntegrationTypeChange,
  sessionUid,
  versionUid,
}: DCloudIntegrationPanelProps): ReactElement {
  const { sessionInformation, isError, isLoading, isFetching } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          isError,
          isFetching,
          isLoading,
          sessionInformation: data?.sessionDetails,
        }),
      },
    );
  return (
    <IntegrationPanel
      sessionIntegrationItems={filterIntegrationsByType(
        sessionInformation?.saasIntegrations,
        integrationType,
      )}
      isLoading={isLoading || isFetching}
      isError={isError}
      integrationType={integrationType}
      onIntegrationTypeChange={onIntegrationTypeChange}
    />
  );
}

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => ({
  onIntegrationTypeChange: () =>
    dispatch(sessionApi.util.invalidateTags([TAGS.SESSIONS])),
});

type DCloudIntegrationPanelProps = IntegrationPanelContainerProps &
  FetchSessionArgs &
  ReturnType<typeof mapDispatchToProps>;

export const DCloudIntegrationPanelContainer = connect(
  undefined,
  mapDispatchToProps,
)(DCloudIntegrationPanel);
