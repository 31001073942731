import React, { ReactElement } from 'react';
import { sessionApi } from '../../../../../../redux/views/session-view/session/api/api';
import { InfoLeftPanelProps } from './infoContainer';
import { PanelHeaderButtons } from '.';

type ConnectedDCloudInfoLeftPanelHeaderButtonsProps = InfoLeftPanelProps &
  FetchSessionArgs;

export function ConnectedDCloudInfoLeftPanelHeaderButtons({
  setLeftPanelType,
  sessionUid,
  versionUid,
}: ConnectedDCloudInfoLeftPanelHeaderButtonsProps): ReactElement {
  const { refetch } = sessionApi.endpoints.getSession.useQuerySubscription({
    sessionUid,
    versionUid,
  });

  return (
    <PanelHeaderButtons
      refreshOnClick={refetch}
      closeOnClick={() => setLeftPanelType(undefined)}
    />
  );
}
