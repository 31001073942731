import { connect } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  topologiesFiltersSelectors,
  addFilter,
  deleteFilter,
  addDataCenterFilter,
  deleteDataCenterFilter,
  setIsOpen,
} from '../../../../redux/topologiesFilters/slice';
import { Filters } from './index';

const mapDispatchToProps = {
  addDataCenterFilter,
  addFilter,
  deleteDataCenterFilter,
  deleteFilter,
  setIsOpen,
};

const mapStateToProps = (state: RootState) => ({
  dataCentersFilters: topologiesFiltersSelectors.getDataCentersFilters(state),
  isApplyingFilters: topologiesFiltersSelectors.getIsApplyingFilters(state),
  isOpen: topologiesFiltersSelectors.getIsOpen(state),
  showFilters: topologiesFiltersSelectors.getShowFilters(state),
});

export type FiltersProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const FilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
