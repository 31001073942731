import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { PageHeading } from '../../../../../../components/PageHeading';
import { determineEditPageTitle } from '../utils';

type EditSaaSIntegrationHeaderProps = {
  isLoading: boolean;
  integrationType: IntegrationType;
};

export function EditSaaSIntegrationHeader({
  isLoading,
  integrationType,
}: EditSaaSIntegrationHeaderProps): ReactElement {
  const { t } = useTranslation();
  const {
    formState: { isValid, dirtyFields },
  } = useFormContext();
  const isDirty = !!Object.keys(dirtyFields).length;
  return (
    <PageHeading
      useCustomStyling={true}
      withBackBtn={true}
      ctaBtn={
        <PrimaryButton
          disabled={!isValid || !isDirty}
          colour="success"
          type="submit"
          loading={isLoading}
          testId="saas-configure-save-button"
        >
          {t('buttons.save')}
        </PrimaryButton>
      }
      pageTitle={determineEditPageTitle(integrationType)}
    />
  );
}
