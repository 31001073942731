import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'dcloud-shared-ui';
import { capitalize, uniq } from 'lodash';
import classnames from 'classnames';
import styles from '../../SiteHeader/SiteHeader.module.css';
import i18n from '../../../../i18n';
import {
  clearUserPrefs,
  getUserPrefs,
  hasUserPreferenceChanged,
  UserPrefKey,
} from '../../../../utils/userPref';
import { InfoModal } from '../../../Modals/InfoModal';
import { SHOW_FILTERS } from '../../../../entities/topologies/components/TopologiesFilters/constants';
import { UserPreferencesModalProps } from './container';

const UserPreferencesModal = ({
  onClose,
  setIsSnapToGridEnabled,
}: UserPreferencesModalProps): ReactElement => {
  const { t } = useTranslation();
  const [, setSettingsSaved] = useState<boolean>(false);

  const getLabelFromFilter = (filterKey: string): string => {
    const label = SHOW_FILTERS.find((filter) => filter.filterKey === filterKey);
    return t(label!.label);
  };

  const colsContent = [
    {
      data: [
        {
          label: i18n.t('userPreferences.theme'),
          value: <div>{capitalize(getUserPrefs()[UserPrefKey.theme])}</div>,
        },
        {
          label: i18n.t('userPreferences.filters'),
          value: (
            <>
              {uniq(getUserPrefs()[UserPrefKey.filters]).map((filter) => (
                <div key={filter}>{getLabelFromFilter(filter)}</div>
              ))}
              {uniq(getUserPrefs()[UserPrefKey.dataCenterFilters]).map(
                (filter) => (
                  <div key={filter}>{filter}</div>
                ),
              )}
            </>
          ),
        },
        {
          label: i18n.t('userPreferences.viewType'),
          value: (
            <div>
              {capitalize(getUserPrefs()[UserPrefKey.topologiesViewType])}
            </div>
          ),
        },
        {
          label: i18n.t('userPreferences.autoAlign'),
          value: (
            <div>
              {(getUserPrefs()[UserPrefKey.autoAlign] || false).toString()}
            </div>
          ),
        },
      ],
    },
  ];

  const footerContent = (
    <div className="row dbl-margin-top">
      <div className="col-12">
        <Button
          testId="user-preferences-delete-all-button"
          className={classnames(styles.reset, 'base-margin-bottom')}
          colour="success"
          disabled={!hasUserPreferenceChanged()}
          onClick={() => {
            clearUserPrefs();
            setSettingsSaved(true);
            setIsSnapToGridEnabled(false);
          }}
        >
          {t('userPreferences.deleteAll')}
        </Button>
      </div>
      {t('userPreferences.note')}
    </div>
  );

  return (
    <InfoModal
      className={styles.infoModal}
      onClose={onClose}
      show={true}
      title={t('userPreferences.title')}
      colsContent={colsContent}
      closeButtonClass={styles.closeButton}
      footerContent={footerContent}
    />
  );
};

export default UserPreferencesModal;
