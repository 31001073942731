import React, { ReactElement } from 'react';
import {
  sessionApi,
  useCreateSessionDnsRecordMutation,
} from '../../../../../../../../redux/views/session-view/session/api/api';
import { getDnsAssetsFromSession, getDnsFromSession } from './utils';
import { DNS } from '.';

export function ConnectedDCloudDNS({
  sessionUid,
  versionUid,
}: FetchSessionArgs): ReactElement {
  const [createDnsTxtRecord, { isLoading: isUpdating }] =
    useCreateSessionDnsRecordMutation();
  const { dns, dnsAssets, isLoading, isFetching, isError } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
          dns: getDnsFromSession(data),
          dnsAssets: getDnsAssetsFromSession(data),
          isError,
          isFetching,
          isLoading,
        }),
      },
    );

  return (
    <DNS
      dns={dns}
      dnsAssets={dnsAssets}
      createDnsTxtRecord={createDnsTxtRecord}
      isLoading={isLoading || isFetching}
      isUpdating={isUpdating}
      isError={isError}
    />
  );
}
