import React, { ReactElement } from 'react';
import { PanelTableLoader } from '../../../../../../../../PanelTableLoader';

export type InternalIpPanelTableLoaderProps = {
  isLoading: boolean;
  isError: boolean;
};

export const InternalIpPanelTableLoader = ({
  isLoading,
  isError,
}: InternalIpPanelTableLoaderProps): ReactElement => {
  if (!isLoading && !isError) return <></>;

  return <PanelTableLoader />;
};
