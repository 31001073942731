import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';
import { TopologyLimitsWarningModalProps } from './container';
import { buildMessage } from './utils/utils';

export const TopologyLimitsWarningModal = ({
  label,
  resetTopologyLimits,
  onConfirmOverride,
  setTopologyLimitsWarningAccepted,
}: TopologyLimitsWarningModalProps): ReactElement => {
  const { t } = useTranslation();
  const onConfirm = onConfirmOverride
    ? onConfirmOverride
    : () => setTopologyLimitsWarningAccepted(true);
  return (
    <ConfirmModal
      heading={t('common.warning')}
      subHeading={label && buildMessage(label)}
      show={label !== undefined}
      onClose={() => resetTopologyLimits()}
      onCancel={() => resetTopologyLimits()}
      onConfirm={onConfirm}
      loading={false}
      confirmButtonLabel={t('buttons.continue')}
      confirmButtonColour="primary"
    />
  );
};
