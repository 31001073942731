import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';

export type HeaderPrimaryButtonProps = {
  isLoading: boolean;
  selectedHardwareItems?: InventoryHardwareItemToAdd[];
  onAddClicked: VoidFunction;
};

export const HeaderPrimaryButton = ({
  isLoading,
  selectedHardwareItems = [],
  onAddClicked,
}: HeaderPrimaryButtonProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <PrimaryButton
      colour="success"
      disabled={isLoading || !selectedHardwareItems.length}
      onClick={onAddClicked}
      loading={isLoading}
      testId="hardware-inventory-table-actions-add"
    >
      {t('buttons.add')}
    </PrimaryButton>
  );
};
