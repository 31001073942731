import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setSessionInventoryActiveTab,
} from '../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../redux/store';
import { SessionInventorySideTabs } from '.';

const mapStateToProps = (state: RootState) => ({
  activeTab: sessionViewSelectors.getSessionInventoryActiveTab(state),
});

const mapDispatchToProps = {
  setSessionInventoryActiveTab,
};

export type SessionInventorySideTabsProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const SessionViewSideTabsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionInventorySideTabs);
