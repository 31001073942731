import { dcloudApi } from '../api';
import { CONNECTIONS_PATH } from '../../api/paths';
import i18n from '../../i18n';
import {
  addDerivedPropertiesToConnections,
  updateAllConnectionsTotalValue,
} from '../diagram/utils';
import { diagramApi } from '../diagram/api';
import { TAGS } from '../api/constants';

export const connectionsApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createConnection: build.mutation<
      DiagramConnection,
      CreateConnectionPayload
    >({
      async onQueryStarted(
        { topology: { uid } },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: connection } = await queryFulfilled;
          dispatch(
            diagramApi.util.updateQueryData(
              'getDiagram',
              uid,
              (response: DiagramResponse) =>
                addDerivedPropertiesToConnections({
                  connection,
                  diagramResponse: response,
                }),
            ),
          );
          updateAllConnectionsTotalValue(connection);
        } catch {
          dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
        }
      },
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('diagram.add.error'),
        method: 'post',
        url: CONNECTIONS_PATH,
      }),
    }),
    deleteConnection: build.mutation<undefined, string>({
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
        }
      },
      query: (connectionUid) => ({
        errorMessage: i18n.t('diagram.delete.error'),
        method: 'delete',
        url: `${CONNECTIONS_PATH}/${connectionUid}`,
      }),
    }),
    updateConnection: build.mutation<Connection, UpdateConnectionPayload>({
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
        }
      },
      query: ({ display, connectionUid }) => ({
        data: { display },
        errorMessage: i18n.t('diagram.update.error'),
        method: 'patch',
        url: `${CONNECTIONS_PATH}/${connectionUid}`,
      }),
    }),
  }),
});

export const {
  useCreateConnectionMutation,
  useDeleteConnectionMutation,
  useUpdateConnectionMutation,
} = connectionsApi;
