import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setShowRenameSessionModal,
} from '../../../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../../../redux/store';
import { RenameSessionModal } from '.';

const mapStateToProps = (state: RootState) => ({
  showRenameSessionModal: sessionViewSelectors.getShowRenameSessionModal(state),
});

const mapDispatchToProps = {
  setShowRenameSessionModal,
};

export type RenameSessionModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const RenameSessionModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenameSessionModal);
