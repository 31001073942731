import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import styles from '../videos.module.css';

type Props = {
  title?: string;
  id?: string;
  setVideoToPlay?: (video: VideoToPlay) => void;
};

const FeaturedVideoTile = ({
  title,
  id,
  setVideoToPlay,
}: Props): ReactElement => {
  const isLoading = !id || !title || !setVideoToPlay;
  const onVideoClick = (event: { preventDefault: VoidFunction }) => {
    if (!isLoading) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setVideoToPlay({ id, title });
    }
    event.preventDefault();
  };
  return (
    <div className={styles.hero} onClick={onVideoClick}>
      {!isLoading && (
        <a data-testid="featuredVideoLink" href="" className={styles.heroLink}>
          <div className={styles.heroText}>
            <h1>{title}</h1>
            <div className={styles.iconWrapper}>
              <Icon name="right-arrow-closed-outline" className={styles.icon} />
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

export default FeaturedVideoTile;
