import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../Modals';

export type FormUnsavedChangesPromptProps = {
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  showModal?: boolean;
};

export const FormUnsavedChangesPrompt = ({
  onConfirm,
  onCancel,
  showModal = false,
}: FormUnsavedChangesPromptProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      show={showModal}
      heading={t('modals.formUnsavedChanges.heading')}
      subHeading={t('modals.formUnsavedChanges.subHeading')}
      onCancel={onCancel}
      onClose={onCancel}
      onConfirm={onConfirm}
      loading={false}
      confirmButtonLabel={t('modals.formUnsavedChanges.confirmButtonLabel')}
      cancelButtonLabel={t('modals.formUnsavedChanges.cancelButtonLabel')}
    />
  );
};
