import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, SelectOption } from 'dcloud-shared-ui';

export type ExternalDnsNoNatRulesAlertProps = {
  publicNatRuleOptions: SelectOption<string>[];
};

export const ExternalDnsNoNatRulesAlert = ({
  publicNatRuleOptions,
}: ExternalDnsNoNatRulesAlertProps): ReactElement => {
  const { t } = useTranslation();

  if (publicNatRuleOptions.length === 1)
    return (
      <Alert colour="info">
        {t('traffic.externalDns.noConfiguredNatRules')}
      </Alert>
    );

  return <></>;
};
