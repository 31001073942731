import { v4 as uuid } from 'uuid';
import { dcloudApi } from '../../api';
import {
  INVENTORY_LICENCES_PATH,
  LICENCES_PATH,
  TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { addToast, removeToast } from '../../toast/slice';
import { TAGS } from '../../api/constants';
import { setLicenceToEdit } from './slice';

export const licencesApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createLicence: build.mutation<Licence, CreateLicencePayload>({
      invalidatesTags: [TAGS.LICENCES],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('licences.add.error'),
        method: 'post',
        successMessage: i18n.t('licences.add.success'),
        url: `${LICENCES_PATH}`,
      }),
    }),
    deleteLicence: build.mutation<undefined, LicenceUid>({
      invalidatesTags: [TAGS.LICENCES],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const infoToastUid = uuid();
        try {
          dispatch(
            addToast({
              message: i18n.t('common.deleting'),
              title: '',
              type: 'info',
              uid: infoToastUid,
            }),
          );
          await queryFulfilled;
        } catch (err) {
          return;
        } finally {
          dispatch(removeToast(infoToastUid));
        }
      },
      query: (licenceUid) => ({
        errorMessage: i18n.t('licences.delete.error'),
        method: 'delete',
        successMessage: i18n.t('licences.delete.success'),
        url: `${LICENCES_PATH}/${licenceUid}`,
      }),
    }),
    getInventoryLicences: build.query<InventoryLicencesResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.LICENCES],
      query: (topologyUid) => ({
        errorMessage: i18n.t('inventoryLicences.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_LICENCES_PATH}`,
      }),
    }),
    getLicence: build.query<Licence, string>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(setLicenceToEdit(undefined));
        }
      },
      providesTags: [TAGS.LICENCE],
      query: (licenceUid) => ({
        errorMessage: i18n.t('licences.fetch.error'),
        method: 'get',
        url: `${LICENCES_PATH}/${licenceUid}`,
      }),
    }),
    getLicences: build.query<LicencesResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.LICENCES],
      query: (topologyUid) => ({
        errorMessage: i18n.t('licences.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${LICENCES_PATH}`,
      }),
    }),
    updateLicence: build.mutation<Licence, Omit<Licence, '_links'>>({
      invalidatesTags: [TAGS.LICENCES],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          return;
        } finally {
          dispatch(setLicenceToEdit(undefined));
        }
      },
      query: (licence) => ({
        data: licence,
        errorMessage: i18n.t('licences.edit.error'),
        method: 'put',
        successMessage: i18n.t('licences.edit.success'),
        url: `${LICENCES_PATH}/${licence.uid}`,
      }),
    }),
  }),
});

export const {
  useGetLicenceQuery,
  useGetLicencesQuery,
  useCreateLicenceMutation,
  useDeleteLicenceMutation,
  useGetInventoryLicencesQuery,
  useUpdateLicenceMutation,
} = licencesApi;
