import React, { AnchorHTMLAttributes, ReactElement } from 'react';

export interface ExternalLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  linkText: string;
  className?: string;
  rel?: string;
  testId?: string;
}

export const ExternalLink = ({
  linkText,
  className,
  testId,
  rel = 'noopener noreferrer',
  ...rest
}: ExternalLinkProps): ReactElement => {
  return (
    <a className={className} data-testid={testId} rel={rel} {...rest}>
      {linkText}
    </a>
  );
};
