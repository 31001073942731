import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../../../../constants/form';

export const getDnsFromSession = (
  data: Session | undefined,
): DnsItem[] | undefined => {
  if (!data) return undefined;

  const {
    sessionDetails: { sessionDnsEntries },
  } = data;

  return sessionDnsEntries.map((sessionDnsEntry) => {
    return {
      name: sessionDnsEntry.name,
      type: sessionDnsEntry.type,
    };
  });
};

export const getDnsAssetsFromSession = (
  data: Session | undefined,
): DnsAsset[] | undefined => {
  if (!data) return undefined;

  const {
    sessionDetails: { sessionDnsAssets },
  } = data;

  if (!sessionDnsAssets) {
    return undefined;
  }
  return sessionDnsAssets.map((sessionDnsAssets) => {
    return {
      name: sessionDnsAssets.name,
    };
  });
};

export const createDomainOptions = (
  dnsAssets: DnsAsset[],
): SelectOption<string>[] => {
  return [
    PLEASE_SELECT_OPTION,
    ...dnsAssets.map(({ name }) => ({ name, value: name })),
  ];
};
