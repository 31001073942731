import * as yup from 'yup';
import i18n from '../../../../../../i18n';

export const editValidationSchema = (
  takenNames: string[],
): yup.SchemaOf<Pick<Hardware, 'name'>> =>
  yup.object().shape({
    name: yup
      .string()
      .required(i18n.t('hardware.edit.nameRequiredError'))
      .max(255, i18n.t('hardware.edit.nameMaxLengthError'))
      .notOneOf(takenNames, i18n.t('hardware.edit.uniqueNameError')),
  });
