import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit';
import { SelectOption } from 'dcloud-shared-ui';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Control, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../../../../../../../components';
import { FormWrapper } from '../../../../../../../components/FormWrapper';
import { buildNatTypesOptions } from '../../utils';
import { NatRulesIpInfo } from '../NatRulesIpInfo';
import { NatRulesVmInfo } from '../NatRulesVmInfo';
import { NAT_RULE_TARGET_TYPES } from '../../../../../../../constants/nat-rules';
import styles from './NatRulesAddForm.module.css';
import { useShowTopologyLimitsWarningRules } from './hooks/use-show-topology-limits-warning-rules';
import { determineNatRuleTargetTypeFromNatRuleType } from './utils/utils';

export interface NatRulesAddFormProps {
  control: Control<NatRuleFormData>;
  errors: UseFormMethods['errors'];
  formState: UseFormMethods['formState'];
  isCreating: boolean;
  natType?: NatRuleFormData['type'];
  handleCreate: SubmitHandler<NatRuleFormData>;
  handleSubmit: UseFormMethods['handleSubmit'];
  vmIpAddressOptions: SelectOption<string>[];
  vmTargets?: VmNicTarget[];
  setVmIpAddressOptions: Dispatch<SetStateAction<SelectOption<string>[]>>;
  topologyLimitsWarningAccepted?: boolean;
  topologyLimit?: TopologyLimit;
  resetTopologyLimits: ActionCreatorWithoutPayload<string>;
  setShowTopologyLimitWarningForEntity: ActionCreatorWithOptionalPayload<
    | TopologyLimitsDisplayLabels
    | Array<TopologyLimitsDisplayLabels>
    | undefined,
    string
  >;
}

export function NatRulesAddForm({
  control,
  errors,
  formState,
  handleCreate,
  handleSubmit,
  isCreating,
  natType,
  setVmIpAddressOptions,
  vmIpAddressOptions,
  vmTargets,
  topologyLimit,
  resetTopologyLimits,
  topologyLimitsWarningAccepted,
  setShowTopologyLimitWarningForEntity,
}: NatRulesAddFormProps): ReactElement | null {
  const { t } = useTranslation();

  const { onAddClicked } = useShowTopologyLimitsWarningRules({
    add: handleCreate,
    resetTopologyLimits,
    setShowTopologyLimitWarningForEntity,
    topologyLimit,
    topologyLimitsWarningAccepted,
  });
  const natRuleTargetType = determineNatRuleTargetTypeFromNatRuleType(natType);

  return (
    <FormWrapper className={styles.root} onSubmit={handleSubmit(onAddClicked)}>
      <ControlledSelect
        className={styles.natTypeInput}
        control={control}
        options={buildNatTypesOptions()}
        label={t('traffic.natRules.table.headings.type')}
        name="type"
        required={true}
        error={errors?.type?.message}
        testId="natRulesSelect"
      />
      {natRuleTargetType === NAT_RULE_TARGET_TYPES.ip && (
        <NatRulesIpInfo
          isCreating={isCreating}
          formState={formState}
          control={control}
          errors={errors}
        />
      )}
      {natRuleTargetType === NAT_RULE_TARGET_TYPES.vmNic && (
        <NatRulesVmInfo
          control={control}
          errors={errors}
          formState={formState}
          isCreating={isCreating}
          setVmIpAddressOptions={setVmIpAddressOptions}
          vmIpAddressOptions={vmIpAddressOptions}
          vmTargets={vmTargets}
        />
      )}
    </FormWrapper>
  );
}
