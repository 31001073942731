import React, { ReactElement, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ControlledIconPicker } from '../../../../../../../components/Form/ControlledIconPickerButton/ControlledIconPicker';
import styles from './ConfigureFormIconInput.module.css';
import { getAvailableIconsForIntegrationType } from './utils';

type SaasConfigureFormIconInputProps = {
  initialSelectedIcon: string;
  integrationType: IntegrationType;
};

export function SaasConfigureFormIconInput({
  initialSelectedIcon,
  integrationType,
}: SaasConfigureFormIconInputProps): ReactElement {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [selectedIcon, setSelectedIcon] = useState<string>(initialSelectedIcon);

  const handleUpdateIcon = (newSelection: string) => {
    setSelectedIcon(newSelection);
    control.setValue('TBV3_userSaasIntegrationIconDefaultValue', newSelection, {
      shouldDirty: true,
    });
  };

  const icons = useMemo(() => {
    return getAvailableIconsForIntegrationType(integrationType);
  }, [integrationType]);

  const isIconFound = icons.find((icon) => icon.key === selectedIcon);
  return (
    <div className={classNames(styles.root, 'col-6')}>
      <ControlledIconPicker
        customControlName="TBV3_userSaasIntegrationIconDefaultValue"
        control={control}
        icons={icons}
        onChange={handleUpdateIcon}
        selectedIcon={selectedIcon || initialSelectedIcon}
        iconPickerLabel={t('integrations.forms.common.icon.label')}
        changeIconLabel={t(
          isIconFound ? 'buttons.changeIcon' : 'buttons.selectAnIcon',
        )}
      />
    </div>
  );
}
