import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { Table } from '../../../../../../components';
import { NetworkTableActions } from './NetworkTableActions';

interface NetworkTableProps {
  networkInterfaces: HardwareNetworkInterface[];
  onDeleteClick: (id: string) => void;
}

enum TableTranslations {
  Interface = 'interface',
  Network = 'network',
}

const translationKey = (key: TableTranslations) =>
  `hardware.edit.networkingInterface.table.${key}`;

export function NetworkTable({
  networkInterfaces,
  onDeleteClick,
}: NetworkTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<HardwareNetworkInterface>[]>(
    () => [
      {
        Cell: ({ value }) => value.id,
        Header: () => t(translationKey(TableTranslations.Interface)),
        accessor: 'networkInterface',
      },
      {
        Cell: ({ value }) => value.name,
        Header: () => t(translationKey(TableTranslations.Network)),
        accessor: 'network',
      },
    ],
    [t],
  );

  const renderActions = (
    networkInterface: HardwareNetworkInterface,
  ): ReactNode => {
    return (
      <NetworkTableActions
        onDeleteClick={() => onDeleteClick(networkInterface.uid)}
      />
    );
  };

  return (
    <div className="dbl-margin-top">
      <Table<HardwareNetworkInterface>
        actions={renderActions}
        data={networkInterfaces}
        columns={columns}
      />
    </div>
  );
}
