import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { ControlledTextfield } from '../../../../../../../components';
import { ConfigureFormReadOnlyInput } from '../ConfigureFormReadOnlyInput';
import { SaasConfigureFormIconInput } from '../ConfigureFormIconInput';
import { ConfigureHideOnSessionViewCheckbox } from '../ConfigureHideOnSessionViewCheckbox';
import { WorkFlowParameterMappings } from '../WorkFlowParameterMappings';

type SaasConfigureFormInputsProps = {
  inventorySaasTemplateToConfigure?: SaasTemplate;
  saasIntegrationToEdit?: SaasIntegration;
  isEditing?: boolean;
  integrationType: IntegrationType;
};

export function SaasConfigureFormInputs({
  inventorySaasTemplateToConfigure,
  saasIntegrationToEdit,
  isEditing,
  integrationType,
}: SaasConfigureFormInputsProps): ReactElement {
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  if (!inventorySaasTemplateToConfigure)
    return (
      <Alert colour="warning">
        {t('saas.configure.formErrors.errorCreatingForm')}
      </Alert>
    );

  return (
    <>
      <ConfigureFormReadOnlyInput
        label={t('saas.configure.formLabels.integrationName')}
        value={inventorySaasTemplateToConfigure?.name}
      />

      <div className="col-md-12 col-lg-6">
        <ControlledTextfield<AddSaasIntegrationFormFields>
          control={control}
          error={errors?.TBV3_userSaasIntegrationNameDefaultValue?.message}
          label={t('saas.configure.formLabels.name')}
          name="TBV3_userSaasIntegrationNameDefaultValue"
          id="add-saas-form-name-input"
          required={true}
          className="base-padding"
        />
      </div>

      <ConfigureFormReadOnlyInput
        label={t('saas.configure.formLabels.description')}
        value={inventorySaasTemplateToConfigure.description}
      />

      <ConfigureFormReadOnlyInput
        label="Details"
        value={inventorySaasTemplateToConfigure.details}
      />

      <ConfigureHideOnSessionViewCheckbox
        sessionView={inventorySaasTemplateToConfigure.sessionView}
        saasIntegrationToEdit={saasIntegrationToEdit}
      />

      <SaasConfigureFormIconInput
        integrationType={integrationType}
        initialSelectedIcon={
          control.defaultValuesRef.current
            .TBV3_userSaasIntegrationIconDefaultValue
        }
      />

      <WorkFlowParameterMappings
        inputs={inventorySaasTemplateToConfigure.inputs}
        userInputs={saasIntegrationToEdit?.inputs}
        isEditing={isEditing}
      />
    </>
  );
}
