import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'dcloud-shared-ui';
import { PanelTableLoader } from '../../../../PanelTableLoader';
import { WebLink } from './components/WebLink';
import { AttachmentContainer } from './components/Attachment/container';
import { DocumentationUrl } from './components/DocumentationUrl';

export type DocumentsProps = {
  documentationUrl?: string;
  documents?: SessionDocument[];
  isError: boolean;
  isLoading: boolean;
};

export function Documents({
  documentationUrl,
  documents,
  isError,
  isLoading,
}: DocumentsProps): ReactElement {
  const { t } = useTranslation();

  if (isLoading || isError) {
    return <PanelTableLoader />;
  }

  if (
    (!documents || !documents.length) &&
    (!documentationUrl || documentationUrl === '')
  ) {
    return (
      <Alert>
        {t('sessions.panels.documentation.documentation.noDocumentation')}
      </Alert>
    );
  }

  return (
    <ul>
      <DocumentationUrl href={documentationUrl} />
      {documents?.map((sessionDocument) => {
        switch (sessionDocument.internal) {
          case false:
            return (
              <WebLink
                sessionDocument={sessionDocument}
                key={sessionDocument.uid}
              />
            );
          case true:
            return (
              <AttachmentContainer
                sessionDocument={sessionDocument}
                key={sessionDocument.uid}
              />
            );
          default:
            return;
        }
      })}
    </ul>
  );
}
