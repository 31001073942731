import React, { ReactElement, useEffect, useState } from 'react';
import { useTheme } from 'dcloud-shared-ui';
import { ConnectedToastsContainer } from './components/ToastsContainer/container';
import { MainRouter } from './routers/MainRouter';
import { getUserPrefs, setUserPrefs, UserPrefKey } from './utils/userPref';

export function App(): ReactElement {
  const [theme, setTheme] = useTheme();
  const [hasInitialized, setInitialized] = useState<boolean>(false);
  const userPrefsObject = getUserPrefs();
  const userTheme = userPrefsObject.theme;
  const actualTheme = theme;
  useEffect(() => {
    if (!hasInitialized) {
      setInitialized(true);
      setTheme(userTheme);
    } else {
      setUserPrefs(UserPrefKey.theme, actualTheme);
    }
  }, [userTheme, actualTheme, setTheme, hasInitialized]);
  return (
    <>
      <MainRouter />
      <ConnectedToastsContainer />
    </>
  );
}
