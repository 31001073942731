import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledTextfield } from '../../../../../components';

export interface NetworkNameInputProps {
  control: UseFormMethods<NetworkFormFields>['control'];
  errorMesage?: string;
}

export function NetworkNameInput({
  control,
  errorMesage,
}: NetworkNameInputProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ControlledTextfield<NetworkFormFields>
      control={control}
      error={errorMesage}
      label={t('networks.form.name.label')}
      labelClass="col-3"
      name="name"
      required={true}
    />
  );
}
