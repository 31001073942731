import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import history from '../../../../../../../redux/history';
import { AddCellRendererProps } from './container';
import styles from './AddCellRenderer.module.css';

export const AddCellRenderer = ({
  inventorySaasTemplate,
  setInventorySaasTemplateToConfigure,
}: AddCellRendererProps): ReactElement => {
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const handleOnClick = () => {
    setInventorySaasTemplateToConfigure(inventorySaasTemplate);
    history.push(`${url}/configure`);
  };

  const isButtonDisabled = (): boolean =>
    inventorySaasTemplate.configuredQuantity >=
    inventorySaasTemplate.maxAmountPerTopology;

  const buttonStyles = classNames({
    [styles.addUserSaasInventoryButton]: !isButtonDisabled(),
  });

  return (
    <div className={classNames({ ['cursorNotAllowed']: isButtonDisabled() })}>
      <Button
        colour="ghost"
        onClick={handleOnClick}
        testId="template-add-action-btn"
        className={buttonStyles}
        disabled={isButtonDisabled()}
      >
        {t('buttons.add')}
      </Button>
    </div>
  );
};
