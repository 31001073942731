import classnames from 'classnames';
import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { IconNames } from 'dcloud-shared-ui';
import { AccordionRow } from './AccordionRow';

interface AccordionRowConfig {
  title: string;
  children: ReactNode;
  iconName?: IconNames;
  iconClassName?: string;
  imageComponent?: ReactElement;
  className?: string;
}

export interface AccordionProps {
  rows: AccordionRowConfig[];
  size?: 'default' | 'small';
  collapsed?: boolean;
}

export function Accordion({
  rows,
  size = 'default',
  collapsed,
}: AccordionProps): ReactElement {
  const [currentRowIndicies, setCurrentRowIndicies] = useState<number[]>(
    collapsed ? [] : [0],
  );

  const onRowClick = useCallback((newIndex: number) => {
    setCurrentRowIndicies((prevState) =>
      prevState.includes(newIndex)
        ? prevState.filter((num) => num !== newIndex)
        : [...prevState, newIndex],
    );
  }, []);

  return (
    <div className="accordions">
      {rows.map(
        (
          {
            title,
            children,
            className,
            iconName,
            iconClassName,
            imageComponent,
          },
          i,
        ) => (
          <AccordionRow
            className={classnames(
              { small: size === 'small' },
              'accordion-row-button',
              className,
            )}
            title={title}
            key={title}
            onRowClick={() => onRowClick(i)}
            isOpen={currentRowIndicies.includes(i)}
            id={`accordion-row-${i}`}
            iconName={iconName}
            iconClassName={iconClassName}
            imageComponent={imageComponent}
          >
            {children}
          </AccordionRow>
        ),
      )}
    </div>
  );
}
