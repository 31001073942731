import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../../components';

type SessionInventoryActionConfirmModalProps = {
  handleOnConfirm: VoidFunction;
  setShowConfirmForAction: SessionInventoryPayload;
  showConfirmForAction?:
    | SessionInventoryConfirmActionForVmPayload
    | SessionInventoryConfirmActionForHwPayload;
  actionLabel: string;
};

export const SessionInventoryActionConfirmModal = ({
  handleOnConfirm,
  setShowConfirmForAction,
  showConfirmForAction,
  actionLabel,
}: SessionInventoryActionConfirmModalProps): ReactElement => {
  const { t } = useTranslation();
  const [isRequestingAction, setIsRequestingAction] = useState(false);

  const onConfirm = () => {
    setIsRequestingAction(true);
    handleOnConfirm();
  };

  useEffect(() => {
    setIsRequestingAction(showConfirmForAction === undefined);
  }, [showConfirmForAction]);

  return (
    <ConfirmModal
      show={showConfirmForAction !== undefined}
      heading={t('sessionsInventory.modal.heading')}
      subHeading={t('sessionsInventory.modal.warning') + t(actionLabel)}
      onCancel={() => setShowConfirmForAction(undefined)}
      onClose={() => setShowConfirmForAction(undefined)}
      onConfirm={() => onConfirm()}
      confirmButtonColour="primary"
      loading={isRequestingAction}
      confirmButtonLabel={t('buttons.confirm')}
      cancelButtonLabel={t('buttons.cancel')}
      testId="session-inventory-action-modal"
    />
  );
};
