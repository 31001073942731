import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { WorkFlowParameterMapping } from './components/WorkFlowParameterMapping';
import styles from './WorkFlowParameterMappings.module.css';

export type WorkFlowParameterMappingsProps = {
  inputs?: SaasTemplateInput[];
  userInputs?: SaasIntegrationInput[];
  isEditing?: boolean;
};

export const WorkFlowParameterMappings = ({
  inputs,
  userInputs,
  isEditing,
}: WorkFlowParameterMappingsProps): ReactElement => {
  const { t } = useTranslation();
  const renderParams = () => {
    return orderBy(inputs, ['mappingName'])?.map((input) => {
      return (
        <WorkFlowParameterMapping
          input={input}
          userInputs={userInputs}
          key={input.mappingName}
          isEditing={isEditing}
        />
      );
    });
  };

  return (
    <div className={classNames('dbl-padding-top', styles.root)}>
      <p className={styles.heading}>
        {t('saas.add.workFlowParametersSubHeading')}
      </p>
      {inputs?.length ? (
        renderParams()
      ) : (
        <Alert>{t('saas.add.noWorkFlowParams')}</Alert>
      )}
    </div>
  );
};
