import React, { ChangeEvent, ReactElement } from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { templateVmNetworkInterfaceFieldsSchema } from '../../../../../../../../validation';
import { TemplateToastToAdd } from '../../../../../../../../redux/toast/slice';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextfield,
} from '../../../../../../../../components/Form';
import { getNicTypeOptions } from '../../../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/Networking/utils';
import { DEFAULT_NETWORK } from '../../../../../../../../api/networks/fixtures';
import { DEFAULT_NETWORK_SUBNET } from '../../../../../../../../utils/constants';
import { PrimaryButton } from '../../../../../../../../components/Buttons/PrimaryButton';
import styles from './TemplateNetworkingForm.module.css';

export interface TemplateNetworkingFormData {
  assignDhcp?: boolean;
  ipAddress?: string;
  macAddress?: string;
  type: string;
}

interface TemplateNetworkingFormProps {
  addVMNetworkInterface: ActionCreatorWithPayload<
    VmNetworkInterfaceToEdit,
    string
  >;
  addErrorToast: ActionCreatorWithPayload<TemplateToastToAdd, string>;
  nicTypes: VmNetworkInterfaceType[];
  nicToEdit?: VmNetworkInterfaceToEdit;
  setNicToEdit: ActionCreatorWithOptionalPayload<
    VmNetworkInterfaceToEdit | undefined,
    string
  >;
  totalNics: number;
  vmNetworkInterfaces?: VmNetworkingToEdit;
  vmToEdit?: VirtualMachine;
  limits: Limits;
}

export function TemplateNetworkingForm({
  addVMNetworkInterface,
  addErrorToast,
  nicTypes,
  totalNics,
  nicToEdit,
  setNicToEdit,
  limits,
}: TemplateNetworkingFormProps): ReactElement {
  const { t } = useTranslation();
  const formSectionClasses = classnames('col-2', styles.formSection);
  const buttonGroupClasses = classnames('col-4', styles.formSection);
  const checkBoxClasses = classnames('col-2', styles.assignDhcpFormElement);

  const { control, errors, handleSubmit, reset, formState, trigger, setValue } =
    useForm<TemplateNetworkingFormData>({
      defaultValues: {
        assignDhcp: nicToEdit?.assignDhcp || true,
        ipAddress: nicToEdit?.ipAddress || '',
        macAddress: nicToEdit?.macAddress || '',
        type: nicToEdit?.type || '',
      },
      mode: 'all',
      resolver: yupResolver(
        templateVmNetworkInterfaceFieldsSchema(limits, DEFAULT_NETWORK_SUBNET),
      ),
    });

  const onAddClicked: SubmitHandler<TemplateNetworkingFormData> = async (
    formData,
  ) => {
    if (totalNics >= 10 && !nicToEdit) {
      addErrorToast({
        message: t('virtualMachines.edit.networking.maxLimit'),
      });
      return;
    }

    const formDataWithTempUid = {
      ...formData,
      inUse: nicToEdit?.inUse || false,
      network: DEFAULT_NETWORK,
      uid: nicToEdit ? nicToEdit.uid : `temp-${v4()}`,
    };
    addVMNetworkInterface(formDataWithTempUid);
    reset();
  };
  const { isDirty, isValid } = formState;

  const handleDhcpChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await setValue('assignDhcp', e.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await setValue('ipAddress', control.getValues().ipAddress, {
      shouldDirty: true,
      shouldValidate: true,
    });
    trigger('ipAddress');
  };

  return (
    <>
      <div aria-label="VM Add Networking form" className={styles.root}>
        <div className={formSectionClasses}>
          <ControlledSelect
            control={control}
            label={t('virtualMachines.edit.networking.type')}
            horizontal={false}
            options={getNicTypeOptions(nicTypes)}
            defaultValue={nicToEdit?.type}
            name="type"
            loading={false}
            testId="edit-vm-networking-nic-type-select"
            required={true}
            error={errors?.type?.message}
          />
        </div>
        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            error={errors?.macAddress?.message}
            defaultValue={nicToEdit?.macAddress}
            label={t('virtualMachines.edit.networking.macAddress')}
            name="macAddress"
            required={false}
            testId="edit-vm-networking-mac-address-input"
          />
        </div>
        <div className={formSectionClasses}>
          <ControlledTextfield
            control={control}
            defaultValue={nicToEdit?.ipAddress}
            error={errors?.ipAddress?.message}
            label={t('virtualMachines.edit.networking.ipAddress')}
            name="ipAddress"
            required={
              nicToEdit?.inUse ||
              nicToEdit?.assignDhcp ||
              control.getValues().assignDhcp
            }
            testId="edit-vm-networking-ip-address-input"
          />
        </div>
        <div className={checkBoxClasses}>
          <ControlledCheckbox
            className={styles.checkbox}
            control={control}
            defaultValue={true}
            customOnChange={async (e) => handleDhcpChange(e)}
            id="assignDhcp"
            label={t('virtualMachines.edit.networking.dhcp.title')}
            testId="edit-vm-networking-enable-dhcp"
          />
        </div>
        <div className={buttonGroupClasses}>
          <PrimaryButton
            className={styles.button}
            type="button"
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onAddClicked)}
            testId="edit-vm-networking-add-button"
          >
            {t('buttons.add')}
          </PrimaryButton>
          {nicToEdit && (
            <PrimaryButton
              className={styles.button}
              type="button"
              colour="danger"
              onClick={() => setNicToEdit(undefined)}
              testId="edit-vm-networking-cancel-button"
            >
              {t('buttons.cancel')}
            </PrimaryButton>
          )}
        </div>
      </div>

      <Alert colour="info">
        {t('virtualMachines.edit.networking.warnings.changesInfo')}
      </Alert>
    </>
  );
}
