import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface TemplateManagerState {
  allArchitectures: Architecture[];
  dataCenters: DataCenterFromApi[];
  selectedInventoryItem?: VmTemplate;
  selectedArchitectures?: Architecture[];
  selectedDataCenter?: DataCenter;
  selectedDemoTemplate?: DemoTemplate;
}

export const initialState: TemplateManagerState = {
  allArchitectures: [],
  dataCenters: [],
  selectedArchitectures: undefined,
  selectedDataCenter: undefined,
  selectedDemoTemplate: undefined,
  selectedInventoryItem: undefined,
};

const slice = createSlice({
  initialState,
  name: 'templateManager',
  reducers: {
    addSelectedArchitecture: (
      state,
      { payload }: PayloadAction<Architecture>,
    ) => {
      state.selectedArchitectures
        ? state.selectedArchitectures.push(payload)
        : (state.selectedArchitectures = [payload]);
    },
    removeSelectedArchitecture: (state, { payload }: PayloadAction<string>) => {
      state.selectedArchitectures =
        state.selectedArchitectures &&
        state.selectedArchitectures.filter(
          (architecture) => architecture.uid !== payload,
        );
    },
    setAllArchitectures: (
      state,
      { payload }: PayloadAction<Architecture[]>,
    ) => {
      state.allArchitectures = payload;
    },
    setDataCenters: (
      state,
      { payload }: PayloadAction<DataCenterFromApi[]>,
    ) => {
      state.dataCenters = payload;
    },
    setSelectedArchitectures: (
      state,
      { payload }: PayloadAction<Architecture[] | undefined>,
    ) => {
      state.selectedArchitectures = payload;
    },
    setSelectedDataCenter: (
      state,
      { payload }: PayloadAction<DataCenter | undefined>,
    ) => {
      state.selectedDataCenter = payload;
    },
    setSelectedDemoTemplate: (
      state,
      { payload }: PayloadAction<DemoTemplate | undefined>,
    ) => {
      if (payload === undefined) {
        state.selectedArchitectures = undefined;
      }
      state.selectedDemoTemplate = payload;
    },
    setSelectedInventoryItem: (
      state,
      { payload }: PayloadAction<VmTemplate | undefined>,
    ) => {
      if (payload === undefined) {
        state.selectedArchitectures = undefined;
      }
      state.selectedInventoryItem = payload;
    },
  },
});

export const getTemplateManagerState = (
  state: RootState,
): TemplateManagerState => state[slice.name];

export const templateManagerSelectors = {
  getAllArchitectures: createSelector(
    getTemplateManagerState,
    ({ allArchitectures }) => allArchitectures,
  ),
  getDataCenters: createSelector(
    getTemplateManagerState,
    ({ dataCenters }) => dataCenters,
  ),
  getSelectedArchitectures: createSelector(
    getTemplateManagerState,
    ({ selectedArchitectures }) => selectedArchitectures,
  ),
  getSelectedDataCenter: createSelector(
    getTemplateManagerState,
    ({ selectedDataCenter }) => selectedDataCenter,
  ),
  getSelectedDemoTemplate: createSelector(
    getTemplateManagerState,
    ({ selectedDemoTemplate }) => selectedDemoTemplate,
  ),
  getSelectedInventoryItem: createSelector(
    getTemplateManagerState,
    ({ selectedInventoryItem }) => selectedInventoryItem,
  ),
};
export const { reducer: templateManagerReducer } = slice;
export const {
  addSelectedArchitecture,
  setAllArchitectures,
  setDataCenters,
  setSelectedDataCenter,
  setSelectedDemoTemplate,
  setSelectedArchitectures,
  setSelectedInventoryItem,
  removeSelectedArchitecture,
} = slice.actions;
