import React, { ReactElement } from 'react';
import { ColDef } from 'ag-grid-community';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { GridEditor } from '../../../../../../../components/GridEditor';
import { HardwareTableActionsCellRendererWrapper } from '../HardwareTableActionsCellRendererWrapper';
import { SelectCellRendererContainer } from '../../../SelectCellRenderer/container';
import { SelectAllCellRendererContainer } from '../../../SelectAllCellRenderer/container';
import { isPowerControlAvailableInDevices } from './utils';

type SessionInventoryHardwareTableProps = {
  hardware?: SessionInventoryHardwareData[];
};

export const SessionInventoryHardwareTable = ({
  hardware,
}: SessionInventoryHardwareTableProps): ReactElement => {
  const { t } = useTranslation();
  const columns: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: 'select',
      cellRendererParams: { page: 'hardware' },
      cellStyle: { 'white-space': 'normal' },
      field: 'hw-multi-select-actions-col',
      flex: 1,
      headerComponent: 'selectAll',
      headerComponentParams: {
        isDisabled: !isPowerControlAvailableInDevices(hardware),
        page: 'hardware',
      },
      maxWidth: 80,
      minWidth: 80,
      valueGetter: (cellRendererParams) => ({
        isHidden: cellRendererParams.data.powerControl === 'No',
      }),
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'name',
      flex: 1,
      headerName: t('sessionsInventory.hardware.table.headings.name'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'deviceType',
      flex: 1,
      headerName: t('sessionsInventory.hardware.table.headings.deviceType'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'description',
      flex: 1,
      headerName: t('sessionsInventory.hardware.table.headings.description'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'interfaces',
      flex: 1,
      headerName: t('sessionsInventory.hardware.table.headings.interfaces'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'powerControl',
      flex: 1,
      headerName: t('sessionsInventory.hardware.table.headings.powerControl'),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellStyle: { 'white-space': 'normal' },
      field: 'hwConsole',
      flex: 1,
      headerName: t(
        'sessionsInventory.hardware.table.headings.hardwareConsole',
      ),
      minWidth: 150,
    },
    {
      autoHeight: true,
      cellRenderer: 'actions',
      cellStyle: { 'white-space': 'normal' },
      field: 'actions',
      flex: 1,
      headerName: t('sessionsInventory.hardware.table.headings.actions'),
      minWidth: 150,
    },
  ];

  if (hardware && hardware.length > 0) {
    return (
      <>
        {hardware.length > 0 ? (
          <GridEditor
            columns={columns}
            data={hardware}
            customComponents={{
              actions: HardwareTableActionsCellRendererWrapper,
              select: SelectCellRendererContainer,
              selectAll: SelectAllCellRendererContainer,
            }}
          />
        ) : (
          <Alert>{t('sessionsInventory.hardware.noItemsToDisplay')}</Alert>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
