export const hasDuplicateScenarioOptions = (
  scenarioOptions: ScenarioOption[] | undefined,
): boolean =>
  scenarioOptions === undefined
    ? false
    : scenarioOptions.some((scenarioOption1, index1) =>
        scenarioOptions.find(
          (scenarioOption2, index2) =>
            index1 !== index2 &&
            (scenarioOption1.displayName === scenarioOption2.displayName ||
              scenarioOption1.internalName === scenarioOption2.internalName),
        ),
      );
