import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpButton } from '../../../../../components/HelpButton';
import { PageHeading } from '../../../../../components/PageHeading';
import { EXTERNAL_DNS_HELP_LINKS } from '../../../../../constants/user-guide';
import { ExternalDnsProps } from './container';
import { buildNatOptions } from './utils';
import { ExternalDnsAddFormContainersHelper } from './ExternalDnsAddFormContainersHelper';
import { ExternalDnsTableHelper } from './ExternalDnsTableHelper';
import { ExternalDnsNoRecordsAlert } from './ExternalDnsNoRecordsAlert';
import { ExternalDnsNoNatRulesAlert } from './ExternalDnsNoNatRulesAlert';
import { ExternalDnsDeleteModalHelper } from './ExternalDnsDeleteModalHelper';

export function ExternalDNS({
  isError,
  isLoading,
  externalDNSRecord,
  externalDnsRecordToDelete,
  setExternalDnsToDelete,
  natRules,
  userRoles,
}: ExternalDnsProps): ReactElement | null {
  const { t } = useTranslation();
  const publicNatRuleOptions = buildNatOptions(natRules || []);

  return (
    <section className="half-padding-left">
      <PageHeading pageTitle={t('traffic.externalDns.name')} />
      <ExternalDnsAddFormContainersHelper
        isLoading={isLoading}
        publicNatRuleOptions={publicNatRuleOptions}
        userRoles={userRoles}
      />
      <ExternalDnsNoRecordsAlert
        externalDNSRecord={externalDNSRecord}
        isLoading={isLoading}
      />
      <ExternalDnsTableHelper
        externalDNSRecord={externalDNSRecord}
        isLoading={isLoading}
        isError={isError}
        setExternalDnsToDelete={setExternalDnsToDelete}
      />
      <ExternalDnsNoNatRulesAlert publicNatRuleOptions={publicNatRuleOptions} />
      <ExternalDnsDeleteModalHelper
        setExternalDnsToDelete={setExternalDnsToDelete}
        externalDnsRecordToDelete={externalDnsRecordToDelete}
      />
      <HelpButton links={EXTERNAL_DNS_HELP_LINKS} />
    </section>
  );
}
