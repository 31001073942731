import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useGetVmNetworkInterfaceTypesQuery } from '../../../../../../redux/configuration/virtual-machine-entities/api';
import {
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setNicToEdit,
  getVirtualMachines,
  updateVMNetworkInterface,
} from '../../../../../../redux/configuration/virtual-machine-entities/slice';
import { getNicTypes } from '../../../../../../redux/entities/nic-types/slice';
import { useGetLimitsQuery } from '../../../../../../redux/limitations/api';
import { RootState } from '../../../../../../redux/store';
import { addErrorToast } from '../../../../../../redux/toast/slice';
import { TemplateNetworking } from '.';

function TemplateNetworkingContainer({
  addErrorToast,
  networkingToEdit,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setNicToEdit,
  nicToEdit,
  nicTypes,
  updateVMNetworkInterface,
  vmToEdit,
}: TemplateNetworkingContainerProps): ReactElement {
  const { isLoading: isLoadingNicTypes, isError: isErrorLoadingNicTypes } =
    useGetVmNetworkInterfaceTypesQuery(undefined, {
      skip: nicTypes !== undefined,
    });

  const {
    data: limitsData,
    isLoading: isLimitsLoading,
    isError: isLimitsError,
  } = useGetLimitsQuery(undefined);
  return (
    <TemplateNetworking
      networkingToEdit={networkingToEdit}
      isErrorLoadingNicTypes={isErrorLoadingNicTypes}
      isErrorLoadingNetworks={false}
      addErrorToast={addErrorToast}
      addVMNetworkInterface={addVMNetworkInterface}
      deleteVMNetworkInterface={deleteVMNetworkInterface}
      nicToEdit={nicToEdit}
      nicTypes={nicTypes}
      setNicToEdit={setNicToEdit}
      isLoadingLimits={isLimitsLoading || isLimitsError}
      isLoadingNicTypes={isLoadingNicTypes}
      isLoadingNetworks={false}
      limits={limitsData}
      updateVMNetworkInterface={updateVMNetworkInterface}
      vmToEdit={vmToEdit}
    />
  );
}

type TemplateNetworkingContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

interface TemplateNetworkingRTKProps {
  isErrorLoadingNicTypes: boolean;
  isErrorLoadingNetworks: boolean;
  nicTypes?: VmNetworkInterfaceType[];
  isLoadingLimits: boolean;
  isLoadingNicTypes: boolean;
  isLoadingNetworks: boolean;
  limits?: Limits;
}

export type TemplateNetworkingProps = TemplateNetworkingContainerProps &
  TemplateNetworkingRTKProps;

const mapStateToProps = (state: RootState) => ({
  networkingToEdit: getVirtualMachines(state).networkingToEdit,
  nicToEdit: getVirtualMachines(state).nicToEdit,
  nicTypes: getNicTypes(state).nicTypes,
  vmToEdit: getVirtualMachines(state).virtualMachineToEdit,
});

const mapDispatchToProps = {
  addErrorToast,
  addVMNetworkInterface,
  deleteVMNetworkInterface,
  setNicToEdit,
  updateVMNetworkInterface,
};

export const ConnectedTemplateNetworking = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateNetworkingContainer);
