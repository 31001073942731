import React, { ReactElement, useState } from 'react';
import { Checkbox, Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styles from './IntegrationsFilter.module.css';
import { IntegrationsFilterProps } from './container';

export const IntegrationsFilter = ({
  setPublicCloudFilter,
  setSaasFilter,
}: IntegrationsFilterProps): ReactElement => {
  const [filterCollapseStatus, setfilterCollapseStatus] = useState(true);
  const { t } = useTranslation();

  return (
    <div className={styles.integrationsFilter}>
      <button
        type="button"
        className={styles.filterButton}
        onClick={() => setfilterCollapseStatus(!filterCollapseStatus)}
        data-testid="integrations-filter-button"
        aria-expanded={filterCollapseStatus ? 'true' : 'false'}
      >
        <span className={styles.filterHeading}>
          {t('integrations.filterAccordian.accordianTitle')}
        </span>
        <Icon
          name={filterCollapseStatus ? 'chevron-down' : 'chevron-up'}
          className={styles.collapseIcon}
        />
      </button>
      <div
        hidden
        className={classnames(
          styles.filterContent,
          filterCollapseStatus ? styles.hideFilter : null,
          'integrations-filter-content',
        )}
      >
        <span className={styles.filterHeading}>
          {t('integrations.filterAccordian.accordianTypes')}
        </span>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            onChange={(e) => setSaasFilter(e.target.checked)}
            className={styles.checkbox}
            id="integrations-filter-saas"
            label={t('integrations.type.saas')}
            data-testid="integrations-filter-saas-checkbox"
            defaultChecked={true}
          />
        </div>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            onChange={(e) => setPublicCloudFilter(e.target.checked)}
            className={styles.checkbox}
            id="integrations-filter-public-cloud"
            label={t('integrations.type.publicCloud')}
            data-testid="integrations-filter-public-cloud-checkbox"
            defaultChecked={true}
          />
        </div>
      </div>
    </div>
  );
};
