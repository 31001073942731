import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useGetVmNicTargetsQuery } from '../../../../../redux/configuration/traffic/api';
import {
  useCreateInboundProxyRuleMutation,
  useGetInboundProxyRulesQuery,
} from '../../../../../redux/configuration/traffic/inbound-proxy-rules/api';
import {
  getInboundProxyRules,
  setInboundProxyRuleToDelete,
} from '../../../../../redux/configuration/traffic/inbound-proxy-rules/slice';
import { RootState } from '../../../../../redux/store';
import { useGetLimitsQuery } from '../../../../../redux/limitations/api';
import { InboundProxyRulesLoader } from './components/InboundProxyRulesLoader';
import { InboundProxyRules } from '.';

export function InboundProxyRulesContainer({
  inboundProxyRuleToDelete,
  setInboundProxyRuleToDelete,
}: InboundProxyRulesContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const {
    data: inboundProxyRules,
    isError: isErrorFetchingInboundProxyRules,
    isLoading: isLoadingInboundProxyRules,
  } = useGetInboundProxyRulesQuery(topologyUid);

  const [createInboundProxyRule, { isLoading: isCreating }] =
    useCreateInboundProxyRuleMutation();

  const {
    data: vmTargetsData,
    isLoading: isLoadingTargets,
    isFetching: isFetchingTargets,
    isError: isErrorFetchingTargets,
  } = useGetVmNicTargetsQuery(topologyUid);

  const {
    data: limitsData,
    isLoading: isLimitsLoading,
    isError: isLimitsError,
  } = useGetLimitsQuery(undefined);

  if (
    isLoadingInboundProxyRules ||
    isLoadingTargets ||
    isFetchingTargets ||
    isLimitsLoading ||
    isLimitsError
  ) {
    return <InboundProxyRulesLoader />;
  }

  return (
    <InboundProxyRules
      inboundProxyRules={inboundProxyRules?.inboundProxyRules}
      inboundProxyRuleToDelete={inboundProxyRuleToDelete}
      isError={isErrorFetchingInboundProxyRules || isErrorFetchingTargets}
      isLoading={false}
      vmTargets={vmTargetsData?.internalIps || []}
      setInboundProxyRuleToDelete={setInboundProxyRuleToDelete}
      createInboundProxyRule={createInboundProxyRule}
      isCreating={isCreating}
      limits={limitsData!}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  inboundProxyRuleToDelete:
    getInboundProxyRules(state).inboundProxyRuleToDelete,
});

const mapDispatchToProps = {
  setInboundProxyRuleToDelete,
};

interface InboundProxyRulesRTKProps {
  inboundProxyRules?: InboundProxyRule[];
  isError: boolean;
  isLoading: boolean;
  vmTargets: VmNicTarget[];
}

export type InboundProxyRulesContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

export type InboundProxyRulesProps = InboundProxyRulesRTKProps &
  Omit<
    InboundProxyRulesContainerProps,
    'setIpAddressOptionsByVm' | 'ipAddressOptionsByVM'
  > & {
    createInboundProxyRule: RTKMutation<
      InboundProxyRulePostPayload,
      InboundProxyRule
    >;
    isCreating: boolean;
    limits: Limits;
  };

export const ConnectedInboundProxyRules = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InboundProxyRulesContainer);
