import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { MainRoutes } from '../../../constants/routes';

interface NoDataCenterRedirectRouteProps extends RouteProps {
  datacenter?: DataCenter;
}

export function NoDataCenterRedirectRoute({
  datacenter,
  ...props
}: NoDataCenterRedirectRouteProps): ReactElement {
  if (!datacenter) {
    return <Redirect to={MainRoutes.Templates} />;
  }

  return <Route {...props} />;
}
