import React, { ReactElement } from 'react';
import { useDeleteTelephonyItemMutation } from '../../../../../redux/configuration/telephony/api';
import { PSTNValueLoader } from '../PSTNValueLoader';
import { TelephonyDelete } from './';

interface TelephonyDeleteContainerProps {
  telephonyItemUid: string;
}

export function TelephonyDeleteContainer({
  telephonyItemUid,
}: TelephonyDeleteContainerProps): ReactElement {
  const [deleteTelephonyItem, { isLoading, isError }] =
    useDeleteTelephonyItemMutation();

  if (isLoading || isError) {
    return <PSTNValueLoader />;
  }

  return (
    <TelephonyDelete onDelete={() => deleteTelephonyItem(telephonyItemUid)} />
  );
}
