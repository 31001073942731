import React, { ReactElement } from 'react';
import { Alert } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../components';

export function PSTNNotSupportedBanner(): ReactElement {
  const { t } = useTranslation();
  return (
    <section className="half-padding-left">
      <PageHeading text={t('telephony.name')} />
      <Alert>{t('telephony.alertText')}</Alert>
    </section>
  );
}
