import React, { ReactElement } from 'react';
import { HardwareProps } from '../container';
import { useGetNodeQuery } from '../../../../../../redux/diagram/api';
import { extractIdFromUrl } from '../../../../../../utils/helpers';
import { TableLoader } from '../../../../../../components/Table/TableLoader';
import { Form } from './';

type FormContainerProps = Omit<
  HardwareProps,
  'deviceIdToConfigureInDiagram' | 'onModalClose'
>;

export function FormContainer({
  allHardware,
  hardwareItem,
  isUpdating,
  networkInterfaceToDelete,
  setNetworkInterfaceToDelete,
  updateHardwareItem,
  updateNode,
  setDeviceIdToConfigureInDiagram,
  isModal,
}: FormContainerProps): ReactElement {
  const { data: nodeData, isLoading } = useGetNodeQuery(
    extractIdFromUrl(hardwareItem!._links.node.href),
  );

  return isLoading ? (
    <TableLoader />
  ) : (
    <Form
      allHardware={allHardware}
      updateHardwareItem={updateHardwareItem}
      hardwareItem={hardwareItem}
      isUpdating={isUpdating}
      nodeData={nodeData}
      setNetworkInterfaceToDelete={setNetworkInterfaceToDelete}
      networkInterfaceToDelete={networkInterfaceToDelete}
      updateNode={updateNode}
      setDeviceIdToConfigureInDiagram={setDeviceIdToConfigureInDiagram}
      isModal={isModal}
    />
  );
}
