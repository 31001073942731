import React, { ReactElement } from 'react';
import { LoaderDots } from 'dcloud-shared-ui';
import { NO_TEXT } from '../../../../constants';
import { VmWareToolsCellRendererProps } from './container';

export const VmWareToolsCellRenderer = ({
  loadingStatus,
  vmWareToolsValue,
  isLoadingAllStatuses,
}: VmWareToolsCellRendererProps): ReactElement => {
  const renderStatus = () => {
    switch (loadingStatus) {
      case 'fulfilled':
        return vmWareToolsValue ? vmWareToolsValue : NO_TEXT;
      case 'pending':
        return <LoaderDots />;
      default:
        return isLoadingAllStatuses ? <LoaderDots /> : NO_TEXT;
    }
  };
  return <span aria-label="VMWare Tools">{renderStatus()}</span>;
};
