import React, { ReactElement } from 'react';
import {
  sessionApi,
  usePostSessionHwActionMutation,
} from '../../../../../../../../redux/views/session-view/session/api/api';
import {
  getConsoleHrefFromSessionDetails,
  getDeviceIdFromSessionDetails,
  getPduControlFromSessionDetails,
} from '../../../../../utils';
import { ControlContainerProps } from './container';
import { HWControls } from '.';

type ConnectedDCloudControlProps = ControlContainerProps & FetchSessionArgs;

export function ConnectedDCloudControl({
  nodeUid,
  sessionUid,
  versionUid,
}: ConnectedDCloudControlProps): ReactElement {
  const { consoleHref, deviceId, pduControl } =
    sessionApi.endpoints.getSession.useQueryState(
      { sessionUid, versionUid },
      {
        selectFromResult: ({ data }) => ({
          consoleHref: getConsoleHrefFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
          deviceId: getDeviceIdFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
          pduControl: getPduControlFromSessionDetails(
            (
              data?.diagram.nodes.filter(
                (node) => node.uid === nodeUid,
              )[0] as SessionHwNode
            ).sessionDetails,
          ),
        }),
      },
    );
  const [postSessionHwAction] = usePostSessionHwActionMutation();

  const postHwAction = (action: SessionHwActions) => {
    if (!pduControl || !deviceId) return;

    postSessionHwAction({
      action,
      deviceId: deviceId,
      sessionId: sessionUid,
      topologyVersion: { uid: versionUid },
    });
  };

  return (
    <HWControls
      consoleHref={consoleHref}
      postHwAction={postHwAction}
      pduControl={pduControl}
    />
  );
}
