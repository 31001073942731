import axios from 'axios';
import { cloudSSOEndpoint } from './paths';

const cloudSSO = axios.create({
  baseURL: cloudSSOEndpoint,
});

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_FULL_AUTH_URL?.includes('4000')
) {
  cloudSSO.defaults.withCredentials = true;
}

export default cloudSSO;
