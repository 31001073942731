import React, { ReactElement } from 'react';
import { format } from 'date-fns';
import { ArchitectureLabelGroup } from '../../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { LAST_UPDATED_DATE_FORMAT } from '../../../constants';
import i18n from '../../../../../../../i18n';
import styles from './DemosTabContent.module.css';

const i18nPrefix = 'inventoryTopologies.infoModal.headings.';

export const buildInfoColsData = (
  showMoreInfoForDemoTemplate: InventoryTopology,
): InfoModalData[] => {
  return [
    {
      data: buildInfoCol1Data(showMoreInfoForDemoTemplate),
    },
    {
      data: buildInfoCol2Data(showMoreInfoForDemoTemplate),
    },
  ];
};

export const buildInfoCol1Data = (
  showMoreInfoForDemoTemplate: InventoryTopology,
): InfoModalDataItem[] => {
  const { datacenter, lastUpdated, originalName } = showMoreInfoForDemoTemplate;

  return [
    {
      label: i18n.t(`${i18nPrefix}datacenter`),
      value: datacenter,
    },
    {
      label: i18n.t(`${i18nPrefix}lastUpdated`),
      value: lastUpdated
        ? format(new Date(lastUpdated), LAST_UPDATED_DATE_FORMAT)
        : undefined,
    },

    {
      label: i18n.t(`${i18nPrefix}originalName`),
      value: originalName,
    },
  ];
};

export const buildInfoCol2Data = (
  showMoreInfoForDemoTemplate: InventoryTopology,
): InfoModalDataItem[] => {
  const { demoId, uid, architectures } = showMoreInfoForDemoTemplate;

  return [
    {
      label: i18n.t(`${i18nPrefix}demoId`),
      value: demoId,
    },
    {
      label: i18n.t(`${i18nPrefix}topologyUid`),
      value: uid,
    },

    {
      label: i18n.t(`${i18nPrefix}architecture`),
      value: architectures && (
        <ArchitectureLabelGroup architectures={architectures} />
      ),
    },
  ];
};

export const buildInfoModalFooter = (
  showMoreInfoForDemoTemplate: InventoryTopology,
): ReactElement => {
  return (
    <div className="row base-margin-bottom dbl-margin-top">
      <div className="col-12">
        <div className="text-left">
          <span className={styles.descriptionLabel}>Description</span>
          {showMoreInfoForDemoTemplate.description}
        </div>
      </div>
    </div>
  );
};

export const prepInventoryTopologiesForTable = (
  inventoryTopologies: InventoryTopologies,
): InventoryTopologies =>
  inventoryTopologies.map((inventoryTopology) => ({
    ...inventoryTopology,
    architectureNames: inventoryTopology.architectures?.map(
      (architecture) => architecture.name || '',
    ),
    description:
      inventoryTopology.description || inventoryTopology.originalDescription,
    name: inventoryTopology.name || inventoryTopology.originalName,
  }));
