import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import {
  useGetAllQuery,
  useGetVmQuery,
} from '../../../../../../redux/configuration/virtual-machine-entities/api';
import { useGetOperatingSystemsQuery } from '../../../../../../redux/configuration/operating-systems/api';
import {
  setNetworkingToEdit,
  setVirtualMachineToEdit,
} from '../../../../../../redux/configuration/virtual-machine-entities/slice';
import { RootState } from '../../../../../../redux/store';
import { getOperatingSystems } from '../../../../../../redux/entities/operating-systems/slice';
import { Modal } from '../../../../../../components';
import {
  diagramSelectors,
  setVmIdToConfigureInDiagram,
} from '../../../../../../redux/diagram/slice';
import { ConfigureVmFromDiagramLoader } from '../../../../../Views/Diagram/components/ConfigureVmFromDiagram/ConfigureVmFromDiagramLoader';
import { diagramApi } from '../../../../../../redux/diagram/api';
import { TAGS } from '../../../../../../redux/api/constants';
import {
  createVmNamesArray,
  determineRDPEnabledNIC,
  determineSSHEnabledNIC,
} from './utils';
import { EditLoader } from './components/EditLoader';
import { ConnectedVirtualMachinesEditForm } from './components/Form/container';
import styles from './Edit.module.css';

export function VirtualMachineEditContainer({
  onModalClose,
  setNetworkingToEdit: onSetNetworkingToEdit,
  setVirtualMachineToEdit: onSetVirtualMachineToEdit,
  operatingSystems,
  isModal,
  vmIdToConfigureInDiagram,
  setVmIdToConfigureInDiagram,
}: VirtualMachineEditContainerProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const virtualMachineUidFromParams = useParams<{
    virtualMachineUid: string;
  }>().virtualMachineUid;
  const virtualMachineUid =
    isModal && vmIdToConfigureInDiagram
      ? vmIdToConfigureInDiagram
      : virtualMachineUidFromParams;
  const {
    data: vmToEdit,
    isFetching: isFetchingVM,
    isError: isErrorLoadingVM,
    rdpEnabledNic,
    sshEnabledNic,
  } = useGetVmQuery(virtualMachineUid, {
    selectFromResult: ({ data, isFetching, isError }) => ({
      data,
      isError,
      isFetching,
      rdpEnabledNic: determineRDPEnabledNIC(data?.vmNetworkInterfaces || []),
      sshEnabledNic: determineSSHEnabledNIC(data?.vmNetworkInterfaces || []),
    }),
  });

  useEffect(() => {
    if (vmToEdit) {
      onSetNetworkingToEdit(vmToEdit.vmNetworkInterfaces);
    }

    return () => {
      onSetNetworkingToEdit(undefined);
    };
  }, [onSetNetworkingToEdit, vmToEdit]);

  useEffect(() => {
    if (vmToEdit) {
      onSetVirtualMachineToEdit(vmToEdit);
    }

    return () => {
      onSetVirtualMachineToEdit(undefined);
    };
  }, [onSetVirtualMachineToEdit, vmToEdit]);

  const { vmNames, isFetchingVms } = useGetAllQuery(topologyUid, {
    selectFromResult: ({ data, isFetching }) => ({
      isFetchingVms: isFetching,
      vmNames: createVmNamesArray(data, virtualMachineUid),
    }),
  });

  const { isError: isErrorLoadingOperatingSystems } =
    useGetOperatingSystemsQuery(undefined, {
      skip: operatingSystems !== undefined,
    });
  const onVmEditModalClose = () => {
    setVmIdToConfigureInDiagram(undefined);
    onModalClose();
  };
  if (
    !vmToEdit ||
    !vmNames ||
    !operatingSystems ||
    isFetchingVM ||
    isFetchingVms ||
    isErrorLoadingOperatingSystems ||
    isErrorLoadingVM
  ) {
    return isModal ? (
      <Modal
        show={true}
        onClose={() => setVmIdToConfigureInDiagram(undefined)}
        size="small"
        clickScreenToClose={true}
        className={styles.diagramVmEditModal}
      >
        <ConfigureVmFromDiagramLoader />
      </Modal>
    ) : (
      <EditLoader />
    );
  }

  return isModal ? (
    <Modal
      show={true}
      onClose={onVmEditModalClose}
      size="fluid"
      clickScreenToClose={true}
      className={styles.diagramVmEditModal}
    >
      <ConnectedVirtualMachinesEditForm
        rdpEnabledNic={rdpEnabledNic}
        setNetworkingToEdit={onSetNetworkingToEdit}
        sshEnabledNic={sshEnabledNic}
        vmNames={vmNames}
        vmToEdit={vmToEdit}
        isModal={true}
      />
    </Modal>
  ) : (
    <ConnectedVirtualMachinesEditForm
      rdpEnabledNic={rdpEnabledNic}
      setNetworkingToEdit={onSetNetworkingToEdit}
      sshEnabledNic={sshEnabledNic}
      vmNames={vmNames}
      vmToEdit={vmToEdit}
    />
  );
}

type VirtualMachineEditContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ConnectedVirtualMachinesEditProps;

type ConnectedVirtualMachinesEditProps = {
  inventoryVmId?: string;
};

type OwnProps = {
  isModal?: boolean;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isModal: ownProps.isModal,
  operatingSystems: getOperatingSystems(state).operatingSystems,
  vmIdToConfigureInDiagram: diagramSelectors.getVmIdToConfigureInDiagram(state),
});

const mapDispatchToProps = (dispatch: React.Dispatch<AnyAction>) => ({
  onModalClose: () => dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM])),
  setNetworkingToEdit: (nic: VmNetworkInterfacesToEdit | undefined) =>
    dispatch(setNetworkingToEdit(nic)),
  setVirtualMachineToEdit: (vm: VirtualMachine | undefined) =>
    dispatch(setVirtualMachineToEdit(vm)),
  setVmIdToConfigureInDiagram: (vmIdToConfigureInDiagram: string | undefined) =>
    dispatch(setVmIdToConfigureInDiagram(vmIdToConfigureInDiagram)),
});

export const ConnectedVirtualMachinesEdit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualMachineEditContainer);
