import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SESSION_VIEW_HW_ACTIONS,
  SESSION_VIEW_VM_ACTIONS,
} from '../../../../../constants';
import { MultiActionProgressBarProps } from './container';
import { buildMessage, buildPercentage } from './utils';

export const MultiActionProgressBar = ({
  complete,
  total,
  show,
  selectedAction,
  page,
  errors,
}: MultiActionProgressBarProps): ReactElement => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [percentageComplete, setPercentageComplete] = useState(0);

  useEffect(() => {
    setMessage(buildMessage(complete, total, errors));
    setPercentageComplete(buildPercentage(complete, total));
  }, [complete, total, errors]);

  const progressBarIsForThisPage = (): boolean => {
    if (
      page === 'hardware' &&
      selectedAction &&
      SESSION_VIEW_HW_ACTIONS.includes(selectedAction)
    ) {
      return true;
    } else if (
      page === 'vm' &&
      selectedAction &&
      SESSION_VIEW_VM_ACTIONS.includes(selectedAction)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {show && progressBarIsForThisPage() && (
        <div
          className="row dbl-padding-bottom"
          data-testid="multi-action-progress-bar"
        >
          <div className="col-lg-6">
            <div className="subheader">{`${selectedAction?.replace(
              /_/g,
              ' ',
            )} ${t('sessionsInventory.progressBar.actionsSubmitted')}`}</div>
            <div className="progressbar" data-percentage={percentageComplete}>
              <div className="progressbar__fill"></div>
              <div className="progressbar__label">{message}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
