import { ArrayField, DeepMap, FieldError } from 'react-hook-form';
import { IconFormKeyValuePairTypes } from '../../../constants';
import { IconFormFields } from '../../../index';

export const filterIconFormFieldsByFieldType = (
  fields: DiagramAddIconFormFieldsDatum[],
  fieldType?: IconFormKeyValuePairType,
): (DiagramAddIconFormFieldsDatum & { index: number })[] =>
  fields
    .map((field, index) => ({
      ...field,
      index,
    }))
    .filter(
      (field) =>
        (!fieldType && !field.type) ||
        (fieldType === IconFormKeyValuePairTypes.link &&
          field.type === IconFormKeyValuePairTypes.link),
    );

type DetermineIfErrorExistsInFieldType = {
  errorKey: string;
  errorDetails?: DeepMap<DiagramAddIconFormFieldsDatum, FieldError>;
  fields: DiagramAddIconFormFieldsDatum[];
  fieldType?: IconFormKeyValuePairType;
};

export const determineIfErrorExistsInFieldType = ({
  errorKey,
  errorDetails,
  fields,
  fieldType,
}: DetermineIfErrorExistsInFieldType): boolean =>
  !!errorDetails &&
  (fieldType === IconFormKeyValuePairTypes.link
    ? fields[parseInt(errorKey)]?.type === IconFormKeyValuePairTypes.link
    : fields[parseInt(errorKey)]?.type !== IconFormKeyValuePairTypes.link);

type DetermineIfPropertiesTabIsInError = {
  errors: DeepMap<IconFormFields, FieldError>;
  fields: Partial<ArrayField<Record<string, string | undefined>, 'id'>>[];
};

export const determineIfPropertiesTabIsInError = ({
  errors,
  fields,
}: DetermineIfPropertiesTabIsInError): boolean =>
  Object.entries(errors.data || {}).some((errorEntry) =>
    determineIfErrorExistsInFieldType({
      errorDetails: errorEntry[1],
      errorKey: errorEntry[0],
      fields,
    }),
  );

type DetermineIfLinksTabIsInError = {
  errors: DeepMap<IconFormFields, FieldError>;
  fields: Partial<ArrayField<Record<string, string | undefined>, 'id'>>[];
};

export const determineIfLinksTabIsInError = ({
  errors,
  fields,
}: DetermineIfLinksTabIsInError): boolean =>
  Object.entries(errors.data || {}).some((errorEntry) =>
    determineIfErrorExistsInFieldType({
      errorDetails: errorEntry[1],
      errorKey: errorEntry[0],
      fieldType: IconFormKeyValuePairTypes.link,
      fields,
    }),
  );

export const determineTabIndexFromFieldType = (
  type?: IconFormKeyValuePairType,
): number => (type === IconFormKeyValuePairTypes.link ? 1 : 0);
