import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentationPanel } from '../DocumentationPanel';
import { EndpointKitsPanelContainer } from '../EndpointKitsPanel/container';
import { HardwarePanel } from '../HardwarePanel';
import { InfoPanel } from '../InfoPanel';
import { ConnectedSharePanel } from '../SharePanel/container';
import { VMPanel } from '../VMPanel';
import { IntegrationPanelContainer } from '../IntegrationPanel/container';
import { ConnectedIconInfoContainer } from '../IconPanel/container';
import { LeftPanelHeaderContainer } from './components/LeftPanelHeader/container';
import { LeftPanelProps } from './container';
import styles from './LeftPanel.module.css';

export function LeftPanel({ leftPanelType }: LeftPanelProps): ReactElement {
  const { t } = useTranslation();

  const renderPanel = () => {
    switch (leftPanelType) {
      case 'info':
        return <InfoPanel />;
      case 'endpointKits':
        return <EndpointKitsPanelContainer />;
      case 'hardware':
        return <HardwarePanel />;
      case 'publicCloud':
        return <IntegrationPanelContainer integrationType="CLOUD" />;
      case 'vm':
        return <VMPanel />;
      case 'saas':
        return <IntegrationPanelContainer integrationType="SAAS" />;
      case 'share':
        return <ConnectedSharePanel />;
      case 'documentation':
        return <DocumentationPanel />;
      case 'icon':
        return <ConnectedIconInfoContainer />;
      default:
        return <InfoPanel />;
    }
  };

  const determineTitle = () => {
    switch (leftPanelType) {
      case 'endpointKits':
        return t('sessions.panels.endpointKits.title');
      case 'share':
        return t('sessions.panels.share.title');
      default:
        return '';
    }
  };

  return (
    <div
      className={classNames('dbl-padding-left dbl-padding-right', styles.root)}
    >
      <LeftPanelHeaderContainer title={determineTitle()} />
      {renderPanel()}
    </div>
  );
}
