import React, { ReactElement, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../components';
import styles from './IpAddressesCellRenderer.module.css';
import { IpAddressesModalContent } from './components/IpAddressesModalContent';

export type IpAddressesCellRendererProps = Pick<ICellRendererParams, 'data'>;

export const IpAddressesCellRenderer = (
  props: IpAddressesCellRendererProps,
): ReactElement => {
  const {
    data: { ipAddresses },
  }: { data: SessionInventoryVirtualMachineData } = props;

  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <>
      <button
        className={styles.viewButton}
        onClick={() => setShow(true)}
        type="button"
        aria-label="View All IP Addresses"
      >
        {t(
          'sessionsInventory.virtualMachines.ipAddressesTable.viewButtonLabel',
        )}
      </button>
      {show && (
        <Modal
          clickScreenToClose={true}
          show={true}
          onClose={() => setShow(false)}
          title={''}
          testId={`sessions-inventory-ip-addresses-modal`}
          headerAlignment="left"
          size="large"
        >
          <IpAddressesModalContent
            ipAddresses={ipAddresses}
            onClose={() => setShow(false)}
          />
        </Modal>
      )}
    </>
  );
};
