import { SelectOption } from 'dcloud-shared-ui';
import { PLEASE_SELECT_OPTION } from '../../../../../../../constants/form';
import { DEFAULT_NETWORK_MAX_CONNECTIONS } from '../../../../constants';

type NetworkOptions = SelectOption<string>[];

export function getNetworkOptions(networks: Network[]): NetworkOptions {
  return [
    PLEASE_SELECT_OPTION,
    ...networks.map((network) => ({
      name: network.name,
      value: network.uid,
    })),
  ];
}

export const isNetworkNicLimitCrossed = (
  currentNic: NetworkToConfigure,
  selectedNics: NetworkToConfigure[],
  networks: Network[],
  networkUid: string,
): NetworkToConfigure => {
  const currentNetwork = networks.find((network) => network.uid === networkUid);
  const limit =
    currentNetwork?.vmConnectionInfo?.maxConnections ||
    DEFAULT_NETWORK_MAX_CONNECTIONS;
  const existingNicCount =
    currentNetwork?.vmConnectionInfo?.currentConnections.length || 0;
  const localNics = selectedNics.filter(
    (interfaceItem) => interfaceItem.network?.uid === networkUid,
  );
  const isOverLimit = existingNicCount + localNics.length > limit;
  const result = { ...currentNic, isLimited: isOverLimit, limit };
  return result;
};

export const calculateNicLimits = (
  mappings: NetworkToConfigure[],
  networks: Network[],
): NetworkToConfigure[] => {
  const mappingsResult = mappings.map((current) => {
    return isNetworkNicLimitCrossed(
      current,
      mappings,
      networks,
      current.network?.uid || '',
    );
  });

  return mappingsResult;
};

export function isIpWithinSubnet(ipAddress: string, subnet: string): boolean {
  const [subnetAddress, subnetMask] = subnet.split('/');
  const subnetBits = Number(subnetMask);
  const subnetStart = ipToNumber(subnetAddress);
  const subnetEnd = subnetStart + Math.pow(2, 32 - subnetBits) - 1;
  const ip = ipToNumber(ipAddress);

  return ip >= subnetStart && ip <= subnetEnd;
}

export function ipToNumber(ipAddress: string): number {
  const parts = ipAddress.split('.');
  return (
    (Number(parts[0]) << 24) +
    (Number(parts[1]) << 16) +
    (Number(parts[2]) << 8) +
    Number(parts[3])
  );
}
export function getFirstIpInSubnet(subnet: string): string {
  const [subnetAddress] = subnet.split('/');
  const subnetStart = ipToNumber(subnetAddress);

  const firstIp = subnetStart;

  return numberToIp(firstIp);
}

export function getSecondIpInSubnet(subnet: string): string {
  const [subnetAddress] = subnet.split('/');
  const subnetStart = ipToNumber(subnetAddress) + 1;

  const firstIp = subnetStart;

  return numberToIp(firstIp);
}

export function getLastIpInSubnet(subnet: string): string {
  const [subnetAddress, subnetMask] = subnet.split('/');
  const subnetBits = Number(subnetMask);
  const subnetStart = ipToNumber(subnetAddress);
  const subnetEnd = subnetStart + Math.pow(2, 32 - subnetBits) - 1;

  return numberToIp(subnetEnd);
}

export function getSecondLastIpInSubnet(subnet: string): string {
  const [subnetAddress, subnetMask] = subnet.split('/');
  const subnetBits = Number(subnetMask);
  const subnetStart = ipToNumber(subnetAddress);
  const subnetEnd = subnetStart + Math.pow(2, 32 - subnetBits) - 2;

  return numberToIp(subnetEnd);
}

export function numberToIp(ipNumber: number): string {
  const octet1 = (ipNumber >>> 24) & 255;
  const octet2 = (ipNumber >>> 16) & 255;
  const octet3 = (ipNumber >>> 8) & 255;
  const octet4 = ipNumber & 255;

  return `${octet1}.${octet2}.${octet3}.${octet4}`;
}

export function ipAddressesOnSameNetwork(
  networkUid: string,
  ipAddress: string,
  interfaces: NetworkToConfigure[],
): boolean {
  if (!networkUid || !ipAddress || !interfaces) {
    return false;
  }
  const ipAddressesOnSameNetwork = interfaces.filter((nic) =>
    nic.ipAddress === ipAddress && nic.ipAddress && nic.network
      ? nic.network.uid === networkUid
      : false,
  );
  return ipAddressesOnSameNetwork.length > 1;
}

export function getSubnet(networkUid: string, networks: Network[]): string {
  const selectedNetwork = networks.find((nw) => nw.uid === networkUid);

  return selectedNetwork ? selectedNetwork.inventoryNetwork.subnet : '';
}

export function getNetworkType(
  networkUid: string,
  networks: Network[],
): string {
  const selectedNetwork = networks.find((nw) => nw.uid === networkUid);

  return selectedNetwork ? selectedNetwork.inventoryNetwork.type : '';
}

export function isDuplicateMacAddress(
  interfaces: NetworkToConfigure[],
  macAddress?: string,
): boolean {
  if (!macAddress) {
    return false;
  }
  const duplicateMacs = interfaces.filter(
    (nic) => nic.macAddress === macAddress,
  );
  return duplicateMacs.length > 1;
}
