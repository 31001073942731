import React, { ReactElement } from 'react';
import { Heading } from '../../../../components/Heading';

export interface PageHeadingProps {
  text: string;
}

export function PageHeading({ text }: PageHeadingProps): ReactElement {
  return (
    <Heading
      className="dbl-padding"
      headingText={text}
      level="h1"
      size="36"
      align="left"
      weight="200"
    />
  );
}
