import classnames from 'classnames';
import { Select } from 'dcloud-shared-ui';
import React, { ComponentPropsWithoutRef, ReactElement } from 'react';
import { getQuantityOptions } from './utilities';
import styles from './QuantitySelect.module.css';

export interface QuantitySelectProps
  extends ComponentPropsWithoutRef<'select'> {
  className?: string;
  inline?: boolean;
  label?: string;
  maxQuantity: number;
  show?: boolean;
}

export function QuantitySelect({
  className,
  label = '',
  maxQuantity,
  show = true,
  ...selectProps
}: QuantitySelectProps): ReactElement | null {
  if (!show) {
    return null;
  }

  return (
    <div
      className={classnames(styles.root, className)}
      data-test="quantity-select"
    >
      <Select
        {...selectProps}
        label={label}
        inline={false}
        options={getQuantityOptions(maxQuantity)}
      />
    </div>
  );
}
